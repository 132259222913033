import React, { useState, useEffect } from 'react';

import TestimonialService from "services/testimonial/testimonial.service";
import { logger } from 'hoc/logger';
import useMyFormValidation from 'hook/useMyFormValidation';
import { TestimonialDto } from 'dto/testimonial/testimonial.dto';
import { useCookies } from 'react-cookie';
import { Loading } from 'components/general/Loading.jsx';
import { MyNavBt } from "components/general/MyNavBt";
import { useResource } from "hook/useResource";
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box';
import { CommonTools } from 'tools/commontools';
import { ComponentCommonTools } from 'tools/componentcommontools'

import ResultObjectDTO from 'dto/app/resultobject.dto';
import MyTextField from 'components/form/mytextfield';
import { LabelTypes } from 'tools/labeltypes';
import ValidateTools from 'tools/validate.tools';
import RequiredValidator from 'validators/required.validator';
import { MyButton } from 'components/general/MyButton';
import { RouteTools } from 'tools/routetools';
import { MyBreadcrumbs } from "components/elements/MyBreadcrumbs/MyBreadcrumbs";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import MyCheckboxField from "components/form/mycheckboxfield";
import IconButton from '@mui/material/IconButton';
import { StudyTypeSelect } from 'components/elements/Select/StudyTypeSelect';
import { StatusSelect } from 'components/elements/Select/StatusSelect';
import { Status } from 'tools/status';
import { Skeleton, Stack } from '@mui/material';



const testimonialService = new TestimonialService();

interface TeacherInterfaceEditTestimonialProps {
    _obj: TestimonialDto,
    extended: boolean,
    setExtended: any,
    itemIndex: number;
    setObjects: any;
    objects: Array<TestimonialDto>;
}

const TeacherInterfaceEditTestimonial: React.FC<TeacherInterfaceEditTestimonialProps> = ({
    _obj,
    extended,
    setExtended,
    
    itemIndex,
    setObjects,
    objects,
    ...props
}) => {

    const { LL } = useResource();
    const [loading, setLoading] = useState<boolean>(true);
    const [obj, setObj] = useState<TestimonialDto>(_obj)

    const [isFormDisabled, setIsFormDisabled] = useState(false);

    const [validators, setValidators] = useState<any>();
    const [vresults, setVResults] = useState<any>(false);


    const validateAll = () => {
        if (!obj) return;
        if (!validators) return;

        obj.teacherresponse = obj.teacherresponse !== undefined ? obj.teacherresponse : '';


        setObj(obj);
        setLoading(false)
        checkIsFormDisabled();
    };

    const validateField = (field: any, value: any, v?: any) => {
        v = (v) ? v : JSON.parse(JSON.stringify(vresults));
        if (v === false) v = {};
        v[field] = ValidateTools.validate(field, value, validators, vresults);
        setVResults(v);
        setIsFormDisabled(ValidateTools.vForm(v, field));
        return v;
    };

    const processValidators = () => {
        let v: any = {
            teacherresponse: [],
        };

        v.teacherresponse.push(new RequiredValidator('', ''));

        setValidators(v);
    };

    useEffect(() => {
        setLoading(true);
        processValidators();
    }, [extended]);

    useEffect(() => {
        validateAll();
    }, [validators]);


    const setObjField = (field: any, value: any) => {
        let t = JSON.parse(JSON.stringify(obj));
        t[field] = value;
        setObj(t);

        validateField(field, value);
    };

    const handleSubmit = (event: any) => {
        event.preventDefault();
        if (isFormDisabled) return false;
        if (!obj) return;
        if (!obj.id) return;
        setLoading(true);
        testimonialService.update(obj.id, handleResult, {}, obj);
    };

    const handleResult = (result: any) => {
        if (!result) return;
        if (!result.err) {
            // setObjectWasChanged(CommonTools.generateRandomString(5))
            updateObjectAtIndex(itemIndex);
            setLoading(false);
            setExtended(false);
        }
    }

    const updateObjectAtIndex = (index:number) => {
        const updatedArray = [...objects];
        updatedArray.splice(index, 1, { ...updatedArray[index], ...obj });
        
        setObjects(updatedArray);
      };

    const checkIsFormDisabled = () => {
        var rez = false;
        if (!obj) rez = true;
        if (!obj.teacherresponse) rez = true;
        setIsFormDisabled(rez);
    };

    useEffect(() => {
        if (!obj) return;
        if (loading) return;

        checkIsFormDisabled();
    }, [obj.teacherresponse]);

    const handleClose = () => {
        setExtended(false);
    }
    const processForm = () => {
        if (loading) return <Skeleton />;
        if (!obj) return <></>;
        if (!obj.id) return <></>;

        return (
            <Box>
                <form>
                    <Box className="shadow-box" sx={{ mt: 3 }}>
                        <MyTextField
                            tabIndex={0}
                            fullWidth
                            multiline
                            minRows={4}
                            id="teacherresponse"
                            name="teacherresponse"
                            variant="standard"
                            className="form-input"
                            color="primary"
                            _label={LL("teacherresponse")}
                            size="small"
                            value={obj.teacherresponse}
                            setObj={setObjField}
                            _vresults={
                                vresults && vresults.teacherresponse ? vresults.teacherresponse : false
                            }
                        />
                    </Box>
                    <Stack direction='row' spacing={2} mt={3}>
                    <MyButton
                        tabIndex={3}
                        cb={handleSubmit}
                        disabled={isFormDisabled}
                        color="primary"
                        
                        variant="contained"
                        
                        type="submit"
                        children={LL("Bt_Submit")}
                    />
                    <MyButton
                        cb={handleClose}
                        className="cancel-response"
                    >
                        {LL("Bt_Cancel")}
                    </MyButton>
                    </Stack>
                </form>
            </Box>
        )
    }


    return processForm();
};

export { TeacherInterfaceEditTestimonial };