import { useResource } from "hook/useResource";
import React, { useContext } from "react";
import { MyButton } from "components/general/MyButton";
import { Status } from "tools/status";
import { StudentCourseContext } from "context/studentcourse/studentcourse.context";
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined';

const StudentButtonFinishLesson: React.FC = () => {
  const { LL } = useResource();
  const {
    state: { lessonEnrollmentObject },
    actions: { finishLessonAndGetNext },
  } = useContext(StudentCourseContext);
  const handleClick = () => {
    if (!lessonEnrollmentObject) return;
    if (!lessonEnrollmentObject.hasOwnProperty("id")) return;
    if (!lessonEnrollmentObject.id) return;

    finishLessonAndGetNext(lessonEnrollmentObject.id);
  };
  const processComponent = () => {
    if (!lessonEnrollmentObject) return <></>;
    
    if (
      !lessonEnrollmentObject.hasOwnProperty("status") ||
      !lessonEnrollmentObject.status
    )
      return <></>;
    if (
      lessonEnrollmentObject.status === Status.LESSON_ERR_FINISHED ||
      lessonEnrollmentObject.status === Status.LESSON_ERR_NEED_TEST ||
      lessonEnrollmentObject.status === Status.LESSON_ERR_PASSED_TEST
    )
      return <></>;
    return <MyButton cb={handleClick} fullWidth className="finishButton"><DoneOutlinedIcon />{LL("BT_FinishLesson")}</MyButton>;
  };

  return processComponent();
};

export { StudentButtonFinishLesson };
