import React, { useEffect, useState } from "react";
import PageComponentProps from "interfaces/pagecomponentprops.interface";
import { useResource } from "hook/useResource";
import { logger } from "hoc/logger";
import MenuService from "services/menu/menu.service";
import { TopMenuLevel } from "./TopMenuLevel";

const TopMenuBlock: React.FC<PageComponentProps> = ({
  currentRoute,
  ...props
}) => {
  
  return <TopMenuLevel currentRoute={currentRoute} level={1} idparent={""} />; 
};

export { TopMenuBlock };
