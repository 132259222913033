import React from "react";
import PageComponentProps from "interfaces/pagecomponentprops.interface";
import { TeacherInterfaceRequestList } from "./TeacherInterfaceRequestList";


const TeacherInterfaceRequestBlock: React.FC<PageComponentProps> =
    ({
        currentRoute,
        ...props
    }) => {

        return (
            <>
             <TeacherInterfaceRequestList currentRoute={currentRoute} />
            </>
        );
    };

export { TeacherInterfaceRequestBlock };
