import React, { useEffect, useState } from "react";
import PageComponentProps from "interfaces/pagecomponentprops.interface";
import { useResource } from "hook/useResource";
import { logger } from "hoc/logger";
import { SiteSocialDto } from "dto/sitesocial/sitesocial.dto";
import { Link } from '@mui/material';

import { ComponentCommonTools } from 'tools/componentcommontools'
import { CommonTools } from "tools/commontools";

type SocialItemIconProps = {
    obj: SiteSocialDto;
    width: number;
    height: number;
}


const SocialItemIcon: React.FC<SocialItemIconProps> = ({
    obj,
    width,
    height,
    ...props
}) => {

    const [imgUrl, setImgUrl] = useState<string>("");
    const [fullUrl, setFullUrl] = useState<string>("");

    const prepareGallery = () => {
        if (!obj) return;
        if (!obj.typesocial) return;
        if (!obj.typesocial.hasOwnProperty('gallery')) return;
        if (!obj.typesocial.gallery) return;
        if (obj.typesocial.gallery.length === 0) return;
        const galleryObj = CommonTools.getGalleryObjectLogo(obj.typesocial.gallery);
        if (!galleryObj) return;
        if (!width) width = 50;
        if (!height) height = 50;
        CommonTools.setFileUrlsGallery(galleryObj, setImgUrl, setFullUrl, width, height);
    }

    useEffect(() => {
        prepareGallery();
    }, [obj])

    const processIcon = () => {
        if (!obj) return <></>;
        if (!obj.link) return <></>;
        if (obj.link === '') return <></>;
        if (obj.typesocial === undefined) return <></>;

        return (
            <>
                {ComponentCommonTools.getFileBlock(imgUrl, 50, 50)}
            </>
        );
    }


    return (<>{processIcon()}</>);
};

export { SocialItemIcon };
