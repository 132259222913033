import { Box, Card, CardContent, IconButton, MenuItem, Skeleton, Stack, Typography, Grid } from "@mui/material";

import { CourseTeacherRequestDto } from "dto/course/courseteacherrequest.dto";
import { useAuth } from "hook/useAuth";
import { useResource } from "hook/useResource";
import React, { useState, useEffect } from "react";

import DateTools from "tools/date.tools";
import { Status } from "tools/status";
import { Types } from "tools/types";

import CloseIcon from '@mui/icons-material/Close';

import { CommonTools } from "tools/commontools";
import { ComponentCommonTools } from "tools/componentcommontools";
import { StatusChip } from "components/elements/Chip/StatusChip";


type CourseTeacherInterfaceRequestItemProps = {
    obj: CourseTeacherRequestDto;
}



const CourseTeacherInterfaceRequestItem: React.FC<CourseTeacherInterfaceRequestItemProps> = ({
    obj,
    ...props
}) => {

    const { LL, CC } = useResource();

    const processItem = () => {
        if (!obj) return (<></>);

        return (
            <Box className="teacherRequest" my={2}>
                    <Typography variant="h5" >
                        {ComponentCommonTools.processObjectFieldOfObject(obj, "course", "name")} <StatusChip obj={obj} />
                    </Typography>
                    <Grid container>
                        <Grid item lg={6} sm={12}>
                            <Typography color="text.disabled" variant="caption">
                                {LL("Type")}
                            </Typography>
                            <Typography >
                                {ComponentCommonTools.processObjectField(obj, "type_name")}
                            </Typography>
                            <Typography color="text.disabled" variant="caption">
                            {LL("Date")}
                            </Typography>
                            <Typography >
                                {ComponentCommonTools.processObjectField(obj, "date_name")}
                            </Typography>
                            <Typography color="text.disabled" variant="caption">
                            {LL("teachermessage")}
                            </Typography>
                            <Typography >
                                {ComponentCommonTools.processObjectField(obj, "teachermessage")}
                            </Typography>
                        </Grid>
                        <Grid item lg={6} sm={12}>
                            <Typography color="text.disabled" variant="caption">
                                {LL("changedate")}
                            </Typography>
                            <Typography >
                                {ComponentCommonTools.processObjectField(obj, "changedate_name")}
                            </Typography>
                            <Typography color="text.disabled" variant="caption">
                                {LL("managerfullname")}
                            </Typography>
                            <Typography >
                                {ComponentCommonTools.processObjectField(obj, "managerfullname")}
                            </Typography>
                            <Typography color="text.disabled" variant="caption">
                                {LL("managermessage")}
                            </Typography>
                            <Typography >
                                {ComponentCommonTools.processObjectField(obj, "managermessage")}
                            </Typography>

                        </Grid>
                    </Grid>
                </Box>
        )
    }


    return processItem();
};

export { CourseTeacherInterfaceRequestItem };
