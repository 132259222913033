import React from "react";
import PageComponentProps from "interfaces/pagecomponentprops.interface";
import { TeacherRequestDialog } from "./TeacherRequestDialog";

const TeacherRequestBlock: React.FC<PageComponentProps> = ({ currentRoute, ...props }) => {

    return (
        <>
            <TeacherRequestDialog currentRoute={currentRoute} />
        </>
    );
};

export { TeacherRequestBlock };
