import React, { useEffect, useState } from "react";
import { useResource } from "hook/useResource";
import { logger } from "hoc/logger";
import CourseCategoryService from "services/course/coursecategory.service";
import MenuLevelProps from "interfaces/menulevelprops.interface";
import { CourseCategoryDto } from "dto/course/coursecategory.dto";
import { RouteTools } from "tools/routetools";
import RequestListDTO from "dto/app/requestlist.dto";
import { Status } from "tools/status";
import RequestFilterDTO from "dto/app/requestfilter.dto";
import ResultListDTO from "dto/app/resultlist.dto";
import { Types } from "tools/types";
import MenuItemProps from "interfaces/menuitemprops.interface";
import { MyNavBt } from "components/general/MyNavBt";
import { InfoCourseCategoryLevel } from "./InfoCourseCategoryLevel";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { MyButton } from "components/general/MyButton";
import { type } from "os";
import { ComponentCommonTools } from "tools/componentcommontools";
import { Stack } from "@mui/material";

type InfoCourseCategoryItemProps = {
    currentRoute: any;
    level: number;
    obj: CourseCategoryDto;
    hasChildren?: boolean;
}

const InfoCourseCategoryItem: React.FC<InfoCourseCategoryItemProps> = ({
    currentRoute,
    level,
    obj,
    hasChildren,
    ...props
}) => {
    const [nextLevel, setNextLevel] = useState<number>(1);
    const [maxLevel, setMaxLevel] = useState<number>(1);
    const [isChildsShowed, setIsChildsShowed] = useState<boolean>(false);

    const {CC} = useResource()
    
    useEffect(() => {
        let ml: number = parseInt(CC("MAXLEVEL_Category_Top_MENU","4"));
        ml = isNaN(ml) || ml == undefined ? 1 : ml;
        setMaxLevel(ml);
        
    }, []);

    useEffect(() => {
        setNextLevel(level + 1);
    }, [level]);

    useEffect(() => {
        setIsChildsShowed(false);
    }, [obj]);

    const processBT = () => {
        if (!obj) return;
        
        if (!obj.fullurl) {
            return (<Stack direction='row' spacing={2}>
                <Box>{processIcon()}</Box>
                {ComponentCommonTools.processObjectField(obj, 'name')}
            </Stack>);
        }

        return (
            <MyNavBt href={obj.fullurl} >
                <Stack direction='row' spacing={2}>
                <Box>{processIcon()}</Box>
                {ComponentCommonTools.processObjectField(obj, 'name')}
            </Stack>
            </MyNavBt>);
    };

    const processDetail = () => {
        if (!obj) return <></>;

        return <Box>{processBT()}</Box>;
    };

    const processBts = () => {
        if (nextLevel > maxLevel || !hasChildren) return <></>;

        if (isChildsShowed)
            return (
                <Box className="icon-minus"
                    onClick={() => {
                        setIsChildsShowed(false);
                    }}
                >
                </Box>
            );
        if (!isChildsShowed)
            return (
                <Box className="icon-plus"
                    onClick={() => {
                        setIsChildsShowed(true);
                    }}
                >
                </Box>
            );
    };
    const processIcon = () => {
        if (!obj) return <></>;
        if (!obj.hasOwnProperty('icon')) return <></>;
        if (!obj.icon) return <></>;
        return ComponentCommonTools.getFileBlockSVG(obj.icon);
    }
    const processChilds = () => {
        if (!hasChildren) return <></>;
        if (nextLevel > maxLevel) return <></>;
        if (!isChildsShowed) return <></>;
        if (!obj) return <></>;

        return (
            <Box className={"section" + level}>
                <Box className="content">
                    <InfoCourseCategoryLevel
                        currentRoute={currentRoute}
                        level={nextLevel}
                        idparent={obj.id ?? ""}
                    /></Box>
            </Box>
        );
    };



    return (
        <>
            <Box className="item"
                onMouseOut={() => {
                    setIsChildsShowed(false);
                }}
                onMouseOver={() => {
                    setIsChildsShowed(true);
                }}
            >
                {processDetail()}
                {processBts()}
                {processChilds()}
            </Box>
        </>
    );

};

export { InfoCourseCategoryItem };
