import React, { useEffect, useState } from "react";
import { useResource } from "hook/useResource";
import { logger } from "hoc/logger";

// import TeacherFAQService from "services/testimonial/testimonial.service";

import MenuLevelProps from "interfaces/menulevelprops.interface";
import { TeacherFAQDto } from "dto/teacher/teacherfaq.dto";
import { RouteTools } from "tools/routetools";
import RequestListDTO from "dto/app/requestlist.dto";
import { Status } from "tools/status";
import RequestFilterDTO from "dto/app/requestfilter.dto";
import ResultListDTO from "dto/app/resultlist.dto";
import { Types } from "tools/types";
import MenuItemProps from "interfaces/menuitemprops.interface";
import { MyNavBt } from "components/general/MyNavBt";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { MyButton } from "components/general/MyButton";
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import { ComponentCommonTools } from 'tools/componentcommontools'
import Typography from "@mui/material/Typography";
import Rating from '@mui/material/Rating';
import Avatar from "@mui/material/Avatar";
import { Stack } from "@mui/material";
import { MyRatingReadOnly } from "components/elements/MyRating/MyRatingReadOnly";
import { MyAvatar } from "components/elements/MyAvatar/MyAvatar";
import { TeacherTypeIcon } from "./TeacherTypeIcon";

type TeacherFAQCardProps = {
    obj: TeacherFAQDto;

}

const TeacherFAQCard: React.FC<TeacherFAQCardProps> = ({
    obj,
    ...props
}) => {
    const { LL } = useResource();


    const processTeacher = () => {
        if (!obj) return <></>;
        if (!obj.teacher) return <></>;
        if (!obj.teacher_fullname) return <></>;
        let fullUrl = '';
        if (obj.teacher.hasOwnProperty('fullurl') && obj.teacher.fullurl) fullUrl = obj.teacher.fullurl;
        if (!fullUrl) {
            return (
                <Box display='flex' alignItems='center'>
                    <Box mr={1}>{processTeacherStatus()}</Box>
                    <Typography className="teacher-name">{ComponentCommonTools.processObjectField(obj, 'teacher_fullname')}</Typography>
                    <Typography className="data">
                            {ComponentCommonTools.processObjectField(obj, 'teacherresponsedate_name')}
                            </Typography>
                </Box>
            )
        }
        return (
            <MyNavBt href={fullUrl} className="no-link">
                 <Box display='flex' alignItems='center'>
                    <Box mr={1}>{processTeacherStatus()}</Box>
                    <Typography className="teacher-name">{ComponentCommonTools.processObjectField(obj, 'teacher_fullname')}</Typography>
                    <Typography className="data">
                            {ComponentCommonTools.processObjectField(obj, 'teacherresponsedate_name')}
                            </Typography>
                </Box>
            </MyNavBt>
        )
    }

    const processUserName = () => {
        if (!obj) return <></>;
        let fullUrl = '';
        if (obj.hasOwnProperty('user_fullurl') && obj.user_fullurl) fullUrl = obj.user_fullurl;

        if (!fullUrl) {
            return (
                <Typography className="name">{processUserFullName()}</Typography>
            )
        }
        return (
            <MyNavBt href={fullUrl}>
                <Typography className="name">{processUserFullName()}</Typography>
            </MyNavBt>
        )
    }

    const processUserFullName = () => {
        if (!obj) return '';
        if (!obj.user_name) return '';
        if (!obj.user_surname) return obj.user_name;


        return obj.user_name + ' ' + obj.user_surname;
    }
    const processAvatar = () => {
        if (!obj) return <></>;
        let name = ''
        let surname = ''
        if (obj.hasOwnProperty('user_name') && obj.user_name) name = obj.user_name;
        if (obj.hasOwnProperty('user_surname') && obj.user_surname) surname = obj.user_surname;

        return <MyAvatar obj={obj} name={name} surname={surname} sx={{ mr: 1 }} />
    }

    const processTeacherAvatar = () => {
        if (!obj) return <></>;
        if (!obj.teacher) return <></>;
        let name = ''
        let surname = ''
        if (obj.teacher.hasOwnProperty('name') && obj.teacher.name) name = obj.teacher.name;
        if (obj.teacher.hasOwnProperty('surname') && obj.teacher.name) surname = obj.teacher.surname;

        return <MyAvatar obj={obj.teacher} name={name} surname={surname} sx={{ mr: 1 }} />
    }

    const processTeacherStatus = () => {
        if (!obj) return <></>;
        if (!obj.teacher) return <></>;
        return <TeacherTypeIcon obj={obj.teacher} />
    }
    const processUserCard = () => {
        if (!obj) return <></>;
        return (
            <Box className="user-message" p={2}>
                    <Box display="flex">
                        {processAvatar()}
                        <Box>
                            <Typography className="student-name">{processUserName()}</Typography>
                            <Typography className="data">{ComponentCommonTools.processObjectField(obj, 'date_name')}</Typography>
                        </Box>
                    </Box>
                    <Typography my={2} ml={6} className="message">{ComponentCommonTools.processObjectField(obj, 'message')}</Typography>

           </Box>
        )
    }

    const processTeacherCard = () => {
        if (!obj) return <></>;
        return (
            <Box className="teacher-response" px={2} ml={8}>
                    <Box display="flex" alignItems="center">
                        {processTeacherAvatar()}
                        {processTeacher()}
                        
                    </Box>
                    <Box >
                        <Typography >
                            {ComponentCommonTools.processObjectField(obj, 'teacherresponse')}
                        </Typography>
                        
                    </Box>
            </Box>
        )
    }
    const processItem = () => {
        if (!obj) return <></>;

        return (
            <Box className="box-faq-message on-site" my={2} pb={2}>
                {processUserCard()}
                {processTeacherCard()}
            </Box>
        )
    };


    return processItem();


};

export { TeacherFAQCard };
