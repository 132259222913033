import React from "react";
import { useResource } from "hook/useResource";
import { TeacherDto } from "dto/teacher/teacher.dto";
import { Status } from "tools/status";
import { Stack, Typography } from "@mui/material";
import { TeacherTypeIcon } from "components/teacher/TeacherTypeIcon";
type TeacherRequestButtonContentProps = {
    obj: TeacherDto;

}

const TeacherRequestButtonContent: React.FC<TeacherRequestButtonContentProps> = ({
    obj,
    ...props
}) => {
    const { LL } = useResource();

    const processItem = () => {
        if (!obj) return <></>;
        if (!obj.hasOwnProperty("status")) return <></>;
        if (obj.status === Status.TEACHER_UNVERIFIED) {
            return (
                <Stack direction={'row'} spacing={1}>
                    <TeacherTypeIcon obj={{ status: Status.TEACHER_VERIFIED }} />
                    <Typography color={'#01E65C'}>
                        {LL('Become')}
                    </Typography>
                    <Typography color={'#01E65C'}>
                        {Status.LL(Status.TEACHER_VERIFIED)}
                    </Typography>
                </Stack>
            )

        };
        if (obj.status === Status.TEACHER_VERIFIED) {
            return (
                <Stack direction={'row'} spacing={1}>
                    <TeacherTypeIcon obj={{ status: Status.TEACHER_PRO }} />
                    <Typography color={'#FFD102'}>
                        {LL('Become')}
                    </Typography>
                    <Typography color={'#FFD102'}>
                        {Status.LL(Status.TEACHER_PRO)}
                    </Typography>
                </Stack>
            );
        }

        return <></>
    };


    return processItem();


};

export { TeacherRequestButtonContent };
