import React, { useEffect, useState } from "react";
import PageComponentProps from "interfaces/pagecomponentprops.interface";
import { CommonTools } from "tools/commontools";
import { Box, Skeleton, Typography, Grid, Stack, Link } from "@mui/material";
import { CourseDto } from "dto/course/course.dto";
import ResultObjectDTO from "dto/app/resultobject.dto";
import CourseService from "services/course/course.service";
import { CoursePageTopBlock } from "components/course/course/CoursePageTopBlock";
import { HtmlTag } from "components/general/HtmlTag";
import { useResource } from "hook/useResource";
import { CourseLessonsList } from "components/course/lesson/CourseLessonsList";
import { TeacherInfoBlock } from "components/teacher/TeacherInfoBlock";
import { CourseVideo } from "components/course/course/CourseVideo";

import { CourseTestimonials } from "components/course/course/CourseTestimonials";
import { CourseListByTeacher } from "components/course/course/CourseListByTeacher";
import { NewsletterBlock } from "components/general/NewsletterBlock";
import { TeacherProcessNameCourse } from "components/teacher/TeacherProcessNameCourse";
import { MyAvatar } from "components/elements/MyAvatar/MyAvatar";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import DateTools from "tools/date.tools";
import { TeacherFAQBlock } from "components/teacher/TeacherFAQBlock";
import { CourseEnrollmentDialog } from "components/course/courseenrollment/CourseEnrollmentDialog";
import { logger } from "hoc/logger";
import { ComponentCommonTools } from "tools/componentcommontools";

const courseService = new CourseService();
const CoursePage: React.FC<PageComponentProps> = ({
  currentRoute,
  ...props
}) => {
  const { LL } = useResource();
  const [idCourse, setIdCourse] = useState("");
  const [obj, setObj] = useState<CourseDto | undefined>();
  const [loading, setLoading] = useState<boolean>(true);
  useEffect(() => {
    setIdCourse(CommonTools.getIdFromPath(currentRoute, "course"));
  }, [currentRoute]);

  const loadObject = (obj: ResultObjectDTO) => {
    if (!obj) return;
    if (!obj.err) {
      const object = obj.obj ? obj.obj : {};
      setObj(object);
    }
  };

  const getObject = () => {
    if (idCourse === "") return;
    setLoading(true);
    courseService.get(idCourse, loadObject, {});
  };

  const checkIsLoading = () => {
    let load: boolean = false;
    if (obj === undefined) load = true;
    setLoading(load);
  };

  useEffect(() => {
    if (idCourse !== "") getObject();
  }, [idCourse]);

  useEffect(() => {
    checkIsLoading();
  }, [obj]);

  const processDescription = () => {
    if (!obj) return <></>;
    if (!obj.hasOwnProperty("description")) return <></>;
    if (!obj.description) return <></>;
    return (
      <Box className="detailsGeneral" pt={5}>
        <Typography variant="h3">{LL("Description")}</Typography>
        <HtmlTag content={obj.description} />
      </Box>
    );
  };

  const processRequirementsText = () => {
    if (!obj) return <></>;
    if (!obj.hasOwnProperty("requirementstext")) return <></>;
    if (!obj.requirementstext) return <></>;
    return (
      <Box className="detailsGeneralround" mt={5}>
        <Typography variant="h3">{LL("requirementstext")}</Typography>
        <HtmlTag content={obj.requirementstext} />
      </Box>
    );
  };

  const processWhatWillYouLearnText = () => {
    if (!obj) return <></>;
    if (!obj.hasOwnProperty("whatwillyoulearntext")) return <></>;
    if (!obj.whatwillyoulearntext) return <></>;
    return (
      <Box className="detailsGeneral" pt={5}>
        <Typography variant="h3">{LL("whatwillyoulearntext")}</Typography>
        <HtmlTag content={obj.whatwillyoulearntext} />
      </Box>
    );
  };

  const processTargetAudienceText = () => {
    if (!obj) return <></>;
    if (!obj.hasOwnProperty("targetaudiencetext")) return <></>;
    if (!obj.targetaudiencetext) return <></>;
    return (
      <Box className="detailsGeneralround " mt={5}>
        <Typography variant="h3">{LL("targetaudiencetext")}</Typography>
        <HtmlTag content={obj.targetaudiencetext} />
      </Box>
    );
  };

  const getCourseDuration = () => {
    if (!obj) return 0;
    if (!obj.hasOwnProperty("courseduration")) return 0;
    if (!obj.courseduration) return 0;
    return DateTools.displayTimestampInHHMM(obj.courseduration);
  };
  const getNumberOfLessons = () => {
    if (!obj) return 0;
    if (!obj.hasOwnProperty("numberoflessons")) return 0;
    if (!obj.numberoflessons) return 0;
    return obj.numberoflessons;
  };

  const processLessonsContentHeader = () => {
    if (!obj) return <></>;
    if (!obj.id) return <></>;

    const lessonNumber = getNumberOfLessons();
    const courseDuration = getCourseDuration();

    return (
      <>
        <Typography variant="h3" mt={5}>
          {LL("CourseContent")}
        </Typography>
        <Stack
          direction="row"
          spacing={2}
          my={3}
          alignItems="center"
          className="courseGeneralData"
        >
          <Typography>
            <span>{LL("LessonsNumber")}</span> {lessonNumber}
          </Typography>
          <FiberManualRecordIcon sx={{ fontSize: "10px" }} color="primary" />
          <Typography>
            <span>{LL("CourseDuration")}</span> {courseDuration}
          </Typography>
        </Stack>
      </>
    );
  };

  const processLessonsList = () => {
    if (!obj) return <></>;
    if (!obj.id) return <></>;
    return (
      <Box id="lesson-block">
        <Box>{processLessonsContentHeader()}</Box>
        <Box>
          <CourseLessonsList idCourse={obj.id} />
        </Box>
      </Box>
    );
  };

  const processTeacherInfo = () => {
    if (!obj) return <></>;
    if (!obj.hasOwnProperty("idteacher")) return <></>;
    if (!obj.idteacher) return <></>;
    return (
      <Box mt={5}>
        <Typography variant="h3" mb={3}>
          {LL("TeacherInfo")}
        </Typography>
        <TeacherInfoBlock id={obj.idteacher} />
      </Box>
    );
  };

  // const processTeacher = () => {
  //     if (!obj) return <></>;
  //     if (!obj.hasOwnProperty('teacher')) return <></>;
  //     if (!obj.teacher) return <></>;

  //     return (
  //         <Box>
  //             {processAvatar()}
  //             <TeacherProcessNameCourse obj={obj.teacher} />
  //         </Box>
  //     )
  // }

  // const processAvatar = () => {
  //     if (!obj) return <></>;
  //     let name = ''
  //     let surname = ''
  //     if (!obj.hasOwnProperty('teacher')) return <></>
  //     if (!obj.teacher) return <></>
  //     if (obj.teacher.hasOwnProperty('name') && obj.teacher.name) name = obj.teacher.name;
  //     if (obj.teacher.hasOwnProperty('surname') && obj.teacher.surname) surname = obj.teacher.surname;

  //     return <MyAvatar obj={obj} name={name} surname={surname} sx={{ mr: 1 }} />
  // }

  const processCourseVideo = () => {
    if (!obj) return <></>;
    if (!obj.hasOwnProperty("video")) return <></>;
    if (!obj.video) return <></>;
    if (!obj.video.length) return <></>;

    return (
      <Box mt={5}>
        <Typography variant="h3" mb={3}>
          {LL("CourseVideo")}
        </Typography>
        <Stack
          direction={{ sm: "column", md: "row" }}
          spacing={2}
          alignItems="center"
        >
          <CourseVideo obj={obj} width={420} height={235} controls={true} />
          <Box className="courseVideoInfo">
            <InfoOutlinedIcon color="primary" sx={{ fontSize: "30px" }} />
            <Typography>{LL("CourseVideo description alert video")}</Typography>
          </Box>
        </Stack>
      </Box>
    );
  };

  const processCourseTestimonials = () => {
    if (!obj) return <></>;
    if (!obj.hasOwnProperty("id")) return <></>;
    if (!obj.id) return <></>;

    return (
      <Box mt={5}>
        <Typography variant="h3">{LL("Testimonials")}</Typography>
        <CourseTestimonials idCourse={obj.id} />
      </Box>
    );
  };
  const processOtherCoursesByTeacher = () => {
    if (!obj) return <></>;
    if (!obj.id) return <></>;
    if (!obj.hasOwnProperty("idteacher")) return <></>;
    if (!obj.idteacher) return <></>;
    if (!obj.hasOwnProperty("teacher")) return <></>;
    if (!obj.teacher) return <></>;
    

    return (
      <Box mb={5}>
        <CourseListByTeacher idTeacher={obj.idteacher} idCourse={obj.id} teacher={obj.teacher} />
      </Box>
    );
  };

  const processTopBlock = () => {
    if (!obj) return <></>;

    return (
      <CoursePageTopBlock
        obj={obj}
        loading={loading}
        setOpenEnrollmentDialog={setOpenEnrollmentDialog}
        openEnrollmentDialog={openEnrollmentDialog}
      />
    );
  };

  const processTeacherFAQ = () => {
    if (!obj) return <></>;
    if (!obj.id) return <></>;
    if (!obj.hasOwnProperty("idteacher")) return <></>;
    if (!obj.idteacher) return <></>;

    return <TeacherFAQBlock idCourse={obj.id} idTeacher={obj.idteacher} />;
  };

  const [openEnrollmentDialog, setOpenEnrollmentDialog] =
    useState<boolean>(false);

  return idCourse && !loading && obj ? (
    <Box className="detailCourse">
      {processTopBlock()}
      <Box className="container">
        <Grid container>
          <Grid item lg={9}>
            {processWhatWillYouLearnText()}
            {processDescription()}
            {processLessonsList()}
            {processRequirementsText()}
            {processTargetAudienceText()}
            {processCourseVideo()}
            {processTeacherInfo()}
            {processCourseTestimonials()}
            {processTeacherFAQ()}
            {processOtherCoursesByTeacher()}
          </Grid>
        </Grid>
      </Box>
      <NewsletterBlock currentRoute={currentRoute} />
      <CourseEnrollmentDialog
        open={openEnrollmentDialog}
        setOpen={setOpenEnrollmentDialog}
        course={obj}
      />
    </Box>
  ) : (
    <Skeleton />
  );
};

export { CoursePage };
