import Idto from "interfaces/idto.interface";

export class RegisterUserDto implements Idto {

  email?: string;
  password?: string;
  name?: string;
  surname?: string;
  birthday?: Date;
  phonenumber?: string;
  countrycode?: string;
  idtypephone?: string;
  idlanguage?: string;
  notificationtype1?: boolean;

  constructor(){
    this.countrycode = process.env.REACT_APP_COUNTRY_CODE;
  }
}
