import React from 'react';
import PageComponentProps from 'interfaces/pagecomponentprops.interface';
import { TopBar } from 'components/general/TopBar';
import Box from '@mui/material/Box';
import { useResource } from "hook/useResource";
import { useAuth } from 'hook/useAuth';
import { UserInterfaceBlock } from 'components/user/UserInterfaceBlock';

import { Types } from 'tools/types';
import { NotificationTopMenu } from 'components/notification/NotificationTopMenu';
import { TeacherRequestBlock } from 'components/teacherrequest/TeacherRequestBlock';

const HeaderTeacher: React.FC<PageComponentProps> = ({ currentRoute, ...props }) => {
    const { user, userInterface } = useAuth();
    const { isTeacher } = useResource();

    const processHeader = () => {
        if (userInterface !== Types.TEACHER_INTERFACE) return <></>;
        if (!isTeacher) return <></>;
        if (!user) return <></>


        return (
            <>
                <Box sx={{ ml: 'auto', display: 'flex', alignItems: 'center' }}>
                    <TeacherRequestBlock currentRoute={currentRoute} />
                    <NotificationTopMenu
                    currentRoute={currentRoute}
                    typeRecipient={Types.TYPE_NOTIFICATION_RECIPIENT_TEACHER}
                  />
                    <UserInterfaceBlock currentRoute={currentRoute} />
                    <TopBar currentRoute={currentRoute} />
                </Box>
            </>
        )
    }
    return processHeader();
};

export { HeaderTeacher };
