import { StudentDto } from 'dto/student/student.dto';
import Idto from 'interfaces/idto.interface';
import { CourseDto } from './course.dto';

export class CourseEnrollmentDto implements Idto {

  id?: string;
  idcourse?: string;
  idstudent?: string;
  status?: number;
  paymentstatus?: number;
  data?: number;
  course?: CourseDto;
  student?: StudentDto;

  numberoflessons?: number;
  numberoflessonspassed?: number;
  coursepercentage?: number;
}

export class PostCourseEnrollmentDto implements Idto {

  idcourse?: string;
  idstudent?: string;
  status?: number;
  paymentstatus?: number;
  data?: number;
  constructor(idcourse?: string, idstudent?: string) {
    this.idcourse = idcourse;
    this.idstudent = idstudent;
  }
}

export class StudentHasCourseDto implements Idto {

  result?: boolean;

  obj?: CourseEnrollmentDto |null;
}