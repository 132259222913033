import React, { useState } from "react";
import CloseIcon from '@mui/icons-material/Close';
import {
    Box,
    Drawer,
    IconButton,
    List,
    ListItem,
    ListItemAvatar,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Stack,
    Typography,
    Divider
} from '@mui/material';
import { LogoComp } from "components/general/LogoComp";
import { SecondDrawer } from "./SecondDrawer";
import { useAuth } from "hook/useAuth";
import { SignUpBlock } from "components/user/SignUpBlock";
import { SignInBlock } from "components/user/SignInBlock";
import { MyNavBt } from "components/general/MyNavBt";
import { useResource } from "hook/useResource";
import { IdCardIcon } from "components/general/IdCardIcon";
import { CourseCategoryMainDrawer } from "components/course/coursecategory/CourseCategoryMainDrawer";
import { CourseCategoryDto } from "dto/course/coursecategory.dto";
import { MyAvatar } from "../MyAvatar/MyAvatar";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { UserDrawer } from "./UserDrawer";

const drawerWidth = 300;
type MainDrawerProps = {
    open: boolean;
    setOpen: any;
    currentRoute: any;
}
const MainDrawer: React.FC<MainDrawerProps> = ({
    open,
    setOpen,
    currentRoute,
    ...props
}) => {

    const { user, usersettings } = useAuth();
    const { LL, isTeacher } = useResource();
    const [openSecondDrawer, setOpenSecondDrawer] = useState<boolean>(false);
    const [openUserDrawer, setOpenUserDrawer] = useState<boolean>(false);
    const [courseCategoryObject, setCourseCategoryObject] = useState<CourseCategoryDto | undefined | null>();
    const [courseCategoryId, setCourseCategoryId] = useState<string>('');

    const handleClose = () => {
        setOpenSecondDrawer(false)
        setOpenUserDrawer(false)
        setOpen(false);
    }
    const handleOpenUserDrawer = () => {
        setOpenUserDrawer(true);
    }
    const processCloseButton = () => {
        return (
            <IconButton onClick={handleClose}>
                <CloseIcon />
            </IconButton>
        )
    }

    const processLogo = () => {
        return (
            <LogoComp />
        )
    }


    const processCourseCategoryList = () => {
        return (
            <Box
                // onClick={handleClose}
                // onKeyDown={handleClose}
                role="presentation"
                sx={{ width: drawerWidth }}
                className="menuMobile"
            >
                <CourseCategoryMainDrawer
                    handleClose={handleClose}
                    setCourseCategoryId={setCourseCategoryId}
                    setCourseCategoryObject={setCourseCategoryObject}
                    setOpenSecondDrawer={setOpenSecondDrawer}
                    idparent=""

                    
                />
            </Box>
        )
    }
    const processSecondDrawer = () => {
        return (
            <SecondDrawer
                open={openSecondDrawer}
                setOpen={setOpenSecondDrawer}
                setOpenMainDrawer={setOpen}
                setCourseCategoryId={setCourseCategoryId}
                courseCategoryId={courseCategoryId}
                courseCategoryObject={courseCategoryObject}
                setCourseCategoryObject={setCourseCategoryObject}
                setOpenUserDrawer={setOpenUserDrawer}
            />
        )
    }

    const processUserDrawer = () => {
        return (<UserDrawer
            open={openUserDrawer}
            setOpen={setOpenUserDrawer}
            setOpenMainDrawer={setOpen}
            setOpenSecondDrawer={setOpenSecondDrawer}
        />)

    }

    const processBecomeTeacher = () => {
        if (isTeacher()) return (<></>)
        return (
            <Box
                onClick={handleClose}
                onKeyDown={handleClose}
                role="presentation"
                sx={{ width: drawerWidth }}
            >
                <List >
                    <ListItem
                        
                    >
                        <ListItemButton additionalOnClick={handleClose}
                        component={MyNavBt}
                        href={'/becometeacherpage'} 
                        className="toBeTeacher">

                                <IdCardIcon />

                                <Box ml={1}>{LL("bt_top_teach")}</Box>
                                <Box>{LL("bt_top_liveclass")}</Box>

                        </ListItemButton>

                    </ListItem>
                </List>
            </Box>
        )
    }


    const processUserName = () => {

        if (!usersettings) return <></>;
        if (!usersettings.hasOwnProperty('name')) return <></>;
        if (!usersettings.hasOwnProperty('surname')) return <></>;
        if (!usersettings.name) return <></>;
        if (!usersettings.surname) return <></>;

        return (
            <Typography color="textPrimary">{usersettings.name} {usersettings.surname}</Typography>
        )
    }

    const processAvatar = () => {
        if (!usersettings) return <></>;
        if (!usersettings.hasOwnProperty('name')) return <></>;
        if (!usersettings.hasOwnProperty('surname')) return <></>;
        if (!usersettings.name) return <></>;
        if (!usersettings.surname) return <></>;

        return <MyAvatar obj={usersettings} name={usersettings.name} surname={usersettings.surname} sx={{ mr: 1 }} />
    }

    const processAuthUser = () => {
        if (!user) return <></>;

        return (
            <Box
                onClick={handleOpenUserDrawer}
                role="presentation"
                sx={{ width: drawerWidth }}>
                <List>
                    <ListItem>
                        <ListItemButton>
                            <ListItemAvatar>
                                {processAvatar()}
                            </ListItemAvatar>
                            <ListItemText>
                                <Stack
                                    direction='column'
                                    justifyContent="center"
                                    alignItems="flex-start"
                                >
                                    <Typography color="textPrimary">{processUserName()}</Typography>
                                    <Typography color="textSecondary" className="subtextBage">{user.email}</Typography>
                                </Stack>
                            </ListItemText>
                            <ListItemIcon sx={{minWidth:'15px'}}>
                                <ArrowForwardIosIcon className="iconRight"/>
                            </ListItemIcon>
                        </ListItemButton>
                    </ListItem>
                </List>
            </Box>
        )

    }

    const processUserActions = () => {
        if (!user) {
            return (
                <Box
                    onClick={handleClose}
                    onKeyDown={handleClose}
                    role="presentation"
                    sx={{ width: drawerWidth }}
                >
                    <List >
                        <ListItem
                            
                        >
                            <ListItemButton
                            additionalOnClick={handleClose}
                            component={MyNavBt}
                            href={'/signup'} 
                            className="btn btnBorder">
                                {LL("BT_SignUpTop")}
                            </ListItemButton>

                        </ListItem>
                        <ListItem
                            
                        >
                            <ListItemButton
                            additionalOnClick={handleClose}
                            component={MyNavBt}
                            href={'/login'}
                            className="btn btnContainer"
                            >
                                {LL("BT_SignInTop")}
                            </ListItemButton>
                        </ListItem>
                    </List>
                </Box>
            )
        }
        return (processAuthUser())
    }

    return (
        <Box>
            <Drawer
                open={open}
                onClose={handleClose} 
                className='mobileMenu'
            >
                <Stack
                    direction='row'
                    justifyContent="space-between"
                >
                    {processLogo()}
                    {processCloseButton()}
                </Stack>
                <Divider />
                {processUserActions()}
                <Divider />
                {processCourseCategoryList()}
                {processSecondDrawer()}
                {processUserDrawer()}
                {processBecomeTeacher()}
            </Drawer>
        </Box>
    );
};

export { MainDrawer };
