
import Idto from 'interfaces/idto.interface';

export default class ModifyPasswordDto implements Idto {
  
  email?: string;
  currentpassword?: string;
  newpassword?: string;
  
}
