
import { GalleryDto } from 'dto/gallery/gallery.dto';
import Idto from 'interfaces/idto.interface';

export class PostPageDto implements Idto {
    
    type?: number;  
    status?: number;
    idlanguage?: string;
    idpage?: string;
    name?: string;
    url?: string;
    title?: string;
    keymeta?: string;
    descriptionmeta?: string;
    content?: string;
    idgallery?: string;
    idattachment?: string;
    idvideo?: string;
    status_name?: string;
    type_name?: string;
    attachment?: any
}


export class PageDto implements Idto {
    
    id?: string;
    type?: number;  
    status?: number;
    idlanguage?: string;
    idpage?: string;
    name?: string;
    url?: string;
    title?: string;
    keymeta?: string;
    descriptionmeta?: string;
    content?: string;
    idgallery?: string;
    idattachment?: string;
    idvideo?: string;
    language?: any;
    status_name?: string;
    type_name?: string;
    attachment?: any
    gallery?: Array<GalleryDto>;
}