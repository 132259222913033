import React, { useEffect, useState } from "react";
import PageComponentProps from "interfaces/pagecomponentprops.interface";
import { useResource } from "hook/useResource";
import { logger } from "hoc/logger";

import { MyNavBt } from "components/general/MyNavBt";
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import { ComponentCommonTools } from 'tools/componentcommontools'
import Grid from '@mui/material/Grid';
import StudentService from "services/student/student.service";
import { StudentDto } from "dto/student/student.dto";
import { useAuth } from "hook/useAuth";
import MyCKEditor from 'components/elements/ckeditor/MyCKEditor';
import { StudyTypeSelect } from 'components/elements/Select/StudyTypeSelect';
import { MyButton } from 'components/general/MyButton';
import ValidateTools from 'tools/validate.tools';
import MyTextField from 'components/form/mytextfield';
import { CommonTools } from "tools/commontools";
import { CircularLoading } from 'components/general/CircularLoading';

import MyCheckboxField from "components/form/mycheckboxfield";

const studentService = new StudentService();

const EditStudentNotification: React.FC<PageComponentProps> = ({
    currentRoute,
    ...props
}) => {
    const { LL } = useResource();
    const { user } = useAuth();

    const [loading, setLoading] = useState<boolean>(true);
    const [obj, setObj] = useState<StudentDto | undefined>();
    const _id = user !== undefined ? user.id : '';
    const [id, setId] = useState<string>(_id);
    const [isFormDisabled, setIsFormDisabled] = useState(false);


    const [validators, setValidators] = useState<any>();
    const [vresults, setVResults] = useState<any>(false)

    const validateField = (field: any, value: any, v?: any) => {

        v = (v) ? v : JSON.parse(JSON.stringify(vresults));
        if (v === false) v = {};
        v[field] = ValidateTools.validate(field, value, validators, vresults);

        setVResults(v);
        setIsFormDisabled(ValidateTools.vForm(v, field));
        return v;
    };

    const loadObject = (obj: any) => {
        if (!obj) return;
        if (!obj.err) {
            const object = obj.obj ? obj.obj : [];
            setObj(object);
        }
    };

    const getObject = () => {
        setLoading(true)
        if (id !== undefined && id !== '') studentService.getByUserId(id, loadObject, {});
    };

    const checkIsLoading = () => {
        let load: boolean = false;
        if (obj === undefined) load = true;
        setLoading(load);
    };

    useEffect(() => {
        getObject();
    }, [currentRoute]);

    useEffect(() => {
        validateAll();
        checkIsLoading();
    }, [obj]);

    useEffect(() => {

        if (user !== undefined) {
            setId(user.id)
        }
    }, [user])

    const validateAll = () => {
        if (!obj) return;
        // if (!validators) return;
        // if (vresults) return;

        obj.notificationtype1 = obj.notificationtype1 !== undefined ? obj.notificationtype1 : false;
        obj.notificationtype2 = obj.notificationtype2 !== undefined? obj.notificationtype2 : false;
        obj.notificationtype3 = obj.notificationtype3 !== undefined ? obj.notificationtype3 : false;

        setObj(obj);
    };


    const setCheckNotificationType1 = (value: any) => {
        let t = JSON.parse(JSON.stringify(obj));
        t['notificationtype1'] = value;
        setObj(t);

    };
    const setCheckNotificationType2 = (value: any) => {
        let t = JSON.parse(JSON.stringify(obj));
        t['notificationtype2'] = value;
        setObj(t);

    };
    const setCheckNotificationType3 = (value: any) => {
        let t = JSON.parse(JSON.stringify(obj));
        t['notificationtype3'] = value;
        setObj(t);

    };
    
    const handleSubmit = (event: any) => {
        event.preventDefault();
        if (isFormDisabled) return false;
        if (id !== undefined && obj) {
            obj.iduser = id;
            setLoading(true);
            studentService.addNotification(CommonTools.handleSuccessAddAndEditStayOnPage, { setLoading }, obj);
        }
    };

    return user !== undefined && !loading && obj ? (
        <>
           
                <form onSubmit={handleSubmit} >
                  <Box  p={3} maxWidth='md' mx={'auto'} justifyContent='center' display='flex' flexDirection='column'>  
                    <Box sx={{ mt: 3 }}  className="likeCard">
                        <MyCheckboxField
                            tabIndex={0}
                            id="notificationtype1"
                            name="notificationtype1"
                            _label={LL("notificationtype1")}
                            setObj={setCheckNotificationType1}
                            checked={obj.notificationtype1}
                            color="primary"
                            size="small"
                            _vresults={undefined}
                        />
                    </Box>

                    <Box sx={{ mt: 3 }} className="likeCard">
                        <MyCheckboxField
                            tabIndex={1}
                            id="notificationtype2"
                            name="notificationtype2"
                            _label={LL("notificationtype2")}
                            setObj={setCheckNotificationType2}
                            checked={obj.notificationtype2}
                            color="primary"
                            size="small"
                            _vresults={undefined}
                        />
                    </Box>

                    <Box sx={{ mt: 3 }}  className="likeCard">
                        <MyCheckboxField
                            tabIndex={2}
                            id="notificationtype3"
                            name="notificationtype3"
                            _label={LL("notificationtype3")}
                            setObj={setCheckNotificationType3}
                            checked={obj.notificationtype3}
                            color="primary"
                            size="small"
                            _vresults={undefined}
                        />
                    </Box>
                    <Box textAlign='center' mt={4}>
                    <MyButton
                        tabIndex={3}
                        disabled={isFormDisabled}
                        color="primary"
                        className="btn"
                        variant="contained"
                        size="large"
                        type="submit"

                        children={LL("Bt_Submit")}
                    /> 
                    
                    </Box></Box>
                </form>
           

        </>
    ) : (
        <CircularLoading />
    );
};

export { EditStudentNotification };
