import React, { useState, useEffect } from "react";
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Skeleton from '@mui/material/Skeleton';
import { CourseDto } from 'dto/course/course.dto'
import { MyButton } from "components/general/MyButton";
import { CommonTools } from 'tools/commontools';
import { ComponentCommonTools } from 'tools/componentcommontools'
import { useResource } from "hook/useResource";
import { RouteTools } from "tools/routetools";
import {
    Box, Stack, Divider, Grid,
    Link, IconButton, Menu, MenuItem,
    ListItemIcon, ListItemText
} from "@mui/material";
import { TeacherProcessNameCourse } from "components/teacher/TeacherProcessNameCourse";
import { logger } from "hoc/logger";

import { MyNavBt } from "components/general/MyNavBt";

import MoreVertIcon from '@mui/icons-material/MoreVert';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import CourseService from "services/course/course.service";
import { useMessage } from "hook/useMessage";
import { useCookies } from "react-cookie";
import DateTools from "tools/date.tools";
import { StatusChipCourse } from "components/course/course/StatusChipCourse";
import { TypeCourseCardChip } from "components/course/course/TypeCourseCardChip";
import { Status } from "tools/status";
import { CourseEnrollmentStatus } from "components/course/courseenrollment/CourseEnrollmentStatus";
import KeyboardDoubleArrowRightOutlinedIcon from '@mui/icons-material/KeyboardDoubleArrowRightOutlined';
type StudentCourseCardProps = {
    obj: CourseDto;
    loading: boolean;
    loadAction?: any
}

const courseService = new CourseService();
const StudentCourseCard: React.FC<StudentCourseCardProps> = ({ obj, loading, loadAction, ...props }) => {

    const { LL } = useResource();
    const [imgUrl, setImgUrl] = useState<string>("");
    const [fullUrl, setFullUrl] = useState<string>("");
    const { registerDialog } = useMessage();
    const [cookies, setCookies] = useCookies()



    const prepareGallery = () => {
        if (!obj) return;
        if (!obj.hasOwnProperty('gallery')) return;
        if (!obj.gallery) return;
        if (obj.gallery.length === 0) return;
        const galleryObj = CommonTools.getGalleryObjectLogo(obj.gallery);
        if (!galleryObj) return;
        CommonTools.setFileUrlsGallery(galleryObj, setImgUrl, setFullUrl, 310, 180);
    }

    useEffect(() => {
        prepareGallery();
    }, [obj])

    const processImage = () => {
        if (!obj) return <></>;
        if (!imgUrl) return <></>;

        return (
            <CardMedia
                sx={{ height: 180 }}
                image={imgUrl}
                title='' >
                {processCourseType()}
                {processCourseStatus()}
            </CardMedia>
        )
    }

    const processCourseType = () => {
        if (!obj) return <></>;
        if (!obj.hasOwnProperty('coursesettings')) return <></>;
        if (!obj.coursesettings) return <></>;
        return <Box p={1}><TypeCourseCardChip obj={obj.coursesettings} width={14} height={14} /></Box>
    }


    const processCourseStatus = () => {
        if (!obj) return <></>;
        if (!obj.hasOwnProperty('status')) return <></>;
        if (!obj.status) return <></>;
        return <Box p={1}><StatusChipCourse obj={obj} /></Box>
    }

    const processCoursePrice = () => {
        if (!obj) return <></>;
        if (!obj.hasOwnProperty('courseenrollments')) return <></>;
        if (!obj.courseenrollments) return <></>;
        if (!obj.courseenrollments.paymentstatus) return <></>;
        if (obj.courseenrollments.paymentstatus === Status.PAYMENT_PAID) return <></>;
        if (!obj.hasOwnProperty('courseprice')) return <></>;
        if (!obj.courseprice) return <></>;
        if (!obj.courseprice.hasOwnProperty('totalprice')) return <></>;
        if (!obj.courseprice.totalprice) return <></>;
        if (!obj.courseprice.hasOwnProperty('priceperlesson')) return <></>;
        if (!obj.courseprice.priceperlesson) return <></>;
        if (!obj.courseprice.hasOwnProperty('currency_identifier')) return <></>;
        if (!obj.courseprice.currency_identifier) return <></>;
        return (
            <Stack direction='column'>
                <Stack direction='row' spacing={1}>
                    <Typography className="priceTotal">
                        <span>{ComponentCommonTools.processObjectFieldOfObject(obj, 'courseprice', 'totalprice')}</span>
                        {ComponentCommonTools.processObjectFieldOfObject(obj, 'courseprice', 'currency_identifier')}
                    </Typography>
                </Stack >

                <Stack direction='row' className="pricePer">
                    <Typography component='span'>
                        <span>{ComponentCommonTools.processObjectFieldOfObject(obj, 'courseprice', 'priceperlesson')}</span>
                        {ComponentCommonTools.processObjectFieldOfObject(obj, 'courseprice', 'currency_identifier')}
                    </Typography>
                    <Typography component='span'>/</Typography>
                    <Typography component='span'>
                        {LL('per_lesson')}
                    </Typography>
                </Stack >
            </Stack >
        )
    }

    const processCourseNextLesson = () => {
        if (!obj) return <></>;
        if (!obj.hasOwnProperty('nextlessonstartdate')) return <></>;
        if (!obj.nextlessonstartdate) return <></>;
        return (
            <Box>
                <Typography component='span' className="lp">
                    {LL('Next_lesson')}
                </Typography>
                <Typography p={1} component='span' className="rp">
                    {DateTools.displayTimestampInDateAndHHMM(obj.nextlessonstartdate)}
                </Typography>
            </Box>
        )
    }



    const processCourseDetails = () => {
        if (!obj) return <></>;

        return (
            <Box>
                <Stack direction='row'
                    justifyContent="space-between"
                    alignItems="flex-start"
                    spacing={2}>
                    <Stack direction='column' className="detailsGeneral">
                        <Box  >
                            <Typography component='span' className="lp">
                                {LL('Language course')}
                            </Typography>
                            <Typography p={1} component='span' className="rp">
                                {ComponentCommonTools.processObjectFieldOfObject(obj, 'language', 'name')}
                            </Typography>
                        </Box>
                        <Box >
                            <Typography component='span' className="lp">
                                {LL('CourseDuration')}
                            </Typography>
                            <Typography p={1} component='span' className="rp">
                                {ComponentCommonTools.processObjectFieldTimeStamp(obj, 'courseduration')}
                            </Typography>
                        </Box>
                        <Box >
                            <Typography component='span' className="lp">
                                {LL('NumberOfLessons')}
                            </Typography>
                            <Typography p={1} component='span' className="rp">
                                {ComponentCommonTools.processObjectField(obj, 'numberoflessons')}
                            </Typography>
                        </Box>
                        {processNumberOfLessonsRemaining()}
                    </Stack>
                </Stack>

            </Box>
        )
    }

    const processNumberOfLessonsRemaining = () => {
        if (!obj) return <></>;
        if (!obj.hasOwnProperty('numberoflessonsremaining')) return <></>;
        if (!obj.numberoflessonsremaining) return <></>;
        return (
            <Box>
                <Typography component='span' className="lp">
                    {LL('NumberOfLessonsRemaining')}
                </Typography>
                <Typography p={1} component='span' className="rp">
                    {ComponentCommonTools.processObjectField(obj, 'numberoflessonsremaining')}
                </Typography>
            </Box>
        )
    }


  
   
    const processName = () => {

        if (!obj) return <></>;
        return (<Typography className="name">
            {ComponentCommonTools.processObjectField(obj, 'name')}
        </Typography>)
    }

    const processButtonGoToCourse = () => {
        if (!obj) return <></>;
        if (!obj.hasOwnProperty('courseenrollments')) return <></>;
        if (!obj.courseenrollments) return <></>;
        if (!obj.courseenrollments.hasOwnProperty('id')) return <></>;
        if (!obj.courseenrollments.id) return <></>;
        const url = '/profile/courseenrollment/' + obj.courseenrollments.id;
        return (<MyNavBt href={url} className="goToClass">
            <KeyboardDoubleArrowRightOutlinedIcon  />
        </MyNavBt>)
    }
    const processStudentCourseCard = () => {
        if (!obj) return <></>;

        return (
            <Box py={1}>
                <Card className="courseCardH teacherCards">
                    <Grid container className="content" spacing={2} alignItems='center'>
                        <Grid item lg={2} sm={12}>{processImage()}</Grid>
                        <Grid item lg={5} sm={12}>
                            {processName()}

                            <Stack
                                direction='row'
                                justifyContent="space-between"
                                alignItems="center"
                                alignContent="center"
                                className="buttons"
                                mt={3}
                                spacing={2}>
                                {processCourseStatus()}
                                {processCoursePrice()}
                            </Stack>
                        </Grid>
                        <Grid item lg={4} sm={12} className="dtP">
                            {processCourseNextLesson()}
                            {processCourseDetails()}

                        </Grid>
                        <Grid  item lg={1} sm={12} p={1} justifyContent='flex-end' display='flex'>
                            {processButtonGoToCourse()}
                        </Grid>
                    </Grid>

                    
                    

                </Card>
            </Box>

        )
    }

    const processEnrollment = () => {
        if (!obj) return <></>;
        if (!obj.hasOwnProperty('courseenrollments')) return <></>;
        if (!obj.courseenrollments) return <></>;
        return (<CourseEnrollmentStatus obj={obj.courseenrollments} />)
    }
    const processCard = () => {
        if (!obj) return <></>;
        if (!obj.hasOwnProperty('courseenrollments')) return <></>;
        if (!obj.courseenrollments) return <></>;
        if (!obj.courseenrollments.status) return <></>;
        if (
            obj.courseenrollments.status === Status.ENR_COURSE_READY ||
            obj.courseenrollments.status === Status.ACTIVE ||
            obj.courseenrollments.status === Status.ENT_COURSE_STARTED ||
            obj.courseenrollments.status === Status.ENR_ARCHIVED ||
            obj.courseenrollments.status === Status.ENR_NEED_END_TEST ||
            obj.courseenrollments.status === Status.ENR_NEED_CERTIFICATE ||
            obj.courseenrollments.status === Status.ENR_COURSE_FINISHED ||
            obj.courseenrollments.status === Status.ENR_PASSED_END_TEST ||
            obj.courseenrollments.status === Status.ENR_WITH_CERTIFICATE ||
            obj.courseenrollments.status === Status.ENR_NEED_START_TEST 
        ) {
            return processStudentCourseCard();
        } else {
            return (
                <Box py={1}>
                    <Card className="courseCardH teacherCards">
                        <Grid container className="content" spacing={2} alignItems='center'>
                            <Grid item lg={2} sm={12}>{processImage()}</Grid>
                            <Grid item lg={5} sm={12}>
                                {processName()}
                                <Stack
                                    direction='row'
                                    justifyContent="space-between"
                                    alignItems="center"
                                    alignContent="center"
                                    className="buttons"
                                    mt={3}
                                    spacing={2}>
                                    {processCourseStatus()}
                                    {processCoursePrice()}
                                </Stack>
                            </Grid>
                            <Grid item lg={4} sm={12} className="dtP">
                                {processEnrollment()}
                            </Grid>
                            <Grid  item lg={1} sm={12} p={1}  justifyContent='flex-end' display='flex'>{processButtonGoToCourse()}</Grid>
                        </Grid>
                    </Card>
                </Box>
            )
        }

    }

    return loading ? <Skeleton variant="rectangular" /> : processCard();
};

export { StudentCourseCard };
