import React, { useEffect, useState } from "react";

import { useResource } from "hook/useResource";
import { logger } from "hoc/logger";

import { Chip } from '@mui/material';



import { Status } from "tools/status";
import { TeacherDto } from "dto/teacher/teacher.dto";

type TypeStatusCardChipProps = {
    obj: TeacherDto
}


const StatusChipTeacher: React.FC<TypeStatusCardChipProps> = ({
    obj,
    ...props
}) => {

    const processColor = (value: number) => {
        switch (value) {
            case Status.TEACHER_PRO:
                return "warning";
            case Status.TEACHER_UNVERIFIED:
                return "error";
            case Status.TEACHER_VERIFIED:
                return "success";
            default:
                return "primary";
        }

    }
    const processChip = () => {
        if (!obj) return <></>;
        if (!obj.hasOwnProperty('status')) return <></>;
        if (!obj.status) return <></>;
        
        const color = processColor(obj.status);


        return (
            <Chip label={Status.LL(obj.status)} {...props} size="small" className="chipTop" color={color} />
        );
    }


    return (<>{processChip()}</>);
};

export { StatusChipTeacher };
