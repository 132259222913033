import React, { useEffect, useState } from "react";
import { useResource } from "hook/useResource";
import { logger } from "hoc/logger";
import { MyNavBt } from "components/general/MyNavBt";
import {
    Box,
    Card,
    CardActions,
    CardContent,
    Typography,
    Stack,
    Link,
    Accordion,
    AccordionDetails,
    AccordionSummary
} from "@mui/material";
import { CommonTools } from "tools/commontools";
import { ComponentCommonTools } from 'tools/componentcommontools'
import { TestResultAnswerDto } from "dto/course/testresultanswer.dto";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

type TestResultAnswerItemProps = {
    obj: TestResultAnswerDto;
}

const TestResultAnswerItem: React.FC<TestResultAnswerItemProps> = ({
    obj,

    ...props
}) => {
    const { LL } = useResource();



    const processAnswerInfo = () => {
        if (!obj) return <></>;
        if (!obj.hasOwnProperty('answer')) return <></>;
        if (!obj.answer) return <></>;

        return (
            <Box>
                <Stack direction="row" spacing={2}>
                    <Typography sx={{fontWeight:'bold'}}>
                        {LL('Answer')}
                    </Typography>
                    <Typography>
                        {ComponentCommonTools.processObjectFieldOfObject(obj, 'answer', 'name')}
                    </Typography>
                </Stack>
            </Box>)
    }

    const processAnswerResultInfo = () => {
        if (!obj) return <></>;


        return (
            <Box>

                <Stack direction="row" spacing={2}>
                    <Typography sx={{fontWeight:'bold'}}>
                        {LL('score')}
                    </Typography>
                    <Typography>
                        {ComponentCommonTools.processObjectField(obj, 'score')}
                    </Typography>
                </Stack>



            </Box>)
    }
    const processItem = () => {
        if (!obj) return <></>;
        let color = '#FFD6D5'
        if (obj.hasOwnProperty('iscorrect') && obj.iscorrect) color = 'lightgreen'

        return (
            <Box sx={{ bgcolor: color }}
            width={'100%'}
            
            >
                <Stack
                    direction={'row'}
                    justifyContent="space-between"
                    alignItems="center"
                    spacing={2}
                >
                    {processAnswerInfo()}
                    {processAnswerResultInfo()}
                </Stack>
            </Box>
        )
    }



    return processItem()
}

export { TestResultAnswerItem }