import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useResource } from "hook/useResource";
import { logger } from "hoc/logger";
import AdvertisementService from "services/advertisement/advertisement.service";
import PageComponentProps from "interfaces/pagecomponentprops.interface";
import { AdvertisementDto } from "dto/advertisement/advertisement.dto";
import { RouteTools } from "tools/routetools";
import RequestListDTO from "dto/app/requestlist.dto";
import { Status } from "tools/status";
import RequestFilterDTO from "dto/app/requestfilter.dto";
import ResultListDTO from "dto/app/resultlist.dto";
import { Types } from "tools/types";
import { ComponentCommonTools } from 'tools/componentcommontools'
import { Loading } from "components/general/Loading";
import Skeleton from '@mui/material/Skeleton';
import { Box } from "@mui/material";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { MyNavBt } from "components/general/MyNavBt";
import { CommonTools } from "tools/commontools";
import Grid from '@mui/material/Grid';
import Typography from "@mui/material/Typography";
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';

const advertisementService = new AdvertisementService();

type BannerItemSpecialHomeSection1Props = {
    obj: AdvertisementDto;
}
const AdvertisementBannerItemHomeBenefits: React.FC<BannerItemSpecialHomeSection1Props> = ({
    obj,
    ...props
}) => {

    const [imgUrl, setImgUrl] = useState<string>("");
    const [fullUrl, setFullUrl] = useState<string>("");
    const width = 50;
    const height = 50;

    const { LL } = useResource();

    const prepareGallery = () => {
        if (!obj) return;

        if (!obj.hasOwnProperty('gallery')) return;
        if (!obj.gallery) return;
        if (obj.gallery.length === 0) return;
        const galleryObj = CommonTools.getGalleryObjectLogo(obj.gallery);
        if (!galleryObj) return;
        CommonTools.setFileUrlsGallery(galleryObj, setImgUrl, setFullUrl, width, height);
    }

    useEffect(() => {
        prepareGallery();
    }, [obj])



    const processImage = () => {
        if (!imgUrl) return (<></>)
        return (<>
            {ComponentCommonTools.getFileBlock(imgUrl, width, height)}
        </>
        )
    }

    // if (obj.type == Types.ADVERTISEMENT_TYPE_SPECIAL) {
    //     return (
    //         <Box className="container">
    //             <Grid container spacing={2}>
    //                 <Grid item md={4} sm={12}>{processImage()}</Grid>
    //                 <Grid item md={6} sm={12}>
    //                     {processDetail(obj)}
    //                     {processButton()}
    //                 </Grid>
    //             </Grid>
    //         </Box>
    //     );
    // }

    const processDetail = (obj: AdvertisementDto) => {
        if (!obj) return <></>;
        return (

            <>
                <Typography variant="h5">
                    {ComponentCommonTools.processObjectField(obj, 'name')}
                </Typography>
                <Typography>
                    {ComponentCommonTools.processObjectField(obj, 'description')}
                </Typography>
            </>

        )
    }

    const processWithFullUrl = (obj: AdvertisementDto) => {

        if (!obj) return <></>;

        if (!imgUrl) {

            return (
                <Box className="bennefitsItem">
                    <MyNavBt href={obj.fullurl}>
                        {processDetail(obj)}
                    </MyNavBt>
                </Box>
            )
        }

        return (
            
                <Stack direction="row" spacing={2} className="bennefitsItem" sx={{alignItems:'center'}}>
                    {(processImage())}
                    <Box>{processDetail(obj)}</Box>
                </Stack>

        )
    }

    const processItem = () => {
        if (!obj) return <></>;

        if (obj.hasOwnProperty('fullurl') && obj.fullurl) {
            return (
                <MyNavBt href={obj.fullurl} >
                    {processWithFullUrl(obj)}
                </MyNavBt>
            );
        }

        return processWithFullUrl(obj)
    }

    return processItem();
};

export { AdvertisementBannerItemHomeBenefits };
