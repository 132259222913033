import React, { useEffect, useState } from "react";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { logger } from "hoc/logger";
import { useResource } from "hook/useResource";
import TypeDisciplineService from "services/nomenclature/typediscipline.service";
import { MySelect } from "components/elements/Select/MySelect";
import RequestListDTO from 'dto/app/requestlist.dto';
import SelectOptions from "dto/app/selectoptions.dto";
import ResultListDTO from 'dto/app/resultlist.dto';
import { PopulateTypeDto } from 'dto/nomenclature/populatetype.dto';
import { CommonTools } from 'tools/commontools';
import { Skeleton } from "@mui/material";
const typeDisciplineService = new TypeDisciplineService();

type DisciplineTypeSelectProps = {
    setObj: ((field: any, value: any) => void);
    field: any;
    obj: any;
    _defaultValue?: any;
    [key: string]: any; // for the rest of the props which we do not have types for
}

const DisciplineTypeSelect: React.FC<DisciplineTypeSelectProps> = ({
    setObj,
    field,
    obj,
    _defaultValue,
    ...props
}) => {
    const { LL } = useResource();
    const defaultValue = _defaultValue != undefined ? _defaultValue : "";
    const label = "TypeDiscipline";
    const [options, setOptions] = useState<Array<SelectOptions>>(new Array<SelectOptions>());
    const [loading, setLoading] = useState<boolean>(true);
    const [value, setValue] = useState<string>(defaultValue);
    const [data, setData] = useState<Array<PopulateTypeDto> | undefined>();
    const onPage = 2000;

    const [reqList, setReqList] = useState<RequestListDTO>(typeDisciplineService.getDefaultRequestListSelect(onPage));

    const loadObjects = (data: ResultListDTO) => {
        setData(data.objects);
    };

    const getReqList = () => {
        const reqList = typeDisciplineService.getDefaultRequestListSelect(onPage)
        setReqList(reqList);
    };

    const getList = () => {
        setLoading(true);
        typeDisciplineService.getList(loadObjects, {}, reqList);
    };

    const processOptions = () => {

        if (data !== undefined) {
            const options: Array<SelectOptions> = data.map((item) => {
                const obj = {
                    label: item.name,
                    value: item.id
                }
                return obj;
            })
            setOptions(options);
        }

    }

    // useEffect(() => {
    //     getReqList();
    // }, []);

    useEffect(() => {
        setLoading(true);
        if (CommonTools.atLeastOneFieldDefined(reqList)) {
            getList();
        }
    }, [reqList]);


    useEffect(() => {
        if (data !== undefined) processOptions()
        checkIsLoading();
    }, [options, data]);

    useEffect(() => {
        if(loading) return;
        setObj(field, value);
    }, [value]);


    const checkIsLoading = () => {
        let load: boolean = false;
        if (data === undefined) load = true;
        if (options === undefined) load = true;
        // if (value === undefined) load = true;
        setLoading(load);
    };

    return !loading ? (
        <MySelect
            options={options}
            _label={label}
            setValue={setValue}
            value={value}
            {...props}
        />
    ) : (<Skeleton variant="rectangular" height={50} />);
};

export { DisciplineTypeSelect };
