import React from "react";
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Skeleton from '@mui/material/Skeleton';
import { CourseDto } from 'dto/course/course.dto'
import { MyButton } from "components/general/MyButton";
import { CommonTools } from 'tools/commontools';
import { ComponentCommonTools } from 'tools/componentcommontools'
import { useResource } from "hook/useResource";
import { RouteTools } from "tools/routetools";
import { Box, Grid, Stack } from "@mui/material";
import { TeacherCard } from "components/teacher/TeacherCard";
import { logger } from "hoc/logger";
import { CourseCharacteristics } from "components/course/course/CourseCharacteristics";
import { TeacherProcessNameCourse } from "components/teacher/TeacherProcessNameCourse";
import { MyAvatar } from "components/elements/MyAvatar/MyAvatar";
import BreadcrumbChildrenDto from "dto/app/breadcrumbchildren.dto";
import { MyBreadcrumbs } from "components/elements/MyBreadcrumbs/MyBreadcrumbs";
import { MyBreadcrumbsCourse } from "components/elements/MyBreadcrumbs/MyBreadcrumbsCourse";
import { HtmlTag } from "components/general/HtmlTag";
import { ShareDialog } from "components/elements/MyDialog/ShareDialog";
import IosShareOutlinedIcon from '@mui/icons-material/IosShareOutlined';
import { ObjectGallery } from "components/elements/media/ObjectGallery";
import { WishlistIconChecker } from "components/wishlist/WishlistIconChecker";
import { log } from "console";
type CoursePageTopBlockProps = {
    obj: CourseDto;
    loading: boolean;
    setOpenEnrollmentDialog: any;
    openEnrollmentDialog: boolean;
}

const CoursePageTopBlock: React.FC<CoursePageTopBlockProps> = ({ 
    obj, 
    loading, 
    setOpenEnrollmentDialog,
    openEnrollmentDialog,
    
    ...props }) => {

    const { LL } = useResource();

    const processCourseTeacher = () => {
        if (!obj) return <></>;
        if (!obj.hasOwnProperty('teacher')) return <></>;
        if (!obj.teacher) return <></>;

        return (
            <Box className="created">
                <Box mb={2}>{LL('Creat de:')}</Box>
                {processTeacher()}
            </Box>)
    }

    const processWishlistButton = () => {
        if (!obj) return <></>;
        if (!obj.hasOwnProperty('id')) return <></>;
        if (!obj.id) return <></>;

        return (

            <WishlistIconChecker idCourse={obj.id} />

        )
    }

    const processTeacher = () => {
        if (!obj) return <></>;
        if (!obj.hasOwnProperty('teacher')) return <></>;
        if (!obj.teacher) return <></>;

        return (
            <Stack mb={5} direction="row" alignItems='center' spacing={2}>
                {processAvatar()}
                <TeacherProcessNameCourse obj={obj.teacher} />
            </Stack>
        )
    }

    const processAvatar = () => {
        if (!obj) return <></>;
        let name = ''
        let surname = ''
        if (!obj.hasOwnProperty('teacher')) return <></>
        if (!obj.teacher) return <></>
        if (!obj.teacher.hasOwnProperty('user')) return <></>
        if (!obj.teacher.user) return <></>
        if (!obj.teacher.user.hasOwnProperty('usersettings')) return <></>
        if (!obj.teacher.user.usersettings) return <></>
        if (!obj.teacher.user.usersettings[0]) return <></>
        if (obj.teacher.hasOwnProperty('name') && obj.teacher.name) name = obj.teacher.name;
        if (obj.teacher.hasOwnProperty('surname') && obj.teacher.surname) surname = obj.teacher.surname;
        const localObj = obj.teacher.user.usersettings[0];

        return <MyAvatar obj={localObj} name={name} surname={surname} className='avatar' sx={{ mr: 1 }} />
    }
    const processCourseCharacteristics = () => {
        if (!obj) return <></>;
        return (
            <CourseCharacteristics 
            obj={obj} 
            loading={loading} 
            setOpenEnrollmentDialog={setOpenEnrollmentDialog}
            openEnrollmentDialog={openEnrollmentDialog}
            />
        )
    }

    const processBreadcrumbChildren = (): BreadcrumbChildrenDto[] => {
        const createHomeBreadcrumb = (): BreadcrumbChildrenDto => {
            const breadcrumb = new BreadcrumbChildrenDto();
            breadcrumb.url = '/';
            breadcrumb.label = LL('crumb_homepage');
            return breadcrumb;
        }

        if (!obj
            || !obj.hasOwnProperty('linkcoursecategory')
            || !obj.linkcoursecategory
            || !Array.isArray(obj.linkcoursecategory)) {
            return [createHomeBreadcrumb()];
        }

        let validItems = obj.linkcoursecategory.filter(item =>
            item &&
            item.hasOwnProperty('coursecategory') &&
            item.coursecategory &&
            item.coursecategory.hasOwnProperty('name') &&
            item.coursecategory.name &&
            item.coursecategory.hasOwnProperty('fullurl') &&
            item.coursecategory.fullurl
        );

        if (validItems.length === 0) {
            return [createHomeBreadcrumb()];
        }

        let arrayOfLinkCourseCategory = validItems.map(item => {
            const breadcrumbChildrenItem = new BreadcrumbChildrenDto();
            breadcrumbChildrenItem.url = item.coursecategory.fullurl;
            breadcrumbChildrenItem.label = item.coursecategory.name;
            return breadcrumbChildrenItem;
        });

        arrayOfLinkCourseCategory.unshift(createHomeBreadcrumb());

        return arrayOfLinkCourseCategory;
    }

    const processBreadcrumb = () => {
        if (!obj) return <></>;
        const breadcrumbChildren = processBreadcrumbChildren();
        return <Box className="breadcrumbs"><MyBreadcrumbsCourse childs={breadcrumbChildren} /></Box>
    }

    const processShortDescription = () => {
        if (!obj) return <></>;
        if (!obj.hasOwnProperty('shortdescription')) return <></>;
        if (!obj.shortdescription) return <></>;
        return (<Box>

            <HtmlTag content={obj.shortdescription} />
        </Box>)
    }

    const processShareButton = () => {
        if (!obj) return <></>;
        if (!obj.hasOwnProperty('fullurl')) return <></>;
        if (!obj.fullurl) return <></>;

        let imageUri = '';

        if (obj.hasOwnProperty('gallery') && obj.gallery) {
            const gallery = CommonTools.getGalleryObjectLogo(obj.gallery);
            if (gallery) {
                const urlObject = CommonTools.getFileUrlsGallery(gallery);
                if (
                    urlObject &&
                    urlObject.hasOwnProperty('aUrl') &&
                    urlObject.aUrl
                ) {
                    imageUri = urlObject.aUrl;
                }
            }
        }
        return <ShareDialog
            url={obj.fullurl}
            imageUrl={imageUri}
            size="large"
            buttonContent={<Box className="shareBtn"><IosShareOutlinedIcon sx={{ mr: 1 }} /> {LL("Share")}</Box>}
        />
    }

    const processCourseTopBlock = () => {
        if (!obj) return <></>;

        return (
            <Box>
                <Box className="section1">
                    <Box className="container" sx={{ position: 'relative' }}>
                        <Grid container spacing={2}>
                            <Grid item lg={9} sm={12}>
                                {processBreadcrumb()}
                                <Typography className="title" my={3}>
                                    {ComponentCommonTools.processObjectField(obj, 'name')}
                                </Typography>

                                <Typography className="shortDescription" my={3}>{processShortDescription()}</Typography>

                                {processCourseTeacher()}

                                <Stack direction='row' spacing={2} alignItems='center' mb={5}>
                                    {processWishlistButton()}
                                    {processShareButton()}

                                </Stack>
                            </Grid>

                            <Grid item lg={3} sm={12} className="rightPart">
                                {processCourseCharacteristics()}
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Box>
        )
    }

    return loading ? <Skeleton variant="rectangular" /> : processCourseTopBlock();
};

export { CoursePageTopBlock };
