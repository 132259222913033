import React from "react";

import { useEffect, useState } from "react";

import { CookiesProvider } from "react-cookie";

import { ResourceProvider } from "hoc/ResourceProvider";

import { AuthProvider } from "hoc/AuthProvider";
import { HeadProvider } from "hoc/HeadProvider";
import theme from "assets/themes/theme";

import { ThemeProvider } from "@mui/material/styles";
import { MessageProvider } from "hoc/MessageProvider";
import { AppRoutes } from "hoc/AppRoutes";
import { MyGoogleOAuthProvider } from "hoc/MyGoogleOAuthProvider";
import { StudentCourseProvider } from "context/studentcourse/studentcourse.context";
import { ClassroomProvider } from "context/classroom/classroom.context";

function App() {
  const [_theme, setTheme] = useState({});
  useEffect(() => {
    setTheme(theme);
  }, []);

  return (
    <MyGoogleOAuthProvider>
      <ThemeProvider theme={_theme}>
        <CookiesProvider>
          <HeadProvider>
            <AuthProvider>
              <ResourceProvider>
                <MessageProvider>
                  <StudentCourseProvider>
                    <ClassroomProvider>
                      <AppRoutes />
                    </ClassroomProvider>
                  </StudentCourseProvider>
                </MessageProvider>
              </ResourceProvider>
            </AuthProvider>
          </HeadProvider>
        </CookiesProvider>
      </ThemeProvider>
    </MyGoogleOAuthProvider>
  );
}

export default App;
