import React, { useState, useEffect } from "react";
import { useResource } from "hook/useResource";
import { LessonDto } from "dto/course/lesson.dto";
import RequestFilterDTO from "dto/app/requestfilter.dto";
import RequestListDTO from "dto/app/requestlist.dto";
import { Status } from "tools/status";
import LessonService from "services/course/lesson.service";
import ResultListDTO from "dto/app/resultlist.dto";
import { Accordion, AccordionDetails, AccordionSummary, Box, Divider, IconButton, Skeleton, Typography, Stack } from "@mui/material";
import { logger } from "hoc/logger";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ComponentCommonTools } from "tools/componentcommontools";
import { LessonSettingsItem } from 'components/course/lessonsettings/LessonSettingsItem'

import { CommonTools } from "tools/commontools";
import ClearIcon from '@mui/icons-material/Clear';
import RequestCriteriaDTO from "dto/app/requestcriteria.dto";
import { Types } from "tools/types";

import SelectOptionSort from "dto/app/selectoptionsort.dto";
import RequestSortCriteriaDTO from "dto/app/requestsortcriteria.dto";
import { MySortSelect } from "components/elements/Select/MySortSelect";
import { MyButton } from "components/general/MyButton";
import { LoadMoreButton } from "components/elements/MyButton/LoadMoreButton";
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { AttachmentList } from "components/attachment/AttachementList";
import { GalleryList } from "components/gallery/GalleryList";
import { VideoList } from "components/video/VideoList";


type CourseLessonsListItemProps = {
    obj: LessonDto;
}

const CourseLessonsListItem: React.FC<CourseLessonsListItemProps> = ({
    obj,
    ...props }) => {

    const { LL, CC } = useResource();
    const [expend, setExpend] = useState(false);
    const handleExpend = (event: React.SyntheticEvent, expanded: boolean) => {
        setExpend(expanded);

    }


    const processLessonDetails = () => {
        if(!expend) return <></>;
        if (!obj) return <></>;
        if (!obj.id) return <></>;
        if (!obj.hasOwnProperty('typelesson')) return <></>;
        if (!obj.typelesson) return <></>;
        return (
            <Box >
                <Typography variant="h6">
                    {LL('TypeLesson')}
                </Typography>
                <Typography>
                    {ComponentCommonTools.processObjectFieldOfObject(obj, 'typelesson', 'name')}
                </Typography>
                <Divider sx={{my:2}} />
                <Box>
                    <Typography variant="h6">
                        {LL('Attachments')}
                    </Typography>
                    <Box>
                        <AttachmentList idparent={obj.id} parent={'lesson'} />
                    </Box>
                    <Divider sx={{my:2}} />
                </Box>
                <Box>
                    <Typography variant="h6">
                        {LL('Gallery')}
                    </Typography>
                    <Box>
                        <GalleryList idparent={obj.id} parent={'lesson'} />
                    </Box>
                    <Divider sx={{my:2}} />
                </Box>
                <Box>
                    <Typography variant="h6">
                        {LL('Video')}
                    </Typography>
                    <Box>
                        <VideoList idparent={obj.id} parent={'lesson'} />
                    </Box>
                </Box>
            </Box>
        )
    }



    const processLessonItem = () => {
        if (!obj) return <></>;
        if (!obj.id) return <></>;

        return (
            <Accordion
                className="courseContent"
                disableGutters
                expanded={expend}
                onChange={handleExpend}>

                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    sx={{ flexDirection: 'row-reverse',}}
                >
                    <Stack direction='row' justifyContent='space-between' sx={{ width: '100%' }}>
                        <Typography className="name" ml={1}>
                            {ComponentCommonTools.processObjectField(obj, 'name')}
                        </Typography>
                        <Typography className="time">
                            <AccessTimeIcon sx={{ mr: 1 }} />
                            {LL('LessonDuration')}
                            {(' ')}
                            {ComponentCommonTools.processObjectFieldTimeStamp(obj, 'lessonduration')}
                        </Typography>
                    </Stack>
                </AccordionSummary>
                <AccordionDetails>
                    {processLessonDetails()}
                </AccordionDetails>
            </Accordion>
        );

    };

    return processLessonItem()
};

export { CourseLessonsListItem };
