import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useResource } from "hook/useResource";
import { logger } from "hoc/logger";
import CourseCategoryService from "services/course/coursecategory.service";
import MenuLevelProps from "interfaces/menulevelprops.interface";
import PageComponentProps from "interfaces/pagecomponentprops.interface";
import { CourseCategoryDto } from "dto/course/coursecategory.dto";
import { RouteTools } from "tools/routetools";
import RequestListDTO from "dto/app/requestlist.dto";
import { Status } from "tools/status";
import RequestFilterDTO from "dto/app/requestfilter.dto";
import ResultListDTO from "dto/app/resultlist.dto";
import { Types } from "tools/types";
import { InfoCourseCategoryItem } from "./InfoCourseCategoryItem";
import { Loading } from "components/general/Loading";
import Skeleton from '@mui/material/Skeleton';
import { Box, Grid } from "@mui/material";
import { InfoCourseCategoryCard } from "./InfoCourseCategoryCard";
const courseCategoryService = new CourseCategoryService();

const TopCourseCategory: React.FC<PageComponentProps> = ({
    currentRoute,
    ...props
}) => {
    const { saveCache, getCache } = useResource();

    const [isLoading, setIsLoading] = useState(true);
    const [objects, setObjects] = useState<Array<CourseCategoryDto>>([]);


    const getList = async () => {
        const reqList = new RequestListDTO();
        reqList.onpage = 100;
        reqList.page = 1;
        reqList.filters = [];

        let f: RequestFilterDTO;

        f = new RequestFilterDTO();
        f.field = "status";
        f.values = [];
        f.values.push(Status.ACTIVE.toString());
        reqList.filters.push(f);

        f = new RequestFilterDTO();
        f.field = "istop";
        f.values = [];
        f.values.push('true');
        reqList.filters.push(f);


        const cd = getCache(reqList);

        if (cd !== false) {
            setObjects(cd);
            setIsLoading(false);
            return;
        }

        setIsLoading(true);

        const data = await courseCategoryService.getList(undefined, {}, reqList);

        const rez = !data || !data.objects ? [] : data.objects;

        setObjects(rez);
        setIsLoading(false);


        saveCache(rez, reqList);
    };

    useEffect(() => {
        getList();
    }, []);

    const processCourseCategoryItem = (obj: CourseCategoryDto, index: number) => {

        return <Grid sm={12} md={4} lg={3} item key={index}> <InfoCourseCategoryCard obj={obj} /> </Grid>;
    };


    const processCourseCategoryList = () => {

        if (!objects.length) return <></>;

        return (
            <>
            <Grid container spacing={2} className="topCategoryHomeCours">
                {objects.map((item: CourseCategoryDto, i: number) => {
                    return processCourseCategoryItem(item, i);
                })}
            </Grid>

            </>
        );
    };

    return isLoading ? <Skeleton /> : processCourseCategoryList();
};

export { TopCourseCategory };
