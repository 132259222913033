import React, { useState, useEffect } from "react";
import { useResource } from "hook/useResource";
import { EditLessonOrderDto, LessonDto, PatchLessonOrderDto } from "dto/course/lesson.dto";
import RequestFilterDTO from "dto/app/requestfilter.dto";
import RequestListDTO from "dto/app/requestlist.dto";
import { Status } from "tools/status";
import LessonService from "services/course/lesson.service";
import ResultListDTO from "dto/app/resultlist.dto";
import { Paper, Box, IconButton, Skeleton, Typography, Stack } from "@mui/material";

import RequestSortCriteriaDTO from "dto/app/requestsortcriteria.dto";
import { MySortSelect } from "components/elements/Select/MySortSelect";
import { MyButton } from "components/general/MyButton";
import { LoadMoreButton } from "components/elements/MyButton/LoadMoreButton";
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { EditCourseLessonItem } from "./EditCourseLessonItem";


import { logger } from "hoc/logger";
import SortableList, { SortableItem } from 'react-easy-sort'
import { is } from "date-fns/locale";
import { useMessage } from "hook/useMessage";
import { CommonTools } from "tools/commontools";

type EditCourseLessonListProps = {
    idCourse: string;
    objectHasChanged: string;
    setObjectsHasChanged: any
}

const lessonService = new LessonService();

const EditCourseLessonList: React.FC<EditCourseLessonListProps> = ({

    idCourse,
    objectHasChanged,
    setObjectsHasChanged,
    ...props }) => {

    const { LL, CC } = useResource();
    const [isLoading, setIsLoading] = useState(true);
    const [objects, setObjects] = useState<Array<LessonDto>>([]);
    const [initialObjects, setInitialObjects] = useState<Array<LessonDto>>([]);
    const [isLessonInEditMode, setIsLessonInEditMode] = useState(true);
    const { registerDialog } = useMessage();
    const [draggedObjects, setDraggedObjects] = useState<Array<EditLessonOrderDto>>([]);


    const getList = () => {

        setIsLoading(true);
        const reqList = new RequestListDTO();
        reqList.onpage = parseInt(CC('DefaultOnPageEditCourseLessonList', 200));
        reqList.page = 1;
        reqList.filters = [];
        reqList.sortcriteria = [];

        const sortCriteria = new RequestSortCriteriaDTO();
        sortCriteria.field = "ordercriteria";
        sortCriteria.asc = true;
        reqList.sortcriteria.push(sortCriteria);

        let f: RequestFilterDTO;

        f = new RequestFilterDTO();
        f.field = "idcourse";
        f.values = [idCourse];
        reqList.filters.push(f);


        lessonService.getListForEditCourse(loadObjects, {}, reqList);
    };


    const loadObjects = (result: ResultListDTO) => {
        if (!result) return;
        if (!result.err) {
            const objects = result.objects ? result.objects : [];
            setInitialObjects(objects)
            setObjects(objects);
        }
    }


    const checkLoading = () => {
        let load = false;

        if (!objects) load = true;

        setIsLoading(load);
    }

    useEffect(() => {
        getList();
    }, [idCourse, objectHasChanged]);

    useEffect(() => {
        checkLoading();

    }, [objects]);

    const hasOrderChanged = (): boolean => {
        if (objects.length !== initialObjects.length) {
            return true;
        }
        for (let i = 0; i < initialObjects.length; i++) {
            if (initialObjects[i].id !== objects[i].id) {
                return true;
            }
        }
        return false;
    };

    const processLessonItem = (obj: LessonDto, index: number) => {
        if (!obj) return <></>;
        if (!obj.id) return <></>;

        return (<Box key={index}>
            <EditCourseLessonItem
                objectFromList={obj}
                objectHasChanged={objectHasChanged}
                idCourse={idCourse}
                setObjectsHasChanged={setObjectsHasChanged}
                setIsLessonInEditMode={setIsLessonInEditMode}
            />
        </Box>);

    };


    const processLessonList = () => {
        if (!objects.length) return <></>;

        return (
            <>
                {objects.map((item: LessonDto, i: number) => {
                    return processLessonItem(item, i);
                })}
            </>
        );
    };

    const handleConfirmUpdateOrder = () => {
        if (!draggedObjects) return;
        if (!draggedObjects.length) return;
        const obj = new PatchLessonOrderDto();
        obj.neworder = draggedObjects;
        setIsLoading(true);
        lessonService.updateOrder(CommonTools.handleSuccessAddAndEditStayOnPage, { setObjectWasChanged: setObjectsHasChanged }, obj);
    }


    const handleOnDragEnd = (oldIndex: number, newIndex: number) => {

        const reorderedItems = Array.from(objects);
        const [movedItem] = reorderedItems.splice(oldIndex, 1);
        reorderedItems.splice(newIndex, 0, movedItem);
        setObjects(reorderedItems);

        processNewOrder(reorderedItems);
    };

    const processNewOrder = (reorderedItems: Array<LessonDto>) => {
        const updatedOrder = reorderedItems.map((item, index) => {
            return { id: item.id, ordercriteria: index };
        });
        setDraggedObjects(updatedOrder);
    }

    // useEffect(() => {
    //     logger('draggedObjects', objects)
    //     logger('draggedObjects', draggedObjects)
    // }, [draggedObjects]);

    const handleSubmit = () => {
        registerDialog(LL('Confirm_edit_lesson_order'),
            LL('Are_you_sure_you_want_to_edit_this_course_lessons_order'),
            { cb: handleConfirmUpdateOrder }, { cb: handleCancel });
    }

    const handleCancel = () => {
        setObjects(initialObjects);
    }

    const processButtonSubmit = () => {
        let isFormDisabled = true;
        if (draggedObjects.length > 0) isFormDisabled = false;
        if (hasOrderChanged()) isFormDisabled = false;
        else isFormDisabled = true;
        if (!isLessonInEditMode) isFormDisabled = true;
        return (
            <Box mt={4} textAlign='center'>
                <MyButton
                    cb={handleSubmit}
                    disabled={isFormDisabled}
                    color="primary"
                    className="btn"
                    variant="contained"
                    size="large"
                    type="submit"
                >
                    {LL("Bt_Submit")}
                </MyButton>
            </Box>
        )
    }


    const processList = () => {
        if (!objects) return <></>;
        if (!objects.length) return <></>;
        if (isLoading) return (<Skeleton />)
        return (
            <SortableList
                onSortEnd={handleOnDragEnd}
                allowDrag={isLessonInEditMode}
            >
                {
                    objects.map((item: LessonDto, i: number) => {
                        if (!item) return <></>;
                        if (!item.id) return <></>;
                        return (
                            <SortableItem
                                key={item.id}
                            >
                                {processLessonItem(item, i)}
                            </SortableItem>
                        )
                    })
                }
            </SortableList>
        )
    }

    return isLoading ? <Skeleton /> : (
        <>
            {processList()}
            {processButtonSubmit()}
            {/* {processLessonList()} */}
        </>
    );
};

export { EditCourseLessonList };
