import React, { useEffect, useState } from "react";
import { useResource } from "hook/useResource";
import { logger } from "hoc/logger";

// import TestimonialService from "services/testimonial/testimonial.service";

import MenuLevelProps from "interfaces/menulevelprops.interface";
import { TestimonialDto } from "dto/testimonial/testimonial.dto";
import { RouteTools } from "tools/routetools";
import RequestListDTO from "dto/app/requestlist.dto";
import { Status } from "tools/status";
import RequestFilterDTO from "dto/app/requestfilter.dto";
import ResultListDTO from "dto/app/resultlist.dto";
import { Types } from "tools/types";
import MenuItemProps from "interfaces/menuitemprops.interface";
import { MyNavBt } from "components/general/MyNavBt";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { MyButton } from "components/general/MyButton";
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import { ComponentCommonTools } from 'tools/componentcommontools'
import Typography from "@mui/material/Typography";
import Rating from '@mui/material/Rating';
import Avatar from "@mui/material/Avatar";
import { Stack } from "@mui/material";
import { MyRatingReadOnly } from "components/elements/MyRating/MyRatingReadOnly";
import { MyAvatar } from "components/elements/MyAvatar/MyAvatar";

type TestimonialCardProps = {
    obj: TestimonialDto;

}

const TestimonialCard: React.FC<TestimonialCardProps> = ({
    obj,
    ...props
}) => {
    const { LL } = useResource();

    const processRating = () => {
        if (!obj) return <></>;
        if (!obj.hasOwnProperty('rating')) return <></>;
        if (!obj.rating) return <></>;
        return <MyRatingReadOnly rating={obj.rating} count={0} showDetails={false} />
    }

    const processTeacher = () => {
        if (!obj) return <></>;
        if (!obj.teacher) return <></>;
        if (!obj.teacher_fullname) return <></>;
        let fullUrl = '';
        if (obj.teacher.hasOwnProperty('fullurl') && obj.teacher.fullurl) fullUrl = obj.teacher.fullurl;
        if (!fullUrl) {
            return (
                <Typography>{ComponentCommonTools.processObjectField(obj, 'teacher_fullname')}</Typography>
            )
        }
        return (
            <MyNavBt href={fullUrl}>
                <Typography>{ComponentCommonTools.processObjectField(obj, 'teacher_fullname')}</Typography>
            </MyNavBt>
        )
    }

    const processUserName = () => {
        if (!obj) return <></>;
        let fullUrl = '';
        if (obj.hasOwnProperty('user_fullurl') && obj.user_fullurl) fullUrl = obj.user_fullurl;

        if (!fullUrl) {
        return (
            <Typography className="name">{processUserFullName()}</Typography>
        )
        }
        return (
            <MyNavBt href={fullUrl}>
                <Typography className="name">{processUserFullName()}</Typography>
            </MyNavBt>
        )
    }
    const processCourse = () => {
        if (!obj) return <></>;
        if (!obj.course) return <></>;
        if (!obj.course.name) return <></>;
        return (
            <Typography>{ComponentCommonTools.processObjectFieldOfObject(obj, 'course', 'name')}</Typography>
        )
    }
    // const processAvatarName = () => {
    //     if (!obj) return 'LC';
    //     if (!obj.user_name) return 'LC';
    //     if (!obj.user_surname) return obj.user_name.charAt(0).toUpperCase();


    //     return obj.user_name.charAt(0).toUpperCase() + obj.user_surname.charAt(0).toUpperCase()
    // }

    const processUserFullName = () => {
        if (!obj) return '';
        if (!obj.user_name) return '';
        if (!obj.user_surname) return obj.user_name;


        return obj.user_name + ' ' + obj.user_surname;
    }
    const processAvatar = () => {
        if (!obj) return <></>;
        let name = ''
        let surname = ''
        if (obj.hasOwnProperty('user_name') && obj.user_name) name = obj.user_name;
        if (obj.hasOwnProperty('user_surname') && obj.user_surname) surname = obj.user_surname;
        
        return <MyAvatar obj={obj} name={name} surname={surname} sx={{ mr: 1 }} />
    }
    const processItem = () => {
        if (!obj) return <></>;

        return (


            <Box p={2} className="testimonials-home">
                <Card className="shadow-element">
                    <CardContent sx={{ textAlign: 'center' }}>
                        <Box className="icon-quotes"></Box>
                        <Box sx={{ display: 'flex', alignContent: 'center', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', height: '150px' }}>
                            <Typography my={2} className="message">
                                {ComponentCommonTools.processObjectField(obj, 'message')}
                            </Typography>
                        </Box>
                        <Typography>{processRating()}</Typography>
                    </CardContent>
                </Card>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }} mt={3}>
                    <Box sx={{ display: 'flex' }}>
                        <Box mr={1}>{processAvatar()}</Box>
                        <Box>
                            {/* <Typography className="name">{processUserFullName()}</Typography> */}
                            {processUserName()}
                            <Typography className="data">{ComponentCommonTools.processObjectField(obj, 'date_name')}</Typography>
                        </Box>
                    </Box>
                    <Box sx={{ textAlign: 'right' }}>
                        <Typography className="data">{processCourse()}</Typography>
                        <Typography className="teacher">{processTeacher()}</Typography>
                    </Box>
                </Box>
            </Box>




        )
    };


    return processItem();


};

export { TestimonialCard };
