import React from 'react';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined';
import InsertChartOutlinedIcon from '@mui/icons-material/InsertChartOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import InboxIcon from '@mui/icons-material/Inbox';
import MailIcon from '@mui/icons-material/Mail';
import { MenuItem, MenuItemOption } from 'dto/app/teacherdrawer.dto';
import ReviewsIcon from '@mui/icons-material/Reviews';
import QuizIcon from '@mui/icons-material/Quiz';

export const TeacherDrawerMenuItems: MenuItem[] = [
  {
    name: 'Amg_Courses',
    icon: <OndemandVideoIcon />,
    isSelected: false,
    options: [
      {
        name: 'MyCourses',
        icon: <InboxIcon />,
        href: '/teacherinterface/course',
        currentItemMenu: 'course',
      },
      {
        name: 'AddCourse',
        icon: <MailIcon />,
        href: '/teacherinterface/addcourse',
        currentItemMenu: 'addcourse',
      },
      {
        name: 'TestResult',
        icon: <MailIcon />,
        href: '/teacherinterface/testresult',
        currentItemMenu: 'testresult',
      },
      {
        name: 'CourseTeacherRequest',
        icon: <MailIcon />,
        href: '/teacherinterface/courseteacherrequest',
        currentItemMenu: 'courseteacherrequest',
      },
    ],
  },
  {
    name: 'Amg_Communications',
    icon: <ChatOutlinedIcon />,
    isSelected: false,
    options: [
      {
        name: 'FAQ',
        icon: <QuizIcon />,
        href: '/teacherinterface/faq',
        currentItemMenu: 'teacherfaq'
      },
      {
        name: 'Messages',
        icon: <MailIcon />,
        href: '/teacherinterface/message',
        currentItemMenu: 'message'
      },
      {
        name: 'Testimonials',
        icon: <ReviewsIcon />,
        href: '/teacherinterface/testimonial',
        currentItemMenu: 'teachertestimonial'
      },
      {
        name: 'Ticket',
        icon: <ReviewsIcon />,
        href: '/teacherinterface/ticket',
        currentItemMenu: 'ticket'
      },

    ],
  },
  {
    name: 'Amg_Performance',
    icon: <InsertChartOutlinedIcon />,
    isSelected: false,
    options: [
      {
        name: 'Dashboard',
        icon: <InboxIcon />,
        href: '/teacherinterface',
        currentItemMenu: 'dashboard',
      },
      {
        name: 'Students',
        icon: <MailIcon />,
        href: '/teacherinterface/student',
        currentItemMenu: 'students'
      },
    ],
  },
  {
    name: 'Amg_Profile_and_Settings',
    icon: <SettingsOutlinedIcon />,
    isSelected: false,
    options: [
      {
        name: 'EditProfile',
        icon: <></>,
        href: '/teacherinterface/editprofile',
        currentItemMenu: 'editprofile',
      },
      {
        name: 'Notifications',
        icon: <></>,
        href: '/teacherinterface/notification',
        currentItemMenu: 'notification',
      },
      {
        name: 'TeacherRequest',
        icon: <></>,
        href: '/teacherinterface/teacherrequest',
        currentItemMenu: 'teacherrequest',
      },
    ],
  },
];
