import React, { useEffect, useState } from "react";
import PageComponentProps from "interfaces/pagecomponentprops.interface";
import { useResource } from "hook/useResource";
import { logger } from "hoc/logger";
import { useAuth } from "hook/useAuth";
import MenuService from "services/menu/menu.service";
import { MyNavBt } from "components/general/MyNavBt";

const SignInBlock: React.FC<PageComponentProps> = ({
  currentRoute,
  ...props
}) => {
  const { LL } = useResource();
  const {user} = useAuth();
  return user == undefined ? (
    <></>
  ) : (
    <MyNavBt sx={{ml:2}} className="btnTop btnSignInTop" href={"/login"}

    >{LL("BT_SignInTop")}</MyNavBt>
  );
};

export { SignInBlock };
