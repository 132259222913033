import React from "react";
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Box from "@mui/material/Box";
import { MyButton } from "components/general/MyButton";
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import IconButton from '@mui/material/IconButton';


type CustomArrowProps = {
    type?: string;
    onClick?: () => void;
    isEdge?: boolean;
};

const CustomArrowLeft: React.FC<CustomArrowProps> = ({ type, onClick, isEdge }) => {
    
    return (
        <IconButton onClick={onClick} className="icon-slider" sx={{left:"0px"}} size="large">
            <KeyboardArrowLeftIcon  />
        </IconButton>
        
    );
};

const CustomArrowRight: React.FC<CustomArrowProps> = ({ type, onClick, isEdge }) => {
    return (
        <IconButton onClick={onClick} className="icon-slider" sx={{right:"0px"}} size="large">
             <KeyboardArrowRightIcon />
        </IconButton>
        
    );
};

type CarouselProps = {
    children?: React.ReactNode;
    _speed?: number;
    _slidesToShow?: number;
    _slidesToScroll?: number;
    _autoplay?: boolean;
    _autoplaySpeed?: number;
    _dots?: boolean;
    _initialSlide?: number;
};


const Carousel: React.FC<CarouselProps> = ({
    children,
    _speed,
    _slidesToShow,
    _slidesToScroll,
    _autoplay,
    _autoplaySpeed,
    _dots,
    _initialSlide,
    ...props }) => {

    const settings = {
        dots: _dots ?? false,
        // fade: true,
        slidesToShow: _slidesToShow ?? 1,
        slidesToScroll: _slidesToScroll ?? 1,
        initialSlide: _initialSlide ?? 0,
        autoplay: _autoplay ?? true,
        autoplaySpeed: _autoplaySpeed ?? 8000,
        nextArrow: <CustomArrowRight/>,
        prevArrow: <CustomArrowLeft/>,
        pauseOnHover: true,
        responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: _slidesToShow ?? 1,
                slidesToScroll: 1,
                
              }
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: _slidesToShow && _slidesToShow < 1  ? _slidesToShow : 1,
                slidesToScroll: 1,
                
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
              }
            }
          ]

    };


    return (
        <Box >
            <Slider {...settings} className="slider-design">
                {children}
            </Slider>
        </Box>);
};

export { Carousel };
