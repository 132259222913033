import React, { useState, useEffect } from "react";
import { useResource } from "hook/useResource";
import { FilterDictionaryDto } from "dto/course/filterdictionary.dto";
import RequestFilterDTO from "dto/app/requestfilter.dto";
import RequestListDTO from "dto/app/requestlist.dto";
import { Status } from "tools/status";
import { Box, FormControl, FormControlLabel, Radio, RadioGroup, Skeleton, Stack, Typography } from "@mui/material";
import { logger } from "hoc/logger";

import { ComponentCommonTools } from "tools/componentcommontools";
import MyTextField from 'components/form/mytextfield';


import RequestCriteriaDTO from "dto/app/requestcriteria.dto";
import { load } from "mime";
import { MyRatingReadOnly } from "components/elements/MyRating/MyRatingReadOnly";
import { objectMethod } from "@babel/types";
import { CommonTools } from "tools/commontools";

type FilterRatingProps = {
    setFilters: any;
    filters?: RequestFilterDTO;
}

const FilterRating: React.FC<FilterRatingProps> = ({

    setFilters,
    filters,
    ...props }) => {

    const { LL } = useResource();


    const [isLoading, setIsLoading] = useState(true);
    const [valueRating, setValueRating] = React.useState(0);

    const addFilter = (value: number) => {
        // const obj = new RequestFilterDTO();
        // obj.field = 'fromteacherrating';
        // obj.values = [value.toString()];
        return CommonTools.addFilterRating(value);
    }

     const getDefaultValue = () => {
        if(!filters) return 0;
        if(!filters.values) return 0;
        if(filters.values.length == 0) return 0;
        return parseFloat(filters.values[0]);
     };

    

    useEffect(() => {
        setIsLoading(true);
        if(!filters) {
            setValueRating(0);
            // setFilters(addFilter(0))
        }
        else{
            setValueRating(getDefaultValue());
        }
        
        setIsLoading(false);
    }, [filters]);


    const processRating = (value: number) => {

        return (<Stack direction='row' spacing={1}>
            <Typography>{LL("Start_from")}</Typography>
            <MyRatingReadOnly rating={value} showDetails={true} />
        </Stack>)
    }

    const processFormControlLabelElement = (value: number) => {
        return <FormControlLabel value={value} control={<Radio />} label={processRating(value)} />
    }

    const processFormControlLabelElementAll = (value: number) => {
        return <FormControlLabel value={value} control={<Radio />} label={<>{LL('All_Ratings')}</>} />
    }



    const handleChange = (event: any) => {
        setValueRating(event.target.value);
        setFilters(addFilter(event.target.value))
    };

    const processRadioGroup = () => {
        return (
            <FormControl>
                <RadioGroup
                    aria-labelledby="radio-buttons-group-rating"
                    name="controlled-radio-buttons-group-rating"
                    value={valueRating}
                    onChange={handleChange}
                >
                    {processFormControlLabelElementAll(0)}
                    {processFormControlLabelElement(4.5)}
                    {processFormControlLabelElement(4)}
                    {processFormControlLabelElement(3.5)}
                    {processFormControlLabelElement(3)}
                </RadioGroup>
            </FormControl>
        )
    }

    return isLoading ? <Skeleton /> : (
        <>
            {processRadioGroup()}
        </>
    );
};

export { FilterRating };
