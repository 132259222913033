import React, { useState, useEffect } from "react";
import { useResource } from "hook/useResource";
import { QuestionDto } from "dto/course/question.dto";
import RequestFilterDTO from "dto/app/requestfilter.dto";
import RequestListDTO from "dto/app/requestlist.dto";
import { Status } from "tools/status";
import QuestionService from "services/course/question.service";
import ResultListDTO from "dto/app/resultlist.dto";
import {
    Paper,
    Box,
    IconButton,
    Skeleton,
    Typography,
    Stack,
    Accordion,
    AccordionDetails,
    AccordionSummary
} from "@mui/material";

import RequestSortCriteriaDTO from "dto/app/requestsortcriteria.dto";
import { MySortSelect } from "components/elements/Select/MySortSelect";
import { MyButton } from "components/general/MyButton";
import { LoadMoreButton } from "components/elements/MyButton/LoadMoreButton";
import AccessTimeIcon from '@mui/icons-material/AccessTime';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ComponentCommonTools } from "tools/componentcommontools";

import { logger } from "hoc/logger";


import { useMessage } from "hook/useMessage";
import { CommonTools } from "tools/commontools";
import { AnswerList } from "./AnswerList";
import { QuestionListItem } from "./QuestionListItem";


type QuestionListProps = {
    idTest: string;
    objectQuestionHasChanged:string;
    setObjectsHasChanged: any
}

const questionService = new QuestionService();

const QuestionList: React.FC<QuestionListProps> = ({

    idTest,
    objectQuestionHasChanged,
    setObjectsHasChanged,
    ...props }) => {

    const { LL, CC } = useResource();
    const [isLoading, setIsLoading] = useState(true);
    const [objects, setObjects] = useState<Array<QuestionDto>>([]);

    
    const { registerDialog } = useMessage();



    const getList = () => {

        setIsLoading(true);
        const reqList = new RequestListDTO();
        reqList.onpage = parseInt(CC('DefaultOnPageQuestionList', 200));
        reqList.page = 1;
        reqList.filters = [];
        reqList.sortcriteria = [];

        const sortCriteria = new RequestSortCriteriaDTO();
        sortCriteria.field = "ordercriteria";
        sortCriteria.asc = true;
        reqList.sortcriteria.push(sortCriteria);

        let f: RequestFilterDTO;

        f = new RequestFilterDTO();
        f.field = "idtest";
        f.values = [idTest];
        reqList.filters.push(f);


        questionService.getList(loadObjects, {}, reqList);
    };


    const loadObjects = (result: ResultListDTO) => {
        if (!result) return;
        if (!result.err) {
            const objects = result.objects ? result.objects : [];
            setObjects(objects);
        }
    }


    const checkLoading = () => {
        let load = false;
        if (!objects) load = true;

        setIsLoading(load);
    }

    useEffect(() => {
        getList();
    }, [idTest,objectQuestionHasChanged]);

    useEffect(() => {
        checkLoading();

    }, [objects]);
    

    const processQuestionItem = (obj: QuestionDto, index: number) => {
        if (!obj) return <></>;
        if (!obj.id) return <></>;

        return (<Box key={index}>
            <QuestionListItem obj={obj} isLoading={isLoading} setObjectWasChanged={setObjectsHasChanged}/>
        </Box>);

    };

    const processQuestionList = () => {
        if (!objects.length) return <></>;

        return (
            <>
                {objects.map((item: QuestionDto, i: number) => {
                    return processQuestionItem(item, i);
                })}
            </>
        );
    };



    return isLoading ? <Skeleton variant='rectangular' height={300}/> : (
        <>
            {processQuestionList()}
        </>
    );
};

export { QuestionList };
