import React from "react";
import PageComponentProps from "interfaces/pagecomponentprops.interface";
import { StudentTeacherFAQList } from "components/studentinterface/StudentTeacherFAQList";
import Typography from "@mui/material/Typography";
import { useResource } from "hook/useResource";

const StudentInterfaceFAQBlock: React.FC<PageComponentProps> = ({ currentRoute, ...props }) => {
    const {LL} = useResource();
    return (
        <>
            <Typography variant="h3" textAlign='center' my={3}>{LL("FAQ student")}</Typography>
            <StudentTeacherFAQList currentRoute={currentRoute} />
        </>);
};

export { StudentInterfaceFAQBlock };
