import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useResource } from "hook/useResource";
import { logger } from "hoc/logger";
import CourseCategoryService from "services/course/coursecategory.service";
import MenuLevelProps from "interfaces/menulevelprops.interface";
import { CourseCategoryDto } from "dto/course/coursecategory.dto";
import { RouteTools } from "tools/routetools";
import RequestListDTO from "dto/app/requestlist.dto";
import { Status } from "tools/status";
import RequestFilterDTO from "dto/app/requestfilter.dto";
import ResultListDTO from "dto/app/resultlist.dto";
import { Types } from "tools/types";
import { InfoCourseCategoryItem } from "./InfoCourseCategoryItem";
import { Loading } from "components/general/Loading";
import Skeleton from '@mui/material/Skeleton';
import { Box, IconButton, List, ListItemButton, ListItem, ListItemSecondaryAction, ListItemIcon } from "@mui/material";

import { ComponentCommonTools } from "tools/componentcommontools";
import { type } from "os";
import { MyNavBt } from "components/general/MyNavBt";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
const courseCategoryService = new CourseCategoryService();

type CourseCategoryMainDrawerProps = {
    handleClose: any;
    setOpenSecondDrawer: any;
    setCourseCategoryObject: any;
    setCourseCategoryId: any;
    idparent: string;
}
const CourseCategoryMainDrawer: React.FC<CourseCategoryMainDrawerProps> = ({
    handleClose,
    setOpenSecondDrawer,
    setCourseCategoryObject,
    setCourseCategoryId,
    idparent,
    ...props
}) => {
    const { saveCache, getCache } = useResource();

    const [isLoading, setIsLoading] = useState(true);
    const [objects, setObjects] = useState<Array<CourseCategoryDto>>([]);


    const getList = async () => {
        const reqList = new RequestListDTO();
        reqList.onpage = 100;
        reqList.page = 1;
        reqList.filters = [];

        let f: RequestFilterDTO;

        f = new RequestFilterDTO();
        f.field = "status";
        f.values = [];
        f.values.push(Status.ACTIVE.toString());
        reqList.filters.push(f);

        f = new RequestFilterDTO();
        f.field = "idparent";
        f.values = [idparent];
        reqList.filters.push(f);


        const cd = getCache(reqList);

        if (cd !== false) {
            setObjects(cd);
            setIsLoading(false);
            return;
        }

        setIsLoading(true);

        const data = await courseCategoryService.getList(undefined, {}, reqList);

        const rez = !data || !data.objects ? [] : data.objects;

        setObjects(rez);
        setIsLoading(false);


        saveCache(rez, reqList);
    };

    useEffect(() => {
        getList();
    }, [idparent]);

    const processIcon = (obj: CourseCategoryDto) => {
        if (!obj) return <></>;
        if (!obj.hasOwnProperty('icon')) return <></>;
        if (!obj.icon) return <></>;
        return (<ListItemIcon className="iconCate">
            {ComponentCommonTools.getFileBlockSVG(obj.icon)}
        </ListItemIcon>);
    }

    const processCourseCategoryItem = (obj: CourseCategoryDto, index: number) => {
        if (!obj) return <></>;
        if (!obj.hasOwnProperty('id')) return (<></>);
        let id = ''
        if (!obj.id) return <></>;
        id = obj.id;
        let fullurl = '';
        if (!obj.hasOwnProperty('fullurl')) fullurl = '';
        if (!obj.fullurl) fullurl = '';
        else fullurl = obj.fullurl;
        const hasChildren = obj.hasOwnProperty('haschildren') && obj.haschildren !== undefined ? obj.haschildren : false;
        
        return (
            <ListItem
                key={index}
                additionalOnClick={handleClose}
                component={MyNavBt}
                href={fullurl}
                sx={{p:0}}
            >

                <ListItemButton className="linkSecond">
                    {processIcon(obj)}
                    {ComponentCommonTools.processObjectField(obj, 'name')}
                </ListItemButton>
                <ListItemSecondaryAction onClick={() => { handleOpenSecondDrawer(obj, id) }} >
                    {processSecondaryActions(hasChildren)}
                </ListItemSecondaryAction>
            </ListItem>
        )
    };

    const handleOpenSecondDrawer = (obj: CourseCategoryDto, id: string) => {
        setCourseCategoryObject(obj);
        setCourseCategoryId(id);
        setOpenSecondDrawer(true);
    }

    const processSecondaryActions = (display: boolean) => {
        if (!display) return (<></>)
        return (
            <IconButton edge="end" aria-label="action">
                <ArrowForwardIosIcon className="iconRight" />
            </IconButton>
        )

    }

    const processCourseCategoryList = () => {


        if (!objects.length) return <></>;

        return (
            <List  >
                {objects.map((item: CourseCategoryDto, i: number) => {
                    return processCourseCategoryItem(item, i);
                })}
            </List>
        );
    };

    return isLoading ? <Skeleton /> : processCourseCategoryList();
};

export { CourseCategoryMainDrawer };
