import React, { useEffect, useState } from "react";
import { useResource } from "hook/useResource";
import { logger } from "hoc/logger";

// import TeacherFAQService from "services/testimonial/testimonial.service";

import MenuLevelProps from "interfaces/menulevelprops.interface";
import { TeacherFAQDto } from "dto/teacher/teacherfaq.dto";
import { RouteTools } from "tools/routetools";
import RequestListDTO from "dto/app/requestlist.dto";
import { Status } from "tools/status";
import RequestFilterDTO from "dto/app/requestfilter.dto";
import ResultListDTO from "dto/app/resultlist.dto";
import { Types } from "tools/types";
import MenuItemProps from "interfaces/menuitemprops.interface";
import { MyNavBt } from "components/general/MyNavBt";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { MyButton } from "components/general/MyButton";
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import { ComponentCommonTools } from 'tools/componentcommontools'
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Rating from '@mui/material/Rating';
import Avatar from "@mui/material/Avatar";
import { CardHeader, Collapse, Link, Stack } from "@mui/material";
import { MyRatingReadOnly } from "components/elements/MyRating/MyRatingReadOnly";
import { MyAvatar } from "components/elements/MyAvatar/MyAvatar";
import { TeacherTypeIcon } from "components/teacher/TeacherTypeIcon"



type StudentInterfaceFAQCardProps = {
    obj: TeacherFAQDto;

}

const StudentInterfaceFAQCard: React.FC<StudentInterfaceFAQCardProps> = ({
    obj,

    ...props
}) => {
    const { LL } = useResource();


    const processTeacher = () => {
        if (!obj) return <></>;
        if (!obj.teacher) return <></>;
        if (!obj.teacher_fullname) return <></>;

        return (
            <Typography variant='h6' display='flex' alignItems='center'>
                <Box mr={1}>{processTeacherStatus()}</Box>
                {ComponentCommonTools.processObjectField(obj, 'teacher_fullname')}
            </Typography>
        )

    }

    const processTeacherAvatar = () => {
        if (!obj) return <></>;
        if (!obj.teacher) return <></>;
        let name = ''
        let surname = ''
        if (obj.teacher.hasOwnProperty('name') && obj.teacher.name) name = obj.teacher.name;
        if (obj.teacher.hasOwnProperty('surname') && obj.teacher.name) surname = obj.teacher.surname;

        return <MyAvatar obj={obj.teacher} name={name} surname={surname} sx={{ mr: 1 }} />
    }

    const processTeacherStatus = () => {
        if (!obj) return <></>;
        if (!obj.teacher) return <></>;
        return <TeacherTypeIcon obj={obj.teacher} />
    }


    const processResponse = () => {
        if (!obj) return <></>;
        if (!obj.hasOwnProperty('teacherresponse')) return <></>;
        if (!obj.teacherresponse) return <></>;
        return (
            <>
            <Box className="teacher-response" px={2} mx={2}>

                <Box display='flex' alignItems='center'>
                    <Box mr={1}>{processTeacherAvatar()}</Box>
                    <Box>
                        <Typography>{processTeacher()}</Typography>
                    </Box>
                </Box>
                    <Typography className="message-teacher">
                        {ComponentCommonTools.processObjectField(obj, 'teacherresponse')}
                    </Typography>
                </Box>
            </>
        )
    }


    const processTeacherCard = () => {
        if (!obj) return <></>;
        return (
            <>
                <Box>
                    {processResponse()}
                </Box>

            </>
        )
    }



    const processCourseImage = () => {
        if (!obj) return <></>;
        if (!obj.hasOwnProperty('course')) return <></>;
        if (!obj.course) return <></>;
        if (!obj.course.hasOwnProperty('gallery')) return <></>;
        if (!obj.course.gallery) return <></>;
        if (!obj.course.gallery.length) return <></>;
        if (!obj.course.gallery[0]) return <></>;
        if (!obj.course.gallery[0].hasOwnProperty('fileInfoObj')) return <></>;
        if (!obj.course.gallery[0].fileInfoObj) return <></>;
        return ComponentCommonTools.getFileBlockImage(obj.course.gallery[0].fileInfoObj, 50, 50, '', false, { borderRadius: 50 })

    }
    const processCourseName = () => {
        if (!obj) return <></>;
        if (!obj.hasOwnProperty('course')) return <></>;
        if (!obj.course) return <></>;
        let fullUrl = '';
        if (obj.course.hasOwnProperty('fullurl') && obj.course.fullurl) fullUrl = obj.course.fullurl;
        if (!fullUrl) {
            return ComponentCommonTools.processObjectFieldOfObject(obj, 'course', 'name')
        } else {
            return (
                <Link href={fullUrl} target="_blank" rel="noopener noreferrer">
                    {ComponentCommonTools.processObjectFieldOfObject(obj, 'course', 'name')}
                </Link>

            )
        }
    }
    const processUserCard = () => {
        if (!obj) return <></>;
        return (
            <>
            <Box className="user-message" p={2}>
                <Typography  className="data">{ComponentCommonTools.processObjectField(obj, 'date_name')}</Typography>
                <Typography className="message">{ComponentCommonTools.processObjectField(obj, 'message')}</Typography>
            </Box>
            </>
        )
    }
    const processCourseInfo = () => {
        if (!obj) return <></>;
        if (!obj.hasOwnProperty('course')) return <></>;
        if (!obj.course) return <></>;
        return (
            <Box display="flex" p={2} sx={{ alignItems: 'center' }}>
                {processCourseImage()}
                <Typography variant="h6" ml={2} className="course-name">
                    {processCourseName()}
                </Typography>
            </Box>)
    }
    const processItem = () => {
        if (!obj) return <></>;

        return (
            <Box className="box-faq-message" my={2} pb={2}>
                
                    {processCourseInfo()}
                    <Divider />
                    {processUserCard()}
                    {processTeacherCard()}

            </Box>
        )
    };

    return processItem();
};

export { StudentInterfaceFAQCard };