import {
    Box,
    Skeleton,
    Stack,
    Typography
} from "@mui/material";
import { HtmlTag } from "components/general/HtmlTag";
import ResultObjectDTO from "dto/app/resultobject.dto";
import { useResource } from "hook/useResource";
import React, { useState, useEffect } from "react";
import { ComponentCommonTools } from "tools/componentcommontools";

import { useAuth } from "hook/useAuth";
import { CommonTools } from "tools/commontools";
import { RouteTools } from "tools/routetools";
import { MyButton } from "components/general/MyButton";
import { Status } from 'tools/status';
import DoneIcon from '@mui/icons-material/Done';
import { Types } from "tools/types";
import { logger } from "hoc/logger";
import CourseService from "services/course/course.service";
import { CourseDto } from "dto/course/course.dto";
import { useMessage } from "hook/useMessage";


type CoursePublishButtonProps = {
    [x: string]: any;
    currentRoute: any;
    obj: CourseDto;
    setObj: any;
}

const courseService = new CourseService();
const CoursePublishButton: React.FC<CoursePublishButtonProps> = ({
    currentRoute,
    obj,
    setObj,
    ...props }) => {

    const { LL, CC } = useResource();
    const { teacher } = useAuth();
    const { registerDialog } = useMessage();
    const [idCourse, setIdCourse] = useState<string>('');
    const [disabled, setDisabled] = useState<boolean>(true);

    useEffect(() => {
        setIdCourse(CommonTools.getIdFromPath(currentRoute, 'editcourse', 1, 2));
    }, [currentRoute]);


    const checkIsDisabled = () => {
        if (!obj) return;
        if (!obj.hasOwnProperty('status')) return;
        if (!obj.status) return;
        if (
            obj.status === Status.ACTIVE ||
            obj.status === Status.COURSE_ARCHIVED ||
            obj.status === Status.COURSE_DELETED
        ) return;
        if (!teacher) return;
        if (!teacher.hasOwnProperty('status')) return;
        if (!teacher.status) return;
        if (teacher.status === Status.TEACHER_UNVERIFIED) return;
        setDisabled(false);
    };

    useEffect(() => {
        checkIsDisabled();
    }, [obj]);

    const handleSubmit = () => {
        if (!idCourse) return;

        registerDialog(LL('Confirm_publish_course'),
            LL('Are_you_sure_you_want_to_publish_this_course'),
            { cb: handleConfirm }, null);
    }

    const handleConfirm = () => {
        if (idCourse) {
            setDisabled(true);
            courseService.publishByTeacher(idCourse, handleResult, {});
        }
    }

    const handleResult = (result: ResultObjectDTO) => {
        if (!result) return;
        if (!result.err) {
            const obj: CourseDto = result.obj ? result.obj : {};
            setObj(obj);
        }
    }
    const processComponent = () => {
        if (!teacher) return <></>;

        return (
            <MyButton
                {...props}
                disabled={disabled}
                cb={handleSubmit}
            >
                {LL("publish_course_btn")}
            </MyButton>
        )
    }
    return processComponent()
};

export { CoursePublishButton };
