import { 
    Box, 
    Accordion, 
    AccordionSummary, 
    AccordionDetails, 
    Skeleton 
} from "@mui/material";

import React, { useState, useEffect } from "react";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useResource } from "hook/useResource";
import { PopulateTypeDto } from "dto/nomenclature/populatetype.dto";

import ResultListDTO from "dto/app/resultlist.dto";
import MyCheckboxField from "components/form/mycheckboxfield";
import { ComponentCommonTools } from "tools/componentcommontools";

type StaticNomenclatureFilterProps = {
    setFilterValue: any;
    filterValue: Array<string>;
    filterName: string;
    service: any;
}
const StaticNomenclatureFilter:
    React.FC<StaticNomenclatureFilterProps> =
    ({
        setFilterValue,
        filterValue,
        filterName,
        service,
        ...props
    }) => {

        const { LL } = useResource();
        const [isLoading, setIsLoading] = useState(false);
        const [expanded, setExpanded] = useState<boolean>(false);
        const [objects, setObjects] = useState<Array<PopulateTypeDto>>([]);

        const handleAccordionChange = (event: any, isExpanded: boolean) => {
            setExpanded(isExpanded);
        };

        const getList = () => {
            if(!service) return;
            if(!expanded) return;

            const reqList = service.getDefaultRequestListSelect(2000);
            setIsLoading(true);

            service.getList(loadObjects, {}, reqList);
        };

        const loadObjects = (result: ResultListDTO) => {
            if (!result) return;
            if (!result.err) {
                const objects = result.objects ? result.objects : [];
                setObjects(objects);
            }
        }

        const checkLoading = () => {
            let load = false;
            if (!objects) load = true;
            setIsLoading(load);
        }
        useEffect(() => {
            getList();
        }, [expanded]);

        useEffect(() => {
            checkLoading();
        }, [objects]);

        const processFilterItem = () => {
            if(!filterValue) return <></>;
            if (!filterName) return <></>;
            return (<Box >
                <Accordion className="accordion"
                    onChange={handleAccordionChange}
                >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        className="summary"
                    >
                        {LL("FN_" + filterName)}
                    </AccordionSummary>
                    <AccordionDetails>
                        {processDetails()}
                    </AccordionDetails>
                </Accordion>
            </Box>);

        };

        const processDetails = () => {
            if (!expanded) return <></>;
            if (isLoading) return <Skeleton />;
            if (!objects) return <></>;
            return (
                <AccordionDetails>
                    <Box>
                        {objects.map((obj, index) => {
                            return <Box key={index}>{processItem(obj)}</Box>
                        })}
                    </Box>
                </AccordionDetails>
            );
        }

        const onChange = (event: any) => {
        
            if (event.target.checked) {
                addFilter(event.target.value);
            }
            if (!event.target.checked) {
                removeFilter(event.target.value);
            }
        }

        const removeFilter = (id: string) => {
            if (!id || !filterValue) return;
    
            const newFilters = [...filterValue];
            const index = newFilters.indexOf(id);

            if (index !== -1) {
                newFilters.splice(index, 1);
            }
            
            setFilterValue(newFilters);
        };
    
        const addFilter = (id: string) => {
            if (!id || !filterValue) return;
    
            const newFilters = [...filterValue];
            newFilters.push(id);
            setFilterValue(newFilters);
        }
    

        const processItem = (obj: PopulateTypeDto) => {
            if (!obj) return <></>;
            if (!obj.id) return <></>;
            const checked = processChecked(obj.id);
            return (
                <MyCheckboxField
                    _label={ComponentCommonTools.processObjectField(obj, 'name')}
                    setObj={(value: any) => { }}
                    checked={checked}
                    onChange={onChange}
                    value={obj.id}
                    field={filterName}
                    color="primary"
                    size="small"
                    _vresults={undefined}
                />
            )
        }


        const processChecked = (id: string): boolean => {
            if (!id || !filterValue) return false;
            return filterValue.includes(id);
        };


        return processFilterItem();
    };

export { StaticNomenclatureFilter };
