import React, { useState, useEffect } from "react";
import { TicketMessageDto } from "dto/support/ticketmessage.dto";
import RequestListDTO from "dto/app/requestlist.dto";
import { CommonTools } from "tools/commontools";
import { useResource } from "hook/useResource";

import TicketMessageService from "services/support/ticketmessage.service";
import RequestFilterDTO from "dto/app/requestfilter.dto";
import { Status } from "tools/status";
import { RouteTools } from "tools/routetools";
import ResultListDTO from "dto/app/resultlist.dto";
import {
    Box,
    Grid,
    List,
    ListItem,
    ListItemButton,
    ListItemText,
    Skeleton,
    Typography
} from "@mui/material";
import { TicketMessageItem } from "./TicketMessageItem";
import { ComponentCommonTools } from "tools/componentcommontools";


const ticketMessageService = new TicketMessageService();

type TicketMessageListProps = {
    idTicket: string;
    objWasChanged: string;

}
const TicketMessageList: React.FC<TicketMessageListProps> = ({
    idTicket,
    objWasChanged,
    ...props }) => {

    const { LL ,CC} = useResource();


    const [isLoading, setIsLoading] = useState(true);
    const [objects, setObjects] = useState<Array<TicketMessageDto>>([]);

    const [page, setPage] = useState<number>(1);
    const [total, setTotal] = useState<number>(-1);
    const [totalPage, setTotalPage] = useState<number>(-1);
    const [responseParams, setResponseParams] = useState<any>({});
    

    const [onPage, setOnPage] = useState<number>(parseInt(CC('OnPageTicketMessageList', '9999')));
    const [reqList, setReqList] = useState<RequestListDTO>(new RequestListDTO());



    const getList = () => {

        reqList.onpage = onPage;
        reqList.page = page;
        reqList.filters = [];

        let f: RequestFilterDTO;

        f = new RequestFilterDTO();
        f.field = "idticket";
        f.values = [idTicket];
        reqList.filters.push(f);

        setIsLoading(true);
        ticketMessageService.getList(loadObjects, {}, reqList);
    };


    const loadObjects = (result: ResultListDTO) => {
        if (!result) return;
        if (!result.err) {
            const objects = result.objects ? result.objects : [];
            const total = result.total !== undefined ? result.total : -1;
            const totalPage = result.totalpages !== undefined ? result.totalpages : -1;
            const reqInfo = result.requestinfo ? result.requestinfo : {};
            setObjects(objects);
            setTotal(total);
            setTotalPage(totalPage);
            setResponseParams(reqInfo);
        }
    }

    const checkLoading = () => {
        let load = false;

        if (total === -1) return true;
        if (totalPage === -1) return true;
        if (!objects) load = true;
        // if (objects.length === 0) load = true;
        if (Object.keys(responseParams).length === 0) return true;

        setIsLoading(load);
    }

    useEffect(() => {
        getList();
    }, [idTicket, objWasChanged]);

    useEffect(() => {
        checkLoading();
    }, [objects]);

    const processList = () => {
        if (isLoading) return <Skeleton variant="rectangular" />
        if (!objects.length) return <></>;

        return (
            <List>
                {objects.map((item: TicketMessageDto, i: number) => {
                    return processItem(item, i);
                })}
            </List>
        );
    };

    const processItem = (obj: TicketMessageDto, index: number) => {
        if (!obj) return <></>;

        return (
            <ListItem key={index}>
                <TicketMessageItem obj={obj} />
            </ListItem>);

    };



    const processComponent = () => {
        return (
            <Box >
                {processList()}
            </Box>

        );
    }

    return processComponent();
};

export { TicketMessageList };
