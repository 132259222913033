import React from "react";
import Rating from '@mui/material/Rating';
import { Stack, Typography } from "@mui/material";
import { CommonTools } from "tools/commontools";
import { styled } from '@mui/material/styles';
import StarIcon from '@mui/icons-material/Star';

type RatingInputProps = {
    [x: string]: any; 
    field: string;
    value?: number;
    setObjField: (field: string, value: any) => void;
    precision?: number;
};

const StyledRating = styled(Rating)({
    '& .MuiRating-iconFilled': {
        color: '#FFD102',
    },
    '& .MuiRating-iconHover': {
        color: '#FFD102',
    },
});

const RatingInput: React.FC<RatingInputProps> = ({
    field,
    value = 0,
    setObjField,
    precision = 0.1,
    ...props }) => {

    const handleChange = (event: React.SyntheticEvent, newValue: number | null) => {
        if (newValue === null) return;
        setObjField(field, newValue);
    }

    return (
        <StyledRating
            onChange={handleChange}
            precision={precision}
            value={value}
            {...props}
        />
    )

};

export { RatingInput };
