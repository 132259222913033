import React, { useEffect, useState } from "react";
import PageComponentProps from "interfaces/pagecomponentprops.interface";
import { useResource } from "hook/useResource";
import { logger } from "hoc/logger";
import MenuService from "services/menu/menu.service";
import { BottomMenuLevel } from "./BottomMenuLevel";

const BottomMenuBlock: React.FC<PageComponentProps> = ({
  currentRoute,
  ...props
}) => {
  
  return <BottomMenuLevel currentRoute={currentRoute} level={1} idparent={""} />; 
};

export { BottomMenuBlock };
