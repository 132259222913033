import React from "react";
import PageComponentProps from "interfaces/pagecomponentprops.interface";
import { BecomeTeacher } from "components/teacher/BecomeTeacher"

const TeacherSignUp: React.FC<PageComponentProps> = ({ currentRoute, ...props }) => {
  return <>
    <BecomeTeacher currentRoute={currentRoute} />
  </>
};

export { TeacherSignUp };
