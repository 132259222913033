import React, { useEffect, useState } from "react";
import { useResource } from "hook/useResource";
import { useAuth } from "hook/useAuth";
import { useGoogleRecaptcha } from "hook/useGoogleRecaptcha";
import PageComponentProps from "interfaces/pagecomponentprops.interface";
import AppBar from "@mui/material/AppBar";
import LockOpenIcon from "@mui/icons-material/LockOpen";

import SignInDto from "dto/auth/signin.dto";

import Button from "@mui/material/Button";
import { Loading } from "components/general/Loading";
import MyTextField from "components/form/mytextfield";
import MyCheckboxField from "components/form/mycheckboxfield";
import RequiredValidator from "validators/required.validator";
import { LabelTypes } from "tools/labeltypes";
import ValidateTools from "tools/validate.tools";
import Container from "@mui/material/Container";
import InputAdornment from "@mui/material/InputAdornment";

import MailOutlineIcon from "@mui/icons-material/MailOutline";

import Box from "@mui/material/Box";
import { logger } from "hoc/logger";
import Typography from "@mui/material/Typography";
import { MyNavBt } from "components/general/MyNavBt";
import { MyButton } from "components/general/MyButton";
import { RouteTools } from "tools/routetools";
import { AuthMethodsBlock } from "components/auth/AuthMethodsBlock";

const Login: React.FC<PageComponentProps> = ({ currentRoute, ...props }) => {
  const { signIn } = useAuth();

  const { executeRecaptcha } = useGoogleRecaptcha();

  const { LL } = useResource();

  const [isLoading, setIsLoading] = useState(true);
  const [isFormDisabled, setIsFormDisabled] = useState(true);

  const [obj, setObj] = useState(new SignInDto());

  const [validators, setValidators] = useState(false);
  const [vresults, setVResults] = useState<any>(false);

  const [remember, setRemember] = useState(false);

  const validateAll = () => {
    if (!obj) return;
    if (!validators) return;
    if (vresults) return;

    obj.identifier = obj.identifier != undefined ? obj.identifier : "";
    obj.password = obj.password != undefined ? obj.password : "";
    setObj(obj);

    let t = validateField("identifier", obj.identifier);
    t = validateField("password", obj.password, t);
  };

  const validateField = (field: any, value: any, v?: any) => {
    v = v ? v : JSON.parse(JSON.stringify(vresults));
    if (v === false) v = {};
    v[field] = ValidateTools.validate(field, value, validators, vresults);

    setVResults(v);
    return v;
  };

  const processValidators = () => {
    let v: any = {
      identifier: [],
      password: [],
    };

    // v.identifier.push(new RequiredValidator(LL('Required field identifier', LabelTypes.LABEL_VALIDATION), ''));
    // v.password.push(new RequiredValidator(LL('Required field password', LabelTypes.LABEL_VALIDATION), ''));

    v.identifier.push(new RequiredValidator("", ""));
    v.password.push(new RequiredValidator("", ""));

    setValidators(v);
  };

  const checkIsLoading = () => {
    if (!isLoading) return;
    if (!obj) return;
    if (!validators) return;
    validateAll();
    setIsLoading(false);
  };

  useEffect(() => {
    processValidators();
  }, []);

  useEffect(() => {
    checkIsLoading();
  }, [obj, validators]);

  useEffect(() => {
    checkIsFormDisabled();
    logger("vresultsvresultsvresultsvresults", vresults);
  }, [vresults]);

  const setObjField = (field: any, value: any) => {
    let t = JSON.parse(JSON.stringify(obj));
    t[field] = value;
    setObj(t);

    validateField(field, value);
  };

  const setObjRemember = (value: any) => {
    setRemember(value);
  };

  const checkIsFormDisabled = () => {
    var rez = ValidateTools.vForm(vresults, "identifier");
    rez = ValidateTools.vForm(vresults, "password", rez);

    setIsFormDisabled(rez);
  };

  const goToSignUp = () => {
    RouteTools.setHistory("/signup", {});
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    if (isFormDisabled) return false;

    await executeRecaptcha();
    signIn(obj, remember);
  };

  return isLoading ? (
    <Loading />
  ) : (
    <Container maxWidth="xs">
      <Box
        mt={5}
        sx={{
          alignItems: "center",
          alignContent: "center",
          width: "100%",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <Typography variant="h2" align="center" sx={{ mb: 5 }}>
          {LL("Autentificare în cont")}
        </Typography>

        <form onSubmit={handleSubmit}>
          <Box sx={{ mb: 4 }}>
            <MyTextField
              fullWidth
              id="identifier"
              name="identifier"
              className="form-input"
              variant="standard"
              _label={LL("Identifier Login")}
              value={obj.identifier}
              setObj={setObjField}
              _vresults={
                vresults && vresults.identifier ? vresults.identifier : false
              }
              _applyClass={true}
            />
          </Box>
          <Box sx={{ mb: 2 }}>
            <MyTextField
              fullWidth
              type="password"
              id="password"
              name="password"
              className="form-input"
              variant="standard"
              _label={LL("Password")}
              value={obj.password}
              setObj={setObjField}
              _vresults={
                vresults && vresults.password ? vresults.password : false
              }
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <MyCheckboxField
              id="remember"
              name="remember"
              _label={LL("remember")}
              setObj={setObjRemember}
              checked={remember}
              color="primary"
              size="medium"
              _vresults={undefined}
            />

            <MyNavBt
              href={"/forgotpassword"}
              children={LL("forgot_password?")}
              size="small"
              _hstate={{}}
            />
          </Box>

          <Box mt={2}>
            <Button
              disabled={isFormDisabled}
              color="primary"
              fullWidth
              variant="contained"
              type="submit"
              className="btn"
            >
              {LL("Bt Login")}
            </Button>
          </Box>
        </form>

        <Typography className="text-strike" my={4}>
          {LL("create_account?")}
        </Typography>
        <AuthMethodsBlock isLoginPage={true} />
      </Box>
    </Container>
  );
};

export { Login };
