import React, { useEffect, useState } from "react";
import { useResource } from "hook/useResource";
import { logger } from "hoc/logger";
import { MyNavBt } from "components/general/MyNavBt";
import {
    Box,
    Card,
    CardActions,
    CardContent,
    Typography,
    Stack,
    Link,
    Divider,
    Grid
} from "@mui/material";
import { CommonTools } from "tools/commontools";
import { ComponentCommonTools } from 'tools/componentcommontools'
import { TestResultDto } from "dto/course/testresult.dto";
import { MyAvatar } from "components/elements/MyAvatar/MyAvatar";
import CastForEducationIcon from '@mui/icons-material/CastForEducation';
import { TestResultQuestionDialog } from "./TestResultQuestionDialog";
import { StatusChip } from "components/elements/Chip/StatusChip";

type TestResultItemProps = {
    obj: TestResultDto;
}

const TestResultItem: React.FC<TestResultItemProps> = ({
    obj,

    ...props
}) => {
    const { LL } = useResource();

    const processUserName = () => {
        if (!obj) return <></>;
        if (!obj.hasOwnProperty('student')) return <></>;
        if (!obj.student) return <></>;
        if (!obj.student.hasOwnProperty('user')) return <></>;
        if (!obj.student.user) return <></>;
        let fullUrl = '';
        if (obj.student.user.hasOwnProperty('fullurl') && obj.student.user.fullurl) fullUrl = obj.student.user.fullurl;

        if (!fullUrl) {
            return (
                <Typography className="name">{processUserFullName()}</Typography>
            )
        }
        return (
            <Link href={fullUrl} target="_blank" rel="noopener noreferrer" className="name">
                <Typography>{processUserFullName()}</Typography>
            </Link>
        )
    }

    const processUserFullName = () => {
        if (!obj) return '';
        if (!obj.hasOwnProperty('student')) return '';
        if (!obj.student) return '';
        let name = ''
        let surname = ''
        if (obj.student.hasOwnProperty('name') && obj.student.name) name = obj.student.name
        if (obj.student.hasOwnProperty('surname') && obj.student.surname) surname = obj.student.surname


        return name + ' ' + surname;
    }
    const processAvatar = () => {
        if (!obj) return <></>;
        if (!obj.hasOwnProperty('student')) return <></>;
        if (!obj.student) return <></>;
        let name = ''
        let surname = ''
        if (obj.student.hasOwnProperty('name') && obj.student.name) name = obj.student.name
        if (obj.student.hasOwnProperty('surname') && obj.student.surname) surname = obj.student.surname

        return <MyAvatar obj={obj.student} name={name} surname={surname} sx={{ mr: 1 }} />
    }
    const processUser = () => {
        if (!obj) return <></>;
        return (
            <Box mt={1}>
                <Stack direction="row" alignItems="center" spacing={1}>
                    {processAvatar()}
                    <Stack direction="column">
                        {processUserName()}
                        <Typography>
                            {ComponentCommonTools.processObjectFieldOfObject(obj, 'student', 'email')}
                        </Typography>
                    </Stack>
                </Stack>
            </Box>
        )
    }
    const processCourseName = () => {
        if (!obj) return <></>;
        if (!obj.hasOwnProperty('course')) return <></>;
        if (!obj.course) return <></>;
        let fullUrl = '';
        if (obj.course.hasOwnProperty('fullurl') && obj.course.fullurl) fullUrl = obj.course.fullurl;
        if (!fullUrl) {
            return ComponentCommonTools.processObjectFieldOfObject(obj, 'course', 'name')
        } else {
            return (
                <Link href={fullUrl} target="_blank" rel="noopener noreferrer"  >
                    {ComponentCommonTools.processObjectFieldOfObject(obj, 'course', 'name')}
                </Link>

            )
        }
    }
    const processCourseInfo = () => {
        if (!obj) return <></>;
        if (!obj.hasOwnProperty('course')) return <></>;
        if (!obj.course) return <></>;
        return (<Box>
            <Stack direction="row" spacing={1}>
                <Typography variant="h5"  className="link">
                    {processCourseName()}
                </Typography>
            </Stack>
        </Box>)
    }

    const processTestInfo = () => {
        if (!obj) return <></>;
        if (!obj.hasOwnProperty('test')) return <></>;
        if (!obj.test) return <></>;
        return (
            <Box>
                <Typography variant="h5">
                    {ComponentCommonTools.processObjectFieldOfObject(obj, 'test', 'name')}
                </Typography>
                <Typography className="color-boulder">{ComponentCommonTools.processTypesField(obj.test, 'type', true)}</Typography>
                <Stack direction='row' spacing={2}>
                    <Box>
                        <Typography  variant="caption" color='text.disabled'>
                            {LL('minscore')}
                        </Typography>
                        <Typography>{ComponentCommonTools.processObjectField(obj, 'minscore')}</Typography>
                    </Box>
                    <Box>
                    <Typography  variant="caption" color='text.disabled'>
                        {LL('maxscore')}
                    </Typography>
                    <Typography>{ComponentCommonTools.processObjectField(obj, 'maxscore')}</Typography>
                    </Box>

                </Stack>
                    

                    



                   



            </Box>
        )
    }
    const processTestResult = () => {
        if (!obj) return <></>;
        return (
            <Box mb={2}>
                <Stack direction='row' spacing={2}>
                    <Box>
                        <Typography variant="caption" color='text.disabled'>
                            {LL('date')}
                        </Typography>
                        <Typography>
                            {ComponentCommonTools.processObjectField(obj, 'date_name')}
                        </Typography>
                    </Box>
                    <Box>
                        <Typography variant="caption" color='text.disabled'>
                            {LL('totalscore_student')}
                        </Typography>
                        <Typography>
                            {ComponentCommonTools.processObjectField(obj, 'totalscore')}
                        </Typography>
                    </Box>
                    <Box>
                    <Typography variant="caption" color='text.disabled'>
                        {LL('percentage')}
                    </Typography>
                    <Typography>
                        {ComponentCommonTools.processObjectField(obj, 'percentage')}
                    </Typography>
                    </Box>
                    <Typography>
                        {/* {ComponentCommonTools.processObjectField(obj, 'status_name')} */}
                        <StatusChip obj={obj} variant='outlined'/>
                    </Typography>
                </Stack>
                    

            </Box>
        )

    }
    const processTestResultQuestions = () => {
        if(!obj) return <></>;
        if(!obj.hasOwnProperty('id')) return <></>;
        if(!obj.id) return <></>;
        return (<TestResultQuestionDialog idTestResult={obj.id}/>)
    }
    const processItem = () => {
        if (!obj) return <></>;
        return (
            <Box className='testResultItem'>
                <Grid container spacing={2} alignItems='center'>
                    <Grid item lg={3} sm={12}>{processTestInfo()}</Grid>
                    <Grid item lg={5} sm={12}>{processCourseInfo()}{processUser()}</Grid>
                    <Grid item lg={4} sm={12}>
                        {processTestResult()}
                        {processTestResultQuestions()}
                    </Grid>
                </Grid>
            </Box>
        )
    }
    return processItem();
}

export { TestResultItem };