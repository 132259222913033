import React, { useEffect, useState } from "react";
import { useResource } from "hook/useResource";
import { logger } from "hoc/logger";
import { MyNavBt } from "components/general/MyNavBt";
import {
    Box,
    Card,
    CardActions,
    CardContent,
    Typography,
    Stack,
    Link,
    Accordion,
    AccordionDetails,
    AccordionSummary
} from "@mui/material";
import { CommonTools } from "tools/commontools";
import { ComponentCommonTools } from 'tools/componentcommontools'
import { TestResultQuestionDto } from "dto/course/testresultquestion.dto";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { TestResultAnswerList } from "./TestResultAnswerList";

type TestResultItemQuestionProps = {
    obj: TestResultQuestionDto;
}

const TestResultItemQuestion: React.FC<TestResultItemQuestionProps> = ({
    obj,

    ...props
}) => {
    const { LL } = useResource();

    const [expend, setExpend] = useState(false);
    const handleExpend = (event: React.SyntheticEvent, expanded: boolean) => {
        setExpend(expanded);
    }

    const processQuestionInfo = () => {
        if (!obj) return <></>;
        if (!obj.hasOwnProperty('question')) return <></>;
        if (!obj.question) return <></>;

        return (
            <Box>

                    <Typography variant="h6" sx={{fontWeight:700}}>
                        {ComponentCommonTools.processObjectFieldOfObject(obj, 'question', 'name')}
                    </Typography>
                    <Typography className="boulder">{ComponentCommonTools.processTypesField(obj.question, 'type', true)}</Typography>

            </Box>)
    }

    const processQuestionResultInfo = () => {
        if (!obj) return <></>;
        

        return (
            <Box>
                
                <Stack direction="row" spacing={2}>
                <Box>
                    <Typography variant="caption"  color='text.disabled'>
                        {LL('maxquestionscore')}
                    </Typography>
                    <Typography>
                        {ComponentCommonTools.processObjectField(obj, 'maxquestionscore')}
                    </Typography>
                </Box>
                <Box>
                    <Typography variant="caption"  color='text.disabled'>
                        {LL('questiontotalscore_by_student')}
                    </Typography>
                    <Typography>
                        {ComponentCommonTools.processObjectField(obj, 'questiontotalscore')}
                    </Typography>
                </Box>
                </Stack>

            </Box>)
    }
    const processItem = () => {
        if (!obj) return <></>;

        return (
            <Accordion
                expanded={expend}
                className="accordionItem"
                onChange={handleExpend}
                sx={{width: '100%'}}
            >
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Stack direction={'column'} >
                        {processQuestionInfo()}
                        {processQuestionResultInfo()}
                    </Stack>
                </AccordionSummary>
                <AccordionDetails className="accordionDetailLesson">
                    {processItemDetails()}
                </AccordionDetails>
            </Accordion>
        )
    }

    const processItemDetails = () => {
        if (!obj) return <></>;
        if (!expend) return <></>;
        if(!obj.hasOwnProperty('id')) return <></>;
        if(!obj.id) return <></>;
        if(!obj.hasOwnProperty('idtestresult')) return <></>;
        if(!obj.idtestresult) return <></>; 
        return <TestResultAnswerList idTestResult={obj.idtestresult} idTestResultQuestion={obj.id}/>
    }

    return processItem()
}

export { TestResultItemQuestion }