import React, { useEffect, useState } from "react";
import { useResource } from "hook/useResource";

import { logger } from "hoc/logger";

import FacebookLogin from "react-facebook-login";


import { GoogleLogin } from "@react-oauth/google";

import { useAuth } from "hook/useAuth";

import SocialSignInDto from "dto/auth/socialsignin.dto";
import { SocialIdentifier } from "tools/socialidentifier";

type MyFaceBookLoginButton = {};

const MyFaceBookLoginButton: React.FC<MyFaceBookLoginButton> = ({
  ...props
}) => {
  const { signInSocial } = useAuth();
  const { LL } = useResource();

  const responseFacebook = (response: any) => {
    // console.log(responseFacebook, response);
    
    const obj = new SocialSignInDto();

    obj.socialid = response.id;
    obj.socialuseridentifier = response.email;
    obj.socialidentifier = SocialIdentifier.FACEBOOK.toString();
    obj.socialtoken = response.signedRequest;
    obj.requestObject = response;
    signInSocial(obj);
  };

  const errorMessage = (e?: any) => {
    logger("error FaceBook", e);
  };

  const appId:string = process.env.REACT_APP_FBCONSOLE_APP_ID ?? "";

  return (
    <>
      <FacebookLogin
        appId={appId}
        autoLoad={false}
        fields="name,email,picture"
        // onClick={componentClicked}
        callback={responseFacebook}
        cssClass="btn-auth-social"
        icon="fa-facebook"
      />
    </>
  );
};

export { MyFaceBookLoginButton };
