import React, { useState } from "react";
import PageComponentProps from "interfaces/pagecomponentprops.interface";
import { useResource } from "hook/useResource";
import { logger } from "hoc/logger";

import { MyNavBt } from "components/general/MyNavBt";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { AdvertisementSearch } from "components/advertisement/AdvertisementSearch";
const HomeSearchBlock: React.FC<PageComponentProps> = ({ currentRoute, ...props }) => {
  const { LL } = useResource();

  const [search, setSearch] = useState("");

  const handleChange = (event: any) => {
    setSearch(event.target.value);
  };


  const handleSubmit = (event: any) => {
    event.preventDefault();

    logger("SearchBlockSearchBlock", search);
  };

  return (<AdvertisementSearch currentRoute={currentRoute}/>  );
};

export { HomeSearchBlock };
