import React, { useEffect, useState } from "react";
import PageComponentProps from "interfaces/pagecomponentprops.interface";
import { CommonTools } from "tools/commontools";
import { Box, Skeleton, Typography, Grid, Stack } from "@mui/material";
import { CourseDto } from "dto/course/course.dto";
import ResultObjectDTO from "dto/app/resultobject.dto";
import CourseService from "services/course/course.service";
import { HtmlTag } from "components/general/HtmlTag";
import { useResource } from "hook/useResource";
import { CourseLessonsList } from "components/course/lesson/CourseLessonsList";
import { TeacherInfoBlock } from "components/teacher/TeacherInfoBlock";
import { CourseVideo } from "components/course/course/CourseVideo";

import { CourseTestimonials } from "components/course/course/CourseTestimonials";
import { CourseListByTeacher } from "components/course/course/CourseListByTeacher";
import { NewsletterBlock } from "components/general/NewsletterBlock";
import { TeacherProcessNameCourse } from "components/teacher/TeacherProcessNameCourse";
import { MyAvatar } from "components/elements/MyAvatar/MyAvatar";
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import DateTools from "tools/date.tools";
import { TeacherFAQBlock } from "components/teacher/TeacherFAQBlock";
import { CourseEnrollmentDialog } from "components/course/courseenrollment/CourseEnrollmentDialog";
import { type } from "os";

const courseService = new CourseService();
type   GeneralInfoCourseTabProps = {
    obj: CourseDto;
}
const GeneralInfoCourseTab: React.FC<GeneralInfoCourseTabProps> = ({ obj, ...props }) => {

    const { LL } = useResource();
  
    const processDescription = () => {
        if (!obj) return <></>;
        if (!obj.hasOwnProperty('description')) return <></>;
        if (!obj.description) return <></>;
        return (<Box className="detailsGeneral" pt={5}>
            <Typography variant="h3">
                {LL('Description')}
            </Typography>
            <HtmlTag content={obj.description} />
        </Box>)
    }

    const processRequirementsText = () => {
        if (!obj) return <></>;
        if (!obj.hasOwnProperty('requirementstext')) return <></>;
        if (!obj.requirementstext) return <></>;
        return (<Box className="detailsGeneralround" mt={5}>
            <Typography variant="h3" >
                {LL('requirementstext')}
            </Typography>
            <HtmlTag content={obj.requirementstext} />
        </Box>)
    }

    const processWhatWillYouLearnText = () => {
        if (!obj) return <></>;
        if (!obj.hasOwnProperty('whatwillyoulearntext')) return <></>;
        if (!obj.whatwillyoulearntext) return <></>;
        return (<Box className="detailsGeneral" pt={5}>
            <Typography variant="h3">
                {LL('whatwillyoulearntext')}
            </Typography>
            <HtmlTag content={obj.whatwillyoulearntext} />
        </Box>)
    }


    const processTargetAudienceText = () => {
        if (!obj) return <></>;
        if (!obj.hasOwnProperty('targetaudiencetext')) return <></>;
        if (!obj.targetaudiencetext) return <></>;
        return (<Box className="detailsGeneralround " mt={5}>
            <Typography variant="h3">
                {LL('targetaudiencetext')}
            </Typography>
            <HtmlTag content={obj.targetaudiencetext} />
        </Box>)
    }

    const processTeacherInfo = () => {
        if (!obj) return <></>
        if (!obj.hasOwnProperty('idteacher')) return <></>
        if (!obj.idteacher) return <></>
        return (<Box mt={5}>
            <Typography variant="h3" mb={3}>{LL('TeacherInfo')}</Typography>
            <TeacherInfoBlock id={obj.idteacher} />
        </Box>)
    }

    const processCourseVideo = () => {
        if (!obj) return <></>;
        if (!obj.hasOwnProperty('video')) return <></>;
        if (!obj.video) return <></>;
        if (!obj.video.length) return <></>;

        return (
            <Box mt={5}>
                <Typography variant="h3" mb={3}>{LL('CourseVideo')}</Typography>
                <Stack direction={{ sm: 'column', md: 'row' }} spacing={2} alignItems='center'>
                    <CourseVideo obj={obj} width={420} height={235} controls={true} />
                    <Box className="courseVideoInfo">
                        <InfoOutlinedIcon color="primary" sx={{ fontSize: '30px' }} />
                        <Typography>{LL('CourseVideo description alert video')}</Typography>
                    </Box>
                </Stack>
            </Box>
        )
    }

   
    return  obj ?
        (<Box className="detailCourse">
            
            <Box className="container">
                <Grid container>
                    <Grid item>
                        {processWhatWillYouLearnText()}
                        {processDescription()}
                        {processRequirementsText()}
                        {processTargetAudienceText()}
                        {processCourseVideo()}
                        {processTeacherInfo()}
                    </Grid>
                </Grid>
            </Box>
        </Box>) : (<></>);
};

export { GeneralInfoCourseTab };
