import React, { useEffect, useState, useRef } from "react";

import { useResource } from "hook/useResource";

import { IconButton, Dialog, DialogContent, DialogTitle } from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import { LessonDto } from "dto/course/lesson.dto";
import { HtmlTag } from "components/general/HtmlTag";

type StudentLessonDialogDescriptionProps = {
  open: boolean;
  setOpen: any;
  obj: LessonDto;
};
const StudentLessonDialogDescription: React.FC<
  StudentLessonDialogDescriptionProps
> = ({ open, setOpen, obj, ...props }) => {
  const { LL, CC } = useResource();

  const handleClose = () => {
    setOpen(false);
  };

  const processDescription = () => {
    if (!obj) return <></>;
    if (!obj.hasOwnProperty("description")) return <></>;
    if (!obj.description) return <></>;
    return (<HtmlTag content={obj.description} />)
}

  return (
    <>
      <Dialog open={open} onClose={handleClose} fullWidth>
        <DialogTitle>{LL("Description")}</DialogTitle>
        <IconButton
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
            {processDescription()}
        </DialogContent>
      </Dialog>
    </>
  );
};

export { StudentLessonDialogDescription };
