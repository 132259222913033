import Idto from "interfaces/idto.interface";

export class LanguageDto implements Idto {

    id?: string;
    name?: string;
    cod2?: string;
    cod3?: string;
    status?: number;
    ordercriteria?: number;
    hmtlcode?: string;
    status_name?: any;
}
