import React, { useEffect, useState, useRef } from "react";
import { useResource } from "hook/useResource";
import { logger } from "hoc/logger";
import {
    Box,
    Typography,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Skeleton,
    Stack
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import { CommonTools } from "tools/commontools";

import { CircularLoading } from "components/general/CircularLoading";
import { MyButton } from "components/general/MyButton";
import { TestResultQuestionList } from "./TestResultQuestionList";


type TestResultQuestionDialogProps = {
    idTestResult: string;
}
const TestResultQuestionDialog: React.FC<TestResultQuestionDialogProps> = ({
    idTestResult,
    ...props
}) => {

    const { LL } = useResource();

    const [open, setOpen] = useState<boolean>(false);

    const handleClose = () => {
        setOpen(false);
    };

    const handleOpen = () => {
        setOpen(true);
    };

    const processList = () => {
        if (!idTestResult) return <></>;
        if (!open) return <></>;
        return <TestResultQuestionList idTestResult={idTestResult} />
    }

    return (<Box>
        <MyButton cb={handleOpen}  className="color-black-pearl">
            {LL("Review_question_answer")}
        </MyButton>
        <Dialog
            open={open}
            onClose={handleClose}
            fullWidth
            className="dialogAdd"

        >
            <DialogTitle>{LL("TestResultQuestion")}</DialogTitle>
            <IconButton sx={{
                position: 'absolute',
                right: 8,
                top: 8,
            }} onClick={handleClose}>
                <CloseIcon />
            </IconButton>
            <DialogContent dividers>
                {processList()}
            </DialogContent>

        </Dialog>
    </Box>)
}
export { TestResultQuestionDialog };