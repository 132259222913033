import Idto from 'interfaces/idto.interface';
import { Status } from 'tools/status';
import { Types } from 'tools/types';

export class AnswerDto implements Idto {
    
    id?: string;
    name?: string;
    status?: number;
    type?: number;
    ordercriteria?: number;
    score?: number;
    idquestion?: string;
  }

  export class PostAnswerDto implements Idto {
    
    name?: string;
    status?: number;
    type?: number;
    ordercriteria?: number;
    score?: number;
    idquestion?: string;

    constructor() {
        this.status = Status.ACTIVE;
        this.ordercriteria = 0;
        this.score = 0;
        this.type = Types.FALSE_ANSWER;
        this.name = '';
    }
  }