import { useEffect } from "react";
import axios from "axios";
import GeneralRepository from "repositories/general.repository";

const RECAPTCHA_SITE_KEY = process.env.REACT_APP_RECAPTCHA_SITE_KEY ?? "";
var recaptchaToken = "";

export function useGoogleRecaptcha() {
  useEffect(() => {
    GeneralRepository.setRecaptchaToken(getRecaptchaToken);

    const loadRecaptchaScript = () => {
      const script = document.createElement("script");
      script.src = `https://www.google.com/recaptcha/api.js?render=${RECAPTCHA_SITE_KEY}`;
      script.async = true;
      document.body.appendChild(script);
    };

    loadRecaptchaScript();

    return () => {
      const script = document.querySelector(
        'script[src="https://www.google.com/recaptcha/api.js"]'
      );
      if (script) {
        document.body.removeChild(script);
      }
    };
  }, []);

  const getRecaptchaToken = () => {
    return recaptchaToken;
  };

  const executeRecaptcha = async () => {
    const token = await new Promise((resolve) => {
      window.grecaptcha.ready(async () => {
        const response = await window.grecaptcha.execute(RECAPTCHA_SITE_KEY, {
          action: "submit",
        });
        resolve(response);
      });
    });
    recaptchaToken = token;

    return token;
  };

//   const verifyRecaptcha = async () => {
//     if (!recaptchaToken) {
//       throw new Error("Recaptcha token is not available.");
//     }

//     const response = await axios.post("YOUR_VERIFICATION_ENDPOINT", {
//       // Send the token to your backend for verification
//       token: recaptchaToken,
//       // Add any additional data required for verification
//     });

//     // Handle the verification response from your backend
//     console.log(response.data);
//   };

  return { executeRecaptcha };
}
