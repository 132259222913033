import React, { useState, useEffect } from "react";
import { useResource } from "hook/useResource";
import { LessonDto } from "dto/course/lesson.dto";
import RequestFilterDTO from "dto/app/requestfilter.dto";
import RequestListDTO from "dto/app/requestlist.dto";
import { Status } from "tools/status";
import LessonService from "services/course/lesson.service";
import ResultListDTO from "dto/app/resultlist.dto";
import { Accordion, AccordionDetails, AccordionSummary, Box, IconButton, Skeleton, Typography, Stack } from "@mui/material";
import { logger } from "hoc/logger";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ComponentCommonTools } from "tools/componentcommontools";
import { LessonSettingsItem } from 'components/course/lessonsettings/LessonSettingsItem'

import { CommonTools } from "tools/commontools";
import ClearIcon from '@mui/icons-material/Clear';
import RequestCriteriaDTO from "dto/app/requestcriteria.dto";
import { Types } from "tools/types";

import SelectOptionSort from "dto/app/selectoptionsort.dto";
import RequestSortCriteriaDTO from "dto/app/requestsortcriteria.dto";
import { MySortSelect } from "components/elements/Select/MySortSelect";
import { MyButton } from "components/general/MyButton";
import { LoadMoreButton } from "components/elements/MyButton/LoadMoreButton";
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { AttachmentList } from "components/attachment/AttachementList";
import { GalleryList } from "components/gallery/GalleryList";
import { VideoList } from "components/video/VideoList";
import { CourseLessonsListItem } from "./CourseLessonListItem";


type CourseLessonsListProps = {
    idCourse: string;
}

const lessonService = new LessonService();

const CourseLessonsList: React.FC<CourseLessonsListProps> = ({

    idCourse,

    ...props }) => {

    const { LL, CC } = useResource();
    const [isLoading, setIsLoading] = useState(true);
    const [objects, setObjects] = useState<Array<LessonDto>>([]);
    const [total, setTotal] = useState<number>(-1);
    const [totalPage, setTotalPage] = useState<number>(-1);
    const [onPage, setOnPage] = useState<number>(parseInt(CC('DefaultOnPageCourseLessonsList', 10)));

   
    const processLoadMoreObjects = () => {
        if (!objects || !objects.length) return <></>;
        return <LoadMoreButton
            objects={objects}
            total={total} onPage={onPage}
            setOnPage={setOnPage}
            step={parseInt(CC('DefaultOnPageCourseLessonsList', 4))}
            className="loadMorebtn"
        />
    }
    const getList = () => {

        setIsLoading(true);
        const reqList = new RequestListDTO();
        reqList.onpage = onPage;
        reqList.page = 1;
        reqList.filters = [];
        reqList.sortcriteria = [];

        const sortCriteria = new RequestSortCriteriaDTO();
        sortCriteria.field = "ordercriteria";
        sortCriteria.asc = true;
        reqList.sortcriteria.push(sortCriteria);

        let f: RequestFilterDTO;

        f = new RequestFilterDTO();
        f.field = "status";
        f.values = [];
        f.values.push(Status.ACTIVE.toString());
        reqList.filters.push(f);

        f = new RequestFilterDTO();
        f.field = "idcourse";
        f.values = [idCourse];
        reqList.filters.push(f);


        lessonService.getList(loadObjects, {}, reqList);

        // setIsLoading(false);
    };


    const loadObjects = (result: ResultListDTO) => {
        if (!result) return;
        if (!result.err) {
            const objects = result.objects ? result.objects : [];
            const total = result.total !== undefined ? result.total : -1;
            const totalPage = result.totalpages !== undefined ? result.totalpages : -1;
            setObjects(objects);
            setTotal(total);
            setTotalPage(totalPage);
            
        }
    }

    const checkLoading = () => {
        let load = false;

        if (total === -1) return true;
        if (totalPage === -1) return true;
        if (!objects) load = true;
        setIsLoading(load);
    }

    useEffect(() => {
        getList();
    }, [idCourse, onPage]);

    useEffect(() => {
        checkLoading();
    }, [objects]);


    const processLessonItem = (obj: LessonDto, index: number) => {
        if (!obj) return <></>;
        if (!obj.id) return <></>;

        return (<Box key={index}>
            <CourseLessonsListItem obj={obj} />
        </Box>);

    };


    const processLessonList = () => {
        if (!objects.length) return <></>;

        return (
            <>
                {objects.map((item: LessonDto, i: number) => {
                    return processLessonItem(item, i);
                })}
            </>
        );
    };

    return isLoading ? <Skeleton /> : (
        <Box>

            {processLessonList()}

            <Box mt={3}>{processLoadMoreObjects()}</Box>
        </Box>
    );
};

export { CourseLessonsList };
