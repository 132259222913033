import { Skeleton } from "@mui/material";
import { MyButton } from "components/general/MyButton";
import ResultObjectDTO from "dto/app/resultobject.dto";
import { StudentHasCourseDto } from "dto/course/courseenrollment.dto";
import { logger } from "hoc/logger";
import { useAuth } from "hook/useAuth";
import { useResource } from "hook/useResource";
import React, { useState, useEffect } from "react";
import CourseEnrollmentService from "services/course/courseenrollment.service";
import { RouteTools } from "tools/routetools";

type CourseActionButtonProps = {
    idCourse: string;
    courseUrl: string;
    setOpenEnrollmentDialog: any;
    openEnrollmentDialog: boolean;
}

const courseEnrollmentService = new CourseEnrollmentService();
const CourseActionButton: React.FC<CourseActionButtonProps> = ({
    idCourse,
    courseUrl,
    setOpenEnrollmentDialog,
    openEnrollmentDialog,
    ...props }) => {

    const { LL } = useResource();
    const { student, setNextLocation } = useAuth();
    const [loading, setLoading] = useState<boolean>(false);
    const [obj, setObj] = useState<StudentHasCourseDto | undefined>();

    const loadObject = (obj: ResultObjectDTO) => {
        if (!obj) return;
        if (!obj.err) {
            const object = obj.obj ? obj.obj : {};
            setObj(object);
            logger('loadObject', object)
        }
    };

    const getObject = () => {
        if (!student) return;
        setLoading(true);
        courseEnrollmentService.checkStudentHasCourse(idCourse, student.id, loadObject, {});
    };

    const checkIsLoading = () => {
        let load: boolean = false;
        if (obj === undefined) load = true;
        setLoading(load);
    };

    useEffect(() => {
        setLoading(false);
        getObject();
    }, [idCourse]);

    useEffect(() => {
        checkIsLoading();
    }, [obj]);

    const redirectToLogin = () => {
        setNextLocation(courseUrl)
        RouteTools.setHistory('/login', {});
    }

    const redirectToCoursePage = () => {
        if(!obj) return;
        if(!obj.hasOwnProperty('obj')) return;
        if(!obj.obj) return;
        if(!obj.obj.hasOwnProperty('id')) return;
        if(!obj.obj.id) return;
        const url = `/profile/courseenrollment/${obj.obj.id}`
        //Temporar
        RouteTools.setHistory(url, {});
    }

    const processButtonStudentHasCourse = () => {
        return (
            <MyButton
                className='btnSub btn'
                cb={redirectToCoursePage}
            >
                {LL('BT_GoToCourse')}
            </MyButton>
        )
    }

    const handleOpenEnrollmentDialog = () => {
        setOpenEnrollmentDialog(true);
    }
    const processButtonStudentHasNotCourse = () => {
        return (
            <MyButton
                className='btnSub btn'
                cb={handleOpenEnrollmentDialog}
            >
                {LL('BT_SignUpForCourse')}
            </MyButton>
        )
    }

    const processButtonStudentNotLogged = () => {
        return (
            <MyButton
                className='btnSub btn'
                cb={redirectToLogin}
            >
                {LL('BT_SignUpForCourse')}
            </MyButton>
        )
    }

    const processItem = () => {
        if (!student) return processButtonStudentNotLogged();
        if (loading) return <Skeleton variant="rectangular" height={50} />;
        if (!obj) return <></>;
        if (!obj.result) return processButtonStudentHasNotCourse();
        return processButtonStudentHasCourse();
    }


    return processItem();
};

export { CourseActionButton };
