import React, { useEffect, useState } from "react";
import PageComponentProps from "interfaces/pagecomponentprops.interface";
import { useResource } from "hook/useResource";
import { logger } from "hoc/logger";
import MenuService from "services/menu/menu.service";
import { MyNavBt } from "components/general/MyNavBt";
import { useAuth } from "hook/useAuth";
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { RouteTools } from "tools/routetools";
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import { Link, Stack, Typography } from "@mui/material";
import { MyAvatar } from "components/elements/MyAvatar/MyAvatar";
import { Types } from "tools/types";
import { StatusChipTeacher } from "components/teacherinterface/StatusChipTeacher";
const UserTopMenu: React.FC<PageComponentProps> = ({
  currentRoute,
  ...props
}) => {
  const { LL } = useResource();

  const { user, signOut, usersettings, teacher, userInterface } = useAuth();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleLogOut = () => {
    signOut();
    setAnchorEl(null);
  }
  const handleGoToPersonalCabinet = () => {
    RouteTools.setHistory('/profile/edit', {})
    setAnchorEl(null);
  };

  const handleGoToMyCourse = () => {
    RouteTools.setHistory('/profile/course', {})
    setAnchorEl(null);
  };

  const handleGoToPublicProfile = () => {
    if (user && user.id) {
      const url = '/student/' + user.id
      RouteTools.setHistory(url, {})
      setAnchorEl(null);
    }
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const processUserName = () => {

    if (!usersettings) return <></>;
    if (!usersettings.hasOwnProperty('name')) return <></>;
    if (!usersettings.hasOwnProperty('surname')) return <></>;
    if (!usersettings.name) return <></>;
    if (!usersettings.surname) return <></>;

    return (
      <Typography color="textPrimary">{usersettings.name} {usersettings.surname}</Typography>
    )
  }

  const processAvatar = () => {
    if (!usersettings) return <></>;
    if (!usersettings.hasOwnProperty('name')) return <></>;
    if (!usersettings.hasOwnProperty('surname')) return <></>;
    if (!usersettings.name) return <></>;
    if (!usersettings.surname) return <></>;

    return <MyAvatar obj={usersettings} name={usersettings.name} surname={usersettings.surname} sx={{ mr: 1 }} />
  }

  const processAvatarComponent = () => {
    if (!user) return <></>;
    if (userInterface === Types.TEACHER_INTERFACE) {
      return (<Stack

        direction={'column'}
        justifyContent="center"
        alignItems="center"
   
      >
        <Box>{processAvatar()}</Box>
        <Box mr={1}>{processTeacherStatusChip()}</Box>
      </Stack>)
    }
    return processAvatar();

  }

  const processTeacherStatusChip = () => {
    if (!teacher) return <></>;
    return <StatusChipTeacher obj={teacher} />
  }

  const processMenu = () => {
    let viewPublicProfileUrl = ''
    if (userInterface === Types.TEACHER_INTERFACE) {
      if (!teacher) {
        viewPublicProfileUrl = ''

      } else if (!teacher.fullurl) {
        viewPublicProfileUrl = ''
      } else {
        viewPublicProfileUrl = process.env.REACT_APP_WWW_SERVER + teacher.fullurl;
      }
    } else {
      viewPublicProfileUrl = process.env.REACT_APP_WWW_SERVER + '/student/' + user.id
    }

    return (
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={handleGoToPersonalCabinet}>
          <ListItemText>{LL('personal_cabinet')} </ListItemText>
        </MenuItem>
        <MenuItem onClick={handleGoToMyCourse}>
          <ListItemText>{LL('MyCourses')} </ListItemText>
        </MenuItem>
        <MenuItem component={Link}
          target="_blank" rel="noopener noreferrer" href={viewPublicProfileUrl} >
          <ListItemText>{LL('view_public_profile')} </ListItemText>
        </MenuItem>
        <Divider></Divider>
        <MenuItem onClick={handleLogOut}>
          <ListItemText>{LL('Logout')} </ListItemText>
        </MenuItem>
      </Menu>
    )
  }
  return user == undefined ? (
    <></>
  ) : (
    <>
      <Button
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        {/* <Avatar>{processAvatarName()}</Avatar> */}
        {/* <MyAvatar obj={usersettings} name={usersettings.name} surname={usersettings.surname} sx={{ mr: 1 }}/> */}
        {processAvatarComponent()}
        <Box sx={{ textTransform: 'none', textAlign: 'left' }}>
          <Typography color="textPrimary">{processUserName()}</Typography>
          <Typography color="textSecondary" className="subtextBage">{user.email}</Typography>
        </Box>
      </Button>
      {processMenu()}
    </>
  );
};

export { UserTopMenu };