import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useResource } from "hook/useResource";
import { logger } from "hoc/logger";
import AdvertisementService from "services/advertisement/advertisement.service";
import PageComponentProps from "interfaces/pagecomponentprops.interface";
import { AdvertisementDto } from "dto/advertisement/advertisement.dto";
import { RouteTools } from "tools/routetools";
import RequestListDTO from "dto/app/requestlist.dto";
import { Status } from "tools/status";
import RequestFilterDTO from "dto/app/requestfilter.dto";
import ResultListDTO from "dto/app/resultlist.dto";
import { Types } from "tools/types";
import { ComponentCommonTools } from 'tools/componentcommontools'
import { Loading } from "components/general/Loading";
import Skeleton from '@mui/material/Skeleton';
import { Box } from "@mui/material";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { MyNavBt } from "components/general/MyNavBt";
import { CommonTools } from "tools/commontools";
import Grid from '@mui/material/Grid';
import Typography from "@mui/material/Typography";

const advertisementService = new AdvertisementService();

type BannerItemSpecialHomeSection1Props = {
    obj: AdvertisementDto;
}
const AdvertisementBannerItemHomeSuggestions: React.FC<BannerItemSpecialHomeSection1Props> = ({
    obj,
    ...props
}) => {

    const [imgUrl, setImgUrl] = useState<string>("");
    const [fullUrl, setFullUrl] = useState<string>("");
    const width = 60;
    const height = 60;

    const { LL } = useResource();

    const prepareGallery = () => {
        if (!obj) return;

        if (!obj.hasOwnProperty('gallery')) return;
        if (!obj.gallery) return;
        if (obj.gallery.length === 0) return;
        const galleryObj = CommonTools.getGalleryObjectLogo(obj.gallery);
        if (!galleryObj) return;
        CommonTools.setFileUrlsGallery(galleryObj, setImgUrl, setFullUrl, width, height);
    }

    useEffect(() => {
        prepareGallery();
    }, [obj])



    const processImage = () => {
        if (!imgUrl) return (<></>)
        return (<>
            {ComponentCommonTools.getFileBlock(imgUrl, width, height)}
        </>
        )
    }

    const processDetail = (obj: AdvertisementDto) => {
        if (!obj) return <></>;
        return (

            <Box>
                <Typography variant="h5" my={1}>
                    {ComponentCommonTools.processObjectField(obj, 'name')}
                </Typography>
                <Typography className="description">
                    {ComponentCommonTools.processObjectField(obj, 'description')}
                </Typography>
            </Box>

        )
    }

    const processWithoutFullUrl = (obj: AdvertisementDto) => {

        if (!obj) return <></>;

        if (!imgUrl) {

            return (
                <MyNavBt href={obj.fullurl}>
                    {processDetail(obj)}
                </MyNavBt>
            )
        }

        return (
            <Box className="sugestionItem" sx={{width: '100%'}}>
                {(processImage())}
                {processDetail(obj)}
            </Box>
        )
    }

    const processItem = () => {
        if (!obj) return <></>;

        if (obj.hasOwnProperty('fullurl') && obj.fullurl) {
            return (
                <MyNavBt href={obj.fullurl} >
                    {processWithoutFullUrl(obj)}
                </MyNavBt>
            );
        }

        return processWithoutFullUrl(obj)
    }

    return processItem();
};

export { AdvertisementBannerItemHomeSuggestions };
