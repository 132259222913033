import React, { useContext } from "react";
import PageComponentProps from "interfaces/pagecomponentprops.interface";
import { Box, Grid, Typography, Divider } from "@mui/material";
import { ClassroomContext } from "context/classroom/classroom.context";
import { MyNavBt } from "components/general/MyNavBt";
import { ComponentCommonTools } from "tools/componentcommontools";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { useResource } from "hook/useResource";

export const ClassroomTopBar: React.FC<PageComponentProps> = ({
  currentRoute,
}) => {
  const {
    state: { course },
  } = useContext(ClassroomContext);
  const { LL } = useResource();

  const processComponent = () => {
    if (!course) return <></>;
    return (
      <Box className="first-block" py={1}>
        <Grid
          container
          className="container"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box display="flex" alignItems="center" flexWrap="wrap">
            <MyNavBt href={"/teacherinterface/course"} className="back-btn">
              <KeyboardArrowLeftIcon />
              {LL("Revenire la cursuri_btn")}
            </MyNavBt>
            <Divider
              orientation="vertical"
              sx={{ mx: 2, opacity: "0.5" }}
              flexItem
            />
            <Typography variant="h5">
              {ComponentCommonTools.processObjectField(course, "name")}
            </Typography>
          </Box>
        </Grid>
      </Box>
    );
  };
  return processComponent();
};
