import React, { useState, useEffect } from "react";
import { CourseEnrollmentDto } from "dto/course/courseenrollment.dto";
import { Status } from "tools/status";
import { StudentNeedCheckout } from "components/student/StudentNeedCheckout";
import { logger } from "hoc/logger";
import { StudentNeedTest } from "components/student/StudentNeedTest";
import { CourseDto } from "dto/course/course.dto";
import { TestDto } from "dto/course/test.dto";
import { Types } from "tools/types";
import { StaticInfoPage } from "components/page/StaticInfoPage";

type TestStaticContentProps = {
    type: number;
}
const TestStaticContent: React.FC<TestStaticContentProps> = ({
    type,
    ...props
}) => {


    const processComponent = () => {
        switch (type) {
            case Types.TYPE_TEST_BEFORE_COURSE: {
                return <StaticInfoPage
                    idPage={process.env.REACT_APP_ID_PAGE_TEST_BEFORE_COURSE}
                    cacheKeyName="TYPE_TEST_BEFORE_COURSE"
                />;

            }
            case Types.TYPE_TEST_LESSON: {
                return <StaticInfoPage
                    idPage={process.env.REACT_APP_ID_PAGE_TEST_LESSON}
                    cacheKeyName="TYPE_TEST_LESSON"
                />;
            }
            case Types.TYPE_TEST_AFTER_COURSE: {
                return <StaticInfoPage
                    idPage={process.env.REACT_APP_ID_PAGE_TEST_AFTER_COURSE}
                    cacheKeyName="TYPE_TEST_AFTER_COURSE"
                />;
            }
            default: {
                return <></>;
            }

        }
    }


    return processComponent();
};

export { TestStaticContent };
