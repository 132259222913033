import React, { useState } from "react";
import PageComponentProps from "interfaces/pagecomponentprops.interface";
import { useResource } from "hook/useResource";
import { logger } from "hoc/logger";

import { MyNavBt } from "components/general/MyNavBt";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { AdvertisementBannerBottom } from "components/advertisement/AdvertisementBannerBottom";


const HomeBannersBottomBlock: React.FC<PageComponentProps> = ({ currentRoute, ...props }) => {
  const { LL } = useResource();

  return (<AdvertisementBannerBottom currentRoute={currentRoute}/>  );
};

export { HomeBannersBottomBlock };
