import React from "react";


import { useEffect, useState } from "react";

import { useLocation } from "react-router-dom";

import { logger } from "hoc/logger";

import { useReactPath } from "hook/useReactPath";

import { RouteTools } from "tools/routetools";

import MD5 from "crypto-js/md5";

import { useAuth } from "hook/useAuth";

import { v4 as uuidv4 } from "uuid";
import { useResource } from "hook/useResource";
import { Login } from "components/auth/Login";
import { TestBlockLayout } from "components/general/TestBlockLayout";
import { NotFound } from "./NotFound";
import { Forbidden } from "./Forbidden";
import { HomePage } from "./HomePage";
import { Header } from "./Header";
import { Footer } from "./Footer";
import { TeacherSignUp } from "../auth/TeacherSignUp";
import { SignUp } from "components/auth/SignUp";
import { PersonalCabinet } from "components/user/PersonalCabinet";
import FAQPage from "components/faq/FAQPage";
import { ForgotPassword } from "components/auth/ForgotPassword";
import { EditUserProfile } from "components/user/EditUserProfile";
import { ChangeTemporaryPassword } from "components/auth/ChangeTemporaryPassword";
import { CourseCategoryPage } from "components/course/coursecategory/CourseCategoryPage";
import { CoursePage } from "components/course/course/CoursePage";
import { PublicTeacherProfile } from "components/teacher/PublicTeacherProfile";

import { PageStatic } from "components/static/page/PageStatic";
import { PublicStudentProfile } from "components/student/PublicStudentProfile";
import { BlogBlock } from "components/static/blog/BlogBlock";
import { BlogStatic } from "components/static/blog/BlogStatic";

import { UserWishlist } from "components/wishlist/UserWishlist";
import { Loading } from "./Loading";
import { TeacherDashboard } from "components/teacher/TeacherDashboard";
import { Types } from "tools/types";
import { BecomeTeacherStaticPage } from "components/teacher/BecomeTeacherStaticPage";
import { TeacherCoursePage } from "components/teacherinterface/TeacherCoursePage";
import { CommonTools } from "tools/commontools";
import { TeacherDrawer } from "components/elements/Drawer/TeacherDrawer";
import { Box } from "@mui/material";
import { TeacherProfile } from "components/teacherinterface/TeacherProfile";
import { EditTeacherProfile } from "components/teacherinterface/EditTeacherProfile";
import { CreateCourseFirstStep } from "components/course/courseteacherinterface/CreateCourseFirstStep";
import { TeacherEditCourse } from "components/teacherinterface/TeacherEditCourse";
import { TeacherDrawerMenuItems } from "dto/teacher/teacherdrawermenuitems";
import { StudentDrawerMenuItems } from "dto/student/studentdrawermenuitems";
import { TeacherList } from "components/teacher/TeacherList";
import { TeacherInterfaceFAQBlock } from "components/teacherinterface/TeacherInterfaceFAQBlock";
import { TeacherInterfaceTestimonialBlock } from "components/teacherinterface/TeacherInterfaceTestimonialBlock";
import { StudentCoursePage } from "components/student/StudentCoursePage";
import { Checkout } from "components/general/Checkout";
import { StudentCourseDetail } from "components/student/StudentCourseDetail";
import { HeaderPrint } from "components/layoutprint/HeaderPrint";
import { FooterPrint } from "components/layoutprint/FooterPrint";
import { Certificate } from "components/course/certificate/Certificate";
import { StudentInterfaceFAQBlock } from "components/studentinterface/StudentInterfaceFAQBlock";
import { StudentInterfaceTestimonialBlock } from "components/studentinterface/StudentInterfaceTestimonialBlock";
import { TeacherInterfaceStudentBlock } from "components/teacherinterface/TeacherInterfaceStudentBlock";
import { ClassroomBlock } from "components/teacherclassroom/ClassroomBlock";
import { TestResultBlock } from "components/course/testresult/TestResultBlock";
import { TeacherNotificationBlock } from "components/teachernotification/TeacherNotificationBlock";
import { StudentNotificationBlock } from "components/studentnotification/StudentNotificationBlock";
import { TeacherMessageBlock } from "components/teachermessage/TeacherMessageBlock";
import { TeacherInterfaceRequestBlock } from "components/teacherrequest/TeacherInterfaceRequestBlock";
import { CourseTeacherInterfaceRequestBlock } from "components/course/courseteacherrequest/CourseTeacherInterfaceRequestBlock";
import { SupportBlockStudent } from "components/support/SupportBlockStudent";
import { SupportBlockTeacher } from "components/support/SupportBlockTeacher";
import {StudentCoursePage as StudentCoursePageOwn} from "components/studentcourse/StudentCoursePage";
import { Classroom } from "components/teacherclassroom/classroom/Classroom";

const MainLayout = ({ ...props }) => {
  const { currentRoute, setCurrentRoute } = useResource();
  const location = useLocation();
  const pathObject = useReactPath();

  const { user, userInterface } = useAuth();
  const [_currentpath, setCurrentPath] = React.useState("");
  const [_mainobject, setMainObject] = React.useState("");

  const processPathObject = async () => {
    var h = MD5(pathObject.path).toString();
    var hr = MD5(pathObject.path).toString();

    var routeobj = await RouteTools.parseLocation(location);

    routeobj.historystate = pathObject.historystate;

    if (routeobj.url == _currentpath) return;
    setCurrentPath(routeobj.url);

    setCurrentRoute(routeobj);
  }

  useEffect(() => {
    processPathObject();
  }, [pathObject]);

  useEffect(() => {
    if (currentRoute && currentRoute._mainobject) {
      setMainObject(currentRoute._mainobject);
    }
  }, [currentRoute]);

  // ----------------------------------

  const processStandard = () => {

    if (!_mainobject) return false;

    if (_mainobject == "test") return <TestBlockLayout currentRoute={currentRoute} />;

    if (_mainobject == "homepage") return <HomePage currentRoute={currentRoute} />;

    if (_mainobject == "login") return <Login currentRoute={currentRoute} />;

    if (_mainobject == "signup") return <SignUp currentRoute={currentRoute} />;

    if (_mainobject == "teachersignup") return <TeacherSignUp currentRoute={currentRoute} />;

    if (_mainobject == "becometeacherpage") return <BecomeTeacherStaticPage currentRoute={currentRoute} />;

    if (_mainobject == "changetemporarypassword") return <ChangeTemporaryPassword currentRoute={currentRoute} />;

    if (_mainobject == "faq") return <FAQPage currentRoute={currentRoute} />;

    if (_mainobject == "checkout") return <Checkout currentRoute={currentRoute} />;

    if (_mainobject == "wishlist") return <UserWishlist currentRoute={currentRoute} />;

    if (_mainobject == "forgotpassword") return <ForgotPassword currentRoute={currentRoute} />;

    if (_mainobject == "forbidden") return <Forbidden currentRoute={currentRoute} />;

    if (_mainobject == "notfound") return <NotFound currentRoute={currentRoute} />;

    // if (_mainobject == "personalcabinet") return <PersonalCabinet currentRoute={currentRoute} />;
    return false;
  };

  const processUser = () => {
    if (!_mainobject) return false;
    const path = currentRoute._paths;
    // if (RouteTools.modules.indexOf(_mainobject) == -1) return false;
    if (_mainobject == "profile") {
      // if (path.length === 1) return <PersonalCabinet currentRoute={currentRoute} />
      if (path[1] === "edit") return <EditUserProfile currentRoute={currentRoute} />
      if (path[1] === "course") return <StudentCoursePage currentRoute={currentRoute} />
      if (path[1] === "courseenrollment") return <StudentCoursePageOwn currentRoute={currentRoute} />
      if (path[1] === "faq") return <StudentInterfaceFAQBlock currentRoute={currentRoute} />;
      if (path[1] === "ticket") return <SupportBlockStudent currentRoute={currentRoute} />;
      if (path[1] === "testimonial") return <StudentInterfaceTestimonialBlock currentRoute={currentRoute} />;
      if (path[1] === "notification") return <StudentNotificationBlock currentRoute={currentRoute} />;


    };

    return false;
  };

  const processCourse = () => {
    if (!_mainobject) return false;
    const path = currentRoute._paths;
    // if (RouteTools.modules.indexOf(_mainobject) == -1) return false;
    if (_mainobject == "coursecategory") {
      return <CourseCategoryPage currentRoute={currentRoute} />
    };
    if (_mainobject == "course") {
      return <CoursePage currentRoute={currentRoute} />
    }

    return false;
  };

  const processPage = () => {
    if (!_mainobject) return false;
    const path = currentRoute._paths;
    // if (RouteTools.modules.indexOf(_mainobject) == -1) return false;
    if (_mainobject == "page") {
      return <PageStatic currentRoute={currentRoute} />
    };

    return false;
  };

  const processBlog = () => {
    if (!_mainobject) return false;
    const path = currentRoute._paths;

    if (_mainobject == "blog") {
      return <BlogBlock currentRoute={currentRoute} />
    };

    if (_mainobject == "blogpage") {
      return <BlogStatic currentRoute={currentRoute} />
    }
    return false;
  }

  const processTeacher = () => {
    if (!_mainobject) return false;
    const path = currentRoute._paths;
    // if (RouteTools.modules.indexOf(_mainobject) == -1) return false;
    if (_mainobject == "teacherpage") {
      return <PublicTeacherProfile currentRoute={currentRoute} />

    };

    if (_mainobject == "teacher") {
      return <TeacherList currentRoute={currentRoute} />
    }

    return false;
  };


  const processTeacherInterface = () => {
    if (!_mainobject) return false;
    const path = currentRoute._paths;

    if (_mainobject !== "teacherinterface") return false;


    if (!user) return false;
    if (userInterface !== Types.TEACHER_INTERFACE) return false;
    if (path.length === 1) return <TeacherDashboard currentRoute={currentRoute} />;
    if (path.length === 2) {
      if (path[1] === "course") return <TeacherCoursePage currentRoute={currentRoute} />;
      if (path[1] === "addcourse") return <CreateCourseFirstStep currentRoute={currentRoute} />;
      if (path[1] === "testresult") return <TestResultBlock currentRoute={currentRoute} />;
      // if (path[1] === "profile") return <TeacherProfile currentRoute={currentRoute} />;
      if (path[1] === "editprofile") return <EditTeacherProfile currentRoute={currentRoute} />;
      if (path[1] === "faq") return <TeacherInterfaceFAQBlock currentRoute={currentRoute} />;
      if (path[1] === "ticket") return <SupportBlockTeacher currentRoute={currentRoute} />;

      if (path[1] === "testimonial") return <TeacherInterfaceTestimonialBlock currentRoute={currentRoute} />;
      if (path[1] === "student") return <TeacherInterfaceStudentBlock currentRoute={currentRoute} />;
      if (path[1] === "notification") return <TeacherNotificationBlock currentRoute={currentRoute} />;
      if (path[1] === "teacherrequest") return <TeacherInterfaceRequestBlock currentRoute={currentRoute} />;
      if (path[1] === "courseteacherrequest") return <CourseTeacherInterfaceRequestBlock currentRoute={currentRoute} />;
      if (path[1] === "message") return <TeacherMessageBlock currentRoute={currentRoute} />;
    }
    if (path.length === 3) {
      if (path[1] === "editcourse") return <TeacherEditCourse currentRoute={currentRoute} />;
      if (path[1] === "classroom") return <Classroom currentRoute={currentRoute} />;
    }
    if (path.length >= 3) {

      if (path[1] === "classroom") return <Classroom currentRoute={currentRoute} />;
    }


    return false
  }

  const processStudent = () => {
    if (!_mainobject) return false;
    const path = currentRoute._paths;

    if (_mainobject == "student") {
      return <PublicStudentProfile currentRoute={currentRoute} />
    };


    return false;
  };

  // ----------------------------------

  const processMainObject = () => {
    let t: any = false;

    t = processStandard();
    if (t) return t;

    t = processUser();
    if (t) return t;

    t = processCourse();
    if (t) return t;

    t = processTeacher();
    if (t) return t;

    t = processPage();
    if (t) return t;

    t = processBlog();
    if (t) return t;

    t = processStudent();
    if (t) return t;

    t = processTeacherInterface();
    if (t) return t;

    return <Loading />;

  };

  // const processUserWithDrawer = () => {
  //   if (!_mainobject) return false;
  //   if (_mainobject === "profile") {
  //     return (
  //       <Box sx={{ display: "flex" }}>
  //         <TeacherDrawer
  //           currentRoute={currentRoute}
  //           source={StudentDrawerMenuItems} />
  //         <Box sx={{ flexGrow: 1, }}>
  //           {layout()}
  //         </Box>
  //       </Box>
  //     )
  //   }


  //   return false;
  // }

  // const layout = () => {
  //   return (<>
  //     <header>
  //       <div className="container">
  //         <Header currentRoute={currentRoute} />
  //       </div>
  //     </header>
  //     <main>
  //       {processMainObject()}
  //     </main>
  //     <footer><Footer currentRoute={currentRoute} /></footer>
  //   </>)
  // }

  // const teacherLayout = () => {
  //   return (
  //     <Box sx={{ display: "flex" }}>
  //       <TeacherDrawer currentRoute={currentRoute} source={TeacherDrawerMenuItems} />
  //       <Box sx={{ flexGrow: 1, }}>
  //         {layout()}
  //       </Box>
  //     </Box>
  //   )
  // }

  // const processMainLayout = () => {
  //   if (CommonTools.isUserInterface(user, userInterface)) {
  //     return teacherLayout();
  //   } else if (_mainobject === "profile") {
  //     return processUserWithDrawer();
  //   }
  //   else {
  //     return layout();
  //   }
  // }


  const processPrint = () => {

    if (!_mainobject) return false;

    if (_mainobject == "certificate") return <Certificate currentRoute={currentRoute} />;


    return false;
  };

  const processPrintObjects = () => {
    let t: any = false;
    t = processPrint();
    if (t) return t;
    return <Loading />;
  }

  const processPrintComponents = () => {
    if (_mainobject === "certificate") return true;
    return false;
  }

  const processPrintLayout = () => {
    if (!_mainobject) return false;
    return (
      <>
        <header>
          <HeaderPrint currentRoute={currentRoute} />
        </header>
        <main>
          {processPrintObjects()}
        </main>
        <footer>
          <FooterPrint currentRoute={currentRoute} />
        </footer>
      </>
    )
  }

  const mainContent = (showLogo: boolean) => {
    const classNameContent = showLogo ? "container" : "container-admin";
    return (
      <>
        <header>
          <div className={classNameContent}>
            <Header showLogo={showLogo} currentRoute={currentRoute} />
          </div>
        </header>
        <main>
          {processMainObject()}
        </main>
        <footer>
          {showLogo ? <Footer currentRoute={currentRoute} /> : <></>}
        </footer>
      </>)
  }

  const processMainLayout = () => {

    if (processPrintComponents()) return processPrintLayout();

    const isTeacher = CommonTools.isUserInterface(user, userInterface);
    const path = currentRoute._paths;
    const drawerComponent = isTeacher ?
      <TeacherDrawer currentRoute={currentRoute} source={TeacherDrawerMenuItems} /> :
      (_mainobject === "profile" && path[1] !== 'courseenrollment'?
        <TeacherDrawer currentRoute={currentRoute} source={StudentDrawerMenuItems} /> :
        null);
    
        const displayComponent = isTeacher || _mainobject === "profile" ? "flex" : "block";
    let showLogo = true;
    if (_mainobject === "profile") {
      showLogo = false;
    } else if (isTeacher) {
      showLogo = false;
    }
    return (
      <Box sx={{ display: displayComponent }}>
        {drawerComponent}
        <Box sx={{ flexGrow: 1 }}>
          {mainContent(showLogo)}
        </Box>
      </Box>
    );
  };

  // return (
  //   <>

  //     {CommonTools.isUserInterface(user, userInterface) ? (
  //       <Box sx={{ display: "flex" }}>
  //         <TeacherDrawer currentRoute={currentRoute} />
  //         <Box sx={{ flexGrow: 1, }}>
  //           {layout()}
  //         </Box>
  //       </Box>
  //     )
  //       : (layout())
  //     }
  //   </>

  // );
  return processMainLayout();
};

export { MainLayout };
