import React from "react";
import PageComponentProps from "interfaces/pagecomponentprops.interface";
import { Box, Typography,Stack } from "@mui/material";
import { useResource } from "hook/useResource";
import { MyNavBt } from "components/general/MyNavBt";
import { AdvertisementCreateNewCourseSection } from "components/advertisement/AdvertisementCreateNewCourseSection";
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';

const TeacherWithoutCourses: React.FC<PageComponentProps> = ({ currentRoute, ...props }) => {

  const { LL } = useResource();
  const processCreateCourseTopBanner = () => {
    return (
      <Box className="container" mt={5}>
        <Box className="cardSpecial">
        <Stack direction={{lg:'row', sm:'column'}}
         justifyContent="space-between"
         alignItems="center"
         spacing={2}
         
        >
        <Box display='flex' alignItems='center'>
          <Box className="icon-start-teach"></Box>
          <Typography variant="h2" ml={2}>
            {LL("Start_to_create_your_course")}
          </Typography>
          </Box>
        <MyNavBt
          href="/teacherinterface/addcourse"
          className='btn'
          sx={{my:1}}
          >
          {LL("Create_course")}<KeyboardDoubleArrowRightIcon  sx={{ml:1}} />
        </MyNavBt>
        </Stack>
        </Box>
      </Box>
    )

  }
  const processCreateCourseBottom = () => {
    return (
      <Box className="container" textAlign='center' mt={5}>
     
        <Typography className="preText" mb={4}>
          {LL("Ready_to_start_to_create_your_course")}
        </Typography>
        <MyNavBt className='btn'
          href="/teacherinterface/addcourse">
          {LL("Create_course")}<KeyboardDoubleArrowRightIcon sx={{ml:1}} />
          
        </MyNavBt>
        
      </Box>
    )

  }
  const processBannerSection = () => {
    return (
      <Box  className="container">
        <Typography className="preText" mt={5} maxWidth='sm' mx={'auto'}>
          {LL("Banner_section_create_new_course_title")}
        </Typography>
        <AdvertisementCreateNewCourseSection currentRoute={currentRoute}/>
      </Box>
    )

  }
  return (
    <Box className="withoutCourse">
      {processCreateCourseTopBanner()}
      {processBannerSection()}
      {processCreateCourseBottom()}
    </Box>
  );
};

export { TeacherWithoutCourses };
