import React, { useState, useEffect } from "react";

import { MyButton } from "components/general/MyButton";
import { CommonTools } from 'tools/commontools';
import { ComponentCommonTools } from 'tools/componentcommontools'
import { useResource } from "hook/useResource";
import { RouteTools } from "tools/routetools";
import { Box, Stack, Divider, ListItemButton, ListItemText, Link } from "@mui/material";
import { logger } from "hoc/logger";
import { Types } from "tools/types";
import { TicketDto } from "dto/support/ticket.dto";



type TicketItemMenuProps = {
    obj: TicketDto;
    selectedTicket?: TicketDto;
    setSelectedTicket: any;
    index: number;
    setIndex: any;
}

const TicketItemMenu: React.FC<TicketItemMenuProps> = ({ obj,
    setSelectedTicket,
    selectedTicket,
    setIndex,
    index,
    ...props }) => {


    const { LL, CC } = useResource();
    const handleSelect = () => {
        setIndex(index);
        setSelectedTicket(obj);
    }
    const isSelected = () => {
        if (!selectedTicket) return false;
        if (!obj) return false;
        if (!obj.hasOwnProperty("id")) return false;
        if (!selectedTicket.hasOwnProperty("id")) return false;
        if (!obj.id) return false;
        if (!selectedTicket.id) return false;

        return selectedTicket.id === obj.id;
    }
    const processItem = () => {
        if (!obj) return <></>


        return (
            <ListItemButton
                onClick={handleSelect}
                selected={isSelected()}
            >
                <Stack direction="column" spacing={1}>
                    <ListItemText>
                        {ComponentCommonTools.processObjectField(obj, "status_name")}
                    </ListItemText>
                    <ListItemText>
                        {ComponentCommonTools.processObjectFieldOfObject(obj, "ticketcategory", "name")}
                    </ListItemText>
                    <ListItemText>
                        {ComponentCommonTools.processObjectField(obj, "date_name")}
                    </ListItemText>
                </Stack>
            </ListItemButton>
        );

    }



    return processItem();
};

export { TicketItemMenu };
