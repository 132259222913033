import React from "react";
import Pagination from '@mui/material/Pagination';

type MyPaginationProps = {
    total: number;
    page: number;
    setPage: any
    totalPage: number;
    [x:string]: any;
}
const MyPagination: React.FC<MyPaginationProps> = ({
    total,
    page,
    setPage,
    totalPage,
    ...props
}) => {

    const handleChange = (event:any, value:number) => {
        setPage(value);
      };

    return <>
        <Pagination
            count={totalPage}
            page={page}
            onChange={handleChange}
            {...props}
            color="primary"
        />
    </>;
};

export { MyPagination };
