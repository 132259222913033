import React, { useState, useEffect } from "react";
import { CourseDto } from 'dto/course/course.dto'
import { ComponentCommonTools } from 'tools/componentcommontools'
import { useResource } from "hook/useResource";
import { Box, Stack, Typography } from "@mui/material";

import { logger } from "hoc/logger";


type CourseCardPriceContentProps = {
    obj: CourseDto;
}

const CourseCardPriceContent: React.FC<CourseCardPriceContentProps> = ({
    obj,
    ...props }) => {

    const { LL, CC } = useResource();

    const processItem = () => {
        if (!obj) return <></>;
        if (!obj.hasOwnProperty('courseprice')) return <></>;
        if (!obj.courseprice) return <></>;
        if (!obj.courseprice.hasOwnProperty('totalprice')) return <></>;
        if (obj.courseprice.totalprice === undefined || obj.courseprice.totalprice === null ) return <></>;
        else if(obj.courseprice.totalprice === 0) return processItemWithoutPrice();
        if (!obj.courseprice.hasOwnProperty('priceperlesson')) return <></>;
        if (!obj.courseprice.priceperlesson) return <></>;
        if (!obj.courseprice.hasOwnProperty('currency_identifier')) return <></>;
        if (!obj.courseprice.currency_identifier) return <></>;

        return processItemWithPrice(obj);
        
    }

    const processItemWithPrice = (obj: CourseDto) => {
        return (
            <Stack direction='column'>
                <Stack direction='row' spacing={1}>
                    <Typography className="priceTotal">
                        <span>
                            {ComponentCommonTools.processObjectFieldOfObject(obj, 'courseprice', 'totalprice')}
                        </span>
                        {ComponentCommonTools.processObjectFieldOfObject(obj, 'courseprice', 'currency_identifier')}
                    </Typography>
                </Stack >

                <Stack direction='row' className="pricePer">
                    <Typography component='span'>
                        <span>
                            {ComponentCommonTools.processObjectFieldOfObject(obj, 'courseprice', 'priceperlesson')}
                        </span>
                        {ComponentCommonTools.processObjectFieldOfObject(obj, 'courseprice', 'currency_identifier')}
                    </Typography>
                    <Typography component='span'>/</Typography>
                    <Typography component='span'>
                        {LL('per_lesson')}
                    </Typography>
                </Stack >
            </Stack >
        )
    }

    const processItemWithoutPrice = () => {
        return (
            <Box className="pricePer">
                <Typography component='span'>{LL('Free')}</Typography>
            </Box>
        )
    }

    return processItem()
};

export { CourseCardPriceContent };
