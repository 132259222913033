import React, { useEffect, useState } from "react";
import PageComponentProps from "interfaces/pagecomponentprops.interface";
import { CommonTools } from "tools/commontools";
import { Box, Skeleton, Typography, Grid, Stack } from "@mui/material";
import { CourseDto } from "dto/course/course.dto";
import ResultObjectDTO from "dto/app/resultobject.dto";
import CourseService from "services/course/course.service";

import { HtmlTag } from "components/general/HtmlTag";
import { useResource } from "hook/useResource";

import { CourseTestimonials } from "components/course/course/CourseTestimonials";

import { TeacherFAQBlock } from "components/teacher/TeacherFAQBlock";

const courseService = new CourseService();
type FAQTabBlockProps = {
    obj: CourseDto;
}
const FAQTabBlock: React.FC<FAQTabBlockProps> = ({ obj, ...props }) => {

    const { LL } = useResource();

    const processCourseTestimonials = () => {
        if (!obj) return <></>;
        if (!obj.hasOwnProperty('id')) return <></>;
        if (!obj.id) return <></>;

        return (<Box mt={5}>
            <Typography variant="h3">{LL('Testimonials')}</Typography>
            <CourseTestimonials idCourse={obj.id} />
        </Box>)
    }

    const processTeacherFAQ = () => {
        if (!obj) return <></>;
        if (!obj.id) return <></>;
        if (!obj.hasOwnProperty('idteacher')) return <></>;
        if (!obj.idteacher) return <></>;

        return <TeacherFAQBlock idCourse={obj.id} idTeacher={obj.idteacher} />
    }


    return obj ?
        (<Box className="detailCourse">
            <Box className="container">
                <Grid container>
                    <Grid item lg={9} sm={12}>
                        {/* {processCourseTestimonials()} */}
                        {processTeacherFAQ()}
                    </Grid>
                </Grid>
            </Box>
        </Box>) : (<></>);
};

export { FAQTabBlock };
