import React, { useEffect, useState } from "react";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { logger } from "hoc/logger";
import { useResource } from "hook/useResource";
import RequestSortCriteriaDTO from "dto/app/requestsortcriteria.dto";
import MenuItem from "@mui/material/MenuItem/MenuItem";
import SelectOptionSort from "dto/app/selectoptionsort.dto";
import InputLabel from "@mui/material/InputLabel/InputLabel";
import FormControl from "@mui/material/FormControl/FormControl";
import { Skeleton } from "@mui/material";
import SelectOptions from "dto/app/selectoptions.dto";
import { set } from "date-fns";

type TypeSelectProps = {
    [key: string]: any;
    setSortCriteria: any;
    sortCriteria: Array<RequestSortCriteriaDTO>;
    objects: Array<string>;
}
const MySortSelect: React.FC<TypeSelectProps> = ({
    setSortCriteria,
    sortCriteria,
    objects,
    ...props
}) => {
    const { LL } = useResource();
    const label = LL("MySort");

    const [options, setOptions] = useState<Array<SelectOptions>>(new Array<SelectOptions>());
    const [loading, setLoading] = useState<boolean>(true);
    const [value, setValue] = useState<string | undefined>();


    const handleChange = (event: SelectChangeEvent) => {
        setValue(event.target.value);
        if (event.target.value !== '' || event.target.value !== undefined) {
            handleChangeValue(processToSortCriteria(event.target.value));
        }
    };

    const processOptions = () => {
        if (!options) return null;
        if (options.length === 0) return null;
        return options.map((item, index) => (
            <MenuItem key={index} value={item.value}>
                {LL(item.label)}
            </MenuItem>
        ));
    };

    useEffect(() => {
        if (objects) {
            setOptions(processObjects(objects));
            processDefaultValue();
            setLoading(false);
        }
    }, [objects]);

    const processDefaultValue = () => {
        logger('MySortSelect', sortCriteria, objects)
        if (!value) {
            if (!sortCriteria || !sortCriteria.length) {

                setValue(objects[0]);
            }
            else {
                setValue(processFromSortCriteria(sortCriteria[0]));
            }
        }
    }
    // useEffect(() => {
    //     if (!options || !options.length) return;
    //     if (value && value !== '') {
    //         handleChangeValue(processToSortCriteria(value));
    //         setHasChanged(true);
    //     }
    // }, [value]);

    const handleChangeValue = (valueLocal: RequestSortCriteriaDTO) => {
        if (!valueLocal) return;
        setSortCriteria([valueLocal]);
    }

    const isAsc = (valueLocal: string) => {
        if (valueLocal === 'asc') return true;
        return false;
    }

    const processToSortCriteria = (valueLocal: string) => {
        const localValue = valueLocal.split('_');
        const sortCriteria = new RequestSortCriteriaDTO();
        sortCriteria.field = localValue[0];
        sortCriteria.asc = isAsc(localValue[1]);
        return sortCriteria;
    }

    const processFromSortCriteria = (sortCriteria: RequestSortCriteriaDTO) => {
        const value = `${sortCriteria.field}_${sortCriteria.asc ? 'asc' : 'desc'}`;
        return value;
    }

    const processObjects = (objectsLocal: Array<string>) => {
        const options = new Array<SelectOptions>();
        objectsLocal.forEach((item) => {
            const option = new SelectOptions();
            option.label = item;
            option.value = item;
            options.push(option);
        })
        return options;
    }

    return !loading ? (
        <FormControl fullWidth>
            <InputLabel id="select-label">{label}</InputLabel>
            <Select
                labelId="select-label"
                id="simple-select"
                onChange={handleChange}
                value={value ? value : ''}
                label={label}

                {...props}
            >
                {processOptions()}
            </Select>
        </FormControl>
    ) : (<Skeleton variant="rectangular" height={50}/>);
};

export { MySortSelect };
