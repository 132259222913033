import React, { useEffect, useState } from "react";

import { useResource } from "hook/useResource";
import { logger } from "hoc/logger";

import { Chip, Link, Typography } from '@mui/material';

import { ComponentCommonTools } from 'tools/componentcommontools'
import { CommonTools } from "tools/commontools";
import { CourseSettingsDto } from "dto/course/coursesettings.dto";

type TypeCourseCardChipProps = {
    obj: CourseSettingsDto;
    width: number;
    height: number;
}


const TypeCourseCardChip: React.FC<TypeCourseCardChipProps> = ({
    obj,
    width,
    height,
    ...props
}) => {

    const [imgUrl, setImgUrl] = useState<string>("");
    const [fullUrl, setFullUrl] = useState<string>("");

    const prepareGallery = () => {
        if (!obj) return;
        if (!obj.typecourse) return;
        if (!obj.typecourse.hasOwnProperty('gallery')) return;
        if (!obj.typecourse.gallery) return;
        if (obj.typecourse.gallery.length === 0) return;
        const galleryObj = CommonTools.getGalleryObjectLogo(obj.typecourse.gallery);
        if (!galleryObj) return;
        if (!width) width = 14;
        if (!height) height = 14;
        CommonTools.setFileUrlsGallery(galleryObj, setImgUrl, setFullUrl, width, height);
    }

    useEffect(() => {
        prepareGallery();
    }, [obj])

    const processIcon = () => {
        if (!imgUrl) return <></>;
        return ComponentCommonTools.getFileBlock(imgUrl, 14, 14)

    }
    const processChip = () => {
        if (!obj) return <></>;
        if (!obj.hasOwnProperty('typecourse')) return <></>;
        if (!obj.typecourse) return <></>;
        if (!obj.typecourse.hasOwnProperty('name')) return <></>;
        if (!obj.typecourse.name) return <></>;
        if (!imgUrl) return <></>;

        return (
            <Chip icon={processIcon()} label={obj.typecourse.name} color="error"/>
        );
    }


    return (<>{processChip()}</>);
};

export { TypeCourseCardChip };
