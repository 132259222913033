import React,{useContext} from "react";
import { useResource } from "hook/useResource";
import { Alert, Box, Typography, Stack } from "@mui/material";
import { TestDialog } from "components/course/test/TestDialog";
import { Types } from "tools/types";
import { StudentCourseContext } from "context/studentcourse/studentcourse.context";
import PageComponentProps from "interfaces/pagecomponentprops.interface";
const StartTest: React.FC<PageComponentProps> = (
    {currentRoute}
) => {
    const open = currentRoute?.historystate?.action === Types.TYPE_PARAM_FOR_TEST_OPEN_DEFAULT ? true : false;
  const { LL } = useResource();

  const {
    state: { idCourse, courseEnrollmentObject, course },
    actions: {
        triggerReloadCourseEnrollment
    },
  } = useContext(StudentCourseContext);

  const processComponent = () => {
    if (!courseEnrollmentObject) return <></>;
    if(!course) return <></>;
    return (
      <Box>

          <Alert severity="info" className="alertZone">
            <Typography mb={1}>
              {LL("To_buy_the_course_you_need_to_pass_the_initial_test")}
            </Typography>
          

        <TestDialog
            courseEnrollmentObject={courseEnrollmentObject}
            idCourse={idCourse}
            idLesson={""}
            type={Types.TYPE_TEST_BEFORE_COURSE}
            course={course}
            setCheckObjectWasChanged={triggerReloadCourseEnrollment}
            openDefault={open}
            
          /></Alert>
      </Box>
    );
  };
  return processComponent();
};

export { StartTest };
