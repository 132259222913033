import React, { useContext, useEffect } from "react";
import { useResource } from "hook/useResource";
import { Box, Typography } from "@mui/material";

import PageComponentProps from "interfaces/pagecomponentprops.interface";
import { ClassroomContext } from "context/classroom/classroom.context";

import { ComponentCommonTools } from "tools/componentcommontools";

import { ClassroomVideoPlayer } from "./ClassroomVideoPlayer";
import { ClassroomZoomBlock } from "../ClassroomZoomBlock";

export const InteractionArea: React.FC<PageComponentProps> = ({
  currentRoute,
}) => {
  const { LL } = useResource();
  const {
    state: { selectedLessonObject  },
    
  } = useContext(ClassroomContext);

  
  const processZoomBlock = () => {
    if (!selectedLessonObject) return <></>;
    if (!selectedLessonObject.hasOwnProperty('islive')) return <></>;
    if (!selectedLessonObject.islive) return <></>;
    if (!selectedLessonObject.hasOwnProperty('id')) return <></>;
    if (!selectedLessonObject.id) return <></>;

    return (
        <ClassroomZoomBlock
            currentRoute={currentRoute}
            idLesson={selectedLessonObject.id}
            lesson={selectedLessonObject} />

    )
}

  const processComponent = () => {
    if (!selectedLessonObject) return <></>;
    return (
      <Box>
        <Typography variant="h3" my={3}>
          {ComponentCommonTools.processObjectField(
            selectedLessonObject,
            "name"
          )}
        </Typography>
        {processZoomBlock()}
        <ClassroomVideoPlayer currentRoute={currentRoute} />
      </Box>
    );
  };

  return processComponent();
};
