import { Box } from "@mui/material";
import { StudentCourseContext } from "context/studentcourse/studentcourse.context";
import React, { useContext,  useEffect } from "react";
import { ComponentCommonTools } from "tools/componentcommontools";
import PageComponentProps from "interfaces/pagecomponentprops.interface";
export const StudentVideoPlayer: React.FC<PageComponentProps> = ({currentRoute}) => {
  const {
    state: { videoSelected,selectedLessonObject },
    actions: { getDefaultVideo },
  } = useContext(StudentCourseContext);

  useEffect(() => {
    if(!selectedLessonObject) return;
    getDefaultVideo(currentRoute);
  }, [selectedLessonObject]);

  const processComponent = () => {
    if (!videoSelected) return <></>;
    return (
      <Box>
        {ComponentCommonTools.getFileBlockVideo(videoSelected, "100%", 600, true)}
      </Box>
    );
  };

  return processComponent();
};
