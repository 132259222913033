import { Box } from "@mui/material";
import { MyButton } from "components/general/MyButton";
import PageComponentProps from "interfaces/pagecomponentprops.interface";
import { StudentCourseContext } from "context/studentcourse/studentcourse.context";
import { useResource } from "hook/useResource";
import React, { useState, useContext } from "react";
import { logger } from "hoc/logger";

export const StudentCourseZoomBlock: React.FC<PageComponentProps> = ({
  currentRoute,

  ...props
}) => {
  const { LL } = useResource();

  const [open, setOpen] = useState(false);
  const {
    state: {
      selectedLessonObject,
      courseEnrollmentObject,
      lessonEnrollmentObject,
    },
  } = useContext(StudentCourseContext);

  const processZoomBlock = () => {
    if (!open) return <></>;
    return <Box height={200}>ZoomBlock</Box>;
  };

  const handleToggle = () => {
    setOpen(!open);
  };

  const processComponent = () => {
    if (!courseEnrollmentObject) return <></>;
    if (!lessonEnrollmentObject) return <></>;
    if (!selectedLessonObject) return <></>;
    if (!selectedLessonObject.hasOwnProperty("islive")) return <></>;
    if (!selectedLessonObject.islive) return <></>;

    return (
      <Box>
        {processZoomBlock()}
        <MyButton cb={handleToggle}>{LL("Zoom")}</MyButton>
      </Box>
    );
  };

  return processComponent();
};
