import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useResource } from "hook/useResource";
import { logger } from "hoc/logger";
import AdvertisementService from "services/advertisement/advertisement.service";
import PageComponentProps from "interfaces/pagecomponentprops.interface";
import { AdvertisementDto } from "dto/advertisement/advertisement.dto";
import { RouteTools } from "tools/routetools";
import RequestListDTO from "dto/app/requestlist.dto";
import { Status } from "tools/status";
import RequestFilterDTO from "dto/app/requestfilter.dto";
import ResultListDTO from "dto/app/resultlist.dto";
import { Types } from "tools/types";
import { ComponentCommonTools } from 'tools/componentcommontools'
import { Loading } from "components/general/Loading";
import Skeleton from '@mui/material/Skeleton';
import { Box } from "@mui/material";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { MyNavBt } from "components/general/MyNavBt";
import { CommonTools } from "tools/commontools";
import Grid from '@mui/material/Grid';
import Typography from "@mui/material/Typography";
import { AdvertisementBannerItemTypeBanner } from "components/advertisement/AdvertisementBannerItemTypeBanner";
import { AdvertisementBannerItemSpecialHomeSection2 } from "components/advertisement/AdvertisementBannerItemSpecialHomeSection2";
import { AdvertisementBannerItemSpecialHomeSection1 } from "components/advertisement/AdvertisementBannerItemSpecialHomeSection1";
import { AdvertisementBannerItemTypeImageRight } from "components/advertisement/AdvertisementBannerItemTypeImageRight";
import { AdvertisementBannerItemTypeImageLeft } from "components/advertisement/AdvertisementBannerItemTypeImageLeft";
import { AdvertisementBannerItemHomeBenefits } from "components/advertisement/AdvertisementBannerItemHomeBenefits";
import { AdvertisementBannerItemHomeSuggestions } from "components/advertisement/AdvertisementBannerItemHomeSuggestions";
import { AdvertisementBannerItemBecomeTeacherSection1 } from "./AdvertisementBannerItemBecomeTeacherSection1";
import { AdvertisementBannerItemBecomeTeacherSection5 } from "./AdvertisementBannerItemBecomeTeacherSection5";

type AdvertisementBannerItemProps = {
    obj: AdvertisementDto;
}
const AdvertisementBannerItem: React.FC<AdvertisementBannerItemProps> = ({
    obj,
    ...props
}) => {


    const processItem = () => {
        if (!obj) return <></>;

        if (obj.type === Types.ADVERTISEMENT_TYPE_BANNER) {
            return <AdvertisementBannerItemTypeBanner obj={obj} />;

        }

        if (obj.type === Types.ADVERTISEMENT_TYPE_SPECIAL &&
            obj.section === Types.SECTION_HOME_1) {
            return <AdvertisementBannerItemSpecialHomeSection1 obj={obj} />;
        }

        if (obj.type === Types.ADVERTISEMENT_TYPE_SPECIAL &&
            obj.section === Types.SECTION_HOME_2) {
            return <AdvertisementBannerItemSpecialHomeSection2 obj={obj} />;
        }


        if (obj.type === Types.ADVERTISEMENT_TYPE_SPECIAL &&
            obj.section === Types.SECTION_BENEFITS) {
            return <AdvertisementBannerItemHomeBenefits obj={obj} />;
        }

        if (obj.type === Types.ADVERTISEMENT_TYPE_SPECIAL &&
            obj.section === Types.SECTION_SUGGESTIONS) {
            return <AdvertisementBannerItemHomeSuggestions obj={obj} />;
        }

        if (obj.type === Types.ADVERTISEMENT_TYPE_BANNER_IMAGE_RIGHT) {
            return <AdvertisementBannerItemTypeImageRight obj={obj} />
        }

        if (obj.type === Types.ADVERTISEMENT_TYPE_BANNER_IMAGE_LEFT) {
            return <AdvertisementBannerItemTypeImageLeft obj={obj} />
        }

        if (obj.type === Types.ADVERTISEMENT_TYPE_BECOME_TEACHER &&
            obj.section === Types.SECTION_BECOME_TEACHER_1) {
            return <AdvertisementBannerItemBecomeTeacherSection1 obj={obj} />
        }

        if (obj.type === Types.ADVERTISEMENT_TYPE_BECOME_TEACHER &&
            obj.section === Types.SECTION_BECOME_TEACHER_2) {
            return <AdvertisementBannerItemHomeSuggestions obj={obj} />
        }

        if (obj.type === Types.ADVERTISEMENT_TYPE_BECOME_TEACHER &&
            obj.section === Types.SECTION_BECOME_TEACHER_3) {
            return <AdvertisementBannerItemHomeBenefits obj={obj} />
        }

        if (obj.type === Types.ADVERTISEMENT_TYPE_BECOME_TEACHER &&
            obj.section === Types.SECTION_BECOME_TEACHER_4) {
            return <AdvertisementBannerItemTypeBanner obj={obj} />
        }


        if (obj.type === Types.ADVERTISEMENT_TYPE_BECOME_TEACHER &&
            obj.section === Types.SECTION_BECOME_TEACHER_5) {
            return <AdvertisementBannerItemBecomeTeacherSection5 obj={obj} />
        }

        if (obj.type === Types.ADVERTISEMENT_TYPE_SPECIAL &&
            obj.section === Types.SECTION_CREATE_NEW_COURSE) {
            return <AdvertisementBannerItemTypeImageLeft obj={obj} />
        }

        return (<></>)
    }

    return processItem();
};

export { AdvertisementBannerItem };
