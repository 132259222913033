import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useResource } from "hook/useResource";
import { logger } from "hoc/logger";
import BlogCategoryService from "services/blog/blogcategory.service";
import MenuLevelProps from "interfaces/menulevelprops.interface";
import { BlogCategoryDto } from "dto/blog/blogcategory.dto";
import { RouteTools } from "tools/routetools";
import RequestListDTO from "dto/app/requestlist.dto";
import { Status } from "tools/status";
import RequestFilterDTO from "dto/app/requestfilter.dto";
import ResultListDTO from "dto/app/resultlist.dto";
import { Types } from "tools/types";
// import { BlogCategoryItem } from "./BlogCategoryItem";
import { Loading } from "components/general/Loading";
import Skeleton from '@mui/material/Skeleton';
import { Box } from "@mui/material";
import { BlogCategoryItem } from "./BlogCategoryItem";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { TreeView } from '@mui/x-tree-view/TreeView';
import { TreeItem } from '@mui/x-tree-view/TreeItem';
import { ComponentCommonTools } from "tools/componentcommontools";
const blogCategoryService = new BlogCategoryService();

type BlogCategoryLevelProps = {
    currentRoute: any;
    level: number;
    idparent: string;
    setBlogCategoryId?: any;
}
const BlogCategoryLevel: React.FC<BlogCategoryLevelProps> = ({
    currentRoute,
    level,
    idparent,
    setBlogCategoryId,
    ...props
}) => {
    const { saveCache, getCache } = useResource();

    const [isLoading, setIsLoading] = useState(true);
    const [objects, setObjects] = useState<Array<BlogCategoryDto>>([]);


    const getList = async () => {
        const reqList = new RequestListDTO();
        reqList.onpage = 100;
        reqList.page = 1;
        reqList.filters = [];

        let f: RequestFilterDTO;

        f = new RequestFilterDTO();
        f.field = "status";
        f.values = [];
        f.values.push(Status.ACTIVE.toString());
        reqList.filters.push(f);

        f = new RequestFilterDTO();
        f.field = "idparent";
        f.values = [];
        f.values.push(idparent);
        reqList.filters.push(f);

        const typeBlogCategory = process.env.REACT_APP_DEFAULT_ID_TYPE_BLOG_CATEGORY ? process.env.REACT_APP_DEFAULT_ID_TYPE_BLOG_CATEGORY : "";
        
        f = new RequestFilterDTO();
        f.field = "idtypeblogcategory";
        f.values = [typeBlogCategory];
        reqList.filters.push(f);

        const cd = getCache(reqList);

        if (cd !== false) {
            setObjects(cd);
            setIsLoading(false);
            return;
        }

        setIsLoading(true);

        const data = await blogCategoryService.getList(undefined, {}, reqList);

        const rez = !data || !data.objects ? [] : data.objects;

        setObjects(rez);
        setIsLoading(false);


        saveCache(rez, reqList);
    };

    useEffect(() => {
        getList();
    }, [idparent]);

    const processBlogCategoryItem = (obj: BlogCategoryDto, index: number) => {
        if (!obj) return <></>;
        if (!obj.hasOwnProperty('id')) return <></>;
        if (!obj.id) return <></>;
        const hasChildren = obj.hasOwnProperty('haschildren') && obj.haschildren !== undefined ? obj.haschildren : false;

        return (
            <BlogCategoryItem
                currentRoute={currentRoute}
                level={level} obj={obj}
                hasChildren={hasChildren}
                setBlogCategoryId={setBlogCategoryId}
            />

        )
    };


    const processBlogCategoryList = () => {
        if (!objects.length) return <></>;

        return (
            <Box >
                {objects.map((item: BlogCategoryDto, i: number) => {
                    return processBlogCategoryItem(item, i);
                })}
            </Box>
        );
    };

    return isLoading ? <Skeleton /> : processBlogCategoryList();
};

export { BlogCategoryLevel };
