import React, { useEffect, useState } from "react";
import PageComponentProps from "interfaces/pagecomponentprops.interface";
import { useResource } from "hook/useResource";
import { logger } from "hoc/logger";

import { MyNavBt } from "components/general/MyNavBt";
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import { ComponentCommonTools } from 'tools/componentcommontools'
import Grid from '@mui/material/Grid';
import StudentService from "services/student/student.service";
import { StudentDto } from "dto/student/student.dto";
import { useAuth } from "hook/useAuth";
import MyCKEditor from 'components/elements/ckeditor/MyCKEditor';
import { StudyTypeSelect } from 'components/elements/Select/StudyTypeSelect';
import { MyButton } from 'components/general/MyButton';
import ValidateTools from 'tools/validate.tools';
import MyTextField from 'components/form/mytextfield';
import { CommonTools } from "tools/commontools";
import { CircularLoading } from 'components/general/CircularLoading';

const studentService = new StudentService();

const UserEditStudent: React.FC<PageComponentProps> = ({
    currentRoute,
    ...props
}) => {
    const { LL } = useResource();
    const { user } = useAuth();

    const [loading, setLoading] = useState<boolean>(true);
    const [obj, setObj] = useState<StudentDto | undefined>();
    const _id = user !== undefined ? user.id : '';
    const [id, setId] = useState<string>(_id);
    const [isFormDisabled, setIsFormDisabled] = useState(false);


    const [validators, setValidators] = useState<any>();
    const [vresults, setVResults] = useState<any>(false)

    const validateField = (field: any, value: any, v?: any) => {

        v = (v) ? v : JSON.parse(JSON.stringify(vresults));
        if (v === false) v = {};
        v[field] = ValidateTools.validate(field, value, validators, vresults);

        setVResults(v);
        setIsFormDisabled(ValidateTools.vForm(v, field));
        return v;
    };

    const loadObject = (obj: any) => {
        if (!obj) return;
        if (!obj.err) {
            const object = obj.obj ? obj.obj : [];
            setObj(object);
        }
    };

    const getObject = () => {
        setLoading(true)
        if (id !== undefined && id !== '') studentService.getByUserId(id, loadObject, {});
    };

    const checkIsLoading = () => {
        let load: boolean = false;
        if (obj === undefined) load = true;
        setLoading(load);
    };

    useEffect(() => {
        getObject();
    }, [currentRoute]);

    useEffect(() => {
        validateAll();
        checkIsLoading();
    }, [obj]);

    useEffect(() => {

        if (user !== undefined) {
            setId(user.id)
        }
    }, [user])

    const validateAll = () => {
        if (!obj) return;
        // if (!validators) return;
        // if (vresults) return;

        obj.bio = obj.bio !== undefined ? obj.bio : '';
        obj.institution = obj.institution !== undefined ? obj.institution : '';
        obj.idtypestudies = obj.idtypestudies !== undefined ? obj.idtypestudies : '';

        setObj(obj);
    };


    const setObjField = (field: any, value: any) => {
        let t = JSON.parse(JSON.stringify(obj));
        t[field] = value;
        setObj(t);

        // validateField(field, value);
    };

    const handleSubmit = (event: any) => {
        event.preventDefault();
        if (isFormDisabled) return false;
        if (id !== undefined && obj) {
            obj.iduser = id;
            setLoading(true);
            studentService.add(CommonTools.handleSuccessAddAndEditStayOnPage, { setLoading }, obj);
        }
    };

    return user !== undefined && !loading && obj ? (
        <>
            <Box p={3}>
                <form onSubmit={handleSubmit} >
                    <Grid container spacing={2}>
                        <Grid item lg={6} sm={12}>
                            <Typography variant='h6'>{LL("Institution")}</Typography>
                            <MyTextField
                                tabIndex={0}
                                fullWidth
                                id="institution"
                                name="institution"
                                className="form-input"
                                variant="standard"
                                _label={LL("institution")}
                                size="small"
                                value={obj.institution}
                                setObj={setObjField}
                                _vresults={
                                    vresults && vresults.institution ? vresults.institution : false
                                }
                            />
                        </Grid>
                        <Grid item lg={6} sm={12}>
                            <Typography variant='h6'>{LL("Type studies")}</Typography>
                            <Box className="labelRemove">
                            <StudyTypeSelect
                                tabIndex={3}
                                id="idtypestudies"
                                name="idtypestudies"
                                value={obj.idtypestudies}
                                field="idtypestudies"
                                setObj={setObjField}
                                obj={obj}
                                _defaultValue={obj.idtypestudies}
                                className="form-select"
                                variant="standard"
                            /></Box>
                        </Grid>
                        <Grid item>
                            <Typography variant='h6'>{LL("Biografie")}</Typography>
                            <MyCKEditor
                                data={obj.bio ? obj.bio : ''}
                                setObj={setObjField}
                                name="bio"
                            />
                        </Grid>
                    </Grid>
                    

                    <Box textAlign="center">
                    <MyButton
                        tabIndex={4}
                        disabled={isFormDisabled}
                        color="primary"
                        variant="contained"
                        type="submit"
                        sx={{ mt: 4 }}
                        children={LL("Bt_Submit")}
                        className="btn"
                    /></Box>
                </form>
            </Box>

        </>
    ) : (
        <CircularLoading />
    );
};

export { UserEditStudent };
