import React, { useEffect, useState } from "react";
import PageComponentProps from "interfaces/pagecomponentprops.interface";
import { useResource } from "hook/useResource";
import { logger } from "hoc/logger";

import { MyNavBt } from "components/general/MyNavBt";
import Typography from '@mui/material/Typography'
import { Box, Stack } from '@mui/material'
import { ComponentCommonTools } from 'tools/componentcommontools'
import Grid from '@mui/material/Grid';

import TeacherService from "services/teacher/teacher.service";
import { BecomeTeacherDto } from "dto/user/becometeacher.dto";
import { useAuth } from "hook/useAuth";
import MyCKEditor from 'components/elements/ckeditor/MyCKEditor';
import { StudyTypeSelect } from 'components/elements/Select/StudyTypeSelect';
import { MyButton } from 'components/general/MyButton';
import ValidateTools from 'tools/validate.tools';
import MyTextField from 'components/form/mytextfield';
import { CommonTools } from "tools/commontools";
import { CircularLoading } from 'components/general/CircularLoading';
import { SpecialtyTypeSelect } from 'components/elements/Select/SpecialtyTypeSelect';
import { TargetGroupTypeSelect } from 'components/elements/Select/TargetGroupTypeSelect';
import { InternetConnectionTypeSelect } from 'components/elements/Select/InternetConnectionTypeSelect';
import { HardwareTypeSelect } from 'components/elements/Select/HardwareTypeSelect';
import { LessonTypeSelect } from 'components/elements/Select/LessonTypeSelect';
import { OccupationTypeSelect } from 'components/elements/Select/OccupationTypeSelect';
import { ProfessionalismLevelTypeSelect } from 'components/elements/Select/ProfessionalismLevelTypeSelect';
import { ExperienceTypeSelect } from 'components/elements/Select/ExperienceTypeSelect';
import { DisciplineTypeSelect } from 'components/elements/Select/DisciplineTypeSelect';
import { Stepper, Step, StepLabel } from '@mui/material';
import MyCheckboxField from "components/form/mycheckboxfield";

const teacherService = new TeacherService();


const BecomeTeacher: React.FC<PageComponentProps> = ({
    currentRoute,
    ...props
}) => {
    const { LL } = useResource();
    const steps = [LL('Step_1'), LL('Step_2'), LL('Step_3')];
    const { user, registerTeacher } = useAuth();

    const [loading, setLoading] = useState<boolean>(true);
    const [obj, setObj] = useState<BecomeTeacherDto>(new BecomeTeacherDto());
    const _id = user !== undefined ? user.id : '';
    const [id, setId] = useState<string>(_id);
    const [isFormDisabled, setIsFormDisabled] = useState(false);


    const [validators, setValidators] = useState<any>();
    const [vresults, setVResults] = useState<any>(false)

    const validateField = (field: any, value: any, v?: any) => {

        v = (v) ? v : JSON.parse(JSON.stringify(vresults));
        if (v === false) v = {};
        v[field] = ValidateTools.validate(field, value, validators, vresults);

        setVResults(v);
        setIsFormDisabled(ValidateTools.vForm(v, field));
        return v;
    };



    useEffect(() => {
        validateAll();
    }, [obj]);

    useEffect(() => {

        if (user !== undefined) {
            setId(user.id)
        }
    }, [user])

    const validateAll = () => {
        if (!obj) return;
        // if (!validators) return;
        // if (vresults) return;

        obj.bio = obj.bio !== undefined ? obj.bio : '';
        obj.graduatedinstitution = obj.graduatedinstitution !== undefined ? obj.graduatedinstitution : '';
        obj.timetoteachperweek = obj.timetoteachperweek !== undefined ? obj.timetoteachperweek : '';
        obj.whyliveclass = obj.whyliveclass !== undefined ? obj.whyliveclass : '';
        obj.hasaudience = obj.hasaudience !== undefined ? obj.hasaudience : false;


        obj.idtypestudies = obj.idtypestudies !== undefined ? obj.idtypestudies : '';
        obj.idtypespecialty = obj.idtypespecialty !== undefined ? obj.idtypespecialty : '';
        obj.idtypetargetgroup = obj.idtypetargetgroup !== undefined ? obj.idtypetargetgroup : '';
        obj.idtypeinternetconnection = obj.idtypeinternetconnection !== undefined ? obj.idtypeinternetconnection : '';
        obj.idtypelesson = obj.idtypelesson !== undefined ? obj.idtypelesson : '';
        obj.idtypediscipline = obj.idtypediscipline !== undefined ? obj.idtypediscipline : '';
        obj.idtypeoccupation = obj.idtypeoccupation !== undefined ? obj.idtypeoccupation : '';
        obj.idtypeprofessionalismlevel = obj.idtypeprofessionalismlevel !== undefined ? obj.idtypeprofessionalismlevel : '';
        obj.idtypeexperience = obj.idtypeexperience !== undefined ? obj.idtypeexperience : '';

        setObj(obj);
    };


    const setObjField = (field: any, value: any) => {
        let t = JSON.parse(JSON.stringify(obj));
        t[field] = value;
        setObj(t);

        // validateField(field, value);
    };
    const setCheckFixed = (value: any) => {
        let t = JSON.parse(JSON.stringify(obj));

        t['hasaudience'] = value;
        setObj(t);

    };
    const handleSubmit = (event: any) => {
        try {
            event.preventDefault();
            if (isFormDisabled) return false;
            if (id !== undefined && obj) {
                obj.iduser = id;

                registerTeacher(obj)

            }
        } catch (e) {

        }
        return false;
    };

    const [activeStep, setActiveStep] = useState(0);

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };
    const [term, setTerm] = useState(false);
    const setObjTerm = (value: any) => {
        setTerm(value);
    };

    const checkIsFormDisabled = () => {
        // var rez = ValidateTools.vForm(vresults, 'email');
        // rez = ValidateTools.vForm(vresults, "password", rez);
        // if (obj.password !== confirmPassword) {
        //     rez = true;
        // }
        let rez = false;
        if (term == false) rez = true;
        setIsFormDisabled(rez);
    };

    useEffect(() => {
        checkIsFormDisabled();
    }, [term]);

    const getStepContent = (step: number) => {
        switch (step) {
            case 0:
                return (
                    <Box >
                        <MyTextField

                            fullWidth
                            id="timetoteachperweek"
                            name="timetoteachperweek"
                            variant="standard"
                            className="form-input"
                            _label={LL("timetoteachperweek")}

                            value={obj.timetoteachperweek}
                            setObj={setObjField}
                            _vresults={
                                vresults && vresults.timetoteachperweek ? vresults.timetoteachperweek : false
                            }
                        />

                        <Box sx={{ mt: 3 }}>
                            <MyTextField

                                fullWidth
                                id="whyliveclass"
                                name="whyliveclass"
                                variant="standard"
                                className="form-input"
                                _label={LL("whyliveclass")}

                                value={obj.whyliveclass}
                                setObj={setObjField}
                                _vresults={
                                    vresults && vresults.whyliveclass ? vresults.whyliveclass : false
                                }
                            />
                        </Box>
                        <Box sx={{ mt: 3 }}>
                            <MyTextField

                                fullWidth
                                id="graduatedinstitution"
                                name="graduatedinstitution"
                                variant="standard"
                                className="form-input"
                                _label={LL("graduatedinstitution")}

                                value={obj.graduatedinstitution}
                                setObj={setObjField}
                                _vresults={
                                    vresults && vresults.graduatedinstitution ? vresults.graduatedinstitution : false
                                }
                            />
                        </Box>
                        <Box sx={{ mt: 3 }}>
                            <ExperienceTypeSelect

                                id="idtypeexperience"
                                name="idtypeexperience"
                                variant="standard"
                                className="form-select"
                                value={obj.idtypeexperience}
                                field="idtypeexperience"

                                setObj={setObjField}
                                obj={obj}
                                _defaultValue={obj.idtypeexperience}
                            />
                        </Box>

                        <Box sx={{ mt: 3 }}>
                            <MyCKEditor
                                data={obj.bio ? obj.bio : ''}
                                setObj={setObjField}
                                name="bio"

                            />
                        </Box>
                    </Box>
                );
            case 1:
                return (
                    <Box >

                        <Box sx={{ mt: 3 }}>
                            <StudyTypeSelect
                                tabIndex={7}
                                id="idtypestudies"
                                name="idtypestudies"
                                variant="standard"
                                className="form-select"
                                value={obj.idtypestudies}
                                field="idtypestudies"

                                setObj={setObjField}
                                obj={obj}
                            />
                        </Box>
                        <Box sx={{ mt: 3 }}>
                            <SpecialtyTypeSelect
                                tabIndex={8}
                                id="idtypespecialty"
                                name="idtypespecialty"
                                variant="standard"
                                className="form-select"
                                value={obj.idtypespecialty}
                                field="idtypespecialty"

                                setObj={setObjField}
                                obj={obj}
                            />
                        </Box>
                        <Box sx={{ mt: 3 }}>
                            <MyCheckboxField
                                id="hasaudience"
                                name="hasaudience"
                                _label={LL("hasaudience")}
                                setObj={setCheckFixed}
                                checked={obj.hasaudience}

                                _vresults={undefined}
                            />
                        </Box>
                        <Box sx={{ mt: 3 }}>
                            <TargetGroupTypeSelect
                                id="idtypetargetgroup"
                                name="idtypetargetgroup"
                                variant="standard"
                                className="form-select"
                                value={obj.idtypetargetgroup}
                                field="idtypetargetgroup"

                                setObj={setObjField}
                                obj={obj}

                            />
                        </Box>
                        <Box sx={{ mt: 3 }}>
                            <InternetConnectionTypeSelect
                                id="idtypeinternetconnection"
                                name="idtypeinternetconnection"
                                variant="standard"
                                className="form-select"
                                value={obj.idtypeinternetconnection}
                                field="idtypeinternetconnection"

                                setObj={setObjField}
                                obj={obj}

                            />
                        </Box>
                    </Box>
                );
            case 2:
                return (
                    <Box >
                        <Box sx={{ mt: 3 }}>
                            <HardwareTypeSelect
                                tabIndex={11}
                                id="idtypehardware"
                                name="idtypehardware"
                                variant="standard"
                                className="form-select"
                                value={obj.idtypehardware}
                                field="idtypehardware"

                                setObj={setObjField}
                                obj={obj}

                            />
                        </Box>
                        <Box sx={{ mt: 3 }}>
                            <LessonTypeSelect
                                tabIndex={12}
                                id="idtypelesson"
                                name="idtypelesson"
                                variant="standard"
                                className="form-select"
                                value={obj.idtypelesson}
                                field="idtypelesson"

                                setObj={setObjField}
                                obj={obj}

                            />
                        </Box>
                        <Box sx={{ mt: 3 }}>
                            <OccupationTypeSelect
                                tabIndex={13}
                                id="idtypeoccupation"
                                name="idtypeoccupation"
                                variant="standard"
                                className="form-select"
                                value={obj.idtypeoccupation}
                                field="idtypeoccupation"

                                setObj={setObjField}
                                obj={obj}

                            />
                        </Box>
                        <Box sx={{ mt: 3 }}>
                            <DisciplineTypeSelect
                                id="idtypediscipline"
                                name="idtypediscipline"
                                variant="standard"
                                className="form-select"
                                value={obj.idtypediscipline}
                                field="idtypediscipline"

                                setObj={setObjField}
                                obj={obj}

                            />
                        </Box>
                        <Box sx={{ mt: 3 }}>
                            <ProfessionalismLevelTypeSelect
                                tabIndex={14}
                                id="idtypeprofessionalismlevel"
                                name="idtypeprofessionalismlevel"
                                variant="standard"
                                className="form-select"
                                value={obj.idtypeprofessionalismlevel}
                                field="idtypeprofessionalismlevel"

                                setObj={setObjField}
                                obj={obj}

                            />
                        </Box>

                    </Box>
                );
            default:
                return 'Unknown step';
        }
    };


    return user !== undefined && obj ? (
        <>

            <Box className='container' sx={{ maxWidth: 650 }} mt={5}>
                <Stepper activeStep={activeStep}>
                    {steps.map((label, index) => (
                        <Step key={index}>
                            <StepLabel>{label}</StepLabel>
                        </Step>
                    ))}
                </Stepper>
                {activeStep === steps.length ? (
                    <>
                        <Typography sx={{ mt: 2, mb: 1 }}>{LL('Condition_Terms_Become_Teacher')}</Typography>
                        <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                            <MyButton cb={handleBack} sx={{ mr: 1 }}>
                                {LL('Back')}
                            </MyButton>
                            <Box>
                                <MyCheckboxField
                                    id="term"
                                    name="term"
                                    _label={LL("term")}
                                    setObj={setObjTerm}
                                    checked={term}
                                    color="primary"
                                    size="small"
                                    _vresults={undefined}
                                />
                            </Box>
                            <MyButton
                                tabIndex={4}
                                cb={handleSubmit}
                                disabled={isFormDisabled}
                                color="primary"
                                fullWidth
                                variant="contained"
                                size="large"
                                type="submit"
                                sx={{ mt: 4 }}
                                children={LL("Bt_Submit")}
                            />
                        </Box>
                    </>
                ) : (
                    <>
                        <Box sx={{ mt: 5 }}>
                            {getStepContent(activeStep)}
                        </Box>

                        <Stack direction='row' spacing={2} alignItems='center' mt={5} justifyContent='space-between'>
                            <MyButton disabled={activeStep === 0} cb={handleBack} className='btn pxBnt' variant='outlined'>
                                {LL('Back')}
                            </MyButton>
                            <MyButton cb={handleNext} className='btn pxBnt' variant='contained'>
                                {activeStep === steps.length - 1 ? LL('Finish') : LL('Next')}
                            </MyButton>
                        </Stack>
                    </>
                )}
                {/* </Box>
            <Box p={3}>
                <form onSubmit={handleSubmit} >
                   
                  
                   

                    <MyButton
                        tabIndex={4}
                        disabled={isFormDisabled}
                        color="primary"
                        fullWidth
                        variant="contained"
                        size="large"
                        type="submit"
                        sx={{ mt: 4 }}
                        children={LL("Bt_Submit")}
                    />
                </form>
            </Box> */}
            </Box>
        </>
    ) : (
        <CircularLoading />
    );
};

export { BecomeTeacher };
