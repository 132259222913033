import React, { useCallback, useEffect, useRef, useState } from "react";
import { useResource } from "hook/useResource";
import { logger } from "hoc/logger";
import { RouteTools } from "tools/routetools";
import RequestListDTO from "dto/app/requestlist.dto";
import { Status } from "tools/status";
import RequestFilterDTO from "dto/app/requestfilter.dto";
import ResultListDTO from "dto/app/resultlist.dto";
import { Types } from "tools/types";
import { ComponentCommonTools } from 'tools/componentcommontools'
import { Box, Grid, Stack, Typography, Skeleton, ListItem, List } from "@mui/material";
import { CircularLoading } from "components/general/CircularLoading";
import { TestResultAnswerDto } from "dto/course/testresultanswer.dto";
import TestResultAnswerService from "services/course/testresultanswer.service";
import { TestResultAnswerItem } from "./TestResultAnswerItem";
import { NoResult } from "components/general/NoResult";

const testResultAnswerService = new TestResultAnswerService();
type TestResultAnswerListProps = {
    idTestResult: string;
    idTestResultQuestion: string;

}
const TestResultAnswerList: React.FC<TestResultAnswerListProps> = ({
    idTestResult,
    idTestResultQuestion,
    ...props }) => {

    const { LL, CC } = useResource();
    const [isLoading, setIsLoading] = useState(true);
    const [objects, setObjects] = useState<Array<TestResultAnswerDto>>([]);
    const [total, setTotal] = useState<number>(-1);
    const [totalPage, setTotalPage] = useState<number>(-1);
    const [responseParams, setResponseParams] = useState<any>({});
    const [onPage, setOnPage] = useState<number>(parseInt(CC('OnPageTestResultAnswerList', '9999999')));
    const [page, setPage] = useState<number>(1);

    const getList = () => {
        if (!idTestResult) return;


        const reqList = new RequestListDTO();
        reqList.onpage = onPage
        reqList.page = page;
        reqList.filters = [];
        reqList.sortcriteria = [];

        let f: RequestFilterDTO;

        f = new RequestFilterDTO();
        f.field = "idtestresult";
        f.values = [idTestResult];
        reqList.filters.push(f);

        f = new RequestFilterDTO();
        f.field = "idtestresultquestion";
        f.values = [idTestResultQuestion];
        reqList.filters.push(f);

        setIsLoading(true);
        testResultAnswerService.getListForTeacher(loadObjects, {}, reqList);
    };

    const loadObjects = (result: ResultListDTO) => {
        if (!result) return;
        if (!result.err) {
            const objects = result.objects ? result.objects : [];
            const total = result.total !== undefined ? result.total : -1;
            const totalPage = result.totalpages !== undefined ? result.totalpages : -1;
            const reqInfo = result.requestinfo ? result.requestinfo : {};
            setObjects(objects);
            setTotal(total);
            setTotalPage(totalPage);
            setResponseParams(reqInfo);
        }
    }

    const checkLoading = () => {
        let load = false;

        if (total === -1) return true;
        if (totalPage === -1) return true;
        if (!objects) load = true;
        if (Object.keys(responseParams).length === 0) return true;

        setIsLoading(load);
    }

    useEffect(() => {
        getList();
    }, [
        idTestResult
    ]);

    useEffect(() => {
        checkLoading();
    }, [objects]);

    const processItem = (obj: TestResultAnswerDto, index: number) => {
        if (!obj) return <></>;
        return (
            <ListItem
                key={index}
            >
             <TestResultAnswerItem obj={obj} />
            </ListItem>
        )
    }

    const processList = () => {

        if(!idTestResult) return <></>;
        if(isLoading) return <CircularLoading />;
        if (!objects.length) return <NoResult identifier="answer_test_result" />;


        return (

            <List>
                {objects.map((item: TestResultAnswerDto, i: number) => {
                    return processItem(item, i)
                })}

            </List>

        );
    };

    return processList();
}

export { TestResultAnswerList };