
import { GoogleOAuthProvider } from "@react-oauth/google";

export const MyGoogleOAuthProvider = ({ children }) => {
   
  const clientId: string = process.env.REACT_APP_FBCONSOLE_APP_ID ?? "";
  return (
    <GoogleOAuthProvider clientId={clientId}>{children}</GoogleOAuthProvider>
  );
};
