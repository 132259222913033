import React from "react";
import PageComponentProps from "interfaces/pagecomponentprops.interface";
import { TeacherInterfaceFAQList } from "components/teacherinterface/TeacherInterfaceFAQList";
import Typography from "@mui/material/Typography";
import { useResource } from "hook/useResource";

const TeacherInterfaceFAQBlock: React.FC<PageComponentProps> = ({ currentRoute, ...props }) => {
    const {LL} = useResource();
    return (
        <>
        <Typography variant="h3" textAlign='center' my={3}>{LL("FAQ profesor")}</Typography>
            <TeacherInterfaceFAQList currentRoute={currentRoute} />
        </>);
};

export { TeacherInterfaceFAQBlock };
