import React, { useEffect, useState } from "react";
import PageComponentProps from "interfaces/pagecomponentprops.interface";
import { useResource } from "hook/useResource";
import { logger } from "hoc/logger";
import MenuService from "services/menu/menu.service";

import { Box } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { TreeView } from '@mui/x-tree-view/TreeView';
import { BlogCategoryLevel } from "./BlogCategoryLevel";

type BlockBlogCategoryProps = {
    currentRoute: any;
    setBlogCategoryId: any;
}
const BlockBlogCategory: React.FC<BlockBlogCategoryProps> = ({
    setBlogCategoryId,
    currentRoute,
    ...props
}) => {


    return (
        <Box className="blogCategory">
            <BlogCategoryLevel currentRoute={currentRoute} level={1} idparent={""} setBlogCategoryId={setBlogCategoryId} />
        </Box>
    );

};

export { BlockBlogCategory };