import React, { useState, useEffect } from "react";

import { logger } from "hoc/logger";
import useMyFormValidation from "hook/useMyFormValidation";
import { CourseSettingsDto } from "dto/course/coursesettings.dto";
import { useCookies } from "react-cookie";
import { Loading } from "components/general/Loading.jsx";
import { MyNavBt } from "components/general/MyNavBt";
import { useResource } from "hook/useResource";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { CommonTools } from "tools/commontools";
import { ComponentCommonTools } from "tools/componentcommontools";
import ResultObjectDTO from "dto/app/resultobject.dto";
import MyTextField from "components/form/mytextfield";
import { LabelTypes } from "tools/labeltypes";
import ValidateTools from "tools/validate.tools";
import RequiredValidator from "validators/required.validator";
import { MyButton } from "components/general/MyButton";
import { RouteTools } from "tools/routetools";
import { MyBreadcrumbs } from "components/elements/MyBreadcrumbs/MyBreadcrumbs";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CourseSettingsService from "services/course/coursesettings.service";
import IconButton from "@mui/material/IconButton";
import { LanguageSelect } from "components/elements/Select/LanguageSelect";
import { TypeGenderSelect } from "components/elements/Select/GenderTypeSelect";
import { CourseTypeSelect } from "components/elements/Select/CourseTypeSelect";
import MyDatePicker from "components/elements/MyDatePicker/MyDatePicker";
import { Status } from "tools/status";
import { CircularLoading } from "components/general/CircularLoading";
import { useMessage } from "hook/useMessage";
const courseSettings = new CourseSettingsService();

interface EditCourseSettingsInfoProps {
  currentRoute: any;
  setObjectCourseHasChanged: (value: string) => void;
}

const EditCourseSettingsInfo: React.FC<EditCourseSettingsInfoProps> = ({
  currentRoute,
  setObjectCourseHasChanged,
  ...props
}) => {
  const { LL } = useResource();

  const id = currentRoute._paths !== undefined ? currentRoute._paths[2] : "";

  const [loading, setLoading] = useState<boolean>(false);
  const [obj, setObj] = useState<CourseSettingsDto | undefined>();
  const { registerDialog } = useMessage();
  const [isFormDisabled, setIsFormDisabled] = useState(false);
  const [objectHasChanged, setObjectHasChanged] = useState<string>("");

  const [validators, setValidators] = useState<any>();
  const [vresults, setVResults] = useState<any>(false);

  const validateAll = () => {
    if (!obj) return;
    if (!validators) return;
    // if (vresults) return;

    obj.id = obj.id ?? obj.id;
    obj.idtypecourse = obj.idtypecourse !== undefined ? obj.idtypecourse : "";
    obj.password = obj.password !== undefined ? obj.password : "";
    obj.maxnumberofstudents =
      obj.maxnumberofstudents !== undefined
        ? obj.maxnumberofstudents
        : undefined;

    setObj(obj);

    let t = validateField("password", obj.password);
  };

  const validateField = (field: any, value: any, v?: any) => {
    v = v ? v : JSON.parse(JSON.stringify(vresults));
    if (v === false) v = {};
    v[field] = ValidateTools.validate(field, value, validators, vresults);

    setVResults(v);
    setIsFormDisabled(ValidateTools.vForm(v, field));

    return v;
  };

  const processValidators = () => {
    let v: any = {
      password: [],
    };

    v.password.push(new RequiredValidator("", ""));

    setValidators(v);
  };

  const checkLoading = () => {
    if (!loading) return;
    if (obj === undefined) return;
    // if (!validators) return;
    validateAll();
    setLoading(false);
  };

  const loadObject = (obj: ResultObjectDTO) => {
    if (!obj) return;
    if (!obj.err) {
      const object = obj.obj ? obj.obj : {};
      setObj(object);
    }
  };

  const getObject = () => {
    setLoading(true);
    if (id !== undefined) {
      courseSettings.getCourseSettingsByCourseId(id, loadObject, {});
    }
  };

  useEffect(() => {
    processValidators();
  }, []);
  const checkIsFormDisabled = () => {
    // var rez = ValidateTools.vForm(vresults, 'password');
    setIsFormDisabled(false);
  };

  useEffect(() => {
    validateAll();
    checkLoading();
  }, [obj, validators]);

  useEffect(() => {
    checkIsFormDisabled();
  }, [vresults]);

  useEffect(() => {
    getObject();
  }, [objectHasChanged]);

  const handleSubmit = (event: any) => {
    event.preventDefault();
    if (isFormDisabled) return false;
    if (!obj) return;
    obj.idcourse = id;
    if (obj.id !== undefined) {
      registerDialog(
        LL("Confirm_edit_course"),
        LL(
          "Are_you_sure_you_want_to_edit_this_course_editing_will_change_the_status"
        ),
        { cb: handleConfirmEdit },
        null
      );
    } else {
      registerDialog(
        LL("Confirm_edit_course"),
        LL(
          "Are_you_sure_you_want_to_edit_this_course_editing_will_change_the_status"
        ),
        { cb: handleConfirmAdd },
        null
      );
    }
  };
  const setObjField = (field: any, value: any) => {
    let t = JSON.parse(JSON.stringify(obj));
    t[field] = value;
    setObj(t);
  };

  const handleConfirmEdit = () => {
    if (obj && obj.id !== undefined) {
      courseSettings.update(
        obj.id,
        CommonTools.handleSuccessAddAndEditStayOnPage,
        {
          setObjectWasChanged: setObjectHasChanged,
          setObjectCourseHasChanged: setObjectCourseHasChanged,
        },
        obj
      );
    }
  };

  const handleConfirmAdd = () => {
    if (obj) {
      courseSettings.add(
        CommonTools.handleSuccessAddAndEditStayOnPage,
        {
          setObjectWasChanged: setObjectHasChanged,
          setObjectCourseHasChanged: setObjectCourseHasChanged,
        },
        obj
      );
    }
  };

  return !loading && obj ? (
    <>
      <form onSubmit={handleSubmit}>
        <Box sx={{ mt: 3, ".MuiFormLabel-root": { display: "none" } }}>
          <Typography variant="h6" mb={1}>
            {LL("Tip curs")}
          </Typography>
          <CourseTypeSelect
            tabIndex={0}
            id="idtypecourse"
            name="idtypecourse"
            variant="standard"
            className="form-select"
            color="primary"
            value={obj.idtypecourse}
            field="idtypecourse"
            setObj={setObjField}
            obj={obj}
            _defaultValue={obj.idtypecourse}
          />
        </Box>
        <Box mt={3}>
          <Typography variant="h6" mb={1}>
            {LL("Parola curs")}
          </Typography>
          <MyTextField
            tabIndex={1}
            fullWidth
            id="password"
            name="password"
            variant="standard"
            className="form-input"
            color="primary"
            _label={LL("password")}
            size="small"
            value={obj.password}
            setObj={setObjField}
            _vresults={false}
          />
        </Box>
        <Box mt={3}>
          <Typography variant="h6" mb={1}>
            {LL("Numar maxim de studenti")}
          </Typography>
          <MyTextField
            tabIndex={1}
            fullWidth
            id="maxnumberofstudents"
            name="maxnumberofstudents"
            variant="standard"
            className="form-input"
            color="primary"
            _label={LL("Max_number_of_students")}
            size="small"
            value={obj.maxnumberofstudents}
            setObj={setObjField}
            _vresults={false}
          />
        </Box>
        <Box mt={4} textAlign="center">
          <MyButton
            tabIndex={2}
            disabled={isFormDisabled}
            color="primary"
            fullWidth
            variant="contained"
            size="large"
            type="submit"
            className="btn"
            children={LL("Bt_Submit")}
          />
        </Box>
      </form>
    </>
  ) : (
    <CircularLoading />
  );
};

export default EditCourseSettingsInfo;
