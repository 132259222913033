import React, { useState, useEffect } from "react";
import PageComponentProps from "interfaces/pagecomponentprops.interface";
import { BlogDto } from "dto/blog/blog.dto";
import RequestListDTO from "dto/app/requestlist.dto";
import { useCookies } from 'react-cookie';
import { CommonTools } from "tools/commontools";
import { useResource } from "hook/useResource";
import { MyPagination } from "components/elements/MyPagination/MyPagination";
import BlogService from "services/blog/blog.service";
import RequestFilterDTO from "dto/app/requestfilter.dto";
import { Status } from "tools/status";
import { RouteTools } from "tools/routetools";
import ResultListDTO from "dto/app/resultlist.dto";
import { Box, Grid, Skeleton, Typography } from "@mui/material";
import { NewsletterBlock } from "components/general/NewsletterBlock";
import { BlockBlogCategory } from "components/static/blogcategory/BlockBlogCategory";
import { logger } from "hoc/logger";
import { BlogCard } from "components/static/blog/BlogCard";
import BreadcrumbChildrenDto from "dto/app/breadcrumbchildren.dto";
import { MyBreadcrumbsCourse } from "components/elements/MyBreadcrumbs/MyBreadcrumbsCourse";

const blogService = new BlogService();

const BlogBlock: React.FC<PageComponentProps> = ({ currentRoute, ...props }) => {

    const { LL, CC } = useResource();

    const mainUrl = currentRoute && currentRoute.url ? currentRoute.url.split("?") : "";
    const [isLoading, setIsLoading] = useState(true);
    const [objects, setObjects] = useState<Array<BlogDto>>([]);
    const [cookies, setCookies] = useCookies();
    const [page, setPage] = useState<number>(1);
    const [total, setTotal] = useState<number>(-1);
    const [totalPage, setTotalPage] = useState<number>(-1);
    const [responseParams, setResponseParams] = useState<any>({});
    const [onPage, setOnPage] = useState<number>(parseInt(CC('BlogListOnPage', '10')));
    const [reqList, setReqList] = useState<RequestListDTO>(new RequestListDTO());

    const [idCategory, setIdCategory] = useState<string>("");

    const getList = () => {

        setIsLoading(true);

        reqList.onpage = onPage;
        reqList.page = page;
        reqList.filters = [];

        let f: RequestFilterDTO;

        f = new RequestFilterDTO();
        f.field = "status";
        f.values = [];
        f.values.push(Status.ACTIVE.toString());
        reqList.filters.push(f);

        f = new RequestFilterDTO();
        f.field = "idblogcategory";
        f.values = [idCategory];
        reqList.filters.push(f);

        const typeBlogCategory = process.env.REACT_APP_DEFAULT_ID_TYPE_BLOG_CATEGORY ? process.env.REACT_APP_DEFAULT_ID_TYPE_BLOG_CATEGORY : "";
        f = new RequestFilterDTO();
        f.field = "idtypeblogcategory";
        f.values = [typeBlogCategory];
        reqList.filters.push(f);


        const urlPart = RouteTools.prepareListLocation(reqList);

        RouteTools.setHistory(mainUrl[0] + urlPart, {});
        blogService.getList(loadObjects, {}, reqList);

        // setIsLoading(false);
    };

    useEffect(() => {
        const reqListRoute = RouteTools.prepareListRequest(currentRoute, cookies);
        if (reqListRoute.page) setPage(reqListRoute.page);

    }, []);

    const loadObjects = (result: ResultListDTO) => {
        if (!result) return;
        if (!result.err) {
            const objects = result.objects ? result.objects : [];
            const total = result.total !== undefined ? result.total : -1;
            const totalPage = result.totalpages !== undefined ? result.totalpages : -1;
            const reqInfo = result.requestinfo ? result.requestinfo : {};
            setObjects(objects);
            setTotal(total);
            setTotalPage(totalPage);
            setResponseParams(reqInfo);
        }
    }

    const checkLoading = () => {
        let load = false;

        if (total === -1) return true;
        if (totalPage === -1) return true;
        if (!objects) load = true;
        // if (objects.length === 0) load = true;
        if (Object.keys(responseParams).length === 0) return true;

        setIsLoading(load);
    }

    useEffect(() => {
        getList();
    }, [page, idCategory]);

    useEffect(() => {
        checkLoading();
    }, [objects]);

    const processPagination = () => {
        if (totalPage === -1) return <></>;
        if (total === -1) return <></>;
        if (objects.length === 0) return <></>;

        return (
            <Box className="container" py={4} display='flex' sx={{ justifyContent: 'center' }}>
                <MyPagination
                    page={page}
                    total={total}
                    totalPage={totalPage}
                    setPage={setPage} />
            </Box>);
    }

    const processBlogItem = (obj: BlogDto, index: number) => {

        if (!obj) return <></>;
        return (<Grid item sm={12} md={4} key={index}>
            <BlogCard obj={obj} loading={isLoading} />
        </Grid>);

    };

    const processBlogList = () => {
        if (!objects.length) return <Typography  textAlign='center'>{LL('No_Blog')}</Typography>;

        return (
            <Grid container className="blogList" alignItems='stretch' alignContent="stretch">
                {objects.map((item: BlogDto, i: number) => {
                    return processBlogItem(item, i);
                })}
            </Grid>
        );
    };
    const processBreadcrumbChildren = (): BreadcrumbChildrenDto[] => {
        const breadcrumbArray: BreadcrumbChildrenDto[] = [];
        let breadcrumb = new BreadcrumbChildrenDto();
        breadcrumb.url = '/';
        breadcrumb.label = LL('crumb_homepage');
        breadcrumbArray.push(breadcrumb);
    
        breadcrumb = new BreadcrumbChildrenDto();
        breadcrumb.url = '';
        breadcrumb.label = LL('crumb_Blog');
        breadcrumbArray.push(breadcrumb);
    
        
    
        return breadcrumbArray;
      }

    const handleSetAllCategories = () => {
        setIdCategory("");
    }
    const processBlogCategoryList = () => {
        return (<Box >
            <Typography variant="h4" mb={3} onClick={handleSetAllCategories}>{LL("All_Categories")}</Typography>
            <BlockBlogCategory currentRoute={currentRoute} setBlogCategoryId={setIdCategory} />
        </Box>)
    }
    useEffect(() => {
        if (idCategory) logger("idCategoryBlog", idCategory)
    }, [idCategory])

    const processBreadcrumb = () => {
        const breadcrumbChildren = processBreadcrumbChildren();
        return <Box className="breadcrumbs"><MyBreadcrumbsCourse childs={breadcrumbChildren} /></Box>
      }

      
    return (
        <Box className="pageGeneral">
            <Box className="onlyBread" py={3}><Box className="container">{processBreadcrumb()}</Box></Box>
            <Box className="container" py={3}>
                <Grid container spacing={2} mt={4}>
                    <Grid item sm={12} lg={9} >
                        {isLoading ? <Skeleton /> : processBlogList()}
                        {isLoading ? <Skeleton /> : processPagination()}
                    </Grid>
                    <Grid item sm={12} lg={3}>
                        {processBlogCategoryList()}
                    </Grid>
                </Grid></Box>
            <Box><NewsletterBlock currentRoute={currentRoute} /></Box>
        </Box>

    );
};

export { BlogBlock };
