import React, { useEffect, useState } from "react";
import { useResource } from "hook/useResource";
import { logger } from "hoc/logger";
import TeacherService from "services/teacher/teacher.service";
import MenuLevelProps from "interfaces/menulevelprops.interface";
import { TeacherDto } from "dto/teacher/teacher.dto";
import { RouteTools } from "tools/routetools";
import RequestListDTO from "dto/app/requestlist.dto";
import { Status } from "tools/status";
import RequestFilterDTO from "dto/app/requestfilter.dto";
import ResultListDTO from "dto/app/resultlist.dto";
import { Types } from "tools/types";
import MenuItemProps from "interfaces/menuitemprops.interface";
import { MyNavBt } from "components/general/MyNavBt";

import Box from "@mui/material/Box";
import VerifiedOutlinedIcon from '@mui/icons-material/VerifiedOutlined';
import SvgIcon from '@mui/material/SvgIcon';
import { Stack, Typography, Divider } from "@mui/material";
import { ComponentCommonTools } from "tools/componentcommontools";
import { TeacherTypeIcon } from "./TeacherTypeIcon";
import { MyRatingReadOnly } from "components/elements/MyRating/MyRatingReadOnly";
type TeacherProcessNameCourseProps = {
    obj: TeacherDto;

}

const TeacherProcessNameCourse: React.FC<TeacherProcessNameCourseProps> = ({
    obj,
    ...props
}) => {
    const { LL } = useResource();

    const processTeacherIcon = () => {
        if (!obj) return <></>;
        if (!obj.hasOwnProperty('status')) return <></>;
        if (!obj.status) return <></>;

        return <TeacherTypeIcon obj={obj} />
    }

    const processTypeDiscipline = () => {
        if (!obj) return <></>;
        if (!obj.hasOwnProperty('typediscipline')) return <></>;
        if (!obj.typediscipline) return <></>;

        return < Typography>
            {ComponentCommonTools.processObjectFieldOfObject(obj, 'typediscipline', 'name')}
        </Typography>
    }
    const processRating = () => {
        if (!obj) return <></>;
        if (!obj.hasOwnProperty('rating') || !obj.hasOwnProperty('numberoftestimonials')) return <MyRatingReadOnly rating={0} count={0} />;
        if (!obj.rating || !obj.numberoftestimonials) return <MyRatingReadOnly rating={0} count={0} />;
        return <MyRatingReadOnly rating={obj.rating} count={obj.numberoftestimonials} />

    }

    const processFullName = () => {
        if (!obj) return <></>;
        if (!obj.hasOwnProperty('fullname')) return <></>;
        if (!obj.fullname) return <></>;
        return (
            <Typography >
                {ComponentCommonTools.processObjectField(obj, 'fullname')}
            </Typography>
        )
    }
    const processItem = () => {
        if (!obj) return <></>;

        return <Stack direction='column'>
            <Stack
                direction='row'
                spacing={1}
                justifyContent="flex-start"
                alignItems="center"
                className="teacherName"
            >
                <Box className="icon-teacher">{processTeacherIcon()}</Box>
                {processFullName()}
                <Divider orientation="vertical" />
                {processTypeDiscipline()}
            </Stack>
            {processRating()}
        </Stack>
    };

    const processLink = () => {
        if (!obj) return <></>;
        if (!obj.hasOwnProperty('fullurl')) return processItem();
        if (!obj.fullurl) return processItem();
        return (<MyNavBt
            color="inherit"
            underline="none"
            href={obj.fullurl}
        >
            {processItem()}
        </MyNavBt>)

    }


    return processLink();


};

export { TeacherProcessNameCourse };
