import { Box, ListItem, MenuItem, Skeleton, Typography } from "@mui/material";
import { MyPagination } from "components/elements/MyPagination/MyPagination";
import { MySortSelect } from "components/elements/Select/MySortSelect";
import { NoResult } from "components/general/NoResult";
import RequestFilterDTO from "dto/app/requestfilter.dto";
import RequestListDTO from "dto/app/requestlist.dto";
import RequestSortCriteriaDTO from "dto/app/requestsortcriteria.dto";
import ResultListDTO from "dto/app/resultlist.dto";
import { CourseTeacherRequestDto } from "dto/course/courseteacherrequest.dto";
import { useAuth } from "hook/useAuth";
import { useResource } from "hook/useResource";
import React, { useState, useEffect } from "react";
import { useCookies } from "react-cookie";
import CourseTeacherRequestService from "services/course/courseteacherrequest.service";
import { RouteTools } from "tools/routetools";
import { Status } from "tools/status";
import { Types } from "tools/types";
import { CourseTeacherInterfaceRequestItem } from "./CourseTeacherInterfaceRequestItem";



type CourseTeacherInterfaceRequestListProps = {
    currentRoute: any;

}

const courseTeacherRequestService = new CourseTeacherRequestService();

const CourseTeacherInterfaceRequestList: React.FC<CourseTeacherInterfaceRequestListProps> = ({
    currentRoute,

    ...props }) => {

    const { LL, CC } = useResource();
    const { teacher } = useAuth();
    const [loading, setLoading] = useState<boolean>(false);
    const [objects, setObjects] = useState<Array<CourseTeacherRequestDto>>([]);
    const [sortCriteria, setSortCriteria] = useState<Array<RequestSortCriteriaDTO>>(new Array<RequestSortCriteriaDTO>());
    const mainUrl = currentRoute && currentRoute.url ? currentRoute.url.split("?") : "";

    const [total, setTotal] = useState<number>(-1);
    const [totalPage, setTotalPage] = useState<number>(-1);
    const [responseParams, setResponseParams] = useState<any>({});
    const [onPage, setOnPage] = useState<number>(parseInt(CC('OnPageCourseTeacherInterfaceRequestList', '10')));
    const [page, setPage] = useState<number>(1);
    const [cookies, setCookies] = useCookies();

    const getList = () => {
        if (!teacher) return;

        const reqList = new RequestListDTO();
        reqList.onpage = onPage
        reqList.page = page;
        reqList.filters = [];
        reqList.sortcriteria = sortCriteria;

        let f: RequestFilterDTO;

        f = new RequestFilterDTO();
        f.field = "idteacher";
        f.values = [teacher.id];
        reqList.filters.push(f);

        const urlPart = RouteTools.prepareListLocation(reqList);

        RouteTools.setHistory(mainUrl[0] + urlPart, {});
        setLoading(true);
        courseTeacherRequestService.getList(loadObjects, {}, reqList);
    };

    const loadObjects = (result: ResultListDTO) => {
        if (!result) return;
        if (!result.err) {
            const objects = result.objects ? result.objects : [];
            const total = result.total !== undefined ? result.total : -1;
            const totalPage = result.totalpages !== undefined ? result.totalpages : -1;
            const reqInfo = result.requestinfo ? result.requestinfo : {};
            setObjects(objects);
            setTotal(total);
            setTotalPage(totalPage);
            setResponseParams(reqInfo);
        }
    }

    const checkLoading = () => {
        let load = false;

        if (total === -1) return true;
        if (totalPage === -1) return true;
        if (!objects) load = true;
        if (Object.keys(responseParams).length === 0) return true;

        setLoading(load);
    }
    const defaultSortCriteria = () => {
        if (!sortCriteria || sortCriteria.length === 0) {
            const sortCriteria = new RequestSortCriteriaDTO();
            sortCriteria.field = "date";
            sortCriteria.asc = false;
            
            setSortCriteria([sortCriteria]);
        }
    }

    useEffect(() => {
        if (sortCriteria && sortCriteria.length > 0) getList();
    }, [sortCriteria,
        onPage,
        page,
    ]);

    useEffect(() => {
        const reqListRoute = RouteTools.prepareListRequest(currentRoute, cookies);
        if (reqListRoute.page) setPage(reqListRoute.page);

        if (reqListRoute.sortcriteria && reqListRoute.sortcriteria.length > 0) {
            setSortCriteria(reqListRoute.sortcriteria);
        } else {
            defaultSortCriteria();
        }

    }, []);

    useEffect(() => {
        checkLoading();
    }, [objects]);

    const processItem = (obj: CourseTeacherRequestDto, index: number) => {
        if (!obj) return <></>;

        return (
            <Box
                key={index}
            >
                <CourseTeacherInterfaceRequestItem obj={obj} />
            </Box>
        )
    };

    const processSortSelect = () => {


        const objects = ['date_asc', 'date_desc'];
        return (
            <Box sx={{maxWidth: 300,
                "& label": {
                    display: 'none',
                },
            }}>
                <MySortSelect
                    objects={objects}
                    setSortCriteria={setSortCriteria}
                    sortCriteria={sortCriteria}
                    variant="standard"
                    className="form-select"


                />
            </Box>
        )
    }

    const processPagination = () => {
        if (totalPage === -1) return <></>;
        if (total === -1) return <></>;
        if (objects.length === 0) return <></>;

        return (
            <Box
                className="container"
                py={4}
                display='flex'
                sx={{ justifyContent: 'center' }}
            >
                <MyPagination
                    page={page}
                    total={total}
                    totalPage={totalPage}
                    setPage={setPage} />
            </Box>);
    }

    const processList = () => {
        if (!objects.length) return <NoResult identifier="courseteacherrequest" />;

        return (

            <Box>
                {objects.map((item: CourseTeacherRequestDto, i: number) => {
                    return processItem(item, i)
                })}
            </Box>

        );
    };

    return (
        <Box className="container" py={4}>
            <Typography variant="h2" textAlign='center'>{LL("Cererile mele pentru curs")}</Typography>
            <Box my={3}>{processSortSelect()}</Box>
            {loading ? <Skeleton /> : processList()}
            {loading ? <Skeleton /> : processPagination()}
        </Box>

    );
}

export { CourseTeacherInterfaceRequestList };