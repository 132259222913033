import React, { useEffect, useState } from "react";
import PageComponentProps from "interfaces/pagecomponentprops.interface";
import { useResource } from "hook/useResource";
import { logger } from "hoc/logger";
import PageService from "services/page/page.service";
import { PageDto } from "dto/page/page.dto";
import { Loading } from "components/general/Loading";
import { HtmlTag } from "components/general/HtmlTag";


const pageService = new PageService();

const CopyRightBlock: React.FC<PageComponentProps> = ({
  currentRoute,
  ...props
}) => {
  const { saveCache, getCache } = useResource();
  
  const [isLoading, setIsLoading] = useState(true);
  const [obj, setObject] = useState<PageDto | null>(null);

  
  const loadObject = (_obj:any) => {
    logger("loadObjectloadObjectloadObjectloadObject", _obj.obj);

      setObject(_obj.obj);
    setIsLoading(false);
    saveCache(_obj.obj, "CopyRightBlock");
  }
  
  const getObject = async () => {
    const id = process.env.REACT_APP_IDPAGE_COPYRIGHT ?? "-1";

    const cd = getCache("CopyRightBlock");
    if (cd !== false) {
      setObject(cd);
      setIsLoading(false);
      return;
    }

    setIsLoading(true);

    pageService.get(id, loadObject);
  };

  useEffect(() => {
    getObject();
  }, []);
  
  // return <TopMenuLevel currentRoute={currentRoute} level={1} idparent={""} />; 
  return isLoading ? (
    <Loading />
  ) : (
    <HtmlTag content={obj?.content} />
  ); 
};

export { CopyRightBlock };
