import React from "react";
import { MyNavBt } from "./MyNavBt";
import { LogoComp } from "./LogoComp";
import PageComponentProps from "interfaces/pagecomponentprops.interface";

const BottomLogoBlock: React.FC<PageComponentProps> = ({ currentRoute, ...props }) => {
  return <MyNavBt href={"/"}><LogoComp /></MyNavBt>;
};

export { BottomLogoBlock };
