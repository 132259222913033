
import Idto from 'interfaces/idto.interface';
import { Status } from 'tools/status';

export class VideoDto implements Idto {
    id?: string;
    status?: number;
    ordercriteria?: number;
    idfile?: string;
    parent?: string;
    idparent?: string;
    isdefault?: boolean;
    idlanguage?: string;
    language?: any;
    status_name?: string;
    idvideo?: string;
    name?: string;
    videolocation?: string;
    videoserver?: string;
    videoid?: string;
    fileInfoObj?: any;
    constructor() {
      this.status = Status.ACTIVE;
      this.ordercriteria = 0;
      this.isdefault = false;
      this.name = '';
    }
}

export class PostVideoForCourseDto implements Idto {

  status?: number;
    ordercriteria?: number;
    idfile?: string;
    parent?: string;
    idparent?: string;
    isdefault?: boolean;
    idlanguage?: string;
    language?: any;
    status_name?: string;
    idvideo?: string;
    name?: string;
    videolocation?: string;
    videoserver?: string;
    videoid?: string;
    fileInfoObj?: any;

  constructor(){
  
      this.isdefault = true;
      this.status = Status.ACTIVE;
      this.ordercriteria = 0;
      this.name = '';
      this.parent = 'course';
  }
}

export class PostVideoDto implements Idto {

    id?: string;
  status?: number;
  ordercriteria?: number;
  idfile?: string;
  parent?: string;
  idparent?: string;
  isdefault?: boolean;
  idlanguage?: string;
  language?: any;
  status_name?: string;
  idvideo?: string;
  name?: string;
  videolocation?: string;
  videoserver?: string;
  videoid?: string;
  fileInfoObj?: any;
  constructor() {
    this.status = Status.ACTIVE;
    this.ordercriteria = 0;
    this.isdefault = false;
    this.name = '';
  }
}