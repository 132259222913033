import ResultObjectDTO from "dto/app/resultobject.dto";
import { LessonEnrollmentFinishedDto } from "dto/course/lessonenrollment.dto";
import React, { useState, useEffect, useContext } from "react";
import LessonEnrollmentService from "services/course/lessonenrollment.service";
import { useAuth } from "hook/useAuth";
import DoneIcon from "@mui/icons-material/Done";
import { StudentCourseContext } from "context/studentcourse/studentcourse.context";
type VerifyLessonProps = {
  idLesson: string;
};

const lessonEnrollmentService = new LessonEnrollmentService();
const VerifyLesson: React.FC<VerifyLessonProps> = ({ idLesson, ...props }) => {
  const { student } = useAuth();
  const [obj, setObj] = useState<LessonEnrollmentFinishedDto | undefined>();
  const [loading, setLoading] = useState<boolean>(true);
  const {
    state: { idCourseEnrollment, idCourse, reloadLessonEnrollment ,lessonEnrollmentObject},
  } = useContext(StudentCourseContext);
  const getObject = () => {
    if (!student) return;
    if (!idCourse) return;
    if (!idCourseEnrollment) return;
    if (!idLesson) return;
    setLoading(true);
    lessonEnrollmentService.verifyLessonFinished(
      idLesson,
      student.id,
      idCourse,
      idCourseEnrollment,
      loadObject,
      {}
    );
  };
  const loadObject = (obj: ResultObjectDTO) => {
    if (!obj) return;
    if (!obj.err) {
      const object: LessonEnrollmentFinishedDto = obj.obj ? obj.obj : {};
      setObj(object);
    }
  };
  useEffect(() => {
    getObject();
  }, [idLesson, idCourse, idCourseEnrollment, student, reloadLessonEnrollment,lessonEnrollmentObject]);

  const checkIsLoading = () => {
    let load: boolean = false;
    if (obj === undefined) load = true;
    setLoading(load);
  };

  useEffect(() => {
    checkIsLoading();
  }, [obj]);

  const processComponent = () => {
    if (!obj) return <></>;
    if (!obj.finished) return <></>;
    return (
      <>
        <DoneIcon color="success" />
      </>
    );
  };
  return processComponent();
};

export { VerifyLesson };
