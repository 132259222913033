
import Idto from 'interfaces/idto.interface';

export class PostBlogDto implements Idto {

    status?: number;
    idlanguage?: string;
    idblog?: string;
    type?: number;
    idgallery?: string;
    idattachment?: string;
    idvideo?: string;
    name?: string;
    url?: string;
    title?: string;
    keymeta?: string;
    descriptionmeta?: string;
    content?: string;    
    idblogcategory?:string;
}

export class BlogDto implements Idto {
    
    id?: string;
    status?: number;
    idlanguage?: string;
    idblog?: string;
    type?: number;
    idgallery?: string;
    idattachment?: string;
    idvideo?: string;
    language?: any;
    name?: string;
    url?: string;
    title?: string;
    keymeta?: string;
    descriptionmeta?: string;
    content?: string;    
    idblogcategory?:string;
    blogcategory?:any;
    status_name?: string;
    type_name?: string;
    gallery?: any;
    attachment?: any;
    video?: any;
    fullurl?: string;

}