import React, { useEffect, useState } from "react";
import { useResource } from "hook/useResource";
import { logger } from "hoc/logger";



import CastForEducationIcon from '@mui/icons-material/CastForEducation';
import { MyNavBt } from "components/general/MyNavBt";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { MyButton } from "components/general/MyButton";
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import { ComponentCommonTools } from 'tools/componentcommontools'
import Typography from "@mui/material/Typography";
import Rating from '@mui/material/Rating';
import Avatar from "@mui/material/Avatar";
import {
    CardHeader,
    Collapse,
    Link,
    Stack,
    ListItem,
    ListItemButton,
    ListItemText,
    ListItemAvatar,
    IconButton 
} from "@mui/material";
import { MyRatingReadOnly } from "components/elements/MyRating/MyRatingReadOnly";
import { MyAvatar } from "components/elements/MyAvatar/MyAvatar";

import { CourseEnrollmentDto } from "dto/course/courseenrollment.dto";
import { CommonTools } from "tools/commontools";

import CommentIcon from '@mui/icons-material/Comment';


type ClassroomStudentItemProps = {
    obj: CourseEnrollmentDto;
    index: number;

}

const ClassroomStudentItem: React.FC<ClassroomStudentItemProps> = ({
    obj,
    index,
    ...props
}) => {
    const { LL } = useResource();

    const processUser = () => {
        if (!obj) return <></>;
        if (!obj.hasOwnProperty('student')) return <></>;
        if (!obj.student) return <></>;
        if (!obj.student.hasOwnProperty('user')) return <></>;
        if (!obj.student.user) return <></>;
        let fullUrl = '';
        if (obj.student.user.hasOwnProperty('fullurl') && obj.student.user.fullurl) fullUrl = obj.student.user.fullurl;

        if (!fullUrl) {
            return (
                <ListItemButton>
                    <ListItemAvatar>{processAvatar()}</ListItemAvatar>
                    <ListItemText className="name" primary={processUserFullName()} />
                </ListItemButton>
            )
        }
        return (
            <ListItemButton
                component={Link}
                href={fullUrl}
                target="_blank"
                rel="noopener noreferrer">
                <ListItemAvatar>{processAvatar()}</ListItemAvatar>
                <ListItemText className="name" primary={processUserFullName()} />
            </ListItemButton>
        )
    }

    const processUserFullName = () => {
        if (!obj) return '';
        if (!obj.hasOwnProperty('student')) return '';
        if (!obj.student) return '';
        if (!obj.student.hasOwnProperty('fullname')) return '';
        if (!obj.student.fullname) return '';


        return obj.student.fullname;
    }
    const processAvatar = () => {
        if (!obj) return <></>;
        if (!obj.hasOwnProperty('student')) return <></>;
        if (!obj.student) return <></>;
        let name = ''
        let surname = ''
        if (obj.student.hasOwnProperty('name') && obj.student.name) name = obj.student.name
        if (obj.student.hasOwnProperty('surname') && obj.student.surname) surname = obj.student.surname

        return <MyAvatar obj={obj.student} name={name} surname={surname} sx={{ mr: 1 }} />
    }




    const processItem = () => {
        if (!obj) return <></>;

        return (
            <ListItem
                key={index}
                secondaryAction={
                    <IconButton edge="end" aria-label="comments">
                      <CommentIcon />
                    </IconButton>
                  }
                disablePadding
            >
                {processUser()}
            </ListItem>
        )
    };


    return processItem();
};

export { ClassroomStudentItem };
