import React, { useState, useEffect, useRef } from "react";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import { Loading } from "components/general/Loading";
import Box from "@mui/material/Box";
import CryptoJS from "crypto-js";
import { JaaSMeeting } from "@jitsi/react-sdk";
import { logger } from "hoc/logger";
 

const LocalJitsiMeeting = (p: any) => {
  const [loading, setLoading] = useState(false);
  
  const [microphonePermissionGranted, setMicrophonePermissionGranted] = useState(false);
  const [microphonePermissionRequested, setMicrophonePermissionRequested] = useState(false);

  // useEffect(() => {
  //   if (!microphonePermissionRequested) {
  //     // Request microphone permission
  //     navigator.mediaDevices
  //       .getUserMedia({ audio: true, video: true })
  //       .then((stream) => {
  //         // Attach the stream to the video element
  //         if (videoRef.current) {
  //           videoRef.current.srcObject = stream;
  //         }
  //         setMicrophonePermissionGranted(true);
  //       })
  //       .catch((error) => {
  //         console.error("Error accessing media devices:", error);
  //         setMicrophonePermissionGranted(false);
  //       });
  //     setMicrophonePermissionRequested(true);
  //   }
  // }, [microphonePermissionRequested]);

  console.log(
    "navigatornavigator",
    navigator
  );




  return loading ? (
    <Loading />
  ) : (
    <Container>
      <Button>Join Meeting_</Button>

      <Container
        sx={{ width: 800, height: 800, minHeight: 800, backgroundColor:'red' }}
      >
        <JaaSMeeting
          appId="vpaas-magic-cookie-e4ae84300ed848d89a89202b3855b664"
          roomName="PleaseUseAGoodRoomName"
          jwt="eyJraWQiOiJ2cGFhcy1tYWdpYy1jb29raWUtZTRhZTg0MzAwZWQ4NDhkODlhODkyMDJiMzg1NWI2NjQvMTdiMmZkLVNBTVBMRV9BUFAiLCJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiJqaXRzaSIsImlzcyI6ImNoYXQiLCJpYXQiOjE3MTA1MDQwNzAsImV4cCI6MTcxMDUxMTI3MCwibmJmIjoxNzEwNTA0MDY1LCJzdWIiOiJ2cGFhcy1tYWdpYy1jb29raWUtZTRhZTg0MzAwZWQ4NDhkODlhODkyMDJiMzg1NWI2NjQiLCJjb250ZXh0Ijp7ImZlYXR1cmVzIjp7ImxpdmVzdHJlYW1pbmciOnRydWUsIm91dGJvdW5kLWNhbGwiOnRydWUsInNpcC1vdXRib3VuZC1jYWxsIjpmYWxzZSwidHJhbnNjcmlwdGlvbiI6dHJ1ZSwicmVjb3JkaW5nIjp0cnVlfSwidXNlciI6eyJoaWRkZW4tZnJvbS1yZWNvcmRlciI6ZmFsc2UsIm1vZGVyYXRvciI6dHJ1ZSwibmFtZSI6ImxpdmVjbGFzc2FwcCIsImlkIjoiZ29vZ2xlLW9hdXRoMnwxMTE4MTM3MDk1MDAyNzMzOTczNDAiLCJhdmF0YXIiOiIiLCJlbWFpbCI6ImxpdmVjbGFzc2FwcEBnbWFpbC5jb20ifX0sInJvb20iOiIqIn0.G3ivKRAtDr9GsRMN7gvvH5QNU6gRDNcrfmNx5TM20UVKd11wTNtkm5DTrarHJnzgMnLHPXzkLd7An0kCvvt3cerWaqZ9KTe8lcevelvaClSHiW5LFOnOWu8Vm99Th6Zks1ykLmE5nugfhusHMbFJI6bpJR0SZbfbWF7kldgjtWeUwJ2qhM4lzsQFDprBjGDqq8zP6cqQHD_fEw47zU9pgUqfWG3SCJzKaOrKp9tq7QPatD6jVbKkVC66IIPH1oZjSrrRNFGFl8nQKgrN3qxgqmacYi7r6tbOskJ40AdvldcMhjYJFAnhe7p5MTuR-EMucxzO9aiXVrS891bYl2RMHw"
          configOverwrite={{
            startWithAudioMuted: true,
            disableModeratorIndicator: true,
            startScreenSharing: true,
            enableEmailInStats: false,

            disableThirdPartyRequests: true,
            disableLocalVideoFlip: true,
            backgroundAlpha: 0.5,
          }}
          interfaceConfigOverwrite={{
            VIDEO_LAYOUT_FIT: "nocrop",
            MOBILE_APP_PROMO: false,
            TILE_VIEW_MAX_COLUMNS: 4,
          }}
          // spinner = { SpinnerView }
          onApiReady={(externalApi) => {
            logger("xxxx");
          }}
        />
      </Container>
    </Container>
  );
};

export { LocalJitsiMeeting };
