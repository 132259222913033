import { Box, Skeleton, Stack, Typography } from "@mui/material";
import { HtmlTag } from "components/general/HtmlTag";
import ResultObjectDTO from "dto/app/resultobject.dto";
import { CourseTeacherRequestDto, CourseTeacherRequestProcessedDto } from "dto/course/courseteacherrequest.dto";
import { useResource } from "hook/useResource";
import React, { useState, useEffect } from "react";
import { ComponentCommonTools } from "tools/componentcommontools";
import CourseTeacherRequestService from "services/course/courseteacherrequest.service";
import { useAuth } from "hook/useAuth";
import { CommonTools } from "tools/commontools";
import { RouteTools } from "tools/routetools";
import { MyButton } from "components/general/MyButton";
import { Status } from 'tools/status';
import DoneIcon from '@mui/icons-material/Done';
import { Types } from "tools/types";
import { logger } from "hoc/logger";
import { CourseDto } from "dto/course/course.dto";


type CourseUnpublishButtonProps = {
    [x: string]: any;
    objectWasChanged: string;
    idCourse: string;
    course: CourseDto;
}

const courseTeacherRequestService = new CourseTeacherRequestService();
const CourseUnpublishButton: React.FC<CourseUnpublishButtonProps> = ({
    objectWasChanged,
    idCourse,
    course,
    ...props }) => {

    const { LL, CC } = useResource();
    const { teacher } = useAuth();
    const [obj, setObj] = useState<CourseTeacherRequestProcessedDto | undefined>();
    const [loading, setLoading] = useState<boolean>(true);
    const [disabled, setDisabled] = useState<boolean>(true);

    const getObject = () => {
        if (!teacher) return;
        if (!teacher.hasOwnProperty("id")) return;
        if (!teacher.id) return;
        if(!course) return;
        if(!course.hasOwnProperty('status')) return;
        if(!course.status) return;
        if(course.status !== Status.ACTIVE) return;

        setLoading(true);
        courseTeacherRequestService.verifyProcessed(
            teacher.id,
            Types.TYPE_REQUEST_TEACHER_COURSE_UNPUBLISH,
            idCourse,
            loadObject,
            {}
        );
    }
    const loadObject = (obj: ResultObjectDTO) => {
        if (!obj) return;
        if (!obj.err) {
            const object: CourseTeacherRequestProcessedDto = obj.obj ? obj.obj : {};
            setObj(object);
            if (object.processed) setDisabled(false);
            else setDisabled(true);
        }
    }
    useEffect(() => {
        getObject();
    }, [teacher, objectWasChanged, idCourse]);

    const checkIsLoading = () => {
        let load: boolean = false;
        if (obj === undefined) load = true;
        setLoading(load);
    };

    useEffect(() => {
        checkIsLoading();
    }, [obj]);



    const processComponent = () => {
        if (!teacher) return <></>;
        if (!teacher.hasOwnProperty("id")) return <></>;
        if (!teacher.id) return <></>;
        
        return (
            <MyButton
                {...props}
                disabled={disabled}
            >
                {LL("unpublish_course_btn")}
            </MyButton>
        )
    }
    return processComponent()
};

export { CourseUnpublishButton };
