import Idto from 'interfaces/idto.interface';

export class BecomeTeacherDto implements Idto {
    
    iduser?: string;
    idtypespecialty?: string;
    idtypetargetgroup?: string;
    idtypeinternetconnection?: string;
    idtypehardware?: string;
    idtypelesson?: string;
    idtypediscipline?: string;
    idtypeoccupation?: string;
    idtypestudies?: string;
    idtypeprofessionalismlevel?: string;
    idtypeexperience?: string;
    bio?: string;
    timetoteachperweek?: string;
    hasaudience?: boolean;
    graduatedinstitution?: string;
    whyliveclass?: string;
    notificationtype1?: boolean;
    notificationtype2?: boolean;
    notificationtype3?: boolean;
}
