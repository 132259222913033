import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useResource } from "hook/useResource";
import { logger } from "hoc/logger";
import AdvertisementService from "services/advertisement/advertisement.service";
import PageComponentProps from "interfaces/pagecomponentprops.interface";
import { AdvertisementDto } from "dto/advertisement/advertisement.dto";
import { RouteTools } from "tools/routetools";
import RequestListDTO from "dto/app/requestlist.dto";
import { Status } from "tools/status";
import RequestFilterDTO from "dto/app/requestfilter.dto";
import ResultListDTO from "dto/app/resultlist.dto";
import { Types } from "tools/types";
import { ComponentCommonTools } from 'tools/componentcommontools'
import { Loading } from "components/general/Loading";
import Skeleton from '@mui/material/Skeleton';
import { Box } from "@mui/material";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { MyNavBt } from "components/general/MyNavBt";

import { AdvertisementBannerItem } from "./AdvertisementBannerItem";
const advertisementService = new AdvertisementService();

const AdvertisementBannerBottom: React.FC<PageComponentProps> = ({
    currentRoute,
    ...props
}) => {
    const { saveCache, getCache } = useResource();
    const { LL } = useResource();
    const [isLoading, setIsLoading] = useState(true);
    const [objects, setObjects] = useState<Array<AdvertisementDto>>([]);


    const getList = async () => {
        const reqList = new RequestListDTO();
        reqList.onpage = 100;
        reqList.page = 1;
        reqList.filters = [];

        let f: RequestFilterDTO;

        f = new RequestFilterDTO();
        f.field = "status";
        f.values = [];
        f.values.push(Status.ACTIVE.toString());
        reqList.filters.push(f);

        f = new RequestFilterDTO();
        f.field = "type";
        f.values = [];
        f.values.push(
            Types.ADVERTISEMENT_TYPE_BANNER.toString(),
            Types.ADVERTISEMENT_TYPE_BANNER_IMAGE_LEFT.toString(),
            Types.ADVERTISEMENT_TYPE_BANNER_IMAGE_RIGHT.toString()
        );
        reqList.filters.push(f);

        f = new RequestFilterDTO();
        f.field = "section";
        f.values = [];
        f.values.push(Types.SECTION_BOTTOM_ADVERTISEMENT.toString());
        reqList.filters.push(f);

        const cd = getCache(reqList);

        if (cd !== false) {
            setObjects(cd);
            setIsLoading(false);
            return;
        }

        setIsLoading(true);

        const data = await advertisementService.getList(undefined, {}, reqList);

        const rez = !data || !data.objects ? [] : data.objects;

        setObjects(rez);
        setIsLoading(false);


        saveCache(rez, reqList);
    };

    useEffect(() => {
        getList();
    }, []);


    const processAdvertisementItem = (obj: AdvertisementDto, index: number) => {
        if (!obj) return <></>;
        return <Box key={index}><AdvertisementBannerItem obj={obj} /> </Box>
    };


    const processAdvertisementList = () => {
        if (!objects.length) return <></>;

        return (
            <>
                {objects.map((item: AdvertisementDto, i: number) => {
                    return processAdvertisementItem(item, i);
                })}

            </>
        );
    };

    return isLoading ? <Skeleton /> : processAdvertisementList();
};

export { AdvertisementBannerBottom };
