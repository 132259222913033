import React, { useState, useEffect } from "react";
import {
  IconButton,
  Box,
  Menu,
  Badge,
  Typography,
  Divider,
  Stack,
  ListItem,
} from "@mui/material";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import { NotificationListTopMenu } from "./NotificationListTopMenu";
import { useResource } from "hook/useResource";
import { MyNavBt } from "components/general/MyNavBt";
import { Types } from "tools/types";
import { MyButton } from "components/general/MyButton";
import SettingsRoundedIcon from "@mui/icons-material/SettingsRounded";
import { RouteTools } from "tools/routetools";
import NotificationService from "services/user/notification.service";
import { useAuth } from "hook/useAuth";
import { CommonTools } from "tools/commontools";
type NotificationTopMenuProps = {
  currentRoute: any;
  typeRecipient: number;
};

const notificationService = new NotificationService();

const NotificationTopMenu: React.FC<NotificationTopMenuProps> = ({
  currentRoute,
  typeRecipient,
  ...props
}) => {
  const { LL } = useResource();
  const { student, teacher } = useAuth();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [objectWasChanged, setObjectWasChanged] = useState<string>("");
  const [isDisableMarkAllRead, setIsDisableMarkAllRead] =
    useState<boolean>(true);

  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleGoToNotificationSettings = () => {
    handleClose();
    if (typeRecipient === Types.TYPE_NOTIFICATION_RECIPIENT_STUDENT) {
      RouteTools.setHistory("/profile/edit", {},'notification');
    } else if (typeRecipient === Types.TYPE_NOTIFICATION_RECIPIENT_TEACHER) {
      RouteTools.setHistory("/teacherinterface/editprofile", {},'teacherNotification');
    }
  };

  const processNotificationList = () => {
    if (!open) return <></>;
    return (
      <NotificationListTopMenu
        currentRoute={currentRoute}
        open={open}
        typeRecipient={typeRecipient}
        objectWasChanged={objectWasChanged}
        setObjectWasChanged={setObjectWasChanged}
        setIsDisableMarkAllRead={setIsDisableMarkAllRead}
      />
    );
  };

  const processNavBt = () => {
    let url = "";
    if (typeRecipient == Types.TYPE_NOTIFICATION_RECIPIENT_STUDENT)
      url = "/profile/notification";
    else if (typeRecipient == Types.TYPE_NOTIFICATION_RECIPIENT_TEACHER)
      url = "/teacherinterface/notification";

    return (
      <MyNavBt href={url} additionalOnClick={handleClose}  className="link">
        {LL("Show_Notifications")}
      </MyNavBt>
    );
  };

  const handleMarkAllAsRead = () => {
    let idRecipient = "";
    if (typeRecipient === Types.TYPE_NOTIFICATION_RECIPIENT_STUDENT) {
      if (!student) return;
      idRecipient = student.id;
    } else if (typeRecipient === Types.TYPE_NOTIFICATION_RECIPIENT_TEACHER) {
      if (!teacher) return;
      idRecipient = teacher.id;
    }
    if (!idRecipient) return;
    setIsDisableMarkAllRead(true);
    notificationService.markAllAsRead(
      idRecipient,
      typeRecipient,
      handleResult,
      {}
    );
  };
  const handleResult = (result: any) => {
    if (!result) return;
    if (!result.err) {
      setObjectWasChanged(CommonTools.generateRandomString(5));
    }
  };
  const processButtonMarkAllAsRead = () => {
    return (
      <MyButton cb={handleMarkAllAsRead} disabled={isDisableMarkAllRead} className="link">
        {LL("Mark_All_As_Read")}
      </MyButton>
    );
  };

  return (
    <Box>
      <IconButton
        id="basic-button-notification"
        aria-controls={open ? "basic-menu-notification" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <Badge color="secondary" variant="dot">
          <NotificationsNoneIcon />
        </Badge>
      </IconButton>
      <Menu
        id="basic-menu-notification"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        variant="menu"
        MenuListProps={{
          "aria-labelledby": "basic-button-notification",
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        className="notification-tmp"
      >
        <ListItem>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
            sx={{ width: "100%" }}
          >

              <Typography variant="h6">{LL("Notifications")}</Typography>

              <IconButton onClick={handleGoToNotificationSettings}>
                <SettingsRoundedIcon />
              </IconButton>

          </Stack>
        </ListItem>
        <Divider />
        {processNotificationList()}
        <Divider />
        <ListItem>
          <Stack
            direction="row"
            justifyContent="end"
            alignItems="center"
            spacing={2}
            sx={{ width: "100%" }}
          >
            {processButtonMarkAllAsRead()}
            {processNavBt()}
          </Stack>
        </ListItem>
      </Menu>
    </Box>
  );
};

export { NotificationTopMenu };
