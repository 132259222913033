
import Idto from 'interfaces/idto.interface';

export class PostFAQDto implements Idto {

    status?: number;
    ordercriteria?: number;
    hasurl?: boolean;
    idlanguage?: string;
    idfaq?: string;
    question?: string;
    answer?: string;    
    type?: number;
    idobject?: string;
    idgallery?: string;
    idattachment?: string;
    idvideo?: string;

}

export class FAQDto implements Idto {
    
    id?: string;
    status?: number;
    ordercriteria?: number;
    hasurl?: boolean;
    idlanguage?: string;
    idfaq?: string;
    question?: string;
    answer?: string;    
    type?: number;
    idobject?: string;
    idgallery?: string;
    idattachment?: string;
    idvideo?: string;
    language?: any;
    status_name?: string;
    type_name?: string;
}