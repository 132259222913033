import { CircularLoading } from "components/general/CircularLoading";
import RequestFilterDTO from "dto/app/requestfilter.dto";
import RequestListDTO from "dto/app/requestlist.dto";
import ResultListDTO from "dto/app/resultlist.dto";
import { AnswerDto } from "dto/course/answer.dto";
import { QuestionDto } from "dto/course/question.dto";
import { useResource } from "hook/useResource";
import React, { useState, useEffect } from "react";
import AnswerService from "services/course/answer.service";
import { Box, DialogContent, Stack, Typography } from "@mui/material";
import { ComponentCommonTools } from "tools/componentcommontools";
import { AnswerSingleQuestionOption } from "./AnswerSingleQuestionOption";
import { Types } from "tools/types";
import { AnswerMultipleQuestionOption } from "./AnswerMultipleQuestionOption";
import { PostTestResultAnswerDto } from "dto/course/testresultanswer.dto";
import { PostTestResultQuestionDto } from "dto/course/testresultquestion.dto";
import { TestResultDto } from "dto/course/testresult.dto";
import { logger } from "hoc/logger";
import { Status } from "tools/status";
import ResultObjectDTO from "dto/app/resultobject.dto";
import TestResultService from "services/course/testresult.service";

type TestResultProps = {

    testResult?: TestResultDto;
}

const testResultService = new TestResultService();

const TestResult: React.FC<TestResultProps> = ({

    testResult,
    ...props }) => {

    const { LL, CC } = useResource();
    const [isLoading, setIsLoading] = useState(true);
    const [obj, setObj] = useState<TestResultDto | undefined>();


    const loadObject = (obj: ResultObjectDTO) => {
        if (!obj) return;
        if (!obj.err) {
            const object = obj.obj ? obj.obj : {};
            setObj(object);
        }
    };

    const getObject = () => {
        if (!testResult) return;
        if (!testResult.id) return;
        setIsLoading(true);
        testResultService.get(testResult.id, loadObject, {});
    };


    useEffect(() => {
        getObject();
    }, [testResult]);

    const checkIsLoading = () => {
        let load: boolean = false;
        if (obj === undefined) load = true;
        setIsLoading(load);
      };

      useEffect(() => {
        checkIsLoading();
      }, [obj]);
      
    const processTestResultSuccess = () => {
        if (!obj) return <></>;
        return (
            <>
                <Typography variant="h4">
                    {LL("Congratulations_you_have_passed-the_test!")}
                </Typography>
            </>
        )

    }

    const processTestResultFail = () => {
        if (!obj) return <></>;
        return (
            <>
                <Typography variant="h4">
                    {LL("Sorry_you_have_failed-the_test!")}
                </Typography>
            </>
        )

    }

    const processItem = () => {
        if (!obj) return <></>;
        if (!obj.hasOwnProperty('status')) return <></>;
        if (!obj.status) return <></>;
        if (obj.status === Status.TEST_SUCCESS) return processTestResultSuccess();
        if (obj.status === Status.TEST_ERROR) return processTestResultFail();
        return <></>;
    }

    const processStatusColor = (obj:TestResultDto) =>{
        if(!obj) return 'black';
        if (!obj.hasOwnProperty('status')) return 'black';
        if (!obj.status) return 'black';
        if (obj.status === Status.TEST_SUCCESS) return 'green'
        if (obj.status === Status.TEST_ERROR) return 'red'
        return 'black'
    }

    const processComponent = () => {
        if(isLoading) return <CircularLoading />;
        if (!obj) return <></>;

        return (
            < >
                {processItem()}

                    <Stack direction="row" spacing={2} mt={3} className="testResult" alignItems="center">
                        <Typography>
                            {LL("You_got")}
                        </Typography>
                        <Typography color={processStatusColor(obj)} className="nb">
                            {ComponentCommonTools.processObjectField(testResult, "percentage")}
                        </Typography>
                        <Typography>
                            {LL("of_correct_answers")}
                        </Typography>
                    </Stack>

            </>
        )
    }





    return processComponent();
};

export { TestResult };
