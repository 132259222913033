import React, { useState, useEffect } from "react";
import { useResource } from "hook/useResource";
import { CourseFilterDto } from "dto/course/coursefilter.dto";
import RequestFilterDTO from "dto/app/requestfilter.dto";
import RequestListDTO from "dto/app/requestlist.dto";
import { Status } from "tools/status";
import CourseFilterService from "services/course/coursefilter.service";
import ResultListDTO from "dto/app/resultlist.dto";
import { Box, List, ListItem, ListItemText, Skeleton, Typography, Stack } from "@mui/material";
import { logger } from "hoc/logger";

import { ComponentCommonTools } from "tools/componentcommontools";

type CourseFilterListProps = {
    idCourse: string;
}

const courseFilterService = new CourseFilterService();

const CourseFilterList: React.FC<CourseFilterListProps> = ({
    idCourse,
    ...props }) => {

    const { LL } = useResource();
    const [isLoading, setIsLoading] = useState(true);
    const [objects, setObjects] = useState<Array<CourseFilterDto>>([]);


    const getList = () => {

        setIsLoading(true);
        const reqList = new RequestListDTO();
        reqList.onpage = 999999;
        reqList.page = 1;
        reqList.filters = [];


        let f: RequestFilterDTO;

        f = new RequestFilterDTO();
        f.field = "idcourse";
        f.values = [];
        f.values.push(idCourse);
        reqList.filters.push(f);


        courseFilterService.getList(loadObjects, {}, reqList);

        // setIsLoading(false);
    };


    const loadObjects = (result: ResultListDTO) => {
        if (!result) return;
        if (!result.err) {
            const objects = result.objects ? result.objects : [];
            // const total = result.total !== undefined ? result.total : -1;
            // const totalPage = result.totalpages !== undefined ? result.totalpages : -1;
            // const reqInfo = result.requestinfo ? result.requestinfo : {};
            setObjects(objects);
            // setTotal(total);
            // setTotalPage(totalPage);
            // setResponseParams(reqInfo);
        }
    }

    const checkLoading = () => {
        let load = false;

        // if (total === -1) return true;
        // if (totalPage === -1) return true;
        if (!objects) load = true;
        // if (objects.length === 0) load = true;
        // if (Object.keys(responseParams).length === 0) return true;

        setIsLoading(load);
    }

    useEffect(() => {
        getList();
    }, [idCourse]);

    useEffect(() => {
        checkLoading();
    }, [objects]);

    const processCourseFilterValue = (obj: CourseFilterDto) => {
        if (!obj) return <></>;
        if (!obj.hasOwnProperty('filterdictionary')) {
            return (<Typography>
                {ComponentCommonTools.processObjectField(obj, 'value')}
            </Typography>)
        }
        return (<Typography>
            {ComponentCommonTools.processObjectFieldOfObject(obj, 'filterdictionary', 'name')}
        </Typography>)
    }


    const processCourseFilterItem = (obj: CourseFilterDto, index: number) => {

        if (!obj) return <></>;
        if (!obj.id) return <></>;

        return (<Box key={index}>
            <Stack direction='row' spacing={1} justifyContent='space-between'>
                <Typography>
                    {ComponentCommonTools.processObjectFieldOfObject(obj, 'filter', 'name')}
                </Typography>
                
                <Typography className="nameFilter">{processCourseFilterValue(obj)}</Typography>
            </Stack>
        </Box>);

    };

    const processCourseFilterList = () => {
        if (!objects.length) return <></>
        return (
            <List>
                {objects.map((item: CourseFilterDto, i: number) => {
                    return processCourseFilterItem(item, i);
                })}
            </List>
        );
    };


    return isLoading ? <Skeleton /> : (
        <Box>

                {processCourseFilterList()}

        </Box>
    );
};

export { CourseFilterList };
