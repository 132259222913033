import { createContext, useState, useEffect } from "react";
import { logger } from "hoc/logger";
import { v4 as uuidv4 } from "uuid";
import GeneralRepository from "repositories/general.repository";
import { MessageItem } from "components/message/MessageItem";
import { MessagesContainerPopUpConfirmDelete } from "components/message/MessagesContainerPopUpConfirmDelete";
import { ConfirmDialog } from "components/elements/MyDialog/ConfirmDialog";
import Box from "@mui/material/Box";
export const MessageContext = createContext();

export const MessageProvider = ({ children }) => {

  const [messages, setMessages] = useState([]);
  const [mesinfo, setMesInfo] = useState(false);

  const RegisterMessage = (obj) => {
    
    if (!obj.code || obj.code == undefined || obj.code == null) return;
    obj.id = uuidv4();
    
    const _m = [...messages, obj];
    setMessages(_m);
    const mi = obj.message === undefined ? false : obj.message;
    setMesInfo(mi);
  };

  const CloseMessage = (obj) => {
    var t = [];
    for (var i in messages) {
      if (messages[i].id == obj.id) continue;
      t.push(messages[i]);
    }
    setMessages(t);
  };

  // ----------------------------------------
  useEffect(() => {
    GeneralRepository.setMessagesF(RegisterMessage);
  }, []);

  const [openDialog, setOpenDialog] = useState(false);
  const [titleDialog, setTitleDialog] = useState("");
  const [contentDialog, setContentDialog] = useState("");
  const [actionConfirmDialog, setActionConfirmDialog] = useState(null);
  const [actionCancelDialog, setActionCancelDialog] = useState(null);

  const registerDialog = (title, content, actionConfirm, actionCancel) => {
    if (!title) return;
    if (!content) return;
    if (!actionConfirm) return;
    if (actionCancel) setActionCancelDialog(actionCancel);
    setTitleDialog(title);
    setContentDialog(content);
    setActionConfirmDialog(actionConfirm);
    setOpenDialog(true);
  }

  const handleConfirmActionDialog = () => {
    
    if (!actionConfirmDialog || !actionConfirmDialog.cb) return;
    actionConfirmDialog.cb();
    setOpenDialog(false);
    setTitleDialog("");
    setContentDialog("");
    setActionConfirmDialog(null);
    setActionCancelDialog(null);
  }
  const handleCancelActionDialog = () => {
    if (actionCancelDialog && actionCancelDialog.cb) actionCancelDialog.cb();
    setOpenDialog(false);
    setTitleDialog("");
    setContentDialog("");
    setActionConfirmDialog(null);
    setActionCancelDialog(null);
  }

  const processDialog = () => {
    if (!openDialog || !actionConfirmDialog) return <></>;
    return (
      <ConfirmDialog
        open={openDialog}
        setOpen={setOpenDialog}
        titleDialog={titleDialog}
        contentDialog={contentDialog}
        setTitleDialog={setTitleDialog}
        setContentDialog={setContentDialog}
        handleConfirm={handleConfirmActionDialog}
        handleCancel={handleCancelActionDialog}
      />)
  }
  // ----------------------------------------
  const processMessageList = () => {
    
    if (!messages) return <></>;
    if (!messages.length) return <></>;

    if (mesinfo && mesinfo.type == "popupconfirmdelete") {

      const localhash = uuidv4();
      return (
        <MessagesContainerPopUpConfirmDelete
          key={localhash}
          _objects={messages}
          cb={setMessages}
          _info={mesinfo}
        />
      );
    }

    return (
      <Box sx={{position:"fixed", bottom:'10px', right:'10px'}}>
        {messages.map((obj, i) => {

          return (
            <MessageItem _obj={obj} _closeMessage={CloseMessage} key={obj.id} />
          );
        })}
        
      </Box>
    );
  };

  const value = { RegisterMessage,registerDialog };

  return (
    <MessageContext.Provider value={value} messages={messages}>
      {children}

      {processMessageList()}
      {processDialog()}
    </MessageContext.Provider>
  );
};
