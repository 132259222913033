import { React, useEffect, useState } from "react";

import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useReactPath } from "hook/useReactPath";

import { RequireAuth } from "hoc/RequireAuth";

import { useAuth } from "hook/useAuth";

import { Loading } from "components/general/Loading";
import { MainLayout } from "components/general/MainLayout";
import { Layout } from "components/general/Layout";

import { Login } from "components/auth/Login";
import { SignUp } from "components/auth/SignUp";

const AppRoutes = () => {
  const [isLoading, setIsLoading] = useState(true);
  // ----------------------------------------
  useEffect(() => {
    setIsLoading(false);
  }, []);

  return isLoading ? (
    <Loading />
  ) : (
    <BrowserRouter>
      <Routes>
        <Route path="/">
          <Route index element={<MainLayout />} />
          <Route path="*" element={<MainLayout />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export { AppRoutes };
