

import React, { useEffect, useState, useRef } from "react";

import { useResource } from "hook/useResource";
import { logger } from "hoc/logger";


import {
    IconButton,
    List,
    ListItem,
    ListItemText,
    Skeleton,
    Stack,
    Typography,
    ListItemIcon
} from "@mui/material";


import { GalleryDto } from "dto/gallery/gallery.dto";
import ResultListDTO from "dto/app/resultlist.dto";
import TbFilterListDTO from "dto/app/tbfilterlist.dto";
import RequestListDTO from "dto/app/requestlist.dto";
import { ComponentCommonTools } from "tools/componentcommontools";
import GalleryService from "services/gallery/gallery.service";
import RequestFilterDTO from "dto/app/requestfilter.dto";
import RequestSortCriteriaDTO from "dto/app/requestsortcriteria.dto";
import { Status } from "tools/status";
import FilePresentIcon from '@mui/icons-material/FilePresent';
import CollectionsIcon from '@mui/icons-material/Collections';
const galleryService = new GalleryService();
type GalleryListProps = {
    idparent: string;
    parent: string
}
const GalleryList: React.FC<GalleryListProps> = ({
    idparent,
    parent,
    ...props
}) => {
    const { LL, CC } = useResource();
    const [loading, setLoading] = useState<boolean>(false);
    const [objects, setObjects] = useState<Array<GalleryDto>>([]);

    const getList = () => {
        if (!idparent) return;
        const reqList = new RequestListDTO();
        reqList.onpage = parseInt(CC('DefaultOnPageGalleryList', 1000));
        reqList.page = 1;
        reqList.filters = [];
        reqList.sortcriteria = [];

        const sortCriteria = new RequestSortCriteriaDTO();
        sortCriteria.field = "ordercriteria";
        sortCriteria.asc = true;
        reqList.sortcriteria.push(sortCriteria);

        let f: RequestFilterDTO;

        f = new RequestFilterDTO();
        f.field = "status";
        f.values = [];
        f.values.push(Status.ACTIVE.toString());
        reqList.filters.push(f);


        f = new RequestFilterDTO();
        f.field = "idparent";
        f.values = [idparent];
        reqList.filters.push(f);
        setLoading(true);

        galleryService.getList(loadObjects, {}, reqList);
    };

    const loadObjects = (result: ResultListDTO) => {
        if (!result) return;
        if (!result.err) {
            const objects = result.objects ? result.objects : [];
            setObjects(objects);
        }
    }

    const checkLoading = () => {
        let load = false;
        if (!objects) load = true;
        setLoading(load);
    }

    useEffect(() => {
        checkLoading();
    }, [objects]);

    useEffect(() => {
        getList();
    }, [idparent]);


    const processItem = (item: GalleryDto, i: number) => {
        if (!item) return (<></>);
        if (!item.hasOwnProperty('id')) return (<></>);
        if (!item.id) return (<></>);
        let name = ''
        if (item.hasOwnProperty('name') && item.name) name = item.name;
        else {
            const index = i + 1;
            name = LL('gallery') + ' ' + index.toString();
        }
        return (
            <ListItem
                key={i}
                className="list-item-info"
            >
                 <ListItemIcon>
              <CollectionsIcon />
            </ListItemIcon>
                <ListItemText primary={name} />
            </ListItem>
        );
    }
    const processLessonList = () => {
        if (loading) return <Skeleton variant="rectangular" height={50} />;
        if (!objects.length) return <></>;

        return (
            <List>
                {objects.map((item: GalleryDto, i: number) => {
                    return processItem(item, i);
                })}
            </List >
        );
    };
    return (processLessonList())
};

export { GalleryList };