import React, { useState, useEffect } from 'react';

import LessonService from "services/course/lesson.service";
import { logger } from 'hoc/logger';
import useMyFormValidation from 'hook/useMyFormValidation';
import { LessonDto } from 'dto/course/lesson.dto';
import { useCookies } from 'react-cookie';
import { Loading } from 'components/general/Loading.jsx';
import { MyNavBt } from "components/general/MyNavBt";
import { useResource } from "hook/useResource";
import Typography from '@mui/material/Typography';
import { Box, Divider } from '@mui/material';
import { CommonTools } from 'tools/commontools';
import { ComponentCommonTools } from 'tools/componentcommontools'

import ResultObjectDTO from 'dto/app/resultobject.dto';
import MyTextField from 'components/form/mytextfield';
import { LabelTypes } from 'tools/labeltypes';
import ValidateTools from 'tools/validate.tools';
import RequiredValidator from 'validators/required.validator';
import { MyButton } from 'components/general/MyButton';
import { RouteTools } from 'tools/routetools';
import { MyBreadcrumbs } from "components/elements/MyBreadcrumbs/MyBreadcrumbs";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import IconButton from '@mui/material/IconButton';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { TabPanel } from 'components/elements/Tabs/TabPanel';

import { LessonTypeSelect } from 'components/elements/Select/LessonTypeSelect';
import MyCheckboxField from "components/form/mycheckboxfield";

import { Status } from 'tools/status';
import { Skeleton, Stack } from '@mui/material';
import MyCKEditor from 'components/elements/ckeditor/MyCKEditor';

import { AttachmentDialog } from 'components/attachment/AttachmentDialog';
import { GalleryDialog } from 'components/gallery/GalleryDialog';
import { VideoDialog } from 'components/video/VideoDialog';
import MyDateTimePicker from 'components/elements/MyDatePicker/MyDateTimePicker';
import DateTools from 'tools/date.tools';
import { AddTestDialog } from '../test/AddTestDialog';
import { useMessage } from 'hook/useMessage';
import { StatusSelect } from 'components/elements/Select/StatusSelect';
const lessonService = new LessonService();

interface EditLessonProps {
    id: string;
    setObjectsHasChanged: any;
    setExpend: any;
    idCourse: string

}

const EditLesson: React.FC<EditLessonProps> = ({
    id,
    setObjectsHasChanged,
    setExpend,
    idCourse,
    ...props
}) => {


    const { registerDialog } = useMessage();
    const { LL } = useResource();
    const [loading, setLoading] = useState<boolean>(false);
    const [obj, setObj] = useState<LessonDto | undefined>()
    const [isFormDisabled, setIsFormDisabled] = useState(false);

    const [validators, setValidators] = useState<any>();
    const [vresults, setVResults] = useState<any>(false);



    const validateAll = () => {
        if (!obj) return;
        if (!validators) return;


        obj.name = obj.name !== undefined ? obj.name : '';
        obj.description = obj.description !== undefined ? obj.description : '';
        obj.idtypelesson = obj.idtypelesson !== undefined ? obj.idtypelesson : '';
        obj.lessonduration = obj.lessonduration !== undefined ? obj.lessonduration : 0;
        obj.islive = obj.islive !== undefined ? obj.islive : false;
        obj.startdate = obj.startdate !== undefined ? obj.startdate : undefined;
        obj.enddate = obj.enddate !== undefined ? obj.enddate : undefined;
        obj.status = obj.status !== undefined ? obj.status : Status.ACTIVE;
        setObj(obj);

        validateField("name", obj.name);

    };


    const validateField = (field: any, value: any, v?: any) => {
        v = (v) ? v : JSON.parse(JSON.stringify(vresults));
        if (v === false) v = {};
        v[field] = ValidateTools.validate(field, value, validators, vresults);
        setVResults(v);
        setIsFormDisabled(ValidateTools.vForm(v, field));
        return v;
    };



    const checkLoading = () => {

        if (obj === undefined) return;
        if (!validators) return;
        setLoading(false);


    };

    const loadObject = (obj: ResultObjectDTO) => {
        if (!obj) return;
        if (!obj.err) {
            const object = obj.obj ? obj.obj : {};
            setObj(object);
        }
    };

    const getObject = () => {
        setLoading(true);
        if (id !== undefined) {
            lessonService.get(id, loadObject, {});
        }
    };


    const checkIsFormDisabled = () => {
        let rez = ValidateTools.vForm(vresults, 'name');
        if (!obj || !obj.hasOwnProperty('idtypelesson')) rez = true;
        if (obj &&
            obj.hasOwnProperty('idtypelesson') &&
            obj.idtypelesson === process.env.REACT_APP_ID_LESSON_TYPE_ONLINE) {
            if (obj && obj.startdate !== undefined && obj.enddate !== undefined) {
                if (obj.startdate > obj.enddate) {
                    rez = true;
                }
            }
            else {
                rez = true;
            }
        }
        setIsFormDisabled(rez);
    };



    useEffect(() => {
        checkLoading();
        validateAll();
        checkValidDefault();
    }, [obj, validators]);

    useEffect(() => {
        checkIsFormDisabled();
    }, [vresults]);

    const processValidators = () => {
        let v: any = {
            name: [],
        };

        v.name.push(new RequiredValidator('', ''));

        setValidators(v);
    };
    useEffect(() => {
        processValidators()
        getObject();
    }, [id]);

    const handleSubmit = (event: any) => {
        event.preventDefault();
        if (isFormDisabled) return false;
        if (id !== undefined) {
            setLoading(true);
            lessonService.update(id, handleResult, {}, obj);
        }
    };

    const handleActionDelete = () => {
        registerDialog(LL('Confirm_delete_lesson'),
            LL('Are_you_sure_you_want_to_delete_this_lesson'),
            { cb: handleDelete }, null);
    }

    const handleDelete = () => {
        if (!obj) return;
        if (!obj.id) return;
        lessonService.delete(obj.id, handleResult, {});
    }

    const handleResult = (result: ResultObjectDTO, cbparams: any) => {
        if (!result) return;
        if (!result.err) {
            setObjectsHasChanged(CommonTools.generateRandomString(5))
            setLoading(false);
            setExpend(false);
        }
    }

    const setObjField = (field: any, value: any) => {
        let t = JSON.parse(JSON.stringify(obj));
        t[field] = value;
        setObj(t);
        validateField(field, value);
    };

    const checkValidDefault = () => {
        if (!obj) return
        validateField("name", obj.name);
    }
    const setObjDateField = (field: any, value: any) => {
        let t = JSON.parse(JSON.stringify(obj));
        t[field] = DateTools.getDateTimeStamp(value);
        setObj(t);

    };

    const [openDialogAttachment, setOpenDialogAttachment] = useState(false);
    const handleOpenDialogAttachment = () => {
        setOpenDialogAttachment(true);
    };

    const [openDialogGallery, setOpenDialogGallery] = useState(false);
    const handleOpenDialogGallery = () => {
        setOpenDialogGallery(true);
    };

    const [openDialogVideo, setOpenDialogVideo] = useState(false);
    const handleOpenDialogVideo = () => {
        setOpenDialogVideo(true);
    };

    const [openDialogTest, setOpenDialogTest] = useState(false);
    const handleOpenDialogTest = () => {
        setOpenDialogTest(true);
    };


    const processFieldsLessonOnline = () => {
        if (!obj) return <></>;
        if (!obj.hasOwnProperty('idtypelesson')) return <></>;
        if (!obj.idtypelesson) return <></>;
        if (obj.idtypelesson !== process.env.REACT_APP_ID_LESSON_TYPE_ONLINE) return <></>;
        return (
            <Box>
                <Box sx={{ mt: 3 }}>
                    <MyCheckboxField
                        tabIndex={1}
                        id="islive"
                        name="islive"
                        _label={LL("islive")}
                        setObj={(value: any) =>
                            CommonTools.handleCheckValue(obj, setObj, 'islive', value)
                        }
                        checked={obj.islive}
                        color="primary"
                        size="small"
                        _vresults={undefined}
                    />
                </Box>
                <Box sx={{ mt: 3 }}>
                    <Typography variant="h6" mb={1}> {LL('Seteaza data si ora lectiei')}</Typography>
                    <Stack direction='row' spacing={2}>
                        <MyDateTimePicker
                            disablePast
                            className="form-picker"
                            field="startdate"
                            _defaultValue={DateTools.convertTimestampToDate(obj.startdate)}
                            LL={LL}
                            setObjField={setObjDateField}

                        />

                        <MyDateTimePicker
                            disablePast
                            className="form-picker"
                            field="enddate"
                            _defaultValue={DateTools.convertTimestampToDate(obj.enddate)}
                            LL={LL}
                            setObjField={setObjDateField}
                        /></Stack>
                </Box>
            </Box>
        )
    }

    return !loading && obj ? (

        <Box >
            <Stack direction="row" sx={{ bgcolor: '#f5f5f5', borderRadius: 1, p: 1 }} spacing={2} alignItems="center" mb={2}>
                <Box mb={3}>
                    <MyButton
                        cb={handleOpenDialogAttachment}
                        variant="outlined"
                        sx={{ bgcolor: '#fff' }}
                    >
                        {LL('Attachments')}
                    </MyButton>
                    <AttachmentDialog
                        open={openDialogAttachment}
                        setOpen={setOpenDialogAttachment}
                        idparent={id}
                        parent="lesson"
                    />
                </Box>
                <Box mb={3}>
                    <MyButton
                        cb={handleOpenDialogGallery}
                        variant="outlined"
                        sx={{ bgcolor: '#fff' }}
                    >
                        {LL('Gallery')}
                    </MyButton>
                    <GalleryDialog
                        open={openDialogGallery}
                        setOpen={setOpenDialogGallery}
                        idparent={id}
                        parent="lesson"
                    />
                </Box>
                <Box mb={3}>
                    <MyButton
                        variant="outlined"
                        sx={{ bgcolor: '#fff' }}
                        cb={handleOpenDialogVideo}
                    >
                        {LL('Video')}
                    </MyButton>
                    <VideoDialog
                        open={openDialogVideo}
                        setOpen={setOpenDialogVideo}
                        idparent={id}
                        parent="lesson"
                    />
                </Box>
                <Box mb={3}>
                    <MyButton
                        variant="outlined"
                        sx={{ bgcolor: '#fff' }}
                        cb={handleOpenDialogTest}
                    >
                        {LL('Test')}
                    </MyButton>
                    <AddTestDialog
                        open={openDialogTest}
                        setOpen={setOpenDialogTest}
                        idLesson={id}
                        id={idCourse}
                        setObjectWasChanged={setObjectsHasChanged}
                    />
                </Box>
            </Stack>
            <Divider />
            <form onSubmit={handleSubmit}>
                <Typography variant="h6" mb={1} mt={3}> {LL('Tip Numele cursului')}</Typography>
                <MyTextField
                    tabIndex={0}
                    fullWidth
                    id="name"
                    name="name"
                    variant="standard"
                    className='form-input'
                    color="primary"
                    _label={LL("Name")}
                    size="small"
                    value={obj.name}
                    setObj={setObjField}
                    _vresults={
                        vresults && vresults.name ? vresults.name : false
                    }
                />

                <Box sx={{ mt: 3 }}>
                    <Typography variant="h6" mb={1}> {LL('Tip Numele cursului')}</Typography>
                    <LessonTypeSelect
                        tabIndex={3}
                        id="idtypelesson"
                        name="idtypelesson"
                        variant="standard"
                        className='form-select'
                        color="primary"
                        value={obj.idtypelesson}
                        field="idtypelesson"
                        size="small"
                        setObj={setObjField}
                        obj={obj}
                        _defaultValue={obj.idtypelesson}
                    />
                </Box>

                <Box sx={{ mt: 3 }}>
                    <Typography variant="h6" mb={1}> {LL('Status')}</Typography>
                    <StatusSelect
                        tabIndex={2}
                        id="status"
                        name="status"
                        variant="standard"
                        className='form-select'
                        color="primary"
                        value={obj.status}
                        field="status"
                        size="small"
                        setObj={setObjField}
                        _defaultValue={obj.status}
                    />

                </Box>

                {processFieldsLessonOnline()}
                <Box sx={{ mt: 3 }}>
                    <Typography variant="h6" mb={1}> {LL('descriere lectie')}</Typography>
                    <MyCKEditor
                        data={obj.description ? obj.description : ''}
                        setObj={setObjField}
                        name="description"
                    />
                </Box>
                <Box my={4} textAlign='center'>
                    <MyButton
                        tabIndex={3}
                        disabled={isFormDisabled}
                        color="primary"
                        className="btn"
                        variant="contained"
                        size="large"
                        type="submit"

                        children={LL("Bt_Submit Modification")}
                    /></Box>
            </form >
            <Box my={4} textAlign='center'>
                <MyButton
                    tabIndex={3}

                    color="error"
                    className="btn"
                    variant="contained"
                    size="large"
                    cb={handleActionDelete}

                    children={LL("Bt_Delete")}
                /></Box>
        </Box >

    ) : (
        <Skeleton variant='rectangular' height={300} />
    );
};

export default EditLesson;