import DateTools from "tools/date.tools";
import { Status } from "tools/status";
import { LabelTypes } from "tools/labeltypes";
import { Types } from "tools/types";
import { logger } from "hoc/logger";

interface PopulateObject {
    key: string;
    type: string;
}

export default class GeneralService {
    static _LL: any = false;

    static setLL(_l: any): any {
        this._LL = _l;
    }
    public static LL = (key: any) => {
        return this._LL(key);
    };

    populateObject(obj: any, data: any, keys: PopulateObject[]) {
        
        const typeConversionMapping = {
            'timestamp': (value: number) => DateTools.convertTimestampToDate(value).toLocaleDateString('en-GB'),
            'date': (value: any) => DateTools.convertToDate(value).toLocaleDateString('en-GB'),
            'status': (value: number) => Status.LL(value),
            'teacherStatus': (value: number) => Status.LL(value),
            'typeLabel': (value: number) => LabelTypes.LL(value),
            'types': (value: number) => Types.LL(value),
            'section': (value: number) => Types.LL(value),
            'faqs': (value: number) => Types.LL(value),
            'blogs': (value: number) => Types.LL(value),
            'questions': (value: number) => Types.LL(value),
            'typeowner': (value: number) => Types.LL(value),
            'transactiontype': (value: number) => Types.LL(value),
            'answers': (value: number) => Types.LL(value),
            'booleans': (value: boolean) => value ? GeneralService.LL('True') : GeneralService.LL('False')
        };



        const keysMap = new Map(keys.map(k => [k.key, k]));

        for (var key in data) {

            let foundKeyObj = keysMap.get(key);

            if (foundKeyObj && foundKeyObj.type in typeConversionMapping) {
                const tmpKey = key + '_name';
                obj[tmpKey] = typeConversionMapping[foundKeyObj.type as keyof typeof typeConversionMapping](data[key]);
            }
        }
    }

    parseResultPopulate(result: any, rez: any, keysAndTypes: PopulateObject[]) {
        if (result.objects && rez.objects) {
            for (let index = 0; index < result.objects.length; index++) {
                const obj = result.objects[index];
                if (rez.objects[index]) {
                    this.populateObject(rez.objects[index], obj, keysAndTypes);
                }
            }
        }
    }

    check() {
        // //logger("check")
    }
}
