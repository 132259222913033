import React, { useState, useEffect } from "react";
import { useResource } from "hook/useResource";
import { LinkCourseCategoryDto } from "dto/course/linkcoursecategory.dto";
import RequestFilterDTO from "dto/app/requestfilter.dto";
import RequestListDTO from "dto/app/requestlist.dto";
import { Status } from "tools/status";
import LinkCourseCategoryService from "services/course/linkcoursecategory.service";
import ResultListDTO from "dto/app/resultlist.dto";
import { Box, List, ListItem, ListItemText, Skeleton, Typography } from "@mui/material";
import { logger } from "hoc/logger";

import { ComponentCommonTools } from "tools/componentcommontools";
import RequestSortCriteriaDTO from "dto/app/requestsortcriteria.dto";

type LinkCourseCategoryListProps = {
    idCourse: string;
}

const linkCourseCategoryService = new LinkCourseCategoryService();

const LinkCourseCategoryList: React.FC<LinkCourseCategoryListProps> = ({
    idCourse,
    ...props }) => {

    const { LL } = useResource();
    const [isLoading, setIsLoading] = useState(true);
    const [objects, setObjects] = useState<Array<LinkCourseCategoryDto>>([]);


    const getList = () => {

        setIsLoading(true);
        const reqList = new RequestListDTO();
        reqList.onpage = 999999;
        reqList.page = 1;
        reqList.filters = [];

        let f: RequestFilterDTO;

        f = new RequestFilterDTO();
        f.field = "idcourse";
        f.values = [];
        f.values.push(idCourse);
        reqList.filters.push(f);


        linkCourseCategoryService.getList(loadObjects, {}, reqList);

        // setIsLoading(false);
    };


    const loadObjects = (result: ResultListDTO) => {
        if (!result) return;
        if (!result.err) {
            const objects = result.objects ? result.objects : [];
            // const total = result.total !== undefined ? result.total : -1;
            // const totalPage = result.totalpages !== undefined ? result.totalpages : -1;
            // const reqInfo = result.requestinfo ? result.requestinfo : {};
            setObjects(objects);
            // setTotal(total);
            // setTotalPage(totalPage);
            // setResponseParams(reqInfo);
        }
    }

    const checkLoading = () => {
        let load = false;

        // if (total === -1) return true;
        // if (totalPage === -1) return true;
        if (!objects) load = true;
        // if (objects.length === 0) load = true;
        // if (Object.keys(responseParams).length === 0) return true;

        setIsLoading(load);
    }

    useEffect(() => {
        getList();
    }, [idCourse]);

    useEffect(() => {
        checkLoading();
    }, [objects]);

   


    const processLinkCourseCategoryItem = (obj: LinkCourseCategoryDto, index: number) => {

        if (!obj) return <></>;
        if (!obj.id) return <></>;

        return (<Box key={index}>

                <Typography className="nameFilter">
                    {ComponentCommonTools.processObjectFieldOfObject(obj, 'coursecategory', 'name')}
                </Typography>

        </Box >);

    };

    const processLinkCourseCategoryList = () => {
        if (!objects.length) return <></>
        return (
            <Box>
                {objects.map((item: LinkCourseCategoryDto, i: number) => {
                    return processLinkCourseCategoryItem(item, i);
                })}
            </Box>
        );
    };


    return isLoading ? <Skeleton /> : (
        <Box>

                {processLinkCourseCategoryList()}

        </Box>
    );
};

export { LinkCourseCategoryList };
