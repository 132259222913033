import React, { useState, useEffect, useRef } from "react";

import { styled, useTheme, Theme, CSSObject } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';

import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import { useResource } from "hook/useResource";

import { MyNavBt } from "components/general/MyNavBt";
import { MenuItem, MenuItemOption } from "dto/app/teacherdrawer.dto"
import { logger } from "hoc/logger";

import { TeacherDrawerMenuItems } from "dto/teacher/teacherdrawermenuitems";
import {StudentDrawerMenuItems} from "dto/student/studentdrawermenuitems";
import { TeacherLogo } from "components/general/TeacherLogo";
const drawerWidth = 300;


const openedMixin = (theme: Theme): CSSObject => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});


const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));


const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);

const secondDrawerWidth = 240; // You can adjust the width of the second drawer as needed

const SecondDrawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        width: secondDrawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        marginLeft: drawerWidth,
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': {
                ...openedMixin(theme),
                marginLeft: drawerWidth,
            },
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);

type DrawerProps = {
    currentRoute: any;
    source:MenuItem[];
}

const TeacherDrawer: React.FC<DrawerProps> = ({ currentRoute,
    source, ...props }) => {
    const theme = useTheme();
    const [open, setOpen] = useState(false);
    const { LL } = useResource();
    const [secondDrawerOpen, setSecondDrawerOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState<any>(null);
    const [loading, setLoading] = useState(true);
    const [menu, setMenu] = useState<MenuItem[]>([]);

    

    const processCurrentRouteItemMenu = () => {
        if (!currentRoute) return '';
        if (!currentRoute.hasOwnProperty('processedData')) return '';
        if (!currentRoute.processedData) return '';
        if (!currentRoute.processedData.hasOwnProperty('currentItemMenu')) return '';
        if (!currentRoute.processedData.currentItemMenu) return '';
        const currentItemMenu = currentRoute.processedData.currentItemMenu;
        return currentItemMenu;
    }
   
    

    const processSelectedItemMenu = () => {
        setLoading(true);

        const menuItems: MenuItem[] = source;
        setMenu([]);
        const localMenu = menuItems.map((item: MenuItem, index: any) => {
            item.isSelected = false;
            if (item.options) {
                item.options.map((option: MenuItemOption, index: any) => {
                    if (option.currentItemMenu == processCurrentRouteItemMenu()) {
                        item.isSelected = true;
                    }
                })
            }
            return item;
        })
        setMenu(localMenu);
        setLoading(false);
    }

    useEffect(() => {
        processSelectedItemMenu();
    }, [currentRoute]);

    useEffect(() => {
        processSelectedItemMenu()
    }, [source]);

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setSelectedItem(null);
        setSecondDrawerOpen(false);
        setOpen(false);
    };
    const handleItemClick = (event: React.MouseEvent, item: any) => {
        event.stopPropagation();
        if (!open) {

            setOpen(true);

            setTimeout(() => {
                setSelectedItem(item);
                setSecondDrawerOpen(true);
            }, theme.transitions.duration.enteringScreen);
        } else {
            setSelectedItem(item);
            setSecondDrawerOpen(true);
        }
    };


    const handleSecondDrawerClose = (event: React.MouseEvent) => {
        event.stopPropagation();
        setSelectedItem(null);
        setSecondDrawerOpen(false);
    };

    const processSecondListMenu = () => {
        if (loading) return <></>;
        const selectedItemOptions = selectedItem?.options || [];

        return (
            <List >
                {selectedItemOptions.map((option: MenuItemOption, index: any) => (
                    <ListItem
                        key={index}
                        disablePadding
                        sx={{ display: 'block' }}
                        additionalOnClick={handleDrawerClose}
                        component={MyNavBt}
                        href={processHref(option)}
                    >
                        <ListItemButton
                            sx={{
                                minHeight: 48,
                                justifyContent: open ? 'initial' : 'center',
                                px: 2.5,
                            }}
                        >
                            <ListItemIcon
                                sx={{
                                    minWidth: 0,
                                    mr: open ? 3 : 'auto',
                                    justifyContent: 'center',
                                }}
                            >
                                {processIcon(option)}
                            </ListItemIcon>
                            <ListItemText sx={{ opacity: open ? 1 : 0 }}>
                                {processName(option)}
                            </ListItemText>
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
        );
    };

    const processListMenu = () => {
        if (loading) return <></>;
        return (
            <List>
                {menu.map((item: MenuItem, index: any) => (
                    <ListItem key={index} disablePadding sx={{ display: 'block' }}>
                        <ListItemButton
                            onClick={(event) => handleItemClick(event, item)}
                            selected={item.isSelected}
                            className="itemOne"
                            sx={{
                                minHeight: 48,
                                justifyContent: open ? 'initial' : 'center',
                                px: 2.5,

                            }}
                        >
                            <ListItemIcon
                                sx={{
                                    minWidth: 0,
                                    mr: open ? 3 : 'auto',
                                    justifyContent: 'center',

                                }}
                                className="itemOne"
                            >
                                {processIcon(item)}
                            </ListItemIcon>
                            <ListItemText sx={{ opacity: open ? 1 : 0 }}>
                                {processName(item)}
                            </ListItemText>
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
        );
    };


    const processSelectedItem = () => {
        if (!selectedItem) return <></>;
        return (
            <Box className="processHeader">
                <Box>{processIcon(selectedItem)}</Box>
                <Typography className="name">
                    {processName(selectedItem)}
                </Typography>
            </Box>
        )
    }

    const processIcon = (obj: any) => {
        if (!obj) return <></>;
        if (!obj.hasOwnProperty('icon')) return <></>;
        if (!obj.icon) return <></>;
        return <>{obj.icon}</>;
    }

    const processName = (obj: any) => {
        if (!obj) return <></>;
        if (!obj.hasOwnProperty('name')) return <></>;
        if (!obj.name) return <></>;
        return <>{LL(obj.name)}</>;
    }

    const processHref = (obj: any) => {
        if (!obj) return '/';
        if (!obj.hasOwnProperty('href')) return '/';;
        if (!obj.href) return '/';;
        return obj.href;
    }

    const processDrawerOnToggle = () => {
        if (open) {
            handleDrawerClose();
        }
        else {
            handleDrawerOpen();
        }
    }

    const boxRef = useRef<HTMLDivElement | null>(null);


    const handleClickOutside = (event: Event) => {
        const target = event.target as Node;
        if (boxRef.current && !boxRef.current.contains(target)) {
            handleDrawerClose();
        }
    };


    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [])

    return !loading ? (<Box
        sx={{ display: 'flex' }}
        onClick={processDrawerOnToggle}
        ref={boxRef as React.RefObject<HTMLDivElement>}
    >

        <Drawer
            variant="permanent"
            open={open}
            className="teacherDrawer"


        >
            {open ?
                (<DrawerHeader className="hD">
                    {/* <i className='teacher-logo'></i> */}
                    <TeacherLogo />
                    <IconButton onClick={handleDrawerClose}>
                        {theme.direction === 'rtl' ? <ChevronRightIcon sx={{ color: '#fff' }} /> : <ChevronLeftIcon sx={{ color: '#fff' }} />}
                    </IconButton>

                </DrawerHeader>)
                : (<DrawerHeader className="hD">
                    <TeacherLogo />
                    {/* <i className='teacher-logo'></i> */}
                </DrawerHeader>)
            }

            {processListMenu()}

        </Drawer>

        {secondDrawerOpen && (
            <SecondDrawer variant="permanent" className="teacherDrawerSecond" open={secondDrawerOpen} sx={{ width: 0 }}>
                <DrawerHeader >
                    {processSelectedItem()}

                </DrawerHeader>
                <Divider />
                {processSecondListMenu()}
            </SecondDrawer>
        )}
    </Box>) : <></>;
};

export { TeacherDrawer };
