import React, { useState, useEffect } from 'react';

import PhoneService from "services/user/phone.service";
import { logger } from 'hoc/logger';

import PhoneDto from 'dto/user/phone.dto';
import { useCookies } from 'react-cookie';
import { Loading } from 'components/general/Loading.jsx';
import { MyNavBt } from "components/general/MyNavBt";
import { useResource } from "hook/useResource";
import Grid from '@mui/material/Grid';
import { CommonTools } from 'tools/commontools';
import { ComponentCommonTools } from 'tools/componentcommontools'

import ResultObjectDTO from 'dto/app/resultobject.dto';
import { MyBreadcrumbs } from "components/elements/MyBreadcrumbs/MyBreadcrumbs";
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'

import { useAuth } from 'hook/useAuth';
import { CircularLoading } from 'components/general/CircularLoading';
import { PhoneTypeSelect } from 'components/elements/Select/PhoneTypeSelect';
import { Status } from 'tools/status';
import MyTextField from 'components/form/mytextfield';
import { LabelTypes } from 'tools/labeltypes';
import ValidateTools from 'tools/validate.tools';
import RequiredValidator from 'validators/required.validator';
import { MyButton } from 'components/general/MyButton';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';


import { CountryCodeSelect } from 'components/elements/Select/CountryCodeSelect';

const phoneService = new PhoneService();


interface PhoneProps {
    currentRoute: any

}

const AddUserPhone: React.FC<PhoneProps> = ({
    currentRoute,
    ...props
}) => {

    const { LL } = useResource();

    const [loading, setLoading] = useState<boolean>(true);
    const [obj, setObj] = useState<Array<PhoneDto> | undefined>();
    const [objPhone, setObjPhone] = useState<PhoneDto | undefined>(new PhoneDto());
    const { user } = useAuth();
    const _id = user !== undefined ? user.id : '';
    const [id, setId] = useState<string>(_id);


    const loadObject = (obj: any) => {
        if (!obj) return;
        if (!obj.err) {
            const object = obj.obj ? obj.obj : [];
            setObj(object);
        }
    };

    const getObject = () => {
        
        setLoading(true);
        if (id !== undefined) phoneService.getPhonesByUserId(id, loadObject, {});
    };

    const checkIsLoading = () => {
        let load: boolean = false;
        if (obj === undefined) load = true;
        setLoading(load);
    };

    useEffect(() => {
        getObject();
    }, [currentRoute]);

    useEffect(() => {
        checkIsLoading();

    }, [obj]);

    useEffect(() => {

        if (user !== undefined) {
            setId(user.id)
        }
    }, [user])

    const [validators, setValidators] = useState<any>();
    const [vresults, setVResults] = useState<any>(false);
    const [isFormDisabled, setIsFormDisabled] = useState(false);

    const validateAll = () => {
        if (!objPhone) return;
        if (!validators) return;
        // if (vresults) return;


        objPhone.countrycode = objPhone.countrycode !== undefined ? objPhone.countrycode : '';
        objPhone.phonenumber = objPhone.phonenumber !== undefined ? objPhone.phonenumber : '';

        objPhone.idtypephone = objPhone.idtypephone !== undefined ? objPhone.idtypephone : '';

        setObjPhone(objPhone);

        let t = validateField("countrycode", objPhone.countrycode);
        t = validateField("phonenumber", objPhone.phonenumber, t);
    };

    const validateField = (field: any, value: any, v?: any) => {

        v = (v) ? v : JSON.parse(JSON.stringify(vresults));
        if (v === false) v = {};
        v[field] = ValidateTools.validate(field, value, validators, vresults);

        setIsFormDisabled(ValidateTools.vForm(v, field));
        setVResults(v);
        return v;
    };

    const checkIsFormDisabled = () => {
        var rez = ValidateTools.vForm(vresults, 'countrycode');
        rez = ValidateTools.vForm(vresults, 'phonenumber', rez);

        setIsFormDisabled(rez);
    };

    useEffect(() => {
        checkIsFormDisabled();
    }, [vresults]);

    useEffect(() => {
        processValidators();
    }, []);
    useEffect(() => {
        validateAll();
    }, [objPhone, validators]);

    const processValidators = () => {
        let v: any = {
            countrycode: [],
            phonenumber: [],
        };

        v.countrycode.push(new RequiredValidator('', ''));
        v.phonenumber.push(new RequiredValidator('', ''));

        setValidators(v);
    };

    const setObjField = (field: any, value: any) => {
        let t = JSON.parse(JSON.stringify(objPhone));
        t[field] = value;
        setObjPhone(t);
    };

    const handleSubmit = (event: any) => {
        event.preventDefault();

        if (objPhone !== undefined && id !== undefined && objPhone.phonenumber !== undefined) {

            objPhone.iduser = id;
            objPhone.status = Status.ACTIVE;
            
            if (!objPhone.hasOwnProperty('id')) phoneService.add(getObject, {}, objPhone);
            else {
                if (objPhone.id) phoneService.update(objPhone.id, getObject, {}, objPhone)
            }
        }

        setObjPhone(new PhoneDto());
    };

    const handleDelete = (obj: PhoneDto) => {

        if (obj.id) phoneService.delete(obj.id, getObject, undefined);
    };

    const processPhoneItem = (obj: PhoneDto, index: any) => {
        if (!obj) return <></>


        return (
                <>
                <Box  sx={{ flexGrow: 1 }}>
                    <Grid container py={2} spacing={2}>
                        <Grid item xs={6}>
                            <Typography>
                                {ComponentCommonTools.processObjectField(obj, 'countrycode')}
                                {' '}
                                {ComponentCommonTools.processObjectField(obj, 'phonenumber')}
                                {' '}
                                {ComponentCommonTools.processObjectFieldOfObject(obj, 'typephone', 'name')}
                            </Typography>
                        </Grid>
                        <Grid item xs={4}><Typography>{ComponentCommonTools.processObjectFieldOfObject(obj, 'typephone', 'name')}</Typography></Grid>
                        <Grid item xs={2} ml={0}>
                            <MyButton
                            type="submit"
                            cb={() => { setObjPhone(obj) }}
                            children={<EditIcon />}
                            color="primary"
                            
                            />
                            
                            <MyButton
                                color="secondary"
                                type="submit"
                                cb={() => { handleDelete(obj) }}
                                children={<DeleteIcon />}
                                
                            />
                        </Grid>
                    </Grid>
                    <Divider />
                </Box>
                    

                    

                </>
            
        )
    }


    const processPhoneList = () => {
        if (!obj) return <></>
        if (obj.length === 0) return <></>
        return (<>
            {obj.map((item: PhoneDto, index: any) => processPhoneItem(item, index))}
        </>
        )
    }
    return !loading && obj && objPhone ? (
        <>

            <Box mt={3}>


                <form onSubmit={handleSubmit} >
                    <Box sx={{ flexGrow: 1 }} maxWidth={'sm'} mx='auto'>
                        <Grid container spacing={2}>
                            <Grid item xs={4}>
                                <Typography variant='h6'>{LL("countrycode")}</Typography>
                                <CountryCodeSelect
                                    value={objPhone.countrycode}
                                    field="countrycode"
                                    size="small"
                                    variant="standard"
                                    color="primary"
                                    className="form-select"
                                    setObj={setObjField}
                                    obj={objPhone}
                                /></Grid>
                            <Grid item xs={8}>
                                <Typography variant='h6'>{LL("phonenumber")}</Typography>
                                <MyTextField
                                tabIndex={1}
                                fullWidth
                                id="phonenumber"
                                name="phonenumber"
                                variant="standard"
                                className="form-input"
                                _label={LL("phonenumber")}
                                value={objPhone.phonenumber}
                                setObj={setObjField}
                                _vresults={
                                    vresults && vresults.phonenumber ? vresults.phonenumber : false
                                }
                            />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant='h6'>{LL("idtypephone")}</Typography>
                                <PhoneTypeSelect
                                    tabIndex={3}
                                    id="idtypephone"
                                    name="idtypephone"
                                    variant="standard"
                                    color="primary"
                                    className="form-select"
                                    value={objPhone.idtypephone}
                                    field="idtypephone"
                                    size="small"
                                    setObj={setObjField}
                                    obj={objPhone}
                                />
                            </Grid>
                        </Grid>
                        <Box textAlign='center'>
                        <MyButton
                        tabIndex={4}
                        disabled={isFormDisabled}
                        color="primary"
                        variant="contained"
                        size="large"
                        type="submit"
                        sx={{ mt: 4 }}
                        children={LL("Bt_Submit")}
                        className="btn"
                        /></Box>
                    </Box>
                    
                </form>
                <Box p={2}>
                    <Typography variant='h4' mt={4}>{LL("Phone numbers list user")}</Typography>
                    {processPhoneList()}
                </Box>


            </Box>
        </>
    ) : (
        <CircularLoading />
    );
};

export default AddUserPhone;
