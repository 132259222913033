import React, { useState, useEffect } from 'react';

import CourseSettingsService from "services/course/coursesettings.service";
import { logger } from 'hoc/logger';

import { CourseSettingsDto } from 'dto/course/coursesettings.dto';
import { useCookies } from 'react-cookie';
import { Loading } from 'components/general/Loading.jsx';
import { MyNavBt } from "components/general/MyNavBt";
import { useResource } from "hook/useResource";
import Grid from '@mui/material/Grid';
import { CommonTools } from 'tools/commontools';
import { ComponentCommonTools } from 'tools/componentcommontools'

import ResultObjectDTO from 'dto/app/resultobject.dto';
import { MyBreadcrumbs } from "components/elements/MyBreadcrumbs/MyBreadcrumbs";
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import Divider from '@mui/material/Divider';
import { Skeleton, Stack } from '@mui/material';

const courseSettingsService = new CourseSettingsService();


interface CourseSettingsProps {
    idCourse: string;

}

const CourseSettingsInfo: React.FC<CourseSettingsProps> = ({
    idCourse,
    ...props
}) => {

    const { LL } = useResource();

    const [loading, setLoading] = useState<boolean>(true);
    const [obj, setObj] = useState<CourseSettingsDto | undefined | null>();


    const loadObject = (obj: any) => {
        if (!obj) return;
        if (!obj.err) {
            const object = obj.obj ? obj.obj : [];
            setObj(object);
        }

    };

    const getObject = () => {
        if (idCourse !== '') courseSettingsService.getCourseSettingsByCourseId(idCourse, loadObject, {});
    };

    const checkIsLoading = () => {
        let load: boolean = false;
        if (obj === undefined) load = true;
        setLoading(load);
    };

    useEffect(() => {
        getObject();
    }, [idCourse]);

    useEffect(() => {
        checkIsLoading();
    }, [obj]);


    const processHasTest = () => {
        if (!obj) return <></>;

        if (!obj.hasOwnProperty('hastest')) return (
            <>
                <Stack direction='row' spacing={1} className="categories" justifyContent='space-between'>
                    <Typography >{LL("HasTest")}</Typography>
                    <Typography className='nameFilter'>{LL('False')}</Typography>
                </Stack>
            </>);
        return (
            <>
                <Stack direction='row' spacing={1} className="categories" justifyContent='space-between'>
                    <Typography >{LL("HasTest")}</Typography>
                    <Typography className='nameFilter'>
                        {ComponentCommonTools.processObjectField(obj, 'hastest_name')}
                    </Typography>
                </Stack>
            </>
        )
    }

    const processCourseSettings = () => {
        if (!obj) return <></>;
        return (
            <>
                <Stack direction='row' spacing={1} className="categories" justifyContent='space-between'>
                    <Typography > {LL("typecourse")}</Typography>
                    <Typography className='nameFilter'>
                        {ComponentCommonTools.processObjectFieldOfObject(obj, 'typecourse', 'name')}
                    </Typography>
                </Stack>
                {processHasTest()}

            </>
        )
    }
    return loading ? <Skeleton /> : (
        <Box>
            {processCourseSettings()}
        </Box>
    );
};

export { CourseSettingsInfo };
