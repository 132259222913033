import React from "react";
import PageComponentProps from "interfaces/pagecomponentprops.interface";
import { AdvertisementBecomeTeacherSection1 } from "components/advertisement/AdvertisementBecomeTeacherSection1";
import { AdvertisementBecomeTeacherSection2 } from "components/advertisement/AdvertisementBecomeTeacherSection2";
import { AdvertisementBecomeTeacherSection3 } from "components/advertisement/AdvertisementBecomeTeacherSection3";
import { AdvertisementBecomeTeacherSection4 } from "components/advertisement/AdvertisementBecomeTeacherSection4";
import { AdvertisementBecomeTeacherSection5 } from "components/advertisement/AdvertisementBecomeTeacherSection5";
import { Box } from "@mui/material";
import { StepperBecomeTeacher } from "./StepperBecomeTeacher";
import { BecomeTeacherTestimonialList } from "components/testimonial/BecomeTeacherTestimonialList";

const BecomeTeacherStaticPage: React.FC<PageComponentProps> = ({ currentRoute, ...props }) => {
  return (
    <Box className="becomeTeacher">
      <AdvertisementBecomeTeacherSection1 currentRoute={currentRoute} />
      <AdvertisementBecomeTeacherSection2 currentRoute={currentRoute} />
      <AdvertisementBecomeTeacherSection3 currentRoute={currentRoute} />
      <StepperBecomeTeacher currentRoute={currentRoute} />
      <AdvertisementBecomeTeacherSection4 currentRoute={currentRoute} />
      <BecomeTeacherTestimonialList currentRoute={currentRoute} />
      <AdvertisementBecomeTeacherSection5 currentRoute={currentRoute} />
    </Box>
  );
};

export { BecomeTeacherStaticPage };
