import React from "react";
import { MyNavBt } from "components/general/MyNavBt";
import { useResource } from "hook/useResource";
import { Alert, Box } from "@mui/material";
import PageComponentProps from "interfaces/pagecomponentprops.interface";

const NeedCheckout: React.FC<PageComponentProps> = ({
  currentRoute,
  ...props
}) => {
  const { LL } = useResource();
  return (
    <>
      <Alert severity="error" className="alertZone">
        <MyNavBt href={"/checkout"} className="btnAlert">{LL("Go_Checkout")}</MyNavBt>
      </Alert>
    </>
  );
};

export { NeedCheckout };
