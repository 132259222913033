import React, { useEffect, useState } from "react";
import PageComponentProps from "interfaces/pagecomponentprops.interface";
import { CommonTools } from "tools/commontools";
import { Box, Skeleton, Typography, Grid, Stack } from "@mui/material";
import { CourseDto } from "dto/course/course.dto";
import ResultObjectDTO from "dto/app/resultobject.dto";
import CourseService from "services/course/course.service";
import { HtmlTag } from "components/general/HtmlTag";
import { useResource } from "hook/useResource";
import { CourseLessonsList } from "components/course/lesson/CourseLessonsList";

import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import DateTools from "tools/date.tools";


const courseService = new CourseService();
type   ResourcesTabBlockProps = {
    obj: CourseDto;
}
const ResourcesTabBlock: React.FC<ResourcesTabBlockProps> = ({ obj, ...props }) => {

    const { LL } = useResource();
  

    const processLessonsList = () => {
        if (!obj) return <></>;
        if (!obj.id) return <></>;
        return (<Box>
            <Box>
                {processLessonsContentHeader()}
            </Box>
            <Box>
                <CourseLessonsList idCourse={obj.id} />
            </Box>
        </Box>)
    }


    const processLessonsContentHeader = () => {
        if (!obj) return <></>;
        if (!obj.id) return <></>;

        const lessonNumber = getNumberOfLessons();
        const courseDuration = getCourseDuration();

        return (<>
            <Typography variant="h3" mt={5}>{LL('CourseContent')}</Typography>
            <Stack direction="row" spacing={2} my={3} alignItems='center' className="courseGeneralData">
                <Typography><span>{LL('LessonsNumber')}</span> {lessonNumber}</Typography>
                <FiberManualRecordIcon sx={{ fontSize: '10px' }} color="primary" />
                <Typography><span>{LL('CourseDuration')}</span>  {courseDuration}</Typography>
            </Stack>
        </>)
    }



    const getCourseDuration = () => {
        if (!obj) return 0;
        if (!obj.hasOwnProperty('courseduration')) return 0;
        if (!obj.courseduration) return 0;
        return DateTools.displayTimestampInHHMM(obj.courseduration);
    }
    const getNumberOfLessons = () => {
        if (!obj) return 0;
        if (!obj.hasOwnProperty('numberoflessons')) return 0;
        if (!obj.numberoflessons) return 0;
        return obj.numberoflessons;
    }
   

   
    return  obj ?
        (<Box className="detailCourse">
            
            <Box className="container">
                <Grid container>
                    <Grid item lg={9}>
                        {processLessonsList()}
                    </Grid>
                </Grid>
            </Box>
        </Box>) : (<></>);
};

export { ResourcesTabBlock };
