import RequestListDTO from "dto/app/requestlist.dto";
import Idto from "interfaces/idto.interface";
import IRepository from "interfaces/irepository.interface";
import GeneralRepository from "repositories/general.repository";

const { REACT_APP_API_SERVER, REACT_APP_URLPREFIX } = process.env;

export default class CourseEnrollmentRepository extends GeneralRepository implements IRepository {
    async delete(id: string, cb?: any, cbparameters?: any): Promise<any> {
        const url = REACT_APP_API_SERVER + "/courseenrollment/" + id;
        return await this.deleteAxios(url, cb, cbparameters);
    }

    async update(id: string, cb?: any, data?: Idto | undefined, cbparameters?: any): Promise<any> {
        const url = REACT_APP_API_SERVER + "/courseenrollment/" + id;
        return await this.putAxios(url, cb, data, cbparameters);
    }

    async requestCertificate(id: string, cb?: any, cbparameters?: any): Promise<any> {
        const url = REACT_APP_API_SERVER + "/courseenrollment/req-certificate/" + id;
        return await this.putAxios(url, cb, {}, cbparameters);
    }
    patch(id: string, cb?: any, data?: Idto | undefined, cbparameters?: any): Promise<any> {
        throw new Error("Method not implemented.");
    }

    async getProgress(id: string, cb?: any, cbparameters?: any): Promise<any> {
        const url = `${REACT_APP_API_SERVER}/courseenrollment/progress/${id}`;
        return await this._get(url, cb, cbparameters);
    }

    async get(id: string, cb?: any, cbparameters?: any): Promise<any> {
        const url = `${REACT_APP_API_SERVER}/courseenrollment/${id}`;
        return await this._get(url, cb, cbparameters);
    }

    async checkStudentHasCourse(
        idcourse: string,
        idstudent: string,
        cb?: any,
        cbparameters?: any): Promise<any> {
        const url = (REACT_APP_API_SERVER ?? '') +
            (REACT_APP_URLPREFIX ?? '') + `/courseenrollment/check-student/${idcourse}?idstudent=${idstudent}`;
        return await this._get(url, cb, cbparameters);
    }

    async getList(
        cb?: any,
        cbparameters?: any,
        data?: RequestListDTO
    ): Promise<any> {
        const url = `${REACT_APP_API_SERVER}/courseenrollment`;
        return await this._getList(url, cb, cbparameters, data);
    }

    async getListForTeacher(
        cb?: any,
        cbparameters?: any,
        data?: RequestListDTO
    ): Promise<any> {
        const url = `${REACT_APP_API_SERVER}/courseenrollment/for-teacher`;
        return await this._getList(url, cb, cbparameters, data);
    }

    async getListForClassroom(
        cb?: any,
        cbparameters?: any,
        data?: RequestListDTO
    ): Promise<any> {
        const url = `${REACT_APP_API_SERVER}/courseenrollment/for-classroom`;
        return await this._getList(url, cb, cbparameters, data);
    }

    async add(cb?: any, cbparameters?: any, data?: Idto): Promise<any> {
        const url = `${REACT_APP_API_SERVER}/courseenrollment`;

        return await this.postAxios(url, cb, data, cbparameters);
    }

    async addForTest(cb?: any, cbparameters?: any, data?: Idto): Promise<any> {
        const url = `${REACT_APP_API_SERVER}/courseenrollment/for-test`;

        return await this.postAxios(url, cb, data, cbparameters);
    }

    async addForCheckout(cb?: any, cbparameters?: any, data?: Idto): Promise<any> {
        const url = `${REACT_APP_API_SERVER}/courseenrollment/for-checkout`;
        return await this.postAxios(url, cb, data, cbparameters);
    }
}
