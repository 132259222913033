import React, { useState, useEffect } from "react";
import { TransitionProps } from '@mui/material/transitions';
import {
    Box,
    DialogContent,
    Button,
    Skeleton,
    Dialog,
    AppBar,
    IconButton,
    Slide,
    Toolbar,
    Typography,
    Stack
} from "@mui/material";
import { useResource } from "hook/useResource";
import { ComponentCommonTools } from "tools/componentcommontools";
import { logger } from "hoc/logger";
import { MyButton } from "components/general/MyButton";

import CloseIcon from '@mui/icons-material/Close';
import { CourseDto } from "dto/course/course.dto";
import RequestListDTO from "dto/app/requestlist.dto";
import RequestFilterDTO from "dto/app/requestfilter.dto";
import ResultListDTO from "dto/app/resultlist.dto";
import { TestDto } from "dto/course/test.dto";
import TestService from "services/course/test.service";
import { TestStaticContent } from "./TestStaticContent";
import { Types } from "tools/types";
import { Status } from "tools/status";
import { TestQuestionList } from "./TestQuestionList";
import { PostTestResultDto, TestResultDto } from "dto/course/testresult.dto";
import { useAuth } from "hook/useAuth";
import TestResultService from "services/course/testresult.service";
import ResultObjectDTO from "dto/app/resultobject.dto";
import { TestResult } from "./TestResult";
import { CommonTools } from "tools/commontools";
import { LessonDto } from "dto/course/lesson.dto";
import { LessonEnrollmentDto } from "dto/course/lessonenrollment.dto";
import { LogoComp } from "components/general/LogoComp";



const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

type TestDialogProps = {
    courseEnrollmentObject: any;
    idCourse: string;
    idLesson?: string;
    type: number;
    course?: CourseDto;
    setCheckObjectWasChanged?: any;
    lesson?: LessonDto
    showTitleLesson?: boolean;
    lessonEnrollment?: LessonEnrollmentDto;
    setVerifyLessonEnrChanged?: any;
    openDefault?: boolean;
}

const testService = new TestService();

const testResultService = new TestResultService();

const TestDialog: React.FC<TestDialogProps> = ({
    courseEnrollmentObject,
    idCourse,
    idLesson,
    type,
    course,
    setCheckObjectWasChanged,
    lesson,
    showTitleLesson,
    lessonEnrollment,
    setVerifyLessonEnrChanged,
    openDefault,
    ...props
}) => {
    const _openDefault = openDefault ? openDefault : false;

    const { student } = useAuth();
    const { LL } = useResource();
    const [open, setOpen] = useState<boolean>(_openDefault);
    const [loading, setLoading] = useState<boolean>(false);
    const [obj, setObj] = useState<TestDto | undefined | null>();

    const [testResult, setTestResult] = useState<TestResultDto | undefined>();

    const createTest = () => {
        if (!courseEnrollmentObject || !idCourse ||
            !student || !course || !obj) return;
        if (!courseEnrollmentObject.id || !student.id ||
            !course.idteacher || !obj.id) return;

        const postObj = new PostTestResultDto();
        postObj.idcourseenrollment = courseEnrollmentObject.id;
        if (
            lessonEnrollment &&
            lessonEnrollment.hasOwnProperty('id') &&
            lessonEnrollment.id
        ) {
            postObj.idlessonenrollment = lessonEnrollment.id;
        }
        postObj.idcourse = idCourse;
        postObj.idstudent = student.id;
        postObj.idteacher = course.idteacher;
        postObj.idtest = obj.id;
        postObj.maxscore = obj.maxtestscore;
        postObj.minscore = obj.mintestscore;
        
        setLoading(true);
        testResultService.add(handleResultAddTestResult, {}, postObj);
    }
    const handleResultAddTestResult = (result: ResultObjectDTO) => {
        if (!result) return;
        if (!result.err) {
            if (result.obj) {
                setTestResult(result.obj);
                setLoading(false);
            }
        }
    }
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setTestResult(undefined);
        setStatus(Status.TEST_DID_NOT_START);
        if (setCheckObjectWasChanged) setCheckObjectWasChanged(CommonTools.generateRandomString(6));
        if(setVerifyLessonEnrChanged)setVerifyLessonEnrChanged(CommonTools.generateRandomString(5));
    };

    const processDialogTitle = () => {
        if (!course) return <>{LL('Test')}</>;
        if (!showTitleLesson) {
            return (<Stack
                direction={'row'}
                spacing={2}
                textAlign="center" 
                justifyContent="center">
                <span>
                    {LL('Test')}
                </span>
                <span>
                    {ComponentCommonTools.processObjectField(course, 'name')}
                </span>
            </Stack>);
        } else if (lesson) {
            return (<Stack
                direction={'row'}
                spacing={2}
                textAlign="center"
                justifyContent="center"
                >
                <span>
                    {LL('Test')}
                </span>
                <span>
                    {ComponentCommonTools.processObjectField(lesson, 'name')}
                </span>
            </Stack>);
        }

    }



    const getList = () => {
        if (!idCourse) return;

        setLoading(true);
        const reqList = new RequestListDTO();
        reqList.onpage = 1
        reqList.page = 1;
        reqList.filters = [];
        reqList.sortcriteria = [];

        let f: RequestFilterDTO;

        f = new RequestFilterDTO();
        f.field = "idcourse";
        f.values = [idCourse];
        reqList.filters.push(f);

        f = new RequestFilterDTO();
        f.field = "type";
        f.values = [type.toString()];
        reqList.filters.push(f);

        if (idLesson) {
            f = new RequestFilterDTO();
            f.field = "idlesson";
            f.values = [idLesson];
            reqList.filters.push(f);
        }


        testService.getList(loadObjects, {}, reqList);
    };

    const loadObjects = (result: ResultListDTO) => {
        if (!result) return;
        if (!result.err) {
            const objects = result.objects ? result.objects : [];
            if (objects.length > 0) setObj(objects[0]);
            else setObj(null);

        }
    }

    const checkLoading = () => {
        let load = false;
        if (!obj) load = true;
        setLoading(load);
    }

    const processContent = () => {
        if (!idCourse) return <></>;
        if (loading) return (<Skeleton variant="rectangular" height={400} />)
        if (!obj) return <></>;

        return (
            <>
            <DialogContent  className="dialogContent alignDialog">

                 <Typography variant="h2" textAlign="center">
                    {ComponentCommonTools.processObjectField(obj, 'name')}
                </Typography>
                <TestStaticContent type={type} />
                {processTestDetail()}
                
            </DialogContent>
            <Box  className="footerContent" p={2} display="flex" justifyContent="end">
            
            <MyButton
                cb={handleStartTest}
                className="btn"
                color="primary"
                variant="contained"
                px={2}
            >
                {LL('Start_Test')}
            </MyButton>
        </Box>
        </>
        );
    }

    const processTestDetail = () => {
        if (!obj) return <></>;
        return (
            <>
                <Stack direction='column' alignItems="center" spacing={1} textAlign="center">
                    <Stack direction='row' spacing={1} divider={<>:</>} textAlign="center">
                        <Typography variant="h6">
                            {LL('Min_Test_Score_To_Pass')}
                        </Typography>
                        <Typography>
                            {ComponentCommonTools.processObjectField(obj, 'mintestscore')}
                        </Typography>
                    </Stack>
                    <Stack direction='row' spacing={1} divider={<>:</>} textAlign="center">
                        <Typography variant="h6">
                            {LL('Max_Test_Score')}
                        </Typography>
                        <Typography>
                            {ComponentCommonTools.processObjectField(obj, 'maxtestscore')}
                        </Typography>
                    </Stack>
                </Stack>

            </>
        );
    }

    const [status, setStatus] = useState<number>(Status.TEST_DID_NOT_START);

    const handleStartTest = () => {
        createTest();
        setStatus(Status.TEST_STARTED);
    }

    const processByStatusTest = () => {
        if (!idCourse) return <></>;
        if (loading) return (<Skeleton variant="rectangular" height={400} />)
        if (!obj) return <></>;
        if (!obj.id) return <></>;
        switch (status) {
            case Status.TEST_DID_NOT_START: {
                return processContent();
            }
            case Status.TEST_STARTED: {
                return (
                    <>
                        <TestQuestionList
                            idTest={obj.id}
                            statusTest={status}
                            setStatusTest={setStatus}
                            testResult={testResult}
                            setTestResult={setTestResult}
                        />
                    </>
                );
            }
            case Status.TEST_FINISHED: {
                return (
                    <DialogContent className="dialogContent alignDialog">
                        <TestResult testResult={testResult} />
                    </DialogContent>
                );
            }
            default: {
                return <></>;
            }
        }
    }

    useEffect(() => {
        getList();
    }, [idCourse, idLesson, type]);

    useEffect(() => {
        checkLoading();
    }, [obj]);

    const processDialog = () => {
        if (!open) return <></>;
        return (
            <Dialog
                fullScreen
                open={open}
                onClose={handleClose}
                TransitionComponent={Transition} className="appTextForm">
                <AppBar  sx={{ position: 'relative', bgcolor:'#fff' }}>
                    <Toolbar>
                        <LogoComp/>
                        <Typography sx={{mx:"auto", flex: 1, bgColor:"red" }} variant="h4" className="h-variant" textAlign="center" >
                            {processDialogTitle()}
                        </Typography>
                        <Button
                            onClick={handleClose}
                            aria-label="close"
                            className="btn-x"
                            ><CloseIcon />{LL('Iesire')}
                        </Button>
                    </Toolbar>
                </AppBar>
                
                    {processByStatusTest()}
                
            </Dialog>
        )
    }

    return (
        <Box >
            <MyButton onClick={handleClickOpen} className="btnAlert" my={1}>
                {LL('Take_Test')}
            </MyButton>
            {processDialog()}
        </Box>
    );
};

export { TestDialog };