import React, { useEffect, useState } from "react";
import PageComponentProps from "interfaces/pagecomponentprops.interface";
import { CommonTools } from "tools/commontools";
import { Box, Skeleton, Stack, Typography, Grid } from "@mui/material";
import { TeacherDto } from "dto/teacher/teacher.dto";
import ResultObjectDTO from "dto/app/resultobject.dto";
import TeacherService from "services/teacher/teacher.service";
import { HtmlTag } from "components/general/HtmlTag";
import { useResource } from "hook/useResource";
import { TeacherInfoBlock } from "./TeacherInfoBlock";
import UserSocialBlock from "components/user/UserSocialBlock";
import { Types } from "tools/types";
import { CourseListByTeacherDisplayBlock } from "components/course/course/CourseListByTeacherDisplayBlock";
import { NewsletterBlock } from "components/general/NewsletterBlock";
import { TeacherTypeIcon } from "./TeacherTypeIcon";
import { TeacherStatistic } from "./TeacherStatistic";
import { MyRatingReadOnly } from "components/elements/MyRating/MyRatingReadOnly";
import { TeacherTestimonials } from "components/testimonial/TeacherTestimonials";
import { ComponentCommonTools } from "tools/componentcommontools";


const teacherService = new TeacherService();

const PublicTeacherProfile: React.FC<PageComponentProps> = ({ currentRoute, ...props }) => {

    const { LL } = useResource();
    const [idTeacher, setIdTeacher] = useState("");
    const [obj, setObj] = useState<TeacherDto | undefined>();
    useEffect(() => {
        setIdTeacher(CommonTools.getIdFromPath(currentRoute, 'teacher'));

    }, [currentRoute]);




    const processTeacherInfo = () => {
        if (!idTeacher) return <></>;

        return (
            <Box className="aboutTeacher">
                <TeacherInfoBlock id={idTeacher} showSocials={false} setCurrentObject={setObj} />
            </Box>
        )
    }

    const processVideoPresentation = () => {
        if (!obj) return <></>;
        if (!obj.hasOwnProperty("videopresentation")) return <></>;
        if (!obj.videopresentation) return <></>;
        return (<Box mb={2}>
            <Typography variant="h5" my={3}>{LL('VideoPresentation')}</Typography>
            {ComponentCommonTools.getFileBlockVideo(obj.videopresentation,'100%')}
        </Box>)
    }

    const processVideoLessonExample = () => {
        if (!obj) return <></>;
        if (!obj.hasOwnProperty("videolessonexample")) return <></>;
        if (!obj.videolessonexample) return <></>;
        return (<>
            <Typography variant='h2' mb={3} mt={5}>{LL('VideoLessonExample')}</Typography>
            {ComponentCommonTools.getFileBlockVideo(obj.videolessonexample)}
        </>)
    }

    const processTeacherStatistic = () => {
        if (!obj) return <></>;
        if (!obj.id) return <></>;
        return (
            <>
                <Typography variant="h2" my={4}>{LL('Coursant review teacher')}</Typography>
                <Stack direction={{sm:'column', md:"row" }} spacing={2} alignItems='center'>
                    {processRatingForStatistic()}
                    <Box sx={{width:'100%'}}><TeacherStatistic idTeacher={obj.id} /></Box>
                </Stack> 
            </>
        )
    }

    const processUserSocials = () => {
        if (!obj) return <></>;
        if (!obj.hasOwnProperty('iduser')) return <></>;
        if (!obj.iduser) return <></>;
        return (<UserSocialBlock idUser={obj.iduser} type={Types.TYPE_SOCIAL_DISPLAY_LONG_FORM} />)
    }

    const processTeacherCourses = () => {
        if (!idTeacher) return <></>;
        return (<>
            <Typography variant="h2" my={3}>{LL('Cursurile mele Teacher')}</Typography>
            <CourseListByTeacherDisplayBlock idTeacher={idTeacher} />
        </>);

    }

    const processRatingForStatistic = () => {
        if (!obj) return <></>;
        if (!obj.hasOwnProperty('rating')) return <MyRatingReadOnly rating={0} showDetails={false} />
        if (!obj.rating) return <MyRatingReadOnly rating={0} showDetails={false} />
        return (
            <Stack direction='column'>
                <Typography color='gold' className="ratingNumberSize" textAlign='center'> {CommonTools.roundToTenth(obj.rating)}</Typography>
                <MyRatingReadOnly rating={obj.rating} showDetails={false} />
                <Typography textAlign='center' className="ratingName" mt={1}>{LL('Rating_Teacher')}</Typography>
            </Stack>
        )
    }

    const processTeacherTestimonial = () => {
        if (!obj) return <></>;
        if (!obj.id) return <></>;

        return (<>
            <TeacherTestimonials idTeacher={obj.id} />
        </>)
    }

    return idTeacher ?
        (<>
            <Box className="container teacherProfile">
                <Grid container direction='row' py={5} spacing={2}>
                    <Grid item sm={12} md={6}>{processTeacherInfo()}</Grid>
                    <Grid item sm={12} md={1}></Grid>
                    <Grid item sm={12} md={5} alignItems='center' alignContent='center'>
                        {processVideoPresentation()}
                        {processUserSocials()}
                        
                    </Grid>
                </Grid>
                
                {processTeacherCourses()}
                <Grid container mb={5}>
                    <Grid item sm={12} md={7}>
                        {processTeacherStatistic()}
                        {processTeacherTestimonial()}
                        {processVideoLessonExample()}
                    </Grid>
                </Grid>
                
            </Box>
            <NewsletterBlock currentRoute={currentRoute} />
        </>) : (<></>);
};

export { PublicTeacherProfile };
