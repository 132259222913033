import React, { useState, useEffect } from "react";

import RequestListDTO from "dto/app/requestlist.dto";
import { CommonTools } from "tools/commontools";
import { useResource } from "hook/useResource";

import TicketService from "services/support/ticket.service";
import RequestFilterDTO from "dto/app/requestfilter.dto";
import { Status } from "tools/status";
import { RouteTools } from "tools/routetools";
import ResultListDTO from "dto/app/resultlist.dto";
import {
    Box,
    Grid,
    List,
    ListItem,
    ListItemButton,
    ListItemText,
    Skeleton,
    Typography
} from "@mui/material";

import { ComponentCommonTools } from "tools/componentcommontools";
import { TicketDto } from "dto/support/ticket.dto";
import { useAuth } from "hook/useAuth";
import { Types } from "tools/types";
import RequestSortCriteriaDTO from "dto/app/requestsortcriteria.dto";
import { TicketItemMenu } from "./TicketItemMenu";
import { LoadMoreButton } from "components/elements/MyButton/LoadMoreButton";


const ticketService = new TicketService();

type TicketMessageListProps = {
    selectedTicket?: TicketDto;
    setSelectedTicket: any;
    userObject: any;
    userObjectField:string;
    userType: number;
    total: number;
    setTotal: any;
    objects: Array<TicketDto>;
    setObjects: any;
    setIndex: any;
}
const TicketListMenu: React.FC<TicketMessageListProps> = ({
    selectedTicket,
    setSelectedTicket,
    userObject,
    userType,
    userObjectField,
    total,
    setTotal,
    objects,
    setObjects,
    setIndex,
    ...props }) => {

    const { LL, CC } = useResource();

    const { student } = useAuth();
    const [isLoading, setIsLoading] = useState(true);
    // const [objects, setObjects] = useState<Array<TicketDto>>([]);

    const [page, setPage] = useState<number>(1);
    const [totalPage, setTotalPage] = useState<number>(-1);
    const [responseParams, setResponseParams] = useState<any>({});


    const [onPage, setOnPage] = useState<number>(parseInt(CC("PAGINATION_ON_PAGE_Ticket", '10')));
    const [reqList, setReqList] = useState<RequestListDTO>(new RequestListDTO());



    const getList = () => {
        if (!userObject) return;
        if (!userObject.hasOwnProperty('id')) return;
        if (!userObject.id) return;

        reqList.onpage = onPage;
        reqList.page = page;
        reqList.filters = [];

        let f: RequestFilterDTO;

        f = new RequestFilterDTO();
        f.field = userObjectField;
        f.values = [userObject.id];
        reqList.filters.push(f);

        f = new RequestFilterDTO();
        f.field = "type";
        f.values = [userType.toString()];
        reqList.filters.push(f);

        const sort = new RequestSortCriteriaDTO();
        sort.field = 'date';
        sort.asc = false;

        reqList.sortcriteria = [sort];


        setIsLoading(true);
        ticketService.getList(loadObjects, {}, reqList);
    };


    const loadObjects = (result: ResultListDTO) => {
        if (!result) return;
        if (!result.err) {
            const objects = result.objects ? result.objects : [];
            const total = result.total !== undefined ? result.total : -1;
            const totalPage = result.totalpages !== undefined ? result.totalpages : -1;
            const reqInfo = result.requestinfo ? result.requestinfo : {};
            setObjects(objects);
            setTotal(total);
            setTotalPage(totalPage);
            setResponseParams(reqInfo);
        }
    }

    const checkLoading = () => {
        let load = false;

        if (total === -1) return true;
        if (totalPage === -1) return true;
        if (!objects) load = true;
        // if (objects.length === 0) load = true;
        if (Object.keys(responseParams).length === 0) return true;

        setIsLoading(load);
    }

    useEffect(() => {
        getList();
    }, [student, onPage]);

    useEffect(() => {
        checkLoading();
    }, [objects]);

    const processList = () => {
        if (isLoading) return <Skeleton variant="rectangular" />
        if (!objects.length) return <></>;

        return (
            <List>
                {objects.map((item: TicketDto, i: number) => {
                    return processItem(item, i);
                })}
            </List>
        );
    };
    const processLoadMoreObjects = () => {
        if (!objects || !objects.length) return <></>;
        return <LoadMoreButton
            objects={objects}
            total={total} onPage={onPage}
            setOnPage={setOnPage}
            step={parseInt(CC('DefaultOnPageTicketList', 4))}
            className="loadMorebtn"
        />
    }

    const processItem = (obj: TicketDto, index: number) => {
        if (!obj) return <></>;

        return (
            <ListItem key={index}>
                <TicketItemMenu
                    obj={obj}
                    selectedTicket={selectedTicket}
                    setSelectedTicket={setSelectedTicket}
                    setIndex={setIndex}
                    index={index}
                />
            </ListItem>);

    };



    const processComponent = () => {
        return (
            <Box>
                {processList()}
                {processLoadMoreObjects()}
            </Box>

        );
    }

    return processComponent();
};

export { TicketListMenu };
