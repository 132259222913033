
import Idto from 'interfaces/idto.interface';

export default class PhoneDto implements Idto {
    id?: string;
    phonenumber?: string;
    countrycode?: string;
    status?: number;
    activationcode?: string;
    idtypephone?: string;
    typephone?: any;
    iduser?: string;

}
