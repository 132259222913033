
import Idto from 'interfaces/idto.interface';

export class PostLocalityDto implements Idto {

    status?: number;
    ordercriteria?: number;
    idlanguage?: string;
    idlocality?: string;
    idparent?: string;
    idtypelocality?: string;
    name?: string;
    idcountry?: string;
}

export class LocalityDto implements Idto {
    
    id?: string;
    status?: number;
    ordercriteria?: number;
    idlanguage?: string;
    idlocality?: string;
    idcountry?: string;
    language?: any;
    code?: string;
    level?: number;
    idparent?: string;
    idtypelocality?: string;
    name?: string;
    status_name?: string;
    country?: any;
    typelocality?: any;
}