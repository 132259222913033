import React, { useState, useEffect } from "react";
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Skeleton from '@mui/material/Skeleton';
import { CourseDto } from 'dto/course/course.dto'
import { MyButton } from "components/general/MyButton";
import { CommonTools } from 'tools/commontools';
import { ComponentCommonTools } from 'tools/componentcommontools'
import { useResource } from "hook/useResource";
import { RouteTools } from "tools/routetools";
import { Box, Stack, Divider, Grid } from "@mui/material";
import { TeacherProcessNameCourse } from "components/teacher/TeacherProcessNameCourse";
import { logger } from "hoc/logger";
import { TypeCourseCardChip } from "./TypeCourseCardChip";
import { MyNavBt } from "components/general/MyNavBt";
import { WishlistIconChecker } from "components/wishlist/WishlistIconChecker";
import { CourseCardPriceContent } from "components/elements/courseelements/CourseCardPriceContent";

type CourseCardForStudentProps = {
    obj: CourseDto;
    loading: boolean;
    withWishlist?: boolean;
    onChangeSubscribe?:any
}

const CourseCardForStudent: React.FC<CourseCardForStudentProps> = ({ obj, 
    loading,
    withWishlist =false, 
    onChangeSubscribe,
    ...props }) => {

    const { LL,CC } = useResource();
    const [imgUrl, setImgUrl] = useState<string>("");
    const [fullUrl, setFullUrl] = useState<string>("");

    const goCourseDetails = () => {
        if (!obj) return;
        if (!obj.id) return;
        if (!obj.hasOwnProperty('fullurl')) return;
        if (!obj.fullurl) return;
        const url = obj.fullurl;
        RouteTools.setHistory(url, {})
    }

    const processWishlistButton = () => {
        if(!withWishlist) return <></>;
        if(!obj) return <></>;
        if(!obj.hasOwnProperty('id')) return <></>;
        if(!obj.id) return <></>;

        return (
            <Box sx={{bottom:'10px', right:'10px', position:'absolute'}}>
                
            <WishlistIconChecker isForCard={true}  idCourse={obj.id} onChangeSubscribe={onChangeSubscribe}/>
            </Box>
        )
    }

    const prepareGallery = () => {
        if (!obj) return;
        if (!obj.hasOwnProperty('gallery')) return;
        if (!obj.gallery) return;
        if (obj.gallery.length === 0) return;
        const galleryObj = CommonTools.getGalleryObjectLogo(obj.gallery);
        if (!galleryObj) return;
        CommonTools.setFileUrlsGallery(galleryObj, setImgUrl, setFullUrl, 310, 180);
    }

    useEffect(() => {
        prepareGallery();
    }, [obj])

    const processImage = () => {
        if (!obj) return <></>;
        if (!imgUrl) return <></>;

        return (
            <CardMedia
                sx={{ height: 180 }}
                image={imgUrl}
                title='' >
                {processCourseType()}
                {processWishlistButton()}
            </CardMedia>
        )
    }

    const processTeacher = () => {
        if (!obj) return <></>;
        if (!obj.hasOwnProperty('teacher')) return <></>;
        if (!obj.teacher) return <></>;

        return (
            <Box>
                <TeacherProcessNameCourse obj={obj.teacher} />
            </Box>
        )
    }

    const processCourseType = () => {
        if (!obj) return <></>;
        if (!obj.hasOwnProperty('coursesettings')) return <></>;
        if (!obj.coursesettings) return <></>;
        return <Box p={1}><TypeCourseCardChip obj={obj.coursesettings} width={14} height={14} /></Box>
    }

    const processCoursePrice = () => {
        if (!obj) return <></>;
        return <CourseCardPriceContent obj={obj} />;
  
}
    const processCourseFilters = () => {
        if (!obj) return <></>;
        if (!obj.hasOwnProperty('coursefiltermain')) return <></>;
        if (!obj.coursefiltermain) return <></>;
        if (obj.coursefiltermain.length === 0) return <></>;
        return (<Stack direction='column'>
            {obj.coursefiltermain.map((item, index) => {
                return (<Typography key={index}>

                    <Typography component='span' className="lp">
                        {ComponentCommonTools.processObjectFieldOfObject(item, 'filter', 'name')}
                    </Typography>
                    <Typography p={1} component='span' className="rp">
                        {ComponentCommonTools.processObjectFieldOfObject(item, 'filterdictionary', 'name')}
                        {ComponentCommonTools.processObjectField(item, 'value')}
                    </Typography>
                </Typography>)
            })}
        </Stack>)
    }

    const processCourseCategories = () => {
        if (!obj) return <></>;
        if (!obj.hasOwnProperty('linkcoursecategory')) return <></>;
        if (!obj.linkcoursecategory) return <></>;
        if (obj.linkcoursecategory.length === 0) return <></>;
        const limitNumberOfElements = parseInt(CC('COURSECARDCATEGORIESLIMIT','3'));
        return (<Stack direction='column'>
            {obj.linkcoursecategory.map((item, index) => {
                if (index >= limitNumberOfElements) return <></>;
                return (<Box key={index} className="detailsGeneral">

                    {ComponentCommonTools.processObjectFieldOfObject(item, 'coursecategory', 'name')}

                </Box>)
            })}
        </Stack>)
    }

    const processCourseDetails = () => {
        if (!obj) return <></>;

        return (
            <Box>
                <Stack direction='row'
                    justifyContent="space-between"
                    alignItems="flex-start"
                    spacing={2}>
                    <Stack direction='column' className="detailsGeneral">
                        <Box  >
                            <Typography component='span' className="lp">
                                {LL('Language course')}
                            </Typography>
                            <Typography p={1} component='span' className="rp">
                                {ComponentCommonTools.processObjectFieldOfObject(obj, 'language', 'name')}
                            </Typography>
                        </Box>
                        <Box >
                            <Typography component='span' className="lp">
                                {LL('CourseDuration')}
                            </Typography>
                            <Typography p={1} component='span' className="rp">
                                {ComponentCommonTools.processObjectFieldTimeStamp(obj, 'courseduration')}
                            </Typography>
                        </Box>
                        <Box >
                            <Typography component='span' className="lp">
                                {LL('NumberOfLessons')}
                            </Typography>
                            <Typography p={1} component='span' className="rp">
                                {ComponentCommonTools.processObjectField(obj, 'numberoflessons')}
                            </Typography>
                        </Box>
                    </Stack>
                    <Box className="detailsGeneral" sx={{ textAlign: 'right' }}>{processCourseFilters()}</Box>
                </Stack>

            </Box>
        )
    }

    const processLinkName = () => {

        if (!obj) return <></>;
        if (!obj.hasOwnProperty('fullurl')) return processName();
        if (!obj.fullurl) return processName();
        const url = obj.fullurl;

        return (
            <MyNavBt href={url} className="name" sx={{height:'auto'}}>
                {processName()}
            </MyNavBt>
        )
    }

    const processName = () => {

        if (!obj) return <></>;
        return (<Typography className="name">
            {ComponentCommonTools.processObjectField(obj, 'name')}
        </Typography>)
    }

    const processCourseCardForStudent = () => {
        if (!obj) return <></>;

        return (
            <Box p={1}>
                <Card className="courseCardV">
                    <Box className="imageZone" sx={{position:'relative'}}>{processImage()}</Box>
                    <CardContent>
                        <Grid container direction='row'>
                            <Grid item sm={12} md={7} order={{xs:1, md:1}}>
                                {processLinkName()}
                                {processTeacher()}
                            </Grid>
                            <Grid item mt={2} sm={12} md={7} order={{xs:3, md:3}}>
                                <Stack
                                    direction='row'
                                    justifyContent="space-between"
                                    alignItems="center"
                                    alignContent="center"
                                    spacing={2}>
                                    <MyButton cb={goCourseDetails} variant='contained' className="btn">{LL('Subscribe_to_course')}</MyButton>
                                    {processCoursePrice()}
                                </Stack>
                            </Grid>
                            <Grid item sm={12} md={5}  order={{xs:2, md:2}}>
                                {processCourseDetails()}
                                <Box className="detailsGeneral" mt={1}>
                                    <Typography component='span' className="lp">{LL('CourseCategories')}</Typography>
                                    <Typography component='span' className="rp">{processCourseCategories()}</Typography>
                                </Box>
                            </Grid>

                        </Grid>

                    </CardContent>
                </Card>
            </Box>
        )
    }

    return loading ? <Skeleton variant="rectangular" /> : processCourseCardForStudent();
};

export { CourseCardForStudent };
