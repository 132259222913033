import React, { useEffect, useState, useRef } from "react";
import { useResource } from "hook/useResource";
import { logger } from "hoc/logger";
import {
    IconButton,
    Stack,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Typography,
    Box

} from "@mui/material";
import MyTextField from 'components/form/mytextfield';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import { useAuth } from "hook/useAuth";


import ValidateTools from "tools/validate.tools";
import RequiredValidator from "validators/required.validator";

import { MyButton } from "components/general/MyButton";

import { Types } from "tools/types";
import TicketService from "services/support/ticket.service";
import ResultObjectDTO from "dto/app/resultobject.dto";
import { TicketDto } from "dto/support/ticket.dto";
import { Status } from "tools/status";
import { CommonTools } from "tools/commontools";
import { StatusSelect } from "components/elements/Select/StatusSelect";
import { ResponseTicketStudentDto } from "dto/support/ticketmessage.dto";
import TicketMessageService from "services/support/ticketmessage.service";

type StudentResponseFormProps = {
    ticket: TicketDto;
    setObjWasChanged: any;
    objWasChanged: string;
    setTicketObjects: any;
    ticketObjects: Array<TicketDto>;
    index: number;

}
const ticketMessageService = new TicketMessageService();
const StudentResponseForm: React.FC<StudentResponseFormProps> = ({
    ticket,
    setObjWasChanged,
    objWasChanged,
    setTicketObjects,
    ticketObjects,
    index,
    ...props
}) => {

    const { LL } = useResource();

    const [obj, setObj] = useState<ResponseTicketStudentDto>(new ResponseTicketStudentDto());
    const [validators, setValidators] = useState(false);
    const [vresults, setVResults] = useState<any>(false);
    const [isFormDisabled, setIsFormDisabled] = useState(true);
    const [loading, setLoading] = useState<boolean>(true);

    const validateAll = () => {
        if (!obj) return;
        if (!ticket) return;


        obj.message = obj.message !== undefined ? obj.message : '';

        setObj(obj);
        validateField('message', obj.message);
    };


    const processValidators = () => {
        setLoading(true);
        let v: any = {
            message: [],
        };

        v.message.push(new RequiredValidator('', ''));
        setValidators(v);
    };

    useEffect(() => {
        processValidators();
        validateAll();
    }, [ticket, objWasChanged]);

    const checkLoading = () => {

        if (!validators) return;
        if (!ticket) return;

        setLoading(false);
    };
    useEffect(() => {
        checkLoading();
    }, [validators]);

    useEffect(() => {
        checkIsFormDisabled();
    }, [
        vresults,
    ]);



    const validateField = (field: any, value: any, v?: any) => {
        v = (v) ? v : JSON.parse(JSON.stringify(vresults));
        if (v === false) v = {};
        v[field] = ValidateTools.validate(field, value, validators, vresults);
        setVResults(v);
        return v;
    };

    const setObjField = (field: any, value: any) => {
        let t = JSON.parse(JSON.stringify(obj));
        t[field] = value;
        setObj(t);

        validateField(field, value);
    };
    const checkIsFormDisabled = () => {
        let rez = ValidateTools.vForm(vresults, 'message');


        setIsFormDisabled(rez);
    };

    const handleSubmit = (event: any) => {
        event.preventDefault();
        if (isFormDisabled) return false;

        if (!obj) return;
        if (!ticket) return;
        if (!ticket.hasOwnProperty('id')) return;
        if (!ticket.id) return;
        if (!ticket.hasOwnProperty('idstudent')) return;
        if (!ticket.idstudent) return;
        obj.idstudent = ticket.idstudent;
        obj.idticket = ticket.id;
        obj.typesender = Types.TYPE_SENDER_STUDENT;
        obj.status = Status.UNREAD;

        setLoading(true);
        ticketMessageService.responseByStudent(handleResult, {}, obj);
    };

    const handleResult = (result: ResultObjectDTO, cbparams: any) => {
        if (!result) return;
        if (!result.err) {
            const obj = result.obj ? result.obj : {};
            updateObjectAtIndex(index, obj);
            setObjWasChanged(CommonTools.generateRandomString(5))
            setLoading(false);
            setObj(new ResponseTicketStudentDto());
        }
    }

    const updateObjectAtIndex = (index: number, newObj: TicketDto) => {
        if(index === -1) return;
        const updatedArray = [...ticketObjects];
        updatedArray.splice(index, 1, { ...updatedArray[index], ...newObj });

        setTicketObjects(updatedArray);
    };

    const processComponent = () => {

        if (!obj) return <></>;
        if (loading) return <></>;

        return (
            <Box>
                <form onSubmit={handleSubmit}>


                    <Box mt={3}>

                        <MyTextField
                            tabIndex={2}
                            fullWidth
                            id="message"
                            name="message"
                            minRows={4}
                            color="primary"
                            _label={LL("message")}
                            size="small"
                            value={obj.message}
                            setObj={setObjField}
                            multiline
                            _vresults={vresults && vresults.message ? vresults.message : false}
                        />
                    </Box>


                    <Box mt={3}>
                        <MyButton
                            tabIndex={1}
                            disabled={isFormDisabled}
                            color="primary"
                            variant="contained"
                            size="large"
                            type="submit"
                            className="btn"
                            children={LL("Bt_Submit")}
                        />
                    </Box>
                </form>
            </Box>
        )
    }


    return processComponent();

}

export { StudentResponseForm };