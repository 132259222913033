import React, { useState, useEffect } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import CircularProgress from "@mui/material/CircularProgress";
import CourseCategoryService from "services/course/coursecategory.service";
import { useResource } from "hook/useResource";
import RequestFilterDTO from "dto/app/requestfilter.dto";
import { logger } from "hoc/logger";

const service = new CourseCategoryService();

interface CourseCategoryMultiAutocompleteProps {
    obj?: any;
    setObj: any;
    _defaultValue?: any;
}

const CourseCategoryMultiAutocomplete: React.FC<CourseCategoryMultiAutocompleteProps> = ({
    obj,
    setObj,
    _defaultValue,
    ...props
}) => {

    const { LL } = useResource();

    const idField = 'id';
    const objField = 'idscoursecategory';
    const searchValueField = 'searchvalue';
    const labelShowField = 'fullname';
    const labelText = LL('Autocomplete_CourseCategory');
    const [loadOnEmpty] = useState<any>(false);


    const [objects, setObjects] = useState<any>([]);
    const [objectIds, setObjectIds] = useState<Array<string>>([]);
    const [value, setValue] = useState<any>([]);

    const [search, setSearch] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(false);
    const [isLoaded, setIsLoaded] = useState<boolean>(false);


    const [open, setOpen] = useState<boolean>(false);
    const [options, setOptions] = useState<any>([{}]);

    const [label] = useState<string>(labelText);
    const onPage = 20;

    const [reqList] = useState<any>(service.getDefaultRequestListSelect(onPage));

    const loadData = () => {
         if ((search === undefined || search === undefined)) {
            setOptions([]);
            setLoading(false);
            return;
        }

        const tmp: RequestFilterDTO = new RequestFilterDTO()
        tmp.field = searchValueField;
        tmp.values = [];
        tmp.values.push(search);
        reqList.filters = [];
        reqList.filters.push(tmp);


        getList();
    };

    const getList = () => {
        setLoading(true);
        service.getList(loadObjects, {}, reqList);
    };
    const loadObjects = (data: any) => {
        
        if(!data) setOptions([]);
        
        setOptions(data.objects);
        setLoading(false);
    };

    useEffect(() => {
        if (_defaultValue) {
            setValue(Array.isArray(_defaultValue) ? _defaultValue : [_defaultValue]);
        }
    }, [_defaultValue]);

    useEffect(() => {
        if (objects !== null && objects !== undefined) {
            setValue(objects);
        } else {
            setValue([]);
        }
    }, [objects]);

    useEffect(() => {
        if (isLoaded) return;
        setIsLoaded(true);
    }, [options]);

    useEffect(() => {
        loadData();
    }, [search]);

    useEffect(() => {
        if (!isLoaded) return;
        if (!obj) return;
        if (!setObj) return;
        if (setObj == undefined) return;

        let _obj = obj;

        if (objects == null || objects == undefined || !objectIds) return;
        _obj[objField] = objectIds;

        setObj(_obj);
    }, [value]);
    const handleChange = (value: any) => {

        const ids = value.map((item: any) => item[idField]);
        setObjects(value);
        setObjectIds(ids);
    };

    useEffect(() => {
        if (!open) {
            setSearch("");
        }
    }, [open]);

    return (
        <Autocomplete
            id="course-category-multi-autocomplete"
            className="form-autocomplete"
            sx={{height:40 }}
            fullWidth
            multiple
            open={open}
            value={value}
            onOpen={() => {
                setOpen(true);
            }}
            onClose={() => {
                setOpen(false);
            }}
            onChange={(event, value) => {

                handleChange(value);

            }}
            isOptionEqualToValue={(option: any, value: any) => {
                return option[idField] === value[idField]
            }}
            getOptionLabel={(option: any) => option[labelShowField] ? option[labelShowField] : ""}
            options={options}
            loading={loading}
            renderInput={(params) => (
                <TextField

                    {...params}
                    label={label}
                    onChange={(event) => {
                        setSearch(event.target.value);
                    }}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <>
                                {loading ? (
                                    <CircularProgress color="inherit" size={20} />
                                ) : null}
                                {params.InputProps.endAdornment}
                            </>
                        ),
                    }}
                />
            )}
        />
    );
}

export { CourseCategoryMultiAutocomplete };