import RequestFilterDTO from "dto/app/requestfilter.dto";
import RequestListDTO from "dto/app/requestlist.dto";
import RequestSortCriteriaDTO from "dto/app/requestsortcriteria.dto";
import ResultListDTO from "dto/app/resultlist.dto";
import ResultObjectDTO from "dto/app/resultobject.dto";
import { CourseDto } from "dto/course/course.dto";
import { LessonDto } from "dto/course/lesson.dto";
import { useAuth } from "hook/useAuth";
import React, { useReducer, useCallback } from "react";
import CourseService from "services/course/course.service";
import LessonService from "services/course/lesson.service";
import { Status } from "tools/status";
import { useResource } from "hook/useResource";
import { logger } from "hoc/logger";
import { CommonTools } from "tools/commontools";
import { RouteTools } from "tools/routetools";
import { VideoDto } from "dto/video/video.dto";
import DateTools from "tools/date.tools";
import { CourseEnrollmentDto } from "dto/course/courseenrollment.dto";
import CourseEnrollmentService from "services/course/courseenrollment.service";

interface ClassroomState {
  lessonObjects: Array<LessonDto> | null;
  selectedLessonObject: LessonDto | null;
  idSelectedLesson: string;
  course: CourseDto | null;
  idCourse: string;
  videoSelected: VideoDto | null;
  idSelectedVideo: string;
  studentObjects: Array<CourseEnrollmentDto> | null;
}

interface ClassroomActions {
  getLessonList: () => void;
  getCourse: (idCourse: string) => void;
  setInitialPaths: (idCourse: string, idLesson: string) => void;
  resetContext: () => void;
  getLessonWhenNoSelectedId: () => void;
  selectLesson: (id: string) => void;
  getLessonWhenSelectedId: () => void;
  selectVideo: (obj: VideoDto) => void;
  getDefaultVideo: () => void;
  setIdVideoSelected: (idSelectedVideo: string) => void;
  updateLessonObject: (obj: LessonDto) => void;
  getStudentList: () => void;
}

export const ClassroomContext = React.createContext({
  state: {} as ClassroomState,
  actions: {} as ClassroomActions,
});

interface ProviderProps {
  children: React.ReactNode;
}

interface Action {
  type: string;
  payload?: any;
}

const reducer = (state: ClassroomState, action: Action) => {
  switch (action.type) {
    case "get_lesson_list": {
      return {
        ...state,
        lessonObjects: action.payload.lessonObjects,
      };
    }
    case "get_course": {
      return {
        ...state,
        course: action.payload.course,
      };
    }
    case "set_initial_paths": {
      return {
        ...state,
        idCourse: action.payload.idCourse,
        idSelectedLesson: action.payload.idSelectedLesson,
      };
    }
    case "set_selected_lesson": {
      return {
        ...state,
        selectedLessonObject: action.payload.selectedLessonObject,
        idSelectedLesson: action.payload.idSelectedLesson,
      };
    }
    case "set_selected_video": {
      return {
        ...state,
        videoSelected: action.payload.videoSelected,
        idSelectedVideo: action.payload.idSelectedVideo,
      };
    }
    case "set_video_id": {
      return {
        ...state,
        idSelectedVideo: action.payload.idSelectedVideo,
      };
    }
    case "get_student_list": {
      return {
        ...state,
        studentObjects: action.payload.studentObjects,
      };
    }
    case "reset_state": {
      return {
        ...state,
        lessonObjects: null,
        selectedLessonObject: null,
        idSelectedLesson: "",
        course: null,
        idCourse: "",
        videoSelected: null,
        idSelectedVideo: "",
        studentObjects:null
      };
    }

    default:
      return state;
  }
};

const lessonService = new LessonService();
const courseService = new CourseService();
const courseEnrollmentService = new CourseEnrollmentService();

export const ClassroomProvider: React.FC<ProviderProps> = ({ children }) => {
  const { CC } = useResource();
  const [state, dispatch] = useReducer(reducer, {
    lessonObjects: null,
    selectedLessonObject: null,
    idSelectedLesson: "",
    course: null,
    idCourse: "",
    videoSelected: null,
    idSelectedVideo: "",
    studentObjects:null
  });

  const handleLoadStudentList = (result: ResultListDTO) => {
    if (!result) return;
    if (result.err) return;
    const objects = result.objects as Array<CourseEnrollmentDto>;
    dispatch({ type: "get_student_list", payload: { studentObjects: objects } });
  }

  const getStudentList = () => {
    if(!state.idCourse) return;
    const reqList: RequestListDTO = new RequestListDTO();

    reqList.onpage = parseInt(
      CC("OnPageClassroomStudentListStudentList", "99999999")
    );
    reqList.page = 1;
    reqList.filters = [];

    let f: RequestFilterDTO;
    f = new RequestFilterDTO();
    f.field = "idcourse";
    f.values = [state.idCourse];
    reqList.filters.push(f);
    
    courseEnrollmentService.getListForClassroom(handleLoadStudentList, {}, reqList);
  };

  const updateLessonObject = (obj: LessonDto) => {
    if (!obj) return;
    if (!state.lessonObjects) return;
    if (!state.lessonObjects.length) return;
    const index = state.lessonObjects.findIndex(
      (item: LessonDto) => item.id === obj.id
    );
    if (index !== -1 && state.lessonObjects[index].ispassed !== obj.ispassed) {
      const newArr = [...state.lessonObjects];
      newArr[index] = obj;
      dispatch({ type: "get_lesson_list", payload: { lessonObjects: newArr } });
    }
  };

  const setIdVideoSelected = (idSelectedVideo: string) => {
    dispatch({ type: "set_video_id", payload: { idSelectedVideo } });
  };

  const getDefaultVideo = () => {
    if (!state.selectedLessonObject) {
      dispatch({
        type: "set_selected_video",
        payload: { videoSelected: null, idSelectedVideo: "" },
      });
      return;
    }
    if (!state.selectedLessonObject.video) {
      dispatch({
        type: "set_selected_video",
        payload: { videoSelected: null, idSelectedVideo: "" },
      });
      return;
    }
    if (!state.selectedLessonObject.video.length) {
      dispatch({
        type: "set_selected_video",
        payload: { videoSelected: null, idSelectedVideo: "" },
      });
      return;
    }
    if (!state.selectedLessonObject.video[0]) {
      dispatch({
        type: "set_selected_video",
        payload: { videoSelected: null, idSelectedVideo: "" },
      });
      return;
    }
    let video = null;
    if (state.idSelectedVideo) {
      video = state.selectedLessonObject.video.find(
        (x: VideoDto) => x.id === state.idSelectedVideo
      );
    }
    if (!video) video = state.selectedLessonObject.video[0];
    dispatch({
      type: "set_selected_video",
      payload: { videoSelected: video, idSelectedVideo: video.id },
    });

    if (video) {
      if (!video.id) return;
      const url = CommonTools.generateUrlResource(
        CommonTools.getClassroomUrl(state.idCourse),
        state.idSelectedLesson,
        "video",
        video.id
      );
      RouteTools.setHistory(url, {});
    }
  };

  const selectVideo = (obj: VideoDto) => {
    if (!obj) return;
    if (!obj.hasOwnProperty("id")) return;
    if (!obj.id) return;
    dispatch({
      type: "set_selected_video",
      payload: {
        videoSelected: obj,
        idSelectedVideo: obj.id,
      },
    });
  };

  const setUrlLesson = (idSelectedLesson: string) => {
    if (!state.idCourse) return;
    logger("getLessonWhenNoSelectedId3", idSelectedLesson);
    const url =
      CommonTools.getClassroomUrl(state.idCourse) + "/" + idSelectedLesson;
    logger("getLessonWhenNoSelectedId4", url);
    RouteTools.setHistory(url, {});
  };

  const selectLesson = (id: string) => {
    if (!state.lessonObjects) return;

    const lesson = state.lessonObjects.find((x: LessonDto) => x.id === id);
    if (!lesson) return;
    const url = CommonTools.getClassroomUrl(state.idCourse) + "/" + lesson.id;
    RouteTools.setHistory(url, {});
    dispatch({
      type: "set_selected_lesson",
      payload: {
        selectedLessonObject: lesson,
        idSelectedLesson: lesson.id,
      },
    });
  };

  const getLessonWhenSelectedId = () => {
    if (!state.idSelectedLesson) return;
    if (!state.lessonObjects) return;
    if (state.lessonObjects.length === 0) return;

    let lesson = state.lessonObjects.find((item: LessonDto) => {
      if (!item) return null;
      if (!item.hasOwnProperty("id")) return null;
      if (!item.id) return null;
      return item.id === state.idSelectedLesson;
    });
    if (!lesson) lesson = state.lessonObjects[0];
    dispatch({
      type: "set_selected_lesson",
      payload: {
        selectedLessonObject: lesson,
        idSelectedLesson: lesson.id,
      },
    });
  };

  const getLessonWhenNoSelectedId = () => {
    if (state.idSelectedLesson) return;
    if (!state.lessonObjects) return;
    if (state.lessonObjects.length === 0) return;
    let lesson = null;
    const now = DateTools.getTimeStamp();
    lesson = state.lessonObjects.find((item: LessonDto) => {
      if (!item) return null;
      if (!item.hasOwnProperty("ispassed")) return null;
      if (!item.hasOwnProperty("startdate")) return null;
      if (!item.startdate) return null;
      return item.ispassed === false && item.startdate > now;
    });
    logger("getLessonWhenNoSelectedId1", lesson);
    if (!lesson) {
      lesson = state.lessonObjects.find((item: LessonDto) => {
        if (!item) return null;
        if (!item.hasOwnProperty("ispassed")) return null;
        return item.ispassed === false;
      });
    }
    logger("getLessonWhenNoSelectedId2", lesson);

    if (!lesson) lesson = state.lessonObjects[0];
    logger("getLessonWhenNoSelectedId2222222222222", lesson);

    dispatch({
      type: "set_selected_lesson",
      payload: {
        selectedLessonObject: lesson,
        idSelectedLesson: lesson.id,
      },
    });
    if (lesson.id) setUrlLesson(lesson.id);
  };

  const resetContext = () => {
    dispatch({ type: "reset_state" });
  };

  const setInitialPaths = (idCourse: string, idLesson: string) => {
    dispatch({
      type: "set_initial_paths",
      payload: { idCourse: idCourse, idSelectedLesson: idLesson },
    });
  };

  const handleGetCourse = (result: ResultObjectDTO) => {
    if (!result) return;
    if (result.err) return;
    const object = result.obj as CourseDto;
    dispatch({ type: "get_course", payload: { course: object } });
  };

  const getCourse = (idCourse: string) => {
    if (!idCourse) return;
    courseService.get(idCourse, handleGetCourse, {});
  };

  const handleGetLessonList = (result: ResultListDTO) => {
    if (!result) return;
    if (result.err) return;
    const objects = result.objects as Array<LessonDto>;
    dispatch({ type: "get_lesson_list", payload: { lessonObjects: objects } });
  };

  const getLessonList = () => {
    if (!state.idCourse) return;

    const requestList: RequestListDTO = new RequestListDTO();
    requestList.page = 1;
    requestList.onpage = parseInt(
      CC("DefaultOnPageClassroomCourseLessonsList", 1000)
    );
    requestList.filters = [];
    const sortCriteria = new RequestSortCriteriaDTO();
    sortCriteria.field = "ordercriteria";
    sortCriteria.asc = true;
    requestList.sortcriteria = [sortCriteria];

    let f: RequestFilterDTO;

    f = new RequestFilterDTO();
    f.field = "status";
    f.values = [];
    f.values.push(Status.ACTIVE.toString());
    requestList.filters.push(f);

    f = new RequestFilterDTO();
    f.field = "idcourse";
    f.values = [state.idCourse];
    requestList.filters.push(f);

    lessonService.getList(handleGetLessonList, {}, requestList);
  };

  return (
    <ClassroomContext.Provider
      value={{
        state: state,
        actions: {
          getLessonList,
          getCourse,
          setInitialPaths,
          resetContext,
          getLessonWhenNoSelectedId,
          selectLesson,
          getLessonWhenSelectedId,
          selectVideo,
          getDefaultVideo,
          setIdVideoSelected,
          updateLessonObject,
          getStudentList
        },
      }}
    >
      {children}
    </ClassroomContext.Provider>
  );
};
