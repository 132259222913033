import React from "react";
import { MyNavBt } from "./MyNavBt";
import { LogoComp } from "./LogoComp";
import PageComponentProps from "interfaces/pagecomponentprops.interface";
import { useResource } from "hook/useResource";
import { ComponentCommonTools } from "tools/componentcommontools";

const TopLogoBlock: React.FC<PageComponentProps> = ({ currentRoute, ...props }) => {

  const {CF} = useResource();
  return <MyNavBt href={"/"}>
    <LogoComp />
    {/* {ComponentCommonTools.getFileBlock(CF("logo"),50,50)}
    {ComponentCommonTools.getFileBlock(CF("logomini"),50,50)} */}
    </MyNavBt>;
};

export { TopLogoBlock };
