import React, { useEffect, useState } from "react";
import PageComponentProps from "interfaces/pagecomponentprops.interface";
import { useResource } from "hook/useResource";
import { logger } from "hoc/logger";
import MenuService from "services/menu/menu.service";
import { MyNavBt } from "components/general/MyNavBt";
import { useAuth } from "hook/useAuth";
import {WishlistIcon} from "components/wishlist/WishlistIcon";
import Box from '@mui/material/Box';

const FavoriteBlock: React.FC<PageComponentProps> = ({
  currentRoute,
  ...props
}) => {
  const { LL } = useResource();
const {user} = useAuth();
  // const {}
  return user ? (
    <WishlistIcon currentRoute={currentRoute}/>
  ) : (
    <></>
  );
};

export { FavoriteBlock };
