import React, { useState, useEffect } from "react";
import PageComponentProps from "interfaces/pagecomponentprops.interface";
import { useResource } from "hook/useResource";
import { GalleryDto, PostGalleryForCourseDto } from "dto/gallery/gallery.dto";
import MyFileField from "components/form/myfilefield";
import { Box, Skeleton, Stack, Typography } from "@mui/material";
import GalleryService from "services/gallery/gallery.service";
import { CommonTools } from "tools/commontools";
import { ComponentCommonTools } from "tools/componentcommontools";
import { MyButton } from "components/general/MyButton";
import { useMessage } from "hook/useMessage";
import RequestListDTO from "dto/app/requestlist.dto";
import RequestFilterDTO from "dto/app/requestfilter.dto";
import ResultListDTO from "dto/app/resultlist.dto";
import { PostVideoForCourseDto, VideoDto } from "dto/video/video.dto";
import MyTextField from "components/form/mytextfield";
import VideoService from "services/video/video.service";
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';

const galleryService = new GalleryService();


const EditCourseGallery: React.FC<PageComponentProps> = ({ currentRoute, ...props }) => {
    const { LL, _idlanguage } = useResource();
    const id = currentRoute._paths !== undefined ? currentRoute._paths[2] : '';
    const { registerDialog } = useMessage();

    const [loadingGallery, setLoadingGallery] = useState<boolean>(true);
    const [postObjGallery, setPostObjGallery] = useState<PostGalleryForCourseDto>(new PostGalleryForCourseDto());
    const [files, setFiles] = useState(null);
    const [isFormDisabledGallery, setIsFormDisabledGallery] = useState(true);
    const [objectHasChanged, setObjectHasChanged] = useState<string>('');


    const setObjFile = (field: any, event: any) => {
        setFiles(event.target.files[0]);
    };
    const validateAll = () => {
        if (!postObjGallery) return;
        setLoadingGallery(true);
        postObjGallery.idparent = id;
        postObjGallery.idlanguage = _idlanguage ?? _idlanguage;
        setPostObjGallery(postObjGallery);
        setLoadingGallery(false);

    };

    useEffect(() => {
        validateAll();
    }, [postObjGallery]);

    useEffect(() => {
        if (files) setIsFormDisabledGallery(false);
        else setIsFormDisabledGallery(true);

    }, [files]);

    const processGalleryInputField = () => {
        if (!postObjGallery) return <></>;
        return (<Box >
            <MyFileField
                tabIndex={1}
                fullWidth
                id="files"
                name="files"
                variant="standard"
                className="form-input"
                color="primary"
                _label={LL("files")}
                size="small"
                setObj={setObjFile}
                _vresults={undefined}
            />
        </Box>);
    }

    const processGalleryDelete = () => {
        if (!gallery) return <></>;
        return (
            <Box>
                <MyButton
                    tabIndex={2}
                    size="large"
                    cb={handleDelete}
                >
                        <DeleteIcon color="secondary"/>
                </MyButton>
            </Box>
        );
    }

    const handleDelete = () => {
        if (!gallery) return;
        registerDialog(LL('Confirm_delete_course_image'),
            LL('Are_you_sure_you_want_to_delete_this_course_image'),
            { cb: handleConfirmDelete }, null);
    }

    const handleConfirmDelete = () => {
        if (!gallery) return;
        if (!gallery.id) return;
        setLoadingGallery(true);
        galleryService.delete(gallery.id, CommonTools.handleSuccessAddAndEditStayOnPage, { setObjectWasChanged: setObjectHasChanged });
    }

    const handleSubmit = (event: any) => {
        event.preventDefault();
        if (isFormDisabledGallery) return false;

        if (postObjGallery) postObjGallery.files = files;
        registerDialog(LL('Confirm_edit_course'),
            LL('Are_you_sure_you_want_to_edit_this_course_editing_will_change_the_status'),
            { cb: handleConfirmAdd }, null);
    };

    const handleConfirmAdd = () => {
        if (postObjGallery && files) {
            setLoadingGallery(true);
            galleryService.addForCourse(
                CommonTools.handleSuccessAddAndEditStayOnPage, { setObjectWasChanged: setObjectHasChanged }, postObjGallery);
        }
    }
    useEffect(() => {

        getList();
        setPostObjGallery(new PostGalleryForCourseDto());
        setFiles(null);

    }, [objectHasChanged]);

    const [gallery, setGallery] = useState<GalleryDto | undefined | null>();
    const [isLoadingGallery, setIsLoadingGallery] = useState<boolean>(true);
    const getList = () => {
        if (!id) return;
        setIsLoadingGallery(true);
        const reqList = new RequestListDTO();
        reqList.onpage = 1;
        reqList.page = 1;
        reqList.filters = [];
        reqList.sortcriteria = undefined;

        let f: RequestFilterDTO;

        f = new RequestFilterDTO();
        f.field = "idparent";
        f.values = [id];
        reqList.filters.push(f);

        galleryService.getList(loadObjects, {}, reqList);

    };
    const loadObjects = (result: ResultListDTO) => {
        if (!result) return;
        if (!result.err) {
            const objects = result.objects ? result.objects : [];
            if (objects.length > 0) setGallery(objects[0]);
            else setGallery(null);
        }
    }

    const checkLoading = () => {
        let load = false;
        if (gallery === undefined) load = true;
        setIsLoadingGallery(load);
        setLoadingGallery(load);
    }

    useEffect(() => {
        checkLoading();
    }, [gallery]);

    const processGalleryImage = () => {
        if (!gallery) return <></>;
        if (isLoadingGallery) return <Skeleton variant="rectangular" height={298} />;
        return (<Box>
            {ComponentCommonTools.getFileBlockImage(gallery.fileInfoObj, 530, 298)}
        </Box>
        )
    }
    const processGalleryItem = () => {
        if (!postObjGallery) return <></>;
        if (loadingGallery) return <Skeleton variant="rectangular" height={298} />;
        if (isLoadingGallery) return <Skeleton variant="rectangular" height={298} />;
        return (
        <>

            <Typography variant="h3" textAlign='center' mb={3}>{LL("Course_Image")}</Typography>
            <Box className="courseGallery">
                <Box>{processGalleryImage()}</Box>
                <Box display='flex' flexDirection='row' alignItems='center' justifyContent='center'mt={3}>
                

                <form onSubmit={handleSubmit}>
                    <Stack direction='row'>
                    {processGalleryInputField()}

                    <MyButton
                        tabIndex={2}
                        disabled={isFormDisabledGallery}
                        type="submit">
                            <SaveIcon />
                    </MyButton>
                    </Stack>
                    
                </form>
                {processGalleryDelete()}
                </Box>
            </Box>
            
            
        </>)
    }


    return processGalleryItem();

};

export { EditCourseGallery };
