import React, { useCallback, useEffect, useRef, useState } from "react";
import { useResource } from "hook/useResource";
import { logger } from "hoc/logger";
import PageComponentProps from "interfaces/pagecomponentprops.interface";
import { RouteTools } from "tools/routetools";
import RequestListDTO from "dto/app/requestlist.dto";
import { Status } from "tools/status";
import RequestFilterDTO from "dto/app/requestfilter.dto";
import ResultListDTO from "dto/app/resultlist.dto";
import { Types } from "tools/types";
import { ComponentCommonTools } from 'tools/componentcommontools'
import { Box, Grid, Stack, Typography, Skeleton } from "@mui/material";
import { MyNavBt } from "components/general/MyNavBt";
import { CommonTools } from "tools/commontools";
import RequestSortCriteriaDTO from "dto/app/requestsortcriteria.dto";
import { MySortSelect } from "components/elements/Select/MySortSelect";
import { MyPagination } from "components/elements/MyPagination/MyPagination";
import { useAuth } from "hook/useAuth";
import { useCookies } from "react-cookie";
import TestResultService from "services/course/testresult.service";
import { CourseByTeacherAutocomplete } from "components/elements/Autocomplete/CourseByTeacherAutocomplete";
import { TestResultDto } from "dto/course/testresult.dto";
import { TestResultItem } from "./TestResultItem";
import { CourseEnrollmentStudentAutocomplete } from "components/elements/Autocomplete/CourseEnrollmentStudentAutocomplete";
import { TestByTeacherAutocomplete } from "components/elements/Autocomplete/TestByTeacherAutocomplete";
import { TestTypeSelectFilter } from "components/elements/Select/TestTypeSelectFilter";
import { LessonByCourseAutocomplete } from "components/elements/Autocomplete/LessonByCourseAutocomplete";
import { TestResultStatusSelectFilter } from "components/elements/Select/TestResultStatusSelectFilter";
import { NoResult } from "components/general/NoResult";

const testResultService = new TestResultService();
const TestResultList: React.FC<PageComponentProps> = ({ currentRoute, ...props }) => {
    const { LL, CC } = useResource();
    const { teacher } = useAuth();
    const mainUrl = currentRoute && currentRoute.url ? currentRoute.url.split("?") : "";
    const [isLoading, setIsLoading] = useState(true);
    const [objects, setObjects] = useState<Array<TestResultDto>>([]);
    const [total, setTotal] = useState<number>(-1);
    const [totalPage, setTotalPage] = useState<number>(-1);
    const [responseParams, setResponseParams] = useState<any>({});
    const [onPage, setOnPage] = useState<number>(parseInt(CC('OnPageTestResultList', '10')));
    const [page, setPage] = useState<number>(1);
    const [sortCriteria, setSortCriteria] = useState<Array<RequestSortCriteriaDTO>>(new Array<RequestSortCriteriaDTO>());
    const [cookies, setCookies] = useCookies();

    const [filterIdCourse, setFilterIdCourse] = useState<string>('');
    const [defaultFilterIdCourse, setDefaultFilterIdCourse] = useState<string>('');

    const [filterIdCourseEnrollment, setFilterIdCourseEnrollment] = useState<string>('');
    const [defaultFilterIdCourseEnrollment, setDefaultFilterIdCourseEnrollment] = useState<string>('');

    const [filterIdTest, setFilterIdTest] = useState<string>('');
    const [defaultFilterIdTest, setDefaultFilterIdTest] = useState<string>('');

    const [testTypeFilter, setTestTypeFilter] = useState<number>(-1);
    const [testResultStatusFilter, setTestResultStatusFilter] = useState<number>(-1);

    const [filterIdLesson, setFilterIdLesson] = useState<string>('');
    const [defaultFilterIdLesson, setDefaultFilterIdLesson] = useState<string>('');


    const getList = () => {
        if (!teacher) return;
        setIsLoading(true);

        const reqList = new RequestListDTO();
        reqList.onpage = onPage
        reqList.page = page;
        reqList.filters = [];
        reqList.sortcriteria = sortCriteria;

        let f: RequestFilterDTO;

        f = new RequestFilterDTO();
        f.field = "idteacher";
        f.values = [teacher.id];
        reqList.filters.push(f);

        if (filterIdCourse) {
            f = new RequestFilterDTO();
            f.field = "idcourse";
            f.values = [filterIdCourse];
            reqList.filters.push(f);
        }

        if (filterIdCourseEnrollment) {
            f = new RequestFilterDTO();
            f.field = "idcourseenrollment";
            f.values = [filterIdCourseEnrollment];
            reqList.filters.push(f);
        }

        if (filterIdTest) {
            f = new RequestFilterDTO();
            f.field = "idtest";
            f.values = [filterIdTest];
            reqList.filters.push(f);
        }

        if (filterIdLesson) {
            f = new RequestFilterDTO();
            f.field = "idlesson";
            f.values = [filterIdLesson];
            reqList.filters.push(f);
        }

        if (testTypeFilter !== -1) {
            f = new RequestFilterDTO();
            f.field = "testtype";
            f.values = [testTypeFilter.toString()];
            reqList.filters.push(f);
        }

        if (testResultStatusFilter !== -1) {
            f = new RequestFilterDTO();
            f.field = "status";
            f.values = [testResultStatusFilter.toString()];
            reqList.filters.push(f);
        }

        const urlPart = RouteTools.prepareListLocation(reqList);

        RouteTools.setHistory(mainUrl[0] + urlPart, {});
        testResultService.getListForTeacher(loadObjects, {}, reqList);
    };
    const processCourseFilter = () => {
        if (!teacher) return <></>;

        return (
            <Box sx={{
                "& label": {
                    display: 'none',
                },
            }}>
                <CourseByTeacherAutocomplete
                    valueFilter={filterIdCourse}
                    setValueFilter={setFilterIdCourse}
                    idTeacher={teacher.id}
                    _defaultValue={defaultFilterIdCourse}
                />
            </Box>

        )

    }

    const processStudentFilter = () => {
        if (!teacher) return <></>;

        return (
            <Box sx={{
                "& label": {
                    display: 'none',
                },
            }}>
                <CourseEnrollmentStudentAutocomplete
                    valueFilter={filterIdCourseEnrollment}
                    setValueFilter={setFilterIdCourseEnrollment}
                    idTeacher={teacher.id}
                    _defaultValue={defaultFilterIdCourseEnrollment}
                />
            </Box>
        )
    }

    const processTestFilter = () => {
        if (!teacher) return <></>;
        return (
            <Box sx={{
                "& label": {
                    display: 'none',
                },
            }}>
                <TestByTeacherAutocomplete
                    valueFilter={filterIdTest}
                    setValueFilter={setFilterIdTest}
                    idTeacher={teacher.id}
                    _defaultValue={defaultFilterIdTest}
                />
            </Box>
        )
    }

    const processLessonFilter = () => {
        let disabled = true;
        if (filterIdCourse) disabled = false;
        return (
            <Box sx={{
                "& label": {
                    display: 'none',
                },
            }}>
                <LessonByCourseAutocomplete
                    valueFilter={filterIdLesson}
                    setValueFilter={setFilterIdLesson}
                    idCourse={filterIdCourse}
                    _defaultValue={defaultFilterIdLesson}
                    disabled={disabled}
                />
            </Box>
        )
    }

    const processTestTypeFilter = () => {
        return (
            <Box sx={{
                "& label": {
                    display: 'none',
                },
            }}>
                <TestTypeSelectFilter
                    setValue={setTestTypeFilter}
                    value={testTypeFilter}
                />
            </Box>
        )
    }

    const processTestResultStatusSelectFilter = () => {
        return (
            <Box sx={{
                "& label": {
                    display: 'none',
                },
            }}>
                <TestResultStatusSelectFilter
                    setValue={setTestResultStatusFilter}
                    value={testResultStatusFilter}
                />
            </Box>
        )
    }
    // 
    const loadObjects = (result: ResultListDTO) => {
        if (!result) return;
        if (!result.err) {
            const objects = result.objects ? result.objects : [];
            const total = result.total !== undefined ? result.total : -1;
            const totalPage = result.totalpages !== undefined ? result.totalpages : -1;
            const reqInfo = result.requestinfo ? result.requestinfo : {};
            setObjects(objects);
            setTotal(total);
            setTotalPage(totalPage);
            setResponseParams(reqInfo);
        }
    }

    const checkLoading = () => {
        let load = false;

        if (total === -1) return true;
        if (totalPage === -1) return true;
        if (!objects) load = true;
        if (Object.keys(responseParams).length === 0) return true;

        setIsLoading(load);
    }

    const defaultSortCriteria = () => {
        if (!sortCriteria || sortCriteria.length === 0) {
            const sortCriteria = new RequestSortCriteriaDTO();
            sortCriteria.field = "date";
            sortCriteria.asc = false;
            setSortCriteria([sortCriteria]);
        }
    }

    useEffect(() => {
        if (sortCriteria && sortCriteria.length > 0) getList();
    }, [
        sortCriteria,
        onPage,
        page,
        filterIdCourse,
        filterIdCourseEnrollment,
        filterIdTest,
        testTypeFilter,
        filterIdLesson,
        testResultStatusFilter
    ]);

    useEffect(() => {

        const reqListRoute = RouteTools.prepareListRequest(currentRoute, cookies);
        if (reqListRoute.page) setPage(reqListRoute.page);
        if (reqListRoute.filters) {
            if (reqListRoute.filters.length > 0) {
                const filterCourse = reqListRoute.filters.find((f: RequestFilterDTO) =>
                    f.field === "idcourse")
                if (filterCourse) {
                    if (filterCourse.values &&
                        filterCourse.values.length > 0) {
                        setFilterIdCourse(filterCourse.values[0]);
                        setDefaultFilterIdCourse(filterCourse.values[0]);
                    }
                }

                const filterCourseEnrollment = reqListRoute.filters.find((f: RequestFilterDTO) =>
                    f.field === "idcourseenrollment")
                if (filterCourseEnrollment) {
                    if (filterCourseEnrollment.values &&
                        filterCourseEnrollment.values.length > 0) {
                        setFilterIdCourseEnrollment(filterCourseEnrollment.values[0]);
                        setDefaultFilterIdCourseEnrollment(filterCourseEnrollment.values[0]);
                    }
                }

                const filterTest = reqListRoute.filters.find((f: RequestFilterDTO) =>
                    f.field === "idtest")
                if (filterTest) {
                    if (filterTest.values &&
                        filterTest.values.length > 0) {
                        setFilterIdTest(filterTest.values[0]);
                        setDefaultFilterIdTest(filterTest.values[0]);
                    }
                }

                const filterTestType = reqListRoute.filters.find((f: RequestFilterDTO) =>
                    f.field === "testtype")
                if (filterTestType) {
                    if (filterTestType.values &&
                        filterTestType.values.length > 0) {
                        setTestTypeFilter(parseInt(filterTestType.values[0]));
                    }
                }

                const filterTestResultStatus = reqListRoute.filters.find((f: RequestFilterDTO) =>
                    f.field === "status")
                if (filterTestResultStatus) {
                    if (filterTestResultStatus.values &&
                        filterTestResultStatus.values.length > 0) {
                        setTestResultStatusFilter(parseInt(filterTestResultStatus.values[0]));
                    }
                }

                const filterLesson = reqListRoute.filters.find((f: RequestFilterDTO) =>
                    f.field === "idlesson")
                if (filterLesson) {
                    if (filterLesson.values &&
                        filterLesson.values.length > 0) {
                        setFilterIdLesson(filterLesson.values[0]);
                        setDefaultFilterIdLesson(filterLesson.values[0]);
                    }
                }
            }
        }

        if (reqListRoute.sortcriteria && reqListRoute.sortcriteria.length > 0) {
            setSortCriteria(reqListRoute.sortcriteria);
        } else {
            defaultSortCriteria();
        }
    }, []);

    useEffect(() => {
        checkLoading();
    }, [objects]);

    const processItem = (obj: TestResultDto, index: number) => {
        if (!obj) return <></>;
        return (
            <Box
                key={index}
                py={1}
            >
                <TestResultItem
                    obj={obj}
                />
            </Box>
        )
    }
    const processSortSelect = () => {


        const objects = ['date_asc', 'date_desc'];
        return (
            <Box sx={{
                "& label": {
                    display: 'none',
                },
            }}>
                <MySortSelect
                    objects={objects}
                    setSortCriteria={setSortCriteria}
                    sortCriteria={sortCriteria}
                    variant="standard"
                    className="form-select"
                    size='small'


                />
            </Box>
        )
    }

    const processPagination = () => {
        if (totalPage === -1) return <></>;
        if (total === -1) return <></>;
        if (objects.length === 0) return <></>;

        return (
            <Box
                className="container"
                py={4}
                display='flex'
                sx={{ justifyContent: 'center' }}
            >
                <MyPagination
                    page={page}

                    total={total}
                    totalPage={totalPage}
                    setPage={setPage} />
            </Box>);
    }

    const processList = () => {
        if (!objects.length) return <NoResult identifier="testresult" />;


        return (

            <Box>
                {objects.map((item: TestResultDto, i: number) => {
                    return processItem(item, i)
                })}
            </Box>
        );
    };

    return (
        <Box className="container" py={4}>
            <Typography variant="h2" textAlign='center'>{LL("Rezultate teste")}</Typography>
            <Grid container spacing={1} py={3}>
                <Grid item lg={2} sm={12}>{processSortSelect()}</Grid>
                <Grid item lg={2} sm={12}>{processCourseFilter()}</Grid>
                <Grid item lg={2} sm={12}>{processLessonFilter()}</Grid>
                <Grid item lg={2} sm={12}>{processStudentFilter()}</Grid>
                <Grid item lg={2} sm={12}>{processTestFilter()}</Grid>
                <Grid item lg={1} sm={12}>{processTestTypeFilter()}</Grid>
                <Grid item lg={1} sm={12}>{processTestResultStatusSelectFilter()}</Grid>
            </Grid>
            {isLoading ? <Skeleton /> : processList()}
            {isLoading ? <Skeleton /> : processPagination()}
        </Box>

    );
};

export { TestResultList };
