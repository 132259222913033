import React from "react";
import PageComponentProps from "interfaces/pagecomponentprops.interface";
import { StudentNotificationList } from "./StudentNotificationList";

const StudentNotificationBlock: React.FC<PageComponentProps> = ({ currentRoute, ...props }) => {
    return (<>
        <StudentNotificationList currentRoute={currentRoute} />
    </>);
};

export { StudentNotificationBlock };
