
import Idto from 'interfaces/idto.interface';

export class PostMenuDto implements Idto {

    status?: number;
    section?: number;
    ordercriteria?: number;
    hasurl?: boolean;
    url?: string;
    idpage?: string;
    idparent?: string;
    idlanguage?: string;
    idmenu?: string;
    name?: string;
    language?: any;
    status_name?: any;
    section_name?: string;
    haschildren?: boolean;
}

export class MenuDto implements Idto {
  id?: string;
  status?: number;
  section?: number;
  ordercriteria?: number;
  hasurl?: boolean;
  url?: string;
  idpage?: string;
  idparent?: string;
  idlanguage?: string;
  idmenu?: string;
  name?: string;
  language?: any;
  status_name?: string;
  section_name?: string;
  page?: any;
  fullurl?: string;
  haschildren?: boolean;
}