import { Box, LinearProgress, Stack, Typography, Grid } from "@mui/material";
import { RatingRangesDto } from "dto/rating/ratingranges.dto";
import React from "react";
import { MyRatingReadOnly } from "../MyRating/MyRatingReadOnly";

type RatingProgressProps = {
    obj: RatingRangesDto;
};

const RatingProgress: React.FC<RatingProgressProps> = ({ obj, ...props }) => {

    const processProgress = (value: number, percentage: number) => {
        const percentageLocal = Math.round(percentage)
        return (<Grid container direction="row" spacing={1} alignItems='center'>
            <Grid item xs={6} lg={9} sx={{color: 'grey.700' }}>
                <LinearProgress
                    variant="determinate"
                    value={percentageLocal}
                    color="inherit"
                    sx={{borderRadius:'5px', height:'6px'}}
                />
            </Grid>
            <Grid item xs={6} lg={3} sx={{display:'flex'}}>
                <MyRatingReadOnly rating={value} showDetails={false} />
                <Typography color='primary' ml={2}>{percentageLocal}%</Typography>
            </Grid>
        </Grid>)
    }


    const processItem = () => {
        if (!obj) return <></>;
        if (obj.firstrangecount === undefined || obj.firstrange === undefined ||
            obj.secondrangecount === undefined || obj.secondrange === undefined ||
            obj.thirdrangecount === undefined || obj.thirdrange === undefined ||
            obj.fourthrangecount === undefined || obj.fourthrange === undefined ||
            obj.fifthrangecount === undefined || obj.fifthrange === undefined
        ) return <></>;
        return (
            <Box>
                {processProgress(5, obj.fifthrange)}
                {processProgress(4, obj.fourthrange)}
                {processProgress(3, obj.thirdrange)}
                {processProgress(2, obj.secondrange)}
                {processProgress(1, obj.firstrange)}
            </Box>
        )
    }
    return (processItem());
};

export { RatingProgress };
