import { createContext, useState, useEffect } from "react";

import { Helmet } from "react-helmet";
import GeneralRepository from "repositories/general.repository";
import { logger } from "./logger";

export const HeadContext = createContext(); 

export const HeadProvider = ({ children }) => {
  const [_publicUrl, setPublicUrl] = useState("");

  const [_title, setTitle] = useState("");
  const [_favicon, setFavicon] = useState("");
  const [_description, setDescription] = useState("");
  const [_author, setAuthor] = useState("");
  const [_languageCode, setLanguageCode] = useState("");
  const [_revisitAfter, setRevisitAfter] = useState("");
  const [_rating, setRating] = useState("");
  const [_googleVerification, setGoogleVerification] = useState("");
  const [_yandexVerification, setYandexVerification] = useState("");
  const [_canonical, setCanonical] = useState("");
  const [_image, setImage] = useState("");
  const [_manifest, setManifest] = useState("");
  const [_alternates, setAlternates] = useState([]);
  const [_keyword, setKeyword] = useState("");

  useEffect(() => {
    const purl = process.env.REACT_APP_PUBLICURL ? process.env.REACT_APP_PUBLICURL : "";
    const gv = process.env.REACT_APP_GOOGLEVERIFICATION ? process.env.REACT_APP_GOOGLEVERIFICATION : "";
    const yv = process.env.REACT_APP_YANDEXVERIFICATION ? process.env.REACT_APP_YANDEXVERIFICATION : "";
    setPublicUrl(purl);
    setGoogleVerification(gv);
    setYandexVerification(yv);
 
    GeneralRepository.setMetaToolsF(generalSetMeta);
  }, []);

  useEffect(() => {
    if (!_publicUrl) return;

    setFavicon(_publicUrl + "favicon.ico");
    setManifest(_publicUrl + "manifest.json");
  }, [_publicUrl]);

  const customMeta = (n, v, fn, vn) =>
  {
    if (!v) return;
    fn = fn != undefined ? fn : 'name';
    vn = vn != undefined ? vn : "content";
    var props = {};
    props[fn] = n;
    props[vn] = v;
    return <meta {...props} />;
  }
  
  const processAlternateList = () => {
    if (!_alternates) return;
    if (!_alternates.length) return;
      
      
    return (
      <Helmet>
        {_alternates.map((obj, i) => {
          const hl = (obj.hreflang) ? obj.hreflang : '';
          const url = (obj.url) ? obj.url : '';
          return <link rel="alternate" hreflang={hl} href={url} />;
        })}
      </Helmet>
    );
  };
  
  const generalSetMeta = (obj) => {
    logger("generalSetMeta", obj);
    if (obj.title) setTitle(obj.title);
    if (obj.description) setDescription(obj.description);
    if (obj.author) setAuthor(obj.author);
    if (obj.languageCode) setLanguageCode(obj.languageCode);
    if (obj.revisitAfter) setRevisitAfter(obj.revisitAfter);
    if (obj.rating) setRating(obj.rating);
    if (obj.canonical) setCanonical(obj.canonical);
    if (obj.image) setImage(obj.image);
    if (obj.alternates) setAlternates(obj.alternates);
    if (obj.keyword) setKeyword(obj.keyword);
  };

  const value = { generalSetMeta };

  return (
    <HeadContext.Provider value={value}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{_title}</title>
        <meta name="description" content={_description} />
        <meta name="author" content={_author} />
        <meta name="DC.Language" content={_languageCode} />
        <meta name="resource-type" content="document" />
        {customMeta("REVISIT-AFTER", _revisitAfter)}
        {customMeta("RATING", _rating)}
        {customMeta("DC.Title", _title)}

        <meta name="DC.Format" content="text/html" />
        {customMeta("google-site-verification", _googleVerification)}
        {customMeta("yandex-verification", _yandexVerification)}

        {customMeta("og:title", _title, "property")}
        {customMeta("og:description", _description, "property")}
        {customMeta("og:url", _canonical, "property")}
        {customMeta("og:image", _image, "property")}
        {customMeta("og:keyword", _keyword, "property")}

        {customMeta("twitter:title", _title, "property")}
        {customMeta("twitter:description", _description, "property")}
        {customMeta("twitter:url", _canonical, "property")}
        {customMeta("twitter:image", _image, "property")}

        <link rel="canonical" href={_canonical} />
        <link rel="icon" type="image/ico" href={_favicon} />
        <link rel="shortcut icon" href={_favicon} />

        <link rel="manifest" href={_manifest} />

        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="theme-color" content="#000000" />

        
      </Helmet>
      {processAlternateList()}
      {children}
    </HeadContext.Provider>
  );
};
