import React, { useCallback, useEffect, useRef, useState } from "react";
import { useResource } from "hook/useResource";
import { logger } from "hoc/logger";
import CourseService from "services/course/course.service";
import PageComponentProps from "interfaces/pagecomponentprops.interface";
import { CourseDto } from "dto/course/course.dto";
import { RouteTools } from "tools/routetools";
import RequestListDTO from "dto/app/requestlist.dto";
import { Status } from "tools/status";
import RequestFilterDTO from "dto/app/requestfilter.dto";
import ResultListDTO from "dto/app/resultlist.dto";
import { Types } from "tools/types";
import { Loading } from "components/general/Loading";
import Skeleton from '@mui/material/Skeleton';
import { Box, Grid } from "@mui/material";
import ResultObjectDTO from "dto/app/resultobject.dto";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { ComponentCommonTools } from "tools/componentcommontools";
import Stack from '@mui/material/Stack';
import AppBar from '@mui/material/AppBar';
import { MyNavBt } from "components/general/MyNavBt";
import { CourseCard } from "components/course/course/CourseCard";
import { Carousel } from "components/elements/Carousel/Carousel";
import RequestSortCriteriaDTO from "dto/app/requestsortcriteria.dto";
import { MyButton } from "components/general/MyButton";
import { LoadMoreButton } from "components/elements/MyButton/LoadMoreButton";

const courseService = new CourseService();

type CourseListByStudentDisplayBlockProps = {
    idStudent: string;
}

const CourseListByStudentDisplayBlock: React.FC<CourseListByStudentDisplayBlockProps> = ({
    idStudent,
    ...props
}) => {

    const { LL, CC } = useResource();
    const [isLoading, setIsLoading] = useState(true);
    const [objects, setObjects] = useState<Array<CourseDto>>([]);
    const [total, setTotal] = useState<number>(-1);
    const [totalPage, setTotalPage] = useState<number>(-1);
    const [onPage, setOnPage] = useState<number>(parseInt(CC('DefaultOnPageCourseList', 4)));

    // const calculateOnPage = () => {
    //     if(!objects) return;
    //     if(!objects.length) return;
    //     if(total === -1) return;

    //     if(total > onPage) {
    //         let localOnPage = onPage;
    //         localOnPage += parseInt(CC('DefaultOnPageCourseList',4))
    //         setOnPage(localOnPage);
    //     }
    // }

    // const processLoadMoreObjects = () => {
    //     if(!objects || !objects.length) return <></>;
    //     if(total === -1) return <></>;
    //     if(total <= onPage) return <></>;
    //     return (
    //         <Box>
    //             <MyButton cb={calculateOnPage}>
    //                 {LL('Bt_LoadMore')}
    //             </MyButton>
    //         </Box>
    //     )
    // }

    const processLoadMoreObjects = () => {
        if (!objects || !objects.length) return <></>;
        return <LoadMoreButton
        className="loadMorebtn"
            objects={objects}
            total={total} 
            onPage={onPage}
            setOnPage={setOnPage}
            step={parseInt(CC('DefaultOnPageCourseList', 4))}
        />
    }

    const getList = () => {
        const reqList = new RequestListDTO();
        reqList.onpage = onPage;
        reqList.page = 1;
        reqList.filters = [];
        reqList.sortcriteria = [];
        let sortCriteria = new RequestSortCriteriaDTO();
        sortCriteria.field = "popularidentifier";
        sortCriteria.asc = false;
        reqList.sortcriteria.push(sortCriteria);

        let f: RequestFilterDTO;

        f = new RequestFilterDTO();
        f.field = "status";
        f.values = [];
        f.values.push(Status.ACTIVE.toString());
        reqList.filters.push(f);
        f = new RequestFilterDTO();
        f.field = "idstudent";
        f.values = [idStudent];
        reqList.filters.push(f);

        setIsLoading(true);

        courseService.getListByStudent(loadObjects, {}, reqList);

        // setIsLoading(false);
    };

    const loadObjects = (result: ResultListDTO) => {
        if (!result) return;
        if (!result.err) {
            const objects = result.objects ? result.objects : [];
            const total = result.total !== undefined ? result.total : -1;
            const totalPage = result.totalpages !== undefined ? result.totalpages : -1;
            setObjects(objects);
            setTotal(total);
            setTotalPage(totalPage);
        }
    }

    const checkLoading = () => {
        let load = false;

        if (!objects) load = true;
        // if (objects.length === 0) load = true;

        setIsLoading(load);
    }
    useEffect(() => {
        if (!idStudent) return;
        getList();
    }, [idStudent, onPage]);

    useEffect(() => {
        checkLoading();
    }, [objects]);



    const processCourseItem = (obj: CourseDto, index: number) => {


        return (<Grid item sm={12} md={4} lg={3} key={index}>
            <CourseCard obj={obj} loading={isLoading} />
        </Grid>);

    };


    const processCourseList = () => {
        if (!objects.length) return <></>;

        return (
            <>
                {objects.map((item: CourseDto, i: number) => {
                    return processCourseItem(item, i);
                })}
            </>
        );
    };


    return isLoading ? <Skeleton /> : (
        <>
            <Grid container spacing={2} direction="row">
                {processCourseList()}
            </Grid>
            <Box my={3}>{processLoadMoreObjects()}</Box>
        </>);


};

export { CourseListByStudentDisplayBlock };
