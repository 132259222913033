import Idto from "interfaces/idto.interface";
import { Status } from "tools/status";

export class CoursePriceDto implements Idto {
  id?: string;
  idcurrency?: string;
  idtypeprice?: string;
  idpricepolicy?: string;
  userecommendationprice?: boolean;
  idrecommendationprice?: string | null;
  idcourse?: string;
  totalprice?: number;
  priceperlesson?: number;
  teacherpercentage?: number;
  teachertotalprice?: number;
  teachertotalpriceperlesson?: number;
  currency?: any;
  typeprice?: any;
  pricepolicy?: any;
  recommendationprice?: any;
  course?: any;
}

export class AddCoursePriceDto implements Idto {
  idpricepolicy: string;
  idcourse: string;
  userecommendationprice: boolean;
  idrecommendationprice: string;
  constructor(
    idpricepolicy?: string,
    idcourse?: string,
    idrecommendationprice?: string
  ) {
    this.idcourse = idcourse ?? "";
    this.userecommendationprice = true;
    this.idpricepolicy = idpricepolicy ?? "";
    this.idrecommendationprice = idrecommendationprice ?? "";
  }
}
