import React, { useEffect, useState } from "react";
import PageComponentProps from "interfaces/pagecomponentprops.interface";
import { useResource } from "hook/useResource";
import { logger } from "hoc/logger";
import MenuService from "services/menu/menu.service";
import { MyNavBt } from "components/general/MyNavBt";
import { useAuth } from "hook/useAuth";
import { Types } from 'tools/types'
import { MyButton } from 'components/general/MyButton';
import { RouteTools } from "tools/routetools";

const UserInterfaceBlock: React.FC<PageComponentProps> = ({
    currentRoute,
    ...props
}) => {
    const { LL ,isTeacher} = useResource();

    const { user, userInterface, setUserInterface } = useAuth();

    const handleChangeInterface = () => {
        if (userInterface === Types.STUDENT_INTERFACE) {
            setUserInterface(Types.TEACHER_INTERFACE);
            RouteTools.setHistory('/teacherinterface',{});
        }
        if (userInterface === Types.TEACHER_INTERFACE) {
            setUserInterface(Types.STUDENT_INTERFACE);
            RouteTools.setHistory('/',{});
        }
    }
    const processUserInterface = () => {
        const interfaceLabel = Types.LL(userInterface);

        return <MyButton className="typeInterfaceBtn" children={interfaceLabel} cb={handleChangeInterface} />
    }
    return user != undefined && isTeacher() ? (
        <>{processUserInterface()}</>
    ) : (
        <></>
    );
};

export { UserInterfaceBlock };
