import React, { useContext } from "react";

import { useResource } from "hook/useResource";

import {
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  ListItemButton,
  Button,
} from "@mui/material";

import { VideoDto } from "dto/video/video.dto";

import VideoFileIcon from "@mui/icons-material/VideoFile";

import { CommonTools } from "tools/commontools";
import { RouteTools } from "tools/routetools";
import { ClassroomContext } from "context/classroom/classroom.context";

type VideoListLessonStaticClassroomProps = {
  objects: Array<VideoDto>;
  disabled: boolean;
  currentRoute: any;
};
const VideoListLessonStaticClassroom: React.FC<
  VideoListLessonStaticClassroomProps
> = ({
  objects,
  disabled,
  currentRoute,

  ...props
}) => {
  const { LL } = useResource();
  const {
    state: { videoSelected },
    actions: { selectVideo },
  } = useContext(ClassroomContext);
  
  const handleSelect = (item: VideoDto) => {
    if (!item) return;
    selectVideo(item);
    const url = CommonTools.generateUrlResourceLesson(
      currentRoute,
      item,
      "video"
    );
    RouteTools.setHistory(url, {});
  };

  const processSelected = (obj: VideoDto) => {
    if (!videoSelected) return false;
    if (!obj) return false;
    if (obj.id === videoSelected.id) return true;
    return false;
  };

  const processItem = (item: VideoDto, i: number) => {
    if (!item) return <></>;
    if (!item.hasOwnProperty("id")) return <></>;
    if (!item.id) return <></>;
    let name = "";
    if (item.hasOwnProperty("name") && item.name) name = item.name;
    else {
      const index = i + 1;
      name = LL("video") + " " + index.toString();
    }
    return (
      <ListItem key={i} disablePadding>
        <ListItemButton
          component={Button}
          onClick={() => handleSelect(item)}
          disabled={disabled}
          selected={processSelected(item)}
        >
          <ListItemIcon>
            <VideoFileIcon />
          </ListItemIcon>
          <ListItemText primary={name} />
        </ListItemButton>
      </ListItem>
    );
  };
  const processLessonList = () => {
    if (!objects.length) return <></>;

    return (
      <List>
        {objects.map((item: VideoDto, i: number) => {
          return processItem(item, i);
        })}
      </List>
    );
  };
  return processLessonList();
};

export { VideoListLessonStaticClassroom };
