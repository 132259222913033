import { createContext, useState, useEffect, useCallback } from "react";

import { useCookies } from "react-cookie";

import { Loading } from "components/general/Loading";

import { useAuth } from "hook/useAuth";
import LocalStorageTools from "tools/localstorage.tools";
import PermissionService from "services/auth/permission.service";
import LabelService from "services/label/label.service";
import SiteConfigService from "services/siteconfig/siteconfig.service";
import SiteConfigFileService from "services/siteconfig/siteconfigfile.service";
import LangService from "services/lang/lang.service";
import RequestListDTO from "dto/app/requestlist.dto";
import RequestFilterDTO from "dto/app/requestfilter.dto";
import { Status } from "tools/status";
import { CommonTools } from "tools/commontools";
import { RouteTools } from "tools/routetools";
import { PostLabelDto } from "dto/label/postlabel.dto";
import { PostSiteConfigDto } from "dto/app/siteconfig.dto";
import GeneralRepository from "repositories/general.repository";
import { logger } from "./logger";
import { LabelTypes } from "tools/labeltypes";
import { Types } from "tools/types";
import GeneralService from "services/general.service";
import DateTools from "tools/date.tools";
import { PostSiteConfigFileDto } from "dto/app/siteconfigfile.dto";
import { FileTools } from "tools/filetools";
import { Md5 } from "ts-md5";

export const ResourceContext = createContext();

export const ResourceProvider = ({ children }) => {
  const [cookies, setCookie, removeCookie] = useCookies();

  const permissionService: PermissionService = new PermissionService();
  const labelService: LabelService = new LabelService();
  const langService: LangService = new LangService();
  const siteConfigService: SiteConfigService = new SiteConfigService();
  const siteConfigFileService: SiteConfigFileService =
    new SiteConfigFileService();
  // ------------------------------
  const [_labelType, setLabelType] = useState(false);
  // ------------------------------

  // ------------------------------
  var _labelsSaved = [];
  var _labelsTmp = [];
  var _labels = LocalStorageTools.getObject("_llabels");

  var _siteConfigsSaved = [];
  var _siteConfigs = LocalStorageTools.getObject("_lsiteconfigs");

  var _siteConfigFilesSaved = [];
  var _siteConfigFiles = LocalStorageTools.getObject("_lsiteconfigfiles");

  var _localLabelsHash = LocalStorageTools.getValue("_llhash");
  var _localSiteConfigsHash = LocalStorageTools.getValue("_lsiteconfighash");
  var _localSiteConfigFilesHash = LocalStorageTools.getValue(
    "_lsiteconfigfilehash"
  );
  // const [_localLabelsHash, setLocalLabelsHash] = useState("-1");
  const [_serverLabelsHash, setServerLabelsHash] = useState("-1");
  const [_isLabelsLoaded, setIsLabelsLoaded] = useState(false);

  const [_serverSiteConfigsHash, setServerSiteConfigsHash] = useState("-1");
  const [_isSiteConfigsLoaded, setIsSiteConfigsLoaded] = useState(false);

  const [_serverSiteConfigFilesHash, setServerSiteConfigFilesHash] =
    useState("-1");
  const [_isSiteConfigFilesLoaded, setIsSiteConfigFilesLoaded] =
    useState(false);
  // const [_labels, setLabels] = useState([]);
  // var _labels = [];
  // ------------------------------

  const { user, roles, usersettings, updateUserSettings } = useAuth(false);

  const [currentRoute, setCurrentRoute] = useState(false);

  // ------------------------------
  var _permissions = LocalStorageTools.getObject("_lpermissions");
  var _localPermissionsHash = LocalStorageTools.getValue("_lphash");
  // const [_localPermissionsHash, setLocalPermissionsHash] = useState("-1");
  const [_serverPermissionsHash, setServerPermissionsHash] = useState("-1");
  const [_isPermissionsLoaded, setIsPermissionsLoaded] = useState(false);
  // const [_permissions, setPermissions] = useState(false);

  // ------------------------------

  // ------------------------------
  var _langs = LocalStorageTools.getObject("_llangs");
  var _localLangsHash = LocalStorageTools.getValue("_llanghash");
  // const [_localLangsHash, setLocalLangsHash] = useState("-1");
  const [_serverLangsHash, setServerLangsHash] = useState("-1");
  const [_isLangsLoaded, setIsLangsLoaded] = useState(false);
  const [langs, setLangs] = useState([]);

  // ------------------------------
  const [_idlanguage, setIdLanguage] = useState(false);
  const [languageCode, setLanguageCode] = useState("");
  // ------------------------------
  const [isLoading, setIsLoading] = useState(true);

  // ----------------------------------------
  const cacheData = {};

  const getCacheIdentifier = (key) => {
    let k = _idlanguage.toString();
    k += "_" + JSON.stringify(user);
    k += "_" + JSON.stringify(key);

    return Md5.hashStr(k);
  };

  const saveCache = (data, key) => {
    const identifier = getCacheIdentifier(key);

    cacheData[identifier] = data;
  };

  const getCache = (key) => {
    const identifier = getCacheIdentifier(key);

    if (cacheData[identifier] != undefined) return cacheData[identifier];

    return false;
  };
  // ----------------------------------------
  const returnLanguageCode = useCallback(() => {
    if (!languageCode) return "";
    else return languageCode;
  }, [languageCode]);

  useEffect(() => {
    GeneralRepository.setLanguageCode(returnLanguageCode);
  }, [returnLanguageCode]);
  // ----------------------------------------
  useEffect(() => {
    GeneralRepository.setIdLanguage(_idlanguage);
    RouteTools.setLL(LL);
    Status.setLL(LL);
    LabelTypes.setLL(LL);
    Types.setLL(LL);
    GeneralService.setLL(LL);
    RouteTools.setHasAccess(hasAccess);
    RouteTools.setCheckUser(checkUser);
    RouteTools.setIsTeacher(isTeacher);
  }, [user, roles, usersettings, currentRoute, isLoading, _idlanguage]);

  // ----------------------------------------
  useEffect(() => {
    // loadLocalPermissionsHash();
    loadServerPermissionsHash();

    // loadLocalLabelsHash();
    loadServerSiteConfigsHash();
    loadServerSiteConfigFilesHash();
    loadServerLabelsHash();

    // loadLocalLangsHash();
    loadServerLangsHash();

    if (!_labelType) {
      let labelType = process.env.REACT_APP_LABEL_TYPE;
      labelType = labelType.toString();
      setLabelType(labelType);
    }
  }, []);

  // ----------------------------------------
  useEffect(() => {
    checkPermissionsHash();
  }, [_localPermissionsHash, _serverPermissionsHash]);

  // const loadLocalPermissionsHash = () => {
  //   const _lphash = LocalStorageTools.getValue("_lphash");
  //   setLocalPermissionsHash(_lphash);
  // };

  const handleLoadServerPermissionsHash = (result, cbparameters, data) => {
    setServerPermissionsHash(result.obj.hash);
  };

  const loadServerPermissionsHash = () => {
    permissionService.getServerPermissionsHash(handleLoadServerPermissionsHash);
  };

  const checkPermissionsHash = () => {
    if (_isPermissionsLoaded) return;

    if (_localPermissionsHash == "-1") return;
    if (_serverPermissionsHash == "-1") return;

    if (_localPermissionsHash == _serverPermissionsHash) {
      loadPermissionsLocal();
    } else {
      loadPermissionsServer();
    }
  };

  const loadPermissionsLocal = () => {
    // const _lpermissions = LocalStorageTools.getObject("_lpermissions");
    // setPermissions(_lpermissions);
  };

  const handleLoadPermissionsServer = (result, cbparameters, data) => {
    _permissions = result.objects;
    LocalStorageTools.saveValue("_lphash", _serverPermissionsHash);
    LocalStorageTools.saveObject("_lpermissions", result.objects);
    // setPermissions(result.objects);
  };

  const loadPermissionsServer = () => {
    permissionService.getList(handleLoadPermissionsServer);
  };

  useEffect(() => {
    if (_isPermissionsLoaded) return;
    if (_permissions !== false) setIsPermissionsLoaded(true);
  }, [_permissions]);

  // ----------------------------------------
  useEffect(() => {
    checkLabelsHash();
  }, [_localLabelsHash, _serverLabelsHash]);

  useEffect(() => {
    checkSiteConfigsHash();
  }, [_localSiteConfigsHash, _serverSiteConfigsHash]);

  useEffect(() => {
    checkSiteConfigFilesHash();
  }, [_localSiteConfigFilesHash, _serverSiteConfigFilesHash]);
  // const loadLocalLabelsHash = () => {
  //   const _llhash = LocalStorageTools.getValue("_llhash");
  //   setLocalLabelsHash(_llhash);
  // };

  const handleLoadServerLabelsHash = (result, cbparameters, data) => {
    setServerLabelsHash(result.obj.hash);
  };

  const loadServerLabelsHash = () => {
    labelService.getServerLabelsHash(handleLoadServerLabelsHash);
  };

  const loadServerSiteConfigsHash = () => {
    siteConfigService.getServerSiteConfigHash(handleLoadServerSiteConfigsHash);
  };

  const loadServerSiteConfigFilesHash = () => {
    siteConfigFileService.getServerSiteConfigFilesHash(
      handleLoadServerSiteConfigFilesHash
    );
  };

  const handleLoadServerSiteConfigFilesHash = (result, cbparameters, data) => {
    setServerSiteConfigFilesHash(result.obj.hash);
  };

  const handleLoadServerSiteConfigsHash = (result, cbparameters, data) => {
    setServerSiteConfigsHash(result.obj.hash);
  };

  const checkLabelsHash = () => {
    if (_isLabelsLoaded) return;

    if (_localLabelsHash == "-1") return;
    if (_serverLabelsHash == "-1") return;

    if (_localLabelsHash == _serverLabelsHash) {
      loadLabelsLocal();
    } else {
      loadLabelsServer();
    }
  };

  const checkSiteConfigsHash = () => {
    if (_isSiteConfigsLoaded) return;

    if (_localSiteConfigsHash == "-1") return;
    if (_serverSiteConfigsHash == "-1") return;

    if (_localSiteConfigsHash == _serverSiteConfigsHash) {
      loadSiteConfigsLocal();
    } else {
      loadSiteConfigsServer();
    }
  };

  const checkSiteConfigFilesHash = () => {
    if (_isSiteConfigFilesLoaded) return;

    if (_localSiteConfigFilesHash == "-1") return;
    if (_serverSiteConfigFilesHash == "-1") return;

    if (_localSiteConfigFilesHash == _serverSiteConfigFilesHash) {
      loadSiteConfigFilesLocal();
    } else {
      loadSiteConfigFilesServer();
    }
  };
  const loadLabelsLocal = () => {
    // const _llabels = LocalStorageTools.getObject("_llabels");
    // _labels  = LocalStorageTools.getObject("_llabels");
    setIsLabelsLoaded(true);
    // setLabels(_llabels);
  };

  const loadSiteConfigsLocal = () => {
    setIsSiteConfigsLoaded(true);
  };

  const loadSiteConfigFilesLocal = () => {
    setIsSiteConfigFilesLoaded(true);
  };

  const handleLoadLabelsServer = (result, cbparameters, data) => {
    _labelsTmp = [..._labelsTmp, ...result.objects];
    // logger("_labelsTmp_labelsTmp_labelsTmp_labelsTmp", _labelsTmp);
    // const _tl = Array.from(_labelsTmp).concat(result.objects);
    // _labelsTmp = _tl;
    if (result.requestinfo.page < result.totalpages) {
      const np = result.requestinfo.page + 1;
      loadLabelsServer(np);
    } else {
      _labels = _labelsTmp;
      LocalStorageTools.saveValue("_llhash", _serverLabelsHash);
      LocalStorageTools.saveObject("_llabels", _labelsTmp);
    }
    // _labels = result.objects;
    // LocalStorageTools.saveValue("_llhash", _serverLabelsHash);
    // LocalStorageTools.saveObject("_llabels", result.objects);
    // setIsLabelsLoaded(true);

    // setLabels(result.objects);
  };

  const loadLabelsServer = (p?: number) => {
    p = p ?? 1;
    var labelType = process.env.REACT_APP_LABEL_TYPE;

    const cbP = {};
    const data = new RequestListDTO();
    data.filters = [];
    data.page = p;
    data.onpage = 100;
    // data.onpage = 9999999;
    const f = new RequestFilterDTO();
    f.field = "type";
    f.values = [];
    f.values.push(labelType.toString());
    f.values.push(LabelTypes.LABEL_MESSAGE.toString());
    f.values.push(LabelTypes.LABEL_VALIDATION.toString());
    data.filters.push(f);

    labelService.getList(handleLoadLabelsServer, cbP, data);
  };

  const handleLoadSiteConfigsServer = (result, cbparameters, data) => {
    _siteConfigs = result.objects;
    LocalStorageTools.saveValue("_lsiteconfighash", _serverSiteConfigsHash);
    LocalStorageTools.saveObject("_lsiteconfigs", result.objects);
    setIsSiteConfigsLoaded(true);
  };

  const handleLoadSiteConfigFilesServer = (result, cbparameters, data) => {
    _siteConfigFiles = result.objects;
    LocalStorageTools.saveValue(
      "_lsiteconfigfilehash",
      _serverSiteConfigFilesHash
    );
    LocalStorageTools.saveObject("_lsiteconfigfiles", result.objects);
    setIsSiteConfigFilesLoaded(true);
  };

  const loadSiteConfigsServer = () => {
    const cbP = {};
    const data = new RequestListDTO();
    data.filters = [];
    data.onpage = 9999999;
    data.page = 1;

    siteConfigService.getList(handleLoadSiteConfigsServer, cbP, data);
  };

  const loadSiteConfigFilesServer = () => {
    const cbP = {};
    const data = new RequestListDTO();
    data.filters = [];
    data.onpage = 9999999;
    data.page = 1;

    siteConfigFileService.getList(handleLoadSiteConfigFilesServer, cbP, data);
  };
  // ----------------------------------------
  useEffect(() => {
    checkLangsHash();
  }, [_localLangsHash, _serverLangsHash]);

  // const loadLocalLangsHash = () => {
  //   const _llanghash = LocalStorageTools.getValue("_llanghash");
  //   setLocalLangsHash(_llanghash);
  // };

  const handleLoadServerLangsHash = (result, cbparameters, data) => {
    setServerLangsHash(result.obj.hash);
  };

  const loadServerLangsHash = () => {
    langService.getServerLangsHash(handleLoadServerLangsHash);
  };

  const checkLangsHash = () => {
    if (_isLangsLoaded) return;

    if (_localLangsHash == "-1") return;
    if (_serverLangsHash == "-1") return;

    if (_localLangsHash == _serverLangsHash) {
      loadLangsLocal();
    } else {
      loadLangsServer();
    }
  };

  const loadLangsLocal = () => {
    const _llangs = LocalStorageTools.getObject("_llangs");
    setIsLangsLoaded(true);
    setLangs(_llangs);
  };

  const handleLoadLangsServer = (result, cbparameters, data) => {
    _langs = result.objects;
    LocalStorageTools.saveValue("_llanghash", _serverLangsHash);
    LocalStorageTools.saveObject("_llangs", result.objects);
    setIsLangsLoaded(true);
    logger("handleLoadLangsServer", result.objects);
    setLangs(result.objects);
  };

  const loadLangsServer = () => {
    const cbP = {};
    const data = new RequestListDTO();
    data.filters = [];
    const f = new RequestFilterDTO();
    f.field = "status";
    f.values = [];
    f.values.push(Status.ACTIVE.toString());
    data.filters.push(f);

    langService.getList(handleLoadLangsServer, cbP, data);
  };

  // ----------------------------------------
  useEffect(() => {
    checkIsLoading();
  }, [
    _isPermissionsLoaded,
    _isLabelsLoaded,
    _isLangsLoaded,
    _labelType,
    _isSiteConfigsLoaded,
    _isSiteConfigFilesLoaded,
  ]);

  const checkIsLoading = () => {
    if (!isLoading) return;

    // if (!_isPermissionsLoaded) return;
    // if (!_isLabelsLoaded) return;
    if (!_isLangsLoaded) return;
    if (!_labelType) return;
    if (!_isSiteConfigsLoaded) return;
    if (!_isSiteConfigFilesLoaded) return;

    setIsLoading(false);
  };

  // ----------------------------------------

  // ----------------------------------------
  useEffect(() => {
    if (!_isLangsLoaded) return;
    processLanguage();
  }, [_isLangsLoaded]);

  const changeLanguage = (idl) => {
    let exp = process.env.REACT_APP_USERCOOKIES_EXPIRE;
    exp = parseInt(exp);
    if (isNaN(exp)) exp = 0;

    setCookie("idlanguage", idl, {
      path: "/",
      expires: DateTools.getDate(exp),
    });
    setIdLanguage(idl);

    updateUserSettings("idlanguage", idl);
  };

  const processLanguage = () => {
    if (
      usersettings != undefined &&
      usersettings != null &&
      usersettings.idlanguage != undefined &&
      usersettings.idlanguage != null &&
      usersettings.idlanguage
    ) {
      setIdLanguage(usersettings.idlanguage);
      return;
    }

    if (cookies.idlanguage) {
      setIdLanguage(cookies.idlanguage);
      return;
    }

    const lobj = getDefalutLanguage();

    if (lobj != null) {
      setIdLanguage(lobj.id);
    }
  };

  const getDefalutLanguage = () => {
    var lcode = process.env.REACT_APP_DEFAULT_LANGUAGE;
    lcode = lcode.toString().toLowerCase();

    var rez = null;
    for (var i in _langs) {
      if (_langs[i].cod2.toString().toLowerCase() != lcode) continue;

      rez = _langs[i];
      break;
    }

    return rez;
  };

  // ----------------------------------------
  const processLanguageCode = useCallback(() => {
    if (!_langs) return;
    if (!_langs.length) return;
    if (!_idlanguage) return;
    const lang = _langs.find((x) => x.id === _idlanguage);
    if (!lang) return;
    if (!lang.hasOwnProperty("cod2")) return;
    if (!lang.cod2) return;
    setLanguageCode(lang.cod2);
  }, [_langs, _idlanguage, _langs.length]);

  useEffect(() => {
    processLanguageCode();
  }, [processLanguageCode]);
  // ----------------------------------------
  const SaveLabel = (identifier, preparedIdentifier, labelType) => {
    if (_labelsSaved.indexOf(identifier) !== -1) return;
    _labelsSaved.push(identifier);

    labelType = labelType != undefined ? labelType : _labelType;

    const obj = new PostLabelDto();
    obj.identifier = preparedIdentifier;
    obj.type = labelType;
    obj.status = Status.ACTIVE;
    obj.value = identifier;
    obj.idlanguage = _idlanguage;
    obj._nonupdate = "1";
    obj.allvalues = [];

    if (!Array.isArray(_labels)) _labels = [];
    _labels.push(obj);

    labelService.add(false, {}, obj);
  };

  const SaveLabelByLang = (
    identifier,
    preparedIdentifier,
    labelType,
    idLang
  ) => {
    if (_labelsSaved.indexOf(identifier) !== -1) return;
    _labelsSaved.push(identifier);

    labelType = labelType != undefined ? labelType : _labelType;
    idLang = idLang != undefined ? idLang : _idlanguage;

    const obj = new PostLabelDto();
    obj.identifier = preparedIdentifier;
    obj.type = labelType;
    obj.status = Status.ACTIVE;
    obj.value = identifier;
    obj.idlanguage = idLang;
    obj._nonupdate = "1";
    obj.allvalues = [];

    if (!Array.isArray(_labels)) _labels = [];
    _labels.push(obj);

    labelService.add(false, {}, obj);
  };

  const SaveSiteConfig = (identifier, value) => {
    if (_siteConfigsSaved.indexOf(identifier) !== -1) return;
    _siteConfigsSaved.push(identifier);

    const obj = new PostSiteConfigDto();
    obj.identifier = CommonTools.prepareLabeldentifier(identifier);
    obj.value = value;
    obj._nonupdate = "1";

    if (!Array.isArray(_siteConfigs)) _siteConfigs = [];
    _siteConfigs.push(obj);

    siteConfigService.add(false, {}, obj);
  };

  const SaveSiteConfigFile = (identifier) => {
    if (_siteConfigFilesSaved.indexOf(identifier) !== -1) return;
    _siteConfigFilesSaved.push(identifier);

    const obj = new PostSiteConfigFileDto();
    obj.identifier = CommonTools.prepareLabeldentifier(identifier);
    obj.idfile = null;
    obj._nonupdate = "1";

    if (!Array.isArray(_siteConfigFiles)) _siteConfigFiles = [];
    _siteConfigFiles.push(obj);

    siteConfigFileService.add(false, {}, obj);
  };

  const LLL = (identifier, idLang, labelType) => {
    labelType = labelType != undefined ? labelType : _labelType;
    idLang = idLang != undefined ? idLang : _idlanguage;

    const preparedIdentifier = CommonTools.prepareLabeldentifier(identifier);

    if (!_idlanguage) return identifier;

    let value = "";
    let founded = false;

    for (var i in _labels) {
      if (!_labels[i]) continue;
      if (_labels[i].identifier != preparedIdentifier) continue;
      if (_labels[i].type.toString() != labelType.toString()) continue;
      founded = true;

      for (var j in _labels[i].allvalues) {
        if (
          _labels[i].allvalues[j].idlanguage.toString() != idLang.toString()
        ) {
          continue;
        }
        value = _labels[i].allvalues[j].value;

        break;
      }

      value = value != "" ? value : _labels[i].value;

      break;
    }

    if (founded) {
      value = value ?? identifier;
      return value;
    }

    SaveLabelByLang(identifier, preparedIdentifier, labelType, idLang);
    return identifier;
  };

  const LL = (identifier, labelType) => {
    labelType = labelType != undefined ? labelType : _labelType;

    const preparedIdentifier = CommonTools.prepareLabeldentifier(identifier);

    if (!_idlanguage) return identifier;

    let value = "";
    let founded = false;

    for (var i in _labels) {
      if (!_labels[i]) continue;
      if (_labels[i].identifier != preparedIdentifier) continue;
      if (_labels[i].type.toString() != labelType.toString()) continue;
      founded = true;

      for (var j in _labels[i].allvalues) {
        if (
          _labels[i].allvalues[j].idlanguage.toString() !=
          _idlanguage.toString()
        ) {
          continue;
        }
        value = _labels[i].allvalues[j].value;

        break;
      }

      value = value != "" ? value : _labels[i].value;

      break;
    }

    if (founded) {
      value = value ?? identifier;
      return value;
    }

    SaveLabel(identifier, preparedIdentifier, labelType);
    return identifier;
  };

  const CC = (identifier, value) => {
    value = value != undefined ? value : "10";

    let valueLocal = "";
    let founded = false;

    for (var i in _siteConfigs) {
      if (!_siteConfigs[i]) continue;
      if (_siteConfigs[i].identifier != identifier) continue;
      founded = true;

      for (var j in _siteConfigs[i].allvalues) {
        valueLocal = _siteConfigs[i].allvalues[j].value;

        break;
      }

      valueLocal = valueLocal != "" ? valueLocal : _siteConfigs[i].value;

      break;
    }

    if (founded) {
      valueLocal = valueLocal ?? valueLocal;
      return valueLocal;
    }

    SaveSiteConfig(identifier, value);
    return value;
  };

  const CF = (identifier) => {
    let valueLocal = null;
    let founded = false;
    // logger('CF Resurces', _siteConfigFiles)
    for (var i in _siteConfigFiles) {
      if (!_siteConfigFiles[i]) continue;
      if (_siteConfigFiles[i].identifier != identifier) continue;
      founded = true;

      for (var j in _siteConfigFiles[i].allvalues) {
        // logger('CF Resurces', j)
        valueLocal = _siteConfigFiles[i].allvalues[j].file;

        break;
      }

      valueLocal = valueLocal != null ? valueLocal : _siteConfigFiles[i].file;

      break;
    }

    if (founded) {
      valueLocal = valueLocal ?? valueLocal;
      // logger('CF Resurces', valueLocal)
      if (valueLocal) {
        if (valueLocal.hasOwnProperty("_id") && valueLocal._id) {
          const url = FileTools.getFileUrl(valueLocal._id);
          if (url) {
            return url;
          }
        }
      }
    }

    SaveSiteConfigFile(identifier);
    const defaultImage = process.env.REACT_APP_URL_NO_IMAGE
      ? process.env.REACT_APP_URL_NO_IMAGE
      : "/images/no-photo.png";
    return defaultImage;
  };

  // ----------------------------------------
  const hasAccess_GetPermission = (key) => {
    if (!_permissions) return false;
    if (!_permissions.length) return false;

    var rez = false;
    for (var i in _permissions) {
      if (_permissions[i].name != key) continue;
      rez = _permissions[i];
      break;
    }

    return rez;
  };

  const hasAccess_CheckRoles = (permissionsObj) => {
    if (!permissionsObj) return true;
    if (!permissionsObj.acceptedroles) return true;
    if (permissionsObj.acceptedroles == undefined) return true;
    if (permissionsObj.acceptedroles == null) return true;
    if (!permissionsObj.acceptedroles.length) return true;

    if (!roles) return false;
    if (!roles.length) return false;

    var rez = false;
    for (var i in permissionsObj.acceptedroles) {
      if (roles.indexOf(permissionsObj.acceptedroles[i]) != -1) {
        rez = true;
        break;
      }
    }

    return rez;
  };

  const hasAccess = (key) => {
    // temporar:
    return true;

    if (!_permissions) return true;

    const permissionsObj = hasAccess_GetPermission(key);
    if (!permissionsObj) return true;

    return hasAccess_CheckRoles(permissionsObj);
  };

  const getCookie = (key) => {
    return cookies[key];
  };
  const setCookieInClass = (key, value) => {
    setCookie(key, value);
  };
  const checkUser = () => {
    if (!user) return false;

    return true;
  };

  const isTeacher = () => {
    const id = process.env.REACT_APP_TEACHER_ROLE_ID ?? "";
    logger("TEACHER_ID", id);
    if (id === "") return false;
    logger("Roles", roles);
    if (!roles) return false;
    if (!Array.isArray(roles)) return false;
    if (roles.length === 0) return false;
    if (roles.includes(id)) return true;

    return false;
  };
  // ----------------------------------------
  const value = {
    LL,
    CC,
    LLL,
    CF,
    currentRoute,
    setCurrentRoute,
    langs,
    _idlanguage,
    changeLanguage,
    saveCache,
    getCache,
    isTeacher,
  };

  return isLoading ? (
    <Loading />
  ) : (
    <ResourceContext.Provider value={value}>
      {children}
    </ResourceContext.Provider>
  );
};
