import React, { useState, useEffect } from "react";
import { useResource } from "hook/useResource";
import { TestDto } from "dto/course/test.dto";
import RequestFilterDTO from "dto/app/requestfilter.dto";
import RequestListDTO from "dto/app/requestlist.dto";
import { Status } from "tools/status";
import TestService from "services/course/test.service";
import ResultListDTO from "dto/app/resultlist.dto";
import { Paper, Box, IconButton, Skeleton, Typography, Stack } from "@mui/material";

import RequestSortCriteriaDTO from "dto/app/requestsortcriteria.dto";
import { MySortSelect } from "components/elements/Select/MySortSelect";
import { MyButton } from "components/general/MyButton";
import { LoadMoreButton } from "components/elements/MyButton/LoadMoreButton";
import AccessTimeIcon from '@mui/icons-material/AccessTime';
// import { EditCourseTestItem } from "./EditCourseTestItem";


import { logger } from "hoc/logger";


import { useMessage } from "hook/useMessage";
import { CommonTools } from "tools/commontools";
import { EditCourseTestItem } from "./EditCourseTestItem";

type EditCourseTestListProps = {
    idCourse: string;
    objectHasChanged: string;
    setObjectsHasChanged: any
}

const testService = new TestService();

const EditCourseTestList: React.FC<EditCourseTestListProps> = ({

    idCourse,
    objectHasChanged,
    setObjectsHasChanged,
    ...props }) => {

    const { LL, CC } = useResource();
    const [isLoading, setIsLoading] = useState(true);
    const [objects, setObjects] = useState<Array<TestDto>>([]);
    const [initialObjects, setInitialObjects] = useState<Array<TestDto>>([]);

    const { registerDialog } = useMessage();



    const getList = () => {

        setIsLoading(true);
        const reqList = new RequestListDTO();
        reqList.onpage = parseInt(CC('DefaultOnPageEditCourseTestList', 200));
        reqList.page = 1;
        reqList.filters = [];
        reqList.sortcriteria = [];

        const sortCriteria = new RequestSortCriteriaDTO();
        sortCriteria.field = "ordercriteria";
        sortCriteria.asc = true;
        reqList.sortcriteria.push(sortCriteria);

        let f: RequestFilterDTO;

        f = new RequestFilterDTO();
        f.field = "idcourse";
        f.values = [idCourse];
        reqList.filters.push(f);


        testService.getList(loadObjects, {}, reqList);
    };


    const loadObjects = (result: ResultListDTO) => {
        if (!result) return;
        if (!result.err) {
            const objects = result.objects ? result.objects : [];
            setObjects(objects);
        }
    }


    const checkLoading = () => {
        let load = false;
        if (!objects) load = true;

        setIsLoading(load);
    }

    useEffect(() => {
        getList();
    }, [idCourse, objectHasChanged]);

    useEffect(() => {
        checkLoading();

    }, [objects]);




    const processTestItem = (obj: TestDto, index: number) => {
        if (!obj) return <></>;
        if (!obj.id) return <></>;

        return (<Box key={index}>
            <EditCourseTestItem
                objectFromList={obj}
                objectHasChanged={objectHasChanged}
                idCourse={idCourse}
                setObjectsHasChanged={setObjectsHasChanged}
            />
        </Box>);

    };


    const processTestList = () => {
        if (!objects.length) return <></>;

        return (
            <>
                {objects.map((item: TestDto, i: number) => {
                    return processTestItem(item, i);
                })}
            </>
        );
    };



    return isLoading ? <Skeleton /> : (
        <>
            {processTestList()}
        </>
    );
};

export { EditCourseTestList };
