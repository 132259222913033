import React,{useContext} from "react";
import { useResource } from "hook/useResource";
import { Alert, Box, Typography, Stack } from "@mui/material";
import { TestDialog } from "components/course/test/TestDialog";
import { Types } from "tools/types";
import { StudentCourseContext } from "context/studentcourse/studentcourse.context";
import { Status } from "tools/status";

const LessonTest: React.FC = () => {
  const { LL } = useResource();

  const {
    state: { idCourse, courseEnrollmentObject, course,lessonEnrollmentObject,selectedLessonObject },
    actions: {
        triggerReloadCourseEnrollment,
        triggerReloadLessonEnrollmentAfterTest
    },
  } = useContext(StudentCourseContext);

  const processComponent = () => {
    if (!courseEnrollmentObject) return <></>;
    if(!selectedLessonObject) return <></>;
    if(!lessonEnrollmentObject) return <></>;
    if(!lessonEnrollmentObject.hasOwnProperty('status')) return <></>;
    if(!lessonEnrollmentObject.status) return <></>;
    if(lessonEnrollmentObject.status !== Status.LESSON_ERR_NEED_TEST) return <></>;
    if(!course) return <></>;
    if(!selectedLessonObject.hasOwnProperty('id')) return <></>;
    if(!selectedLessonObject.id) return <></>;
    if(!lessonEnrollmentObject.hasOwnProperty('idlesson')) return <></>;
    if(!lessonEnrollmentObject.idlesson) return <></>;  
    if(selectedLessonObject.id !== lessonEnrollmentObject.idlesson) return <></>;
    
    
    return (
      <Box p={5}>
        <Stack direction={"row"}>
          <Alert severity="error" sx={{border:1}}>

              {LL("To_finish_the_finish_you_need_to_pass_the_test")}
          </Alert>
          

          <TestDialog
            courseEnrollmentObject={courseEnrollmentObject}
            idCourse={idCourse}
            idLesson={selectedLessonObject.id}
            type={Types.TYPE_TEST_LESSON}
            course={course}
            lesson={selectedLessonObject}
            showTitleLesson={true}
            lessonEnrollment={lessonEnrollmentObject}
            setVerifyLessonEnrChanged={triggerReloadLessonEnrollmentAfterTest}
            setCheckObjectWasChanged={triggerReloadCourseEnrollment}
          />
        </Stack>
      </Box>
    );
  };
  return processComponent();
};

export { LessonTest };
