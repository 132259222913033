import React, { useState, useEffect } from "react";
import { useResource } from "hook/useResource";
import { FilterDto } from "dto/course/filter.dto";
import RequestFilterDTO from "dto/app/requestfilter.dto";
import RequestListDTO from "dto/app/requestlist.dto";
import { Status } from "tools/status";
import FilterService from "services/course/filter.service";
import ResultListDTO from "dto/app/resultlist.dto";
import { Accordion, AccordionDetails, AccordionSummary, Box, IconButton, Skeleton, Typography, Stack, Drawer } from "@mui/material";
import { logger } from "hoc/logger";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ComponentCommonTools } from "tools/componentcommontools";
import { Filter } from "@mui/icons-material";
import { FilterDictionaryItem } from "../filterdictionary/FilterDictionaryItem";
import { CommonTools } from "tools/commontools";
import ClearIcon from '@mui/icons-material/Clear';
import RequestCriteriaDTO from "dto/app/requestcriteria.dto";
import { Types } from "tools/types";
import { FilterTypeRange } from 'components/course/filter/FilterTypeRange';
import SelectOptionSort from "dto/app/selectoptionsort.dto";
import RequestSortCriteriaDTO from "dto/app/requestsortcriteria.dto";
import { MySortSelect } from "components/elements/Select/MySortSelect";

import FilterListIcon from '@mui/icons-material/FilterList';
import { MyButton } from "components/general/MyButton";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { FilterRating } from "./FilterRating";
import { StaticNomenclatureFilter } from "./StaticNomenclatureFilter";
import TypeDisciplineService from "services/nomenclature/typediscipline.service";
import TypeProfessionalismLevelService from "services/nomenclature/typeprofessionalismlevel.service";
import TypeExperienceService from "services/nomenclature/typeexperience.service";
import TypeTargetGroupService from "services/nomenclature/typetargetgroup.service";


const typeDisciplineService = new TypeDisciplineService();
const typeProfessionalismLevelService = new TypeProfessionalismLevelService();
const typeTargetGroupService = new TypeTargetGroupService();
const typeExperienceService = new TypeExperienceService();

type FilterBoxTeacherProps = {
    currentRoute: any;
    numberOfResults: number;
    setFilterRating: any;
    filtersRating?: RequestFilterDTO;

    filterDisciplineValue: Array<string>;
    setFilterDisciplineValue: any;

    filterTypeProfessionalismLevelValue: Array<string>;
    setFilterTypeProfessionalismLevelValue: any;
    filterTypeTargetGroupValue: Array<string>;
    setFilterTypeTargetGroupValue: any;
    filterTypeExperienceValue: Array<string>;
    setFilterTypeExperienceValue: any;

    window?: () => Window;
}

const drawerWidth = 240;



const FilterBoxTeacher: React.FC<FilterBoxTeacherProps> = ({
    currentRoute,
    filterDisciplineValue,
    setFilterDisciplineValue,
    numberOfResults,
    setFilterRating,
    filtersRating,
    filterTypeProfessionalismLevelValue,
    setFilterTypeProfessionalismLevelValue,
    filterTypeTargetGroupValue,
    setFilterTypeTargetGroupValue,
    filterTypeExperienceValue,
    setFilterTypeExperienceValue,

    window,
    ...props }) => {

    const { LL } = useResource();
    const [isLoading, setIsLoading] = useState(false);


    const theme = useTheme();
    const isSmUp = useMediaQuery(theme.breakpoints.up('md'));

    const container = (typeof window !== 'undefined' && window) ? window().document.body : undefined;


    const [expanded, setExpanded] = useState<boolean>(false);
    const [rendered, setRendered] = useState<boolean>(false);

    const handleAccordionChange = (event: any, isExpanded: boolean) => {
        setExpanded(isExpanded);
    };

    useEffect(() => {
        if (expanded && !rendered) {
            setRendered(true);
        }
    }, [expanded, rendered]);


    const clearFilters = () => {

        if (setFilterRating) setFilterRating(CommonTools.addFilterRating(0));
        if (setFilterDisciplineValue) setFilterDisciplineValue([]);
        if (setFilterTypeProfessionalismLevelValue) setFilterTypeProfessionalismLevelValue([]);
        if (setFilterTypeTargetGroupValue) setFilterTypeTargetGroupValue([]);
        if (setFilterTypeExperienceValue) setFilterTypeExperienceValue([]);

    }


    const countActiveFilters = (...filterArrays: any) => {
        let count = 0;
        if (!filterArrays) return count;
        filterArrays.forEach((filterArray: any) => {
            if (filterArray &&
                filterArray.length > 0
            ) {
                count++;
            }

        });

        return count;
    };

    const processCountTotal = () => {
        const count = countActiveFilters(
            filterDisciplineValue,
            filterTypeProfessionalismLevelValue,
            filterTypeTargetGroupValue,
            filterTypeExperienceValue) + processFilterRatingIsSet();
        return count;
    }

    const [count, setCount] = useState<number>(0);
    useEffect(() => {
        setCount(processCountTotal);
    }, [
        filterDisciplineValue, 
        filtersRating, 
        filterTypeProfessionalismLevelValue, 
        filterTypeTargetGroupValue, 
        filterTypeExperienceValue
    ]);

    const [mobileOpen, setMobileOpen] = useState(false);

    const handleDrawerToggle = () => {
        if (!isSmUp) {
            setMobileOpen(!mobileOpen);
        }
    };

    const processFilterRatingIsSet = () => {
        if (!filtersRating) return 0;
        if (!filtersRating.values) return 0;
        if (filtersRating.values.length == 0) return 0;
        if (!filtersRating.values[0]) return 0;
        if (parseFloat(filtersRating.values[0]) == 0) return 0;
        return 1;
    }

    const processFilterToolBox = () => {
        if (!filtersRating) return <Box onClick={handleDrawerToggle}><Typography component='div' className="btn">
            <FilterListIcon sx={{ mr: 1 }} />{LL('Filter')}</Typography></Box>;
        const counter = count;
        if (!counter) return <Box onClick={handleDrawerToggle}><Typography component='div' className="btn">
            <FilterListIcon sx={{ mr: 1 }} />{LL('Filter')}</Typography></Box>;
        return (
            <Stack direction='row' spacing={1}>
                <Box onClick={handleDrawerToggle}>
                    <Typography component='div' className="btn">
                        <FilterListIcon sx={{ mr: 1 }} />
                        {LL('Filter')} ({counter})
                    </Typography>
                </Box>
                <Box>
                    <IconButton onClick={clearFilters}>
                        <ClearIcon />
                    </IconButton>
                    {LL('Clear Filters')}
                </Box>
            </Stack>
        )

    }

    const processNumberOfResults = () => {
        if (isLoading) return <></>;
        if (numberOfResults === -1) return <></>;
        return (<Box>
            <Typography >{numberOfResults} {LL('Results')}</Typography>
        </Box>
        )
    }

    const processFilterToolBoxDrawer = () => {
        if (!filtersRating) return <></>;
        const counter = count;
        if (!counter) return <></>;;
        return (
            <Box>
                {processNumberOfResults()}
                <IconButton onClick={clearFilters}>
                    <ClearIcon />
                </IconButton>
                {LL('Clear Filters')}
            </Box>
        )

    }



    const processFilterRating = () => {


        return (<Box >
            <Accordion className="accordion"
                onChange={handleAccordionChange}
            >
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    className="summary"
                >
                    <Typography variant="h5">{LL("Teacher_Rating")}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <FilterRating
                        setFilters={setFilterRating}
                        filters={filtersRating}
                    />
                </AccordionDetails>
            </Accordion>
        </Box>)
    }



    const processFilterDiscipline = () => {
        if (!filterDisciplineValue) return <></>;

        return (<StaticNomenclatureFilter
            filterName="typediscipline"
            filterValue={filterDisciplineValue}
            setFilterValue={setFilterDisciplineValue}
            service={typeDisciplineService}
        />);

    };

    const processFilterTypeProfessionalismLevel = () => {
        if (!filterTypeProfessionalismLevelValue) return <></>;

        return (<StaticNomenclatureFilter
            filterName="typeprofessionalismlevel"
            filterValue={filterTypeProfessionalismLevelValue}
            setFilterValue={setFilterTypeProfessionalismLevelValue}
            service={typeProfessionalismLevelService}
        />);

    }

    const processFilterTypeTargetGroup = () => {
        if (!filterTypeTargetGroupValue) return <></>;

        return (<StaticNomenclatureFilter
            filterName="typetargetgroup"
            filterValue={filterTypeTargetGroupValue}
            setFilterValue={setFilterTypeTargetGroupValue}
            service={typeTargetGroupService}
        />);

    }

    const processFilterTypeExperience = () => {
        if (!filterTypeExperienceValue) return <></>;

        return (<StaticNomenclatureFilter
            filterName="typeexperience"
            filterValue={filterTypeExperienceValue}
            setFilterValue={setFilterTypeExperienceValue}
            service={typeExperienceService}
        />);

    }

    const processFilterList = () => {

        return (
            <>
                {processFilterRating()}
                {processFilterDiscipline()}
                {processFilterTypeProfessionalismLevel()}
                {processFilterTypeTargetGroup()}
                {processFilterTypeExperience()}

            </>
        );
    };

    const processFilterListContent = () => (
        <>
            <Box mt={4}>
                {processFilterList()}
            </Box>
        </>
    );


    return isLoading ? <Skeleton /> : (
        <Box className="filterLeft">

            {processFilterToolBox()}
            <Drawer
                container={container}
                variant="temporary"
                sx={{
                    display: { xs: 'block', sm: 'block', md: 'none', lg: 'none', xl: 'none' },
                    '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                }}
                open={mobileOpen}
                anchor="right"
                onClose={handleDrawerToggle}
            >
                {processFilterToolBoxDrawer()}
                {processFilterListContent()}
            </Drawer>
            <Box
                sx={{
                    display: { xs: 'none', sm: 'none', md: 'block', lg: 'block', xl: 'block' },
                    '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                }}
            >
                {processFilterListContent()}
            </Box>
        </Box>
    );
};

export { FilterBoxTeacher };
