import { useEffect, useState } from "react";
import Checkbox from "@mui/material/Checkbox";
import { FormControlLabel } from "@mui/material";

const MyCheckboxField = ({ _label, setObj, _vresults ,...props }) => {
  const [label, setLabel] = useState("");
  const [field, setField] = useState("");
  const [vresults, setVResults] = useState(false);

  useEffect(() => {
    setLabel(_label);
  }, [_label]);

  useEffect(() => {
    setVResults(_vresults);
  }, [_vresults]);

  useEffect(() => {
    const f = props.field ? props.field : props.name;
    setField(f);
  }, [props]);

  const handleChange = (event) => {
    if (!setObj) return;

    setObj(event.target.checked);
  };

  const infoButton = () => {
    if (!vresults) return <></>;

    if (vresults.isOk) {
      return <>OK_BT</>;
    }

    return <>ERR_BT</>;
  };

  const infoMessages = () => {
    if (!vresults) return <></>;
    if (!vresults.objects) return <></>;
    if (!vresults.objects.length) return <></>;
    

    const cl = vresults.isOk ? 'active' : 'disabled';

    return vresults.objects.map((_obj, i) => {
      if (!_obj.message) return;

      return <div className={cl}>{_obj.message}</div>;
    });
  };

  return (
    <>
      <FormControlLabel
        control={<Checkbox onChange={handleChange} {...props} />}
        label={label}
      /> 
      {infoButton()}
      {infoMessages()}
    </>
  );
};

export default MyCheckboxField;
