import React, { useEffect, useState, useRef } from "react";
import PageComponentProps from "interfaces/pagecomponentprops.interface";
import { useResource } from "hook/useResource";
import { logger } from "hoc/logger";
import Box from '@mui/material/Box'
import { MyNavBt } from "components/general/MyNavBt";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import MyTextField from 'components/form/mytextfield';
import { IconButton, Skeleton, Stack } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import Snackbar from '@mui/material/Snackbar';
import { useMessage } from "hook/useMessage";
import { CommonTools } from "tools/commontools";

import { AttachmentDto } from "dto/attachment/attachment.dto";
import ResultListDTO from "dto/app/resultlist.dto";
import TbFilterListDTO from "dto/app/tbfilterlist.dto";
import RequestListDTO from "dto/app/requestlist.dto";
import { ComponentCommonTools } from "tools/componentcommontools";
import AttachmentService from "services/attachment/attachment.service";
import RequestFilterDTO from "dto/app/requestfilter.dto";
import RequestSortCriteriaDTO from "dto/app/requestsortcriteria.dto";
import { Status } from "tools/status";
import TbFilterDTO from "dto/app/tbfilter.dto";
import FilterTools from "tools/filtertools";
import MyTable from "components/elements/TabelTools/MyTable";
import { MyButton } from "components/general/MyButton";

import { EditAttachmentDialog } from "components/attachment/EditAttachmentDialog";
import { AddAttachmentDialog } from "components/attachment/AddAttachmentDialog";



const attachmentService = new AttachmentService();
type AttachmentDialogProps = {
    open: boolean;
    setOpen: any;
    idparent: string;
    parent:string
}
const AttachmentDialog: React.FC<AttachmentDialogProps> = ({
    open,
    setOpen,
    idparent,
    parent,
    ...props
}) => {
    const { LL, CC } = useResource();
    const [loading, setLoading] = useState<boolean>(false);

    const [data, setData] = useState<ResultListDTO>(new ResultListDTO());
    const [rows, setRows] = useState<Array<AttachmentDto>>(new Array<AttachmentDto>());
    const [total, setTotal] = useState(0);
    const [totalPage, setTotalPage] = useState(0);
    const [objectHasChanged, setObjectHasChanged] = useState<string>('');
    const [responseParams, setResponseParams] = useState<RequestListDTO>(
        new RequestListDTO()
    );

    const [reqList, setReqList] = useState<RequestListDTO>(new RequestListDTO());
    const [tbFilterList, setTbFilterList] = useState<TbFilterListDTO>(
        new TbFilterListDTO()
    );
    const [deleteOneObject, setDeleteOneObject] = useState<any>(undefined);
    const deleteUser = (obj: any) => {
        setDeleteOneObject(obj);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const columns = [];
    columns.push(ComponentCommonTools.columns_GetCheck());
    columns.push(ComponentCommonTools.columns_FileColumn(LL, "idfile"));
    columns.push(ComponentCommonTools.columns_StandardColumn(LL, "name"));
    columns.push(ComponentCommonTools.columns_ConstantColumn(LL));
    columns.push(
        ComponentCommonTools.columns_StandardColumn(LL, "ordercriteria")
    );
    columns.push(
        ComponentCommonTools.columns_StandardColumn(
            LL,
            "isdefault",
            undefined,
            true,
            true,
            "boolean"
        )
    );
    columns.push(
        ComponentCommonTools.columns_ActionsColumn(
            LL,
            parent,
            deleteUser,
            ["delete"],
            ["delete"],
            undefined,
            'attachment',
        )
    );

    const loadObjects = (data: ResultListDTO) => {
        CommonTools.processListLoadObjects(
            data,
            setData,
            setRows,
            setTotal,
            setTotalPage,
            setResponseParams
        );
    };

    const getList = () => {
        if (!open) return;
        if (!reqList) return;
        if (!reqList.filters) return;
        if (!reqList.filters.length) return;

        setLoading(true);

        attachmentService.getList(loadObjects, {}, reqList);
    };

    const getReqList = () => {
        const localReq = new RequestListDTO();

        const t = new RequestFilterDTO();
        t.field = "idparent";
        t.values = [idparent];

        localReq.page = 1;
        localReq.onpage = parseInt(CC("DEFAULT_ONPAGE_ATTACHEMENT", "10"));
        localReq.filters = [t]
        const sortCriteria = new RequestSortCriteriaDTO();
        sortCriteria.field = "ordercriteria";
        sortCriteria.asc = true;
        reqList.sortcriteria = [sortCriteria]

        setReqList(localReq);
    };
    const checkIsLoading = () => {
        const load: boolean = CommonTools.checkIsLoading(
            reqList,
            responseParams,
            data,
            total,
            totalPage,
            rows
        );
        setLoading(load);
    };

    const processTbFilterList = () => {
        const _tobj = new TbFilterListDTO();

        var t: TbFilterDTO;


        t = new TbFilterDTO("status", FilterTools.fiterTypeSelectMultiple);
        t.setValues(Status.GA("clasificator", true));
        _tobj.addFilter(t);

        setTbFilterList(_tobj);
    };

    useEffect(() => {
        processTbFilterList();
    }, []);

    useEffect(() => {
        getReqList();
    }, [idparent]);

    useEffect(() => {
        if (CommonTools.atLeastOneFieldDefined(reqList)) {
            getList();
        }
    }, [reqList, objectHasChanged]);

    useEffect(() => {
        checkIsLoading();
    }, [data, rows, reqList, total, totalPage]);

    const handleDelete = (item: any) => {
        attachmentService.delete(item.id, getList, undefined);
    };
    const [openDialogAddAttachment, setOpenDialogAddAttachment] = useState(false);
    const handleOpenDialogAddAttachment = () => {
        setOpenDialogAddAttachment(true);
    }

    const [openDialogEditAttachment, setOpenDialogEditAttachment] = useState(false);
    const [idCurrent, setIdCurrent] = useState<string>('');

    const handleOpenDialogEditAttachment = (value: any) => {
        setIdCurrent(value.id);
        setOpenDialogEditAttachment(true);
    }

    return (<>

        <Dialog
            open={open}
            onClose={handleClose}
            fullWidth

        >
            <DialogTitle>{LL("Attachment")}</DialogTitle>
            <IconButton sx={{
                position: 'absolute',
                right: 8,
                top: 8,
            }} onClick={handleClose}>
                <CloseIcon />
            </IconButton>
            <DialogContent dividers>
                <Box>
                    <Box textAlign='center' mb={2}>
                    <MyButton variant="outlined"
                        sx={{bgcolor:'#fff'}} cb={handleOpenDialogAddAttachment}>
                        {LL("Bt_Add")}
                    </MyButton></Box>
                    <AddAttachmentDialog
                        open={openDialogAddAttachment}
                        setOpen={setOpenDialogAddAttachment}
                        idparent={idparent}
                        setObjectWasChanged={setObjectHasChanged}
                        parent={parent}

                    />
                    <EditAttachmentDialog
                        open={openDialogEditAttachment}
                        setOpen={setOpenDialogEditAttachment}
                        id={idCurrent}
                        setObjectWasChanged={setObjectHasChanged}
                        idparent={idparent}
                        setId={setIdCurrent}
                        parent={parent}
                    />
                </Box>

                <MyTable
                    _columns={columns}
                    _data={rows}
                    _total={total}
                    _totalPage={totalPage}
                    _reqList={reqList}
                    _tbFilterList={tbFilterList}
                    setReqList={setReqList}
                    loading={loading}
                    currentRoute={parent}
                    onDelete={handleDelete}
                    mainObject={parent}
                    fieldToShowOnDelete="name"
                    deleteOneObject={deleteOneObject}
                    setDeleteOneObject={setDeleteOneObject}
                    onRowClickCb={handleOpenDialogEditAttachment}
                    {...props}
                />

            </DialogContent>

        </Dialog>
    </>
    )
};

export { AttachmentDialog };