import React, { useState, useEffect } from 'react';

import TestService from "services/course/test.service";
import { logger } from 'hoc/logger';
import useMyFormValidation from 'hook/useMyFormValidation';
import { TestDto } from 'dto/course/test.dto';
import { useCookies } from 'react-cookie';
import { Loading } from 'components/general/Loading.jsx';
import { MyNavBt } from "components/general/MyNavBt";
import { useResource } from "hook/useResource";
import Typography from '@mui/material/Typography';
import { Box, Divider } from '@mui/material';
import { CommonTools } from 'tools/commontools';
import { ComponentCommonTools } from 'tools/componentcommontools'

import ResultObjectDTO from 'dto/app/resultobject.dto';
import MyTextField from 'components/form/mytextfield';
import { LabelTypes } from 'tools/labeltypes';
import ValidateTools from 'tools/validate.tools';
import RequiredValidator from 'validators/required.validator';
import { MyButton } from 'components/general/MyButton';
import { RouteTools } from 'tools/routetools';
import { MyBreadcrumbs } from "components/elements/MyBreadcrumbs/MyBreadcrumbs";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import IconButton from '@mui/material/IconButton';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { TabPanel } from 'components/elements/Tabs/TabPanel';

import { TestTypeSelect } from 'components/elements/Select/TestTypeSelect';
import MyCheckboxField from "components/form/mycheckboxfield";

import { Status } from 'tools/status';
import { Skeleton, Stack } from '@mui/material';
import MyCKEditor from 'components/elements/ckeditor/MyCKEditor';

import { AttachmentDialog } from 'components/attachment/AttachmentDialog';
import { GalleryDialog } from 'components/gallery/GalleryDialog';
import { VideoDialog } from 'components/video/VideoDialog';
import MyDateTimePicker from 'components/elements/MyDatePicker/MyDateTimePicker';
import DateTools from 'tools/date.tools';
import { LessonSelect } from 'components/elements/Select/LessonSelect';
import { Types } from 'tools/types';
import { useMessage } from 'hook/useMessage';
import { AddQuestionDialog } from './AddQuestionDialog';
import { QuestionList } from './QuestionList';
import { StatusSelect } from 'components/elements/Select/StatusSelect';
const testService = new TestService();

interface EditTestProps {
    id: string;
    setObjectsHasChanged: any;
    setExpend: any

}

const EditTest: React.FC<EditTestProps> = ({
    id,
    setObjectsHasChanged,
    setExpend,

    ...props
}) => {

    const { registerDialog } = useMessage();
    const { LL } = useResource();
    const [loading, setLoading] = useState<boolean>(false);
    const [obj, setObj] = useState<TestDto | undefined>()
    const [isFormDisabled, setIsFormDisabled] = useState(false);

    const [validators, setValidators] = useState<any>();
    const [vresults, setVResults] = useState<any>(false);



    const validateAll = () => {

        if (!obj) return;
        if (!validators) return;


        obj.name = obj.name !== undefined ? obj.name : '';
        obj.mintestscore = obj.mintestscore !== undefined ? obj.mintestscore : 0;
        obj.status = obj.status !== undefined ? obj.status : Status.ACTIVE;
        obj.idlesson = obj.idlesson ? obj.idlesson : null;

        setObj(obj);


    };

    const checkValidDefault = () => {
        if (!obj) return
        validateField("name", obj.name);
    }


    const validateField = (field: any, value: any, v?: any) => {
        v = v ? v : JSON.parse(JSON.stringify(vresults));
        if (v === false) v = {};
        v[field] = ValidateTools.validate(field, value, validators, vresults);

        setVResults(v);
        setIsFormDisabled(ValidateTools.vForm(v, field));
        return v;
    };



    const checkLoading = () => {

        if (obj === undefined) return;
        if (!validators) return;
        setLoading(false);


    };

    const loadObject = (obj: ResultObjectDTO) => {
        if (!obj) return;
        if (!obj.err) {
            const object = obj.obj ? obj.obj : {};
            setObj(object);
            validateAll();
        }
    };

    const getObject = () => {
        setLoading(true);
        if (id !== undefined) {
            testService.get(id, loadObject, {});
        }
    };


    const checkIsFormDisabled = () => {

        let rez = ValidateTools.vForm(vresults, 'name');

        if (!obj || !obj.type) rez = true;

        setIsFormDisabled(rez);
    };



    useEffect(() => {
        checkLoading();
        checkValidDefault();
    }, [obj, validators]);

    useEffect(() => {
        checkIsFormDisabled();
    }, [vresults, obj?.type]);

    const processValidators = () => {
        let v: any = {

            name: [],

        };

        v.name.push(new RequiredValidator('', ''));


        setValidators(v);
    };
    useEffect(() => {
        processValidators()
        getObject();

    }, [id]);





    const handleSubmit = (event: any) => {
        event.preventDefault();
        if (isFormDisabled) return false;
        if (id !== undefined) {
            setLoading(true);
            testService.update(id, handleResult, {}, obj);
        }
    };

    const handleResult = (result: ResultObjectDTO, cbparams: any) => {
        if (!result) return;
        if (!result.err) {
            setObjectsHasChanged(CommonTools.generateRandomString(5))
            setLoading(false);
            setExpend(false);
        }
    }

    const setObjField = (field: any, value: any) => {
        let t = JSON.parse(JSON.stringify(obj));
        t[field] = value;
        setObj(t);
        validateField(field, value);
    };

    const processLessonSelect = () => {
        if (!obj) return <></>;
        if (!obj.type) return <></>;
        if (obj.type !== Types.TYPE_TEST_LESSON) return <></>;
        const defaultValue = obj.idlesson ? obj.idlesson : '';
        const idCourse = obj.idcourse ? obj.idcourse : '';
        return (
            <Box>
                <Typography variant="h6" mb={1}>{LL('Lesson')}</Typography>
                <LessonSelect
                    id="idlesson"
                    name="idlesson"
                    variant="standard"
                    className="form-select"
                    color="primary"
                    setObj={setObjField}
                    field="idlesson"
                    obj={obj}
                    idCourse={idCourse}
                    _defaultValue={defaultValue}
                />
            </Box>
        )
    }

    const handleActionDelete = () => {
        registerDialog(LL('Confirm_delete_test'),
            LL('Are_you_sure_you_want_to_delete_this_test'),
            { cb: handleDelete }, null);
    }

    const handleDelete = () => {
        if (!obj) return;
        if (!obj.id) return;
        testService.delete(obj.id, handleResult, {});
    }

    const [openDialogQuestion, setOpenDialogQuestion] = useState(false);
    const handleOpenDialogQuestion = () => {
        setOpenDialogQuestion(true);
    }

    const processQuestionList = () => {
        if (!obj) return <></>;
        if (!obj.id) return <></>;
        return (<Box>
            <QuestionList
                idTest={obj.id}
                setObjectsHasChanged={setObjectQuestionHasChanged}
                objectQuestionHasChanged={objectQuestionHasChanged}
            />
        </Box>)
    }

    const [objectQuestionHasChanged, setObjectQuestionHasChanged] = useState<string>('');
    return !loading && obj ? (

        <Box >
            <Box mb={3}>
                <MyButton
                    cb={handleOpenDialogQuestion}
                    variant="outlined"
                    sx={{ bgcolor: '#fff' }}
                >
                    {LL('Question')}
                </MyButton>
                <AddQuestionDialog
                    setOpen={setOpenDialogQuestion}
                    open={openDialogQuestion}
                    id={id}
                    setObjectWasChanged={setObjectQuestionHasChanged}

                />
            </Box>

            <form onSubmit={handleSubmit}>
                <Box>
                    <Typography variant="h6" mb={1}> {LL('Numele')}</Typography>
                    <MyTextField
                        tabIndex={0}
                        fullWidth
                        id="name"
                        name="name"
                        variant="standard"
                        color="primary"
                        className="form-input"
                        _label={LL("Name")}
                        size="small"
                        value={obj.name}
                        setObj={setObjField}
                        _vresults={vresults && vresults.name ? vresults.name : false}
                    />
                </Box>
                <Box>
                    <Typography variant="h6" mb={1}>{LL('Tip test')}</Typography>
                    <TestTypeSelect
                        id="type"
                        name="type"
                        variant="standard"
                        className="form-select"
                        color="primary"
                        setObj={setObjField}
                        field="type"
                        value={obj.type}
                    />
                </Box>
                <Box>
                    <Typography variant="h6" mb={1}> {LL('Min test score')}</Typography>
                    <MyTextField
                        tabIndex={0}
                        fullWidth
                        id="mintestscore"
                        name="mintestscore"
                        variant="standard"
                        color="primary"
                        className="form-input"
                        _label={LL("mintestscore")}
                        size="small"
                        value={obj.mintestscore}
                        setObj={setObjField}
                        _vresults={undefined}
                    />
                </Box>
                <Box>
                    <Typography variant="h6" mb={1}> {LL('Status')}</Typography>
                    <StatusSelect
                        tabIndex={2}
                        id="status"
                        name="status"
                        variant="standard"
                        className='form-select'
                        color="primary"
                        value={obj.status}
                        field="status"
                        size="small"
                        setObj={setObjField}
                        _defaultValue={obj.status}
                    />
                </Box>
                {processLessonSelect()}
                <Box my={4} textAlign='center'>
                    <MyButton
                        tabIndex={3}
                        disabled={isFormDisabled}
                        color="primary"
                        className="btn"
                        variant="contained"
                        size="large"
                        type="submit"

                        children={LL("Bt_Submit Modification")}
                    /></Box>
            </form>
            <Box my={4} textAlign='center'>
                <MyButton
                    tabIndex={3}

                    color="error"
                    className="btn"
                    variant="contained"
                    size="large"
                    cb={handleActionDelete}

                    children={LL("Bt_Delete")}
                />
            </Box>
            {processQuestionList()}
        </Box>

    ) : (
        <Skeleton variant='rectangular' height={300} />
    );
};

export default EditTest;