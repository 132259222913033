import React, { useEffect, useState } from "react";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { logger } from "hoc/logger";
import { useResource } from "hook/useResource";
import RecommendationPriceService from "services/finance/recommendationprice.service";
import { MySelect } from "components/elements/Select/MySelect";
import RequestListDTO from "dto/app/requestlist.dto";
import SelectOptions from "dto/app/selectoptions.dto";
import ResultListDTO from "dto/app/resultlist.dto";
import { RecommendationPriceDto } from "dto/finance/recommendationprice.dto";
import { CommonTools } from "tools/commontools";
import { Skeleton } from "@mui/material";
import RequestFilterDTO from "dto/app/requestfilter.dto";
const recommendationPriceService = new RecommendationPriceService();

type RecommendationPriceSelectProps = {
  setObj: (field: any, value: any) => void;
  field: any;
  obj: any;
  idpricepolicy: string;
  _defaultValue?: string | null;
  useDefaultValueSelectPrice?: boolean;
  [key: string]: any; // for the rest of the props which we do not have types for
};

const RecommendationPriceSelect: React.FC<RecommendationPriceSelectProps> = ({
  setObj,
  field,
  obj,
  _defaultValue,
  idpricepolicy,
  useDefaultValueSelectPrice = false,
  ...props
}) => {
  const { LL } = useResource();

  const label = "RecommendationPrice";

  const defaultValue = _defaultValue ? _defaultValue : "";
  const [options, setOptions] = useState<Array<SelectOptions>>(
    new Array<SelectOptions>()
  );
  const [loading, setLoading] = useState<boolean>(true);
  const [value, setValue] = useState<string>(defaultValue);
  const [data, setData] = useState<Array<RecommendationPriceDto> | undefined>();
  const onPage = 2000;

  const getReqList = () => {
    const reqList =
      recommendationPriceService.getDefaultRequestListSelect(onPage);
    reqList.filters = [];
    if (!idpricepolicy) return reqList;
    let filter = new RequestFilterDTO();
    filter.field = "idpricepolicy";
    filter.values = [idpricepolicy];
    reqList.filters.push(filter);
    return reqList;
  };

  const [reqList, setReqList] = useState<RequestListDTO>(getReqList());

  const loadObjects = (data: ResultListDTO) => {
    setData(data.objects);
    
    if (useDefaultValueSelectPrice) {
        const value = RecommendationPriceDto.selectDefaultValue(
            data.objects as RecommendationPriceDto[]
          )
      setValue(value);
      setObj(field, value);
    }
  };

  useEffect(() => {
    if (!idpricepolicy) return;
    setReqList(getReqList());
  }, [idpricepolicy]);

  const getList = () => {
    setLoading(true);
    recommendationPriceService.getList(loadObjects, {}, reqList);
  };

  const processOptions = () => {
    if (data !== undefined) {
      const options: Array<SelectOptions> = data.map((item) => {
        const obj = {
          label: item.name + " - " + item.price,
          value: item.id,
        };

        if (item.hasOwnProperty("currency") && item.currency) {
          if (
            item.currency.hasOwnProperty("identifier") &&
            item.currency.identifier
          ) {
            obj.label += " " + item.currency.identifier;
          }
        }

        return obj;
      });
      setOptions(options);
    }
  };

  // useEffect(() => {
  //     getReqList();
  // }, []);

  useEffect(() => {
    setLoading(true);
    if (CommonTools.atLeastOneFieldDefined(reqList)) {
      getList();
    }
  }, [reqList]);

  useEffect(() => {
    if (data !== undefined) processOptions();
    checkIsLoading();
  }, [options, data]);

  useEffect(() => {
    if (loading) return;
    setObj(field, value);
  }, [value]);

  const checkIsLoading = () => {
    let load: boolean = false;
    if (data === undefined) load = true;
    if (options === undefined) load = true;
    // if (value === undefined) load = true;
    setLoading(load);
  };

  return !loading ? (
    <MySelect
      options={options}
      _label={label}
      setValue={setValue}
      value={value}
      {...props}
    />
  ) : (
    <Skeleton variant="rectangular" height={50} />
  );
};

export { RecommendationPriceSelect };
