import React, { useState, useEffect } from 'react';

import AddressService from "services/address/address.service";
import { logger } from 'hoc/logger';

import { AddressDto } from 'dto/address/address.dto';
import { useCookies } from 'react-cookie';
import { Loading } from 'components/general/Loading.jsx';
import { MyNavBt } from "components/general/MyNavBt";
import { useResource } from "hook/useResource";
import Grid from '@mui/material/Grid';
import { CommonTools } from 'tools/commontools';
import { ComponentCommonTools } from 'tools/componentcommontools'

import ResultObjectDTO from 'dto/app/resultobject.dto';
import { MyBreadcrumbs } from "components/elements/MyBreadcrumbs/MyBreadcrumbs";
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import IconButton from '@mui/material/IconButton';
import Divider from '@mui/material/Divider';
import { useAuth } from 'hook/useAuth';
import { CircularLoading } from 'components/general/CircularLoading';

import { Status } from 'tools/status';
import MyTextField from 'components/form/mytextfield';
import { LabelTypes } from 'tools/labeltypes';
import ValidateTools from 'tools/validate.tools';
import RequiredValidator from 'validators/required.validator';
import { MyButton } from 'components/general/MyButton';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';

import { LocalityAutocomplete } from 'components/elements/Autocomplete/LocalityAutocomplete';
import { LocalityAutocompleteV2 } from 'components/elements/Autocomplete/LocalityAutocompleteV2';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
const addressPhone = new AddressService();


interface AddressProps {
    currentRoute: any

}

const AddUserAddress: React.FC<AddressProps> = ({
    currentRoute,
    ...props
}) => {

    const { LL } = useResource();

    const [loading, setLoading] = useState<boolean>(true);
    const [obj, setObj] = useState<Array<AddressDto> | undefined>();
    const [objAddress, setObjAddress] = useState<AddressDto | undefined>(new AddressDto());
    const { user } = useAuth();
    const _id = user !== undefined ? user.id : '';
    const [id, setId] = useState<string>(_id);


    const loadObject = (obj: any) => {
        if (!obj) return;
        if (!obj.err) {
            const object = obj.obj ? obj.obj : [];
            setObj(object);
        }
    };

    const getObject = () => {

        setLoading(true);
        if (id !== undefined) addressPhone.getAddressByUserId(id, loadObject, {});
    };

    const checkIsLoading = () => {
        let load: boolean = false;
        if (obj === undefined) load = true;
        setLoading(load);
    };

    useEffect(() => {
        getObject();
    }, [currentRoute]);

    useEffect(() => {
        checkIsLoading();

    }, [obj]);

    useEffect(() => {

        if (user !== undefined) {
            setId(user.id)
        }
    }, [user])

    const [validators, setValidators] = useState<any>();
    const [vresults, setVResults] = useState<any>(false);
    const [isFormDisabled, setIsFormDisabled] = useState(false);

    const validateAll = () => {
        if (!objAddress) return;
        if (!validators) return;
        // if (vresults) return;


        objAddress.street = objAddress.street !== undefined ? objAddress.street : '';
        objAddress.streetnumber = objAddress.streetnumber !== undefined ? objAddress.streetnumber : 0;

        objAddress.zipcode = objAddress.zipcode !== undefined ? objAddress.zipcode : '';
        objAddress.idlocality = objAddress.idlocality !== undefined ? objAddress.idlocality : '';

        setObjAddress(objAddress);

        let t = validateField("street", objAddress.street);
        t = validateField("streetnumber", objAddress.streetnumber, t);
    };

    const validateField = (field: any, value: any, v?: any) => {

        v = (v) ? v : JSON.parse(JSON.stringify(vresults));
        if (v === false) v = {};
        v[field] = ValidateTools.validate(field, value, validators, vresults);

        setIsFormDisabled(ValidateTools.vForm(v, field));
        setVResults(v);
        return v;
    };

    const checkIsFormDisabled = () => {
        var rez = ValidateTools.vForm(vresults, 'street');
        rez = ValidateTools.vForm(vresults, 'streetnumber', rez);

        setIsFormDisabled(rez);
    };

    useEffect(() => {
        checkIsFormDisabled();
    }, [vresults]);

    useEffect(() => {
        processValidators();
    }, []);
    useEffect(() => {
        validateAll();
    }, [objAddress, validators]);

    const processValidators = () => {
        let v: any = {
            street: [],
            streetnumber: [],
        };

        v.street.push(new RequiredValidator('', ''));
        v.streetnumber.push(new RequiredValidator('', ''));

        setValidators(v);
    };

    const setObjField = (field: any, value: any) => {
        let t = JSON.parse(JSON.stringify(objAddress));
        t[field] = value;
        setObjAddress(t);
    };

    const handleSubmit = (event: any) => {
        event.preventDefault();

        if (objAddress !== undefined && id !== undefined && objAddress.street !== undefined) {

            objAddress.iduser = id;
            objAddress.status = Status.ACTIVE;

            if (!objAddress.hasOwnProperty('id')) addressPhone.add(getObject, {}, objAddress);
            else {
                if (objAddress.id) addressPhone.update(objAddress.id, getObject, {}, objAddress)
            }
        }

        setObjAddress(new AddressDto());
    };

    const handleDelete = (obj: AddressDto) => {

        if (obj.id) addressPhone.delete(obj.id, getObject, undefined);
        setObjAddress(new AddressDto());
    };

    const processAddressItem = (obj: AddressDto, index: any) => {
        if (!obj) return <></>


        return (
            <>
            <Box  sx={{ flexGrow: 1 }}>
                    <Grid container py={2} spacing={2}>
                        <Grid item xs={10}>
                        <Typography>
                        {ComponentCommonTools.processObjectField(obj, 'street')}
                        {' '}
                        {ComponentCommonTools.processObjectField(obj, 'streetnumber')}
                        {' '}
                        {ComponentCommonTools.processObjectField(obj, 'zipcode')}
                        {' '}
                        {ComponentCommonTools.processObjectFieldOfObject(obj, 'locality', 'name')}
                    </Typography>
                    </Grid>
                 
                 <Grid item xs={2}>
                    <MyButton


                        type="submit"
              
                        cb={() => { setObjAddress(obj) }
                        }
                        children={<EditIcon />}
                        color="primary"
                    />
                    <MyButton
                        color="secondary"
                        type="submit"

                        cb={() => { handleDelete(obj) }}
                        children={<DeleteIcon />}
                    />
                </Grid>
                    </Grid>
                    <Divider />
                </Box>
                </>
        )
    }


    const processAddressList = () => {
        if (!obj) return <></>
        if (obj.length === 0) return <></>
        return (<>
            {obj.map((item: AddressDto, index: any) => processAddressItem(item, index))}
        </>
        )
    }
    return !loading && obj && objAddress ? (
        <>

            <Box >

                
                <form onSubmit={handleSubmit} >
                    <Box sx={{ flexGrow: 1 }} maxWidth={'sm'} p={2} mx='auto'>
                    <Typography variant='h6'>{LL("Strada")}</Typography>
                    <MyTextField
                        tabIndex={0}
                        fullWidth
                        id="street"
                        name="street"
                        variant="standard"
                        className="form-input"
                        _label={LL("Street")}
                        size="small"
                        value={objAddress.street}
                        setObj={setObjField}
                        _vresults={
                            vresults && vresults.street ? vresults.street : false
                        }
                    />
                    <Box sx={{ mt: 3 }}>
                        <Typography variant='h6'>{LL("Nr. stradă")}</Typography>
                        <MyTextField
                            tabIndex={1}
                            fullWidth
                            id="streetnumber"
                            name="streetnumber"
                            _label={LL("streetnumber")}
                            variant="standard"
                            className="form-input"
                            value={objAddress.streetnumber}
                            setObj={setObjField}
                            _vresults={
                                vresults && vresults.streetnumber ? vresults.streetnumber : false
                            }
                        />
                    </Box>
                    <Box sx={{ mt: 3 }}>
                        <Typography variant='h6'>{LL("Cod poștal")}</Typography>
                        <MyTextField
                            tabIndex={1}
                            fullWidth
                            id="zipcode"
                            name="zipcode"
                            
                            _label={LL("zipcode")}
                            variant="standard"
                            className="form-input"
                            value={objAddress.zipcode}
                            setObj={setObjField}
                            _vresults={
                                vresults && vresults.zipcode ? vresults.zipcode : false
                            }
                        />
                    </Box>
                    <Box sx={{ mt: 3 }}>
                       <Typography variant='h6'>{LL("Alege localitatea")}</Typography>

                            <LocalityAutocompleteV2  setObj={setObjAddress} obj={objAddress} _defaultValue={objAddress.locality} />
 
                    </Box>
                    <Box textAlign='center'>
                    <MyButton
                        tabIndex={4}
                        disabled={isFormDisabled}
                        color="primary"
                        className="btn"
                        variant="contained"
                        size="large"
                        type="submit"
                        sx={{ mt: 4 }}
                        children={LL("Bt_Submit")}
                    />
                    </Box></Box>
                </form>
                <Box p={2}>
                <Typography variant='h4'>{LL("Adresele mele")}</Typography>
                    {processAddressList()}
                </Box>


            </Box>
        </>
    ) : (
        <CircularLoading />
    );
};

export default AddUserAddress;
