import React, { useState, useEffect } from 'react';


import { logger } from 'hoc/logger';
import useMyFormValidation from 'hook/useMyFormValidation';

import { useCookies } from 'react-cookie';
import { Loading } from 'components/general/Loading.jsx';
import { MyNavBt } from "components/general/MyNavBt";
import { useResource } from "hook/useResource";
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { CommonTools } from 'tools/commontools';
import { ComponentCommonTools } from 'tools/componentcommontools'
import ResultObjectDTO from 'dto/app/resultobject.dto';
import MyTextField from 'components/form/mytextfield';
import { LabelTypes } from 'tools/labeltypes';
import ValidateTools from 'tools/validate.tools';
import RequiredValidator from 'validators/required.validator';
import { MyButton } from 'components/general/MyButton';
import { RouteTools } from 'tools/routetools';
import { MyBreadcrumbs } from "components/elements/MyBreadcrumbs/MyBreadcrumbs";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';



import IconButton from '@mui/material/IconButton';

import { Status } from 'tools/status';
import { CircularLoading } from 'components/general/CircularLoading';
import { useMessage } from 'hook/useMessage';
import MyCheckboxField from 'components/form/mycheckboxfield';
import { EditCourseLessonList } from '../lesson/EditCourseLessonList';
import { AddLessonDialog } from '../lesson/AddLessonDialog';



interface EditCourseLessonProps {
    currentRoute: any;

}

const EditCourseLesson: React.FC<EditCourseLessonProps> = ({
    currentRoute,

    ...props
}) => {

    const { LL } = useResource();

    const id = currentRoute._paths !== undefined ? currentRoute._paths[2] : '';

    const [loading, setLoading] = useState<boolean>(false);
    const [obj, setObj] = useState<any>()
    const { registerDialog } = useMessage();

    const [objectHasChanged, setObjectHasChanged] = useState<string>('');
    const [openDialog, setOpenDialog] = useState<boolean>(false);

    const processLessonList = () => {
        if (!id) return <></>;
        return (<EditCourseLessonList
            idCourse={id}
            objectHasChanged={objectHasChanged}
            setObjectsHasChanged={setObjectHasChanged}
        />);
    }

    const handleOpenDialog = () => {
        setOpenDialog(true);
    }


    return !loading ? (
        <>
            <Box> 
                
            <Typography variant="h2" textAlign='center' mb={1}> {LL('Lectii curs')}</Typography>
                <Box textAlign='center' mb={4}>
                    <MyButton className='btn btnAddlesson' cb={handleOpenDialog}>
                        {LL('Bt_Add_Lesson')}
                    </MyButton>
                   
                </Box>
                {processLessonList()}

            </Box>
            <AddLessonDialog
                id={id}
                open={openDialog}
                setOpen={setOpenDialog}
                setObjectWasChanged={setObjectHasChanged}
            />
        </>
    ) : (
        <CircularLoading />
    );
};

export default EditCourseLesson;