import React, { useEffect, useState } from "react";
import { useResource } from "hook/useResource";
import { logger } from "hoc/logger";



import CastForEducationIcon from '@mui/icons-material/CastForEducation';
import { MyNavBt } from "components/general/MyNavBt";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { MyButton } from "components/general/MyButton";
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import { ComponentCommonTools } from 'tools/componentcommontools'
import Typography from "@mui/material/Typography";
import Rating from '@mui/material/Rating';
import Avatar from "@mui/material/Avatar";
import { CardHeader, Collapse, Link, Stack, Grid } from "@mui/material";
import { MyRatingReadOnly } from "components/elements/MyRating/MyRatingReadOnly";
import { MyAvatar } from "components/elements/MyAvatar/MyAvatar";

import { CourseEnrollmentDto } from "dto/course/courseenrollment.dto";
import { CommonTools } from "tools/commontools";


type TeacherInterfaceStudentCardProps = {
    obj: CourseEnrollmentDto;

}

const TeacherInterfaceStudentCard: React.FC<TeacherInterfaceStudentCardProps> = ({
    obj,

    ...props
}) => {
    const { LL } = useResource();

    const processUserName = () => {
        if (!obj) return <></>;
        if (!obj.hasOwnProperty('student')) return <></>;
        if (!obj.student) return <></>;
        if (!obj.student.hasOwnProperty('user')) return <></>;
        if (!obj.student.user) return <></>;
        let fullUrl = '';
        if (obj.student.user.hasOwnProperty('fullurl') && obj.student.user.fullurl) fullUrl = obj.student.user.fullurl;

        if (!fullUrl) {
            return (
                <Typography className="name">{processUserFullName()}</Typography>
            )
        }
        return (
            <Link href={fullUrl} target="_blank" rel="noopener noreferrer" className="name">
                {processUserFullName()}
            </Link>
        )
    }

    const processUserFullName = () => {
        if (!obj) return '';
        if (!obj.hasOwnProperty('student')) return '';
        if (!obj.student) return '';
        if (!obj.student.hasOwnProperty('fullname')) return '';
        if (!obj.student.fullname) return '';


        return obj.student.fullname;
    }
    const processAvatar = () => {
        if (!obj) return <></>;
        if (!obj.hasOwnProperty('student')) return <></>;
        if (!obj.student) return <></>;
        let name = ''
        let surname = ''
        if (obj.student.hasOwnProperty('name') && obj.student.name) name = obj.student.name
        if (obj.student.hasOwnProperty('surname') && obj.student.surname) surname = obj.student.surname

        return <MyAvatar obj={obj.student} name={name} surname={surname} sx={{ mr: 1 }} />
    }


    const processUserCard = () => {
        if (!obj) return <></>;
        return (
            <Box my={2} className="studentListItem">
                <Grid container>
                    <Grid item lg={3} xs={12}>
                        <Stack direction={"row"}>
                            {processAvatar()}
                            <Box>
                                {processUserName()}
                                <Typography>{ComponentCommonTools.processObjectFieldOfObject(obj, 'student', 'email')}</Typography>
                                <Typography variant="caption" pt={2}>{LL("Enrolled_on")}</Typography>
                                <Typography>{ComponentCommonTools.processObjectField(obj, 'data_name')}</Typography>
                            </Box>
                        </Stack>
                    </Grid>
                    {processCourseInfo()}
                </Grid>
            </Box>
        )
    }

    const processCourseName = () => {
        if (!obj) return <></>;
        if (!obj.hasOwnProperty('course')) return <></>;
        if (!obj.course) return <></>;
        let fullUrl = '';
        if (obj.course.hasOwnProperty('fullurl') && obj.course.fullurl) fullUrl = obj.course.fullurl;
        if (!fullUrl) {
            return ComponentCommonTools.processObjectFieldOfObject(obj, 'course', 'name')
        } else {
            return (
                <Link href={fullUrl} target="_blank" rel="noopener noreferrer">
                    {ComponentCommonTools.processObjectFieldOfObject(obj, 'course', 'name')}
                </Link>

            )
        }
    }
    
    const processCoursePercentage = () => {
        if(!obj) return '0';
        if(!obj.hasOwnProperty('coursepercentage')) return '0';
        if(!obj.coursepercentage) return '0';
        return CommonTools.roundToTenth(obj.coursepercentage)
    }
    const processCourseInfo = () => {
        if (!obj) return <></>;
        if (!obj.hasOwnProperty('course')) return <></>;
        if (!obj.course) return <></>;
        return (<>
            <Grid item  lg={5} xs={12}>
            <Stack direction="row" spacing={1}>
                <CastForEducationIcon />
                <Typography variant="h5" className="courseName">
                    {processCourseName()}
                </Typography>
            </Stack>
            <Stack direction="row" spacing={1} alignItems="center">
                <Typography variant="caption" color={"text.secondary"}>
                    {LL("numberoflessons")}
                </Typography>
                <Typography>
                    {ComponentCommonTools.processObjectField(obj, 'numberoflessons')}
                </Typography>
            </Stack>
            <Stack direction="row" spacing={1} alignItems="center">
                <Typography  variant="caption"  color={"text.secondary"}>
                    {LL("numberoflessonspassed")}
                </Typography>
                <Typography>
                    {ComponentCommonTools.processObjectField(obj, 'numberoflessonspassed')}
                </Typography>
            </Stack>
            <Stack direction="row" spacing={1} alignItems="center">
                <Typography  variant="caption"  color={"text.secondary"}>
                    {LL("coursepercentage")}
                </Typography>
                <Typography>
                    {processCoursePercentage()}
                </Typography>
            </Stack>
            </Grid>
            <Grid item lg={4} xs={12} alignContent={"center"} alignItems={"center"}>
            <Stack direction="row" spacing={1}  alignItems="center">
                <Typography variant="caption"  color={"text.secondary"}>
                    {LL("status")}
                </Typography>
                <Typography>
                    {ComponentCommonTools.processObjectField(obj, 'status_name')}
                </Typography>
            </Stack>
            <Stack direction="row" spacing={1} alignItems="center">
                <Typography variant="caption"  color={"text.secondary"}>
                    {LL("paymentstatus")}
                </Typography>
                <Typography>
                    {ComponentCommonTools.processObjectField(obj, 'paymentstatus_name')}
                </Typography>
            </Stack>
            </Grid>
        </>)
    }
    const processItem = () => {
        if (!obj) return <></>;

        return processUserCard()
    };


    return processItem();
};

export { TeacherInterfaceStudentCard };
