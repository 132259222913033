
import Idto from 'interfaces/idto.interface';


export class PostAdvertisementDto implements Idto {

    status?: number;
    section?: number;
    ordercriteria?: number;
    startdate?: Date;
    enddate?: Date;
    clabt?: string;
    url?: string;
    type?: number;
    description?: string;
    attachment?: any;
    gallery?: any;
    video?: any;
    fullurl?: any;
    idattachment?: string;
    idgallery?: string;
    idvideo?: string;
    idlanguage?: string;
    idadvertisement?: string;
    name?: string;
    language?: any;
    status_name?: any;
    section_name?: string;
    type_name?: string;
    startdate_name?: string;
    enddate_name?: string;
 
}

export class AdvertisementDto implements Idto {
    id?: string;
    status?: number;
    section?: number;
    ordercriteria?: number;
    startdate?: Date;
    enddate?: Date;
    clabt?: string;
    url?: string;
    type?: number;
    description?: string;
    attachment?: any;
    gallery?: any;
    video?: any;
    fullurl?: any;
    idattachment?: string;
    idgallery?: string;
    idvideo?: string;
    idlanguage?: string;
    idadvertisement?: string;
    name?: string;
    language?: any;
    status_name?: any;
    section_name?: string;
    type_name?: string;
    startdate_name?: string;
    enddate_name?: string;
 
}