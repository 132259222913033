import React, { useState, useEffect } from "react";
import PageComponentProps from "interfaces/pagecomponentprops.interface";
import { CommonTools } from "tools/commontools";
import CertificateService from "services/course/certificate.service";
import { CertificateDto } from "dto/course/certificate.dto";
import ResultObjectDTO from "dto/app/resultobject.dto";
import { Box, Skeleton, Typography } from "@mui/material";
import { useResource } from "hook/useResource";
import { ComponentCommonTools } from "tools/componentcommontools";

const certificateService = new CertificateService();
const Certificate: React.FC<PageComponentProps> = ({ currentRoute, ...props }) => {

  const { LLL } = useResource();
  const [hashCertificate, setHashCertificate] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(true);
  const [obj, setObj] = useState<CertificateDto | undefined>();

  useEffect(() => {
    setHashCertificate(CommonTools.getIdFromPath(currentRoute, 'certificate'));
  }, [currentRoute]);

  const getObject = () => {
    if (!hashCertificate) return;
    setLoading(true);
    certificateService.getByHash(hashCertificate, loadObject, {});
  };

  const loadObject = (obj: ResultObjectDTO) => {
    if (!obj) return;
    if (!obj.err) {
      const object: CertificateDto = obj.obj ? obj.obj : {};
      setObj(object);
    }
  };

  useEffect(() => {
    getObject();
  }, [hashCertificate]);

  const checkIsLoading = () => {
    let load: boolean = false;
    if (obj === undefined) load = true;
    setLoading(load);
  };

  useEffect(() => {
    checkIsLoading();
  }, [obj]);

  const processCertificate = () => {
    if (loading) return <Skeleton variant="rectangular" />;
    if (!obj) return <></>;
    if (!obj.hasOwnProperty('idlanguage')) return <></>;
    if (!obj.idlanguage) return <></>;

    return (
      <Box>
        <Typography>
          {LLL("Certificate_title", obj.idlanguage)}
        </Typography>
        <Typography>
          <span>
            {LLL("This_Certificate_certifies_that", obj.idlanguage)}
          </span>
          
          <span>
            {ComponentCommonTools.processObjectField(obj, 'studentname')}
          </span>
          
          <span>
            {LLL("successfully_complete", obj.idlanguage)}
          </span>
          
          <span>
            {LLL("number_of_lessons", obj.idlanguage)}
          </span>
          
          <span>
            {ComponentCommonTools.processObjectField(obj, 'numberoflessons')}
          </span>
          
          <span>
            {LLL("number_of_hours", obj.idlanguage)}
          </span>
          
          <span>
            {ComponentCommonTools.processObjectFieldTimeStamp(obj, 'numberofhours')}
          </span>
          
          <span>
            {LLL("of", obj.idlanguage)}
          </span>
          <span>
            {ComponentCommonTools.processObjectField(obj, 'coursename')}
          </span>

          <span>
            {LLL("by", obj.idlanguage)}
          </span>

          <span>
            {ComponentCommonTools.processObjectField(obj, 'teachername')}
          </span>

          <span>
            {LLL("course_on", obj.idlanguage)}
          </span>
          
          <span>
            {ComponentCommonTools.processObjectField(obj, 'date_name')}
          </span>
        </Typography>
      </Box>
    )

  }

  return (
    <>
      {processCertificate()}
    </>
  );
};

export { Certificate };
