import React, { useState, useEffect } from "react";
import { CoursePriceDto } from "dto/course/courseprice.dto";
import { useResource } from "hook/useResource";
import { Box, Typography, Alert } from "@mui/material";
import { CommonTools } from "tools/commontools";
import ResultObjectDTO from "dto/app/resultobject.dto";
import { MyButton } from "components/general/MyButton";
import CoursePriceService from "services/course/courseprice.service";
import { RecommendationPriceSelect } from "components/elements/Select/RecommendationPriceSelect";
import { Status } from "tools/status";
import { CircularLoading } from "components/general/CircularLoading";
import { useMessage } from "hook/useMessage";
import { CourseDto } from "dto/course/course.dto";
import { useAuth } from "hook/useAuth";
import { AddCoursePrice } from "./AddCoursePrice";

type Props = {
  currentRoute: any;
  course: CourseDto;
};
const service = new CoursePriceService();
const SelectCoursePrice: React.FC<Props> = ({ currentRoute, course }) => {
  const { LL } = useResource();
  const { teacher } = useAuth();
  const id = currentRoute._paths !== undefined ? currentRoute._paths[2] : "";
  const { registerDialog } = useMessage();
  const [loading, setLoading] = useState<boolean>(false);
  const [obj, setObj] = useState<CoursePriceDto | undefined>();
  const [isFormDisabled, setIsFormDisabled] = useState(false);
  const [objectHasChanged, setObjectHasChanged] = useState<string>("");
  const loadObject = (obj: ResultObjectDTO) => {
    if (!obj) return;
    if (!obj.err) {
      const object = obj.obj ? obj.obj : {};
      setObj(object);
    }
  };

  const getObject = () => {
    setLoading(true);
    if (id !== undefined) {
      service.getCoursePriceByCourseId(id, loadObject, {});
    }
  };

  const checkLoading = () => {
    let loading = false;
    if (!obj) loading = true;
    setLoading(loading);
  };

  useEffect(() => {
    getObject();
  }, [objectHasChanged]);

  useEffect(() => {
    checkLoading();
  }, [obj]);

  const setObjField = (field: any, value: any) => {
    let t = JSON.parse(JSON.stringify(obj));
    t[field] = value;
    setObj(t);
  };
  const checkIsFormDisabled = () => {
    let rez = false;
    if (!obj) rez = true;
    if (obj && !obj.idpricepolicy) rez = true;
    if (!teacher) rez = true;
    if (!teacher.hasOwnProperty("status")) rez = true;
    if (!teacher.status) rez = true;
    if (teacher.status === Status.TEACHER_UNVERIFIED) rez = true;
    if (!obj) rez = true;
    if (obj && !obj.idrecommendationprice) rez = true;

    setIsFormDisabled(rez);
  };

  useEffect(() => {
    checkIsFormDisabled();
  }, [obj?.idrecommendationprice, teacher]);

  const handleSubmit = (event: any) => {
    event.preventDefault();
    if (isFormDisabled) return false;
    if (!obj) return;
    obj.idcourse = id;
    obj.userecommendationprice = true;
    if (obj.id !== undefined) {
      registerDialog(
        LL("Confirm_edit_course"),
        LL(
          "Are_you_sure_you_want_to_edit_this_course_editing_will_change_the_status"
        ),
        { cb: handleConfirmEdit },
        null
      );
    }
  };

  const handleConfirmEdit = () => {
    if (obj && obj.id !== undefined) {
      service.update(
        obj.id,
        CommonTools.handleSuccessAddAndEditStayOnPage,
        { setObjectWasChanged: setObjectHasChanged },
        obj
      );
    }
  };

  const processPricePolicy = (obj: any) => {
    if (!obj) return <></>;
    return (
      <Box sx={{ mt: 3 }}>
        <Typography variant="h6" mb={1}>
          {CommonTools.processObjectField(obj, ["name"])}
        </Typography>
      </Box>
    );
  };

  const processObject = () => {
    if (!obj) return <></>;
    if (!obj.idpricepolicy || !obj.hasOwnProperty("idpricepolicy")) {
      return (
        <AddCoursePrice
          currentRoute={currentRoute}
          setObjectHasChanged={setObjectHasChanged}
          course={course}
        />
      );
    } else {
      return (
        <form onSubmit={handleSubmit}>
          {processPricePolicy(obj.pricepolicy)}
          <Box sx={{ mt: 3 }}>
            <Typography variant="h6" mb={1}>
              {LL("Recommendation price")}
            </Typography>
            <RecommendationPriceSelect
              id="idrecommendationprice"
              name="idrecommendationprice"
              variant="standard"
              className="form-select"
              color="primary"
              value={obj.idrecommendationprice}
              field="idrecommendationprice"
              idpricepolicy={obj.idpricepolicy}
              setObj={setObjField}
              obj={obj}
              _defaultValue={obj.idrecommendationprice}
            />
          </Box>
          <Box mt={4} textAlign='center'>
          <MyButton
            disabled={isFormDisabled}
            color="primary"
            variant="contained"
            size="large"
            type="submit"
            className="btn"
            children={LL("Bt_Submit")}
          />
          </Box>
        </form>
      );
    }
  };

  const processWarningCourse = () => {
    return (
      <Box mt={2}>
        <Alert severity="warning">
          {LL(
            "You_have_to_set_the_course_type"
          )}
        </Alert>
      </Box>
    );
  }
  const processComponent = () => {
    if (!teacher) return <></>;
    if (!teacher.hasOwnProperty("status")) return <></>;
    if (!teacher.status) return <></>;
    if(!course) return processWarningCourse();
    if (!course.hasOwnProperty("coursesettings")) return processWarningCourse();
    if (!course.coursesettings) return processWarningCourse();
    if (!course.coursesettings.hasOwnProperty("idtypecourse")) return processWarningCourse();
    if (!course.coursesettings.idtypecourse) return processWarningCourse();
    if (teacher.status === Status.TEACHER_UNVERIFIED) {
      return (
        <Box mt={2}>
          <Alert severity="warning">
            {LL(
              "You_are_not_verified_yet_you_should_be_verified_to_set_the_price"
            )}
          </Alert>
        </Box>
      );
    }
    return processObject();
  };

  return loading ? <CircularLoading /> : processComponent();
};

export { SelectCoursePrice };
