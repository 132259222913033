import React, { useContext } from "react";
import { useResource } from "hook/useResource";
import { List, Box } from "@mui/material";

import { CommonTools } from "tools/commontools";
import { RouteTools } from "tools/routetools";
import PageComponentProps from "interfaces/pagecomponentprops.interface";
import { ClassroomContext } from "context/classroom/classroom.context";
import { LessonDto } from "dto/course/lesson.dto";
import { ClassroomLessonItem } from "./ClassroomLessonItem";

export const ClassroomLessonList: React.FC<PageComponentProps> = ({
  currentRoute,
}) => {
  const { LL } = useResource();
  const {
    state: { lessonObjects },
  } = useContext(ClassroomContext);

  const processItem = (item: LessonDto, i: number) => {
    if (!item) return <></>;
    return (
      <Box key={i}>
        <ClassroomLessonItem obj={item} currentRoute={currentRoute} />
      </Box>
    );
  };

  const processComponent = () => {
    if (!lessonObjects) return <></>;
    if (lessonObjects.length === 0) return <></>;
    return (
      <List>
        {lessonObjects.map((item: LessonDto, i: number) => {
          return processItem(item, i);
        })}
      </List>
    );
  };

  return processComponent();
};
