import React, { useState } from 'react';
import { TopLogoBlock } from './TopLogoBlock';
import PageComponentProps from 'interfaces/pagecomponentprops.interface';
import { TopMenuBlock } from 'components/menu/TopMenuBlock';
import { SignUpTeacherBlock } from 'components/user/SignUpTeacherBlock';
import { SignUpBlock } from 'components/user/SignUpBlock';
import { SignInBlock } from "components/user/SignInBlock";
import { FavoriteBlock } from "components/user/FavoriteBlock";
import { SearchBlock } from "components/general/SearchBlock";
import { TopBar } from 'components/general/TopBar';
import { FAQBlock } from 'components/faq/FAQBlock';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { useResource } from "hook/useResource";
import { useAuth } from 'hook/useAuth';
import { UserInterfaceBlock } from 'components/user/UserInterfaceBlock';
import { InfoCourseCategoryBlock } from 'components/course/coursecategory/InfoCourseCategoryBlock';

import { MainDrawer } from 'components/elements/Drawer/MainDrawer';
import { IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { HeaderTeacher } from 'components/teacher/HeaderTeacher';
import { Types } from 'tools/types';
import { TeacherDrawer } from 'components/elements/Drawer/TeacherDrawer';
import { CommonTools } from 'tools/commontools';
import { NotificationTopMenu } from 'components/notification/NotificationTopMenu';

type HeaderProps = {
  currentRoute: any;
  showLogo: boolean
}

const Header: React.FC<HeaderProps> = ({ currentRoute, showLogo, ...props }) => {
  const { user, userInterface } = useAuth();
  const { isTeacher } = useResource();

  const [openDrawer, setOpenDrawer] = useState(false);
  const handleOpenDrawer = () => {
    setOpenDrawer(true);
  }

  const processTeacherHeader = () => {
    return <HeaderTeacher currentRoute={currentRoute} />
  }
  return (

    <Grid container className='row headerStyle'>

      <Box className="el1">
        <IconButton onClick={handleOpenDrawer}>
          <MenuIcon />
        </IconButton>
        <MainDrawer open={openDrawer} setOpen={setOpenDrawer} currentRoute={currentRoute} />
      </Box>

      <Box className="el2">
        <TopLogoBlock currentRoute={currentRoute} />
      </Box>
      <Box className="el3">
        {!CommonTools.isUserInterface(user, userInterface) ? <TopMenuBlock currentRoute={currentRoute} /> : <></>}
      </Box>
      <Box className="el4">
        {showLogo ? <InfoCourseCategoryBlock currentRoute={currentRoute} /> : <></>}
      </Box>
      <Box className="el5">
        <Box className="el5_1">
          {!isTeacher() ? <SignUpTeacherBlock currentRoute={currentRoute} /> : <></>}
        </Box>
        <Box className="el5_2">
          {!CommonTools.isUserInterface(user, userInterface) ? <SearchBlock currentRoute={currentRoute} /> : <></>}
        </Box>
        <Box className="el5_3">
          {!CommonTools.isUserInterface(user, userInterface) ? <FavoriteBlock currentRoute={currentRoute} /> : <></>}
        </Box>
        {user ?
          (
            <Box className="el5_4">
              {
                !CommonTools.isUserInterface(user, userInterface) ?
                  <NotificationTopMenu
                    currentRoute={currentRoute}
                    typeRecipient={Types.TYPE_NOTIFICATION_RECIPIENT_STUDENT}
                  /> :
                  <></>
              }
            </Box>
          ) : <></>
        }
        <Box className="el5_5">
          {!user ? (
            <>
              <SignUpBlock currentRoute={currentRoute} />
              <SignInBlock currentRoute={currentRoute} />
            </>
          ) : <>
            {!CommonTools.isUserInterface(user, userInterface) ? (
              <>
                <UserInterfaceBlock currentRoute={currentRoute} />
                <TopBar currentRoute={currentRoute} />
              </>) : processTeacherHeader()}
          </>}
        </Box>
      </Box>

    </Grid>
  );
};

export { Header };
