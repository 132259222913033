import RequestListDTO from "dto/app/requestlist.dto";
import RequestSortCriteriaDTO from "dto/app/requestsortcriteria.dto";
import ResultObjectDTO from "dto/app/resultobject.dto";
import { PostPageDto } from "dto/page/page.dto";
import ResultListDTO from "dto/app/resultlist.dto";
import { logger } from "hoc/logger";
import IService from "interfaces/iservice.interface";
import PageRepository from "repositories/page/page.repository";
import { Status } from "tools/status";
import GeneralService from "services/general.service";

export default class PageService extends GeneralService implements IService {
  pageRepository: PageRepository = new PageRepository();
  constructor() {
    super();
    this.handleGetList = this.handleGetList.bind(this);
    this.handleGet = this.handleGet.bind(this);
  }
  handleUpdate(result?: any, cbparameters?: any, data?: any): any {
    let rez = new ResultObjectDTO();
    rez = result;

    if (result.err) return;

    if (cbparameters && cbparameters._cb) {
      cbparameters._cb(rez, cbparameters, data);
    }
  }

  async update(
    id: string,
    cb?: any,
    cbparameters?: any,
    data?: any
  ): Promise<any> {
    cbparameters = cbparameters ? cbparameters : {};
    cbparameters._cb = cb;
    this.pageRepository.update(
      id,
      this.handleUpdate,
      this.prepareObjToUpdate(data),
      cbparameters
    );
  }

  handleDelete(result?: any, cbparameters?: any): any {
    let rez = new ResultObjectDTO();
    rez = result;
    if (result.err) return;

    if (cbparameters && cbparameters._cb) {
      cbparameters._cb(rez, cbparameters);
    }
  }

  async delete(id: string, cb?: any, cbparameters?: any): Promise<any> {
    cbparameters = cbparameters ? cbparameters : {};
    cbparameters._cb = cb;

    this.pageRepository.delete(id, this.handleDelete, cbparameters);
  }

  handleGet(result?: any, cbparameters?: any, data?: any): any {
    let rez = new ResultObjectDTO();
    rez = result;
    if (result.err) return;
    this.populateObject(rez.obj, result.obj, [
      { key: "status", type: "status" },
      { key: "type", type: "types" },
    ]);
    if (cbparameters && cbparameters._cb) {
      cbparameters._cb(rez, cbparameters, data);
    }

    return rez;
  }

  async getHomeSEO(cb?: any, cbparameters?: any): Promise<any> {
    cbparameters = cbparameters ? cbparameters : {};
    cbparameters._cb = cb;
    const t = await this.pageRepository.getHomeSEO(
      this.handleGet,
      cbparameters
    );
    if (cb == undefined) {
      return this.handleGet(t);
    }
  }

  async get(
    id: string,
    cb?: any,
    cbparameters?: any,
    idLanguage?: string
  ): Promise<any> {
    cbparameters = cbparameters ? cbparameters : {};
    cbparameters._cb = cb;
    const t = await this.pageRepository.get(
      id,
      this.handleGet,
      cbparameters,
      idLanguage
    );
    if (cb == undefined) {
      return this.handleGet(t);
    }
  }

  handleGetList(result?: any, cbparameters?: any, data?: any): any {
    let rez = new ResultListDTO();

    rez = result;
    if (result.err) return;

    this.parseResultPopulate(result, rez, [
      { key: "status", type: "status" },
      { key: "type", type: "types" },
    ]);

    if (cbparameters && cbparameters._cb) {
      cbparameters._cb(rez, cbparameters, data);
    }

    return rez;
  }

  async getList(
    cb?: any,
    cbparameters?: any,
    data?: RequestListDTO
  ): Promise<any> {
    cbparameters = cbparameters ? cbparameters : {};
    cbparameters._cb = cb;
    const t = await this.pageRepository.getList(
      this.handleGetList,
      cbparameters,
      data
    );
    if (cb == undefined) {
      return this.handleGetList(t);
    }
  }

  handleAdd(result?: any, cbparameters?: any, data?: any): any {
    let rez = new ResultObjectDTO();
    rez = result;
    if (result.err) return;

    if (cbparameters && cbparameters._cb) {
      cbparameters._cb(rez, cbparameters, data);
    }
  }

  prepareObjToAdd(obj: any): PostPageDto {
    const rez = new PostPageDto();

    rez.idlanguage = obj.idlanguage;

    rez.name = obj.name;
    rez.status = obj.status;
    rez.url = obj.url;
    rez.title = obj.title;
    rez.type = obj.type;
    rez.keymeta = obj.keymeta;
    rez.descriptionmeta = obj.descriptionmeta;
    rez.content = obj.content;

    return rez;
  }

  prepareObjToUpdate(obj: any): PostPageDto {
    const rez = new PostPageDto();

    rez.idlanguage = obj.idlanguage;

    rez.name = obj.name;
    rez.status = obj.status;
    rez.url = obj.url;
    rez.title = obj.title;
    rez.type = obj.type;
    rez.keymeta = obj.keymeta;
    rez.descriptionmeta = obj.descriptionmeta;
    rez.content = obj.content;

    rez.idpage = obj.idpage;
    return rez;
  }

  async add(cb?: any, cbparameters?: any, data?: any): Promise<any> {
    cbparameters = cbparameters ? cbparameters : {};
    cbparameters._cb = cb;
    this.pageRepository.add(
      this.handleGetList,
      cbparameters,
      this.prepareObjToAdd(data)
    );
  }

  getDefaultRequestListSelect(onPage?: number): RequestListDTO {
    onPage = onPage ?? Number(process.env.REACT_APP_ONPAGE);
    if (onPage === 0) onPage = Number(process.env.REACT_APP_ONPAGE);
    var defaultRequestList = new RequestListDTO();
    defaultRequestList.page = Number(process.env.REACT_APP_PAGE);
    defaultRequestList.onpage = onPage;
    var defaultRequestSortCriteria = new RequestSortCriteriaDTO();
    defaultRequestSortCriteria.asc = true;
    defaultRequestSortCriteria.field = "name";
    defaultRequestList.sortcriteria = [];
    defaultRequestList.sortcriteria?.push(defaultRequestSortCriteria);
    return defaultRequestList;
  }
  getDefaultRequestList(obj: RequestListDTO): RequestListDTO {
    if (
      !obj.sortcriteria ||
      !Array.isArray(obj.sortcriteria) ||
      !obj.sortcriteria.length
    ) {
      var sobj = new RequestSortCriteriaDTO();
      sobj.asc = true;
      sobj.field = "name";

      obj.sortcriteria = [];
      obj.sortcriteria?.push(sobj);
    }

    return obj;
  }
}
