import React, { useContext, useEffect, useState, useCallback } from "react";
import PageComponentProps from "interfaces/pagecomponentprops.interface";
import { StudentCourseContext } from "context/studentcourse/studentcourse.context";
import { ComponentCommonTools } from "tools/componentcommontools";
import { Box, Typography, Skeleton, Stack } from "@mui/material";
import { StudentCourseZoomBlock } from "./StudentCourseZoomBlock";
import { ShouldFinishLesson } from "./ShouldFinishLesson";
import { LessonTest } from "./LessonTest";
import { StudentVideoPlayer } from "./StudentVideoPlayer";

export const LessonArea: React.FC<PageComponentProps> = ({ currentRoute }) => {
  const {
    state: { selectedLessonObject, loadingLessonArea },
    actions: { getCurrentLessonSelection },
  } = useContext(StudentCourseContext);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    getCurrentLessonSelection();
  }, [getCurrentLessonSelection]);



  const checkLoading = useCallback(() => {
    let loading = false;
    if (selectedLessonObject === null) loading = true;
    setLoading(loading);
  }, [selectedLessonObject]);

  useEffect(() => {
    checkLoading();
  }, [checkLoading]);

  const processComponent = () => {
    if (loading || loadingLessonArea)
      return <Skeleton variant="rectangular" height={"50%"} />;
    if (!selectedLessonObject) return <></>;
    return (
      <Box>
        <Stack>
          <ShouldFinishLesson />
          <LessonTest />
        </Stack>

          <StudentCourseZoomBlock currentRoute={currentRoute} />

        <StudentVideoPlayer  currentRoute={currentRoute} />
      </Box>
    );
  };

  return processComponent();
};
