import React from "react";
import PageComponentProps from "interfaces/pagecomponentprops.interface";
import { useAuth } from "hook/useAuth";
import { Skeleton, IconButton } from "@mui/material";
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import { RouteTools } from "tools/routetools";
const WishlistIcon: React.FC<PageComponentProps> = ({ currentRoute, ...props }) => {
    const { user } = useAuth();

    const goCourseDetails = () => {
        if (!user) return;

        RouteTools.setHistory('/wishlist', {})
    }
    const processIcon = () => {

        return (<IconButton onClick={goCourseDetails} sx={{mx:1}}>

            <FavoriteBorderIcon  />
        </IconButton>)
    };
    return user ? processIcon() : <> </>;
};

export { WishlistIcon };
