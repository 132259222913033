export class FileTools {
  public static getFileUrl = (idfile: string, fileName?: string): string => {
    let url: string = process.env.REACT_APP_API_SERVER as string;

    url += "/file/object";
    url += "/" + idfile;
    // url += fileName ? "/" + encodeURIComponent(fileName) : "";

    return url;
  };

  public static getFileImage = (
    idfile: string,
    fileName?: string,
    w?: number,
    h?: number
  ): string => {
    let url: string = process.env.REACT_APP_API_SERVER as string;

    url += "/file/object";
    url += "/" + idfile;
    // url += fileName ? "/" + encodeURIComponent(fileName) : "";

    if (w != undefined || h != undefined)
    {
      url += "?";
      if (w != undefined && w) url += "&w=" + w;
      if (h != undefined && h) url += "&h=" + h;
    }

    return url;
  };
}
