import React, { useCallback, useEffect, useRef, useState } from "react";
import { useResource } from "hook/useResource";
import { logger } from "hoc/logger";
import CourseCategoryService from "services/course/coursecategory.service";
import PageComponentProps from "interfaces/pagecomponentprops.interface";
import { CourseCategoryDto } from "dto/course/coursecategory.dto";
import { RouteTools } from "tools/routetools";
import RequestListDTO from "dto/app/requestlist.dto";
import { Status } from "tools/status";
import RequestFilterDTO from "dto/app/requestfilter.dto";
import ResultListDTO from "dto/app/resultlist.dto";
import { Types } from "tools/types";
import { InfoCourseCategoryItem } from "./InfoCourseCategoryItem";
import { Loading } from "components/general/Loading";
import Skeleton from '@mui/material/Skeleton';
import { Box, Typography } from "@mui/material";
import ResultObjectDTO from "dto/app/resultobject.dto";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { ComponentCommonTools } from "tools/componentcommontools";
import Stack from '@mui/material/Stack';
import AppBar from '@mui/material/AppBar';
import { MyNavBt } from "components/general/MyNavBt";
import { InfoCourseCategoryCard } from "components/course/coursecategory/InfoCourseCategoryCard";
import { Sort } from "@mui/icons-material";
import RequestSortCriteriaDTO from "dto/app/requestsortcriteria.dto";
import { Carousel } from "components/elements/Carousel/Carousel";
import { CommonTools } from "tools/commontools";


const courseCategoryService = new CourseCategoryService();

type PopularCategoryProps = {
    currentRoute: any;
    idCategory: string;
}

const PopularCategory: React.FC<PopularCategoryProps> = ({
    currentRoute,
    idCategory,
    ...props
}) => {

    const [isLoading, setIsLoading] = useState(true);
    const [objects, setObjects] = useState<Array<CourseCategoryDto>>([]);
    const [obj, setObj] = useState<CourseCategoryDto | undefined>();
    const { LL, CC } = useResource();

    const loadObject = (obj: ResultObjectDTO) => {
        if (!obj) return;
        if (!obj.err) {
            const object = obj.obj ? obj.obj : undefined;
            setObj(object);
        }
    };

    const getObject = () => {
        setIsLoading(true);
        if (idCategory !== '') {
            courseCategoryService.get(idCategory, loadObject, {});
        }
    };

    const getList = () => {
        const reqList = new RequestListDTO();
        reqList.onpage = 10;
        reqList.page = 1;
        reqList.filters = [];
        reqList.sortcriteria = [];
        let sortCriteria = new RequestSortCriteriaDTO();
        sortCriteria.field = "popularidentifier";
        sortCriteria.asc = false;
        reqList.sortcriteria.push(sortCriteria);

        let f: RequestFilterDTO;

        f = new RequestFilterDTO();
        f.field = "status";
        f.values = [];
        f.values.push(Status.ACTIVE.toString());
        reqList.filters.push(f);

        f = new RequestFilterDTO();
        f.field = "idparent";
        f.values = [];
        f.values.push(idCategory);
        reqList.filters.push(f);


        setIsLoading(true);

        courseCategoryService.getList(loadObjects, {}, reqList);

        // setIsLoading(false);
    };


    const loadObjects = (result: ResultListDTO) => {
        if (!result) return;
        if (!result.err) {
            const objects = result.objects ? result.objects : [];
            setObjects(objects);
        }
    }

    const checkLoading = () => {
        let load = false;
        if (!obj) load = true;
        if (!objects) load = true;
        if (objects.length === 0) load = true;

        setIsLoading(load);
    }
    useEffect(() => {
        if (idCategory !== '') {
            getObject();
            getList();
        }
    }, [idCategory]);

    useEffect(() => {
        checkLoading();
    }, [obj, objects]);


    // const [value, setValue] = useState();

    // const handleChange = (event: React.SyntheticEvent, newValue: any) => {
    //     if (newValue) {
    //         setValue(newValue);
    //         if (newValue.fullurl) {
    //             RouteTools.setHistory(newValue.fullurl, {});
    //         }
    //     }
    // };

    const processCourseCategoryItem = (obj: CourseCategoryDto, index: number) => {

        return <Box key={index}> <Box p={1}><InfoCourseCategoryCard obj={obj} withImage={false} /> </Box></Box>;
    };


    const processCourseCategoryList = () => {
        if (!objects.length) return <></>;
        const slidesToShow = CommonTools.calculateNumberOfElementsShowOnCarousel(parseInt(CC("DefaultValueShowPopularCourseCategoryListCarousel", "5")), objects.length)
        return (
            <Carousel _slidesToShow={slidesToShow} >
                {objects.map((item: CourseCategoryDto, i: number) => {
                    return processCourseCategoryItem(item, i);
                })}
            </Carousel>
        );
    };



    return isLoading && !obj ? <Skeleton /> : (
        <Box className="container populaCategories" py={5}>
            <Typography variant="h2" my={4}>{LL('Subiecte populare')}</Typography>
            {processCourseCategoryList()}
        </Box>);


};

export { PopularCategory };
