import { Box, Card, CardContent, IconButton, MenuItem, Skeleton, Stack, Typography } from "@mui/material";

import { NotificationDto } from "dto/user/notification.dto";
import { useAuth } from "hook/useAuth";
import { useResource } from "hook/useResource";
import React, { useState, useEffect } from "react";
import NotificationService from "services/user/notification.service";
import DateTools from "tools/date.tools";
import { Status } from "tools/status";
import { Types } from "tools/types";

import CloseIcon from '@mui/icons-material/Close';
import ResultObjectDTO from "dto/app/resultobject.dto";
import { CommonTools } from "tools/commontools";
import { NotificationParamsList } from "components/notification/NotificationParamsList";
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import DeleteIcon from '@mui/icons-material/Delete';

type TeacherNotificationItemProps = {
    obj: NotificationDto;
    setLoading: any;
    itemIndex: number;
    objects: Array<NotificationDto>;
    setObjects: any
}

const notificationService = new NotificationService();

const TeacherNotificationItem: React.FC<TeacherNotificationItemProps> = ({
    obj,
    setLoading,
    itemIndex,
    objects,
    setObjects,
    ...props
}) => {

    const { LL, CC } = useResource();

    const processAgoTime = () => {
        if (!obj) return (<></>);
        if (!obj.hasOwnProperty('date')) return (<></>);
        if (!obj.date) return (<></>);
        return DateTools.timeAgo(obj.date, LL);
    }

    const [areButtonsDisabled, setAreButtonsDisabled] = useState(false);

    const handleMarkAsRead = () => {
        if (!obj) return;
        if (!obj.id) return;
        setAreButtonsDisabled(true)
        notificationService.markAsRead(obj.id, handleResult, {});
    }

    const updateObjectAtIndex = (index: number, newObj: NotificationDto) => {
        const updatedArray = [...objects];
        updatedArray.splice(index, 1, { ...updatedArray[index], ...newObj });

        setObjects(updatedArray);
    };

    const handleResult = (result: ResultObjectDTO) => {
        if (!result) return;
        if (!result.err) {
            const newObj: NotificationDto = result.obj ? result.obj : {};
            updateObjectAtIndex(itemIndex, newObj);
            setAreButtonsDisabled(false);
        }
    }

    const removeObjectAtIndex = (index: number) => {
        const updatedArray = [...objects];
        updatedArray.splice(index, 1);
        setObjects(updatedArray);
    };

    const handleDelete = () => {
        if (!obj) return;
        if (!obj.id) return;
        setAreButtonsDisabled(true)
        notificationService.delete(obj.id, handleDeleteResult, {});
        
    }

    const handleDeleteResult = (result: ResultObjectDTO) => {
        if (!result) return;
        if (!result.err) {
            removeObjectAtIndex(itemIndex)
            setAreButtonsDisabled(false);
        }
    }

    const processUnRead = () => {
        if (!obj) return (<></>);
        if (!obj.hasOwnProperty('status')) return (<></>);
        if (!obj.status) return (<></>);
        if (obj.status === Status.NOTIFICATION_NEW) {
            return (<FiberManualRecordIcon color={'primary'} />)
        }
        return (<></>)
    }

    const processUnReadButton = () => {
        if (!obj) return (<></>);
        if (!obj.hasOwnProperty('status')) return (<></>);
        if (!obj.status) return (<></>);
        if (obj.status === Status.NOTIFICATION_NEW) {
            return (
                <IconButton
                    disabled={areButtonsDisabled}
                    onClick={handleMarkAsRead}
                >
                    <CloseIcon />
                </IconButton>
            )
        }
        return (<></>)
    }

    const processDeleteButton = () => {
        if (!obj) return (<></>);
        return (
            <IconButton
                onClick={handleDelete}
                disabled={areButtonsDisabled}
            >
                <DeleteIcon color='error' />
            </IconButton>
        )
    }

    const processItem = () => {
        if (!obj) return (<></>);
        if (!obj.hasOwnProperty('id')) return (<></>);
        if (!obj.id) return (<></>);

        return (
            <Box className="notification-tmp" >

                    <Stack
                        direction='row'
                        justifyContent="space-between"
                        alignItems="center"
                        spacing={2}
                        my={2}
                        
                        sx={{ width: '100%',}}
                    >
                        <NotificationParamsList
                                    idNotification={obj.id}
                                />

                        <Stack direction='row' >
                            <Box display="flex" justifyContent="flex-end" alignItems="center">
                                {processUnRead()}
                                <Typography className="data" mx={2}>{processAgoTime()}</Typography>
                            </Box>
                            <Stack direction={'row'} spacing={2}>
                                {processUnReadButton()}
                                {processDeleteButton()}
                            </Stack>
                        </Stack>

                    </Stack>
                </Box>
        )
    }


    return processItem();
};

export { TeacherNotificationItem };
