import React from "react";
import PageComponentProps from "interfaces/pagecomponentprops.interface";
import { TeacherInterfaceTestimonialList } from "components/teacherinterface/TeacherInterfaceTestimonialList";
import Typography from "@mui/material/Typography";
import { useResource } from "hook/useResource";

const TeacherInterfaceTestimonialBlock: React.FC<PageComponentProps> = ({ currentRoute, ...props }) => {
    const {LL} = useResource();
    return (
        <>
         <Typography variant="h3" textAlign='center' my={3}>{LL("Testimonials profesor")}</Typography>
            <TeacherInterfaceTestimonialList currentRoute={currentRoute} />
        </>);
};

export { TeacherInterfaceTestimonialBlock };
