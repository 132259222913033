import Idto from "interfaces/idto.interface";


export class PostNomenclatureDto implements Idto {
    id?: string;
    idlanguage?: string;
    name?: string;
    status?: number;
    ordercriteria?: number;
    idtype?: string;
    attachment?: any;
  video?: any;
  gallery?: any;
}
