import React, { useState, useEffect } from "react";
import { useResource } from "hook/useResource";
import { LessonDto } from "dto/course/lesson.dto";
import RequestFilterDTO from "dto/app/requestfilter.dto";
import RequestListDTO from "dto/app/requestlist.dto";
import { Status } from "tools/status";
import LessonService from "services/course/lesson.service";
import ResultListDTO from "dto/app/resultlist.dto";
import { Accordion, AccordionDetails, AccordionSummary, Box, IconButton, Skeleton, Typography, Stack } from "@mui/material";
import { logger } from "hoc/logger";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ComponentCommonTools } from "tools/componentcommontools";
import { LessonSettingsItem } from 'components/course/lessonsettings/LessonSettingsItem'

import { CommonTools } from "tools/commontools";
import ClearIcon from '@mui/icons-material/Clear';
import RequestCriteriaDTO from "dto/app/requestcriteria.dto";
import { Types } from "tools/types";

import SelectOptionSort from "dto/app/selectoptionsort.dto";
import RequestSortCriteriaDTO from "dto/app/requestsortcriteria.dto";
import { MySortSelect } from "components/elements/Select/MySortSelect";
import { MyButton } from "components/general/MyButton";
import { LoadMoreButton } from "components/elements/MyButton/LoadMoreButton";
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import ResultObjectDTO from "dto/app/resultobject.dto";
import EditLesson from "./EditLesson";


type EditCourseLessonItemProps = {
    [x: string]: any;
    objectFromList: LessonDto;
    idCourse: string;
    objectHasChanged: string;
    setObjectsHasChanged:any;
    setIsLessonInEditMode: any;
}


const EditCourseLessonItem: React.FC<EditCourseLessonItemProps> = ({
    objectFromList,
    idCourse,
    objectHasChanged,
    setObjectsHasChanged,
    setIsLessonInEditMode,
    ...props }) => {

    const { LL, CC } = useResource();
    const [loading, setLoading] = useState(false);
    const [expend, setExpend] = useState(false);
    

    const handleExpend = (event: React.SyntheticEvent, expanded: boolean) => {
        setExpend(expanded);
        setIsLessonInEditMode(expend);
    }

    const processLessonItem = () => {
        if (!objectFromList) return <></>;
        if (!objectFromList.id) return <></>;

        return (
            <Accordion
            

                sx={{ my: 2}}
                expanded={expend}
                className="accordionItem"
                onChange={handleExpend}>

                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    sx={{ flexDirection: 'row-reverse', p:1}}
                    
                >
                    <Stack direction='row' justifyContent='space-between' sx={{ width: '100%' }}>
                        <Typography className="name" ml={1}>
                            {processName()}
                        </Typography>
                    </Stack>
                </AccordionSummary>
                {processLessonDetails()}
            </Accordion>
        );
    };

    const processName = () => {
        if(!objectFromList) return <></>;
        if(expend) return <>{LL("Edit_Lesson")}</>;
        return <>{ComponentCommonTools.processObjectField(objectFromList, 'name')}</>;
    }

    const processLessonDetails = () => {
        if(!objectFromList) return <></>;
        if(!objectFromList.id) return <></>;
        if (!expend) return <></>;
        
        return (
            <AccordionDetails className="accordionDetailLesson">
                <EditLesson 
                id={objectFromList.id} 
                setObjectsHasChanged={setObjectsHasChanged} 
                setExpend={setExpend}
                idCourse={idCourse}
                />
            </AccordionDetails>
        )
    }

    return loading ? <Skeleton /> : (processLessonItem());
};

export { EditCourseLessonItem };
