import React, { useEffect, useState } from "react";
import PageComponentProps from "interfaces/pagecomponentprops.interface";
import { useResource } from "hook/useResource";
import { logger } from "hoc/logger";

import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'

import UserService from "services/user/user.service";
import Grid from '@mui/material/Grid';
import { useAuth } from "hook/useAuth";
import { Divider } from "@mui/material";
import EditUserSettingsInfo from "./EditUserSettingsInfo";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { TabPanel } from 'components/elements/Tabs/TabPanel';

import { CircularLoading } from 'components/general/CircularLoading';

import { MyButton } from "components/general/MyButton";
import { RouteTools } from "tools/routetools";
import UserDto from "dto/user/user.dto";
import ValidateTools from 'tools/validate.tools';
import RequiredValidator from 'validators/required.validator';
import { LabelTypes } from 'tools/labeltypes';

import MyTextField from 'components/form/mytextfield';
import { CommonTools } from "tools/commontools";
import { MyBreadcrumbs } from "components/elements/MyBreadcrumbs/MyBreadcrumbs";
import { IconButton } from "@mui/material";
import { MyNavBt } from "components/general/MyNavBt";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { ChangePasswordForm } from "components/auth/ChangePasswordForm";
import { AddSocialUser } from "components/social/AddSocialUser";
import AddUserPhone from "components/user/AddUserPhone";
import AddUserAddress from "components/user/AddUserAddress";
import { UserEditStudent } from 'components/student/UserEditStudent';

import { UserEditTeacher } from 'components/teacher/UserEditTeacher';

import Button from '@mui/material/Button';
import Container from "@mui/material/Container";
import { EditStudentNotification } from "components/student/EditStudentNotification";

const userService = new UserService();

const EditUserProfile: React.FC<PageComponentProps> = ({
    currentRoute,
    ...props
}) => {
    const mainObject = currentRoute._mainobject;

    const { LL, isTeacher } = useResource();
    const { user } = useAuth();

    const [loading, setLoading] = useState<boolean>(true);
    const [obj, setObj] = useState<UserDto | undefined>(user);
    const _id = user !== undefined ? user.id : '';
    const [id, setId] = useState<string>(_id);
    

    useEffect(() => {
        
        if (user !== undefined) {
            setId(user.id)
            setLoading(false)
        }
    }, [user])

 

    const [value, setValue] = React.useState(CommonTools.getAnchor(currentRoute, 'generalInfo'));

    const handleChange = (event: React.SyntheticEvent, newValue: any) => {
        setValue(newValue);
        RouteTools.setAnchor(newValue)
    };

    const ChildsCrumbs = [
        { url: "/", label: LL("crumb_homepage") },
        {
            url: '/profile',
            label: LL("crumb_personal_cabinet"),
        },
        {
            label: LL("crumbEdit_" + mainObject),
        },
    ];
    const mainUrl = CommonTools.generateMainUrl(mainObject);
    const goToProfile = () => {
        RouteTools.setHistory('/profile', { tabValue: value })
    }
    return user !== undefined && !loading && obj ? (
        <>


            <Box className='container' textAlign="center"><Typography variant='h3' py={3}>{LL("Edit_personal_cabinet")}</Typography></Box>

            <Divider/>
            <Box sx={{ flexGrow: 1 }} className='container' mt={2}>
                <Grid container spacing={2}>
                    <Grid item alignItems='center' justifyContent="center" display="flex">
                        <Tabs
                            variant="scrollable"
                            value={value}
                            onChange={handleChange}
                            orientation="horizontal"
                            scrollButtons
                            allowScrollButtonsMobile
                            className="tabVertical profileTeacher"

                        >
                            <Tab label={LL("General_Info")} value={'generalInfo'} />
                            <Tab label={LL("Change_Password")} value={'changePassword'} />
                            <Tab label={LL("User_Social")} value={'socialLink'} />
                            <Tab label={LL("User_Phone")} value={'phone'} />
                            <Tab label={LL("User_Address")} value={'address'} />
                            <Tab label={LL("Student_Info")} value={'studentInfo'} />
                            <Tab label={LL("Notification")} value={'notification'} />
                        </Tabs>
                    </Grid>
                    <Grid item>
                        <Box className=''>

                            <TabPanel value={value} index={'generalInfo'}>
                                <EditUserSettingsInfo currentRoute={currentRoute} />
                            </TabPanel>
                            <TabPanel value={value} index={'changePassword'}>
                                <ChangePasswordForm currentRoute={currentRoute} />
                            </TabPanel>
                            <TabPanel value={value} index={'socialLink'}>
                                <AddSocialUser currentRoute={currentRoute} />
                            </TabPanel>
                            <TabPanel value={value} index={'phone'}>
                                <AddUserPhone currentRoute={currentRoute} />
                            </TabPanel>
                            <TabPanel value={value} index={'address'}>
                                <AddUserAddress currentRoute={currentRoute} />
                            </TabPanel>
                            <TabPanel value={value} index={'studentInfo'}>
                                <UserEditStudent currentRoute={currentRoute} />
                            </TabPanel>
                            <TabPanel value={value} index={'notification'}>
                                <EditStudentNotification currentRoute={currentRoute} />
                            </TabPanel>
                        </Box>
                    </Grid>
                </Grid>

            </Box>

        </>
    ) : (
        <CircularLoading />
    );
};

export { EditUserProfile };
