import React, { useCallback, useEffect, useRef, useState } from "react";
import { useResource } from "hook/useResource";
import { logger } from "hoc/logger";
import CourseService from "services/course/course.service";
import PageComponentProps from "interfaces/pagecomponentprops.interface";
import { CourseDto } from "dto/course/course.dto";
import { RouteTools } from "tools/routetools";
import RequestListDTO from "dto/app/requestlist.dto";
import { Status } from "tools/status";
import RequestFilterDTO from "dto/app/requestfilter.dto";
import ResultListDTO from "dto/app/resultlist.dto";
import { Types } from "tools/types";
// import { InfoCourseItem } from "components//InfoCourseItem";
import { Loading } from "components/general/Loading";
import Skeleton from '@mui/material/Skeleton';
import { Box, Typography, Grid } from "@mui/material";
import ResultObjectDTO from "dto/app/resultobject.dto";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { ComponentCommonTools } from "tools/componentcommontools";
import Stack from '@mui/material/Stack';
import AppBar from '@mui/material/AppBar';
import { MyNavBt } from "components/general/MyNavBt";
import { CourseCard } from "components/course/course/CourseCard";
import { Carousel } from "components/elements/Carousel/Carousel";
import RequestSortCriteriaDTO from "dto/app/requestsortcriteria.dto";
import { MyPagination } from "components/elements/MyPagination/MyPagination";
import { useCookies } from 'react-cookie';
import { CommonTools } from "tools/commontools";

import { FilterBox } from "../filter/FilterBox";
import RequestCriteriaDTO from "dto/app/requestcriteria.dto";
import { MySortSelect } from "components/elements/Select/MySortSelect";
import SelectOptionSort from "dto/app/selectoptionsort.dto";
import { NewsletterBlock } from "components/general/NewsletterBlock";
import { CourseListCard } from "components/course/course/CourseListCard";
import { CourseCategoryDto } from "dto/course/coursecategory.dto";
import Alert from '@mui/material/Alert';
import { NoResult } from "components/general/NoResult";

const courseService = new CourseService();

type CourseListProps = {
    currentRoute: any;
    idCategory: string;
    categoryObject: CourseCategoryDto | undefined;
}

const CourseList: React.FC<CourseListProps> = ({
    currentRoute,
    idCategory,
    categoryObject,
    ...props
}) => {

    const { LL, CC } = useResource();

    const mainUrl = currentRoute && currentRoute.url ? currentRoute.url.split("?") : "";
    const [isLoading, setIsLoading] = useState(true);
    const [objects, setObjects] = useState<Array<CourseDto>>([]);
    const [cookies, setCookies] = useCookies();
    const [page, setPage] = useState<number>(1);
    const [total, setTotal] = useState<number>(-1);
    const [totalPage, setTotalPage] = useState<number>(-1);
    const [responseParams, setResponseParams] = useState<any>({});
    const [onPage, setOnPage] = useState<number>(parseInt(CC('CourseListOnPage', '10')));
    const [reqList, setReqList] = useState<RequestListDTO>(new RequestListDTO());

    const [filters, setFilters] = useState<Array<RequestCriteriaDTO>>(new Array<RequestCriteriaDTO>());
    
    const [filtersRange, setFiltersRange] = useState<Array<RequestCriteriaDTO>>(new Array<RequestCriteriaDTO>());

    const [sortCriteria, setSortCriteria] = useState<Array<RequestSortCriteriaDTO>>(new Array<RequestSortCriteriaDTO>());
    const [filterRating, setFilterRating] = useState<RequestFilterDTO | undefined>();
    
    const getList = () => {

        setIsLoading(true);

        reqList.onpage = onPage;
        reqList.page = page;
        reqList.filters = [];
        reqList.sortcriteria = sortCriteria;
        reqList.criteria = filters;
        reqList.range = filtersRange;


        let f: RequestFilterDTO;

        f = new RequestFilterDTO();
        f.field = "status";
        f.values = [];
        f.values.push(Status.ACTIVE.toString());
        reqList.filters.push(f);
        f = new RequestFilterDTO();
        f.field = "idcoursecategory";
        f.values = [idCategory];
        reqList.filters.push(f);
        if (filterRating) reqList.filters.push(filterRating);

        const urlPart = RouteTools.prepareListLocation(reqList);

        RouteTools.setHistory(mainUrl[0] + urlPart, {});
        courseService.getList(loadObjects, {}, reqList);

        // setIsLoading(false);
    };

    const defaultSortCriteria = () => {
        if (!sortCriteria || sortCriteria.length === 0) {
            const sortCriteria = new RequestSortCriteriaDTO();
            sortCriteria.field = "popularidentifier";
            sortCriteria.asc = false;
            setSortCriteria([sortCriteria]);
        }
    }

    useEffect(() => {
        const reqListRoute = RouteTools.prepareListRequest(currentRoute, cookies);
        if (reqListRoute.page) setPage(reqListRoute.page);
        if (reqListRoute.criteria && reqListRoute.criteria.length > 0) {
            setFilters(reqListRoute.criteria);
        }
        if (reqListRoute.range && reqListRoute.range.length > 0) {
            setFiltersRange(reqListRoute.range);
        }
        if(reqListRoute.filters && reqListRoute.filters.length > 0) {
            const existingFilter = reqListRoute.filters.find(filter => filter.field === 'fromteacherrating');
            setFilterRating(existingFilter);
        }
        if (reqListRoute.sortcriteria && reqListRoute.sortcriteria.length > 0) {
            setSortCriteria(reqListRoute.sortcriteria);
        } else {
            defaultSortCriteria();
        }

    }, []);

    const loadObjects = (result: ResultListDTO) => {
        if (!result) return;
        if (!result.err) {
            const objects = result.objects ? result.objects : [];
            const total = result.total !== undefined ? result.total : -1;
            const totalPage = result.totalpages !== undefined ? result.totalpages : -1;
            const reqInfo = result.requestinfo ? result.requestinfo : {};
            setObjects(objects);
            setTotal(total);
            setTotalPage(totalPage);
            setResponseParams(reqInfo);
        }
    }



    const checkLoading = () => {
        let load = false;

        if (total === -1) return true;
        if (totalPage === -1) return true;
        if (!objects) load = true;
        // if (objects.length === 0) load = true;
        if (Object.keys(responseParams).length === 0) return true;

        setIsLoading(load);
    }
    useEffect(() => {
        if (sortCriteria && sortCriteria.length > 0) getList();
    }, [page, filters, filtersRange, sortCriteria ,filterRating]);

    useEffect(() => {
        checkLoading();
    }, [objects]);

    const processPagination = () => {
        if (totalPage === -1) return <></>;
        if (total === -1) return <></>;
        if (objects.length === 0) return <></>;

        return (
            <Box className="container" py={4} display='flex' sx={{ justifyContent: 'center' }}>
                <MyPagination
                    page={page}

                    total={total}
                    totalPage={totalPage}
                    setPage={setPage} />
            </Box>);
    }



    const processSortSelect = () => {
        if (isLoading) return <></>;

        const objects = ['popularidentifier_asc', 'popularidentifier_desc', 'name_asc', 'name_desc'];
        return (
            <Box mb={4} sx={{
                "& label": {
                    display: 'none',
                },
                maxWidth: '300px'
            }}>
                <MySortSelect
                    objects={objects}
                    setSortCriteria={setSortCriteria}
                    sortCriteria={sortCriteria}
                    variant="standard"
                    className="form-select"


                />
            </Box>
        )
    }



    const processCourseItem = (obj: CourseDto, index: number) => {

        if (!obj) return <></>;
        return (<Box key={index}>
            <CourseListCard obj={obj} loading={isLoading} />
        </Box>);

    };

    const processNumberOfResults = () => {
        if (isLoading) return <></>;
        if (total === -1) return <></>;
        return (<Box>
            <Typography >{total} {LL('Results')}</Typography>
        </Box>
        )
    }

    const processCourseList = () => {
        if (!objects.length) return <NoResult identifier="course" />;

        return (
            <>
                {objects.map((item: CourseDto, i: number) => {
                    return processCourseItem(item, i);
                })}
            </>
        );
    };

    const processFilterBox = () => {

        return (
            <>
                <FilterBox
                    currentRoute={currentRoute}
                    idCategory={idCategory}
                    setFilters={setFilters}
                    filters={filters}
                    setFiltersRange={setFiltersRange}
                    filtersRange={filtersRange}
                    numberOfResults={total}
                    setFilterRating={setFilterRating}
                    filterRating={filterRating}
                />
            </>

        )
    }

    const processCourseCategoryName = () => {
        if (!objects) return <></>;
        if (!categoryObject) return <></>;
        return (
            <>
                {ComponentCommonTools.processObjectField(categoryObject, 'name')}
            </>
        )
    }
    return (
        <Box className='courseLIST'>
            <Box className="banner"><Box className="container"><Typography variant="h3" my={4}> {LL('gasestecursulpotrivit')}</Typography></Box></Box>
            <Box className="container" py={5}>
                <Typography variant="h2" mb={4}> {LL('ListCourseSubject')} {processCourseCategoryName()}</Typography>
                <Alert severity="info" className="alert">{LL('Nu esti sigur')}</Alert>
                <Grid container spacing={2} mt={4}>
                    <Grid item sm={12} lg={3}>
                        {processFilterBox()}
                    </Grid>
                    <Grid item sm={12} lg={9}>
                        {processSortSelect()}
                        {processNumberOfResults()}
                        {isLoading ? <Skeleton /> : processCourseList()}
                        {isLoading ? <Skeleton /> : processPagination()}
                    </Grid>
                </Grid></Box>
            <Box><NewsletterBlock currentRoute={currentRoute} /></Box>
        </Box>

    );


};

export { CourseList };
