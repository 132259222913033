import React, { useEffect, useState } from "react";
import { useResource } from "hook/useResource";
import { logger } from "hoc/logger";
import BlogCategoryService from "services/blog/blogcategory.service";
import MenuLevelProps from "interfaces/menulevelprops.interface";
import { BlogCategoryDto } from "dto/blog/blogcategory.dto";
import { RouteTools } from "tools/routetools";
import RequestListDTO from "dto/app/requestlist.dto";
import { Status } from "tools/status";
import RequestFilterDTO from "dto/app/requestfilter.dto";
import ResultListDTO from "dto/app/resultlist.dto";
import { Types } from "tools/types";
import MenuItemProps from "interfaces/menuitemprops.interface";
import { MyNavBt } from "components/general/MyNavBt";
import { BlogCategoryLevel } from "./BlogCategoryLevel";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { MyButton } from "components/general/MyButton";
import { type } from "os";
import { ComponentCommonTools } from "tools/componentcommontools";
import { Stack } from "@mui/material";
import { TreeItem } from '@mui/x-tree-view/TreeItem';

type BlogCategoryItemProps = {
    currentRoute: any;
    level: number;
    obj: BlogCategoryDto;
    hasChildren?: boolean;
    setBlogCategoryId?: any
}

const BlogCategoryItem: React.FC<BlogCategoryItemProps> = ({
    currentRoute,
    level,
    obj,
    hasChildren,
    setBlogCategoryId,
    ...props
}) => {
    const [nextLevel, setNextLevel] = useState<number>(1);
    const [maxLevel, setMaxLevel] = useState<number>(1);
    const [isChildsShowed, setIsChildsShowed] = useState<boolean>(true);

    const { CC } = useResource()

    useEffect(() => {
        let ml: number = parseInt(CC("MAXLEVEL_Category_Blog", "6"));
        ml = isNaN(ml) || ml == undefined ? 1 : ml;
        setMaxLevel(ml);

    }, []);

    useEffect(() => {
        setNextLevel(level + 1);
    }, [level]);

    // useEffect(() => {
    //     setIsChildsShowed(false);
    // }, [obj]);



    const processDetail = () => {
        if (!obj) return <></>;
        if (!obj.hasOwnProperty('id')) return <></>;
        if (!obj.id) return <></>;
        return (
            <Box onClick={() => { setBlogCategoryId(obj.id) }} className="item">

                    {ComponentCommonTools.processObjectField(obj, 'name')}

            </Box>);
    };

  
    // const processIcon = () => {
    //     if (!obj) return <></>;
    //     if (!obj.hasOwnProperty('icon')) return <></>;
    //     if (!obj.icon) return <></>;
    //     return ComponentCommonTools.getFileBlockSVG(obj.icon);
    // }
    const processChilds = () => {
        if (!hasChildren) return <></>;
        if (nextLevel > maxLevel) return <></>;
        // if (!isChildsShowed) return <></>;
        if (!obj) return <></>;

        return (
            <Box className={"section" + level}>
                <BlogCategoryLevel
                        currentRoute={currentRoute}
                        level={nextLevel}
                        idparent={obj.id ?? ""}
                        setBlogCategoryId={setBlogCategoryId}
                    />
            </Box>
        );
    };



    return (
        <>
            <Box 
                onMouseOut={() => {
                    setIsChildsShowed(false);
                }}
                onMouseOver={() => {
                    setIsChildsShowed(true);
                }}

            >
                {processDetail()}
                {/* {processBts()} */}
                {processChilds()}
            </Box>
        </>
    );

};

export { BlogCategoryItem };
