import Idto from 'interfaces/idto.interface';
import { PostTestResultAnswerDto } from './testresultanswer.dto';

export class TestResultQuestionDto implements Idto {

    id?: string;
    idquestion?: string;
    idtestresult?: string;
    maxquestionscore?: number;
    questiontotalscore?: number;
    question?: any;
    testresult?: any;
}

export class PostTestResultQuestionDto implements Idto {

    idquestion?: string;
    idtestresult?: string;
    maxquestionscore?: number;
    questiontotalscore?: number;
 
    constructor () {
        this.questiontotalscore = 0;
    }
}


export class PostTestResultQuestionAndAnswerDto implements Idto {
    question?: PostTestResultQuestionDto;
    answers?: PostTestResultAnswerDto[];
}

