import React, { useState, useEffect } from "react";
import { useResource } from "hook/useResource";
import { FilterDto } from "dto/course/filter.dto";
import RequestFilterDTO from "dto/app/requestfilter.dto";
import RequestListDTO from "dto/app/requestlist.dto";
import { Status } from "tools/status";
import FilterService from "services/course/filter.service";
import ResultListDTO from "dto/app/resultlist.dto";
import { Accordion, AccordionDetails, AccordionSummary, Box, IconButton, Skeleton, Typography, Stack, Drawer } from "@mui/material";
import { logger } from "hoc/logger";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ComponentCommonTools } from "tools/componentcommontools";
import { Filter } from "@mui/icons-material";
import { FilterDictionaryItem } from "../filterdictionary/FilterDictionaryItem";
import { CommonTools } from "tools/commontools";
import ClearIcon from '@mui/icons-material/Clear';
import RequestCriteriaDTO from "dto/app/requestcriteria.dto";
import { Types } from "tools/types";
import { FilterTypeRange } from 'components/course/filter/FilterTypeRange';
import SelectOptionSort from "dto/app/selectoptionsort.dto";
import RequestSortCriteriaDTO from "dto/app/requestsortcriteria.dto";
import { MySortSelect } from "components/elements/Select/MySortSelect";
import { set } from "date-fns";
import FilterListIcon from '@mui/icons-material/FilterList';
import { MyButton } from "components/general/MyButton";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { FilterRating } from "./FilterRating";


type FilterBoxProps = {
  currentRoute: any;
  idCategory: string;
  setFilters: any;
  filters: Array<RequestCriteriaDTO>;
  setFiltersRange: any;
  filtersRange: Array<RequestCriteriaDTO>;
  numberOfResults: number;
  setFilterRating: any;
  filterRating?: RequestFilterDTO;
  window?: () => Window;
}

const drawerWidth = 240;

const filterService = new FilterService();

const FilterBox: React.FC<FilterBoxProps> = ({
  currentRoute,
  idCategory,
  setFilters,
  filters,
  filtersRange,
  setFiltersRange,
  numberOfResults,
  setFilterRating,
  filterRating,
  window,
  ...props }) => {

  const { LL } = useResource();
  const [isLoading, setIsLoading] = useState(true);
  const [objects, setObjects] = useState<Array<FilterDto>>([]);

  const theme = useTheme();
  const isSmUp = useMediaQuery(theme.breakpoints.up('md'));

  const container = (typeof window !== 'undefined' && window) ? window().document.body : undefined;


  const getList = () => {

    setIsLoading(true);
    const reqList = new RequestListDTO();
    reqList.onpage = 20;
    reqList.page = 1;
    reqList.filters = [];


    let f: RequestFilterDTO;

    f = new RequestFilterDTO();
    f.field = "status";
    f.values = [];
    f.values.push(Status.ACTIVE.toString());
    reqList.filters.push(f);


    filterService.getList(loadObjects, {}, reqList);

    // setIsLoading(false);
  };


  const loadObjects = (result: ResultListDTO) => {
    if (!result) return;
    if (!result.err) {
      const objects = result.objects ? result.objects : [];
      // const total = result.total !== undefined ? result.total : -1;
      // const totalPage = result.totalpages !== undefined ? result.totalpages : -1;
      // const reqInfo = result.requestinfo ? result.requestinfo : {};
      setObjects(objects);
      // setTotal(total);
      // setTotalPage(totalPage);
      // setResponseParams(reqInfo);
    }
  }

  const checkLoading = () => {
    let load = false;

    // if (total === -1) return true;
    // if (totalPage === -1) return true;
    if (!objects) load = true;
    if (objects.length === 0) load = true;
    // if (Object.keys(responseParams).length === 0) return true;

    setIsLoading(load);
  }

  useEffect(() => {
    getList();
  }, []);

  useEffect(() => {
    checkLoading();
  }, [objects]);

  const [expanded, setExpanded] = useState<boolean>(false);
  const [rendered, setRendered] = useState<boolean>(false);

  const handleAccordionChange = (event: any, isExpanded: boolean) => {
    setExpanded(isExpanded);
  };

  useEffect(() => {
    if (expanded && !rendered) {
      setRendered(true);
    }
  }, [expanded, rendered]);


  const clearFilters = () => {
    if (filters && filters.length > 0) setFilters([]);
    if (filtersRange && filtersRange.length > 0) setFiltersRange([]);
    if (filterRating) setFilterRating(CommonTools.addFilterRating(0));
  }

  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    if (!isSmUp) {
      setMobileOpen(!mobileOpen);
    }
  };

const processFilterRatingIsSet = () => {
  if(!filterRating) return 0;
  if(!filterRating.values) return 0;
  if(filterRating.values.length == 0) return 0;
  if(!filterRating.values[0]) return 0;
  if(parseFloat(filterRating.values[0]) == 0) return 0;
  return 1;
}


  const processFilterToolBox = () => {
    if (!filters && !filtersRange) return <Box onClick={handleDrawerToggle}><Typography component='div' className="btn">
      <FilterListIcon sx={{ mr: 1 }} />{LL('Filter')}</Typography></Box>;
    const counter = filters.length + filtersRange.length + processFilterRatingIsSet();
    if (!counter) return <Box onClick={handleDrawerToggle}><Typography component='div' className="btn">
      <FilterListIcon sx={{ mr: 1 }} />{LL('Filter')}</Typography></Box>;
    return (
      <Stack direction='row' spacing={1}>
        {/* <Typography component='div' className="btn">
          <FilterListIcon sx={{ mr: 1 }} />
          {LL('Filter')} ({counter})
        </Typography> */}
        <Box onClick={handleDrawerToggle}>
          <Typography component='div' className="btn">
            <FilterListIcon sx={{ mr: 1 }} />
            {LL('Filter')} ({counter})
          </Typography>
        </Box>
        <Box>
          <IconButton onClick={clearFilters}>
            <ClearIcon />
          </IconButton>
          {LL('Clear Filters')}
        </Box>
      </Stack>
    )

  }

  const processNumberOfResults = () => {
    if (isLoading) return <></>;
    if (numberOfResults === -1) return <></>;
    return (<Box>
      <Typography >{numberOfResults} {LL('Results')}</Typography>
    </Box>
    )
  }

  const processFilterToolBoxDrawer = () => {
    if (!filters && !filtersRange) return <></>;
    const counter = filters.length + filtersRange.length + processFilterRatingIsSet();;
    if (!counter) return <></>;;
    return (
      <Box>
        {processNumberOfResults()}
        <IconButton onClick={clearFilters}>
          <ClearIcon />
        </IconButton>
        {LL('Clear Filters')}
      </Box>
    )

  }


  const processFilterDictionaryItem = (idFilter: string) => {
    if (!idFilter) return <></>;
    if (!rendered) return <></>;
    return <FilterDictionaryItem
      currentRoute={currentRoute}
      idFilter={idFilter}
      filters={filters}
      setFilters={setFilters}
    />
  }

  const processFilterRangeItem = (idFilter: string) => {
    if (!idFilter) return <></>;


    return <FilterTypeRange
      currentRoute={currentRoute}
      idFilter={idFilter}
      filtersRange={filtersRange}
      setFiltersRange={setFiltersRange}
    />


  }

  const processFilterRating = () => {

    return (<Box >
      <Accordion className="accordion"
        onChange={handleAccordionChange}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          className="summary"
        >
          <Typography variant="h5">{LL("Teacher_Rating")}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <FilterRating
            setFilters={setFilterRating}
            filters={filterRating}
          />
        </AccordionDetails>
      </Accordion>
    </Box>)
  }

  const processFilterDetails = (obj: FilterDto) => {
    if (!obj) return <></>;
    if (!obj.id) return <></>;
    if (obj.type === Types.FILTER_TYPE_DICTIONARY) return processFilterDictionaryItem(obj.id);
    if (obj.type === Types.FILTER_TYPE_RANGE) return processFilterRangeItem(obj.id);
  }

  const processFilterItem = (obj: FilterDto, index: number) => {
    if (!obj) return <></>;
    if (!obj.id) return <></>;


    return (<Box key={index}>
      <Accordion className="accordion"
        onChange={handleAccordionChange}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          className="summary"
        >
          <Typography variant="h5">{ComponentCommonTools.processObjectField(obj, 'name')}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          {processFilterDetails(obj)}
        </AccordionDetails>
      </Accordion>
    </Box>);

  };

  const processFilterList = () => {
    if (!objects.length) return <></>;

    return (
      <>
        {processFilterRating()}
        {objects.map((item: FilterDto, i: number) => {
          return processFilterItem(item, i);
        })}
      </>
    );
  };

  const processFilterListContent = () => (
    <>
      <Box mt={4}>
        {processFilterList()}
      </Box>
    </>
  );


  return isLoading ? <Skeleton /> : (
    <Box className="filterLeft">

      {processFilterToolBox()}
      <Drawer
        container={container}
        variant="temporary"
        sx={{
          display: { xs: 'block', sm: 'block', md: 'none', lg: 'none', xl: 'none' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
        }}
        open={mobileOpen}
        anchor="right"
        onClose={handleDrawerToggle}
      >
        {processFilterToolBoxDrawer()}
        {processFilterListContent()}
      </Drawer>
      <Box
        sx={{
          display: { xs: 'none', sm: 'none', md: 'block', lg: 'block', xl: 'block' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
        }}
      >
        {processFilterListContent()}
      </Box>
    </Box>
  );
};

export { FilterBox };
