import Idto from 'interfaces/idto.interface';

export class SiteSocialDto implements Idto {
    
    id?: string;
    link?: string;
    idtypesocial?: string;
    typesocial?: any;
    status?: number;
  }

  export class PostSiteSocialDto implements Idto {
    
    
    link?: string;
    idtypesocial?: string;
    typesocial?: any;
    status?: number;
  }