import React, { useState } from "react";
import PageComponentProps from "interfaces/pagecomponentprops.interface";
import {
    Stepper,
    Step,
    StepLabel,
    Typography,
    Box,

} from '@mui/material';
import { useResource } from "hook/useResource";
import { BecomeTeacherStepPage } from "components/static/page/BecomeTeacherStepPage";
import BorderColorIcon from '@mui/icons-material/BorderColor';
import Groups2Icon from '@mui/icons-material/Groups2';
import CastIcon from '@mui/icons-material/Cast';
import CreditCardIcon from '@mui/icons-material/CreditCard';




const StepperBecomeTeacher: React.FC<PageComponentProps> = ({ currentRoute, ...props }) => {

    const [activeStep, setActiveStep] = useState(0);
    const { LL } = useResource();

    const steps = [
        LL('Create_and_public_course'),
        LL('Students_select_the_course'),
        LL('Go_live_to_teach'),
        LL('Get_paid')
    ];

    const getStepIcon = (step: number) => {
        switch (step) {
            case 0:
                return <div className="step1 stepp"></div>;
            case 1:
                return <div className="step2 stepp"></div>;
            case 2:
                return  <div className="step3 stepp"></div>;
            case 3:
                return  <div className="step4 stepp"></div>;
            default:
                return <></>;
        }
    };

    
    const getStepContent = (step: number) => {
        const idPage1 = process.env.REACT_APP_DEFAULT_ID_PAGE_STEP_1 ?? '';
        const idPage2 = process.env.REACT_APP_DEFAULT_ID_PAGE_STEP_2 ?? '';
        const idPage3 = process.env.REACT_APP_DEFAULT_ID_PAGE_STEP_3 ?? '';
        const idPage4 = process.env.REACT_APP_DEFAULT_ID_PAGE_STEP_4 ?? '';
        switch (step) {
            case 0:
                return (
                    <BecomeTeacherStepPage
                        idParam={idPage1}
                        index={0} />
                )
            case 1:
                return (
                    <BecomeTeacherStepPage
                        idParam={idPage2}
                        index={1} />
                )
            case 2:
                return (
                    <BecomeTeacherStepPage
                        idParam={idPage3}
                        index={2} />
                )
            case 3:
                return (
                    <BecomeTeacherStepPage
                        idParam={idPage4}
                        index={3} />
                ) 
            default:
                return 'Unknown step';
        }
    }

    const processStepper = () => {
        return (
            <Box className='container becomeSteps' my={5}>
                <Typography variant="h2" textAlign='center' mb={4}>{LL('Cum ajungi să faci bani din pasiunea ta')}</Typography>
                <Stepper nonLinear activeStep={activeStep} sx={{alignItems:'flex-start'}}>
                    {steps.map((label, index) => (
                        <Step 
                        key={label} 
                        onClick={() => setActiveStep(index)}
                        className="step"
                        >
                            <StepLabel icon={getStepIcon(index)}>
                            <Typography className="stepText">{label}</Typography>
                            </StepLabel>
                        </Step>
                    ))}
                </Stepper>
                <Box my={5} width={1500} className='detailsContentBecome'>
                    {getStepContent(activeStep)}
                </Box>
            </Box>
        )
    }
    return processStepper();
};

export { StepperBecomeTeacher };
