import React, { useEffect, useState } from "react";
import { useResource } from "hook/useResource";
import { useAuth } from "hook/useAuth";
import PageComponentProps from "interfaces/pagecomponentprops.interface";
import AppBar from "@mui/material/AppBar";
import LockOpenIcon from "@mui/icons-material/LockOpen";

import SignInDto from "dto/auth/signin.dto";

import Button from "@mui/material/Button";
import { Loading } from "components/general/Loading";
import MyTextField from "components/form/mytextfield";
import MyCheckboxField from "components/form/mycheckboxfield";
import RequiredValidator from "validators/required.validator";
import { LabelTypes } from "tools/labeltypes";
import ValidateTools from "tools/validate.tools";

import InputAdornment from "@mui/material/InputAdornment";

import MailOutlineIcon from "@mui/icons-material/MailOutline";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { useGoogleRecaptcha } from "hook/useGoogleRecaptcha";
import { logger } from "hoc/logger";
import Typography from "@mui/material/Typography";
import SignUpDto from "dto/auth/signup.dto";
import { MyNavBt } from "components/general/MyNavBt";
import { AuthMethodsBlock } from "components/auth/AuthMethodsBlock";
import Container from "@mui/material/Container";
import { PasswordStrengthChecker } from "components/auth/PasswordStrengthChecker";
import { RegisterUserDto } from "dto/auth/registeruser.dto";
import MyDatePicker from "components/elements/MyDatePicker/MyDatePicker";
import { PhoneTypeSelect } from "components/elements/Select/PhoneTypeSelect";
import { CountryCodeSelect } from "components/elements/Select/CountryCodeSelect";
import { LanguageSelect } from "components/elements/Select/LanguageSelect";
import { CommonTools } from "tools/commontools";

const SignUp: React.FC<PageComponentProps> = ({ currentRoute, ...props }) => {
  const { signUp } = useAuth();

  const { executeRecaptcha } = useGoogleRecaptcha();

  const { LL } = useResource();
  const [isLoading, setIsLoading] = useState(true);
  const [isFormDisabled, setIsFormDisabled] = useState(true);

  const [obj, setObj] = useState<RegisterUserDto>(new RegisterUserDto());
  const [validators, setValidators] = useState(false);
  const [vresults, setVResults] = useState<any>(false);

  const [term, setTerm] = useState(false);

  const validateAll = () => {
    if (!obj) return;
    if (!validators) return;
    // if (vresults) return;

    obj.email = obj.email != undefined ? obj.email : "";
    obj.password = obj.password != undefined ? obj.password : "";
    obj.name = obj.name != undefined ? obj.name : "";
    obj.surname = obj.surname != undefined ? obj.surname : "";
    obj.birthday = obj.birthday !== undefined ? obj.birthday : undefined;
    obj.surname = obj.surname != undefined ? obj.surname : "";
    obj.phonenumber = obj.phonenumber != undefined ? obj.phonenumber : "";
    obj.countrycode = obj.countrycode != undefined ? obj.countrycode : "";
    obj.idtypephone = obj.idtypephone != undefined ? obj.idtypephone : "";
    obj.idlanguage = obj.idlanguage != undefined ? obj.idlanguage : "";
    obj.notificationtype1 =
      obj.notificationtype1 != undefined ? obj.notificationtype1 : false;

    setObj(obj);

    let t = validateField("email", obj.email);
    t = validateField("password", obj.password, t);
  };

  const validateField = (field: any, value: any, v?: any) => {
    v = v ? v : JSON.parse(JSON.stringify(vresults));
    if (v === false) v = {};
    v[field] = ValidateTools.validate(field, value, validators, vresults);

    setVResults(v);
    return v;
  };
  const [confirmPassword, setConfirmPassword] = useState("");
  const processValidators = () => {
    let v: any = {
      email: [],
      password: [],
    };

    // v.email.push(new RequiredValidator(LL('Required field email', LabelTypes.LABEL_VALIDATION), ''));
    // v.password.push(new RequiredValidator(LL('Required field password', LabelTypes.LABEL_VALIDATION), ''));
    v.email.push(new RequiredValidator("", ""));
    v.password.push(new RequiredValidator("", ""));
    setValidators(v);
  };

  const checkIsLoading = () => {
    if (!isLoading) return;
    if (!obj) return;
    if (!validators) return;
    validateAll();
    setIsLoading(false);
  };

  useEffect(() => {
    processValidators();
  }, []);

  useEffect(() => {
    checkIsLoading();
  }, [obj, validators]);

  useEffect(() => {
    checkIsFormDisabled();
  }, [vresults, term, confirmPassword]);

  const setObjField = (field: any, value: any) => {
    let t = JSON.parse(JSON.stringify(obj));
    t[field] = value;
    setObj(t);

    validateField(field, value);
  };

  const setObjTerm = (value: any) => {
    setTerm(value);
  };

  const checkIsFormDisabled = () => {
    var rez = ValidateTools.vForm(vresults, "email");
    rez = ValidateTools.vForm(vresults, "password", rez);
    if (obj.password !== confirmPassword) {
      rez = true;
    }
    if (term == false) rez = true;
    setIsFormDisabled(rez);
  };

  const setCheckNotification = (value: any) => {
    let t = JSON.parse(JSON.stringify(obj));

    t["notificationtype1"] = value;
    setObj(t);
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    if (isFormDisabled) return false;
    await executeRecaptcha();

    signUp(obj);
  };

  return isLoading ? (
    <Loading />
  ) : (
    <Container maxWidth="sm">
      <Typography variant="h2" align="center" sx={{ my: 5 }}>
        {LL("SignUp")}
      </Typography>
      <Container maxWidth="xs" sx={{ p: 0 }}>
        <Box
          sx={{
            alignItems: "center",
            alignContent: "center",
            width: "100%",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <form onSubmit={handleSubmit}>
            <Box mb={4}>
              <Typography variant="h6">{LL("Name")}</Typography>
              <MyTextField
                fullWidth
                id="name"
                name="name"
                className="form-input"
                variant="standard"
                _label={LL("Name")}
                value={obj.name}
                setObj={setObjField}
                _vresults={vresults && vresults.name ? vresults.name : false}
              />
            </Box>
            <Box mb={4}>
              <Typography variant="h6">{LL("Surname")}</Typography>
              <MyTextField
                fullWidth
                id="surname"
                name="surname"
                className="form-input"
                variant="standard"
                _label={LL("Surname")}
                value={obj.surname}
                setObj={setObjField}
                _vresults={
                  vresults && vresults.surname ? vresults.surname : false
                }
              />
            </Box>

            <Box mb={4}>
              <Typography variant="h6">{LL("Email")}</Typography>
              <MyTextField
                fullWidth
                id="email"
                name="email"
                className="form-input"
                variant="standard"
                _label={LL("Email")}
                value={obj.email}
                setObj={setObjField}
                _vresults={vresults && vresults.email ? vresults.email : false}
              />
            </Box>
            <Box mb={1}>
              <Typography variant="h6">{LL("Password")}</Typography>
              <MyTextField
                fullWidth
                type="password"
                id="password"
                name="password"
                className="form-input"
                variant="standard"
                _label={LL("Password")}
                value={obj.password}
                setObj={setObjField}
                _vresults={
                  vresults && vresults.password ? vresults.password : false
                }
              />
            </Box>
            <Box mb={3}>
              <PasswordStrengthChecker password={obj.password} />
            </Box>
            <Box mb={3}>
              <Typography variant="h6">{LL("Confirm Password")}</Typography>
              <MyTextField
                fullWidth
                type="password"
                id="confirmPassword"
                name="confirmPassword"
                className="form-input"
                variant="standard"
                _label={LL("Confirm Password")}
                value={confirmPassword}
                setObj={(field: any, value: any) => setConfirmPassword(value)}
                _vresults={undefined}
              />
            </Box>

            <Box mb={4}>
              <Typography variant="h6">{LL("birthday")}</Typography>
              <MyDatePicker
                tabIndex={3}
                field="birthday"
                _defaultValue={obj.birthday}
                className="form-picker"
                LL={LL}
                setObjField={setObjField}
              />
            </Box>
            <Box>
              <Typography variant="h6">{LL("phone number")}</Typography>
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <CountryCodeSelect
                    value={obj.countrycode}
                    field="countrycode"
                    size="small"
                    variant="standard"
                    color="primary"
                    className="form-select"
                    setObj={setObjField}
                    obj={obj}
                  />
                </Grid>
                <Grid item xs={8}>
                  <MyTextField
                    tabIndex={1}
                    fullWidth
                    id="phonenumber"
                    name="phonenumber"
                    className="form-input"
                    variant="standard"
                    _label={LL("phonenumber")}
                    value={obj.phonenumber}
                    setObj={setObjField}
                    _vresults={undefined}
                  />
                </Grid>
              </Grid>
            </Box>
            <Box sx={{ mt: 3 }}>
              <Typography variant="h6">{LL("Type phone")}</Typography>
              <PhoneTypeSelect
                tabIndex={3}
                id="idtypephone"
                name="idtypephone"
                value={obj.idtypephone}
                field="idtypephone"
                size="small"
                variant="standard"
                color="primary"
                className="form-select"
                setObj={setObjField}
                obj={obj}
              />
            </Box>
            <Box sx={{ mt: 3 }}>
              <Typography variant="h6">{LL("language")}</Typography>
              <LanguageSelect
                tabIndex={4}
                id="idlanguage"
                name="idlanguage"
                value={obj.idlanguage}
                field="idlanguage"
                size="small"
                variant="standard"
                color="primary"
                className="form-select"
                setObj={setObjField}
                obj={obj}
              />
            </Box>
            <Box mt={2} className="checkTerm">
              <MyCheckboxField
                id="notificationtype1"
                name="notificationtype1"
                _label={LL("notificationtype1")}
                setObj={setCheckNotification}
                checked={obj.notificationtype1}
                color="primary"
                size="small"
                _vresults={undefined}
              />
            </Box>
            <Box mt={2} className="checkTerm">
              <MyCheckboxField
                id="term"
                name="term"
                _label={LL("term")}
                setObj={setObjTerm}
                checked={term}
                color="primary"
                size="small"
                _vresults={undefined}
              />
            </Box>
            <Box mt={4}>
              <Button
                disabled={isFormDisabled}
                color="primary"
                fullWidth
                variant="contained"
                className="btn"
                type="submit"
              >
                {LL("Bt SignUp")}
              </Button>
            </Box>
          </form>
          <Typography className="text-strike" my={4}>
            {LL("I have already an account")}
          </Typography>
          <AuthMethodsBlock isLoginPage={false} />
        </Box>
      </Container>
    </Container>
  );
};

export { SignUp };
