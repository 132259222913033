import React, { useState, useEffect } from "react";
import MyTextField from "components/form/mytextfield";
import SocialDto from "dto/user/social.dto";
import { useResource } from "hook/useResource";
import { useAuth } from "hook/useAuth";
import { Box, IconButton, Button } from "@mui/material";
import { SocialTypeSelect } from "components/elements/Select/SocialTypeSelect";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import { MyButton } from "components/general/MyButton";
import { logger } from "hoc/logger";
import { GalleryDto } from "dto/gallery/gallery.dto";
import { CommonTools } from "tools/commontools";

type MainObjectImageProps = {
  gallery: Array<GalleryDto> | undefined;
  w?: number;
  h?: number;
};

const MainObjectImage: React.FC<MainObjectImageProps> = ({
  gallery,
  w,
  h,
  ...props
}) => {
  w = w ?? 1200;
  h = h ?? 200;
  const [mainObj, setMainObj] = useState<GalleryDto>();

  const [imgUrl, setImgUrl] = useState<string>("");
  const [fullUrl, setFullUrl] = useState<string>("");

  const [_w, setW] = useState<number>(w);
  const [_h, setH] = useState<number>(h);

  const processMainObj = () => {
    if (!gallery || !Array.isArray(gallery) || !gallery?.length) {
      setMainObj(undefined);
      return;
    }

    let mainObj: GalleryDto | undefined = undefined;

    for (const _obj of gallery) {
      if (_obj.isdefault) {
        mainObj = _obj;
        break;
      }
    }

    if (mainObj === undefined) {
      mainObj = gallery[0];
    }

    setMainObj(mainObj);

    CommonTools.setFileUrlsGallery(mainObj, setImgUrl, setFullUrl, _w, _h);
  };

  useEffect(() => {
    processMainObj();
  }, [gallery]);

  const processData = () => {
    if (mainObj == undefined || !mainObj) return <></>;
    if (imgUrl == undefined || !imgUrl) return <></>;

    return <img src={imgUrl} width={_w} height={_h} alt={""} />;
  };

  return <>{processData()}</>;
};

export { MainObjectImage };
