import React, { useEffect, useState } from "react";
import PageComponentProps from "interfaces/pagecomponentprops.interface";
import { useResource } from "hook/useResource";
import { logger } from "hoc/logger";
import { ComponentCommonTools } from 'tools/componentcommontools'
import { CommonTools } from "tools/commontools";
import { CourseDto } from "dto/course/course.dto";
import { VideoDto } from "dto/video/video.dto";
type CourseVideoProps = {
    obj: CourseDto;
    width: number;
    height: number;
    controls: boolean;
}


const CourseVideo: React.FC<CourseVideoProps> = ({
    obj,
    width,
    height,
    controls,
    ...props
}) => {

    const [video, setVideo] = useState<VideoDto | undefined>()

    const prepareVideo = () => {
        if (!obj) return;

        if (!obj.hasOwnProperty('video')) return;
        if (!obj.video) return;
        if (obj.video.length === 0) return;
        const videoObj = CommonTools.getMediaDefault(obj.video);
        if (!videoObj) return;
        setVideo(videoObj);
    }

    useEffect(() => {
        prepareVideo();
    }, [obj])

    const processVideo = () => {
        if (!obj) return <></>;
        if (!obj.hasOwnProperty('video')) return <></>;
        if (!obj.video) return <></>;
        if (obj.video.length === 0) return <></>;
        if (!video) return <></>;

        if (!width) width = 640;
        if (!height) height = 360;
        if (!controls) controls = true;

        return (
            <>
                {ComponentCommonTools.getFileBlockVideo(video, width, height, controls)}
            </>
        );
    }

    return (<>{processVideo()}</>);
};

export { CourseVideo };
