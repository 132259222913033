import { CircularLoading } from "components/general/CircularLoading";
import RequestFilterDTO from "dto/app/requestfilter.dto";
import RequestListDTO from "dto/app/requestlist.dto";
import ResultListDTO from "dto/app/resultlist.dto";
import { AnswerDto } from "dto/course/answer.dto";
import { QuestionDto } from "dto/course/question.dto";
import { useResource } from "hook/useResource";
import React, { useState, useEffect } from "react";
import AnswerService from "services/course/answer.service";
import { Box, Typography, FormControl, RadioGroup, FormControlLabel, Radio } from "@mui/material";
import { ComponentCommonTools } from "tools/componentcommontools";
type AnswerSingleQuestionOptionProps = {
    obj: QuestionDto;
    objects: Array<AnswerDto>;
    answerSelected: Array<string>;
    setAnswerSelected: any;
}

const answerService = new AnswerService();
const AnswerSingleQuestionOption: React.FC<AnswerSingleQuestionOptionProps> = ({
    obj,
    objects,
    answerSelected,
    setAnswerSelected,
    ...props }) => {

    const [value, setValue] = useState('');

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue((event.target as HTMLInputElement).value);
        setAnswerSelected([(event.target as HTMLInputElement).value]);
    };

    const processAnswerItem = (obj: AnswerDto, index: number) => {
        if (!obj) return <></>;
        if (!obj.id) return <></>;
        if (!obj.name) return <></>;

        return (
            <FormControlLabel
                value={obj.id}
                control={<Radio />}
                label={obj.name}
                className="answerElement"
            />
        )
    }

    const processAnswerList = () => {
        if (!objects.length) return <></>;
        return (
            <FormControl>
                <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    name="radio-buttons-group"
                    value={value}
                    onChange={handleChange}
                    row
                    
                >
                    {objects.map((obj, index) => {
                        return processAnswerItem(obj, index)
                    })}
                </RadioGroup>
            </FormControl>
        )
    }

    return processAnswerList();
};

export { AnswerSingleQuestionOption };
