import Idto from 'interfaces/idto.interface';

export class TestResultAnswerDto implements Idto {
    
    id?: string;
    idanswer?: string;
    idtestresult?: string;
    idtestresultquestion?: string;
    score?: number;
    iscorrect?: boolean;

    answer?: any;
    testresult?: any;
    testresultquestion?: any;
}


export class PostTestResultAnswerDto implements Idto {
    
    idanswer?: string;
    idtestresult?: string;
    idtestresultquestion?: string;
    score?: number;
    iscorrect?: boolean;
}