import RequestListDTO from "dto/app/requestlist.dto";
import RequestSortCriteriaDTO from "dto/app/requestsortcriteria.dto";
import ResultObjectDTO from "dto/app/resultobject.dto";
import ResultListDTO from "dto/app/resultlist.dto";
import { LabelDto } from "dto/label/label.dto";
import { LabelValuePopulateDto } from "dto/label/labelvaluepopulate.dto";
import { PostLabelDto } from "dto/label/postlabel.dto";
import { logger } from "hoc/logger";
import IService from "interfaces/iservice.interface";
import LabelRepository from "repositories/label/label.repository";
import GeneralService from "services/general.service";

export default class LabelService extends GeneralService implements IService {
  labelRepository: LabelRepository = new LabelRepository();

  constructor() {
    super();
    this.handleGetList = this.handleGetList.bind(this);
    this.handleGet = this.handleGet.bind(this);
  }

  handleUpdate(result?: any, cbparameters?: any, data?: any): any {
    let rez = new ResultObjectDTO();
    rez = result;

    if (result.err) return;

    if (cbparameters && cbparameters._cb) {
      cbparameters._cb(rez, cbparameters, data);
    }
  }

  fromLabelPopulateToPostLabel(obj: LabelDto): PostLabelDto {
    const object = new PostLabelDto();
    object.identifier = obj.identifier;
    object.idlanguage = obj.idlanguage;
    object.status = obj.status;
    object.type = obj.type;
    object.value = obj.value;
    // object.idlabelvalue = obj.id;
    object.idlabel = obj.id;
    return object;
  }
  parseForUpdate(obj: any): PostLabelDto {
    const object = new PostLabelDto();
    object.identifier = obj.identifier;
    object.idlanguage = obj.idlanguage;
    object.status = obj.status;
    object.type = obj.type;
    object.value = obj.value;
    object.idlabel = obj.id;

    return object;
  }

  async update(
    id: string,
    cb?: any,
    cbparameters?: any,
    data?: PostLabelDto
  ): Promise<any> {
    // const updateData: PostLanguageDto = {
    //   name: data ? data.name : '',
    //   cod2: data ? data.cod2 : '',
    //   cod3: data ? data.cod3 : '',
    //   status: data ? data.status : Status.INACTIVE,
    //   ordercriteria: data ? data.ordercriteria : 0,
    //   hmtlcode: data ? data.hmtlcode : ''
    // }


    cbparameters = cbparameters ? cbparameters : {};
    cbparameters._cb = cb;
    this.labelRepository.update(id, this.handleUpdate, this.parseForUpdate(data), cbparameters);
  }
  handleDelete(result?: any, cbparameters?: any): any {
    let rez = new ResultObjectDTO();
    rez = result;
    if (result.err) return;

    if (cbparameters && cbparameters._cb) {
      cbparameters._cb(rez, cbparameters);
    }
  }

  async delete(id: string, cb?: any, cbparameters?: any): Promise<any> {
    cbparameters = cbparameters ? cbparameters : {};
    cbparameters._cb = cb;

    this.labelRepository.delete(id, this.handleDelete, cbparameters);
  }

  handleGetServerLabelsHash(result?: any, cbparameters?: any, data?: any): any {
    let rez = new ResultObjectDTO();
    rez = result;
    if (result.err) return;

    if (cbparameters && cbparameters._cb) {
      cbparameters._cb(rez, cbparameters, data);
    }
  }

  async getServerLabelsHash(cb?: any, cbparameters?: any): Promise<any> {
    cbparameters = cbparameters ? cbparameters : {};
    cbparameters._cb = cb;
    this.labelRepository.getServerLabelsHash(
      this.handleGetServerLabelsHash,
      cbparameters
    );
  }

  handleGet(result?: any, cbparameters?: any, data?: any): any {
    let rez = new ResultObjectDTO();

    rez = result;
    if (result.err) return;
    this.populateObject(rez.obj, result.obj, [
      { key: "type", type: "typeLabel" },
      { key: "status", type: "status" },
    ]);
    if (cbparameters && cbparameters._cb) {
      cbparameters._cb(rez, cbparameters, data);
    }

    return rez;
  }

  async get(id: string, cb?: any, cbparameters?: any,idLanguage?:string): Promise<any> {
    cbparameters = cbparameters ? cbparameters : {};
    cbparameters._cb = cb;
    const t = await this.labelRepository.get(id, this.handleGet, cbparameters, idLanguage);
        if (cb == undefined)
        {
            return this.handleGet(t);
        }
  }

  async getByLanguage(
    id: string,
    idLanguage: string,
    cb?: any,
    cbparameters?: any
  ): Promise<any> {
    cbparameters = cbparameters ? cbparameters : {};
    cbparameters._cb = cb;
    this.labelRepository.getByLanguage(
      id,
      idLanguage,
      this.handleGet,
      cbparameters
    );
  }

  async getPopulate(id: string, cb?: any, cbparameters?: any): Promise<any> {
    cbparameters = cbparameters ? cbparameters : {};
    cbparameters._cb = cb;
    this.labelRepository.getPopulate(id, this.handleGet, cbparameters);
  }

  handleGetList(result?: any, cbparameters?: any, data?: any): any {
    let rez = new ResultListDTO();

    rez = result;
    if (result.err) return;

    this.parseResultPopulate(result, rez, [
      { key: "type", type: "typeLabel" },
      { key: "status", type: "status" },
    ]);

    if (cbparameters && cbparameters._cb) {
      cbparameters._cb(rez, cbparameters, data);
    }

        return rez;
  }

  async getList(
    cb?: any,
    cbparameters?: any,
    data?: RequestListDTO
  ): Promise<any> {
    cbparameters = cbparameters ? cbparameters : {};
    cbparameters._cb = cb;
    const t = await this.labelRepository.getList(this.handleGetList, cbparameters, data);
        if (cb == undefined)
        {
            return this.handleGetList(t);
        }
  }

  handleAdd(result?: any, cbparameters?: any, data?: any): any {
    let rez = new ResultObjectDTO();
    rez = result;
    if (result.err) return;

    if (cbparameters && cbparameters._cb) {
      cbparameters._cb(rez, cbparameters, data);
    }
  }

  async add(cb?: any, cbparameters?: any, data?: PostLabelDto): Promise<any> {
    cbparameters = cbparameters ? cbparameters : {};
    cbparameters._cb = cb;
    this.labelRepository.add(this.handleGetList, cbparameters, data);
  }

  getDefaultRequestList(obj: RequestListDTO): RequestListDTO {
    if (
      !obj.sortcriteria ||
      !Array.isArray(obj.sortcriteria) ||
      !obj.sortcriteria.length
    ) {
      var sobj = new RequestSortCriteriaDTO();
      sobj.asc = true;
      sobj.field = "value";

      obj.sortcriteria = [];
      obj.sortcriteria?.push(sobj);
    }

    return obj;
  }
}
