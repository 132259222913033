import React, { useState, useEffect, Dispatch, SetStateAction } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import CircularProgress from "@mui/material/CircularProgress";
import { logger } from "hoc/logger";
import { useResource } from "hook/useResource";
import RequestFilterDTO from "dto/app/requestfilter.dto";

import TestService from "services/course/test.service";
import { Status } from "tools/status";
import ResultObjectDTO from "dto/app/resultobject.dto";
import { Skeleton } from "@mui/material";
const service = new TestService();

interface TestByTeacherAutocompleteProps {
    [x: string]: any;
    valueFilter: string
    setValueFilter: any
    idTeacher: string;
    _defaultValue: any;

}

const TestByTeacherAutocomplete: React.FC<TestByTeacherAutocompleteProps> = ({
    valueFilter,
    setValueFilter,
    _defaultValue,
    idTeacher,
    ...props
}) => {
    const { LL } = useResource();

    const idField = 'idtest';
    const objField = 'test';
    const searchValueField = 'search';
    const labelShowField = 'name';
    const labelText = LL('Autocomplete_Test');

    const [loadOnEmpty] = useState<any>(false);
    const [objects, setObjects] = useState<any>();
    const [value, setValue] = useState<any>(null);
    const [search, setSearch] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(false);
    const [isLoaded, setIsLoaded] = useState<boolean>(false);

    

    const [open, setOpen] = useState<boolean>(false);
    const [options, setOptions] = useState<any>([{}]);

    const [label] = useState<string>(labelText);


    const onPage = 20;

    const [reqList] = useState<any>(service.getDefaultRequestListSelect(onPage));
    

    const processDefaultValue = () => {
        if (!_defaultValue) return;
        
        service.get(_defaultValue, loadDefaultObject, {})
    }
    const loadDefaultObject = (result: ResultObjectDTO) => {
        if (!result) {

            setValue(null);
        }
        if (!result.err) {

            setValue(result.obj)

        }
              
    }

    useEffect(() => {
        processDefaultValue();
    }, [_defaultValue])


    const loadData = () => {
         if ((search === undefined || search === undefined)) {
            setOptions([]);
            setLoading(false);
            return;
        }
        reqList.filters = [];

        const tmp: RequestFilterDTO = new RequestFilterDTO()
        tmp.field = searchValueField;
        tmp.values = [];
        tmp.values.push(search);

        const tmp2: RequestFilterDTO = new RequestFilterDTO()
        tmp2.field = "idteacher";
        tmp2.values = [idTeacher];

        reqList.filters.push(tmp, tmp2);


        getList();
    };

    const getList = () => {
        if (!reqList) return;
        setLoading(true);

        service.getList(loadObjects, {}, reqList);
    };
    const loadObjects = (data: any) => {
        if (!data) setOptions([]);

        setOptions(data.objects);
        setLoading(false);
    };

    useEffect(() => {
        loadData();
    }, [search]);

    useEffect(() => {
        if (objects !== null && objects !== undefined) {
            setValue(objects);
        } else {
            setValue(null);
        }
    }, [objects]);

    useEffect(() => {
        if (isLoaded) return;
        setIsLoaded(true);
    }, [options]);


    useEffect(() => {
        if (!isLoaded) return;
        if (valueFilter === undefined || valueFilter === null) return;
        if (!setValueFilter) return;
        
        if (!value) {
            setValueFilter('');
            return;
        }
        if (!objects) return;
        setValueFilter(objects.id);
    }, [value]);

    const handleChange = (value: any) => {
        setObjects(value);
    };

    useEffect(() => {
        if (!open) {
            setSearch("");
        }
    }, [open]);


    return (

        <Autocomplete
            id="asynchronous-demo"
            fullWidth

            size='small'
            className="form-autocomplete"
            open={open}
            value={value}
            onOpen={() => {
                setOpen(true);
            }}
            onClose={() => {
                setOpen(false);
            }}
            onChange={(event, value) => { handleChange(value); }}
            isOptionEqualToValue={(option: any, value: any) => option.id === value.id}
            getOptionLabel={(option: any) => option[labelShowField] ? option[labelShowField] : ""}
            options={options}
            loading={loading}

            renderInput={(params) => (
                <TextField

                    {...params}
                    label={label}
                    onChange={(event) => { setSearch(event.target.value); }}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <>
                                {loading ? (
                                    <CircularProgress color="inherit" size={20} />
                                ) : null}
                                {params.InputProps.endAdornment}
                            </>
                        ),
                    }}
                />
            )}
        />
    )
};

export { TestByTeacherAutocomplete };
