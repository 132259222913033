import React, { useState, useEffect } from "react";
import { useResource } from "hook/useResource";
import { QuestionDto } from "dto/course/question.dto";
import RequestFilterDTO from "dto/app/requestfilter.dto";
import RequestListDTO from "dto/app/requestlist.dto";
import { Status } from "tools/status";
import QuestionService from "services/course/question.service";
import ResultListDTO from "dto/app/resultlist.dto";
import {
    Paper,
    Box,
    IconButton,
    Skeleton,
    Typography,
    Stack,
    Accordion,
    AccordionDetails,
    AccordionSummary
} from "@mui/material";

import RequestSortCriteriaDTO from "dto/app/requestsortcriteria.dto";
import { MySortSelect } from "components/elements/Select/MySortSelect";
import { MyButton } from "components/general/MyButton";
import { LoadMoreButton } from "components/elements/MyButton/LoadMoreButton";
import AccessTimeIcon from '@mui/icons-material/AccessTime';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ComponentCommonTools } from "tools/componentcommontools";

import { logger } from "hoc/logger";
import EditIcon from '@mui/icons-material/Edit';

import { useMessage } from "hook/useMessage";
import { CommonTools } from "tools/commontools";
import { AnswerList } from "./AnswerList";
import { EditQuestionDialog } from "./EditQuestionDialog";
import { AttachmentDialog } from "components/attachment/AttachmentDialog";
import { GalleryDialog } from "components/gallery/GalleryDialog";
import { VideoDialog } from "components/video/VideoDialog";
import VideocamIcon from '@mui/icons-material/Videocam';
import CollectionsIcon from '@mui/icons-material/Collections';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import DeleteIcon from '@mui/icons-material/Delete';
type QuestionListItemProps = {
    obj: QuestionDto
    isLoading: boolean
    setObjectWasChanged: any;
}

const questionService = new QuestionService();

const QuestionListItem: React.FC<QuestionListItemProps> = ({

    obj,
    isLoading,
    setObjectWasChanged,
    ...props }) => {

    const { LL, CC } = useResource();
    const { registerDialog } = useMessage();



    const [expend, setExpend] = useState(false);
    const handleExpend = (event: React.SyntheticEvent, expanded: boolean) => {
        setExpend(expanded);

    }
    const [openEditDialog, setOpenEditDialog] = useState(false);

    const handleOpenEditDialog = () => {
        setOpenEditDialog(true);
    }


    const [openDialogAttachment, setOpenDialogAttachment] = useState(false);
    const handleOpenDialogAttachment = () => {
        setOpenDialogAttachment(true);
    };

    const [openDialogGallery, setOpenDialogGallery] = useState(false);
    const handleOpenDialogGallery = () => {
        setOpenDialogGallery(true);
    };

    const [openDialogVideo, setOpenDialogVideo] = useState(false);
    const handleOpenDialogVideo = () => {
        setOpenDialogVideo(true);
    };

    const handleActionDelete = () => {
        registerDialog(LL('Confirm_delete_question'),
            LL('Are_you_sure_you_want_to_delete_this_question'),
            { cb: handleDelete }, null);
    }

    const handleDelete = () => {
        if (!obj) return;
        if (!obj.id) return;
        questionService.delete(obj.id, CommonTools.handleSuccessAddAndEditStayOnPage, { setObjectWasChanged });
    }

    const processItem = () => {
        if (!obj) return <></>;
        if (!obj.id) return <></>;

        return (
            <>
                <EditQuestionDialog
                    open={openEditDialog}
                    setOpen={setOpenEditDialog}
                    id={obj.id}
                    setObjectWasChanged={setObjectWasChanged}
                />
                <AttachmentDialog
                    open={openDialogAttachment}
                    setOpen={setOpenDialogAttachment}
                    idparent={obj.id}
                    parent="question"
                />
                <GalleryDialog
                    open={openDialogGallery}
                    setOpen={setOpenDialogGallery}
                    idparent={obj.id}
                    parent="question"
                />
                <VideoDialog
                    open={openDialogVideo}
                    setOpen={setOpenDialogVideo}
                    idparent={obj.id}
                    parent="question"
                />
                <Accordion


                    sx={{ my: 2 }}
                    expanded={expend}
                    className="accordionItem"
                    onChange={handleExpend}>

                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        sx={{ flexDirection: 'row-reverse', p: 1 }}

                    >
                        <Stack direction='row' justifyContent='space-between' sx={{ width: '100%' }}>
                            <Typography className="name" ml={1}>
                                {ComponentCommonTools.processObjectField(obj, 'name')}
                            </Typography>
                            <Typography ml={1}>
                                {ComponentCommonTools.processObjectField(obj, 'type_name')}
                            </Typography>
                            <Typography ml={1}>
                                {ComponentCommonTools.processObjectField(obj, 'totalscore')}
                            </Typography>
                            <Box onClick={(e) => { e.stopPropagation() }}>
                                <IconButton
                                    onClick={handleOpenEditDialog}
                                >
                                    <EditIcon />
                                </IconButton>
                                <IconButton
                                    onClick={handleOpenDialogAttachment}
                                >
                                    <AttachFileIcon />
                                </IconButton>
                                <IconButton
                                    onClick={handleOpenDialogGallery}
                                >
                                    <CollectionsIcon />
                                </IconButton>
                                <IconButton
                                    onClick={handleOpenDialogVideo}
                                >
                                    <VideocamIcon />
                                </IconButton>
                                <IconButton
                                    onClick={handleActionDelete}
                                >
                                    <DeleteIcon />
                                </IconButton>
                            </Box>
                        </Stack>
                    </AccordionSummary>
                    {processDetails(obj)}
                </Accordion>
            </>
        );
    };

    const processDetails = (obj: QuestionDto) => {
        if (!expend) return <></>;
        if (!obj) return <></>;
        if (!obj.id) return <></>;

        return (
            <AccordionDetails >
                <AnswerList
                    idQuestion={obj.id}
                    extend={expend}
                />
            </AccordionDetails>
        )
    }





    return isLoading ? <Skeleton /> : (
        <>
            {processItem()}

        </>
    );
};

export { QuestionListItem };
