import { Alert, Box, Link, Skeleton, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";

import { useResource } from "hook/useResource";
import { useAuth } from "hook/useAuth";
import { PostTeacherFAQDto } from "dto/teacher/teacherfaq.dto";
import { MyButton } from "components/general/MyButton";
import MyTextField from "components/form/mytextfield";
import TeacherFAQService from "services/teacher/teacherfaq.service";
import { useMessage } from "hook/useMessage";
import { CommonTools } from "tools/commontools";
import { MessageTypes } from "tools/messagetypes";
import { logger } from "hoc/logger";

type AddTeacherFAQFormProps = {
  idCourse: string;
  idTeacher: string;
};

const teacherfaqService = new TeacherFAQService();

const AddTeacherFAQForm: React.FC<AddTeacherFAQFormProps> = ({
  idCourse,
  idTeacher,
  ...props
}) => {
  const { LL, CC } = useResource();
  const { user } = useAuth();
  const [obj, setObj] = useState<PostTeacherFAQDto>(new PostTeacherFAQDto());
  const [isFormDisabled, setIsFormDisabled] = useState(false);
  const { RegisterMessage } = useMessage();

  const setObjField = (field: any, value: any) => {
    let t = JSON.parse(JSON.stringify(obj));
    t[field] = value;
    setObj(t);
  };

  const checkIsFormDisabled = () => {
    let rez = false;
    if (!obj) rez = true;
    if (!obj.message) rez = true;
    setIsFormDisabled(rez);
  };
  useEffect(() => {
    checkIsFormDisabled();
  }, [obj.message]);

  const [loading, setLoading] = useState(false);
  const handleSubmit = (event: any) => {
    event.preventDefault();
    if (isFormDisabled) return false;
    if (!user) return false;
    if (!idCourse) return false;
    if (!idTeacher) return false;
    obj.iduser = user.id;
    obj.idcourse = idCourse;
    obj.idteacher = idTeacher;
    setLoading(true);

    teacherfaqService.add(handleResult, {}, obj);
  };

  const handleResult = (result: any) => {
    if (!result) return;
    if (!result.err) {
      const objMessage = CommonTools.createMessage(
        LL("Your_question_was_placed"),
        MessageTypes.MESSAGE_SUCCESS,
        " "
      );

      RegisterMessage(objMessage);
      setObj(new PostTeacherFAQDto());
      setLoading(false);
    }
  };

  const processForm = () => {
    if (!user) return <></>;
    if (!idCourse) return <></>;
    if (!idTeacher) return <></>;
    if (loading) return <Skeleton variant="rectangular" height={150} />;

    return (
      <form>
        <MyTextField
          multiline
          minRows={4}
          fullWidth
          id="message"
          name="message"
          variant="standard"
          color="primary"
          className="form-input"
          _label={LL("Place_you_question ...")}
          size="small"
          value={obj.message}
          setObj={setObjField}
          _vresults={undefined}
        />
        <Stack direction="row" spacing={2} mt={2} alignItems="center" justifyContent="space-between">
          <Alert severity="info">
            <Stack direction="row" spacing={1}>
              <Typography>{LL("Nu uita sa faci cunostinta cu")}</Typography>
              <Link
                href={CC("term_page", "/termeni")}
                target="_blank"
                rel="noopener"
              >
                <Typography>{LL("Regulile de comunicare")}</Typography>
              </Link>
              <Typography>{LL("pe LiveClass")}</Typography>
            </Stack>
          </Alert>
          <MyButton
            cb={handleSubmit}
            tabIndex={4}
            disabled={isFormDisabled}
            color="primary"
            className="btn"
            variant="contained"
            size="small"
            type="submit"
            sx={{ mt: 4 }}
            children={LL("Bt_Send_Question")}
          />
        </Stack>
      </form>
    );
  };
  return user ? (
    <Box>
      <Typography variant="h5" mb={3} mt={4}>
        {LL("Ask_Teacher_a_question")}
      </Typography>
      {processForm()}
    </Box>
  ) : (
    <></>
  );
};

export { AddTeacherFAQForm };
