import React, { useEffect, useState } from "react";
import PageComponentProps from "interfaces/pagecomponentprops.interface";
import { useResource } from "hook/useResource";
import { logger } from "hoc/logger";

import { MyNavBt } from "components/general/MyNavBt";
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import { ComponentCommonTools } from 'tools/componentcommontools'
import Grid from '@mui/material/Grid';
import TeacherService from "services/teacher/teacher.service";
import { TeacherDto } from "dto/teacher/teacher.dto";
import { useAuth } from "hook/useAuth";
import MyCKEditor from 'components/elements/ckeditor/MyCKEditor';
import { StudyTypeSelect } from 'components/elements/Select/StudyTypeSelect';
import { MyButton } from 'components/general/MyButton';
import ValidateTools from 'tools/validate.tools';
import MyTextField from 'components/form/mytextfield';
import { CommonTools } from "tools/commontools";
import { CircularLoading } from 'components/general/CircularLoading';
import { SpecialtyTypeSelect } from 'components/elements/Select/SpecialtyTypeSelect';
import { TargetGroupTypeSelect } from 'components/elements/Select/TargetGroupTypeSelect';
import { InternetConnectionTypeSelect } from 'components/elements/Select/InternetConnectionTypeSelect';
import { HardwareTypeSelect } from 'components/elements/Select/HardwareTypeSelect';
import { LessonTypeSelect } from 'components/elements/Select/LessonTypeSelect';
import { OccupationTypeSelect } from 'components/elements/Select/OccupationTypeSelect';
import { ProfessionalismLevelTypeSelect } from 'components/elements/Select/ProfessionalismLevelTypeSelect';
import { ExperienceTypeSelect } from 'components/elements/Select/ExperienceTypeSelect';
import { DisciplineTypeSelect } from 'components/elements/Select/DisciplineTypeSelect';
import MyCheckboxField from "components/form/mycheckboxfield";

const teacherService = new TeacherService();

const UserEditTeacher: React.FC<PageComponentProps> = ({
    currentRoute,
    ...props
}) => {
    const { LL } = useResource();
    const { user } = useAuth();

    const [loading, setLoading] = useState<boolean>(true);
    const [obj, setObj] = useState<TeacherDto | undefined>();
    const _id = user !== undefined ? user.id : '';
    const [id, setId] = useState<string>(_id);
    const [isFormDisabled, setIsFormDisabled] = useState(false);


    const [validators, setValidators] = useState<any>();
    const [vresults, setVResults] = useState<any>(false)

    const validateField = (field: any, value: any, v?: any) => {

        v = (v) ? v : JSON.parse(JSON.stringify(vresults));
        if (v === false) v = {};
        v[field] = ValidateTools.validate(field, value, validators, vresults);

        setVResults(v);
        setIsFormDisabled(ValidateTools.vForm(v, field));
        return v;
    };

    const loadObject = (obj: any) => {
        if (!obj) return;
        if (!obj.err) {
            const object = obj.obj ? obj.obj : [];
            setObj(object);
        }
    };

    const getObject = () => {
        setLoading(true)
        if (id !== undefined && id !== '') teacherService.getByUserId(id, loadObject, {});
    };

    const checkIsLoading = () => {
        let load: boolean = false;
        if (obj === undefined) load = true;
        setLoading(load);
    };

    useEffect(() => {
        getObject();
    }, [currentRoute]);

    useEffect(() => {
        validateAll();
        checkIsLoading();
    }, [obj]);

    useEffect(() => {

        if (user !== undefined) {
            setId(user.id)
        }
    }, [user])

    const validateAll = () => {
        if (!obj) return;
        // if (!validators) return;
        // if (vresults) return;

        obj.bio = obj.bio !== undefined ? obj.bio : '';
        obj.graduatedinstitution = obj.graduatedinstitution !== undefined ? obj.graduatedinstitution : '';
        obj.timetoteachperweek = obj.timetoteachperweek !== undefined ? obj.timetoteachperweek : '';
        obj.whyliveclass = obj.whyliveclass !== undefined ? obj.whyliveclass : '';
        obj.hasaudience = obj.hasaudience !== undefined ? obj.hasaudience : false;

        obj.status = obj.status !== undefined ? obj.status : 0;
        obj.idtypestudies = obj.idtypestudies !== undefined ? obj.idtypestudies : '';
        obj.idtypespecialty = obj.idtypespecialty !== undefined ? obj.idtypespecialty : '';
        obj.idtypetargetgroup = obj.idtypetargetgroup !== undefined ? obj.idtypetargetgroup : '';
        obj.idtypeinternetconnection = obj.idtypeinternetconnection !== undefined ? obj.idtypeinternetconnection : '';
        obj.idtypelesson = obj.idtypelesson !== undefined ? obj.idtypelesson : '';
        obj.idtypediscipline = obj.idtypediscipline !== undefined ? obj.idtypediscipline : '';
        obj.idtypeoccupation = obj.idtypeoccupation !== undefined ? obj.idtypeoccupation : '';
        obj.idtypeprofessionalismlevel = obj.idtypeprofessionalismlevel !== undefined ? obj.idtypeprofessionalismlevel : '';
        obj.idtypeexperience = obj.idtypeexperience !== undefined ? obj.idtypeexperience : '';

        setObj(obj);
    };


    const setObjField = (field: any, value: any) => {
        let t = JSON.parse(JSON.stringify(obj));
        t[field] = value;
        setObj(t);

        // validateField(field, value);
    };
    const setCheckFixed = (value: any) => {
        let t = JSON.parse(JSON.stringify(obj));

        t['hasaudience'] = value;
        setObj(t);

    };
    const handleSubmit = (event: any) => {
        event.preventDefault();
        if (isFormDisabled) return false;
        if (id !== undefined && obj) {
            obj.iduser = id;
            setLoading(true);
            teacherService.add(CommonTools.handleSuccessAddAndEditStayOnPage, { setLoading }, obj);
        }
    };

    return user !== undefined && !loading && obj ? (
        <>
            <Box p={3}>
                <form onSubmit={handleSubmit} >
                    <Grid container spacing={3}>
                        <Grid item lg={6} xs={12}>
                        <MyTextField
                        tabIndex={0}
                        fullWidth
                        id="timetoteachperweek"
                        name="timetoteachperweek"
                        variant="standard"
                        color="primary"
                        className="form-input"
                        _label={LL("timetoteachperweek")}
                        size="small"
                        value={obj.timetoteachperweek}
                        setObj={setObjField}
                        _vresults={
                            vresults && vresults.timetoteachperweek ? vresults.timetoteachperweek : false
                        }
                    />
                        </Grid>
                        <Grid item lg={6} xs={12}>
                        <MyTextField
                            tabIndex={4}
                            fullWidth
                            id="whyliveclass"
                            name="whyliveclass"
                            variant="standard"
                                color="primary"
                                className="form-input"
                            _label={LL("whyliveclass")}
                            size="small"
                            value={obj.whyliveclass}
                            setObj={setObjField}
                            _vresults={
                                vresults && vresults.whyliveclass ? vresults.whyliveclass : false
                            }
                        />
                        </Grid>
                        <Grid item lg={6} xs={12}>
                        <MyTextField
                            tabIndex={5}
                            fullWidth
                            id="graduatedinstitution"
                            name="graduatedinstitution"
                            variant="standard"
                                color="primary"
                                className="form-input"
                            _label={LL("graduatedinstitution")}
                            size="small"
                            value={obj.graduatedinstitution}
                            setObj={setObjField}
                            _vresults={
                                vresults && vresults.graduatedinstitution ? vresults.graduatedinstitution : false
                            }
                        />
                        </Grid>
                        
                        <Grid item lg={12} xs={12}>
                        <MyCheckboxField
                            tabIndex={6}
                            id="hasaudience"
                            name="hasaudience"
                            _label={LL("hasaudience")}
                            setObj={setCheckFixed}
                            checked={obj.hasaudience}
                            color="primary"
                            size="small"
                            _vresults={undefined}
                            />
                        </Grid>
                        <Grid item lg={6} xs={12}>
                        <StudyTypeSelect
                            tabIndex={7}
                            id="idtypestudies"
                            name="idtypestudies"
                            variant="standard"
                            color="primary"
                            className="form-select"
                            value={obj.idtypestudies}
                            field="idtypestudies"
                            size="small"
                            setObj={setObjField}
                            obj={obj}
                            _defaultValue={obj.idtypestudies}
                        />
                        </Grid>
                        <Grid item lg={6} xs={12}>
                        <SpecialtyTypeSelect
                            tabIndex={8}
                            id="idtypespecialty"
                            name="idtypespecialty"
                            variant="standard"
                                color="primary"
                                className="form-select"
                            value={obj.idtypespecialty}
                            field="idtypespecialty"
                            size="small"
                            setObj={setObjField}
                            obj={obj}
                            _defaultValue={obj.idtypespecialty}
                        />
                        </Grid>
                        <Grid item lg={6} xs={12}>
                        <TargetGroupTypeSelect
                            tabIndex={9}
                            id="idtypetargetgroup"
                            name="idtypetargetgroup"
                            variant="standard"
                            color="primary"
                            className="form-select"
                            value={obj.idtypetargetgroup}
                            field="idtypetargetgroup"
                            size="small"
                            setObj={setObjField}
                            obj={obj}
                            _defaultValue={obj.idtypetargetgroup}
                        />
                        </Grid>
                        <Grid item lg={6} xs={12}>
                        <InternetConnectionTypeSelect
                            tabIndex={10}
                            id="idtypeinternetconnection"
                            name="idtypeinternetconnection"
                            variant="standard"
                                color="primary"
                                className="form-select"
                            value={obj.idtypeinternetconnection}
                            field="idtypeinternetconnection"
                            size="small"
                            setObj={setObjField}
                            obj={obj}
                            _defaultValue={obj.idtypeinternetconnection}
                        />
                        </Grid>
                        <Grid item lg={6} xs={12}>
                        <HardwareTypeSelect
                            tabIndex={11}
                            id="idtypehardware"
                            name="idtypehardware"
                            variant="standard"
                            color="primary"
                            className="form-select"
                            value={obj.idtypehardware}
                            field="idtypehardware"
                            size="small"
                            setObj={setObjField}
                            obj={obj}
                            _defaultValue={obj.idtypehardware}
                        />
                        </Grid>
                        <Grid item lg={6} xs={12}>
                        <LessonTypeSelect
                            tabIndex={12}
                            id="idtypelesson"
                            name="idtypelesson"
                            variant="standard"
                            color="primary"
                            className="form-select"
                            value={obj.idtypelesson}
                            field="idtypelesson"
                            size="small"
                            setObj={setObjField}
                            obj={obj}
                            _defaultValue={obj.idtypelesson}
                        />
                        </Grid>
                        <Grid item lg={6} xs={12}>
                        <OccupationTypeSelect
                            tabIndex={13}
                            id="idtypeoccupation"
                            name="idtypeoccupation"
                            variant="standard"
                            color="primary"
                            className="form-select"
                            value={obj.idtypeoccupation}
                            field="idtypeoccupation"
                            size="small"
                            setObj={setObjField}
                            obj={obj}
                            _defaultValue={obj.idtypeoccupation}
                        />
                        </Grid>
                        <Grid item lg={6} xs={12}>
                        <ProfessionalismLevelTypeSelect
                            tabIndex={14}
                            id="idtypeprofessionalismlevel"
                            name="idtypeprofessionalismlevel"
                            variant="standard"
                            color="primary"
                            className="form-select"
                            value={obj.idtypeprofessionalismlevel}
                            field="idtypeprofessionalismlevel"
                            size="small"
                            setObj={setObjField}
                            obj={obj}
                            _defaultValue={obj.idtypeprofessionalismlevel}
                        />
                        </Grid>
                        <Grid item lg={6} xs={12}>
                        <ExperienceTypeSelect
                            tabIndex={15}
                            id="idtypeexperience"
                            name="idtypeexperience"
                            variant="standard"
                            color="primary"
                            className="form-select"
                            value={obj.idtypeexperience}
                            field="idtypeexperience"
                            size="small"
                            setObj={setObjField}
                            obj={obj}
                            _defaultValue={obj.idtypeexperience}
                        />
                        </Grid>
                        <Grid item lg={6} xs={12}>
                        <DisciplineTypeSelect
                            tabIndex={16}
                            id="idtypediscipline"
                            name="idtypediscipline"
                            variant="standard"
                            color="primary"
                            className="form-select"
                            value={obj.idtypediscipline}
                            field="idtypediscipline"
                            size="small"
                            setObj={setObjField}
                            obj={obj}
                            _defaultValue={obj.idtypediscipline}
                        />
                        </Grid>
                        <Grid item lg={12} xs={12}>
                        <MyCKEditor
                            data={obj.bio ? obj.bio : ''}
                            setObj={setObjField}
                            name="bio"
                        />
                        </Grid>
                        <Grid item lg={6} xs={12}>
                        <MyButton
                        tabIndex={4}
                        disabled={isFormDisabled}
                        color="primary"
                        className="btn"
                        variant="contained"
                        size="large"
                        type="submit"
                        sx={{ mt: 4 }}
                        children={LL("Bt_Submit")}
                    />
                        </Grid>

                    </Grid>




                    
                </form>
            </Box>

        </>
    ) : (
        <CircularLoading />
    );
};

export { UserEditTeacher };
