import { Box } from "@mui/material";
import { MyButton } from "components/general/MyButton";
import { CourseDto } from "dto/course/course.dto";
import { CourseEnrollmentDto } from "dto/course/courseenrollment.dto";
import { LessonDto } from "dto/course/lesson.dto";
import { LessonEnrollmentDto } from "dto/course/lessonenrollment.dto";
import { useResource } from "hook/useResource";
import React, { useState } from "react";

type ClassroomZoomBlockProps = {
    currentRoute: any
    idLesson: string;
    lesson: LessonDto;
}

const ClassroomZoomBlock: React.FC<ClassroomZoomBlockProps> = ({
    currentRoute,
    idLesson,
    lesson,
    ...props
}) => {

    const { LL } = useResource();

    const [open, setOpen] = useState(false);

    const processClassroomZoomBlock = () => {
        if (!open) return <></>;
        return (
            <Box height={200}>
                ClassroomZoomBlock
            </Box>
        )
    }

    const handleToggle = () => {
        setOpen(!open);
    }

    return (
        <Box>
            {processClassroomZoomBlock()}
            <MyButton cb={handleToggle}>
                {LL("Zoom")}
            </MyButton>
        </Box>
    );
};

export { ClassroomZoomBlock };
