import React, { useEffect, useState } from "react";
import { useResource } from "hook/useResource";
import { logger } from "hoc/logger";
import TeacherService from "services/teacher/teacher.service";
import MenuLevelProps from "interfaces/menulevelprops.interface";
import { TeacherDto } from "dto/teacher/teacher.dto";
import { RouteTools } from "tools/routetools";
import RequestListDTO from "dto/app/requestlist.dto";
import { Status } from "tools/status";
import RequestFilterDTO from "dto/app/requestfilter.dto";
import ResultListDTO from "dto/app/resultlist.dto";
import { Types } from "tools/types";
import MenuItemProps from "interfaces/menuitemprops.interface";
import { MyNavBt } from "components/general/MyNavBt";

import Box from "@mui/material/Box";

import { ComponentCommonTools } from 'tools/componentcommontools'
import Typography from "@mui/material/Typography";
import Rating from '@mui/material/Rating';
import Avatar from "@mui/material/Avatar";
import ResultObjectDTO from "dto/app/resultobject.dto";
import { Skeleton, Stack } from "@mui/material";
import { HtmlTag } from "components/general/HtmlTag";
import UserSocialBlock from "components/user/UserSocialBlock";
import { MyRatingReadOnly } from "components/elements/MyRating/MyRatingReadOnly";
import { MyAvatar } from "components/elements/MyAvatar/MyAvatar";
import { TeacherTypeIcon } from "./TeacherTypeIcon";
import { CommonTools } from "tools/commontools";

type TeacherInfoBlockProps = {
    id: string;
    showSocials?: boolean;
    setCurrentObject?: (obj: any) => void;
}


const teacherService = new TeacherService();

const TeacherInfoBlock: React.FC<TeacherInfoBlockProps> = ({
    id,
    showSocials = true,
    setCurrentObject,
    ...props
}) => {
    const { LL, CC } = useResource();

    const [obj, setObj] = useState<TeacherDto | undefined>();
    const [loading, setLoading] = useState<boolean>(true);

    const getObject = () => {
        if (!id) return;
        setLoading(true);
        teacherService.get(id, loadObject, {});
    };

    const loadObject = (obj: ResultObjectDTO) => {
        if (!obj) return;
        if (!obj.err) {
            const object = obj.obj ? obj.obj : {};
            setObj(object);
            if (setCurrentObject !== undefined) setCurrentObject(object);
        }
    };

    const checkIsLoading = () => {
        let load: boolean = false;
        if (obj === undefined) load = true;
        setLoading(load);
    };

    useEffect(() => {
        if (!id) return;
        getObject();
    }, [id]);

    useEffect(() => {
        checkIsLoading();
    }, [obj]);

    const processRating = () => {
        if (!obj) return <></>;
        if (!obj.hasOwnProperty('rating') || !obj.hasOwnProperty('numberoftestimonials')) return <MyRatingReadOnly rating={0} count={0} showDetails={false} />;
        if (!obj.rating || !obj.numberoftestimonials) return <MyRatingReadOnly rating={0} count={0} showDetails={false} />;
        return (
            <Stack direction='row' spacing={1}>
                <Typography color='gold'> {CommonTools.roundToTenth(obj.rating)}</Typography>
                <MyRatingReadOnly rating={obj.rating} count={obj.numberoftestimonials} showDetails={false} />
            </Stack>)
    }

    // const processAvatarName = () => {
    //     if (!obj) return CC("DefaultAvatarCredentials", "LC");
    //     if (!obj.hasOwnProperty('name')) return CC("DefaultAvatarCredentials", "LC");
    //     if (!obj.name) return CC("DefaultAvatarCredentials", "LC");
    //     if (!obj.hasOwnProperty('surname')) return obj.name.charAt(0).toUpperCase();
    //     if (!obj.surname) return obj.name.charAt(0).toUpperCase();


    //     return obj.name.charAt(0).toUpperCase() + obj.surname.charAt(0).toUpperCase()
    // }
    const processAvatar = () => {
        if (!obj) return <></>;
        let name = ''
        let surname = ''
        if (obj.hasOwnProperty('name') && obj.name) name = obj.name;
        if (obj.hasOwnProperty('surname') && obj.surname) surname = obj.surname;
        
        return <MyAvatar obj={obj} name={name} surname={surname} className="avatar" sx={{ mr: 1 }} />
    }

    const processTeacherStatus = () => {
        if (!obj) return <></>;
        return <TeacherTypeIcon obj={obj} />
    }
    
    const processFullName = () => {
        if (!obj) return <></>
        if (!obj.hasOwnProperty('name') && !obj.name) return <></>
        if (!obj.hasOwnProperty('surname') && !obj.surname) return <></>
        return (<>
            <Stack direction="row" spacing={1}>
                {processTeacherStatus()}
                <Typography variant="h3">{ComponentCommonTools.processObjectField(obj, 'name')}</Typography>
                <Typography variant="h3">{ComponentCommonTools.processObjectField(obj, 'surname')}</Typography>
            </Stack>
        </>)
    }

    const processOccupationAndDiscipline = () => {
        if (!obj) return <></>
        if (!obj.hasOwnProperty('typeoccupation') && !obj.typeoccupation) {
            return (<Stack direction="row" spacing={1}>
                <Typography>{ComponentCommonTools.processObjectFieldOfObject(obj, 'typediscipline', 'name')}</Typography>
            </Stack>)
        }
        if (!obj.hasOwnProperty('typediscipline') && !obj.typediscipline) {
            return (<Stack direction="row" spacing={1}>
                <Typography>{ComponentCommonTools.processObjectFieldOfObject(obj, 'typeoccupation', 'name')}</Typography>
            </Stack>
            )
        }
        return (<>
            <Stack direction="row" spacing={1}  my={1} divider={<>,</>}>
                <Typography className="discipline">{ComponentCommonTools.processObjectFieldOfObject(obj, 'typeoccupation', 'name')}</Typography>
                <Typography  className="discipline">{ComponentCommonTools.processObjectFieldOfObject(obj, 'typediscipline', 'name')}</Typography>
            </Stack>
        </>)
    }

    const processInfoColumn = () => {
        if (!obj) return <></>
        return (<>
            <Stack direction="column">
                {processFullName()}
                {processOccupationAndDiscipline()}
                {processUserSocials()}
            </Stack>
        </>)
    }

    const processTestimonials = () => {
        if (!obj) return <></>
        if (!obj.hasOwnProperty('numberoftestimonials') && !obj.numberoftestimonials) return <>0</>

        return (<>
            {ComponentCommonTools.processObjectField(obj, 'numberoftestimonials')}
        </>)
    }
    const processNumberOfStudents = () => {
        if (!obj) return <></>
        if (!obj.hasOwnProperty('numberofstudents') && !obj.numberofstudents) return <>0</>

        return (<>
            {ComponentCommonTools.processObjectField(obj, 'numberofstudents')}
        </>)
    }

    const processInfoColumnStatistic = () => {
        if (!obj) return <></>
        return (<Stack spacing={2} textAlign="center" direction='row'>
            <Stack direction="column"  className="numbers">
                {LL('numberofstudents')}
                <Typography variant="h4" mt={2}>{processNumberOfStudents()}</Typography>
            </Stack>
            <Stack direction="column"  className="numbers">
                {LL('numberoftestimonials')}
                <Typography variant="h4" mt={2}>{processTestimonials()}</Typography>
            </Stack>
            <Stack direction="column"  className="numbers">
                {LL('Rating')}
                <Typography variant="h4" mt={2}>{processRating()}</Typography>
            </Stack>
        </Stack>)
    }

    const processBio = () => {
        if (!obj) return <></>;
        if (!obj.hasOwnProperty('bio')) return <></>;
        if (!obj.bio) return <></>;
        return (
            <Box>
                <Typography variant="h5" my={2}>{LL('About_me')}</Typography>
                <HtmlTag content={obj.bio} />
            </Box>
        )
    }
    const processUserSocials = () => {
        if (!showSocials) return <></>;
        if (!obj) return <></>;
        if (!obj.iduser) return <></>;
        return (<Box className="socialIcons"><UserSocialBlock idUser={obj.iduser} type={Types.TYPE_SOCIAL_DISPLAY_SHORT_FORM} /></Box>)
    }

    const processItem = () => {
        if (!obj) return <></>;

        return (
            <Box className='teacherDetailsDown'>
                <Stack direction="row" spacing={2} alignItems='center'>
                    {processAvatar()}
                    <Box className="info">{processInfoColumn()}</Box>
                </Stack>
                <Box my={5}>
                        {processInfoColumnStatistic()}
                </Box>  

                {processBio()}
            </Box>
        )
    };


    return id && !loading ?
        (<>
            {processItem()}
        </>) : (<Skeleton />);

};

export { TeacherInfoBlock };
