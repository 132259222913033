import React, { useState, useEffect, useContext, useCallback } from "react";
import { useResource } from "hook/useResource";
import {
  List,
  ListItemButton,
  ListItemIcon,
  Skeleton,
  Typography,
  Stack,
  Divider,
  Box,
} from "@mui/material";

import { CommonTools } from "tools/commontools";
import { RouteTools } from "tools/routetools";
import PageComponentProps from "interfaces/pagecomponentprops.interface";
import { StudentCourseContext } from "context/studentcourse/studentcourse.context";
import { LessonDto } from "dto/course/lesson.dto";
import { ToolBoxLessonItem } from "./ToolBoxLessonItem";

export const StudentCourseToolBox: React.FC<PageComponentProps> = ({
  currentRoute,
}) => {
  const { LL } = useResource();
  const {
    state: { lessonObjects },
  } = useContext(StudentCourseContext);

  const [loading, setLoading] = useState<boolean>(true);

  const checkLoading = useCallback(() => {
    let loading = false;
    if (lessonObjects === null) loading = true;
    setLoading(loading);
  }, [lessonObjects]);

  useEffect(() => {
    checkLoading();
  }, [checkLoading]);

  const processItem = (item: LessonDto, i: number) => {
    if(!item) return <></>;
    return <Box key={i}>
        <ToolBoxLessonItem obj={item} currentRoute={currentRoute} />
    </Box>;
  };

  const processComponent = () => {
    if (loading) return <Skeleton variant="rectangular" height={"100%"} />;
    if (!lessonObjects) return <></>;
    if (lessonObjects.length === 0) return <></>;
    return (
      <List>
        {lessonObjects.map((item: LessonDto, i: number) => {
          return processItem(item, i);
        })}
      </List>
    );
  };

  return processComponent();
};
