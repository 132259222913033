import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useResource } from "hook/useResource";
import { logger } from "hoc/logger";
import AdvertisementService from "services/advertisement/advertisement.service";
import PageComponentProps from "interfaces/pagecomponentprops.interface";
import { AdvertisementDto } from "dto/advertisement/advertisement.dto";
import { RouteTools } from "tools/routetools";
import RequestListDTO from "dto/app/requestlist.dto";
import { Status } from "tools/status";
import RequestFilterDTO from "dto/app/requestfilter.dto";
import ResultListDTO from "dto/app/resultlist.dto";
import { Types } from "tools/types";
import { ComponentCommonTools } from 'tools/componentcommontools'
import { Loading } from "components/general/Loading";
import Skeleton from '@mui/material/Skeleton';
import { Box } from "@mui/material";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { MyNavBt } from "components/general/MyNavBt";
import { CommonTools } from "tools/commontools";
import Grid from '@mui/material/Grid';
import Typography from "@mui/material/Typography";

const advertisementService = new AdvertisementService();

type AdvertisementBannerItemTypeBannerProps = {
    obj: AdvertisementDto;
}
const AdvertisementBannerItemTypeBanner: React.FC<AdvertisementBannerItemTypeBannerProps> = ({
    obj,
    ...props
}) => {

    const [imgUrl, setImgUrl] = useState<string>("");
    const [fullUrl, setFullUrl] = useState<string>("");
    const width = 200;
    const height = 200;
    
    const { LL } = useResource();

    const prepareGallery = () => {
        if (!obj) return;

        if (!obj.hasOwnProperty('gallery')) return;
        if (!obj.gallery) return;
        if (obj.gallery.length === 0) return;
        const galleryObj = CommonTools.getGalleryObjectLogo(obj.gallery);
        if (!galleryObj) return;
        CommonTools.setFileUrlsGallery(galleryObj, setImgUrl, setFullUrl, width, height);
    }

    useEffect(() => {
        prepareGallery();
    }, [obj])

    const processDetail = (obj: AdvertisementDto) => {
        if (!obj) return <></>;

        return (
            <Box className="container">
                <Typography variant="h2" mb={3}>
                    {ComponentCommonTools.processObjectField(obj, 'name')}
                </Typography>
                <Typography>
                    {ComponentCommonTools.processObjectField(obj, 'description')}
                </Typography>
            </Box>
        )
    }

    const processButton = () => {
        if (!obj) return <></>;
        if (!obj.fullurl) return <></>;
        const label = obj.clabt ? LL(obj.clabt) : '';
        return (
            <MyNavBt href={obj.fullurl}>
                {label}
            </MyNavBt>
        )
    }

    const processImage = () => {
        if (!imgUrl) return (<></>)
        return (<>
            {ComponentCommonTools.getFileBlock(imgUrl, width, height)}
        </>
        )
    }

    const processItem = () => {
        if (!obj) return <></>;
          
        if (!imgUrl) {
            return (
            <Box>
                {processDetail(obj)}
                {processButton()}
            </Box>
            );
        }

        return (
            <Box className="item" sx={{backgroundImage: `url(${imgUrl})`}}>
            {processDetail(obj)}
            {processButton()}
            </Box>
        );
    }

    return processItem();
};

export { AdvertisementBannerItemTypeBanner };
