import { NotificationParamsDto } from "dto/user/notificationparams.dto";
import { FileTools } from "./filetools";

export class NotificationParamsIdentifier {
    public static FILE = 'idfile';
    public static SUBJECT = 'subject';
    public static MESSAGE = 'message';


    public static getParamValue(list: Array<NotificationParamsDto>, key: string) {
        for (const item of list) {
            if (item.params === key) {
                return item.value;
            }
        }
        return '';
    }

    public static getParamImage(list: Array<NotificationParamsDto>, key: string) {
        const idFile = NotificationParamsIdentifier.getParamValue(list, key);
        const defaultImage = process.env.REACT_APP_URL_NO_IMAGE ?
            process.env.REACT_APP_URL_NO_IMAGE : '/images/no-photo.png';
        if (!idFile) return defaultImage;
        return FileTools.getFileUrl(idFile);;
    }
}