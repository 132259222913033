import { Avatar, Box, MenuItem, Skeleton, Stack, Typography } from "@mui/material";
import { NoResult } from "components/general/NoResult";
import RequestFilterDTO from "dto/app/requestfilter.dto";
import RequestListDTO from "dto/app/requestlist.dto";

import ResultListDTO from "dto/app/resultlist.dto";
import { NotificationParamsDto } from "dto/user/notificationparams.dto";
import { logger } from "hoc/logger";
import { useAuth } from "hook/useAuth";
import { useResource } from "hook/useResource";
import React, { useState, useEffect } from "react";
import NotificationParamsService from "services/user/notificationparams.service";
import { NotificationParamsIdentifier } from "tools/notificationparams";
import { Status } from "tools/status";
import { Types } from "tools/types";


type NotificationParamsListProps = {
    idNotification: string;
    timeComponent?: any
}

const notificationParamsService = new NotificationParamsService();
const NotificationParamsList: React.FC<NotificationParamsListProps> = ({
    idNotification,
    timeComponent,
    ...props }) => {

    const { LL, CC } = useResource();

    const [loading, setLoading] = useState<boolean>(false);
    const [objects, setObjects] = useState<Array<NotificationParamsDto>>([]);
    

    const getList = () => {
        if (!idNotification) return;
        const reqList = new RequestListDTO();

        reqList.onpage = parseInt(CC('MaxOnPageNotificationParams', '999999'));
        reqList.page = 1;
        reqList.filters = [];
        reqList.sortcriteria = [];

        let f: RequestFilterDTO;

        f = new RequestFilterDTO();
        f.field = "idnotification";
        f.values = [idNotification];
        reqList.filters.push(f);

        setLoading(true);
        notificationParamsService.getList(loadObjects, {}, reqList);
    };

    const checkLoading = () => {
        let load = false;
        if (!objects) load = true;
        setLoading(load);
    }

    const loadObjects = (result: ResultListDTO) => {
        if (!result) return;
        if (!result.err) {
            const objects = result.objects ? result.objects : [];
            setObjects(objects);
        }
    }

    useEffect(() => {
        checkLoading();
    }, [objects]);

    useEffect(() => {
        getList();
    }, [idNotification]);


    const processList = () => {
        if (loading) return <Skeleton variant="rectangular" />;
        if (!objects.length) return <></>;
        return (
            <Stack
                direction="row"
                spacing={1}
                alignItems="center"
            >
                 <Avatar
                        src={
                            NotificationParamsIdentifier.getParamImage(objects, NotificationParamsIdentifier.FILE)
                        }
                    />
                <Box>
                   
                

                    <Typography className="nt-name">
                        {NotificationParamsIdentifier.getParamValue(objects, NotificationParamsIdentifier.SUBJECT)}
                    </Typography>
                    <Typography  className="nt-desc">
                        {NotificationParamsIdentifier.getParamValue(objects, NotificationParamsIdentifier.MESSAGE)}
                    </Typography>
                    <Typography  className="data">{timeComponent && timeComponent()}</Typography>
                    </Box>
                    

            </Stack>
        )
    }

    return processList();
};

export { NotificationParamsList };
