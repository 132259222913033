import { Box, Stack, Typography, CircularProgress } from "@mui/material";
import { useResource } from "hook/useResource";
import React, { useContext } from "react";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import { StudentCourseContext } from "context/studentcourse/studentcourse.context";

const CourseProgress: React.FC = () => {
  const { LL, CC } = useResource();
  const {
    state: { courseEnrollmentObject },
  } = useContext(StudentCourseContext);

  const processItem = (percentage: number) => {
    const color = percentage === 100 ? "gold" : "lightgrey";

    return (
      <Box position="relative" display="inline-flex">
        <CircularProgress
          size={40}
          thickness={1}
          variant="determinate"
          value={100}
          sx={{
            color: "lightgrey",
          }}
        />
        <CircularProgress
          size={40}
          thickness={4}
          variant="determinate"
          value={percentage}
          sx={{
            color: color,
            position: "absolute",
            left: 0,
            top: 0,
            zIndex: 1,
          }}
        />
        <Box
          top={0}
          left={0}
          bottom={0}
          right={0}
          position="absolute"
          display="flex"
          alignItems="center"
          justifyContent="center"
          zIndex={2}
        >
          <EmojiEventsIcon sx={{ color: color }} />
        </Box>
      </Box>
    );
  };

  const processComponent = () => {
    if (!courseEnrollmentObject) return processItem(0);
    if (!courseEnrollmentObject.hasOwnProperty("coursepercentage"))
      return processItem(0);
    if (!courseEnrollmentObject.coursepercentage) return processItem(0);

    return processItem(courseEnrollmentObject.coursepercentage);
  };
  return (
    <Stack
      direction={"row"}
      spacing={2}
      justifyContent="center"
      alignItems="center"
    >
      <Box>{processComponent()}</Box>
      <Box>
        <Typography>{LL("Your_progress")}</Typography>
      </Box>
    </Stack>
  );
};

export { CourseProgress };
