import React, { useEffect, useState } from "react";
import { useResource } from "hook/useResource";
import { useAuth } from "hook/useAuth";
import PageComponentProps from "interfaces/pagecomponentprops.interface";
import AppBar from '@mui/material/AppBar';
import LockOpenIcon from '@mui/icons-material/LockOpen';

import SignInDto from "dto/auth/signin.dto";

import Button from "@mui/material/Button";
import { Loading } from "components/general/Loading";
import MyTextField from "components/form/mytextfield";
import MyCheckboxField from "components/form/mycheckboxfield";
import RequiredValidator from "validators/required.validator";
import { LabelTypes } from "tools/labeltypes";
import ValidateTools from "tools/validate.tools";

import InputAdornment from '@mui/material/InputAdornment';

import MailOutlineIcon from '@mui/icons-material/MailOutline';

import Box from '@mui/material/Box';
import { logger } from "hoc/logger";
import Typography from "@mui/material/Typography";
import ForgotPasswordDto from "dto/auth/forgotpassword.dto";
import { MyNavBt } from "components/general/MyNavBt";

import Container from '@mui/material/Container';
import UserService from "services/user/user.service";
import { AuthMethodsBlock } from "./AuthMethodsBlock";

const userService = new UserService();

const ForgotPassword: React.FC<PageComponentProps> = ({ currentRoute, ...props }) => {


    const { LL } = useResource();

    const [isLoading, setIsLoading] = useState(true);
    const [isFormDisabled, setIsFormDisabled] = useState(true);

    const [obj, setObj] = useState(new ForgotPasswordDto());

    const [validators, setValidators] = useState(false);
    const [vresults, setVResults] = useState<any>(false);

    const validateAll = () => {
        if (!obj) return;
        if (!validators) return;
        if (vresults) return;

        obj.email = obj.email != undefined ? obj.email : '';
        setObj(obj);

        let t = validateField("email", obj.email);

    };

    const validateField = (field: any, value: any, v?: any) => {

        v = (v) ? v : JSON.parse(JSON.stringify(vresults));
        if (v === false) v = {};
        v[field] = ValidateTools.validate(field, value, validators, vresults);

        setVResults(v);
        return v;
    };

    const processValidators = () => {
        let v: any = {
            email: [],
        };

        // v.email.push(new RequiredValidator(LL('Required field email', LabelTypes.LABEL_VALIDATION), ''));
        v.email.push(new RequiredValidator('', ''));

        setValidators(v);
    };

    const checkIsLoading = () => {
        if (!isLoading) return;
        if (!obj) return;
        if (!validators) return;
        validateAll();
        setIsLoading(false);
    };

    useEffect(() => {
        processValidators();
    }, []);

    useEffect(() => {
        checkIsLoading();
    }, [obj, validators]);

    useEffect(() => {
        checkIsFormDisabled();
    }, [vresults]);


    const setObjField = (field: any, value: any) => {
        let t = JSON.parse(JSON.stringify(obj));
        t[field] = value;
        setObj(t);

        validateField(field, value);
    };

    const checkIsFormDisabled = () => {
        var rez = ValidateTools.vForm(vresults, 'email');

        setIsFormDisabled(rez);
    };


    const handleSubmit = (event: any) => {
        event.preventDefault();

        if (isFormDisabled) return false;

        userService.resetPasswordCode(obj);
    };



    return isLoading ? (
        <Loading />
    ) : (
        <Container maxWidth="xs">

            <Box  mt={5}>


                <Typography variant="h2" align="center" sx={{ mb: 5 }}>{LL("ForgotPassword")}</Typography>

                <form onSubmit={handleSubmit} >
                    <Box className="box-form-input-icon" sx={{mb:4}}>
                        <MailOutlineIcon />
                        <MyTextField
                            fullWidth
                            id="email"
                            name="email"
                            className="form-input"
                            variant="standard"
                            _label={LL("Email")}
                            value={obj.email}
                            setObj={setObjField}
                            _vresults={
                                vresults && vresults.email ? vresults.email : false
                            }
                        />
                    </Box>
                    <Box mt={2}>
                        <Button
                            disabled={isFormDisabled}
                            color="primary"
                            fullWidth
                            variant="contained"
                            type="submit"
                            className="btn"
                        >
                            {LL("Bt_Forgot_Submit")}
                        </Button>

                    </Box>
                </form>

                <Typography className="text-strike" my={4}>{LL('Remembered_password?')}</Typography>
                <AuthMethodsBlock isLoginPage={false} />
            </Box>

        </Container >
    );
};

export { ForgotPassword };
