import React, { useEffect, useState } from "react";
import PageComponentProps from "interfaces/pagecomponentprops.interface";
import { useResource } from "hook/useResource";
import { logger } from "hoc/logger";

import { MyNavBt } from "components/general/MyNavBt";
import Typography from '@mui/material/Typography'
import { Box, Stack } from '@mui/material'
import { ComponentCommonTools } from 'tools/componentcommontools'
import Grid from '@mui/material/Grid';

import TeacherService from "services/teacher/teacher.service";

import { useAuth } from "hook/useAuth";

import { MyButton } from 'components/general/MyButton';
import ValidateTools from 'tools/validate.tools';
import MyTextField from 'components/form/mytextfield';
import { CommonTools } from "tools/commontools";
import { CircularLoading } from 'components/general/CircularLoading';


import MyCheckboxField from "components/form/mycheckboxfield";
import RequiredValidator from "validators/required.validator";
import { CourseDto, CreateCourseFirstStepDto, EditGeneralInfoCourseDto } from "dto/course/course.dto";
import CourseService from "services/course/course.service";
import { CourseCategoryMultiAutocomplete } from "components/elements/Autocomplete/CourseCategoryMultiAutocomplete";
import MyCKEditor from "components/elements/ckeditor/MyCKEditor";
import { LanguageSelect } from "components/elements/Select/LanguageSelect";
import { useMessage } from "hook/useMessage";

const courseService = new CourseService();

type EditGeneralInfoProps = {
    currentRoute: any
    defaultObj: CourseDto;
    setObjectWasChanged: any;
    objectWasChanged: string
}
const EditGeneralInfo: React.FC<EditGeneralInfoProps> = ({
    currentRoute,
    defaultObj,
    setObjectWasChanged,
    objectWasChanged,
    ...props
}) => {
    const { LL, } = useResource();
    const { teacher } = useAuth();
    const { registerDialog } = useMessage();
    const id = currentRoute._paths !== undefined ? currentRoute._paths[2] : '';
    const [loading, setLoading] = useState<boolean>(false);
    const [obj, setObj] = useState<EditGeneralInfoCourseDto>(defaultObj);


    const [isFormDisabled, setIsFormDisabled] = useState(true);

    const [validators, setValidators] = useState(false);
    const [vresults, setVResults] = useState<any>(false);


    const validateAll = () => {
        if (!obj) return;
        // if (!validators) return;
        // if (vresults) return;
        logger('validateAllEditGeneral', obj);
        obj.name = obj.name !== undefined ? obj.name : '';
        obj.description = obj.description !== undefined ? obj.description : '';
        obj.requirementstext = obj.requirementstext !== undefined ? obj.requirementstext : '';
        obj.whatwillyoulearntext = obj.whatwillyoulearntext !== undefined ? obj.whatwillyoulearntext : '';
        obj.targetaudiencetext = obj.targetaudiencetext !== undefined ? obj.targetaudiencetext : '';
        obj.shortdescription = obj.shortdescription !== undefined ? obj.shortdescription : '';
        obj.idlanguage = obj.idlanguage !== undefined ? obj.idlanguage : '';


        setObj(obj);

        validateField("name", obj.name);
        // t = validateField("description", obj.description, t);
    };

    const validateField = (field: any, value: any, v?: any) => {
        v = (v) ? v : JSON.parse(JSON.stringify(vresults));
        if (v === false) v = {};
        v[field] = ValidateTools.validate(field, value, validators, vresults);
        setVResults(v);
        return v;
    };

    const processValidators = () => {
        let v: any = {
            name: [],
            // description: [],
        };

        v.name.push(new RequiredValidator('', ''));
        // v.description.push(new RequiredValidator(LL('Required field description', LabelTypes.LABEL_VALIDATION), ''));
        setValidators(v);
    };

    const checkLoading = () => {
        if (!loading) return;
        if (!obj) return;
        if (!validators) return;
        validateAll();
        setLoading(false);
    };

    useEffect(() => {
        processValidators();
        validateAll();
    }, [objectWasChanged]);

    useEffect(() => {
        checkLoading();
    }, [obj, validators]);

    useEffect(() => {
        checkIsFormDisabled();
    }, [vresults]);


    const setObjField = (field: any, value: any) => {
        let t = JSON.parse(JSON.stringify(obj));
        t[field] = value;
        setObj(t);

        validateField(field, value);
    };

    const checkIsFormDisabled = () => {
        var rez = ValidateTools.vForm(vresults, 'name');
        if (obj.name !== undefined) rez = false;
        setIsFormDisabled(rez);
    };

    const handleSubmit = (event: any) => {
        event.preventDefault();
        if (isFormDisabled) return false;
        if (id) {
            obj.id = id;
    
            registerDialog(LL('Confirm_edit_course'),
                LL('Are_you_sure_you_want_to_edit_this_course_editing_will_change_the_status'),
                { cb: handleConfirmEdit }, null);
        }
    };

    
    const handleConfirmEdit = () => {
        
        if (obj.id) {
            courseService.updateGeneralInfo(
                CommonTools.handleSuccessAddAndEditStayOnPage,
                { setObjectWasChanged },
                obj
            );
        }
    }

    return !loading && obj ? (
        <>

            
                <form onSubmit={handleSubmit}>
                    <Typography variant="h6" mb={1}>
                            {LL('Numele cursului')}
                        </Typography>
                    <MyTextField
                        tabIndex={0}
                        fullWidth
                        id="name"
                        name="name"
                        variant="standard"
                        color="primary"
                        className='form-input'
                        _label={LL("Name")}

                        value={obj.name}
                        setObj={setObjField}
                        _vresults={
                            vresults && vresults.name ? vresults.name : false
                        }
                    />
                    <Box mt={3} className="myCk">
                        <Typography variant="h6" mb={1}>
                            {LL('Descrierea cursului')}
                        </Typography>
                        <MyCKEditor
                            data={obj.description ? obj.description : ''}
                            setObj={setObjField}
                            name="description"
                        />
                    </Box>
                    <Box mt={3}>
                        <Typography variant="h6" mb={1}>
                            {LL('requirementstext')}
                        </Typography>
                        <MyCKEditor
                            data={obj.requirementstext ? obj.requirementstext : ''}
                            setObj={setObjField}
                            name="requirementstext"
                        />
                    </Box>
                    <Box mt={3}>
                        <Typography variant="h6" mb={1}>
                            {LL('whatwillyoulearntext')}
                        </Typography>
                        <MyCKEditor
                            data={obj.whatwillyoulearntext ? obj.whatwillyoulearntext : ''}
                            setObj={setObjField}
                            name="whatwillyoulearntext"
                        />
                    </Box>
                    <Box mt={3}>
                        <Typography variant="h6" mb={1}>
                            {LL('targetaudiencetext')}
                        </Typography>
                        <MyCKEditor
                            data={obj.targetaudiencetext ? obj.targetaudiencetext : ''}
                            setObj={setObjField}
                            name="targetaudiencetext"
                        />
                    </Box>
                    <Box mt={3} className="myCk">
                        <Typography variant="h6" mb={1}>
                            {LL("Descriere scurta")}
                        </Typography>
                        <MyCKEditor
                            data={obj.shortdescription ? obj.shortdescription : ''}
                            setObj={setObjField}
                            name="shortdescription"
                        />
                    </Box>
                    <Box sx={{ mt: 3 }}>
                     <Typography variant="h6" mb={1}>
                            {LL("Selecteaza limba cursului")}
                        </Typography>
                        <LanguageSelect
                            tabIndex={4}
                            id="idlanguage"
                            name="idlanguage"
                            variant="standard"
                            className='form-select'
                            color="primary"
                            value={obj.idlanguage}
                            field="idlanguage"
                            setObj={setObjField}
                            obj={obj}
                            _defaultValue={obj.idlanguage}
                        />
                    </Box>
                    <Box sx={{ mt: 3 }} textAlign='center'>
                    <MyButton
                        tabIndex={5}
                        disabled={isFormDisabled}
                        color="primary"
                        variant="contained"
                        size="large"
                        className="btn"
                        type="submit"
                        sx={{ mt: 4 }}
                        children={LL("Bt_Submit")}
                    />
                    </Box>
                </form>
            
        </>
    ) : (
        <CircularLoading />
    );
};

export { EditGeneralInfo };
