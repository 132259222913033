import React,{useEffect} from "react";
import PageComponentProps from "interfaces/pagecomponentprops.interface";
import { HomeSearchBlock } from "components/homepage/HomeSearchBlock";
import { HomeCourseBlock } from "components/homepage/HomeCourseBlock";
import { HomeTopTeacherBlock } from "components/homepage/HomeTopTeacherBlock";
import { HomeTestimonial } from "components/homepage/HomeTestimonial";
import { HomeCategoryBlock } from "components/homepage/HomeCategoryBlock";
import { HomeBannersBottomBlock } from "components/homepage/HomeBannersBottomBlock";
import { AdvertisementHomeBannerSection1 } from "components/advertisement/AdvertisementHomeBannerSection1";
import { AdvertisementHomeBannerSection2 } from "components/advertisement/AdvertisementHomeBannerSection2";
import { AdvertisementHomeBannerBenefits } from "components/advertisement/AdvertisementHomeBannerBenefits";
import { AdvertisementHomeBannerSuggestions } from "components/advertisement/AdvertisementHomeBannerSuggestions";
import { NewsletterBlock } from "./NewsletterBlock";
import PageService from "services/page/page.service";

const service = new PageService();
const HomePage: React.FC<PageComponentProps> = ({ currentRoute, ...props }) => {
  
  
  const getHomeSEO = () => {
    service.getHomeSEO();
  };

  useEffect(() => {
    getHomeSEO()
  }, []);
  
  return (
    <>
      {/* Top section */}
      <HomeSearchBlock currentRoute={currentRoute} />
      {/* <HomeSmallBannerBlock currentRoute={currentRoute} /> */}
      <AdvertisementHomeBannerBenefits currentRoute={currentRoute} />
      {/* Top section */}

      {/* Info section */}
      <HomeCourseBlock currentRoute={currentRoute} />
      {/* <HomeBannerInfoBlock currentRoute={currentRoute} /> */}
      <AdvertisementHomeBannerSuggestions currentRoute={currentRoute} />
      <HomeCategoryBlock currentRoute={currentRoute} />
      {/* <HomeBannersBlock currentRoute={currentRoute} /> */}
      <AdvertisementHomeBannerSection1 currentRoute={currentRoute} />
      <AdvertisementHomeBannerSection2 currentRoute={currentRoute} />
      <HomeTopTeacherBlock currentRoute={currentRoute} />
      {/* Info section */}

      {/* Bottom section */}
      <HomeTestimonial currentRoute={currentRoute} />
      <HomeBannersBottomBlock currentRoute={currentRoute} />
      <NewsletterBlock currentRoute={currentRoute} />

      {/* Bottom section */}
    </>
  );
};

export { HomePage };
