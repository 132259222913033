import React, { useState } from "react";
import PageComponentProps from "interfaces/pagecomponentprops.interface";
import { Box, Typography, Grid } from "@mui/material";
import MyTextField from "components/form/mytextfield";
import { MyButton } from "./MyButton";
import { useResource } from "hook/useResource";
import MailOutlineIcon from '@mui/icons-material/MailOutline';

const NewsletterBlock: React.FC<PageComponentProps> = ({ currentRoute, ...props }) => {

    const { LL } = useResource();
    const [value, setValue] = useState("");


    const handleSubmit = (event: any) => {
        event.preventDefault();


    };
    return (
      <Box className="newsletterBlock">
      <Grid container className="container" sx={{ justifyContent:'center'}}>
        <Grid item sm={12} md={5} mx={0}>
          <Typography className="title">
            {LL("Subscribe_to_Newsletter")}
          </Typography>

          <Typography my={3} className="description">
            {LL("Subscribe_to_Newsletter_Detail_Information")}
          </Typography>

          <form onSubmit={handleSubmit}>
            <Box className="form">
              <MailOutlineIcon />
              <MyTextField
                _containerClass="input"
                fullWidth
                variant="outlined"
                id="search"
                name="email"
                className="input"
                sx={{
                  width: "100%",
                  "& label.MuiFormLabel-root": {
                    color: "#fff",
                  },
                  "& label.Mui-focused": {
                    display: "none",
                  },
                  "& .MuiOutlinedInput-root.Mui-focused": {
                    "& > fieldset": {
                      borderColor: "none",
                      borderWidth: "0px",
                    },
                  },
                  "& .MuiOutlinedInput-root": {
                    "& > fieldset": {
                      border: "none",
                    },
                  },
                }}
                _label={LL("Enter_the_personal_email")}
                value={value}
                setObj={(field: any, value: any) => {
                  setValue(value);
                }}
                _vresults={undefined}
              />
              <MyButton cb={() => {}} variant="contained" className="btn">
                {LL("Bt_Subscribe_news")}{" "}
              </MyButton>
            </Box>
          </form>
        </Grid>
      </Grid>
      </Box>
    );
};

export { NewsletterBlock };
