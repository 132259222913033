// MyCKEditor.tsx

import React, { useState, useEffect } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { logger } from "hoc/logger";

interface MyCKEditorProps {
  field?: string | undefined;
  name?: string | undefined;
  data: string;
  setObj: (field: any, value: any) => void;
}

const MyCKEditor: React.FC<MyCKEditorProps> = ({data, setObj, ...props } ) => {
  const [field, setField] = useState("");
  
  useEffect(() => {
    // const p: any = props;
    const f = props.field ? props.field : props.name;
    if (f != undefined) {
      setField(f);
    }
  }, [props]);

  const handleChange = (event:any, editor:any) => {
    if (!setObj) return;
    
    setObj(field, editor.getData());
  };

  return (
    <CKEditor
      data={data}
      editor={ClassicEditor}
      onChange={handleChange}
      {...props}
    />
  );
};

export default MyCKEditor;
