import React, { useState, useEffect } from "react";
import { CheckCourseInWishlist } from "dto/user/wishlist.dto";
import ResultObjectDTO from "dto/app/resultobject.dto";
import WishlistService from "services/user/wishlist.service";

import { useResource } from "hook/useResource";
import { useAuth } from "hook/useAuth";
import { Skeleton, IconButton, Box } from "@mui/material";
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import { PostWishlistDto } from "../../dto/user/wishlist.dto";
import { logger } from "hoc/logger";
import { CommonTools } from "tools/commontools";

type WishlistIconCheckerProps = {
    idCourse: string;
    onChangeSubscribe?: any
    isForCard?: boolean
}
const wishlistService = new WishlistService();

const WishlistIconChecker: React.FC<WishlistIconCheckerProps> = ({ idCourse, onChangeSubscribe,isForCard = false, ...props }) => {

    const [loading, setLoading] = useState<boolean>(true);
    const [obj, setObj] = useState<CheckCourseInWishlist | undefined>();
    const [subscribe, setSubscribe] = useState<boolean>(false);
    const [id, setId] = useState<string>("");
    const { user } = useAuth();
    const {LL} = useResource();
    

    const loadObject = (obj: ResultObjectDTO) => {
        logger('WishlistIconChecker obj', obj)
        if (!obj) return;
        if (!obj.err) {
            const object = obj.obj ? obj.obj : {};
            setObj(object);
            logger('WishlistIconChecker', object)
        }
    };

    const getObject = () => {
        logger('WishlistIconChecker', user, idCourse)
        if (!user) return;
        if (!idCourse) return;
        if (!user.id) return;
        setLoading(true)
        wishlistService.checkInWishlist(user.id, idCourse, loadObject, {});
    };

    const checkIsLoading = () => {
        let load: boolean = false;
        if (obj === undefined) load = true;
        if (obj) {
            processObject();
        }
        setLoading(load);
    };

    useEffect(() => {
        checkIsLoading();

    }, [obj]);



    const processObject = () => {
        if (!obj) return;
        if (!obj.isinwishlist) {
            setSubscribe(false);
            setId('');
        }
        else {
            setSubscribe(true);
            if (obj.hasOwnProperty('id') && obj.id) setId(obj.id);
        }
    }

    useEffect(() => {
        getObject();

    }, [idCourse, user]);

    const handleSubscribe = () => {
        if (!user) return;
        if (!idCourse) return;
        if (!user.id) return;
        const obj = new PostWishlistDto();
        obj.idcourse = idCourse;
        obj.iduser = user.id;

        wishlistService.add(getObject, {}, obj)

    }
    const handleUnSubscribe = () => {
        if (!user) return;
        if (!idCourse) return;
        if (!id) return;
        wishlistService.delete(id, getObject, {})
        handleOnChangeSubscribe();

    }

    const handleOnChangeSubscribe = () => {
        if (onChangeSubscribe) {
            const localRefresh = CommonTools.generateRandomString(10)
            onChangeSubscribe(localRefresh)
        }
    }

    const processElement = () => {
        if (!obj) return <></>
        if (!user) return <></>
        logger('WishlistIconChecker', subscribe)
        if (!subscribe) {
            return (
                <IconButton onClick={handleSubscribe}>

                    <FavoriteBorderIcon color='error' />
                </IconButton>
            )
        }

        return (
            <IconButton onClick={handleUnSubscribe}>
                <FavoriteIcon color='error' />
            </IconButton>
        )


    }

    const processElementNotForCard = () => {
        if (!obj) return <></>
        if (!user) return <></>
        
        if (!subscribe) {
            return (
                <Box onClick={handleSubscribe} className='btnWish'>

                    <FavoriteBorderIcon color='error'  sx={{mr:1}}/>{LL('Wishlist')}
                </Box>
            )
        }

        return (
            <Box onClick={handleUnSubscribe} className='btnWish'>
                <FavoriteIcon color='error' sx={{mr:1}} />{LL('Wishlist')}
            </Box>
        )


    }

    const processComponent = () => {
        if(isForCard) return processElement();
        return processElementNotForCard();
    }

    return loading ? <Skeleton /> : processComponent();
};

export { WishlistIconChecker };
