import React from "react";

import { useResource } from "hook/useResource";
import { logger } from "hoc/logger";

import {
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  ListItemButton,
  Link,
} from "@mui/material";

import { GalleryDto } from "dto/gallery/gallery.dto";

import CollectionsIcon from '@mui/icons-material/Collections';
import { FileTools } from "tools/filetools";

type GalleryListLessonStaticProps = {
  objects: Array<GalleryDto>;
  disabled: boolean;
};
const GalleryListLessonStatic: React.FC<GalleryListLessonStaticProps> = ({
  objects,
  disabled,

  ...props
}) => {
  const { LL, CC } = useResource();

  const processItemUrl = (item: GalleryDto) => {
    if (!item) return "";
    if (!item.hasOwnProperty("idfile")) return "";
    if (!item.idfile) return "";
    const url = FileTools.getFileUrl(item.idfile);
    return url;
  };
  const processItem = (item: GalleryDto, i: number) => {
    if (!item) return <></>;
    if (!item.hasOwnProperty("id")) return <></>;
    if (!item.id) return <></>;
    let name = "";
    if (item.hasOwnProperty("name") && item.name) name = item.name;
    else {
      const index = i + 1;
      name = LL("gallery") + " " + index.toString();
    }
    const url = processItemUrl(item);
    return (
      <ListItem key={i} disablePadding className="subList" sx={{m:0, p:0}}>
        <ListItemButton
          disabled={disabled}
          component={Link}
          target="_blank"
          rel="noreferrer"
          href={url}
        >

          <CollectionsIcon sx={{mr:1}} />
          <ListItemText primary={name} />
        </ListItemButton>
      </ListItem>
    );
  };
  const processLessonList = () => {
    if (!objects.length) return <></>;

    return (
      <List sx={{m:0, p:0}}>
        {objects.map((item: GalleryDto, i: number) => {
          return processItem(item, i);
        })}
      </List>
    );
  };
  return processLessonList();
};

export { GalleryListLessonStatic };
