import React from "react";
import PageComponentProps from "interfaces/pagecomponentprops.interface";
import { TeacherInterfaceStudentList } from "./TeacherInterfaceStudentList";

const TeacherInterfaceStudentBlock: React.FC<PageComponentProps> = ({ currentRoute, ...props }) => {

    return (
        <>
            <TeacherInterfaceStudentList currentRoute={currentRoute} />
        </>
    );
};

export { TeacherInterfaceStudentBlock };
