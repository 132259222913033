import React, { useState, useEffect } from "react";

import { AddCoursePriceDto, CoursePriceDto } from "dto/course/courseprice.dto";
import { useResource } from "hook/useResource";
import { CourseDto } from "dto/course/course.dto";
import { Box, Typography, Alert, Skeleton } from "@mui/material";
import { CommonTools } from "tools/commontools";
import ResultObjectDTO from "dto/app/resultobject.dto";
import { MyButton } from "components/general/MyButton";
import CoursePriceService from "services/course/courseprice.service";
import { RecommendationPriceSelect } from "components/elements/Select/RecommendationPriceSelect";
import { Status } from "tools/status";
import PricePolicyService from "services/finance/pricepolicy.service";
import { PricePolicyDto } from "dto/finance/pricepolicy.dto";
import { useAuth } from "hook/useAuth";
import { useMessage } from "hook/useMessage";
import { logger } from "hoc/logger";

type Props = {
  currentRoute: any;
  course: CourseDto;
  setObjectHasChanged: (value: string) => void;
};

const service = new CoursePriceService();
const pricePolicyService = new PricePolicyService();

const AddCoursePrice: React.FC<Props> = ({
  currentRoute,
  course,
  setObjectHasChanged,
}) => {
  const { LL } = useResource();
  const { teacher } = useAuth();
  const id = currentRoute._paths !== undefined ? currentRoute._paths[2] : "";
  const [loading, setLoading] = useState<boolean>(true);
  const [obj, setObj] = useState<AddCoursePriceDto>(new AddCoursePriceDto());
  const [pricePolicyObj, setPricePolicyObj] = useState<
    PricePolicyDto | null | undefined
  >();
  const [isFormDisabled, setIsFormDisabled] = useState(false);
  const { registerDialog } = useMessage();

  const setObjField = (field: any, value: any) => {
    let t = JSON.parse(JSON.stringify(obj));
    t[field] = value;
    setObj(t);
  };
  const checkIsFormDisabled = () => {
    let rez = false;
    if (!obj) rez = true;

    if (!teacher) rez = true;
    if (!teacher.hasOwnProperty("status")) rez = true;
    if (!teacher.status) rez = true;
    if (teacher.status === Status.TEACHER_UNVERIFIED) rez = true;
    if (!obj) rez = true;
    if (obj && !obj.idrecommendationprice) rez = true;

    setIsFormDisabled(rez);
  };

  useEffect(() => {
    checkIsFormDisabled();
  }, [obj?.idrecommendationprice, teacher, teacher?.status]);

  const loadObjectPrice = (obj: ResultObjectDTO) => {
    if (!obj) return;

    if (!obj.err) {
      const object = obj.obj;
      setPricePolicyObj(object);
    }
  };

  const checkLoading = () => {
    let loading = false;
    if (pricePolicyObj === undefined) loading = true;
    setLoading(loading);
  };

  useEffect(() => {
    checkLoading();
  }, [pricePolicyObj]);

  const getPricePolicy = () => {
    if (!teacher) return;
    if (!teacher.hasOwnProperty("id")) return;
    if (!teacher.id) return;
    if (!teacher.hasOwnProperty("status")) return;
    if (!teacher.status) return;
    logger("AddCoursePrice", course);
    if (!course) return;
    if (!course.hasOwnProperty("coursesettings")) return;
    if (!course.coursesettings) return;
    if (!course.coursesettings.hasOwnProperty("idtypecourse")) return;
    if (!course.coursesettings.idtypecourse) return;
    logger("AddCoursePrice");
    setLoading(true);
    pricePolicyService.getPriceForCourse(
      teacher.id,
      teacher.status,
      course.coursesettings.idtypecourse,
      loadObjectPrice,
      {}
    );
  };

  useEffect(() => {
    getPricePolicy();
  }, [teacher?.id, teacher?.status, course?.coursesettings?.idtypecourse]);

  const processPricePolicy = (obj: any) => {
    if (!obj) return <></>;
    return (
      <Box sx={{ mt: 3 }}>
        <Typography variant="h6" mb={1}>
          {CommonTools.processObjectField(obj, ["name"])}
        </Typography>
      </Box>
    );
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();
    if (isFormDisabled) return false;
    if (!obj) return;

    registerDialog(
      LL("Confirm_edit_course"),
      LL(
        "Are_you_sure_you_want_to_edit_this_course_editing_will_change_the_status"
      ),
      { cb: handleConfirmAdd },
      null
    );
  };

  const handleConfirmAdd = () => {
    if (!pricePolicyObj) return;
    if (!pricePolicyObj.id) return;
    if (obj) {
      obj.idcourse = id;
      obj.idpricepolicy = pricePolicyObj.id;

      service.add(
        CommonTools.handleSuccessAddAndEditStayOnPage,
        { setObjectWasChanged: setObjectHasChanged },
        obj
      );
    }
  };

  const processComponent = () => {
    if (loading) return <Skeleton height={"100%"} />;
    if (pricePolicyObj === undefined) return <></>;
    if (pricePolicyObj === null)
      return (
        <Box mt={2}>
          <Alert severity="error">
            {LL(
              "Sorry_at_the_moment_there_is_no_price_policy_available_for_this_course"
            )}
          </Alert>
        </Box>
      );
    if (!pricePolicyObj.id) return <></>;
    return (
      <form onSubmit={handleSubmit}>
        {processPricePolicy(pricePolicyObj)}
        <Box sx={{ mt: 3 }}>
          <Typography variant="h6" mb={1}>
            {LL("Recommendation price")}
          </Typography>
          <RecommendationPriceSelect
            id="idrecommendationprice"
            name="idrecommendationprice"
            variant="standard"
            className="form-select"
            color="primary"
            value={obj.idrecommendationprice}
            field="idrecommendationprice"
            idpricepolicy={pricePolicyObj.id}
            setObj={setObjField}
            obj={obj}
            useDefaultValueSelectPrice={true}
          />
        </Box>
        <Box mt={4} textAlign="center">
          <MyButton
            disabled={isFormDisabled}
            color="primary"
            variant="contained"
            size="large"
            type="submit"
            className="btn"
            children={LL("Bt_Submit")}
          />
        </Box>
      </form>
    );
  };

  return processComponent();
};

export { AddCoursePrice };
