import React, { useEffect, useState } from "react";
import PageComponentProps from "interfaces/pagecomponentprops.interface";
import { useResource } from "hook/useResource";
import { logger } from "hoc/logger";

import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'

import UserService from "services/user/user.service";
import Grid from '@mui/material/Grid';
import { useAuth } from "hook/useAuth";
import { Divider } from "@mui/material";

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { TabPanel } from 'components/elements/Tabs/TabPanel';

import { CircularLoading } from 'components/general/CircularLoading';

import { MyButton } from "components/general/MyButton";
import { RouteTools } from "tools/routetools";
import UserDto from "dto/user/user.dto";
import ValidateTools from 'tools/validate.tools';
import RequiredValidator from 'validators/required.validator';
import { LabelTypes } from 'tools/labeltypes';

import MyTextField from 'components/form/mytextfield';
import { CommonTools } from "tools/commontools";
import { MyBreadcrumbs } from "components/elements/MyBreadcrumbs/MyBreadcrumbs";
import { IconButton } from "@mui/material";
import { MyNavBt } from "components/general/MyNavBt";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Skeleton from '@mui/material/Skeleton';


import Button from '@mui/material/Button';
import Container from "@mui/material/Container";
import { PopularCourses } from "components/course/course/PopularCourses";
import { TendencyCourses } from "components/course/course/TendencyCourses";



const CourseTabsPanelBlock: React.FC<PageComponentProps> = ({
    currentRoute,
    ...props
}) => {
    const mainObject = currentRoute._mainobject;

    const { LL } = useResource();

    const [loading, setLoading] = useState<boolean>(false);
    // const [obj, setObj] = useState<UserDto | undefined>();


    const [value, setValue] = React.useState('popularCourses');

    const handleChange = (event: React.SyntheticEvent, newValue: any) => {
        setValue(newValue);
    };

    const ChildsCrumbs = [
        { url: "/", label: LL("crumb_homepage") },
        {
            url: '/profile',
            label: LL("crumb_personal_cabinet"),
        },
        {
            label: LL("crumbEdit_" + mainObject),
        },
    ];

    return !loading ? (
        <>
            <Box className="container" >

                <Tabs
                allowScrollButtonsMobile
                    variant="scrollable"
                    value={value}
                    scrollButtons={false}
                    onChange={handleChange}
                    className="tabCourse"
                    sx={{ mb:2 }}
                >
                    <Tab className="name" label={LL("Popular_Courses")} value={'popularCourses'} />
                    <Tab className="name" label={LL("Tendency_Courses")} value={'tendencyCourses'} />

                </Tabs>

                <Box >

                    <TabPanel value={value} index={'popularCourses'}>
                        <PopularCourses currentRoute={currentRoute} />
                    </TabPanel>
                    <TabPanel value={value} index={'tendencyCourses'}>
                        <TendencyCourses currentRoute={currentRoute} />
                    </TabPanel>

                </Box>

            </Box>

        </>
    ) : (
        <Skeleton variant="rectangular" />
    );
};

export { CourseTabsPanelBlock };
