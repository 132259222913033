import React from 'react';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined';
import InsertChartOutlinedIcon from '@mui/icons-material/InsertChartOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import InboxIcon from '@mui/icons-material/Inbox';
import MailIcon from '@mui/icons-material/Mail';
import { MenuItem, MenuItemOption } from 'dto/app/teacherdrawer.dto';
import ReviewsIcon from '@mui/icons-material/Reviews';
import QuizIcon from '@mui/icons-material/Quiz';
export const StudentDrawerMenuItems: MenuItem[] = [
    {
        name: 'Amg_Courses',
        icon: <OndemandVideoIcon />,
        isSelected: false,
        options: [
            {
                name: 'MyCourses',
                icon: <InboxIcon />,
                href: '/profile/course',
                currentItemMenu: 'course',
            },
        ],
    },
    {
        name: 'Amg_Communications',
        icon: <ChatOutlinedIcon />,
        isSelected: false,
        options: [
          { name: 'FAQ', icon: <QuizIcon />, href: '/profile/faq', currentItemMenu: 'teacherfaq' },
        //   { name: 'Messages', icon: <MailIcon />, href: '/', currentItemMenu: 'messages' },
          {
            name: 'Testimonials',
            icon: <ReviewsIcon />,
            href: '/profile/testimonial',
            currentItemMenu: 'testimonial'
          },
          { 
            name: 'Ticket', 
            icon: <QuizIcon />, 
            href: '/profile/ticket', 
            currentItemMenu: 'ticket' 
        },
        ],
      },
    {
        name: 'Amg_Profile_and_Settings',
        icon: <SettingsOutlinedIcon />,
        isSelected: false,
        options: [
            {
                name: 'Personal_cabinet',
                icon: <InboxIcon />,
                href: '/profile',
                currentItemMenu: 'profile',
            },
            {
                name: 'EditProfile',
                icon: <></>,
                href: '/profile/edit',
                currentItemMenu: 'editprofile',
            },
            {
                name: 'Notifications',
                icon: <></>,
                href: '/profile/notification',
                currentItemMenu: 'notification',
            },
        ],
    },
];
