import React, { useState, useEffect } from 'react';


import { logger } from 'hoc/logger';
import useMyFormValidation from 'hook/useMyFormValidation';

import { useCookies } from 'react-cookie';
import { Loading } from 'components/general/Loading.jsx';
import { MyNavBt } from "components/general/MyNavBt";
import { useResource } from "hook/useResource";
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { CommonTools } from 'tools/commontools';
import { ComponentCommonTools } from 'tools/componentcommontools'
import ResultObjectDTO from 'dto/app/resultobject.dto';
import MyTextField from 'components/form/mytextfield';
import { LabelTypes } from 'tools/labeltypes';
import ValidateTools from 'tools/validate.tools';
import RequiredValidator from 'validators/required.validator';
import { MyButton } from 'components/general/MyButton';
import { RouteTools } from 'tools/routetools';
import { MyBreadcrumbs } from "components/elements/MyBreadcrumbs/MyBreadcrumbs";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import IconButton from '@mui/material/IconButton';

import { Status } from 'tools/status';
import { CircularLoading } from 'components/general/CircularLoading';
import { useMessage } from 'hook/useMessage';
import MyCheckboxField from 'components/form/mycheckboxfield';
import { CourseVerifyDialog } from '../courseaction/CourseVerifyDialog';
import { CourseUnpublishDialog } from '../courseaction/CourseUnpublishDialog';
import { CoursePublishButton } from '../courseaction/CoursePublishButton';
import { CourseDto } from 'dto/course/course.dto';

interface CourseActionsProps {
    currentRoute: any;
    course: CourseDto;
    setCourse: any;
}

const CourseActions: React.FC<CourseActionsProps> = ({
    currentRoute,
    course,
    setCourse,
    ...props
}) => {

    const { LL } = useResource();

    return (
        <Box>
            <Typography>
                {LL('CourseActions')}
            </Typography>
            <Box>
                <CourseVerifyDialog
                    currentRoute={currentRoute}
                    course={course}
                />
            </Box>
            <Box>
                <CoursePublishButton currentRoute={currentRoute} obj={course} setObj={setCourse} />
            </Box>
            <Box>
                <CourseUnpublishDialog
                    currentRoute={currentRoute}
                    course={course}
                />
            </Box>
        </Box>
    )
};

export default CourseActions;