import { CircularLoading } from "components/general/CircularLoading";
import RequestFilterDTO from "dto/app/requestfilter.dto";
import RequestListDTO from "dto/app/requestlist.dto";
import ResultListDTO from "dto/app/resultlist.dto";
import { AnswerDto } from "dto/course/answer.dto";
import { QuestionDto } from "dto/course/question.dto";
import { useResource } from "hook/useResource";
import React, { useState, useEffect } from "react";
import AnswerService from "services/course/answer.service";
import {
    Box,
    Typography,
    FormControl,
    FormControlLabel,
    FormGroup,
    Checkbox
} from "@mui/material";
import { ComponentCommonTools } from "tools/componentcommontools";
type AnswerMultipleQuestionOptionProps = {
    obj: QuestionDto;
    objects: Array<AnswerDto>;
    answerSelected: Array<string>;
    setAnswerSelected: any;
}

const answerService = new AnswerService();
const AnswerMultipleQuestionOption: React.FC<AnswerMultipleQuestionOptionProps> = ({
    obj,
    objects,
    answerSelected,
    setAnswerSelected,
    ...props }) => {

    const [value, setValue] = useState<string[]>([]);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const optionValue = (event.target as HTMLInputElement).value;
        setValue(prevValue => {
            if (prevValue.includes(optionValue)) {
                return prevValue.filter(item => item !== optionValue);
            } else {
                return [...prevValue, optionValue];
            }
        });

    };

    useEffect(() => {
        setAnswerSelected(value);
    }, [value]);

    const processAnswerItem = (obj: AnswerDto, index: number) => {
        if (!obj || !obj.id || !obj.name) return <></>;

        return (
            <FormControlLabel
            className="answerElement"
                control={
                    <Checkbox
                        checked={value.includes(obj.id)}
                        onChange={handleChange}
                        value={obj.id}
                    />
                }
                label={obj.name}
            />
        );
    };

    const processAnswerList = () => {
        if (!objects.length) return <></>;

        return (
            <FormControl component="fieldset">
                <FormGroup row>
                    {objects.map((obj, index) => {
                        return processAnswerItem(obj, index);
                    })}
                </FormGroup>
            </FormControl>
        );
    };

    return processAnswerList();
};

export { AnswerMultipleQuestionOption };
