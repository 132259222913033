import React, { useState, useEffect } from "react";
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Skeleton from '@mui/material/Skeleton';
import { CourseDto } from 'dto/course/course.dto'
import { MyButton } from "components/general/MyButton";
import { CommonTools } from 'tools/commontools';
import { ComponentCommonTools } from 'tools/componentcommontools'
import { useResource } from "hook/useResource";
import { RouteTools } from "tools/routetools";
import { Box, Stack, Divider } from "@mui/material";
import { CourseFilterList } from "components/course/coursefilter/CourseFilterList";
import { LinkCourseCategoryList } from "components/course/linkcoursecategory/LinkCourseCategoryList";
import { CourseSettingsInfo } from "components/course/coursesettings/CourseSettingsInfo";
import { TypeCourseCardChip } from "./TypeCourseCardChip";
import DateTools from "tools/date.tools";
import { CourseActionButton } from "components/elements/MyButton/CourseActionButton";
import { CourseVideoDialog } from "./CourseVideoDialog";
type CourseCharacteristicsProps = {
    obj: CourseDto;
    loading: boolean;
    setOpenEnrollmentDialog: any;
    openEnrollmentDialog: boolean;
}

const CourseCharacteristics: React.FC<CourseCharacteristicsProps> = ({
    obj, 
    loading, 
    setOpenEnrollmentDialog,
    openEnrollmentDialog,
    
    ...props }) => {

    const { LL } = useResource();
    const [imgUrl, setImgUrl] = useState<string>("");
    const [fullUrl, setFullUrl] = useState<string>("");

    const processCourseActionButton = () => {
        if (!obj) return <></>;
        if (!obj.hasOwnProperty('id')) return <></>;
        if (!obj.id) return <></>;
        let courseUrl = '/';
        if (!obj.hasOwnProperty('fullurl')) courseUrl = '/';
        if (!obj.fullurl) courseUrl = '/';
        else courseUrl = obj.fullurl;
        return (
            <CourseActionButton 
            idCourse={obj.id} 
            courseUrl={courseUrl} 
            setOpenEnrollmentDialog={setOpenEnrollmentDialog}
            openEnrollmentDialog={openEnrollmentDialog}
            />
        )
    }

    const scrollToAnchor = () => {
        const anchor = document.getElementById('lesson-block');
        if (anchor) {
          anchor.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
      };

    const processButtonSignUpForCourse = () => {
        if (!obj) return <></>;

        return (
            <>
                {/* <MyButton className='btnSub btn' cb={() => { }}>{LL('BT_SignUpForCourse')}</MyButton> */}
                {processCourseActionButton()}
                <MyButton className='btnSel btn' cb={scrollToAnchor}>{LL('BT_Course_Content')}</MyButton>
            </>
        )
    }

    const processCourseLanguage = () => {
        if (!obj) return <></>;
        if (!obj.hasOwnProperty('language')) return <></>;
        if (!obj.language) return <></>;
        return (
            <Stack direction='row' spacing={1} className="categories" justifyContent='space-between'>
                <Typography>{LL('Language')}</Typography>
                <Typography className="nameFilter">
                    {ComponentCommonTools.processObjectFieldOfObject(obj, 'language', 'name')}
                </Typography>
            </Stack>
        )
    }

    const getCourseDuration = () => {
        if (!obj) return 0;
        if (!obj.hasOwnProperty('courseduration')) return 0;
        if (!obj.courseduration) return 0;
        return DateTools.displayTimestampInHHMM(obj.courseduration);
    }
    const getNumberOfLessons = () => {
        if (!obj) return 0;
        if (!obj.hasOwnProperty('numberoflessons')) return 0;
        if (!obj.numberoflessons) return 0;
        return obj.numberoflessons;
    }
    const processCourseDuration = () => {
        if (!obj) return <></>;

        return (
            <>
                <Stack direction='row' spacing={1} className="categories" justifyContent='space-between'>
                    <Typography>{LL('CourseDuration')}</Typography>
                    <Typography className="nameFilter">{getCourseDuration()}</Typography>
                </Stack>
                <Stack direction='row' spacing={1} className="categories" justifyContent='space-between'>
                    <Typography>{LL('NumberOfLessons')}</Typography>
                    <Typography className="nameFilter">{getNumberOfLessons()}</Typography>
                </Stack>
            </>
        )
    }
    const processOtherCharacteristics = () => {
        if (!obj) return <></>;

        return (
            <Box>
                {processCourseDuration()}
                {processCourseLanguage()}
                {processCourseSettings()}
            </Box>
        )
    }
    const processCourseFilterList = () => {
        if (!obj) return <></>;
        if (!obj.id) return <></>;

        return (
            <Box className="categories">
                <CourseFilterList idCourse={obj.id} />
            </Box>
        )

    }

    const processCourseSettings = () => {
        if (!obj) return <></>;
        if (!obj.id) return <></>;
        if (!obj.hasOwnProperty('idcoursesettings')) return <></>;
        return (
            <>
                <CourseSettingsInfo idCourse={obj.id} />
            </>
        );
    }

    const processLinkCourseCategoryList = () => {
        if (!obj) return <></>;
        if (!obj.id) return <></>;

        return (
            <Stack direction='row' spacing={1} className="categories" justifyContent='space-between'>
                <Typography>{LL('CourseCategories')}</Typography>
                <LinkCourseCategoryList idCourse={obj.id} />
            </Stack>
        )
    }
    const processCoursePrice = () => {
        if (!obj) return <></>;
        if (!obj.hasOwnProperty('courseprice')) return <></>;
        if (!obj.courseprice) return <></>;
        if (!obj.courseprice.hasOwnProperty('totalprice')) return <></>;
        if (obj.courseprice.totalprice === undefined || obj.courseprice.totalprice === null ) return <></>;
        else if(obj.courseprice.totalprice === 0) return processItemWithoutPrice();

        return (
            <Box>
                <Stack direction='row' spacing={1} alignItems='center' justifyContent='space-between'>
                    <Typography variant='h2'>
                        {ComponentCommonTools.processObjectFieldOfObject(obj, 'courseprice', 'totalprice')}
                        {' '}
                        {ComponentCommonTools.processObjectFieldOfObject(obj, 'courseprice', 'currency_identifier')}
                    </Typography>
                    <Typography className="pricePer">
                        {ComponentCommonTools.processObjectFieldOfObject(obj, 'courseprice', 'priceperlesson')}
                        <span>{'/'}</span>
                        {LL('PricePerLesson')}
                    </Typography>
                </Stack>
                <Box my={2}>
                    {processButtonSignUpForCourse()}
                </Box>
            </Box>
        )
    }

    const processItemWithoutPrice = () => {
        return (
            <Box >
                <Typography variant='h2'>{LL('Free')}</Typography>
                <Box my={2}>
                    {processButtonSignUpForCourse()}
                </Box>
            </Box>
        )
    }

    const prepareGallery = () => {
        if (!obj) return;
        if (!obj.hasOwnProperty('gallery')) return;
        if (!obj.gallery) return;
        if (obj.gallery.length === 0) return;
        const galleryObj = CommonTools.getGalleryObjectLogo(obj.gallery);
        if (!galleryObj) return;
        CommonTools.setFileUrlsGallery(galleryObj, setImgUrl, setFullUrl, 310, 180);
    }

    useEffect(() => {
        prepareGallery();
    }, [obj])

    const processVideo = () => {
        if(!obj) return <></>;
        return <CourseVideoDialog obj={obj} />
    }



    const processImage = () => {
        if (!obj) return <></>;
        if (!imgUrl) return <></>;

        return (
            <CardMedia
                sx={{ height: 180 ,}}
                image={imgUrl}
                title='' >
                {processCourseType()}
                
                {processVideo()}
                
            </CardMedia>
        )
    }

    const processCourseType = () => {
        if (!obj) return <></>;
        if (!obj.hasOwnProperty('coursesettings')) return <></>;
        if (!obj.coursesettings) return <></>;
        return <Box p={1}><TypeCourseCardChip obj={obj.coursesettings} width={14} height={14} /></Box>
    }
    const processCourseCharacteristics = () => {
        if (!obj) return <></>;

        return (
            <Card className="characteristic">
                {processImage()}
                <CardContent className="courseDetailPage">
                    {processCoursePrice()}
                    <Typography className="alert" my={2}>{LL('Courseminitext')}</Typography>
                    <Box mb={2}><Divider><Typography variant="h5" >{LL('CourseCharacteristics')}</Typography></Divider></Box>
                    {processLinkCourseCategoryList()}
                    {processCourseFilterList()}
                    {processOtherCharacteristics()}
                </CardContent>
            </Card>
        )
    }

    return loading ? <Skeleton variant="rectangular" /> : processCourseCharacteristics();
};

export { CourseCharacteristics };
