import React, { useEffect, useState, useRef } from "react";
import PageComponentProps from "interfaces/pagecomponentprops.interface";
import { useResource } from "hook/useResource";
import { logger } from "hoc/logger";
import Box from '@mui/material/Box'
import { MyNavBt } from "components/general/MyNavBt";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import MyTextField from 'components/form/mytextfield';
import { IconButton, Skeleton, Stack } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import Snackbar from '@mui/material/Snackbar';
import { useMessage } from "hook/useMessage";
import { CommonTools } from "tools/commontools";
import { MessageTypes } from "tools/messagetypes";
import { ShareElements } from "components/elements/Share/ShareElements";

import RequiredValidator from "validators/required.validator";
import ValidateTools from "tools/validate.tools";
import { LocalityAutocompleteV2 } from "components/elements/Autocomplete/LocalityAutocompleteV2";
import MyDatePicker from "components/elements/MyDatePicker/MyDatePicker";

import { useAuth } from "hook/useAuth";
import ResultObjectDTO from "dto/app/resultobject.dto";
import { MyButton } from "components/general/MyButton";
import { Types } from "tools/types";

import { CircularLoading } from "components/general/CircularLoading";
import MyCKEditor from "components/elements/ckeditor/MyCKEditor";
import { LessonDto, PostLessonDto } from "dto/course/lesson.dto";
import LessonService from "services/course/lesson.service";
import MyCheckboxField from "components/form/mycheckboxfield";
import { LessonTypeSelect } from "components/elements/Select/LessonTypeSelect";
import { PostAttachmentDto } from "dto/attachment/attachment.dto";
import AttachmentService from "services/attachment/attachment.service";
import MyFileField from "components/form/myfilefield";
import { LanguageSelect } from "components/elements/Select/LanguageSelect";
import { Status } from "tools/status";
import { StatusSelect } from "components/elements/Select/StatusSelect";


const attachmentService = new AttachmentService();


type AddAttachmentDialogProps = {
    open: boolean;
    setOpen: any;
    idparent: string;
    setObjectWasChanged: any;
    parent: string;
}
const AddAttachmentDialog: React.FC<AddAttachmentDialogProps> = ({
    open,
    setOpen,
    idparent,
    setObjectWasChanged,
    parent,
    ...props
}) => {
    const { LL } = useResource();

    const [loading, setLoading] = useState<boolean>(false);
    const [obj, setObj] = useState<PostAttachmentDto>(new PostAttachmentDto());
    const [isFormDisabled, setIsFormDisabled] = useState(true);
    const [files, setFiles] = useState(null);

    const [validators, setValidators] = useState(false);
    const [vresults, setVResults] = useState<any>(false);
    const validateAll = () => {
        if (!obj) return;
        if (!validators) return;

        obj.name = obj.name !== undefined ? obj.name : "";
        obj.ordercriteria = obj.ordercriteria !== undefined ? obj.ordercriteria : 0;
        obj.idlanguage = obj.idlanguage !== undefined ? obj.idlanguage : "";
        obj.isdefault = obj.isdefault !== undefined ? obj.isdefault : false;
        obj.status = obj.status !== undefined ? obj.status : Status.ACTIVE;
        setObj(obj);


    };

    const validateField = (field: any, value: any, v?: any) => {
        v = (v) ? v : JSON.parse(JSON.stringify(vresults));
        if (v === false) v = {};
        v[field] = ValidateTools.validate(field, value, validators, vresults);
        setVResults(v);
        return v;
    };

    const processValidators = () => {
        let v: any = {

            name: [],
        };

        v.name.push(new RequiredValidator('', ''));

        setValidators(v);
    };

    const checkLoading = () => {
        if (!loading) return;
        if (!obj) return;
        if (!validators) return;
        validateAll();
        setLoading(false);
    };

    useEffect(() => {
        processValidators();
    }, []);
    useEffect(() => {
        checkLoading();
    }, [obj, validators]);

    useEffect(() => {
        checkIsFormDisabled();
    }, [files]);



    const setObjField = (field: any, value: any) => {
        let t = JSON.parse(JSON.stringify(obj));
        t[field] = value;
        setObj(t);

        validateField(field, value);
    };

    const checkIsFormDisabled = () => {
        let rez = false;
        if (files == null) {
            rez = true;
        }
        
        setIsFormDisabled(rez);
    };

    const handleSubmit = (event: any) => {
        event.preventDefault();
        if (isFormDisabled) return false;

        if (!obj) return;

        if (!idparent) return;
        obj.idparent = idparent
        obj.parent = parent 
        if (obj) obj.files = files;
        setLoading(true);
        attachmentService.add(handleResult, {}, obj);
    };

    const handleResult = (result: ResultObjectDTO, cbparams: any) => {
        if (!result) return;
        if (!result.err) {
            setObjectWasChanged(CommonTools.generateRandomString(5));
            setOpen(false);
            setLoading(false);
            setFiles(null);
            setObj(new PostAttachmentDto());
        }
    }

    const handleClose = () => {
        setOpen(false);
    };

    const setObjFile = (field: any, event: any) => {
        setFiles(event.target.files[0]);
    };

    const processForm = () => {
        if (!obj) return <></>;
        if (loading) return <CircularLoading />;

        return (
            <form onSubmit={handleSubmit}>
                <Box>
                    <MyTextField
                        tabIndex={0}
                        fullWidth
                        id="name"
                        name="name"
                        variant="standard"
                        className='form-input'
                        color="primary"
                        _label={LL("Name")}
                        size="small"
                        value={obj.name}
                        setObj={setObjField}
                        _vresults={undefined}
                    />
                </Box>
                <Box sx={{ mt: 3 }}>
                    <MyTextField
                        tabIndex={1}
                        fullWidth
                        id="ordercriteria"
                        name="ordercriteria"
                        variant="standard"
                        className='form-input'
                        color="primary"
                        _label={LL("ordercriteria")}
                        size="small"
                        value={obj.ordercriteria}
                        setObj={setObjField}
                        _vresults={
                            undefined
                        }
                    />
                </Box>
                <Box sx={{ mt: 3 }}>
                    <StatusSelect
                        tabIndex={2}
                        id="status"
                        name="status"
                        variant="standard"
                        className='form-select'
                        color="primary"
                        value={obj.status}
                        field="status"
                        size="small"
                        setObj={setObjField}
                    />
                </Box>
                <Box sx={{ mt: 3 }}>
                    <LanguageSelect
                        tabIndex={3}
                        id="idlanguage"
                        name="idlanguage"
                        variant="standard"
                        className='form-select'
                        color="primary"
                        value={obj.idlanguage}
                        field="idlanguage"
                        size="small"
                        setObj={setObjField}
                        obj={obj}
                        
                    />
                </Box>

                <Box sx={{ mt: 3 }}>
                    <MyCheckboxField
                        tabIndex={4}
                        id="isdefault"
                        name="isdefault"
                        _label={LL("isdefault")}
                        setObj={(value: any) =>
                            CommonTools.handleCheckValue(obj, setObj, 'isdefault', value)}
                        checked={obj.isdefault}
                        color="primary"
                        size="small"
                        _vresults={undefined}
                    />
                </Box>

                <Box sx={{ mt: 3 }}>
                    <MyFileField
                        tabIndex={1}
                        fullWidth
                        id="files"
                        name="files"
                        variant="standard"
                        className='form-input'
                        color="primary"
                        _label={LL("files")}
                        size="small"
                        setObj={setObjFile}
                        _vresults={undefined}
                    />

                </Box>
                <Box sx={{ mt: 3 }} textAlign='center'>
                <MyButton
                    tabIndex={1}
                    disabled={isFormDisabled}
                    color="primary"
                    className='btn'
                    variant="contained"
                    size="large"
                    type="submit"
                
                    children={LL("Bt_Submit")}
                /></Box>
            </form>
        )
    }
    return (<>

        <Dialog
            open={open}
            onClose={handleClose}
            fullWidth

        >
            <DialogTitle>{LL("Attachments")}</DialogTitle>
            <IconButton sx={{
                position: 'absolute',
                right: 8,
                top: 8,
            }} onClick={handleClose}>
                <CloseIcon />
            </IconButton>
            <DialogContent dividers>
                {processForm()}
            </DialogContent>

        </Dialog>
    </>
    )
};

export { AddAttachmentDialog };