import Idto from "interfaces/idto.interface";

export class PostRecommendationPriceDto implements Idto {
  status?: number;
  ordercriteria?: number;
  idlanguage?: string;
  idrecommendationprice?: string;

  name?: string;
  idpricepolicy?: string;
  idcurrency?: string;
  price?: number;
}

export class RecommendationPriceDto implements Idto {
  id?: string;
  status?: number;
  ordercriteria?: number;
  idlanguage?: string;
  idrecommendationprice?: string;
  language?: any;
  status_name?: string;
  name?: string;
  idpricepolicy?: string;
  idcurrency?: string;
  price?: number;
  pricepolicy?: any;
  currency?: any;
  isdefault?: boolean;

  static selectDefaultValue = (arr: Array<RecommendationPriceDto>): string => {
    if(!arr) return "";
    if(!arr.length) return "";
    const newArr = arr.filter(
      (item: RecommendationPriceDto) => item.isdefault === true
    );
    if (newArr.length > 0) return newArr[0].id || "";
    else return "";
  };
}
