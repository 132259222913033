import React, { useCallback, useEffect, useRef, useState } from "react";
import { useResource } from "hook/useResource";
import { logger } from "hoc/logger";
import CourseCategoryService from "services/course/coursecategory.service";
import PageComponentProps from "interfaces/pagecomponentprops.interface";
import { CourseCategoryDto } from "dto/course/coursecategory.dto";
import { RouteTools } from "tools/routetools";
import RequestListDTO from "dto/app/requestlist.dto";
import { Status } from "tools/status";
import RequestFilterDTO from "dto/app/requestfilter.dto";
import ResultListDTO from "dto/app/resultlist.dto";
import { Types } from "tools/types";
import { InfoCourseCategoryItem } from "./InfoCourseCategoryItem";
import { Loading } from "components/general/Loading";
import Skeleton from '@mui/material/Skeleton';
import { Box } from "@mui/material";
import ResultObjectDTO from "dto/app/resultobject.dto";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { ComponentCommonTools } from "tools/componentcommontools";
import Stack from '@mui/material/Stack';
import AppBar from '@mui/material/AppBar';
import { MyNavBt } from "components/general/MyNavBt";
import { Typography } from "@mui/material";

const courseCategoryService = new CourseCategoryService();

type CourseCategoryTopBarProps = {
    currentRoute: any;
    idCategory: string;
    setCategoryObject:any;
}

const CourseCategoryTopBar: React.FC<CourseCategoryTopBarProps> = ({
    currentRoute,
    idCategory,
    setCategoryObject,
    ...props
}) => {

    const [isLoading, setIsLoading] = useState(true);
    const [objects, setObjects] = useState<Array<CourseCategoryDto>>([]);
    const [obj, setObj] = useState<CourseCategoryDto | undefined>();


    const loadObject = (obj: ResultObjectDTO) => {
        if (!obj) return;
        if (!obj.err) {
            const object = obj.obj ? obj.obj : undefined;
            setObj(object);
            setCategoryObject(object);
        }
    };

    const getObject = () => {
        setIsLoading(true);
        if (idCategory !== '') {
            courseCategoryService.get(idCategory, loadObject, {});
        }
    };

    const getList = () => {
        const reqList = new RequestListDTO();
        reqList.onpage = 100;
        reqList.page = 1;
        reqList.filters = [];

        let f: RequestFilterDTO;

        f = new RequestFilterDTO();
        f.field = "status";
        f.values = [];
        f.values.push(Status.ACTIVE.toString());
        reqList.filters.push(f);

        f = new RequestFilterDTO();
        f.field = "idparent";
        f.values = [];
        f.values.push(idCategory);
        reqList.filters.push(f);


        setIsLoading(true);

        courseCategoryService.getList(loadObjects, {}, reqList);

        // setIsLoading(false);
    };

    const loadObjects = (result: ResultListDTO) => {
        if (!result) return;
        if (!result.err) {
            const objects = result.objects ? result.objects : [];
            setObjects(objects);
        }
    }

    const checkLoading = () => {
        let load = false;
        if (!obj) load = true;
        if (!objects) load = true;
        if (objects.length === 0) load = true;

        setIsLoading(load);
    }
    useEffect(() => {
        if (idCategory !== '') {
            getObject();
            getList();
        }
    }, [idCategory]);

    useEffect(() => {
        checkLoading();
    }, [obj, objects]);


    const [value, setValue] = useState();

    const handleChange = (event: React.SyntheticEvent, newValue: any) => {
        if (newValue) {
            setValue(newValue);
            if (newValue.fullurl) {
                RouteTools.setHistory(newValue.fullurl, {});
            }
        }
    };

    const processCourseCategoryItem = (obj: CourseCategoryDto, index: number) => {
        if (!obj) return <></>;
        if (!obj.id) return <></>;
        if (!obj.name) return <></>;
        if (!obj.fullurl) return <></>;

        return (
            <Tab value={obj} label={obj.name} />
        );
    };

    

    const processCourseCategoryList = () => {

        if (!obj) return <></>;
        if (!objects.length) return <></>;

        
        return (

            <Tabs
                variant="scrollable"
                scrollButtons
                allowScrollButtonsMobile
                sx={{ width:'100%'}}
    
                value={value}
                onChange={handleChange}
                
            >
                {objects.map((item: CourseCategoryDto, i: number) => {
                    return processCourseCategoryItem(item, i);
                })}
            </Tabs>
        );

    };



    return isLoading && !obj ? <Skeleton /> : (
        <Box className="categoryTopBar">
            <Box className="container">
                <Stack direction='row' spacing={0} sx={{alignContent:'center', alignItems:'center'}}>
                    <Typography className="name" variant="h6" pl={2} pr={3} py={2}>{ComponentCommonTools.processObjectField(obj, 'name')}</Typography>
                    {processCourseCategoryList()}
                </Stack>
            </Box>
        </Box>);


};

export { CourseCategoryTopBar };
