import React, { useCallback, useEffect, useRef, useState } from "react";
import { useResource } from "hook/useResource";
import { logger } from "hoc/logger";

import WishlistService from "services/user/wishlist.service";

import PageComponentProps from "interfaces/pagecomponentprops.interface";
import { WishlistDto } from "dto/user/wishlist.dto";
import { RouteTools } from "tools/routetools";
import RequestListDTO from "dto/app/requestlist.dto";
import { Status } from "tools/status";
import RequestFilterDTO from "dto/app/requestfilter.dto";
import ResultListDTO from "dto/app/resultlist.dto";
import { Types } from "tools/types";
import { ComponentCommonTools } from 'tools/componentcommontools'
import { Loading } from "components/general/Loading";
import Skeleton from '@mui/material/Skeleton';
import { Box, Grid, Stack, Typography } from "@mui/material";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { MyNavBt } from "components/general/MyNavBt";
import { useAuth } from "hook/useAuth";
import { Carousel } from "components/elements/Carousel/Carousel";
import { AddBox } from "@mui/icons-material";
import { CommonTools } from "tools/commontools";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { CourseCard } from "components/course/course/CourseCard";
import { MyButton } from "components/general/MyButton";
import { LoadMoreButton } from "components/elements/MyButton/LoadMoreButton";
import { NoResult } from "components/general/NoResult";
const wishlistService = new WishlistService();

const UserWishlist: React.FC<PageComponentProps> = ({ currentRoute, ...props }) => {

    const { LL, CC } = useResource();
    const [isLoading, setIsLoading] = useState(true);
    const [objects, setObjects] = useState<Array<WishlistDto>>([]);
    const { user } = useAuth();
    const [total, setTotal] = useState<number>(-1);
    const [totalPage, setTotalPage] = useState<number>(-1);
    const [onPage, setOnPage] = useState<number>(parseInt(CC('DefaultOnPageWishlist', 4)));

    const [refresh, setRefresh] = useState<string>('');
    const getList = async () => {


        if (!user) return;
        const reqList = new RequestListDTO();
        reqList.onpage = onPage;
        reqList.page = 1;
        reqList.filters = [];

        let f: RequestFilterDTO;

        f = new RequestFilterDTO();
        f.field = "iduser";
        f.values = [user.id];
        reqList.filters.push(f);


        setIsLoading(true);

        wishlistService.getList(loadObjects, {}, reqList);


    };

    useEffect(() => {
        getList();
    }, [refresh, onPage]);

    const loadObjects = (result: ResultListDTO) => {
        if (!result) return;
        if (!result.err) {
            const objects = result.objects ? result.objects : [];
            const total = result.total !== undefined ? result.total : -1;
            const totalPage = result.totalpages !== undefined ? result.totalpages : -1;
            setObjects(objects);
            setTotal(total);
            setTotalPage(totalPage);

        }
    }

    const checkLoading = () => {
        let load = false;

        if (total === -1) return true;
        if (totalPage === -1) return true;
        if (!objects) load = true;

        setIsLoading(load);
    }

    useEffect(() => {
        checkLoading();
    }, [objects]);

    const processWishlistItem = (obj: WishlistDto, index: number) => {
        if (!obj) return <></>;
        if (!obj.hasOwnProperty('course')) return <></>;
        if (!obj.course) return <></>;
        return <Grid item sm={12} lg={3} md={4} key={index}>
            <CourseCard
                obj={obj.course}
                loading={isLoading}
                withWishlist={true}
                onChangeSubscribe={setRefresh} />
        </Grid>
    };

    const processLoadMoreObjects = () => {
        if (!objects || !objects.length) return <></>;
        return <LoadMoreButton
            objects={objects}
            total={total} onPage={onPage}
            setOnPage={setOnPage}
            step={parseInt(CC('DefaultOnPageWishlist', 4))}
            className="loadMorebtn"
        />
    }



    const handleDeleteWishlist = () => {
        if (!objects) return;
        if (!objects.length) return;
        if (!user) return;
        if (!user.id) return;

        wishlistService.deleteAllByIdUser(user.id, getList, {})
    }

    const processDeleteWishlist = () => {
        if (!objects) return <></>;
        if (!objects.length) return <></>;
        if (isLoading) return <></>;

        return (
            <MyButton cb={handleDeleteWishlist} className='btn btnDelete'><DeleteOutlineIcon />{LL('Delete_All_From_Wishlist')}</MyButton>
        )
    }


    const processWishlistList = () => {
        if (!objects.length) return <NoResult identifier="wishlist" />;


        return (
            <Grid
                container
                spacing={2}
                mb={4}
            >
                {objects.map((obj, index) => processWishlistItem(obj, index))}
            </Grid>
        );
    };

    return isLoading ? <Skeleton /> : (
        <Box className='container pageWish'>

            <Stack direction="row"
                justifyContent="space-between"
                alignItems="center">
                <Typography variant="h2" my={5}>{LL("Wishlist")}</Typography>
                {processDeleteWishlist()}
            </Stack>
            {processWishlistList()}
            {processLoadMoreObjects()}
        </Box>
    );
};

export { UserWishlist };