import React, { useEffect, useState } from "react";
import PageComponentProps from "interfaces/pagecomponentprops.interface";
import { useResource } from "hook/useResource";
import { logger } from "hoc/logger";
import MenuService from "services/menu/menu.service";
import { InfoCourseCategoryLevel } from "./InfoCourseCategoryLevel";
import { Box } from "@mui/material";



const InfoCourseCategoryBlock: React.FC<PageComponentProps> = ({
  currentRoute,
  ...props
}) => {
  
  return <Box className="menuGeneral"><InfoCourseCategoryLevel currentRoute={currentRoute} level={1} idparent={""} /></Box>; 
};

export { InfoCourseCategoryBlock };
