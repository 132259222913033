import RequestListDTO from "dto/app/requestlist.dto";
import RequestSortCriteriaDTO from "dto/app/requestsortcriteria.dto";
import ResultObjectDTO from "dto/app/resultobject.dto";
// import {TicketDto} from "dto/support/ticket.dto";
import ResultListDTO from "dto/app/resultlist.dto";
import { logger } from "hoc/logger";
import IService from "interfaces/iservice.interface";
import TicketRepository from "repositories/support/ticket.repository";
import { Status } from "tools/status";
import GeneralService from "services/general.service";

export default class TicketService extends GeneralService implements IService {
    ticketRepository: TicketRepository = new TicketRepository();
    constructor() {
        super();
        this.handleGetList = this.handleGetList.bind(this);
        this.handleGet = this.handleGet.bind(this);
    }
    handleUpdate(result?: any, cbparameters?: any, data?: any): any {
        let rez = new ResultObjectDTO();
        rez = result;

        if (result.err) return;

        if (cbparameters && cbparameters._cb) {
            cbparameters._cb(rez, cbparameters, data);
        }
    }

    async update(id: string, cb?: any, cbparameters?: any, data?: any): Promise<any> {

        cbparameters = cbparameters ? cbparameters : {};
        cbparameters._cb = cb;
        this.ticketRepository.update(id, this.handleUpdate, data, cbparameters);
    }

    handleDelete(result?: any, cbparameters?: any): any {
        let rez = new ResultObjectDTO();
        rez = result;
        if (result.err) return;

        if (cbparameters && cbparameters._cb) {
            cbparameters._cb(rez, cbparameters);
        }
    }

    async delete(id: string, cb?: any, cbparameters?: any): Promise<any> {
        cbparameters = cbparameters ? cbparameters : {};
        cbparameters._cb = cb;

        this.ticketRepository.delete(id, this.handleDelete, cbparameters);
    }



    handleGet(result?: any, cbparameters?: any, data?: any): any {
        let rez = new ResultObjectDTO();
        rez = result;
        if (result.err) return;

        this.populateObject(rez.obj, result.obj, [
            { key: 'status', type: 'status' },
            { key: 'date', type: 'timestamp' },
            { key: 'type', type: 'types' },
        ]);
        if (cbparameters && cbparameters._cb) {
            cbparameters._cb(rez, cbparameters, data);
        }

    return rez;
    }

    async get(id: string, cb?: any, cbparameters?: any): Promise<any> {
        cbparameters = cbparameters ? cbparameters : {};
        cbparameters._cb = cb;
        const t = await this.ticketRepository.get(id, this.handleGet, cbparameters);
        if (cb == undefined)
        {
            return this.handleGet(t);
        }
    }

    handleGetList(result?: any, cbparameters?: any, data?: any): any {
        let rez = new ResultListDTO();

        rez = result;
        if (result.err) return;

        this.parseResultPopulate(result, rez, [
            { key: 'status', type: 'status' },
            { key: 'date', type: 'timestamp' },
            { key: 'type', type: 'types' },

        ]);

        if (cbparameters && cbparameters._cb) {
            cbparameters._cb(rez, cbparameters, data);
        }

        return rez;
    }

    async getList(
        cb?: any,
        cbparameters?: any,
        data?: RequestListDTO
    ): Promise<any> {
        cbparameters = cbparameters ? cbparameters : {};
        cbparameters._cb = cb;
        const t = await this.ticketRepository.getList(this.handleGetList, cbparameters, data);
        if (cb == undefined)
        {
            return this.handleGetList(t);
        }
    }

    handleAdd(result?: any, cbparameters?: any, data?: any): any {
        let rez = new ResultObjectDTO();
        rez = result;
        if (result.err) return;

        if (cbparameters && cbparameters._cb) {
            cbparameters._cb(rez, cbparameters, data);
        }
    }



    async add(
        cb?: any,
        cbparameters?: any,
        data?: any
    ): Promise<any> {
        cbparameters = cbparameters ? cbparameters : {};
        cbparameters._cb = cb;

        this.ticketRepository.add(this.handleGetList, cbparameters, data);
    }


    getDefaultRequestList(onPage?: number): RequestListDTO {
        onPage = onPage ?? Number(process.env.REACT_APP_ONPAGE);
        if (onPage === 0) onPage = Number(process.env.REACT_APP_ONPAGE);
        var defaultRequestList = new RequestListDTO();
        defaultRequestList.page = Number(process.env.REACT_APP_PAGE);
        defaultRequestList.onpage = onPage;
        var defaultRequestSortCriteria = new RequestSortCriteriaDTO()
        defaultRequestSortCriteria.asc = true;
        defaultRequestSortCriteria.field = 'name';
        defaultRequestList.sortcriteria = []
        defaultRequestList.sortcriteria?.push(defaultRequestSortCriteria);
        return defaultRequestList;
    }
}
