import React, { useState, useEffect } from 'react';


import FAQService from "services/faq/faq.service";
import { logger } from 'hoc/logger';
import RequestListDTO from 'dto/app/requestlist.dto';
import ResultListDTO from 'dto/app/resultlist.dto';
import { FAQDto } from 'dto/faq/faq.dto';
import { useCookies } from 'react-cookie';
import { Loading } from 'components/general/Loading';
import { MyNavBt } from "components/general/MyNavBt";
import { useResource } from "hook/useResource";
import { CommonTools } from 'tools/commontools';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';



import { RouteTools } from 'tools/routetools';
import { Status } from 'tools/status';
import RequestFilterDTO from 'dto/app/requestfilter.dto';
import { MyAccordion } from 'components/elements/MyAccordion/MyAccordion';


const faqService = new FAQService();


interface FAQProps {
  
  currentRoute: any;
  
}

const FAQPage: React.FC<FAQProps> = ({
  currentRoute,
  ...props
}) => {


  const { LL } = useResource();
  const [cookies, setCookies] = useCookies();


  const [data, setData] = useState<ResultListDTO>(new ResultListDTO());
  const [rows, setRows] = useState<Array<FAQDto>>(new Array<FAQDto>());

  const [total, setTotal] = useState(0);
  const [totalPage, setTotalPage] = useState(0);

  const [responseParams, setResponseParams] = useState<RequestListDTO>(
    new RequestListDTO()
  );

  const [reqList, setReqList] = useState<RequestListDTO>(new RequestListDTO());
  
  const [loading, setLoading] = useState<boolean>(true);

  const loadObjects = (data: ResultListDTO) => {
    CommonTools.processListLoadObjects(
      data,
      setData,
      setRows,
      setTotal,
      setTotalPage,
      setResponseParams
    );
  };
  const getList = () => {
    setLoading(true);
    
    faqService.getList(loadObjects, {}, reqList);
  };

  const getReqList = () => {
    let reqList = faqService.getDefaultRequestListSelect(100);

    setReqList(reqList);
  };

  useEffect(() => {
    getReqList();
  }, [currentRoute]);

  useEffect(() => {
    if (CommonTools.atLeastOneFieldDefined(reqList)) {
      getList();
    }
  }, [reqList]);

  useEffect(() => {
    checkIsLoading();
  }, [data, rows, reqList, total, totalPage]);

  const checkIsLoading = () => {
    const load: boolean = CommonTools.checkIsLoading(
      reqList,
      responseParams,
      data,
      total,
      totalPage,
      rows
    );
    setLoading(load);
  };
  


  const processFAQItem = (obj: FAQDto, index: number) => {
    if(!obj) return <></>
    if(!obj.question || !obj.answer) return <></>

    return <MyAccordion question={obj.question} answer={obj.answer} key={index} />;
  };

  const processFAQList = () => {
    if (!rows.length) return <></>;
    logger("processFAQList", rows);
    return (
      <Box>
        {rows.map((item: FAQDto, i: number) => {
          return processFAQItem(item, i);
        })}
      </Box>
    );
  };


  return loading ? (<Loading />) : (processFAQList());
};

export default FAQPage;
