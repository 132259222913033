import { CircularLoading } from "components/general/CircularLoading";
import RequestFilterDTO from "dto/app/requestfilter.dto";
import RequestListDTO from "dto/app/requestlist.dto";
import ResultListDTO from "dto/app/resultlist.dto";
import { AnswerDto } from "dto/course/answer.dto";
import { QuestionDto } from "dto/course/question.dto";
import { useResource } from "hook/useResource";
import React, { useState, useEffect } from "react";
import AnswerService from "services/course/answer.service";
import { Box, Typography } from "@mui/material";
import { ComponentCommonTools } from "tools/componentcommontools";
import { AnswerSingleQuestionOption } from "./AnswerSingleQuestionOption";
import { Types } from "tools/types";
import { AnswerMultipleQuestionOption } from "./AnswerMultipleQuestionOption";
import { PostTestResultAnswerDto } from "dto/course/testresultanswer.dto";
import { PostTestResultQuestionDto } from "dto/course/testresultquestion.dto";
import { TestResultDto } from "dto/course/testresult.dto";
import { logger } from "hoc/logger";

type TestQuestionItemProps = {
    obj: QuestionDto;
    indexQuestion: number;
    setQuestionResult: any
    questionResult?: PostTestResultQuestionDto;
    setAnswersResult: any;
    answersResult: Array<PostTestResultAnswerDto>;
    testResult?: TestResultDto;
    
}

const answerService = new AnswerService();
const TestQuestionItem: React.FC<TestQuestionItemProps> = ({
    obj,
    indexQuestion,
    setQuestionResult,
    questionResult,
    setAnswersResult,
    answersResult,
    testResult,
    
    ...props }) => {

    const [objects, setObjects] = useState<Array<AnswerDto>>([]);
    const { LL, CC } = useResource();
    const [isLoading, setIsLoading] = useState(true);

    const [answerSelected, setAnswerSelected] = useState<Array<string>>([]);

    const processQuestionResult = () => {
        if (!obj || !testResult) return;
        if (!obj.id || !testResult.id) return;

        const questionResult = new PostTestResultQuestionDto();
        questionResult.idquestion = obj.id;
        questionResult.idtestresult = testResult.id;
        questionResult.maxquestionscore = obj.totalscore;
        setQuestionResult(questionResult);
    }

    const processAnswerResult = () => {
        if (!testResult || !objects) return;
        if (!testResult.id) return;
        if (!answerSelected.length || !objects.length) {
            setAnswersResult([]);
            return;
        }

        const answersResult = answerSelected.map((answerId) => {
            const answerResult = new PostTestResultAnswerDto();
            answerResult.idanswer = answerId;
            answerResult.idtestresult = testResult.id;
            answerResult.iscorrect = checkAnswerIsTrue(answerId);
            answerResult.score = processAnswerScore(answerId);
            return answerResult;
        });

        logger('answersResult', answersResult,questionResult)
        setAnswersResult(answersResult);
    }

    useEffect(() => {
        processAnswerResult();
    }, [answerSelected])

    const checkAnswerIsTrue = (id: string) => {
        const answer = objects.find((answer) => answer.id === id);
        if (!answer) return false;
        if (!answer.hasOwnProperty('type')) return false;
        if (!answer.type) return false;
        if (answer.type === Types.FALSE_ANSWER) return false;
        else return true;
    }

    const processAnswerScore = (id: string) => {
        const answer = objects.find((answer) => answer.id === id);
        if (!answer) return 0;
        if (!answer.hasOwnProperty('score')) return 0;
        if (!answer.score) return 0;
        return answer.score;
    }


    const getList = () => {
        if (!obj) return;
        if (!obj.id) return;
        setIsLoading(true);
        const reqList = new RequestListDTO();
        reqList.onpage = parseInt(CC('DefaultOnPageTestAnswerList', 2000));
        reqList.page = 1;
        reqList.filters = [];
        reqList.sortcriteria = [];

        let f: RequestFilterDTO;

        f = new RequestFilterDTO();
        f.field = "idquestion";
        f.values = [obj.id];
        reqList.filters.push(f);

        
        answerService.getList(loadObjects, {}, reqList);
    };

    const loadObjects = (result: ResultListDTO) => {
        if (!result) return;
        if (!result.err) {
            const objects = result.objects ? result.objects : [];
            setObjects(objects);
        }
    }

    const checkLoading = () => {
        let load = false;
        if (!objects) load = true;

        setIsLoading(load);
    }

    useEffect(() => {
        getList();
        processQuestionResult();
    }, [obj]);

    useEffect(() => {
        checkLoading();
    }, [objects]);

    const processComponent = () => {
        if (!obj) return <></>;
        if (!obj.id) return <></>;
        if (isLoading) return <CircularLoading />;
        return (
            <Box>

                    <Typography variant="h4" textAlign="center" mb={3}>
                        {ComponentCommonTools.processObjectField(obj, 'name')}
                    </Typography>


                    {processAnswerList()}

            </Box>
        )
    }



    const processAnswerList = () => {
        if (!obj) return <></>;
        if (!obj.hasOwnProperty('type')) return <></>;
        if (!obj.type) return <></>;
        if (!objects.length) return <></>;

        if (obj.type === Types.TYPE_QUESTION_SINGLE_CHOICE) {
            return (
                <Box>
                    <AnswerSingleQuestionOption
                        obj={obj}
                        objects={objects}
                        answerSelected={answerSelected}
                        setAnswerSelected={setAnswerSelected}
                    />
                </Box>
            )
        }
        if (obj.type === Types.TYPE_QUESTION_MULTIPLE_CHOICE) {
            return (
                <Box>
                    <AnswerMultipleQuestionOption
                        obj={obj}
                        objects={objects}
                        answerSelected={answerSelected}
                        setAnswerSelected={setAnswerSelected}
                    />
                </Box>
            )
        }
    }

    return processComponent();
};

export { TestQuestionItem };
