import Idto from 'interfaces/idto.interface';

export class CourseFilterDto implements Idto {
    
    id?: string;
    value?: number | string;
    idfilter?: string;
    idfilterdictionary?: string;
    filter?: any;
    filterdictionary?:any;
    idcourse?: string;
  }

  export class PostCourseFilterDto implements Idto {
    
    value?: number;
    idfilter?: string;
    idfilterdictionary?: string; 
    idcourse?: string;
    filter?: any;
    filterdictionary?:any;
  }