import React, { useCallback, useEffect, useRef, useState } from "react";
import { useResource } from "hook/useResource";
import { logger } from "hoc/logger";

import TestimonialService from "services/testimonial/testimonial.service";

import PageComponentProps from "interfaces/pagecomponentprops.interface";
import { TestimonialDto } from "dto/testimonial/testimonial.dto";
import { RouteTools } from "tools/routetools";
import RequestListDTO from "dto/app/requestlist.dto";
import { Status } from "tools/status";
import RequestFilterDTO from "dto/app/requestfilter.dto";
import ResultListDTO from "dto/app/resultlist.dto";
import { Types } from "tools/types";
import { ComponentCommonTools } from 'tools/componentcommontools'
import { Loading } from "components/general/Loading";
import Skeleton from '@mui/material/Skeleton';
import { Box, Grid, Stack, Typography } from "@mui/material";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { MyNavBt } from "components/general/MyNavBt";
import { TestimonialCard } from "components/testimonial/TestimonialCard";
import { Carousel } from "components/elements/Carousel/Carousel";
import { AddBox } from "@mui/icons-material";
import { CommonTools } from "tools/commontools";

import {BecomeTeacherTestimonialItem} from "components/testimonial/BecomeTeacherTestimonialItem";

const testimonialService = new TestimonialService();

const BecomeTeacherTestimonialList: React.FC<PageComponentProps> = ({ currentRoute, ...props }) => {
  const { saveCache, getCache } = useResource();
  const { LL,CC } = useResource();
  const [isLoading, setIsLoading] = useState(true);
  const [objects, setObjects] = useState<Array<TestimonialDto>>([]);

  
  const getList = async () => {
    const reqList = new RequestListDTO();
    reqList.onpage = 20;
    reqList.page = 1;
    reqList.filters = [];

    let f: RequestFilterDTO;

    f = new RequestFilterDTO();
    f.field = "status";
    f.values = [];
    f.values.push(Status.ACTIVE.toString());
    reqList.filters.push(f);

    const cd = getCache(reqList);

    if (cd !== false) {
      setObjects(cd);
      setIsLoading(false);
      return;
    }

    setIsLoading(true);

    const data = await testimonialService.getList(undefined, {}, reqList);

    const rez = !data || !data.objects ? [] : data.objects;

    setObjects(rez);
    setIsLoading(false);


    saveCache(rez, reqList);
  };

  useEffect(() => {
    getList();
  }, []);


 

  const processTestimonialItem = (obj: TestimonialDto, index: number) => {
    if (!obj) return <></>;

    return (
      <Box key={index}>
        {" "}
        <BecomeTeacherTestimonialItem obj={obj} />{" "}
      </Box>
    );
  };


  const processBecomeTeacherTestimonialList = () => {
    if (!objects.length) return <></>;
    const slidesToShow = CommonTools.calculateNumberOfElementsShowOnCarousel(parseInt(CC("DefaultValueShowTestimonialCarouselBecomeTeacher","3")), objects.length)
    
    return (

      <Box className='container' py={5}>
        <Typography variant="h2" textAlign='center'>{LL("Extent_your_area")}</Typography>
        <Typography textAlign='center' className="subText" mt={2} mb={4}>{LL("We_changed_the_life_between_student_and_teacher")}</Typography>
        <Carousel _slidesToShow={slidesToShow}>
          {objects.map((item: TestimonialDto, i: number) => {
            return processTestimonialItem(item, i)
          })}
        </Carousel>
      </Box>

    );
  };

  return isLoading ? <Skeleton /> : processBecomeTeacherTestimonialList();
};

export { BecomeTeacherTestimonialList };




