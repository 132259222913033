import React, { useEffect, useState } from "react";
import PageComponentProps from "interfaces/pagecomponentprops.interface";
import { CommonTools } from "tools/commontools";
import { Box, Skeleton, Typography } from "@mui/material";

import { useResource } from "hook/useResource";

import { RatingRangesDto } from "dto/rating/ratingranges.dto";
import RatingService from "services/rating/rating.service";
import { RatingProgress } from "components/elements/Statistic/RatingProgress";


const ratingService = new RatingService();

type TeacherStatisticProps = {
    idTeacher: string;
};

const TeacherStatistic: React.FC<TeacherStatisticProps> = ({ idTeacher, ...props }) => {


    const { LL } = useResource();
    
    const [loading, setLoading] = useState<boolean>(true);
    const [obj, setObj] = useState<RatingRangesDto| undefined>();

    const loadObject = (obj: any) => {
        if (!obj) return;
        if (!obj.err) {
            const object = obj.obj ? obj.obj : [];
            setObj(object);
        }
    };

    const getObject = () => {
        if (idTeacher) ratingService.getTeacherStatistic(idTeacher, loadObject, {});
    };

    const checkIsLoading = () => {
        let load: boolean = false;
        if (obj === undefined) load = true;
        setLoading(load);
    };

    useEffect(() => {
        getObject();
    }, [idTeacher]);

    useEffect(() => {
        checkIsLoading();

    }, [obj]);
    

    const processStatistic = () =>{
        if (!obj) return <></>;
        return <RatingProgress obj={obj}/>
    }

    return idTeacher && !loading ?
        (processStatistic()) : (<Skeleton />);
};

export { TeacherStatistic };
