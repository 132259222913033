import React, { useState, useEffect } from 'react';

import SocialService from "services/user/social.service";
import { logger } from 'hoc/logger';

import SocialDto from 'dto/user/social.dto';
import { useCookies } from 'react-cookie';
import { Loading } from 'components/general/Loading.jsx';
import { MyNavBt } from "components/general/MyNavBt";
import { useResource } from "hook/useResource";
import Grid from '@mui/material/Grid';
import { CommonTools } from 'tools/commontools';
import { ComponentCommonTools } from 'tools/componentcommontools'

import ResultObjectDTO from 'dto/app/resultobject.dto';
import { MyBreadcrumbs } from "components/elements/MyBreadcrumbs/MyBreadcrumbs";
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'

import { Link, Skeleton, Stack } from '@mui/material';
import { useAuth } from 'hook/useAuth';
import { CircularLoading } from 'components/general/CircularLoading';
import { Divider } from "@mui/material";
import { Types } from 'tools/types';
import { SocialItemIcon } from 'components/social/SocialItemIcon';

const socialService = new SocialService();


interface SocialProps {
    idUser: string
    type: number;
}

const UserSocialBlock: React.FC<SocialProps> = ({
    idUser,
    type,
    ...props
}) => {

    const { LL } = useResource();

    const [loading, setLoading] = useState<boolean>(true);
    const [obj, setObj] = useState<Array<SocialDto> | undefined>();

    const loadObject = (obj: any) => {
        if (!obj) return;
        if (!obj.err) {
            const object = obj.obj ? obj.obj : [];
            setObj(object);
        }
    };

    const getObject = () => {
        if (!idUser) return;
        socialService.getSocialsByUserId(idUser, loadObject, {});
    };

    const checkIsLoading = () => {
        let load: boolean = false;
        if (!obj) load = true;
        setLoading(load);
    };

    useEffect(() => {
        if (!idUser) return;
        getObject();
    }, [idUser]);

    useEffect(() => {
        checkIsLoading();
    }, [obj]);


    const processItemLink = (item: SocialDto) => {
        if (!item) return <></>;
        if (!item.link) return <></>;
        return (
            <Link  href={item.link} target="_blank" rel="noopener noreferrer">
                {processDetail(item)}
            </Link>
        )
    }
    const processDetail = (item: SocialDto) => {
        if (!item) return <></>;
        if (!item.typesocial) return <></>;
        if (!item.typesocial.hasOwnProperty('gallery')) return <>{ComponentCommonTools.processObjectFieldOfObject(item, 'typesocial', 'name')}</>;

        if (type === Types.TYPE_SOCIAL_DISPLAY_SHORT_FORM) {
            return <SocialItemIcon obj={item} width={20} height={20} />
        }
        if (type === Types.TYPE_SOCIAL_DISPLAY_LONG_FORM) {
            return (
                    
                    <>
                        <SocialItemIcon obj={item} width={20} height={20} />
                        {ComponentCommonTools.processObjectFieldOfObject(item, 'typesocial', 'name')}
                    </>
                
               
            )
        }
    }



    const processItem = (item: SocialDto, index: number) => {
        if (!item) return <></>;
       
        return (
            <Box className='blockEl' key={index}>
                {processItemLink(item)}
            </Box>
        )
    }

    const processList = () => {
        if (!obj) return <></>;
        if (obj.length === 0) return <></>;
        
        if (type === Types.TYPE_SOCIAL_DISPLAY_SHORT_FORM) {
            
            return (
                <Stack direction='row' spacing={1}>
                    {obj.map((item, index) => {
                        return processItem(item, index);
                    })}
                </Stack>
            )
        }
        return (
            <Box className='socialName'>
                {
                    obj.map((item, index) => {
                        return processItem(item, index);
                    })
                }
            </Box >
        )
    };


    return idUser && !loading ?
        (<>
            {processList()}
        </>) : (<Skeleton />);
};

export default UserSocialBlock;
