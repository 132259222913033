import React, { useEffect, useState, useRef } from "react";
import PageComponentProps from "interfaces/pagecomponentprops.interface";
import { useResource } from "hook/useResource";
import { logger } from "hoc/logger";
import Box from '@mui/material/Box'
import { MyNavBt } from "components/general/MyNavBt";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import MyTextField from 'components/form/mytextfield';
import { IconButton, Stack } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import Snackbar from '@mui/material/Snackbar';
import { useMessage } from "hook/useMessage";
import { CommonTools } from "tools/commontools";
import { MessageTypes } from "tools/messagetypes";
import { ShareElements } from "components/elements/Share/ShareElements";



type ShareDialogProps = {
    [x: string]: any;
    url: string;
    buttonContent?: React.ReactNode;
    title?: string;
    description?: string;
    imageUrl?: string;
}
const ShareDialog: React.FC<ShareDialogProps> = ({
    url,
    buttonContent,
    title = '',
    description = '',
    imageUrl = '',

    ...props
}) => {
    const { LL } = useResource();
    const { RegisterMessage} = useMessage();

    const [fullUrl, setFullUrl] = useState('');

    useEffect(() => {
        if (url) setFullUrl(prepareFullUrl());
    }, [url]);

    const prepareFullUrl = () => {
        if (!url) return '';
        return CommonTools.generateFullUrlSite(url);
    }
    const handleSubmit = (event: any) => {
        event.preventDefault();

        logger("ShareDialogShareDialog", fullUrl);
    };

    const handleClose = () => {
        setOpen(false);
    };
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const processButtonContent = () => {
        if (!buttonContent) {
            return <>{LL("Share")}</>
        }
        return buttonContent;
    }

    const inputRef = useRef<HTMLInputElement>(null);

    const handleCopyClick = () => {
        
        const inputElement = inputRef.current;
        if (inputElement) {
            inputElement.select();
            inputElement.setSelectionRange(0, 99999); 
            document.execCommand('copy');
            RegisterMessage(CommonTools.createMessage(LL("Link_copied_to_clipboard"), MessageTypes.MESSAGE_SUCCESS,' '))

        }
    };
    const processShareElements = () => {
        if(!url) return <></>;
        return (<ShareElements url={fullUrl} title={title} description={description} imageUrl={imageUrl} />)
    }


   
    return (<>
        <Button onClick={handleClickOpen} {...props}>
            {processButtonContent()}
        </Button>
        <Dialog
            open={open}
            onClose={handleClose}
            fullWidth

        >
            <DialogTitle>{LL("Sharethiscourse")}</DialogTitle>
            <IconButton sx={{
                position: 'absolute',
                right: 8,
                top: 8,
            }} onClick={handleClose}>
                <CloseIcon />
            </IconButton>
            <DialogContent dividers>
                <form onSubmit={handleSubmit}>
                    <Stack direction='row' sx={{width:'100%', pb:3}} spacing={1} justifyContent='space-between'>
                        <Box sx={{width:'100%'}}>
                    <MyTextField
                        fullWidth
                        id="fullUrl"
                        name="fullUrl"
                        size="small"
                        variant="outlined"
                        _label={LL("fullUrl_share")}
                        value={fullUrl}
                        setObj={(field: any, value: any) => { setFullUrl(value) }}
                        inputRef={inputRef}
                        _vresults={
                            undefined
                        }
                    /></Box>
                    <Button onClick={handleCopyClick} variant="contained">
                        {LL("Copy_Link")}
                    </Button>
                    </Stack>
                </form>
                {processShareElements()}
            </DialogContent>

        </Dialog>
    </>
    );
};

export { ShareDialog };
