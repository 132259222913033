import RequestListDTO from "dto/app/requestlist.dto";
import RequestSortCriteriaDTO from "dto/app/requestsortcriteria.dto";
import ResultObjectDTO from "dto/app/resultobject.dto";
import { TeacherFAQDto } from "dto/teacher/teacherfaq.dto";
import ResultListDTO from "dto/app/resultlist.dto";
import { logger } from "hoc/logger";
import IService from "interfaces/iservice.interface";
import TeacherFAQRepository from "repositories/teacher/teacherfaq.repository";
import { Status } from "tools/status";
import GeneralService from "services/general.service";

export default class TeacherFAQService extends GeneralService implements IService {
    teacherfaqRepository: TeacherFAQRepository = new TeacherFAQRepository();
    constructor() {
        super();
        this.handleGetList = this.handleGetList.bind(this);
        this.handleGet = this.handleGet.bind(this);
    }
    handleUpdate(result?: any, cbparameters?: any, data?: any): any {
        let rez = new ResultObjectDTO();
        rez = result;

        if (result.err) return;

        if (cbparameters && cbparameters._cb) {
            cbparameters._cb(rez, cbparameters, data);
        }
    }

    async update(id: string, cb?: any, cbparameters?: any, data?: any): Promise<any> {

        cbparameters = cbparameters ? cbparameters : {};
        cbparameters._cb = cb;
        this.teacherfaqRepository.update(id, this.handleUpdate, data, cbparameters);
    }

    handleDelete(result?: any, cbparameters?: any): any {
        let rez = new ResultObjectDTO();
        rez = result;
        if (result.err) return;

        if (cbparameters && cbparameters._cb) {
            cbparameters._cb(rez, cbparameters);
        }
    }

    async delete(id: string, cb?: any, cbparameters?: any): Promise<any> {
        cbparameters = cbparameters ? cbparameters : {};
        cbparameters._cb = cb;

        this.teacherfaqRepository.delete(id, this.handleDelete, cbparameters);
    }



    handleGet(result?: any, cbparameters?: any, data?: any): any {
        let rez = new ResultObjectDTO();
        rez = result;
        if (result.err) return;
        this.populateObject(rez.obj, result.obj, [
            { key: 'status', type: 'status' },
            { key: 'date', type: 'timestamp' },
            { key: 'teacherresponsedate', type: 'timestamp' },
            { key: 'showoncourse', type: 'booleans'}
        ]);
        if (cbparameters && cbparameters._cb) {
            cbparameters._cb(rez, cbparameters, data);
        }

    return rez;
    }

    async get(id: string, cb?: any, cbparameters?: any): Promise<any> {
        cbparameters = cbparameters ? cbparameters : {};
        cbparameters._cb = cb;
        const t = await this.teacherfaqRepository.get(id, this.handleGet, cbparameters);
        if (cb == undefined)
        {
            return this.handleGet(t);
        }
    }

    handleGetList(result?: any, cbparameters?: any, data?: any): any {
        let rez = new ResultListDTO();

        rez = result;
        if (result.err) return;

        this.parseResultPopulate(result, rez, [
            { key: 'status', type: 'status' },
            { key: 'date', type: 'timestamp' },
            { key: 'teacherresponsedate', type: 'timestamp' },
            { key: 'showoncourse', type: 'booleans'}

        ]);

        if (cbparameters && cbparameters._cb) {
            cbparameters._cb(rez, cbparameters, data);
        }

        return rez;
    }

    async getList(
        cb?: any,
        cbparameters?: any,
        data?: RequestListDTO
    ): Promise<any> {
        cbparameters = cbparameters ? cbparameters : {};
        cbparameters._cb = cb;
        const t = await this.teacherfaqRepository.getList(this.handleGetList, cbparameters, data);
        if (cb == undefined)
        {
            return this.handleGetList(t);
        }
    }

    async getListTeacher(
        cb?: any,
        cbparameters?: any,
        data?: RequestListDTO
    ): Promise<any> {
        cbparameters = cbparameters ? cbparameters : {};
        cbparameters._cb = cb;
        const t = await this.teacherfaqRepository.getListTeacher(this.handleGetList, cbparameters, data);
        if (cb == undefined)
        {
            return this.handleGetList(t);
        }
    }

    

    handleAdd(result?: any, cbparameters?: any, data?: any): any {
        let rez = new ResultObjectDTO();
        rez = result;
        if (result.err) return;

        if (cbparameters && cbparameters._cb) {
            cbparameters._cb(rez, cbparameters, data);
        }
    }



    async add(
        cb?: any,
        cbparameters?: any,
        data?: any
    ): Promise<any> {
        cbparameters = cbparameters ? cbparameters : {};
        cbparameters._cb = cb;

        this.teacherfaqRepository.add(this.handleAdd, cbparameters, data);
    }


    // getDefaultRequestList(onPage?: number): RequestListDTO {
    //     onPage = onPage ?? Number(process.env.REACT_APP_ONPAGE);
    //     if (onPage === 0) onPage = Number(process.env.REACT_APP_ONPAGE);
    //     var defaultRequestList = new RequestListDTO();
    //     defaultRequestList.page = Number(process.env.REACT_APP_PAGE);
    //     defaultRequestList.onpage = onPage;
    //     var defaultRequestSortCriteria = new RequestSortCriteriaDTO()
    //     defaultRequestSortCriteria.asc = true;
    //     defaultRequestSortCriteria.field = 'status';
    //     defaultRequestList.sortcriteria = []
    //     defaultRequestList.sortcriteria?.push(defaultRequestSortCriteria);
    //     return defaultRequestList;
    // }

    getDefaultRequestListSelect(onPage?: number): RequestListDTO {
        onPage = onPage ?? Number(process.env.REACT_APP_ONPAGE);
        if (onPage === 0) onPage = Number(process.env.REACT_APP_ONPAGE);
        var defaultRequestList = new RequestListDTO();
        defaultRequestList.page = Number(process.env.REACT_APP_PAGE);
        defaultRequestList.onpage = onPage;
        var defaultRequestSortCriteria = new RequestSortCriteriaDTO()
        defaultRequestSortCriteria.asc = true;
        defaultRequestSortCriteria.field = 'date';
        defaultRequestList.sortcriteria = []
        defaultRequestList.sortcriteria?.push(defaultRequestSortCriteria);
        return defaultRequestList;
    }

    getDefaultRequestList(obj: RequestListDTO): RequestListDTO {
        if (
            !obj.sortcriteria ||
            !Array.isArray(obj.sortcriteria) ||
            !obj.sortcriteria.length
        ) {
            var sobj = new RequestSortCriteriaDTO();
            sobj.asc = true;
            sobj.field = "date";

            obj.sortcriteria = [];
            obj.sortcriteria?.push(sobj);
        }

        return obj;
    }
}
