import Idto from 'interfaces/idto.interface';
import { Status } from 'tools/status';

export class TestResultDto implements Idto {

    id?: string;
    
    idtest?: string;
    idstudent?: string;
    idcourseenrollment?: string;
    idteacher?: string;
    idcourse?: string;
    totalscore?: number;
    date?: number;
    status?: number;
    maxscore?: number;
    minscore?: number;
    student?: any;
    test?: any;
    teacher?: any;
    course?: any;
    courseenrollment?: any;
    idlessonenrollment?: string;
}

export class PostTestResultDto implements Idto {

    idtest?: string;
    idstudent?: string;
    idcourseenrollment?: string;
    idteacher?: string;
    idcourse?: string;
    totalscore?: number;
    date?: number;
    status?: number;
    maxscore?: number;
    minscore?: number;
    idlessonenrollment?: string;

    constructor() {
        this.totalscore = 0
        this.status = Status.TEST_STARTED;
    }
}