import { Box, Card, CardContent, IconButton, MenuItem, Skeleton, Stack, Typography, Grid } from "@mui/material";

import { TeacherRequestDto } from "dto/teacher/teacherrequest.dto";
import { useAuth } from "hook/useAuth";
import { useResource } from "hook/useResource";
import React, { useState, useEffect } from "react";

import DateTools from "tools/date.tools";
import { Status } from "tools/status";
import { Types } from "tools/types";

import CloseIcon from '@mui/icons-material/Close';

import { CommonTools } from "tools/commontools";
import { ComponentCommonTools } from "tools/componentcommontools";
import { StatusChip } from "components/elements/Chip/StatusChip";


type TeacherInterfaceRequestItemProps = {
    obj: TeacherRequestDto;
}



const TeacherInterfaceRequestItem: React.FC<TeacherInterfaceRequestItemProps> = ({
    obj,
    ...props
}) => {

    const { LL, CC } = useResource();

    const processItem = () => {
        if (!obj) return (<></>);

        return (
            <Box className="teacherRequest" my={2}>
                    <Box mb={1}>
                        <Typography variant="h5">
                            {ComponentCommonTools.processObjectField(obj, "type_name")}<StatusChip sx={{ml:1, color:'#fff'}} obj={obj} />
                        </Typography>
                        {/* {ComponentCommonTools.processObjectField(obj, "status_name")} */}
                        
                    </Box>
                    <Grid container>
                        <Grid item lg={6} sm={12}>
                            <Stack direction='row' spacing={2}>
                                <Typography color="text.disabled" variant="caption">
                                    {LL("Date")}
                                </Typography>
                                <Typography >
                                    {ComponentCommonTools.processObjectField(obj, "date_name")}
                                </Typography>
                                <Typography color="text.disabled" variant="caption">
                                    {LL("changedate")}
                                </Typography>
                                <Typography >
                                    {ComponentCommonTools.processObjectField(obj, "changedate_name")}
                                </Typography>
                                </Stack>
                            <Typography color="text.disabled" variant="caption">
                                {LL("teachermessage")}
                            </Typography>
                            <Typography >
                                {ComponentCommonTools.processObjectField(obj, "teachermessage")}
                            </Typography>
                        </Grid>
                        <Grid item lg={6} sm={12}>

                            <Typography color="text.disabled" variant="caption">
                                {LL("managerfullname")}
                            </Typography>
                            <Typography >
                                {ComponentCommonTools.processObjectField(obj, "managerfullname")}
                            </Typography>
                            <Typography color="text.disabled" variant="caption">
                                {LL("managermessage")}
                            </Typography>
                            <Typography >
                                {ComponentCommonTools.processObjectField(obj, "managermessage")}
                            </Typography>
                        </Grid>
                    </Grid>
                    
            </Box>
        )
    }


    return processItem();
};

export { TeacherInterfaceRequestItem };
