import { Box, IconButton, MenuItem, Skeleton, Stack, Typography } from "@mui/material";

import { NotificationDto } from "dto/user/notification.dto";
import { useAuth } from "hook/useAuth";
import { useResource } from "hook/useResource";
import React, { useState, useEffect } from "react";
import NotificationService from "services/user/notification.service";
import DateTools from "tools/date.tools";
import { Status } from "tools/status";
import { Types } from "tools/types";
import { NotificationParamsList } from "./NotificationParamsList";
import CloseIcon from '@mui/icons-material/Close';
import ResultObjectDTO from "dto/app/resultobject.dto";
import { CommonTools } from "tools/commontools";
type NotificationListItemTopMenuProps = {
    obj: NotificationDto;
    setLoading: any;
    setObjectWasChanged:any;
    setIsDisableMarkAllRead: any;
}

const notificationService = new NotificationService();

const NotificationListItemTopMenu: React.FC<NotificationListItemTopMenuProps> = ({
    obj,
    setObjectWasChanged,
    setLoading,
    setIsDisableMarkAllRead,

    ...props }) => {

    const { LL, CC } = useResource();
    const { user, teacher, student } = useAuth();



    const processAgoTime = () => {
        if (!obj) return (<></>);
        if (!obj.hasOwnProperty('date')) return (<></>);
        if (!obj.date) return (<></>);
        return DateTools.timeAgo(obj.date, LL);
    }

    const handleMarkAsRead = () => {
        if (!obj) return;
        if (!obj.id) return;
        setLoading(true)
        setIsDisableMarkAllRead(true);
        notificationService.markAsRead(obj.id, handleResult, {});
    }

    const handleResult = (result: ResultObjectDTO) => {
        if (!result) return;
        if (!result.err) {
            setObjectWasChanged(CommonTools.generateRandomString(5))
        }
    }

    const processItem = () => {
        if (!obj) return (<></>);
        if (!obj.hasOwnProperty('id')) return (<></>);
        if (!obj.id) return (<></>);

        return (
            <Stack
                direction='row'
                justifyContent="space-between"
                alignItems="center"
                spacing={1}
                sx={{ width: '100%' }}
                
            >

                    <Box>
                        <NotificationParamsList
                            idNotification={obj.id}
                            timeComponent={processAgoTime}
                        />

                        {/* <Typography className="data">
                            {processAgoTime()}
                        </Typography> */}
                    </Box>

                <Box >
                    <IconButton onClick={handleMarkAsRead}>
                        <CloseIcon />
                    </IconButton>
                </Box>
            </Stack>
        )
    }


    return processItem();
};

export { NotificationListItemTopMenu };
