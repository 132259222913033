import React, { useEffect, useState, useRef } from "react";
import { useResource } from "hook/useResource";
import { useAuth } from "hook/useAuth";
import { useGoogleRecaptcha } from "hook/useGoogleRecaptcha";
import PageComponentProps from "interfaces/pagecomponentprops.interface";
import AppBar from "@mui/material/AppBar";
import LockOpenIcon from "@mui/icons-material/LockOpen";

import SignInDto from "dto/auth/signin.dto";

import Button from "@mui/material/Button";
import { Loading } from "components/general/Loading";
import MyTextField from "components/form/mytextfield";
import MyCheckboxField from "components/form/mycheckboxfield";
import RequiredValidator from "validators/required.validator";
import { LabelTypes } from "tools/labeltypes";
import ValidateTools from "tools/validate.tools";
import Container from "@mui/material/Container";
import InputAdornment from "@mui/material/InputAdornment";

import MailOutlineIcon from "@mui/icons-material/MailOutline";

import Box from "@mui/material/Box";
import { logger } from "hoc/logger";
import Typography from "@mui/material/Typography";
import { MyNavBt } from "components/general/MyNavBt";
import { MyButton } from "components/general/MyButton";
import { RouteTools } from "tools/routetools";
import { AuthMethodsBlock } from "components/auth/AuthMethodsBlock";
// import {ZoomMeeting} from "components/zoomElements/ZoomMeeting";
import { LocalJitsiMeeting } from "components/jitsi/LocalJitsiMeeting";

const TestBlockLayout: React.FC<PageComponentProps> = ({
  currentRoute,
  ...props
}) => {
  const { LL } = useResource();
  const [isLoading, setIsLoading] = useState(false);

  return isLoading ? (
    <Loading />
  ) : (
    <Container maxWidth="xs">
      <Box
        mt={5}
        sx={{
          alignItems: "center",
          alignContent: "center",
          width: "100%",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <Typography variant="h2" align="center" sx={{ mb: 5 }}>
          TestBlockLayout
        </Typography>

        <Box>LocalJitsiMeeting</Box>

        <Box>
          <LocalJitsiMeeting />
        </Box>
      </Box>
    </Container>
  );
};

export { TestBlockLayout };
