import React, { useEffect, useState } from "react";
import PageComponentProps from "interfaces/pagecomponentprops.interface";
import { CommonTools } from "tools/commontools";
import { Box, Skeleton, Stack, Typography, Grid } from "@mui/material";
import { StudentDto } from "dto/student/student.dto";
import ResultObjectDTO from "dto/app/resultobject.dto";
import StudentService from "services/student/student.service";
import { HtmlTag } from "components/general/HtmlTag";
import { useResource } from "hook/useResource";

import UserSocialBlock from "components/user/UserSocialBlock";
import { Types } from "tools/types";
import { CourseListByStudentDisplayBlock } from "components/course/course/CourseListByStudentDisplayBlock";
import { NewsletterBlock } from "components/general/NewsletterBlock";

import { ComponentCommonTools } from "tools/componentcommontools";
import { MyAvatar } from "components/elements/MyAvatar/MyAvatar";
import { RecommendedCourseForStudent } from "components/course/course/RecommendedCourseForStudent";
import { logger } from "hoc/logger";


const studentService = new StudentService();

const PublicStudentProfile: React.FC<PageComponentProps> = ({ currentRoute, ...props }) => {

    const { LL } = useResource();
    const [idStudent, setIdStudent] = useState("");
    const [obj, setObj] = useState<StudentDto | undefined>();
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
      
        setIdStudent(CommonTools.getIdFromPath(currentRoute, 'student'));

    }, [currentRoute]);

    const getObject = () => {
        if (!idStudent) return;
        setLoading(true);
        studentService.getByIdOrIdUser(idStudent, loadObject, {});
    };

    const loadObject = (obj: ResultObjectDTO) => {
        if (!obj) return;
        if (!obj.err) {
            const object = obj.obj ? obj.obj : {};
            setObj(object);

        }
    };

    const checkIsLoading = () => {
        let load: boolean = false;
        if (obj === undefined) load = true;
        setLoading(load);
    };

    useEffect(() => {
        if (!idStudent) return;
        getObject();
    }, [idStudent]);

    useEffect(() => {
        checkIsLoading();
    }, [obj]);

    const processFullName = () => {
        if (!obj) return <></>
        if (!obj.hasOwnProperty('name') && !obj.name) return <></>
        if (!obj.hasOwnProperty('surname') && !obj.surname) return <></>
        return (<>
            <Stack direction="row" spacing={1}>
                <Typography variant="h3">{ComponentCommonTools.processObjectField(obj, 'name')}</Typography>
                <Typography variant="h3">{ComponentCommonTools.processObjectField(obj, 'surname')}</Typography>
            </Stack>
        </>)
    }

    const processAvatar = () => {
        if (!obj) return <></>;
        let name = ''
        let surname = ''
        if (obj.hasOwnProperty('name') && obj.name) name = obj.name;
        if (obj.hasOwnProperty('surname') && obj.surname) surname = obj.surname;

        return <MyAvatar className="avatar" obj={obj} name={name} surname={surname} sx={{ mr: 1 }} />
    }

    const processTypeStudies = () => {
        if (!obj) return <></>;
        if (!obj.hasOwnProperty('typestudies')) return <></>;
        if (!obj.typestudies) return <></>;
        return (
            <Typography className="discipline">
                {ComponentCommonTools.processObjectFieldOfObject(obj, 'typestudies', 'name')}
            </Typography>
        )
    }
    const processInstitution = () => {
        if (!obj) return <></>;
        if (!obj.hasOwnProperty('institution')) return <></>;
        if (!obj.institution) return <></>;
        return (
            <Typography>
                {ComponentCommonTools.processObjectField(obj, 'institution')}
            </Typography>
        )
    }

    const processUserSocials = () => {
        if (!obj) return <></>;
        if (!obj.hasOwnProperty('iduser')) return <></>;
        if (!obj.iduser) return <></>;
        return (<Box className="socials"><UserSocialBlock idUser={obj.iduser} type={Types.TYPE_SOCIAL_DISPLAY_SHORT_FORM} /></Box>)
    }

    const processStudentCourses = () => {
        if (!idStudent) return <></>;
        return (<Box mt={5}>
        <Typography variant="h2" mb={2}>{LL('StudentCourses')}</Typography>
            <CourseListByStudentDisplayBlock idStudent={idStudent} />
        </Box>);

    }

    const processBio = () => {
        if (!obj) return <></>;
        if (!obj.hasOwnProperty('bio')) return <></>;
        if (!obj.bio) return <></>;
        return (
            <Box>
                <Typography variant="h5" mt={5} mb={2}>{LL('About_me')}</Typography>
                <HtmlTag content={obj.bio} />
            </Box>
        )
    }

    const processRecommendedCourses = () => {
        if (!obj) return <></>;
        if(!idStudent) return (<></>);
        
        return (<Box>
            <Typography variant="h5" my={2}>{LL('Recommended_courses')}</Typography>
            <RecommendedCourseForStudent idStudent={idStudent}/>
        </Box>)
    }

    const processStudentInfo = () => {
        if (!obj) return <></>;
        return (
            <Stack direction='row' spacing={2} alignItems='center'>
                {processAvatar()}
                <Stack direction='column'>
                    {processFullName()}
                    <Stack sx={{my:1}} direction='row' spacing={1} divider={<>,</>}>
                        {processTypeStudies()}
                        {processInstitution()}
                    </Stack>
                    {processUserSocials()}
                    
                </Stack>
            </Stack>
        )
    }


    return idStudent && !loading ?
        (<>
        <Box className="container studentProfile" pb={3}>
            <Grid container direction='row' py={5} spacing={2}>
                <Grid item sm={12} md={6} className="aboutStudent">
                    {processStudentInfo()}
                    {processBio()}
                </Grid>
                <Grid item sm={12} md={1}></Grid>
                <Grid item sm={12} md={5}>{processRecommendedCourses()}</Grid>
            </Grid>
            {processStudentCourses()}
        </Box>
        <NewsletterBlock currentRoute={currentRoute} />
        </>) : (<></>);
};

export { PublicStudentProfile };
