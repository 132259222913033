import { AttachmentDto } from 'dto/attachment/attachment.dto';
import { GalleryDto } from 'dto/gallery/gallery.dto';
import { VideoDto } from 'dto/video/video.dto';
import Idto from 'interfaces/idto.interface';
import { Status } from 'tools/status';

export class LessonDto implements Idto {

    id?: string;
    name?: string;
    description?: string;
    status?: number;
    ordercriteria?: number;
    idgallery?: string;
    idattachment?: string;
    idvideo?: string;
    istest?: boolean;
    idtest?: string | null;
    idlessonsettings?: string | null;
    lessonsettings?: any;
    idtypelesson?: string;
    idcourse?: string;
    lessonduration?: number;
    islive?: boolean;
    typelesson?: any;
    startdate_name?: string;
    enddate_name?: string;
    startdate?: number;
    enddate?: number;
    ispassed?: boolean;
    attachment?:Array<AttachmentDto>;
    video?: Array<VideoDto>;
    gallery?: Array<GalleryDto>;
    constructor() {
        this.status = Status.ACTIVE;
        this.ordercriteria = 0;
        this.islive = false;
        this.lessonduration = 0;
        this.istest = false;
        
    }
    
}

export class EditLessonOrderDto implements Idto {
    id?: string;
    ordercriteria?: number;
}

export class PatchLessonOrderDto implements Idto {
    
    neworder?: EditLessonOrderDto[]
}

export class PostLessonDto implements Idto {

    name?: string;
    description?: string;
    status?: number;
    ordercriteria?: number;
    idgallery?: string;
    idattachment?: string;
    idvideo?: string;
    istest?: boolean;
    idtest?: string | null;
    idlessonsettings?: string | null;
    lessonsettings?: any;
    idtypelesson?: string;
    idcourse?: string;
    lessonduration?: number;
    islive?: boolean;
    startdate_name?: string;
    enddate_name?: string;
    startdate?: number;
    enddate?: number;
    constructor() {
        this.status = Status.ACTIVE;
        this.ordercriteria = 0;
        this.islive = false;
        this.lessonduration = 0;
        this.istest = false;
        
    }
}