import React, { useCallback, useEffect, useRef, useState } from "react";
import { useResource } from "hook/useResource";
import { logger } from "hoc/logger";
import CourseService from "services/course/course.service";
import PageComponentProps from "interfaces/pagecomponentprops.interface";
import { CourseDto } from "dto/course/course.dto";
import { RouteTools } from "tools/routetools";
import RequestListDTO from "dto/app/requestlist.dto";
import { Status } from "tools/status";
import RequestFilterDTO from "dto/app/requestfilter.dto";
import ResultListDTO from "dto/app/resultlist.dto";
import { Types } from "tools/types";
// import { InfoCourseItem } from "components//InfoCourseItem";
import { Loading } from "components/general/Loading";
import Skeleton from "@mui/material/Skeleton";
import { Box, Link, Typography } from "@mui/material";
import ResultObjectDTO from "dto/app/resultobject.dto";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { ComponentCommonTools } from "tools/componentcommontools";
import Stack from "@mui/material/Stack";
import AppBar from "@mui/material/AppBar";
import { MyNavBt } from "components/general/MyNavBt";
import { CourseCard } from "components/course/course/CourseCard";
import { Carousel } from "components/elements/Carousel/Carousel";
import RequestSortCriteriaDTO from "dto/app/requestsortcriteria.dto";
import { CommonTools } from "tools/commontools";
import { TeacherDto } from "dto/teacher/teacher.dto";

const courseService = new CourseService();

type CourseListByTeacherProps = {
  idTeacher: string;
  idCourse: string;
  teacher: TeacherDto;
};

const CourseListByTeacher: React.FC<CourseListByTeacherProps> = ({
  idTeacher,
  idCourse,
  teacher,
  ...props
}) => {
  const { LL, CC } = useResource();
  const [isLoading, setIsLoading] = useState(true);
  const [objects, setObjects] = useState<Array<CourseDto>>([]);

  const getList = () => {
    const reqList = new RequestListDTO();
    reqList.onpage = 10;
    reqList.page = 1;
    reqList.filters = [];
    reqList.sortcriteria = [];
    let sortCriteria = new RequestSortCriteriaDTO();
    sortCriteria.field = "popularidentifier";
    sortCriteria.asc = false;
    reqList.sortcriteria.push(sortCriteria);

    let f: RequestFilterDTO;

    f = new RequestFilterDTO();
    f.field = "status";
    f.values = [];
    f.values.push(Status.ACTIVE.toString());
    reqList.filters.push(f);
    f = new RequestFilterDTO();
    f.field = "idteacher";
    f.values = [idTeacher];
    reqList.filters.push(f);
    f = new RequestFilterDTO();
    f.field = "notidcourse";
    f.values = [idCourse];
    reqList.filters.push(f);

    setIsLoading(true);

    courseService.getListByTeacher(loadObjects, {}, reqList);

    // setIsLoading(false);
  };

  const loadObjects = (result: ResultListDTO) => {
    if (!result) return;
    if (!result.err) {
      const objects = result.objects ? result.objects : [];

      setObjects(objects);
    }
  };

  const checkLoading = () => {
    let load = false;

    if (!objects) load = true;
    // if (objects.length === 0) load = true;

    setIsLoading(load);
  };
  useEffect(() => {
    if (!idTeacher) return;
    if (!idCourse) return;
    getList();
  }, [idTeacher, idCourse]);

  useEffect(() => {
    checkLoading();
  }, [objects]);

  const processCourseItem = (obj: CourseDto, index: number) => {
    logger("InfoCourseLevel", obj);

    return (
      <Box key={index}>
        <CourseCard obj={obj} loading={isLoading} />
      </Box>
    );
  };

  const processCourseList = () => {
    if (!objects.length) return <></>;
    const slidesToShow = CommonTools.calculateNumberOfElementsShowOnCarousel(
      parseInt(CC("DefaultValueShowCourseListByValueCarousel", "3")),
      objects.length
    );
    return (
      <>
        <Box>
          <Typography variant="h3" mt={5} mb={3}>
            {LL("OtherCoursesByTeacher")}
            <Link
              sx={{ ml: 1, textDecoration: "none" }}
              href={teacher.fullurl}
              target="_blank"
              rel="noopener"
            >
              {ComponentCommonTools.processObjectField(teacher, "fullname")}
            </Link>
          </Typography>
        </Box>

        <Carousel _slidesToShow={slidesToShow}>
          {objects.map((item: CourseDto, i: number) => {
            return processCourseItem(item, i);
          })}
        </Carousel>
      </>
    );
  };

  return isLoading ? (
    <Skeleton />
  ) : (
    <Box>
      {processCourseList()}
    </Box>
  );
};

export { CourseListByTeacher };
