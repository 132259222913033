import React, { useEffect, useState } from "react";
import { useResource } from "hook/useResource";

import { logger } from "hoc/logger";
import { GoogleLogin } from "@react-oauth/google";

import { useAuth } from "hook/useAuth";

import SocialSignInDto from "dto/auth/socialsignin.dto";
import { SocialIdentifier } from "tools/socialidentifier";

type MyGoogleLoginButtonProps = {}

const MyGoogleLoginButton: React.FC<MyGoogleLoginButtonProps> = ({ ...props }) => {
  const { signInSocial } = useAuth();
  const { LL } = useResource();

  const responseMessage = (response?: any) => {
    const obj = new SocialSignInDto();
    obj.socialidentifier = SocialIdentifier.GOOGLE.toString();
    obj.socialtoken = response.credential;
    obj.requestObject = response;
    signInSocial(obj);
  };

  const errorMessage = (e?: any) => {
    logger("error Google", e);
  };

  // theme={"outline"} // "outline" | "filled_blue" | "filled_black"
  // text={"signin"} // "signin_with" | "signup_with" | "continue_with" | "signin"
  // logo_alignment={"left"} // "left" | "center"
  // size={"small"} // "small" | "large" | "medium"
  // shape={"rectangular"} // "rectangular" | "pill" | "circle" | "square"
  // https://developers.google.com/identity/gsi/web/reference/js-reference
  // locale={"zh_CN"}

  return (
    <>
      <GoogleLogin
        onSuccess={responseMessage}
        onError={errorMessage}
        theme={"outline"}
        text={"signin"}
        logo_alignment={"left"}
        size={"medium"}
        shape={"pill"}
        width={"200px"}
      />
      {/* <div onClick={responseMessage}>xxx</div> */}
    </>
  );
};

export { MyGoogleLoginButton };
