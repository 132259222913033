import React, { useEffect, useState } from "react";
import PageComponentProps from "interfaces/pagecomponentprops.interface";
import { useResource } from "hook/useResource";
import { logger } from "hoc/logger";

import { MyNavBt } from "components/general/MyNavBt";
import Typography from '@mui/material/Typography'
import { Box, Stack } from '@mui/material'
import { ComponentCommonTools } from 'tools/componentcommontools'
import Grid from '@mui/material/Grid';

import TeacherService from "services/teacher/teacher.service";

import { useAuth } from "hook/useAuth";

import { MyButton } from 'components/general/MyButton';
import ValidateTools from 'tools/validate.tools';
import MyTextField from 'components/form/mytextfield';
import { CommonTools } from "tools/commontools";
import { CircularLoading } from 'components/general/CircularLoading';


import MyCheckboxField from "components/form/mycheckboxfield";
import RequiredValidator from "validators/required.validator";
import { CreateCourseFirstStepDto } from "dto/course/course.dto";
import CourseService from "services/course/course.service";
import { CourseCategoryMultiAutocomplete } from "components/elements/Autocomplete/CourseCategoryMultiAutocomplete";
import { CourseTypeSelect } from "components/elements/Select/CourseTypeSelect";

const courseService = new CourseService();


const CreateCourseFirstStep: React.FC<PageComponentProps> = ({
    currentRoute,
    ...props
}) => {
    const { LL, } = useResource();
    const { teacher } = useAuth();

    const [loading, setLoading] = useState<boolean>(false);
    var [obj, setObj] = useState<CreateCourseFirstStepDto>(new CreateCourseFirstStepDto());


    const [isFormDisabled, setIsFormDisabled] = useState(true);

    const [validators, setValidators] = useState(false);
    const [vresults, setVResults] = useState<any>(false);


    const validateAll = () => {
        if (!obj) return;
        if (!validators) return;
        // if (vresults) return;

        obj.name = obj.name !== undefined ? obj.name : '';
        obj.idtypecourse = obj.idtypecourse !== undefined ? obj.idtypecourse : '';

        setObj(obj);

        let t = validateField("name", obj.name);
        // t = validateField("description", obj.description, t);
    };

    const validateField = (field: any, value: any, v?: any) => {
        v = (v) ? v : JSON.parse(JSON.stringify(vresults));
        if (v === false) v = {};
        v[field] = ValidateTools.validate(field, value, validators, vresults);
        setVResults(v);
        return v;
    };

    const processValidators = () => {
        let v: any = {
            name: [],
            // description: [],
        };

        v.name.push(new RequiredValidator('', ''));
        // v.description.push(new RequiredValidator(LL('Required field description', LabelTypes.LABEL_VALIDATION), ''));
        setValidators(v);
    };

    const checkLoading = () => {
        if (!loading) return;
        if (!obj) return;
        if (!validators) return;
        validateAll();
        setLoading(false);
    };

    useEffect(() => {
        processValidators();
    }, []);

    useEffect(() => {
        checkLoading();
    }, [obj, validators]);

    useEffect(() => {
        checkIsFormDisabled();
    }, [vresults]);


    const setObjField = (field: any, value: any) => {
        let t = JSON.parse(JSON.stringify(obj));
        t[field] = value;
        setObj(t);

        validateField(field, value);
    };

    const checkIsFormDisabled = () => {
        var rez = ValidateTools.vForm(vresults, 'name');
        if (
            !obj ||
            !obj.idscoursecategory ||
            obj.idscoursecategory.length === 0 ||
            !obj.idtypecourse
        ) rez = true;
        setIsFormDisabled(rez);
    };

    const handleSubmit = (event: any) => {
        event.preventDefault();
        if (isFormDisabled) return false;
        if (teacher && teacher.id) {
            obj.idteacher = teacher.id;
            courseService.addFirstStep(
                ComponentCommonTools.handleSuccessAndCreateRouteWithId,
                { errorRoute: '/teacherinterface/course', route: '/teacherinterface/editcourse' },
                obj
            );
        }
    };



    return !loading ? (
        <Box className="container" maxWidth='md'>
            <Typography variant="h2" textAlign='center' my={4}>
                {LL("AddBlock")}
            </Typography>
            <Box>
                <form onSubmit={handleSubmit}>
                    <Typography variant="h6" mb={1}>{LL('Numele cursului')}</Typography>
                    <MyTextField
                        tabIndex={0}
                        fullWidth
                        id="name"
                        name="name"
                        variant="standard"
                        className="form-input"
                        color="primary"
                        _label={LL("Name")}
                        size="small"
                        value={obj.name}
                        setObj={setObjField}
                        _vresults={vresults && vresults.name ? vresults.name : false}
                    />
                    <Box mt={3}>
                        <Typography variant="h6" mb={1}>{LL('Categoria din care face parte')}</Typography>
                        <CourseCategoryMultiAutocomplete
                            setObj={setObjField}
                            obj={obj}
                        /></Box>
                    <Box sx={{ mt: 3, ".MuiFormLabel-root": { display: 'none' }, }}>
                        <Typography variant="h6" mb={1}>
                            {LL('Tip curs')}
                        </Typography>
                        <CourseTypeSelect
                            tabIndex={2}
                            id="idtypecourse"
                            name="idtypecourse"
                            variant="standard"
                            className="form-select"
                            color="primary"
                            value={obj.idtypecourse}
                            field="idtypecourse"
                            setObj={setObjField}
                            obj={obj}
                            _defaultValue={obj.idtypecourse}
                        /></Box>
                    <Box mt={4} textAlign='center'>
                        <MyButton
                            tabIndex={5}
                            disabled={isFormDisabled}
                            color="primary"
                            className="btn"
                            variant="contained"
                            size="large"
                            type="submit"
                            children={LL("Bt_Submit")}
                        /></Box>
                </form>
            </Box>
        </Box>
    ) : (
        <CircularLoading />
    );
};

export { CreateCourseFirstStep };
