import React, { useEffect, useState } from "react";
import { useResource } from "hook/useResource";
import { logger } from "hoc/logger";
import MenuService from "services/menu/menu.service";
import MenuLevelProps from "interfaces/menulevelprops.interface";
import { MenuDto } from "dto/menu/menu.dto";
import { RouteTools } from "tools/routetools";
import RequestListDTO from "dto/app/requestlist.dto";
import { Status } from "tools/status";
import RequestFilterDTO from "dto/app/requestfilter.dto";
import ResultListDTO from "dto/app/resultlist.dto";
import { Types } from "tools/types";
import MenuItemProps from "interfaces/menuitemprops.interface";
import { MyNavBt } from "components/general/MyNavBt";
import { BottomMenuLevel } from "./BottomMenuLevel";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from '@mui/material/Grid';

const BottomMenuItem: React.FC<MenuItemProps> = ({
  currentRoute,
  level,
  obj,
  hasChildren,
  ...props
}) => {
  const [nextLevel, setNextLevel] = useState<number>(1);
  const [maxLevel, setMaxLevel] = useState<number>(1);

  useEffect(() => {
    let ml: number = parseInt(process.env.REACT_APP_MAXLEVEL_BOTTOMMENU ?? "1");
    ml = isNaN(ml) || ml == undefined ? 1 : ml;
    setMaxLevel(ml);
  }, []);

  useEffect(() => {
    setNextLevel(level + 1);
  }, [level]);

  const processBT = () => {
    if (!obj) return;

    if ( !obj.fullurl) {
      return <>{obj.name}</>;
    }

    return <MyNavBt href={obj.fullurl}>{obj.name}</MyNavBt>;
  };

  const processDetail = () => {
    
    if (!obj) return <></>;

    return <Box>{processBT()}</Box>;
  };

  const processChilds = () => {
    if(!hasChildren) return <></>;
    if (nextLevel > maxLevel) return <></>;
    if (!obj) return <></>;

    return (
      <Box>
        <BottomMenuLevel
          currentRoute={currentRoute}
          level={nextLevel}
          idparent={obj.id ?? ""}
        />
      </Box>
    );
  };

  

  return (
    <Grid item lg={3} xs={12}>
      {processDetail()}
      {processChilds()}
    </Grid>
  );

};

export { BottomMenuItem };
