import React, { useEffect, useState } from "react";
import PageComponentProps from "interfaces/pagecomponentprops.interface";
import { useResource } from "hook/useResource";
import { logger } from "hoc/logger";
import SocialService from "services/user/social.service";
import { CommonTools } from 'tools/commontools';
import RequestListDTO from "dto/app/requestlist.dto";
import ResultListDTO from "dto/app/resultlist.dto";
import SocialDto from "dto/user/social.dto";
import Box from '@mui/material/Box';
import { useAuth } from 'hook/useAuth';
import RequestFilterDTO from "dto/app/requestfilter.dto";
import { Typography } from "@mui/material";
import MyTextField from "components/form/mytextfield";
import { ComponentCommonTools } from "tools/componentcommontools";
import { PopulateTypeDto } from 'dto/nomenclature/populatetype.dto';
import { MyButton } from "components/general/MyButton";
import { MyBreadcrumbs } from "components/elements/MyBreadcrumbs/MyBreadcrumbs";
import TypeSocialService from "services/nomenclature/typesocial.service";
import { CircularLoading } from "components/general/CircularLoading";

const socialService = new SocialService();
const typeSocialService = new TypeSocialService();

const AddSocialUser: React.FC<PageComponentProps> = ({
    currentRoute,
    ...props
}) => {
    const { LL } = useResource();
    const { user } = useAuth();
    const { saveCache, getCache } = useResource();
    const _id = user !== undefined ? user.id : '';
    const [id, setId] = useState<string | undefined>(_id);
    const [data, setData] = useState<ResultListDTO>(new ResultListDTO());
    const [rows, setRows] = useState<Array<SocialDto>>(new Array<SocialDto>());

    const [total, setTotal] = useState(0);
    const [totalPage, setTotalPage] = useState(0);

    const [responseParams, setResponseParams] = useState<RequestListDTO>(
        new RequestListDTO()
    );

    const [reqList, setReqList] = useState<RequestListDTO>(new RequestListDTO());

    const [loading, setLoading] = useState<boolean>(true);

    const [dataType, setDataType] = useState<ResultListDTO>(new ResultListDTO());
    const [rowsType, setRowsType] = useState<Array<PopulateTypeDto>>(new Array<PopulateTypeDto>());

    const [totalType, setTotalType] = useState(0);
    const [totalPageType, setTotalPageType] = useState(0);

    const [responseParamsType, setResponseParamsType] = useState<RequestListDTO>(
        new RequestListDTO()
    );

    const [reqListType, setReqListType] = useState<RequestListDTO>(new RequestListDTO());

    const [loadingType, setLoadingType] = useState(false);
    //------------------------------------------------------------------------
    const loadObjectsType = (dataType: ResultListDTO) => {
        CommonTools.processListLoadObjects(
            dataType,
            setDataType,
            setRowsType,
            setTotalType,
            setTotalPageType,
            setResponseParamsType
        );
    };

    const getListType = () => {
        setLoadingType(true);
        typeSocialService.getList(loadObjectsType, {}, reqListType);
    };
    const getReqListType = () => {
        let reqList = typeSocialService.getDefaultRequestListSelect(100);
        setReqListType(reqList);
    };
    useEffect(() => {
        if (CommonTools.atLeastOneFieldDefined(reqListType)) {
            getListType();
        }
    }, [reqListType]);
    const checkIsLoadingType = () => {
        const load: boolean = CommonTools.checkIsLoading(
            reqListType,
            responseParamsType,
            dataType,
            totalType,
            totalPageType,
            rowsType
        );
        setLoadingType(load);
    };
    useEffect(() => {
        checkIsLoadingType();

    }, [dataType, rowsType, reqListType, totalType, totalPageType]);
    //------------------------------------------------------------------------
    const [objects, setObjects] = useState<Array<SocialDto>>(new Array<SocialDto>());
    const [loadingObjects, setLoadingObjects] = useState<boolean>(true);

    const processObjects = () => {
        setLoadingObjects(true)
        if (rows === undefined || rowsType === undefined || id === '') return;
        if (rowsType.length === 0) return;
        let arr: SocialDto[] = new Array<SocialDto>();
        arr = [];
        logger('rowsType',rowsType)
        rowsType.forEach((item) => {
            const obj = new SocialDto();
            obj.iduser = id;
            obj.idtypesocial = item.id;
            obj.link = '';
            obj.typesocial = item;
            if (rows.length > 0) {
                const correspondingRow = rows.find(row => row.idtypesocial === item.id);
                if (correspondingRow) {
                    obj.id = correspondingRow.id;
                    obj.link = correspondingRow.link;
                }
            }

            arr.push(obj);
        });

        setObjects(arr);
    };

    useEffect(() => {
        processObjects();
    }, [rowsType]);

   

    useEffect(() => {
        if (objects && objects.length > 0) setLoadingObjects(false);
        logger('objects', objects)
    }, [objects]);
    //--------------------------------------------------------------
    const loadObjects = (data: ResultListDTO) => {
        CommonTools.processListLoadObjects(
            data,
            setData,
            setRows,
            setTotal,
            setTotalPage,
            setResponseParams
        );
    };
    const getList = () => {
        setLoading(true);

        socialService.getList(loadObjects, {}, reqList);
    };

    const getReqList = () => {
        let reqList = socialService.getDefaultRequestListSelect(100);
        const f = new RequestFilterDTO();
        f.field = "iduser";
        f.values = [];
        if (id) f.values.push(id);
        reqList.filters = []
        reqList.filters.push(f);

        setReqList(reqList);
    };

    useEffect(() => {
        getReqList();
        getReqListType();
    }, [currentRoute]);

    useEffect(() => {
        if (CommonTools.atLeastOneFieldDefined(reqList)) {
            getList();
        }
    }, [reqList]);

    useEffect(() => {
        checkIsLoading();
        logger("Rows", rows)
    }, [data, rows, reqList, total, totalPage]);

    const checkIsLoading = () => {
        const load: boolean = CommonTools.checkIsLoading(
            reqList,
            responseParams,
            data,
            total,
            totalPage,
            rows
        );
        setLoading(load);
    };

    const handleLinkChange = (index: number, value: string) => {
        const newObjects = [...objects];
        newObjects[index].link = value;
        setObjects(newObjects);
    };

    useEffect(() => {

        if (user !== undefined) {
            setId(user.id)
        }
    }, [user])

    const processSocialItem = (obj: SocialDto, index: number) => {
        if (!obj) return <></>

        return (
            <Box key={index} sx={{my:2}}>
                <Typography variant="h6">{ComponentCommonTools.processObjectFieldOfObject(obj, 'typesocial', 'name')}</Typography>
                <MyTextField
                    fullWidth
                    id="link"
                    name="link"
                    variant="standard"
                    className="form-input"
                    color="primary"
                    _label={LL("link")}
                    size="small"
                    value={obj.link}
                    setObj={(field: any, value: any) => handleLinkChange(index, value)}
                    _vresults={
                        undefined
                    }
                />
            </Box>
        )
    };

    const processSocialList = () => {

        if (!objects.length) return <></>;

        return (
            <Box>
                {objects.map((item: SocialDto, i: number) => {
                    return processSocialItem(item, i);
                })}
            </Box>
        );
    };

    const handleSuccessAddAndEditStayOnPage = (result:any,params:any) => {
        if(result.err) return;
        getList();
        getListType();
    }
    const handleSubmit = (event: any) => {
        event.preventDefault();
        logger('objectsbeforeadd', objects)
        if (objects !== undefined) {
            
            socialService.addArray(handleSuccessAddAndEditStayOnPage, {}, objects);
        }
    };

    return !loading && !loadingType && !loadingObjects ? (<>
        <Box maxWidth='sm' mx='auto'>

            <form onSubmit={handleSubmit} >
                {processSocialList()}
                <Box textAlign='center'>
                <MyButton
                    tabIndex={0}
                    disabled={false}
                    color="primary"
                    variant="contained"
                    size="large"
                    className="btn"
                    type="submit"
                    sx={{ mx: 2, my:2 }}
                    children={LL("Bt_Submit")}
                /></Box>
            </form>
        </Box>
    </>) : (<CircularLoading />);
};

export { AddSocialUser };
