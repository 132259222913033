import React, { useEffect, useState } from "react";
import { useResource } from "hook/useResource";
import { logger } from "hoc/logger";
import TeacherService from "services/teacher/teacher.service";
import MenuLevelProps from "interfaces/menulevelprops.interface";
import { TeacherDto } from "dto/teacher/teacher.dto";
import { RouteTools } from "tools/routetools";
import RequestListDTO from "dto/app/requestlist.dto";
import { Status } from "tools/status";
import RequestFilterDTO from "dto/app/requestfilter.dto";
import ResultListDTO from "dto/app/resultlist.dto";
import { Types } from "tools/types";
import MenuItemProps from "interfaces/menuitemprops.interface";
import { MyNavBt } from "components/general/MyNavBt";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { MyButton } from "components/general/MyButton";
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import { ComponentCommonTools } from 'tools/componentcommontools'
import Typography from "@mui/material/Typography";
import Rating from '@mui/material/Rating';
import Avatar from "@mui/material/Avatar";
import { MyRatingReadOnly } from "components/elements/MyRating/MyRatingReadOnly";
import { Stack } from "@mui/system";
import { TeacherTypeIcon } from "./TeacherTypeIcon";
import { MyAvatar } from "components/elements/MyAvatar/MyAvatar";
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import { SvgIcon } from "@material-ui/core";

type TeacherListCardProps = {
    obj: TeacherDto;

}

const TeacherListCard: React.FC<TeacherListCardProps> = ({
    obj,
    ...props
}) => {
    const { LL } = useResource();

    const processRating = () => {
        if (!obj) return <></>;
        if (!obj.hasOwnProperty('rating') || !obj.hasOwnProperty('numberoftestimonials')) return <MyRatingReadOnly rating={0} count={0} />;
        if (!obj.rating || !obj.numberoftestimonials) return <MyRatingReadOnly rating={0} count={0} />;
        return <MyRatingReadOnly rating={obj.rating} count={obj.numberoftestimonials} />

    }

    const processButton = () => {
        if (!obj) return <></>;
        if (!obj.id) return <></>;
        let fullurl = '';
        if (obj.hasOwnProperty('fullurl') && obj.fullurl) fullurl = obj.fullurl;
        return (
            <MyNavBt className="btn" href={fullurl} children={LL('BT_Show_Profile')} />
        )
    }
    
    const processAvatar = () => {
        if (!obj) return <></>;
        let name = ''
        let surname = ''
        if (obj.hasOwnProperty('name') && obj.name) name = obj.name;
        if (obj.hasOwnProperty('surname') && obj.surname) surname = obj.surname;
        // return <Avatar>{processAvatarName()}</Avatar>
        return <MyAvatar obj={obj} name={name} surname={surname} className="badgeImg" sx={{ mr: 1,}} />
    }

    const getNumberOfCourses = () => {
        if (!obj) return 0;
        if (!obj.hasOwnProperty('numberofcourses')) return 0;
        if (!obj.numberofcourses) return 0;

        return obj.numberofcourses;
    }

    const getNumberOfStudents = () => {
        if (!obj) return 0;
        if (!obj.hasOwnProperty('numberofstudents')) return 0;
        if (!obj.numberofstudents) return 0;
        return obj.numberofstudents;
    }
    const processNumberOfCourses = () => {
        if (!obj) return <>
        </>;

        return <Typography className="number courses">{LL('Number_of_courses: ')}<b>&nbsp;{getNumberOfCourses()}</b></Typography>
    }
    const processNumberOfStudents = () => {
        if (!obj) return <></>;

        return <Typography className="number students">{LL('Number_of_students: ')}<b>&nbsp;{getNumberOfStudents()}</b></Typography>
    }

    const processTeacherStatus = () => {
        if (!obj) return <></>;
        return <TeacherTypeIcon obj={obj} />
    }
    const processItem = () => {
        if (!obj) return <></>;

        return (
            
                <Card className="card">
                    <Box p={2} sx={{display:'flex'}}>
                        <Box>{processAvatar()}</Box>
                        <Box>
                            <Stack direction="row" spacing={1}>
                                {processTeacherStatus()}
                                <Typography className="name">{ComponentCommonTools.processObjectField(obj, 'name')}</Typography>
                                <Typography className="name">{ComponentCommonTools.processObjectField(obj, 'surname')}</Typography>
                            </Stack>
                            <Typography  className="proffesion">{ComponentCommonTools.processObjectFieldOfObject(obj, 'typediscipline', 'name')}</Typography>
                            <Typography>{processRating()}</Typography>
                        </Box>
                    </Box>
                    <Box px={2} pb={2}>
                        <Stack direction="row" spacing={2} sx={{justifyContent:"space-between"}}>
                            <Stack direction="column" spacing={1}>
                                {processNumberOfStudents()}
                                {processNumberOfCourses()}
                            </Stack>
                            <Typography component="div">
                                {processButton()}
                            </Typography>
                        </Stack>
                    </Box>
                </Card>
         
        )
    };


    return processItem();


};

export { TeacherListCard };
