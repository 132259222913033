import React, { useState, useEffect } from "react";
import PageComponentProps from "interfaces/pagecomponentprops.interface";
import { useResource } from "hook/useResource";

import { Box, Skeleton, Typography } from "@mui/material";

import { EditCourseGallery } from "./EditCourseGallery";
import { EditCourseVideo } from "./EditCourseVideo";


const EditCourseMedia: React.FC<PageComponentProps> = ({ currentRoute, ...props }) => {
    const { LL, _idlanguage } = useResource();
    
    return (<>
        <Box>
            <EditCourseGallery currentRoute={currentRoute}/>
            <EditCourseVideo currentRoute={currentRoute}/>
        </Box>
    </>);
};

export { EditCourseMedia };
