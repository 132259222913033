import React from "react";
import PageComponentProps from "interfaces/pagecomponentprops.interface";
import { useResource } from "hook/useResource";

const Forbidden: React.FC<PageComponentProps> = ({ currentRoute, ...props }) => {
  const { LL } = useResource();
  return <>{LL('Forbidden')}</>;
};

export { Forbidden };
