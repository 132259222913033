import ValidateTools from "tools/validate.tools";
import { LabelTypes } from "tools/labeltypes";
import RequiredValidator from "validators/required.validator";
import RequestListDTO from "dto/app/requestlist.dto";
import ResultObjectDTO from "dto/app/resultobject.dto";
import { logger } from "hoc/logger";
import { GalleryDto } from "dto/gallery/gallery.dto";
import { FileTools } from "tools/filetools";
import { VideoDto } from "dto/video/video.dto";
import RequestFilterDTO from "dto/app/requestfilter.dto";
import MessageDto from "dto/app/message.dto";
import { Types } from "./types";
import { CourseEnrollmentDto } from "dto/course/courseenrollment.dto";
import { CourseDto } from "dto/course/course.dto";

export class CommonTools {
  public static isEmpty = (obj: object) => {
    return Object.keys(obj).length === 0 && obj.constructor === Object;
  };

  public static processObjectField(
    obj: any,
    fields: string[],
    type?: number,
    numberAfterPoint?: number
  ): string {
    if (numberAfterPoint === undefined || numberAfterPoint === null)
      numberAfterPoint = 2;
    if (!type) type = Types.FIELD_TYPE_STRING;

    let currentObj = obj;

    for (const field of fields) {
      if (
        !currentObj ||
        typeof currentObj !== "object" ||
        !currentObj.hasOwnProperty(field)
      ) {
        return "";
      }
      currentObj = currentObj[field];
    }

    if (typeof currentObj === "object" && currentObj !== null) return "";

    if (currentObj == null || currentObj == undefined) return "";
    if (type == Types.FIELD_TYPE_NUMBER && typeof currentObj === "number") {
      return currentObj.toFixed(numberAfterPoint);
    }

    return currentObj;
  }
  
  public static processListLoadObjects = (
    obj: any,
    setData: any,
    setRows: any,
    setTotal: any,
    setTotalPage: any,
    setResponseParams: any
  ) => {
    setData(obj);

    if (!obj) return;

    if (!obj.err) {
      const objects = obj.objects ? obj.objects : [{}];
      const total = obj.total !== undefined ? obj.total : -1;
      const totalPage = obj.totalpages !== undefined ? obj.totalpages : -1;
      const reqInfo = obj.requestinfo ? obj.requestinfo : {};

      setRows(objects);
      setTotal(total);
      setTotalPage(totalPage);
      setResponseParams(reqInfo);
    }
  };

  public static getTabValueUserTab = (
    currentRoute: any,
    exceptValues?: Array<string>,
    defaultValue?: string
  ) => {
    if (
      currentRoute &&
      currentRoute.hasOwnProperty("+") &&
      currentRoute.historystate.hasOwnProperty("tabValue") &&
      currentRoute.historystate.tabValue
    ) {
      if (exceptValues && exceptValues.length > 0) {
        if (exceptValues.includes(currentRoute.historystate.tabValue)) {
          return defaultValue ? defaultValue : "generalInfo";
        }
      }
      return currentRoute.historystate.tabValue;
    }
    return "generalInfo";
  };

  public static getAnchor = (currentRoute: any, defaultValue: string) => {
    if (!currentRoute) return defaultValue;
    if (!currentRoute.hasOwnProperty("anchor")) return defaultValue;
    if (!currentRoute.anchor) return defaultValue;
    return currentRoute.anchor;
  };

  public static getTabValueUrlAnchor = (
    currentRoute: any,
    defaultUrlAnchor?: string
  ) => {
    if (!defaultUrlAnchor) defaultUrlAnchor = "generalInfo";
    if (!currentRoute) return defaultUrlAnchor;
    if (!currentRoute.hasOwnProperty("url") && !currentRoute.url)
      return defaultUrlAnchor;
    const url: string = currentRoute.url;
    const urlParts = url.split("#");
    if (urlParts.length === 2) return urlParts[1];
    return defaultUrlAnchor;
  };

  public static setTabValueUrlAnchor = (currentRoute: any, value: string) => {
    if (
      !currentRoute &&
      !currentRoute.hasOwnProperty("url") &&
      !currentRoute.url
    )
      return "";
    const url: string = currentRoute.url;
    const urlParts = url.split("#");
    const newUrl = urlParts[0] + "#" + value;
    return newUrl;
  };

  public static getCreateTeacher = (currentRoute: any) => {
    if (
      currentRoute &&
      currentRoute.hasOwnProperty("historystate") &&
      currentRoute.historystate.hasOwnProperty("createTeacher")
    )
      return true;
    return false;
  };

  public static checkIsLoading = (
    reqList: any,
    responseParams: any,
    data: any,
    total: any,
    totalPage: any,
    rows: any
  ) => {
    if (!data) return true;
    if (total === -1) return true;
    if (totalPage === -1) return true;
    if (rows === undefined) return true;
    if (Object.keys(responseParams).length === 0) return true;
    return CommonTools.checkRequestAndParams(reqList, responseParams);
  };

  public static checkRequestAndParams = (req: any, res: any) => {
    if (req.page?.toString() !== res.page?.toString()) return true;
    if (req.onpage?.toString() !== res.onpage?.toString()) return true;
    if (!CommonTools.arraysAreEqual(req.sortcriteria, res.sortcriteria))
      return true;
    // if (!CommonTools.arraysAreEqual(req.filters, res.filters)) return true;
    return false;
  };

  public static prepareLabeldentifier = (str: string): string => {
    str = str ?? "";

    str = str.replace(/[^\w\d]/gi, "-");
    str = str.replace(/(-+)/gi, "-");

    str = str.replace(/^[-]/gi, "");
    str = str.replace(/[-]$/gi, "");

    str = str.toString().toLowerCase();

    str = str ?? "-";
    return str;
  };

  public static arraysAreEqual = (arr1: any, arr2: any) => {
    if (arr1 == undefined && arr2 == undefined) return true;
    if (arr1 == undefined || arr2 == undefined) return false;

    if (arr1.length !== arr2.length) return false;

    const sortedArr1 = arr1.map(JSON.stringify).sort();
    const sortedArr2 = arr2.map(JSON.stringify).sort();

    for (let i = 0; i < sortedArr1.length; i++) {
      if (sortedArr1[i] !== sortedArr2[i]) return false;
    }

    return true;
  };

  public static atLeastOneFieldDefined = (obj: any) => {
    for (const key in obj) {
      if (obj[key] !== undefined) {
        return true;
      }
    }
    return false;
  };

  public static generateMainUrl = (input: any) => {
    if (typeof input !== "string") return "";
    const url = "/" + input.toLowerCase();
    return url;
  };
  public static generateDetailUrl = (input: any, id: string | undefined) => {
    if (typeof input !== "string" && id === undefined) return "";

    const mainUrl = this.generateMainUrl(input);
    const url = mainUrl + "/" + id;
    return url;
  };

  public static generateAddUrl = (input: any) => {
    if (typeof input !== "string") return "";

    const mainUrl = this.generateMainUrl(input);
    const url = mainUrl + "/add";
    return url;
  };

  public static generateEditUrl = (input: any, id?: string) => {
    if (typeof input !== "string") return "";

    const mainUrl = this.generateMainUrl(input);
    let url = mainUrl + "/edit";
    if (id !== undefined) url = url + "/" + id;
    return url;
  };

  public static generateMainUrlSpecial = (currentRoute: any) => {
    const path = currentRoute._paths;

    const url = "/" + path[0] + "/" + path[1] + "/" + path[2];

    return url;
  };

  public static generateParentMainUrlSpecial = (currentRoute: any) => {
    const path = currentRoute._paths;

    const url = "/" + path[0];

    return url;
  };

  public static generateEditUrlSpecial = (
    id?: string,
    parentType?: string,
    parentId?: string,
    specialtype?: string
  ) => {
    return (
      "/" +
      parentType +
      "/" +
      parentId +
      "/" +
      specialtype +
      "/" +
      "edit" +
      "/" +
      id
    );
  };

  public static generateDetailUrlSpecial = (
    id?: string,
    parentType?: string,
    parentId?: string,
    specialtype?: string
  ) => {
    return "/" + parentType + "/" + parentId + "/" + specialtype + "/" + id;
  };

  public static generateAddUrlSpecial = (currentRoute: any) => {
    const path = currentRoute._paths;

    const url = "/" + path[0] + "/" + path[1] + "/" + path[2] + "/add";

    return url;
  };

  public static prepareObjectForUpdate = (obj: any, mainObj: any) => {
    if (!obj) return;
    if (!mainObj) return;

    const mainUrl = this.generateMainUrl(mainObj);
    const object: any = {
      id: obj.id,
      obj: obj,
      _mainurl: mainUrl,
    };
    return object;
  };

  public static prepareObjectForAddChild = (
    obj: any,
    mainObj: any,
    field: any
  ) => {
    if (!obj) return;
    if (!field) return;
    if (!mainObj) return;

    const mainUrl = this.generateMainUrl(mainObj);
    const object: any = {
      id: obj[field],
      _mainurl: mainUrl,
    };
    return object;
  };

  public static handleSuccessAddAndEditStayOnPage = (
    result: any,
    cbparameters: any
  ) => {
    if (cbparameters.setLoading) {
      if (!result.err) {
        cbparameters.setLoading(false);
      }
    }
    if (cbparameters.setObjectWasChanged) {
      if (!result.err) {
        cbparameters.setObjectWasChanged(CommonTools.generateRandomString(5));
      }
    }
    if (cbparameters.setObjectCourseHasChanged) {
      if (!result.err) {
        cbparameters.setObjectCourseHasChanged(
          CommonTools.generateRandomString(5)
        );
      }
    }
  };

  public static getIdFromPath = (
    currentRoute: any,
    mainObject: string,

    mainPathPosition?: number,
    idPathPosition?: number
  ) => {
    console.log("getIdFromPath", currentRoute);
    if (!mainPathPosition) mainPathPosition = 0;
    if (!idPathPosition) idPathPosition = 1;
    if (!currentRoute) return "";

    if (!currentRoute.hasOwnProperty("_paths")) return "";
    if (!Array.isArray(currentRoute._paths)) return "";
    if (currentRoute._paths.length === 0) return "";
    if (
      currentRoute._paths.length < mainPathPosition ||
      currentRoute._paths.length < idPathPosition
    )
      return "";
    const paths = currentRoute._paths;
    const mainObj = paths[mainPathPosition];
    if (mainObj !== mainObject) return "";
    return paths[idPathPosition];
  };

  public static getGalleryObjectLogo = (objects: Array<GalleryDto>) => {
    if (!objects) return null;
    if (!Array.isArray(objects)) return null;
    if (objects.length === 0) return null;
    if (objects.length === 1) return objects[0];
    const logo = objects.find((obj) => obj.isdefault === true);
    if (logo) return logo;
    return objects[0];
  };

  public static getMediaDefault = (objects: Array<VideoDto>) => {
    if (!objects) return null;
    if (!Array.isArray(objects)) return null;
    if (objects.length === 0) return null;
    if (objects.length === 1) return objects[0];
    const defaultObj = objects.find((obj) => obj.isdefault === true);
    if (defaultObj) return defaultObj;
    return objects[0];
  };

  public static generateListUrl = (mainObject: any, currentRoute: any) => {
    if (typeof currentRoute != "object") {
      return CommonTools.generateMainUrl(mainObject);
    }

    // if (currentRoute.url)
    // {
    //   return currentRoute.url;
    // }

    if (
      currentRoute.historystate == undefined ||
      typeof currentRoute.historystate != "object"
    ) {
      return CommonTools.generateMainUrl(mainObject);
    }
    if (currentRoute.historystate.listUrl == undefined) {
      return CommonTools.generateMainUrl(mainObject);
    }

    return currentRoute.historystate.listUrl;
  };

  public static userDetailUrl = (obj: any) => {
    if (!obj) return "";
    const url = "/user/" + obj.iduser;
    return url;
  };

  public static addToRequestFilter = (
    obj: RequestListDTO,
    field: string,
    value: any
  ): RequestListDTO => {
    const filters: RequestFilterDTO[] = [];

    obj.filters = obj.filters ?? [];

    for (var i in obj.filters) {
      if (obj.filters[i].field == field) continue;
      filters.push(obj.filters[i]);
    }

    const f = new RequestFilterDTO();
    f.field = field;
    f.values = Array.isArray(value) ? value : [value];

    filters.push(f);

    obj.filters = filters;

    return obj;
  };

  public static generateGalleryUrl = (input: any, id?: string) => {
    if (typeof input !== "string") return "";

    const mainUrl = this.generateMainUrl(input);
    let url = mainUrl;
    url += "/";
    url += id !== undefined ? id : "-";
    url += "/gallery";

    return url;
  };

  public static generateAttachmentUrl = (input: any, id?: string) => {
    if (typeof input !== "string") return "";

    const mainUrl = this.generateMainUrl(input);
    let url = mainUrl;
    url += "/";
    url += id !== undefined ? id : "-";
    url += "/attachment";

    return url;
  };

  public static generateVideoUrl = (input: any, id?: string) => {
    if (typeof input !== "string") return "";

    const mainUrl = this.generateMainUrl(input);
    let url = mainUrl;
    url += "/";
    url += id !== undefined ? id : "-";
    url += "/video";

    return url;
  };

  public static setFileUrlsGallery = (
    obj: GalleryDto,
    setImgUrl: any,
    setFullUrl: any,
    w?: number,
    h?: number
  ) => {
    if (w === undefined) w = 200;
    if (h === undefined) h = 200;
    if (obj === undefined) return;

    if (obj.fileInfoObj == undefined) {
      setImgUrl("");
      setFullUrl("");
      return;
    }

    const imgUrl = FileTools.getFileImage(
      obj.fileInfoObj._id,
      obj.fileInfoObj.name,
      w,
      h
    );
    const aUrl = FileTools.getFileUrl(
      obj.fileInfoObj._id,
      obj.fileInfoObj.name
    );

    setImgUrl(imgUrl);
    setFullUrl(aUrl);
  };

  public static areObjectsEqual(objA: any, objB: any) {
    // Check if both values are objects
    if (typeof objA !== "object" || typeof objB !== "object") {
      return objA == objB; // For non-objects, perform a simple comparison
    }

    // Get the keys of both objects
    const keysA = Object.keys(objA);
    const keysB = Object.keys(objB);

    // Check if the number of keys is the same
    if (keysA.length != keysB.length) {
      return false;
    }

    // Check if the values of each key are equal (recursively)
    for (const key of keysA) {
      if (!CommonTools.areObjectsEqual(objA[key], objB[key])) {
        return false;
      }
    }

    // If all checks passed, the objects are equal
    return true;
  }

  public static getFileUrlsGallery = (
    obj: GalleryDto,
    w?: number,
    h?: number
  ) => {
    if (w === undefined) w = 200;
    if (h === undefined) h = 200;
    if (obj === undefined) return;

    if (obj.fileInfoObj == undefined) {
      return {
        imgUrl: "",
        aUrl: "",
      };
    }

    const imgUrl = FileTools.getFileImage(
      obj.fileInfoObj._id,
      obj.fileInfoObj.name,
      w,
      h
    );
    const aUrl = FileTools.getFileUrl(
      obj.fileInfoObj._id,
      obj.fileInfoObj.name
    );

    // setImgUrl(imgUrl);
    // setFullUrl(aUrl);
    return {
      imgUrl: imgUrl,
      aUrl: aUrl,
    };
  };

  public static isExternalUrl = (url: string | undefined): boolean => {
    if (url == undefined) return false;

    try {
      const parsedUrl = new URL(url, window.location.href);
      // Compare the hostname of the URL with the current page's hostname
      return parsedUrl.hostname !== window.location.hostname;
    } catch (error) {
      // Handle invalid URLs or URLs without a hostname
      console.error("Invalid URL:", url);
      return false;
    }
  };
  public static calculateNumberOfElementsShowOnCarousel = (
    defaultValue: number,
    totalCount: number
  ): number => {
    let result = 0;
    if (totalCount === 1) return 1;
    if (totalCount <= defaultValue) {
      result = totalCount - 1;
    } else {
      result = defaultValue;
    }
    return result;
  };

  public static roundToTenth(number: number) {
    return (Math.round(number * 10) / 10).toFixed(1);
  }

  public static addFilterRating(value: number) {
    const obj = new RequestFilterDTO();
    obj.field = "fromteacherrating";
    obj.values = [value.toString()];
    return obj;
  }

  public static createMessage(message: string, type: number, code: string) {
    const obj = new MessageDto();
    obj.message = message;
    obj.mestype = type;
    obj.code = code;
    return obj;
  }

  public static generateFullUrlSite = (url: string) => {
    if (!url) return "";
    if (!process.env.REACT_APP_WWW_SERVER) return "";
    const fullUrl = process.env.REACT_APP_WWW_SERVER + url;
    return fullUrl;
  };
  public static generateRandomString(length: number) {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let result = "";
    for (let i = 0; i < length; i++) {
      result += characters.charAt(
        Math.floor(Math.random() * characters.length)
      );
    }
    return result;
  }

  public static isUserInterface(user: any, userInterface: number) {
    if (!user) return false;
    if (userInterface === Types.TEACHER_INTERFACE) return true;
    return false;
  }

  public static handleCheckValue = (
    obj: any,
    setObj: any,
    field: string,
    value: any
  ) => {
    if (!obj) return;
    if (!field) return;
    if (!setObj) return;
    if (value === undefined) return;

    let t = JSON.parse(JSON.stringify(obj));

    t[field] = value;
    setObj(t);
  };

  public static getCurseEnrollmentUrl = (
    obj: CourseEnrollmentDto,
    id: string
  ) => {
    if (!obj) return "";
    if (!id) return "";
    if (!obj.hasOwnProperty("id")) return "";
    if (!obj.id) return "";

    return "/profile/courseenrollment/" + obj.id;
  };

  public static getClassroomUrl = (id: string) => {
    if (!id) return "";
    return "/teacherinterface/classroom/" + id;
  };
  public static generateUrlResource = (
    mainUrl: string,
    idLesson: string,
    parent: string,
    idResource: string
  ) => {
    if (!mainUrl) return "";
    if (!idLesson) return "";
    if (!parent) return "";
    if (!idResource) return "";
    return mainUrl + "/" + idLesson + "/" + parent + "/" + idResource;
  };
  public static generateUrlResourceLesson = (
    currentRoute: any,
    obj: any,
    parent: string
  ) => {
    console.log("generateUrlResourceLesson", currentRoute);
    if (!currentRoute) return "";
    if (
      !currentRoute.hasOwnProperty("_paths") &&
      !currentRoute._paths &&
      !Array.isArray(currentRoute._paths) &&
      currentRoute._paths.length < 4
    )
      return "";
    const currentUrl =
      "/" +
      currentRoute._paths[0] +
      "/" +
      currentRoute._paths[1] +
      "/" +
      currentRoute._paths[2] +
      "/" +
      currentRoute._paths[3];
    if (!obj) return "";
    if (!obj.hasOwnProperty("id") && !obj.id) return "";
    if (!parent) return "";
    const url = currentUrl + "/" + parent + "/" + obj.id;
    return url;
  };
}
