import React, { useState, useEffect } from "react";
import { useResource } from "hook/useResource";
import { AnswerDto } from "dto/course/answer.dto";
import RequestFilterDTO from "dto/app/requestfilter.dto";
import RequestListDTO from "dto/app/requestlist.dto";
import { Status } from "tools/status";
import AnswerService from "services/course/answer.service";
import ResultListDTO from "dto/app/resultlist.dto";
import { Paper, Box, IconButton, Skeleton, Typography, Stack, Card, CardContent } from "@mui/material";

import RequestSortCriteriaDTO from "dto/app/requestsortcriteria.dto";
import { MySortSelect } from "components/elements/Select/MySortSelect";
import { MyButton } from "components/general/MyButton";
import { LoadMoreButton } from "components/elements/MyButton/LoadMoreButton";
import AccessTimeIcon from '@mui/icons-material/AccessTime';

import { AttachmentDialog } from "components/attachment/AttachmentDialog";
import { GalleryDialog } from "components/gallery/GalleryDialog";
import { VideoDialog } from "components/video/VideoDialog";
import VideocamIcon from '@mui/icons-material/Videocam';
import CollectionsIcon from '@mui/icons-material/Collections';
import AttachFileIcon from '@mui/icons-material/AttachFile';

import { logger } from "hoc/logger";


import { useMessage } from "hook/useMessage";
import { CommonTools } from "tools/commontools";
import { ComponentCommonTools } from "tools/componentcommontools";
import { AnswerListItem } from "./AnswerListItem";


type AnswerListProps = {
    idQuestion: string;
    extend: boolean;
}

const answerService = new AnswerService();

const AnswerList: React.FC<AnswerListProps> = ({

    idQuestion,

    extend,
    ...props }) => {

    const { LL, CC } = useResource();
    const [isLoading, setIsLoading] = useState(true);
    const [objects, setObjects] = useState<Array<AnswerDto>>([]);


    const { registerDialog } = useMessage();

    const [objectWasChanged, setObjectWasChanged] = useState<string>('');

    const getList = () => {
        if (!extend) return;
        setIsLoading(true);
        const reqList = new RequestListDTO();
        reqList.onpage = parseInt(CC('DefaultOnPageAnswerList', 200));
        reqList.page = 1;
        reqList.filters = [];
        reqList.sortcriteria = [];

        const sortCriteria = new RequestSortCriteriaDTO();
        sortCriteria.field = "ordercriteria";
        sortCriteria.asc = true;
        reqList.sortcriteria.push(sortCriteria);

        let f: RequestFilterDTO;

        f = new RequestFilterDTO();
        f.field = "idquestion";
        f.values = [idQuestion];
        reqList.filters.push(f);


        answerService.getList(loadObjects, {}, reqList);
    };


    const loadObjects = (result: ResultListDTO) => {
        if (!result) return;
        if (!result.err) {
            const objects = result.objects ? result.objects : [];
            setObjects(objects);
        }
    }


    const checkLoading = () => {
        let load = false;
        if (!objects) load = true;

        setIsLoading(load);
    }

    useEffect(() => {
        getList();
    }, [idQuestion, extend, objectWasChanged]);

    useEffect(() => {
        checkLoading();

    }, [objects]);




    const processAnswerItem = (obj: AnswerDto, index: number) => {
        if (!obj) return <></>;
        if (!obj.id) return <></>;

        return (<Box key={index}>

            <AnswerListItem obj={obj} setObjectWasChanged={setObjectWasChanged} />
        </Box>);

    };


    const processAnswerList = () => {
        if (!objects.length) return <></>;

        return (
            <>
                {objects.map((item: AnswerDto, i: number) => {
                    return processAnswerItem(item, i);
                })}
            </>
        );
    };



    return isLoading ? <Skeleton variant='rectangular' /> : (
        <>

            {processAnswerList()}

        </>
    );
};

export { AnswerList };
