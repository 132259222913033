import Idto from 'interfaces/idto.interface';
import { Status } from 'tools/status';

export class TestDto implements Idto {

    id?: string;

    mintestscore?: number;
    maxtestscore?: number;
    name?: string;
    type?: number;
    status?: number;
    idcourse?: string;
    idlesson?: string | null;
    question?: any;
}

export class PostTestDto implements Idto {


    mintestscore?: number;
    maxtestscore?: number;
    name?: string;
    type?: number;
    status?: number;
    idcourse?: string;
    idlesson?: string | null;

    constructor() {
        this.status = Status.ACTIVE;
        this.mintestscore = 0;
    }
}