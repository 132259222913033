import React, { useEffect, useState } from "react";
import PageComponentProps from "interfaces/pagecomponentprops.interface";
import { CommonTools } from "tools/commontools";
import { CourseCategoryTopBar } from "components/course/coursecategory/CourseCategoryTopBar";
import { CourseTabsPanelBlock } from "components/course/course/CourseTabsPanelBlock";
import { PopularCategory } from "components/course/coursecategory/PopularCategory";
import { CourseList } from "components/course/course/CourseList";
import { CourseCategoryDto } from "dto/course/coursecategory.dto";

const CourseCategoryPage: React.FC<PageComponentProps> = ({ currentRoute, ...props }) => {

    const [idCategory, setIdCategory] = useState("");
    const [categoryObject, setCategoryObject] = useState<CourseCategoryDto| undefined>();
    useEffect(() => {

        setIdCategory(CommonTools.getIdFromPath(currentRoute, 'coursecategory'));

    }, [currentRoute]);

    return idCategory ?
        (<>
            <CourseCategoryTopBar currentRoute={currentRoute} idCategory={idCategory} setCategoryObject={setCategoryObject}/>
            <CourseTabsPanelBlock currentRoute={currentRoute}/>
            <PopularCategory currentRoute={currentRoute} idCategory={idCategory} />
            <CourseList currentRoute={currentRoute} idCategory={idCategory} categoryObject={categoryObject} />
        </>) : (<></>);
};

export { CourseCategoryPage };
