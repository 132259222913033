
import Idto from 'interfaces/idto.interface';

export class PostPricePolicyDto implements Idto {

    status?: number;
    ordercriteria?: number;
    idlanguage?: string;
    idpricepolicy?: string;
    
    name?: string;
    idtypelesson?: string;
    teacherstatus?: number;
    percentageteacher?: number;
    percentageplatform?: number;
}

export class PricePolicyDto implements Idto {
    
    id?: string;
    status?: number;
    ordercriteria?: number;
    idlanguage?: string;
    idpricepolicy?: string;
    language?: any;
    teacherstatus_name?: string;
    status_name?: string;
    name?: string;
    idtypelesson?: string;
    typelesson?: any;
    teacherstatus?: number;
    percentageteacher?: number;
    percentageplatform?: number;
}