
import PageComponentProps from "interfaces/pagecomponentprops.interface";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useResource } from "hook/useResource";
import { logger } from "hoc/logger";
import CourseService from "services/course/course.service";
import MenuLevelProps from "interfaces/menulevelprops.interface";
import { CourseDto } from "dto/course/course.dto";
import { RouteTools } from "tools/routetools";
import RequestListDTO from "dto/app/requestlist.dto";
import { Status } from "tools/status";
import RequestFilterDTO from "dto/app/requestfilter.dto";
import ResultListDTO from "dto/app/resultlist.dto";
import { Types } from "tools/types";

import { Loading } from "components/general/Loading";
import Skeleton from '@mui/material/Skeleton';
import { Box } from "@mui/material";
import { CourseCard } from "components/course/course/CourseCard";
import { Carousel } from "components/elements/Carousel/Carousel";
import { CommonTools } from "tools/commontools";
import { Typography } from "@mui/material";
const courseService = new CourseService();

const HomeCourseBlock: React.FC<PageComponentProps> = ({ currentRoute, ...props }) => {
    const { saveCache, getCache } = useResource();

    const [isLoading, setIsLoading] = useState(true);
    const [objects, setObjects] = useState<Array<CourseDto>>([]);
const { LL, CC } = useResource();
    const getList = async () => {
        const reqList = new RequestListDTO();
        reqList.onpage = 100;
        reqList.page = 1;
        reqList.filters = [];

        let f: RequestFilterDTO;

        f = new RequestFilterDTO();
        f.field = "status";
        f.values = [];
        f.values.push(Status.ACTIVE.toString());
        reqList.filters.push(f);




        const cd = getCache(reqList);
        logger("InfoCourseLevel getCache", cd);
        if (cd !== false) {
            setObjects(cd);
            setIsLoading(false);
            return;
        }

        setIsLoading(true);
        logger("InfoCourseLevel reqList", reqList);
        const data = await courseService.getListRecommendedHome(undefined, {}, reqList);

        const rez = !data || !data.objects ? [] : data.objects;
        logger("InfoCourseLevel data", data);
        setObjects(rez);
        setIsLoading(false);

        logger("InfoCourseLevel rez", rez);
        saveCache(rez, reqList);
    };



    useEffect(() => {
        getList();
    }, []);

    const processCourseItem = (obj: CourseDto, index: number) => {
        logger("InfoCourseLevel", obj);

        return (<Box key={index}>
            <CourseCard obj={obj} loading={isLoading} />
        </Box>);

    };

    
    const processCourseList = () => {
        if (!objects.length) return <></>;
        const slidesToShow = CommonTools.calculateNumberOfElementsShowOnCarousel(parseInt(CC("DefaultValueShowHomeCourseCarousel","4")), objects.length)

        return (
            <Box className="container coursesHome">
                <Typography variant="h2" mb={3}>{LL('Recomandări pentru tine' )}</Typography>
                <Carousel _slidesToShow={slidesToShow}>
                    {objects.map((item: CourseDto, i: number) => {
                        return processCourseItem(item, i);
                    })}
                </Carousel>
            </Box>
        );
    };

    return isLoading ? <Skeleton /> : processCourseList();
    // return <Carousel displayFunction={processCourseList} />
};

export { HomeCourseBlock };

