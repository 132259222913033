import React from "react";
import PageComponentProps from "interfaces/pagecomponentprops.interface";
import { useResource } from "hook/useResource";
import { TestimonialList } from "components/testimonial/TestimonialList";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

const HomeTestimonial: React.FC<PageComponentProps> = ({ currentRoute, ...props }) => {
  const { LL } = useResource();
  return <>
    <Box className="container" my={4}>
      <Typography variant="h2" my={4}>
        {LL("Testimonial home page")}
      </Typography>
      <TestimonialList currentRoute={currentRoute} />
    </Box>
  </>;
};

export { HomeTestimonial };
