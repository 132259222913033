import React, { useState, useEffect } from "react";
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Skeleton from '@mui/material/Skeleton';
import { CourseDto } from 'dto/course/course.dto'
import { MyButton } from "components/general/MyButton";
import { CommonTools } from 'tools/commontools';
import { ComponentCommonTools } from 'tools/componentcommontools'
import { useResource } from "hook/useResource";
import { RouteTools } from "tools/routetools";
import {
    Box, Stack, Divider, Grid,
    Link, IconButton, Menu, MenuItem,
    ListItemIcon, ListItemText
} from "@mui/material";
import { TeacherProcessNameCourse } from "components/teacher/TeacherProcessNameCourse";
import { logger } from "hoc/logger";
import { TypeCourseCardChip } from "./TypeCourseCardChip";
import { MyNavBt } from "components/general/MyNavBt";
import { StatusChipCourse } from "./StatusChipCourse";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import CourseService from "services/course/course.service";
import { useMessage } from "hook/useMessage";
import { useCookies } from "react-cookie";
import DateTools from "tools/date.tools";
import { CourseCardPriceContent } from "components/elements/courseelements/CourseCardPriceContent";
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import AppRegistrationOutlinedIcon from '@mui/icons-material/AppRegistrationOutlined';
import KeyboardDoubleArrowRightOutlinedIcon from '@mui/icons-material/KeyboardDoubleArrowRightOutlined';

type TeacherCourseCardProps = {
    obj: CourseDto;
    loading: boolean;
    loadAction?: any
}

const courseService = new CourseService();
const TeacherCourseCard: React.FC<TeacherCourseCardProps> = ({ obj, loading, loadAction, ...props }) => {

    const { LL } = useResource();
    const [imgUrl, setImgUrl] = useState<string>("");
    const [fullUrl, setFullUrl] = useState<string>("");
    const { registerDialog } = useMessage();
    const [cookies, setCookies] = useCookies()
    const goCourseEdit = () => {
        if (!obj) return;
        if (!obj.id) return;
        setCookies('tabValueTeacherEditCourse', 'generalInfo')
        const url = '/teacherinterface/editcourse/' + obj.id
        RouteTools.setHistory(url, {})
    }

    const prepareGallery = () => {
        if (!obj) return;
        if (!obj.hasOwnProperty('gallery')) return;
        if (!obj.gallery) return;
        if (obj.gallery.length === 0) return;
        const galleryObj = CommonTools.getGalleryObjectLogo(obj.gallery);
        if (!galleryObj) return;
        CommonTools.setFileUrlsGallery(galleryObj, setImgUrl, setFullUrl, 310, 180);
    }

    useEffect(() => {
        prepareGallery();
    }, [obj])

    const processImage = () => {
        if (!obj) return <></>;
        if (!imgUrl) return <></>;

        return (
            <CardMedia
                sx={{ height: 180 }}
                image={imgUrl}
                title='' >
                {processCourseType()}
                {processCourseStatus()}
            </CardMedia>
        )
    }



    const processCourseType = () => {
        if (!obj) return <></>;
        if (!obj.hasOwnProperty('coursesettings')) return <></>;
        if (!obj.coursesettings) return <></>;
        return <Box p={1}><TypeCourseCardChip obj={obj.coursesettings} width={14} height={14} /></Box>
    }

    const processNumberOfStudents = () => {
        if (!obj) return <></>;
        if (!obj.hasOwnProperty('numberofstudents')) return <></>;
        if (!obj.numberofstudents) return <></>;
        return (
            <Box>
                <Typography component='span' variant="h5" fontWeight='bold'>
                    {ComponentCommonTools.processObjectField(obj, 'numberofstudents')} 
                    {processMaxNumberOfStudents()}
                </Typography>
                <Typography display='flex' className="color-boulder">
                    <AppRegistrationOutlinedIcon color="primary" />{LL('Number_of_students_on course')}
                </Typography>

            </Box>
        )
    }
    const processMaxNumberOfStudents = () => {
        if (!obj) return <></>;
        if (!obj.hasOwnProperty('coursesettings')) return <></>;
        if (!obj.coursesettings) return <></>;
        if (!obj.coursesettings.hasOwnProperty('maxnumberofstudents')) return <></>;
        if (!obj.coursesettings.maxnumberofstudents) return <></>;
        return (
            <Typography component='span' variant="h5" fontWeight='bold'>
                 {LL('out_of')} {ComponentCommonTools.processObjectFieldOfObject(obj, 'coursesettings', 'maxnumberofstudents')}
            </Typography>
        )
    }

    const processCourseStatus = () => {
        if (!obj) return <></>;
        if (!obj.hasOwnProperty('status')) return <></>;
        if (!obj.status) return <></>;
        return <Box className="statuses"><StatusChipCourse  obj={obj} /></Box>
    }

    const processCourseStatusOutlined = () => {
        if (!obj) return <></>;
        if (!obj.hasOwnProperty('status')) return <></>;
        if (!obj.status) return <></>;
        return <Box className="statuses"><StatusChipCourse variant="outlined" obj={obj} /></Box>
    }
    // 
    const processCoursePrice = () => {
        if (!obj) return <></>;
        return <CourseCardPriceContent obj={obj} />;
  
}

    const processCourseNextLesson = () => {
        if (!obj) return <></>;
        if (!obj.hasOwnProperty('nextlessonstartdate')) return <></>;
        if (!obj.nextlessonstartdate) return <></>;
        return (
            <Box>
                <Typography variant="h5" fontWeight='bold'>
                    {LL('Next_lesson')}
                </Typography>
                <Typography display='flex'  className="color-boulder">
                    <CalendarMonthOutlinedIcon color="primary" />
                    {DateTools.displayTimestampInDateAndHHMM(obj.nextlessonstartdate)}
                </Typography>
            </Box>
        )
    }



    const processCourseDetails = () => {
        if (!obj) return <></>;

        return (
            <Box>
                <Stack direction='row'
                    justifyContent="space-between"
                    alignItems="flex-start"
                    spacing={2}>
                    <Stack direction='column' className="detailsGeneral">
                        <Box  >
                            <Typography component='span' className="lp">
                                {LL('Language course')}
                            </Typography>
                            <Typography p={1} component='span' className="rp">
                                {ComponentCommonTools.processObjectFieldOfObject(obj, 'language', 'name')}
                            </Typography>
                        </Box>
                        <Box >
                            <Typography component='span' className="lp">
                                {LL('CourseDuration')}
                            </Typography>
                            <Typography p={1} component='span' className="rp">
                                {ComponentCommonTools.processObjectFieldTimeStamp(obj, 'courseduration')}
                            </Typography>
                        </Box>
                        <Box >
                            <Typography component='span' className="lp">
                                {LL('NumberOfLessons')}
                            </Typography>
                            <Typography p={1} component='span' className="rp">
                                {ComponentCommonTools.processObjectField(obj, 'numberoflessons')}
                            </Typography>
                        </Box>
                        {processNumberOfLessonsRemaining()}
                    </Stack>
                </Stack>

            </Box>
        )
    }

    const processNumberOfLessonsRemaining = () => {
        if (!obj) return <></>;
        if (!obj.hasOwnProperty('numberoflessonsremaining')) return <></>;
        if (!obj.numberoflessonsremaining) return <></>;
        return (
            <Box>
                <Typography component='span' className="lp">
                    {LL('NumberOfLessonsRemaining')}
                </Typography>
                <Typography p={1} component='span' className="rp">
                    {ComponentCommonTools.processObjectField(obj, 'numberoflessonsremaining')}
                </Typography>
            </Box>
        )
    }


    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleActionDelete = () => {
        if (!obj) return;
        handleClose();
        registerDialog(LL('Confirm_delete_course'),
            LL('Are_you_sure_you_want_to_delete_this_course_deleting_will_change_the_status'),
            { cb: handleDeleteByTeacher }, null);

    }
    const handleDeleteByTeacher = () => {
        if (!obj) return;
        if (!obj.hasOwnProperty('id')) return;
        if (!obj.id) return;
        if (!loadAction) return;

        courseService.deletedByTeacher(loadAction, {}, obj.id);

    }

    const processButtonGoToClassroom = () => {
        if (!obj) return <></>;
        if (!obj.hasOwnProperty('id')) return <></>;
        if (!obj.id) return <></>;
        
        const url = '/teacherinterface/classroom/' + obj.id;
        return (<MyNavBt href={url} className="goToClass">
            <KeyboardDoubleArrowRightOutlinedIcon  />
        </MyNavBt>)
    }

    const processCardActions = () => {
        if (!obj) return <></>;
        if (!obj.hasOwnProperty('id')) return <></>;
        if (!obj.id) return <></>;
        if (!obj.hasOwnProperty('fullurl')) return <></>;
        if (!obj.fullurl) return <></>;
        return (
            <CardActions>
                <IconButton
                    aria-label="more"
                    id="long-button"
                    aria-controls={open ? 'long-menu' : undefined}
                    aria-expanded={open ? 'true' : undefined}
                    aria-haspopup="true"
                    onClick={handleClick}
                >
                    <MoreVertIcon />
                </IconButton>
                <Menu
                    id="long-button"
                    aria-labelledby="long-button"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'center',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'center',
                        horizontal: 'left',
                    }}
                    variant='menu'
                >
                    <MenuItem
                        onClick={goCourseEdit}
                    >
                        <ListItemIcon>
                            <EditIcon />
                        </ListItemIcon>
                        <ListItemText>
                            {LL('Edit')}
                        </ListItemText>
                    </MenuItem>
                    <MenuItem
                        component={Link}
                        href={obj.fullurl}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <ListItemIcon>
                            <RemoveRedEyeIcon />
                        </ListItemIcon>
                        <ListItemText>
                            {LL('Preview')}
                        </ListItemText>
                    </MenuItem>
                    <MenuItem onClick={handleActionDelete}>
                        <ListItemIcon>
                            <DeleteIcon />
                        </ListItemIcon>
                        <ListItemText>
                            {LL('Delete')}
                        </ListItemText>
                    </MenuItem>
                </Menu>
                
            </CardActions>
        )
    }
    const processName = () => {

        if (!obj) return <></>;
        return (<Typography variant="h5" fontWeight='bold'>
            {ComponentCommonTools.processObjectField(obj, 'name')}
        </Typography>)
    }

    const processTeacherCourseCard = () => {
        if (!obj) return <></>;

        return (
            <Box py={1}>
                <Card className="courseCardH teacherCards">
                    <Grid container className="content" spacing={2} alignItems='center'>
                        <Grid item lg={2} sm={12}>{processImage()}</Grid>
                        <Grid item lg={5} sm={12} mt={0} p={1}>
                            {processName()}

                            <Stack
                                direction='row'
                                justifyContent="start"
                                alignItems="center"
                                alignContent="center"
                                mt={3}
                                spacing={2}>
                                {processCourseStatusOutlined()}
                                {processCoursePrice()}
                            </Stack>
                        </Grid>
                        <Grid item lg={2} sm={12}  p={1}>
                            {processNumberOfStudents()}
                           
                        </Grid>
                        <Grid item lg={2} sm={12}  p={1}>
                        {processCourseNextLesson()}
                        </Grid>
                        <Grid  item lg={1} sm={12} p={1}>
                            <Stack direction='row' justifyContent='space-between' alignItems='center' >
                                {processButtonGoToClassroom()}
                                {processCardActions()}
                            </Stack>
                        </Grid>

                    </Grid>

                    

                </Card>
            </Box>

        )
    }

    return loading ? <Skeleton variant="rectangular" /> : processTeacherCourseCard();
};

export { TeacherCourseCard };
