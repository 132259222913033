import React, { useCallback, useEffect, useRef, useState } from "react";
import { useResource } from "hook/useResource";
import { logger } from "hoc/logger";
import CourseService from "services/course/course.service";
import PageComponentProps from "interfaces/pagecomponentprops.interface";
import { CourseDto } from "dto/course/course.dto";
import { RouteTools } from "tools/routetools";
import RequestListDTO from "dto/app/requestlist.dto";
import { Status } from "tools/status";
import RequestFilterDTO from "dto/app/requestfilter.dto";
import ResultListDTO from "dto/app/resultlist.dto";
import { Types } from "tools/types";
// import { InfoCourseItem } from "components//InfoCourseItem";
import { Loading } from "components/general/Loading";
import Skeleton from '@mui/material/Skeleton';
import { Box } from "@mui/material";
import ResultObjectDTO from "dto/app/resultobject.dto";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { ComponentCommonTools } from "tools/componentcommontools";
import Stack from '@mui/material/Stack';
import AppBar from '@mui/material/AppBar';
import { MyNavBt } from "components/general/MyNavBt";
import { CourseCard } from "components/course/course/CourseCard";
import { Carousel } from "components/elements/Carousel/Carousel";
import { CommonTools } from "tools/commontools";

const courseService = new CourseService();

type TendencyCoursesProps = {
    currentRoute: any;
    // id: string;
}

const TendencyCourses: React.FC<TendencyCoursesProps> = ({
    currentRoute,
    // id,
    ...props
}) => {

    const [isLoading, setIsLoading] = useState(true);
    const [objects, setObjects] = useState<Array<CourseDto>>([]);
    const { LL, CC } = useResource();

    const getList = () => {
        const reqList = new RequestListDTO();
        reqList.onpage = 100;
        reqList.page = 1;
        reqList.filters = [];

        let f: RequestFilterDTO;

        f = new RequestFilterDTO();
        f.field = "status";
        f.values = [];
        f.values.push(Status.ACTIVE.toString());
        reqList.filters.push(f);



        setIsLoading(true);

        courseService.getListInTendency(loadObjects, {}, reqList);

        // setIsLoading(false);
    };

    const loadObjects = (result: ResultListDTO) => {
        if (!result) return;
        if (!result.err) {
            const objects = result.objects ? result.objects : [];
            setObjects(objects);
        }
    }

    const checkLoading = () => {
        let load = false;

        if (!objects) load = true;
        if (objects.length === 0) load = true;

        setIsLoading(load);
    }
    useEffect(() => {
        getList();
    }, []);

    useEffect(() => {
        checkLoading();
    }, [objects]);



    const processCourseItem = (obj: CourseDto, index: number) => {
        

        return (<Box key={index}>
            <CourseCard obj={obj} loading={isLoading} />
        </Box>);

    };

    
    const processCourseList = () => {
        if (!objects.length) return <></>;
        const slidesToShow = CommonTools.calculateNumberOfElementsShowOnCarousel(parseInt(CC("DefaultValueShowTendencyCourseListByValueCarousel","3")), objects.length)
        return (
            <Carousel _slidesToShow={slidesToShow}>
                {objects.map((item: CourseDto, i: number) => {
                    return processCourseItem(item, i);
                })}
            </Carousel>
        );
    };


    return isLoading ? <Skeleton /> : (
        <Box>

            {processCourseList()}

        </Box>);


};

export { TendencyCourses };
