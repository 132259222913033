import React, { useState, useEffect } from "react";

import { logger } from "hoc/logger";
import useMyFormValidation from "hook/useMyFormValidation";
import { CoursePriceDto } from "dto/course/courseprice.dto";
import { useCookies } from "react-cookie";
import { Loading } from "components/general/Loading.jsx";
import { MyNavBt } from "components/general/MyNavBt";
import { useResource } from "hook/useResource";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { CommonTools } from "tools/commontools";
import { ComponentCommonTools } from "tools/componentcommontools";
import ResultObjectDTO from "dto/app/resultobject.dto";
import MyTextField from "components/form/mytextfield";
import { LabelTypes } from "tools/labeltypes";
import ValidateTools from "tools/validate.tools";
import RequiredValidator from "validators/required.validator";
import { MyButton } from "components/general/MyButton";
import { RouteTools } from "tools/routetools";
import { MyBreadcrumbs } from "components/elements/MyBreadcrumbs/MyBreadcrumbs";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import CoursePriceService from "services/course/courseprice.service";

import IconButton from "@mui/material/IconButton";
import { CurrencySelect } from "components/elements/Select/CurrencyTypeSelect";
import { PricePolicySelect } from "components/elements/Select/PricePolicySelect";
import { PriceTypeSelect } from "components/elements/Select/PriceTypeSelect";
import { RecommendationPriceSelect } from "components/elements/Select/RecommendationPriceSelect";
import { Status } from "tools/status";
import { CircularLoading } from "components/general/CircularLoading";
import { useMessage } from "hook/useMessage";
import MyCheckboxField from "components/form/mycheckboxfield";
import CourseFilterService from "services/course/coursefilter.service";

import MyTable from "components/elements/TabelTools/MyTable";
import ResultListDTO from "dto/app/resultlist.dto";
import TbFilterDTO from "dto/app/tbfilter.dto";
import TbFilterListDTO from "dto/app/tbfilterlist.dto";
import FilterTools from "tools/filtertools";
import RequestListDTO from "dto/app/requestlist.dto";
import RequestFilterDTO from "dto/app/requestfilter.dto";
import { CourseFilterDto } from "dto/course/coursefilter.dto";
import { CourseCategoryAutocomplete } from "components/elements/Autocomplete/CourseCategoryAutocomplete";

import { FilterAutocomplete } from "components/elements/Autocomplete/FilterAutocomplete";
import { FilterDictionaryAutocomplete } from "components/elements/Autocomplete/FilterDictionaryAutocomplete";
import { Skeleton } from "@mui/material";
import { CourseFilterBlockAutocomplete } from "components/elements/Autocomplete/CourseFilterBlockAutocomplete";
import { CourseFilterDictionaryBlockAutocomplete } from "components/elements/Autocomplete/CourseFilterDictionaryBlockAutocomplete";
const coursefilterService = new CourseFilterService();

interface EditCourseFilterProps {
  currentRoute: any;
}

const EditCourseFilter: React.FC<EditCourseFilterProps> = ({
  currentRoute,

  ...props
}) => {
  const { LL, CC } = useResource();

  const id = currentRoute._paths !== undefined ? currentRoute._paths[2] : "";

  const [loading, setLoading] = useState<boolean>(true);
  const [loadingForm, setLoadingForm] = useState<boolean>(true);
  const [obj, setObj] = useState<CourseFilterDto | undefined>(
    new CourseFilterDto()
  );
  const { registerDialog } = useMessage();
  const [isFormDisabled, setIsFormDisabled] = useState(false);
  

  const [cookies, setCookies] = useCookies();
  const [idFilter, setIdFilter] = useState<string>("");
  const [data, setData] = useState<ResultListDTO>(new ResultListDTO());
  const [rows, setRows] = useState<Array<CourseFilterDto>>(
    new Array<CourseFilterDto>()
  );

  const [total, setTotal] = useState(0);
  const [totalPage, setTotalPage] = useState(0);

  const [responseParams, setResponseParams] = useState<RequestListDTO>(
    new RequestListDTO()
  );

  const [reqList, setReqList] = useState<RequestListDTO>(new RequestListDTO());
  const [tbFilterList, setTbFilterList] = useState<TbFilterListDTO>(
    new TbFilterListDTO()
  );

  const validateAll = () => {
    if (!obj) return;
    setLoadingForm(true);

    // obj.value = obj.value !== undefined ? obj.value : "";
    obj.idfilter = obj.idfilter !== undefined ? obj.idfilter : "";
    obj.idfilterdictionary =
      obj.idfilterdictionary !== undefined ? obj.idfilterdictionary : "";

    setObj(obj);
    setLoadingForm(false);
  };

  const deleteObj = (obj: any) => {
    setDeleteOneObject(obj);
  };

  const columns = [];
  columns.push(ComponentCommonTools.columns_GetCheck());
  columns.push(
    ComponentCommonTools.columns_StandardObjectColumn(LL, "filter", "name")
  );
  columns.push(
    ComponentCommonTools.columns_StandardObjectColumn(
      LL,
      "filterdictionary",
      "name"
    )
  );
//   columns.push(ComponentCommonTools.columns_StandardColumn(LL, "value"));
  columns.push(
    ComponentCommonTools.columns_ActionsColumn(LL, "course", deleteObj, [
      "delete",
    ])
  );

  const handleSubmit = (event: any) => {
    event.preventDefault();
    if (isFormDisabled) return false;
    if (!obj) return;

    if (id !== undefined) {
      registerDialog(
        LL("Confirm_edit_course"),
        LL(
          "Are_you_sure_you_want_to_edit_this_course_editing_will_change_the_status"
        ),
        { cb: handleConfirmEdit },
        null
      );
    }
  };
  const setObjField = (field: any, value: any) => {
    let t = JSON.parse(JSON.stringify(obj));
    t[field] = value;
    setObj(t);
  };

  const handleConfirmEdit = () => {
    if (obj !== undefined && id !== undefined) {
      obj.idcourse = id;
      coursefilterService.add(handleCallbackConfirmEdit, {}, obj);
      setIdFilter("");
    }
  };

  const handleCallbackConfirmEdit = (result: any) => {
    if (!result) return;
    if (!result.err) {
      setLoading(true);
      setLoadingForm(true);
      setObj(new CourseFilterDto());
      getList();
    }
  };

  useEffect(() => {
    validateAll();
  }, [obj]);

  const loadObjects = (data: ResultListDTO) => {
    CommonTools.processListLoadObjects(
      data,
      setData,
      setRows,
      setTotal,
      setTotalPage,
      setResponseParams
    );
  };

  const getList = () => {
    if (id !== undefined) coursefilterService.getList(loadObjects, {}, reqList);
  };

  const getReqList = () => {
    let reqListLocal = RouteTools.prepareListRequest(currentRoute, cookies);
    reqListLocal = coursefilterService.getDefaultRequestList(reqListLocal);
    const filters = new Array<RequestFilterDTO>();
    const filter = new RequestFilterDTO();
    filter.field = "idcourse";
    if (id !== undefined) filter.values = [id];
    else filter.values = [];
    filters.push(filter);
    reqListLocal.filters = filters;
    reqListLocal.criteria = undefined;
    reqListLocal.sortcriteria = undefined;
    reqListLocal.range = undefined;
    reqListLocal.onpage = parseInt(
      CC("DEFAULT_ONPAGE_Edit_Coursefilter", "10")
    );

    setReqList(reqListLocal);
  };

  const checkIsLoading = () => {
    const load: boolean = CommonTools.checkIsLoading(
      reqList,
      responseParams,
      data,
      total,
      totalPage,
      rows
    );
    validateAll();
    setLoading(load);
  };

  const processTbFilterList = () => {
    const _tobj = new TbFilterListDTO();

    var t: TbFilterDTO;

    t = new TbFilterDTO("search", FilterTools.fiterTypeText);
    _tobj.addFilter(t);

    setTbFilterList(_tobj);
  };

  useEffect(() => {
    processTbFilterList();
    validateAll();
  }, []);

  useEffect(() => {
    getReqList();
  }, [currentRoute]);

  useEffect(() => {
    if (CommonTools.atLeastOneFieldDefined(reqList)) {
      setLoading(true);
      getList();
    }
  }, [reqList]);

  useEffect(() => {
    checkIsLoading();
  }, [data, rows, reqList, total, totalPage]);

  const [deleteOneObject, setDeleteOneObject] = useState<any>(undefined);

  const handleDelete = (item: any) => {
    coursefilterService.delete(item.id, getList, undefined);
  };

  
  const processForm = () => {
    if (!obj) return <></>;
    if (loadingForm) return <Skeleton variant="rectangular" height={200} />;
    return (
      <form onSubmit={handleSubmit}>
        <Box sx={{ mt: 3 }}>
          <Typography variant="h6" mb={1}>
            {LL("Select filter")}
          </Typography>
          <CourseFilterBlockAutocomplete setObj={setObj} obj={obj} setIdFilter={setIdFilter} />
        </Box>
        {idFilter ? (
          <Box sx={{ mt: 3 }}>
            <Typography variant="h6" mb={1}>
              {LL("Select dictionary")}
            </Typography>
            <CourseFilterDictionaryBlockAutocomplete
              setObj={setObj}
              obj={obj}
              idFilter={idFilter}
            />
          </Box>
        ) : null}

        {/* <Box sx={{ mt: 3 }}>
            <Typography variant="h6" mb={1}>{LL('Value filter')}</Typography>
                <MyTextField
                    tabIndex={2}
                    fullWidth
                    id="value"
                    name="value"
                    variant="standard"
                    className="form-input"
                    color="primary"
                    _label={LL("value")}
                    size="small"
                    value={obj.value}
                    setObj={setObjField}
                    _vresults={false}
                />
            </Box> */}
        <Box textAlign="center" mt={4}>
          <MyButton
            tabIndex={1}
            disabled={isFormDisabled}
            color="primary"
            variant="contained"
            size="large"
            className="btn"
            type="submit"
            children={LL("Bt_Submit")}
          />
        </Box>
      </form>
    );
  };
  return obj ? (
    <>
      <Box>{processForm()}</Box>
      <Box mt={4}>
        <MyTable
          _columns={columns}
          _data={rows}
          _total={total}
          _totalPage={totalPage}
          _reqList={reqList}
          _tbFilterList={tbFilterList}
          setReqList={setReqList}
          loading={loading}
          currentRoute={currentRoute}
          onDelete={handleDelete}
          mainObject={"course"}
          fieldToShowOnDelete="id"
          deleteOneObject={deleteOneObject}
          setDeleteOneObject={setDeleteOneObject}
          onRowClickCb={() => {}}
        />
      </Box>
    </>
  ) : (
    <CircularLoading />
  );
};

export default EditCourseFilter;
