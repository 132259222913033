import React, { useState, useEffect } from "react";
import { CourseDto } from "dto/course/course.dto"

import Dialog from '@mui/material/Dialog';

import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { Box, DialogContent, Skeleton, Paper } from "@mui/material";
import { useResource } from "hook/useResource";
import { ComponentCommonTools } from "tools/componentcommontools";
import { logger } from "hoc/logger";
import { MyButton } from "components/general/MyButton";
import CourseEnrollmentService from "services/course/courseenrollment.service";
import { useAuth } from "hook/useAuth";
import { PostCourseEnrollmentDto } from "dto/course/courseenrollment.dto";
import { RouteTools } from "tools/routetools";
import { LogoComp } from "components/general/LogoComp";
import { Types } from "tools/types";

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});


type CourseEnrollmentDialogProps = {
    course: CourseDto;
    open: boolean;
    setOpen: any;
}


const courseEnrollmentService = new CourseEnrollmentService();

const CourseEnrollmentDialog: React.FC<CourseEnrollmentDialogProps> = (
    {
        course,
        open,
        setOpen,
        ...props
    }
) => {

    const { LL } = useResource();
    const { student, setStudent } = useAuth();
    const [courseHasTest, setCourseHasTest] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    const handleClose = () => {
        setOpen(false);
    };

    const processCourseHasTest = () => {
        if (!course) return;
        if (!course.hasOwnProperty('coursesettings')) return;
        if (!course.coursesettings) return;
        setLoading(true);
        if (!course.coursesettings.hasOwnProperty('hastest')) {
            setCourseHasTest(false);
            setLoading(false);
            return;
        }
        if (!course.coursesettings.hastest) setCourseHasTest(false);
        else {
            setCourseHasTest(true);
            setLoading(false);
        }
    }

    useEffect(() => {
        if (!open) return;
        processCourseHasTest();
    }, [course, open]);

    const handleAddForTest = () => {
        if (!student) return;
        if (!course) return;
        if (!course.hasOwnProperty('id')) return;
        if (!course.id) return;
        const obj = new PostCourseEnrollmentDto(course.id, student.id)
        setLoading(true);
        courseEnrollmentService.addForTest(handleResult, { forTest: true }, obj);
    }
    const handleAddForCheckout = () => {
        if (!student) return;
        if (!course) return;
        if (!course.hasOwnProperty('id')) return;
        if (!course.id) return;
        const obj = new PostCourseEnrollmentDto(course.id, student.id)
        setLoading(true);
        courseEnrollmentService.addForCheckout(handleResult, { forTest: false }, obj);
    }

    const updateStudentNumberOfCourse = () => {
        if (!student) return;
        if (!student.hasOwnProperty('numberofcourses') || !student.numberofcourses) {
            student.numberofcourses = 1;
        }
        else {
            student.numberofcourses++;
        }
        setStudent(student);
    }

    const handleResult = (result: any, params: any) => {
        if (!result) return;
        if (!result.err) {
            updateStudentNumberOfCourse();
            if (params.forTest) {
                if (result.obj && 
                    result.obj.hasOwnProperty('id')
                    && result.obj.id) {
                    const url = '/profile/courseenrollment/' + result.obj.id;
                    RouteTools.setHistory(url, {action:Types.TYPE_PARAM_FOR_TEST_OPEN_DEFAULT});
                }
            }
            else {
                RouteTools.setHistory('/checkout', {});
            }
            setLoading(false);
            handleClose();
        }
    }


    const processActionButton = () => {
        if (!course) return <></>;
        if (loading) return <Skeleton variant="rectangular" height={50} />
        if (!courseHasTest) {
            return (
                <MyButton cb={handleAddForCheckout} className="btn" color="primary" variant="contained">
                    {LL('BT_Go_to_Checkout')}
                </MyButton>
            )
        }
        else {
            return (
                <MyButton cb={handleAddForTest} className="btn" color="primary" variant="contained">
                    {LL('BT_Go_to_Test')}
                </MyButton>
            )
        }
    }

    const processInformativeText = () => {
        if (!course) return <></>;

        return (
            <Box  textAlign="center">
                <Typography variant="h3">{LL("InformativeText_CourseEnrollment")}</Typography>
                <Typography my={2}>{ComponentCommonTools.processObjectField(course, 'name')}</Typography>
            </Box>
        )
    }

    const processHasToTakeTest = () => {
        if (!course) return <></>
        if (loading) return <Skeleton variant="rectangular" height={50} />
        if (!courseHasTest) {
            return (
                <Typography  textAlign="center" variant="h6">
                    {LL("InformativeText_CourseEnrollment_Checkout")}
                </Typography>
            )
        } else {
            return (
                <Typography  textAlign="center" variant="h6">
                    {LL("InformativeText_CourseEnrollment_Test")}
                </Typography>
            )
        }
    }

    const processDialog = () => {
        if (!open) return <></>;
        if (!course) return <></>;
        if (!course.hasOwnProperty('id')) return <></>;
        if (!course.id) return <></>;

        return (
            <Dialog
                fullScreen
                open={open}
                onClose={handleClose}
                TransitionComponent={Transition}  className="appTextForm">
                <AppBar sx={{ position: 'relative', bgcolor:'#fff' }}>
                    <Toolbar>
                        <LogoComp/>
                        <Typography sx={{ ml: 1, flex: 1 }} variant="h4" className="h-variant" textAlign="center" >
                            {LL('CourseEnrollment')}
                        </Typography>
                        <Button
                            onClick={handleClose}
                            aria-label="close"
                            className="btn-x"
                        ><CloseIcon />{LL('Iesire')}
                        </Button>
                    </Toolbar>
                </AppBar>
                <DialogContent className="dialogContent alignDialog">
                    {processInformativeText()}
                    {processHasToTakeTest()}
                </DialogContent>
                <Box className="footerContent" p={2} display="flex" justifyContent="end">{processActionButton()}</Box>
            </Dialog>
        )
    }

    return processDialog();
};

export { CourseEnrollmentDialog };
