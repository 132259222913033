import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useResource } from "hook/useResource";
import { logger } from "hoc/logger";
import MenuService from "services/menu/menu.service";
import MenuLevelProps from "interfaces/menulevelprops.interface";
import { MenuDto } from "dto/menu/menu.dto";
import { RouteTools } from "tools/routetools";
import RequestListDTO from "dto/app/requestlist.dto";
import { Status } from "tools/status";
import RequestFilterDTO from "dto/app/requestfilter.dto";
import ResultListDTO from "dto/app/resultlist.dto";
import { Types } from "tools/types";
import { BottomMenuItem } from "./BottomMenuItem";
import { Loading } from "components/general/Loading";
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
const menuService = new MenuService();

const BottomMenuLevel: React.FC<MenuLevelProps> = ({
  currentRoute,
  level,
  idparent,
  ...props
}) => {
  const { saveCache, getCache, _idlanguage } = useResource();

  const [isLoading, setIsLoading] = useState(true);
  const [objects, setObjects] = useState<Array<MenuDto>>([]);

  const getList = async () => {
    const reqList = new RequestListDTO();
    reqList.onpage = 100;
    reqList.page = 1;
    reqList.filters = [];

    let f: RequestFilterDTO;

    f = new RequestFilterDTO();
    f.field = "section";
    f.values = [];
    f.values.push(Types.SECTION_BOTTOM.toString());
    reqList.filters.push(f);

    f = new RequestFilterDTO();
    f.field = "status";
    f.values = [];
    f.values.push(Status.ACTIVE.toString());
    reqList.filters.push(f);

    if (_idlanguage) {
      f = new RequestFilterDTO();
      f.field = "idlanguage";
      f.values = [_idlanguage];
      reqList.filters.push(f);
    }

    f = new RequestFilterDTO();
    f.field = "idparent";
    f.values = [];
    f.values.push(idparent);
    reqList.filters.push(f);

    const cd = getCache(reqList);
    if (cd !== false) {
      setObjects(cd);
      setIsLoading(false);
      return;
    }

    setIsLoading(true);

    const data = await menuService.getList(undefined, {}, reqList);
    const rez = !data || !data.objects ? [] : data.objects;

    setObjects(rez);
    setIsLoading(false);

    saveCache(rez, reqList);
  };

  useEffect(() => {
    getList();
  }, [idparent, _idlanguage]);

  const processMenuItem = (obj: MenuDto, index: number) => {
    const hasChildren = obj.hasOwnProperty('haschildren') && obj.haschildren !== undefined ? obj.haschildren : false;
    return <BottomMenuItem currentRoute={currentRoute} level={level} obj={obj} hasChildren={hasChildren} />;
  };

  const processMenuList = () => {
    if (!objects.length) return <></>;

    return (
      <Grid container className="bottomMenu">
        {objects.map((item: MenuDto, i: number) => {
          return processMenuItem(item, i);
        })}
      </Grid>
    );
  };

  return isLoading ? <Skeleton /> : processMenuList();
};

export { BottomMenuLevel };
