import React, { useState, useEffect } from "react";

import { MyButton } from "components/general/MyButton";
import { CommonTools } from 'tools/commontools';
import { ComponentCommonTools } from 'tools/componentcommontools'
import { useResource } from "hook/useResource";
import { RouteTools } from "tools/routetools";
import { Box, Stack, Divider, ListItemButton, ListItemText, Link, Typography } from "@mui/material";
import { logger } from "hoc/logger";
import { Types } from "tools/types";
import { TicketDto } from "dto/support/ticket.dto";
import { TicketMessageList } from "./TicketMessageList";



type SupportSelectedTicketProps = {
    obj?: TicketDto;
    objWasChanged: string;
    setObjWasChanged:any;
}

const SupportSelectedTicket: React.FC<SupportSelectedTicketProps> = ({ 
    obj,
    objWasChanged,
    setObjWasChanged,

    ...props }) => {


    const { LL, CC } = useResource();

    
    
    const processMessageList = () => {
        if (!obj) return <></>
        if (!obj.hasOwnProperty("id")) return <></>
        if (!obj.id) return <></>

        return <TicketMessageList idTicket={obj.id} objWasChanged={objWasChanged} />
    }

    const processItem = () => {
        if (!obj) return <>{LL('Select_a_ticket')}</>
        return (
            <Box>
                <Box>
                    <Stack direction="row" spacing={1}>
                        <Typography>
                            {ComponentCommonTools.processObjectField(obj, "status_name")}
                        </Typography>
                        <Typography>
                            {ComponentCommonTools.processObjectFieldOfObject(obj, "ticketcategory", "name")}
                        </Typography>
                        <Typography>
                            {ComponentCommonTools.processObjectField(obj, "date_name")}
                        </Typography>
                    </Stack>
                </Box>
                <Box mt={1}>
                    {processMessageList()}
                </Box>
            </Box>
        )
    }

    return processItem();
};

export { SupportSelectedTicket };
