import React from "react";
import PageComponentProps from "interfaces/pagecomponentprops.interface";
import { TestResultList } from "./TestResultList";

const TestResultBlock: React.FC<PageComponentProps> = ({ currentRoute, ...props }) => {


    return (<>
        <TestResultList currentRoute={currentRoute} />
    </>);
};

export { TestResultBlock };
