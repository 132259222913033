import React, { useState, useEffect } from "react";
import { useResource } from "hook/useResource";
import { QuestionDto } from "dto/course/question.dto";
import RequestFilterDTO from "dto/app/requestfilter.dto";
import RequestListDTO from "dto/app/requestlist.dto";
import { Status } from "tools/status";
import QuestionService from "services/course/question.service";
import ResultListDTO from "dto/app/resultlist.dto";
import {
    Paper,
    Box,
    IconButton,
    Skeleton,
    Typography,
    Stack,
    LinearProgress,
    DialogContent,
} from "@mui/material";

import RequestSortCriteriaDTO from "dto/app/requestsortcriteria.dto";
import { MySortSelect } from "components/elements/Select/MySortSelect";
import { MyButton } from "components/general/MyButton";
import { LoadMoreButton } from "components/elements/MyButton/LoadMoreButton";
import AccessTimeIcon from '@mui/icons-material/AccessTime';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ComponentCommonTools } from "tools/componentcommontools";

import { logger } from "hoc/logger";


import { useMessage } from "hook/useMessage";
import { CommonTools } from "tools/commontools";

import { TestQuestionItem } from "components/course/test/TestQuestionItem";
import { TestResultDto } from "dto/course/testresult.dto";
import { PostTestResultQuestionAndAnswerDto, PostTestResultQuestionDto } from "dto/course/testresultquestion.dto";
import { PostTestResultAnswerDto } from "dto/course/testresultanswer.dto";
import TestResultQuestionService from "services/course/testresultquestion.service";
import ResultObjectDTO from "dto/app/resultobject.dto";
import TestResultService from "services/course/testresult.service";
import { NoResult } from "components/general/NoResult";

type TestQuestionListProps = {
    idTest: string;
    statusTest: number;
    setStatusTest: any;
    testResult?: TestResultDto;
    setTestResult: any;
}

const questionService = new QuestionService();

const testResultQuestion = new TestResultQuestionService();

const testResultService = new TestResultService();

const TestQuestionList: React.FC<TestQuestionListProps> = ({
    idTest,
    statusTest,
    setStatusTest,
    testResult,
    setTestResult,
    ...props }) => {

    const { LL, CC } = useResource();
    const [isLoading, setIsLoading] = useState(true);
    const [objects, setObjects] = useState<Array<QuestionDto>>([]);
    const [index, setIndex] = useState<number>(0);


    const { registerDialog } = useMessage();



    const getList = () => {
        if (!idTest) return;
        setIsLoading(true);
        const reqList = new RequestListDTO();
        reqList.onpage = parseInt(CC('DefaultOnPageTestQuestionList', 2000));
        reqList.page = 1;
        reqList.filters = [];
        reqList.sortcriteria = [];

        const sortCriteria = new RequestSortCriteriaDTO();
        sortCriteria.field = "ordercriteria";
        sortCriteria.asc = true;
        reqList.sortcriteria.push(sortCriteria);

        let f: RequestFilterDTO;

        f = new RequestFilterDTO();
        f.field = "idtest";
        f.values = [idTest];
        reqList.filters.push(f);


        questionService.getList(loadObjects, {}, reqList);
    };


    const loadObjects = (result: ResultListDTO) => {
        if (!result) return;
        if (!result.err) {
            const objects = result.objects ? result.objects : [];
            setObjects(objects);
        }
    }


    const checkLoading = () => {
        let load = false;
        if (!objects) load = true;

        setIsLoading(load);
    }

    useEffect(() => {
        getList();
    }, [idTest]);

    useEffect(() => {
        checkLoading();
    }, [objects]);

    const [questionResult, setQuestionResult] = useState<PostTestResultQuestionDto | undefined>();
    const [answersResult, setAnswersResult] = useState<Array<PostTestResultAnswerDto>>([]);
    const calculateScoreQuestion = () => {
        if (!questionResult) return;

        let score = 0;
        if (answersResult) {
            answersResult.forEach(answer => {
                if (answer.score) score += answer.score;
            });
        }
        questionResult.questiontotalscore = score;
    }

    useEffect(() => {
        calculateScoreQuestion();
    }, [answersResult]);

    const addTestResultQuestion = () => {
        if (!questionResult || !answersResult || !testResult) return;

        const postObj = new PostTestResultQuestionAndAnswerDto();
        postObj.question = questionResult;
        postObj.answers = answersResult;
        setAnswersResult([]);
        setQuestionResult(undefined);

        testResultQuestion.addWithAnswers(handleResultAddQuestion, {}, postObj);
    }

    const addLastTestResultQuestion = () => {
        if (!questionResult || !answersResult || !testResult) return;

        const postObj = new PostTestResultQuestionAndAnswerDto();
        postObj.question = questionResult;
        postObj.answers = answersResult;
        setAnswersResult([]);
        setQuestionResult(undefined);

        testResultQuestion.addWithAnswers(handleResultAddQuestion, { lastQuestion: true }, postObj);
    }

    const handleResultAddQuestion = (result: ResultObjectDTO, cbParams: any) => {
        if (!result) return;
        if (!result.err) {
            if (cbParams && cbParams.lastQuestion) {
                handleFinish();
            }
            else {
                handleNext();
            }
        }
    }
    const processQuestionItem = (obj: QuestionDto) => {
        if (!obj) return <></>;
        if (!obj.id) return <></>;

        return (
            <>
                <TestQuestionItem
                    obj={obj}
                    indexQuestion={index}
                    setQuestionResult={setQuestionResult}
                    answersResult={answersResult}
                    setAnswersResult={setAnswersResult}
                    questionResult={questionResult}
                    testResult={testResult}
                    
                />
            </>
        );

    };

    const processTestQuestionList = () => {
        if (!objects.length) return <></>;
        if (index > objects.length) return <></>;
        const obj = objects[index];

        return (
            <>
                {processProgressBar()}
                <DialogContent className="dialogContent alignDialog">{processQuestionItem(obj)}</DialogContent>
                <Box  className="footerContent" p={2} display="flex" alignItems="center" justifyContent="space-between">{processToolbar()}</Box>
            </>
        );
    };

    const handleBack = () => {
        if (!index) return;
        const newIndex = index - 1;
        setIndex(newIndex);
    }
    const processBackButton = () => {
        if (!index) return <></>;
        return (
            <MyButton
                cb={handleBack}
            >
                {LL('Back')}
            </MyButton>
        )
    }

    const handleNext = () => {
        if (index >= objects.length - 1) return;
        const newIndex = index + 1;
        setIndex(newIndex);
    }

    const processNextButton = () => {
        if (index >= objects.length - 1) return <></>;
        return (
            <MyButton
                cb={addTestResultQuestion}
                className="btn"
                color="primary"
                variant="contained"
            >
                {LL('Next')}
            </MyButton>
        )
    }

    const handleFinish = () => {
        if (!objects.length) return;
        if (!testResult) return;
        if (!testResult.id) return;
        const testResultPost = new TestResultDto();
        testResultPost.status = Status.TEST_FINISHED;
        setIsLoading(true);
        testResultService.update(testResult.id, handleResultFinish, {}, testResultPost);
    }

    const handleResultFinish = (result: ResultObjectDTO) => {
        if (!result) return;
        if (!result.err) {
            setTestResult(result.obj);
            setStatusTest(Status.TEST_FINISHED);
            setIsLoading(false);
        }
    }
    const processFinishButton = () => {
        if (index === objects.length - 1) {
            return (
                <MyButton
                    cb={addLastTestResultQuestion}
                    variant="contained"
                    className="btn"
                >
                    {LL('Bt_Continua')}
                </MyButton>
            )
        }
        return <></>;
    }
    const processCount = () => {
        if (!objects.length) return <></>;
        const currentIndex = index + 1;
        return (
            <Stack
                direction={'row'}
                spacing={1}
            >
                <Typography variant="h6">
                    {LL('Question')}
                </Typography>
                <Typography variant="h6">
                    {currentIndex}
                </Typography>
                <Typography variant="h6">
                    {LL('from')}
                </Typography>
                <Typography variant="h6">
                    {objects.length}
                </Typography>
            </Stack>
        )
    }

    const processProgressBar = () => {
        if (!objects.length) return <></>;
        const progress = (index + 1) / objects.length * 100;
        return (
            <Box sx={{ width: '100%' }}>
                <LinearProgress variant="determinate" value={progress} />
            </Box>
        )
    }

    const processToolbar = () => {
        if (!objects.length) return <></>;
        return (
            <>
                {/* {processBackButton()} */}
                {processCount()}
                {processNextButton()}
                {processFinishButton()}
            </>
        )
    }


    return isLoading ? <Skeleton variant='rectangular' height={300} /> : (
        <>
            {processTestQuestionList()}
        </>
    );
};

export { TestQuestionList };
