import IValidator from "interfaces/ivalidator.interface";
import ValidatorResult from "validators/validatorresult";

export default class RequiredValidator implements IValidator {
  messageErr: string;
  messageOk: string;

  constructor(mE: string, mO: string) {
    this.messageErr = mE;
    this.messageOk = mO;
  }

  validate = (value: any): ValidatorResult => {
    let rez: ValidatorResult = new ValidatorResult();

    value = value.toString().trim();
    if (value) {
      rez.isOk = true;
      rez.message = this.messageOk;
    } else {
      rez.isOk = false;
      rez.message = this.messageErr;
    }

    return rez;
  }
}
