import Idto from 'interfaces/idto.interface';
import { Status } from 'tools/status';
import { Types } from 'tools/types';
import { PostAnswerDto,AnswerDto } from './answer.dto';

export class QuestionDto implements Idto {
    
  id?: string;
  name?: string;
  status?: number;
  ordercriteria?: number;
  type?: number;
  idtest?: string;
  totalscore?: number;
    
  }

  export class PostQuestionWithAnswerDto implements Idto {
    
    question?: PostQuestionDto;
    answers?: PostAnswerDto[];
   
  }

  export class PutQuestionWithAnswerDto implements Idto {
    
    question?: QuestionDto;
    answers?: AnswerDto[];
    deleteanswerids?: string[];
   
  }


  export class PostQuestionDto implements Idto {
    
    name?: string;
    status?: number;
    ordercriteria?: number;
    type?: number;
    idtest?: string;
    totalscore?: number;

    constructor() {
        this.status = Status.ACTIVE;
        this.ordercriteria = 0;
        this.totalscore = 0;
        this.type = Types.TYPE_QUESTION_SINGLE_CHOICE;
    }
  }