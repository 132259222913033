import { Box } from "@mui/material";
import { MyButton } from "components/general/MyButton";
import { useResource } from "hook/useResource";
import React from "react";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';


type LoadMoreButtonProps = {
    [x: string]: any;
    objects: Array<any>;
    total: number;
    onPage: number;
    setOnPage: any;
    step: number;
}

const LoadMoreButton: React.FC<LoadMoreButtonProps> = ({
    objects,
    total,
    onPage,
    setOnPage,
    step,
    ...props }) => {

    const { LL } = useResource();

    const calculateOnPage = () => {
        if (!objects) return;
        if (!objects.length) return;
        if (total === -1) return;

        if (total > onPage) {
            let localOnPage = onPage;
            localOnPage += step;
            setOnPage(localOnPage);
        }
    }

    const processLoadMoreObjects = () => {
        if (!objects || !objects.length) return <></>;
        if (total === -1) return <></>;
        if (total <= onPage) return <></>;
        return (
            <MyButton cb={calculateOnPage} {...props}>
                {LL('Bt_LoadMore')}<KeyboardArrowDownIcon />
            </MyButton>
        )
    }
    return processLoadMoreObjects();
};

export { LoadMoreButton };
