import React from "react";
import { useResource } from "hook/useResource"
import { ComponentCommonTools } from "tools/componentcommontools";

const TeacherLogo = () => {
    const { CF } = useResource();

    return <i className='teacher-logo'>
         {ComponentCommonTools.getFileBlock(CF("teacher-logo"), 25, 25)}
    </i>
};

export { TeacherLogo };
