import React, { useEffect, useState, useRef } from "react";
import PageComponentProps from "interfaces/pagecomponentprops.interface";
import { useResource } from "hook/useResource";
import { logger } from "hoc/logger";
import { Box, Typography } from '@mui/material';
import { MyNavBt } from "components/general/MyNavBt";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import MyTextField from 'components/form/mytextfield';
import { IconButton, Skeleton, Stack, Divider } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import Snackbar from '@mui/material/Snackbar';
import { useMessage } from "hook/useMessage";
import { CommonTools } from "tools/commontools";
import { MessageTypes } from "tools/messagetypes";
import { ShareElements } from "components/elements/Share/ShareElements";

import RequiredValidator from "validators/required.validator";
import ValidateTools from "tools/validate.tools";
import { LocalityAutocompleteV2 } from "components/elements/Autocomplete/LocalityAutocompleteV2";
import MyDatePicker from "components/elements/MyDatePicker/MyDatePicker";

import { useAuth } from "hook/useAuth";
import ResultObjectDTO from "dto/app/resultobject.dto";
import { MyButton } from "components/general/MyButton";
import { Types } from "tools/types";

import { CircularLoading } from "components/general/CircularLoading";
import MyCKEditor from "components/elements/ckeditor/MyCKEditor";
import { QuestionDto, PostQuestionDto, PostQuestionWithAnswerDto } from "dto/course/question.dto";
import QuestionService from "services/course/question.service";
import MyCheckboxField from "components/form/mycheckboxfield";

import MyDateTimePicker from "components/elements/MyDatePicker/MyDateTimePicker";
import DateTools from "tools/date.tools";
import { LessonSelect } from "components/elements/Select/LessonSelect";
import { QuestionTypeSelect } from "components/elements/Select/QuestionSelect";
import { AnswerDto, PostAnswerDto } from "dto/course/answer.dto";
import { AnswerTypeSelect } from "components/elements/Select/AnswerSelect";

import DeleteIcon from '@mui/icons-material/Delete';
const questionService = new QuestionService();

type AddQuestionDialogProps = {
    open: boolean;
    setOpen: any;
    id: string;
    setObjectWasChanged: any;

}
const AddQuestionDialog: React.FC<AddQuestionDialogProps> = ({
    open,
    setOpen,
    id,
    setObjectWasChanged,
    ...props
}) => {
    const { LL } = useResource();

    const [loading, setLoading] = useState<boolean>(false);
    const [obj, setObj] = useState<PostQuestionDto>(new PostQuestionDto());
    const [answerObjects, setAnswerObjects] = useState<Array<PostAnswerDto>>(new Array<PostAnswerDto>());
    const [isFormDisabled, setIsFormDisabled] = useState(true);
    const [message, setMessage] = useState<string>('');
    const handleClose = () => {
        setOpen(false);
    };
    const setObjField = (field: any, value: any) => {
        let t = JSON.parse(JSON.stringify(obj));
        t[field] = value;
        setObj(t);
    }

    const setAnswerField = (index: number, field: string, value: any) => {
        const updatedAnswers = JSON.parse(JSON.stringify(answerObjects));
        updatedAnswers[index][field] = value;
        setAnswerObjects(updatedAnswers);
    };

    const removeAnswer = (index: number) => {
        const updatedAnswers = answerObjects.filter((_, i) => i !== index);
        setAnswerObjects(updatedAnswers);
    };

    const questionForm = () => {
        if (!obj) return <></>;
        return (
            <Box mb={2}>
                <Stack direction={'row'} spacing={2} >
                    <MyTextField
                        fullWidth
                        id="name"
                        name="name"
                        variant="standard"
                        color="primary"
                        className="form-input"
                        _label={LL("Name")}
                        size="small"
                        value={obj.name}
                        setObj={setObjField}
                        _vresults={undefined}
                    />
                    <QuestionTypeSelect
                        id="type"
                        name="type"
                        variant="standard"
                        className="form-select"
                        color="primary"
                        setObj={setObjField}
                        field="type"
                        value={obj.type}
                    />
                </Stack>
                <Divider />
            </Box>
        )
    }
    const handleAddAnswer = () => {
        if (answerObjects.length === 0) setAnswerObjects([new PostAnswerDto()]);
        else setAnswerObjects([...answerObjects, new PostAnswerDto()]);
    }
    const answerForm = () => {
        if (!obj) return <></>;


        return (
            <Stack direction={'column'} spacing={2}>
                {answerObjects.map((answer, index) => {
                    return processAnswer(answer, index);
                })}
                <MyButton cb={handleAddAnswer}>
                    {LL("Add_answer")}
                </MyButton>
            </Stack>
        )
    }
    const processAnswer = (answer: PostAnswerDto, index: number) => {
        return (
            <Stack direction={'row'} spacing={2} key={index}>
                <MyTextField

                    fullWidth
                    id="name"
                    name="name"
                    variant="standard"
                    color="primary"
                    className="form-input"
                    _label={LL("Name")}
                    size="small"
                    value={answer.name}
                    setObj={(field: any, value: any) => {
                        setAnswerField(index, field, value)
                    }}
                    _vresults={undefined}
                />
                <MyTextField

                    fullWidth
                    id="score"
                    name="score"
                    variant="standard"
                    color="primary"
                    className="form-input"
                    _label={LL("score")}
                    size="small"
                    value={answer.score}
                    type="number"
                    setObj={(field: any, value: any) => {
                        setAnswerField(index, field, parseFloat(value))
                    }}
                    _vresults={undefined}
                />
                <AnswerTypeSelect
                    id="type"
                    name="type"
                    variant="standard"
                    className="form-select"
                    color="primary"
                    setObj={(field: any, value: any) => {
                        setAnswerField(index, field, value)
                    }}
                    field="type"
                    value={answer.type}
                />
                <IconButton onClick={() => removeAnswer(index)}>
                    <DeleteIcon />
                </IconButton>
            </Stack>
        )
    }

    const checkQuestionIsValid = () => {
        let rez = false;
        let errorMessage = '';

        if (!obj.name){ 
            rez = true;
            errorMessage = 'Question_cannot_be_empty';
        }
        if (answerObjects.length === 0) {
            rez = true;
            errorMessage = 'Question_must_have_at_least_one_answer';
        }

        let trueAnswerCount = 0;

        for (let i = 0; i < answerObjects.length; i++) {
            if (!answerObjects[i].name) {
                rez = true;
                errorMessage = 'Answer_cannot_be_empty'
                break;
            }
            if (answerObjects[i].type === Types.TRUE_ANSWER) {
                trueAnswerCount++;
            }
        }

        if (obj.type === Types.TYPE_QUESTION_SINGLE_CHOICE) {
            if (trueAnswerCount !== 1) {
                rez = true;
                errorMessage = 'Single_choice_question_must_have_one_true_answer'
            }
        } else {
            if (trueAnswerCount < 1) {
                rez = true;
                errorMessage = 'Multiple_choice_question_must_have_at_least_one_true_answer'
            }
        }

        
        setIsFormDisabled(rez);
        setMessage(errorMessage);
    }

    const processInformativeMessage = () => {
        if(!message) return <></>;
        return (
            <Box>
                <Typography>{LL(message)}</Typography>
            </Box>
        )
    }

    useEffect(() => {
        checkQuestionIsValid();
    }, [obj.name, obj.type, answerObjects])

    const processForm = () => {
        if (loading) return <CircularLoading />;
        if (!obj) return <></>;

        return (
            <Box>
                {questionForm()}
                {answerForm()}
                {processInformativeMessage()}
                <Box textAlign='center' my={4}>
                    <MyButton
                        tabIndex={1}
                        disabled={isFormDisabled}
                        color="primary"
                        variant="contained"
                        size="large"
                        className="btn"
                        cb={handleSubmit}
                        children={LL("Bt_Submit")}
                    />
                </Box>
            </Box>
        )
    }

    const calculatePositiveScoreSum = (answerObjects: PostAnswerDto[]) => {
        let sum = 0;
        for (const answer of answerObjects) {
            if(!answer.score) continue;
            if (answer.score > 0) {
                sum += answer.score;
            }
        }
        return sum;
    };

    const handleSubmit = () => {
        if(!obj) return;
        if(!obj.type) return;
        if(!obj.name) return;
        if(!answerObjects) return;
        if(answerObjects.length === 0) return;
        obj.totalscore = calculatePositiveScoreSum(answerObjects);
        obj.idtest = id;
        const postObj = new PostQuestionWithAnswerDto();
        postObj.question = obj;
        postObj.answers = answerObjects;
        if(!postObj) return;
        setLoading(true);
        questionService.addWithAnswers(handleResult,{}, postObj);
    }

    const handleResult = (result: ResultObjectDTO, cbparams: any) => {
        if (!result) return;
        if (!result.err) {
            setObjectWasChanged(CommonTools.generateRandomString(5));
            setOpen(false);
            setLoading(false);
            setObj(new PostQuestionDto());
            setAnswerObjects(new Array<PostAnswerDto>());

        }
    }

    return (<>

        <Dialog
            open={open}
            onClose={handleClose}
            fullWidth
            className="dialogAdd"

        >
            <DialogTitle>{LL("Add_question")}</DialogTitle>
            <IconButton sx={{
                position: 'absolute',
                right: 8,
                top: 8,
            }} onClick={handleClose}>
                <CloseIcon />
            </IconButton>
            <DialogContent dividers>

                {processForm()}
            </DialogContent>

        </Dialog>
    </>
    )
};

export { AddQuestionDialog };