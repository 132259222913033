import React from "react";
import PageComponentProps from "interfaces/pagecomponentprops.interface";
import { Box, Typography,Stack } from "@mui/material";
import { useResource } from "hook/useResource";
import { MyNavBt } from "components/general/MyNavBt";
import { CourseListSuggestions } from "components/course/course/CourseListSuggestions";
import { StudentNoCourseYet } from "components/page/StudentNoCourseYet";


const StudentNoCoursePage: React.FC<PageComponentProps> = ({ currentRoute, ...props }) => {
    const { LL } = useResource();
    const processSection = () => {
        return (
          <Box  className="container">
            <StudentNoCourseYet currentRoute={currentRoute} />
          </Box>
        )
    
    }

    const processBottom = () => {
        return (
          <Box className="container"  mt={5}>
         
            <Typography className="preText" textAlign='center' mb={4}>
              {LL("Recommended_courses")}
            </Typography>
            <Box>
                <CourseListSuggestions currentRoute={currentRoute}/>
            </Box>
          </Box>
        )
    
      }
      return (
        <Box className="withoutCourse">
          {processSection()}
          {processBottom()}
        </Box>
      );
};

export { StudentNoCoursePage };
