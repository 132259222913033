import React, { useState, useEffect } from "react";
import PageComponentProps from "interfaces/pagecomponentprops.interface";
import { useResource } from "hook/useResource";

import MyFileField from "components/form/myfilefield";
import { Box, Skeleton, Stack, Typography } from "@mui/material";

import { CommonTools } from "tools/commontools";
import { ComponentCommonTools } from "tools/componentcommontools";
import { MyButton } from "components/general/MyButton";
import { useMessage } from "hook/useMessage";
import RequestListDTO from "dto/app/requestlist.dto";
import RequestFilterDTO from "dto/app/requestfilter.dto";
import ResultListDTO from "dto/app/resultlist.dto";
import { PostVideoForCourseDto, VideoDto } from "dto/video/video.dto";
import MyTextField from "components/form/mytextfield";
import VideoService from "services/video/video.service";
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';

const videoService = new VideoService();

const EditCourseVideo: React.FC<PageComponentProps> = ({ currentRoute, ...props }) => {
    const { LL, _idlanguage } = useResource();
    const id = currentRoute._paths !== undefined ? currentRoute._paths[2] : '';
    const { registerDialog } = useMessage();

    const [loadingVideo, setLoadingVideo] = useState<boolean>(true);
    const [postObjVideo, setPostObjVideo] = useState<PostVideoForCourseDto>(new PostVideoForCourseDto());
    const [isFormDisabledVideo, setIsFormDisabledVideo] = useState(true);
    const [objectHasChangedVideo, setObjectHasChangedVideo] = useState<string>('');

    const validateAll = () => {

        if (!postObjVideo) return;
        setLoadingVideo(true);
        postObjVideo.idparent = id;
        postObjVideo.idlanguage = _idlanguage ?? _idlanguage;
        setPostObjVideo(postObjVideo);
        setLoadingVideo(false);
    };

    useEffect(() => {
        validateAll();
    }, [postObjVideo]);

    useEffect(() => {


        if (!postObjVideo.videolocation) setIsFormDisabledVideo(true);
        else setIsFormDisabledVideo(false);

    }, [postObjVideo.videolocation]);

    const setObjField = (field: any, value: any) => {
        let t = JSON.parse(JSON.stringify(postObjVideo));
        t[field] = value;
        setPostObjVideo(t);
    };

    const processVideoInputField = () => {
        if (!postObjVideo) return <></>;
        return (
            <Box sx={{minWidth:'350px'}}>
                <MyTextField
                    tabIndex={0}
                    fullWidth
                    id="videolocation"
                    name="videolocation"
                    variant="standard"
                    className="form-input"
                    color="primary"
                    _label={LL("videolocation")}

                    value={postObjVideo.videolocation}
                    setObj={setObjField}
                    _vresults={undefined}
                />
            </Box>
        )
    }

    const [video, setVideo] = useState<VideoDto | undefined | null>();
    const [isLoadingVideo, setIsLoadingVideo] = useState<boolean>(true);
    const getListVideo = () => {
        if (!id) return;
        setIsLoadingVideo(true);
        const reqList = new RequestListDTO();
        reqList.onpage = 1;
        reqList.page = 1;
        reqList.filters = [];
        reqList.sortcriteria = undefined;

        let f: RequestFilterDTO;


        f = new RequestFilterDTO();
        f.field = "idparent";
        f.values = [id];
        reqList.filters.push(f);

        videoService.getList(loadObjectsVideo, {}, reqList);

    }

    const loadObjectsVideo = (result: ResultListDTO) => {
        if (!result) return;
        if (!result.err) {
            const objects = result.objects ? result.objects : [];
            if (objects.length > 0) setVideo(objects[0]);
            else setVideo(null);
        }
    }

    const checkLoadingVideo = () => {
        let load = false;
        if (video === undefined) load = true;
        setIsLoadingVideo(load);
        setLoadingVideo(load);
    }

    useEffect(() => {
        checkLoadingVideo();
    }, [video]);

    useEffect(() => {
        getListVideo();
        setPostObjVideo(new PostVideoForCourseDto());
    }, [objectHasChangedVideo]);


    const processVideoDelete = () => {
        if (!video) return <></>;
        return (
            <Box>
                <MyButton
                    tabIndex={2}
                    cb={handleDelete}
                >
                        <DeleteIcon color="secondary"/>
                </MyButton>
            </Box>
        );
    }

    const handleDelete = () => {
        if (!video) return;
        registerDialog(LL('Confirm_delete_course_video'),
            LL('Are_you_sure_you_want_to_delete_this_course_video'),
            { cb: handleConfirmDelete }, null);
    }

    const handleConfirmDelete = () => {
        if (!video) return;
        if (!video.id) return;
        setLoadingVideo(true);
        videoService.delete(video.id,
            CommonTools.handleSuccessAddAndEditStayOnPage,
            { setObjectWasChanged: setObjectHasChangedVideo });
    }


    const processVideoPlayer = () => {
        if (!video) return <></>;
        if (isLoadingVideo) return <Skeleton variant="rectangular" height={200} />;
        return (<Box>
            {ComponentCommonTools.getFileBlockVideo(video, 200, 200)}
        </Box>)
    }
    const processVideoItem = () => {
        if (!postObjVideo) return <></>;
        if (loadingVideo) return <Skeleton variant="rectangular" height={200} />;
        if (isLoadingVideo) return <Skeleton variant="rectangular" height={200} />;
        return (
            <>
            <Typography variant="h3" textAlign='center' mt={5} mb={3}>{LL("Course_Video")}</Typography>
            <Box className="courseGallery">
            {processVideoPlayer()}
            <Box display='flex' flexDirection='row' alignItems='center' justifyContent='center'mt={3}>
           
            <form onSubmit={handleSubmitVideo}>
            <Stack direction='row'>
                {processVideoInputField()}
                <MyButton
                    tabIndex={2}
                    disabled={isFormDisabledVideo}
                    type="submit"
                ><SaveIcon /></MyButton>
            </Stack>
            </form>
            {processVideoDelete()}
            </Box>
            </Box>
        </>)
    }

    const handleSubmitVideo = (event: any) => {
        event.preventDefault();
        if (isFormDisabledVideo) return false;

        registerDialog(LL('Confirm_edit_course'),
            LL('Are_you_sure_you_want_to_edit_this_course_editing_will_change_the_status'),
            { cb: handleConfirmAddVideo }, null);
    };

    const handleConfirmAddVideo = () => {
        if (postObjVideo) {
            setLoadingVideo(true);
            videoService.addForCourse(
                CommonTools.handleSuccessAddAndEditStayOnPage, { setObjectWasChanged: setObjectHasChangedVideo }, postObjVideo);
        }
    }

    return processVideoItem();
};

export { EditCourseVideo };
