import React, { useEffect, useState } from "react";
import PageComponentProps from "interfaces/pagecomponentprops.interface";
import { useResource } from "hook/useResource";
import { logger } from "hoc/logger";
import {
    Box,
    Typography,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Skeleton,
    Stack
} from '@mui/material';
import MyTextField from 'components/form/mytextfield';
import CloseIcon from '@mui/icons-material/Close';
import RequiredValidator from "validators/required.validator";
import ValidateTools from "tools/validate.tools";
import { useAuth } from "hook/useAuth";
import ResultObjectDTO from "dto/app/resultobject.dto";
import { MyButton } from "components/general/MyButton";
import { Types } from "tools/types";
import { CircularLoading } from "components/general/CircularLoading";
import { CourseTeacherRequestDto, PostCourseTeacherRequestDto } from "dto/course/courseteacherrequest.dto";
import CourseTeacherRequestService from "services/course/courseteacherrequest.service";

import { Status } from "tools/status";
import { CommonTools } from "tools/commontools";
import { CourseVerifyButton } from "./CourseVerifyButton";
import { CourseUnpublishButton } from "./CourseUnpublishButton";
import { CourseDto } from "dto/course/course.dto";

type CourseUnpublishDialogProps = {
    currentRoute:any;
    course:CourseDto
}

const courseTeacherRequestService = new CourseTeacherRequestService();
const CourseUnpublishDialog: React.FC<CourseUnpublishDialogProps> = ({
    currentRoute,
    course,
    ...props
}) => {

    const { LL } = useResource();
    const { teacher } = useAuth();
    const [loading, setLoading] = useState<boolean>(false);
    const [obj, setObj] = useState<PostCourseTeacherRequestDto>(new PostCourseTeacherRequestDto());
    const [isFormDisabled, setIsFormDisabled] = useState(true);
    const [open, setOpen] = useState(false);
    const [objectWasChanged, setObjectWasChanged] = useState<string>('');
    const [idCourse, setIdCourse] = useState<string>('');

    const [validators, setValidators] = useState(false);
    const [vresults, setVResults] = useState<any>(false);

    const validateAll = () => {
        if (!obj) return;
        if (!validators) return;
        obj.teachermessage = obj.teachermessage !== undefined ? obj.teachermessage : '';
        setObj(obj);
        validateField("teachermessage", obj.teachermessage);
    };

    const validateField = (field: any, value: any, v?: any) => {
        v = (v) ? v : JSON.parse(JSON.stringify(vresults));
        if (v === false) v = {};
        v[field] = ValidateTools.validate(field, value, validators, vresults);
        setVResults(v);
        setIsFormDisabled(ValidateTools.vForm(v, field));
        return v;
    };

    const processValidators = () => {
        let v: any = {

            teachermessage: [],
        };

        v.teachermessage.push(new RequiredValidator('', ''));

        setValidators(v);
    };
    const checkLoading = () => {
        if (!loading) return;
        if (!obj) return;

        setLoading(false);
    };

    useEffect(() => {
        setIdCourse(CommonTools.getIdFromPath(currentRoute, 'editcourse', 1, 2));
        processValidators();
        validateAll();
        
    }, [currentRoute]);


    useEffect(() => {
        checkLoading();
    }, [obj, validators]);

    useEffect(() => {
        checkIsFormDisabled();
    }, [vresults]);

    const setObjField = (field: any, value: any) => {
        let t = JSON.parse(JSON.stringify(obj));
        t[field] = value;
        setObj(t);

        validateField(field, value);
    };

    const checkIsFormDisabled = () => {
        var rez = ValidateTools.vForm(vresults, 'teachermessage');

        setIsFormDisabled(rez);
    };

    const handleSubmit = (event: any) => {
        event.preventDefault();
        if (isFormDisabled) return false;

        if (!obj || !teacher || !idCourse) return;

        obj.idteacher = teacher.id;
        obj.idcourse = idCourse;

        setLoading(true);
        courseTeacherRequestService.unpublish(handleResult, {}, obj);
    };

    const handleResult = (result: ResultObjectDTO, cbparams: any) => {
        if (!result) return;
        if (!result.err) {
            setObjectWasChanged(CommonTools.generateRandomString(5))
            setOpen(false);
            setLoading(false);
            setObj(new PostCourseTeacherRequestDto());
        }
    }

    const handleClose = () => {
        setOpen(false);
        setObj(new PostCourseTeacherRequestDto());
    };

    const processForm = () => {
        if (!obj) return <></>;
        if (loading) return <CircularLoading />;

        return (
            <form onSubmit={handleSubmit}>
                <Box>
                    <Typography variant="h6" mb={1}> {LL('Message')}</Typography>
                    <MyTextField
                        multiline
                        minRows={4}
                        tabIndex={0}
                        fullWidth
                        id="teachermessage"
                        name="teachermessage"
                        variant="standard"
                        color="primary"
                        className="form-input"
                        _label={LL("teachermessage")}
                        size="small"
                        value={obj.teachermessage}
                        setObj={setObjField}
                        _vresults={vresults &&
                            vresults.teachermessage ?
                            vresults.teachermessage : false}
                    />
                </Box>

                <Box>
                    <MyButton
                        tabIndex={1}
                        disabled={isFormDisabled}
                        color="primary"
                        variant="contained"
                        size="large"
                        type="submit"
                        className="btn"
                        children={LL("Bt_Submit")}
                    />
                </Box>
            </form>
        )
    }

    const handleClickOpen = () => {
        setOpen(true);
    }


    const processComponent = () => {
        if (!teacher) return <></>;
        if (!idCourse) return <></>;

        return (<>
            <CourseUnpublishButton
                cb={handleClickOpen}
                objectWasChanged={objectWasChanged}
                idCourse={idCourse}
                course={course}
            />
            <Dialog
                open={open}
                onClose={handleClose}
                fullWidth
                className="dialogAdd"

            >
                <DialogTitle>
                    {LL("verify_course")}
                </DialogTitle>
                <IconButton sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                }} onClick={handleClose}>
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers>

                    {processForm()}
                </DialogContent>

            </Dialog>
        </>)
    }

    return processComponent()

};

export { CourseUnpublishDialog };
