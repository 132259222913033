import React, { useEffect, useState } from "react";
import { useResource } from "hook/useResource";
import { logger } from "hoc/logger";
import PageService from "services/page/page.service";
import { PageDto } from "dto/page/page.dto";
import { Loading } from "components/general/Loading";
import { HtmlTag } from "components/general/HtmlTag";
import { Box, Skeleton, Typography, Grid } from "@mui/material";
import { MainObjectImage } from "components/elements/media/MainObjectImage";


const pageService = new PageService();

type BecomeTeacherStepPageProps = {
    
    index: number;
    idParam: string;
}
const BecomeTeacherStepPage: React.FC<BecomeTeacherStepPageProps> = ({
    idParam,
    index,
    ...props
}) => {
    const { saveCache, getCache } = useResource();

    const [isLoading, setIsLoading] = useState(true);
    const [obj, setObject] = useState<PageDto | null>(null);


    const loadObject = (_obj: any) => {


        setObject(_obj.obj);
        setIsLoading(false);
        const cacheName = "BecomeTeacherStepPage" + index;
        saveCache(_obj.obj, cacheName);
    }

    const getObject = async () => {
        const id = idParam ?? "-1";

        const cacheName = "BecomeTeacherStepPage" + index;
        const cd = getCache(cacheName);
        if (cd !== false) {
            setObject(cd);
            setIsLoading(false);
            return;
        }

        setIsLoading(true);

        pageService.get(id, loadObject);
    };

    useEffect(() => {
        getObject();
    }, [idParam]);


    const processMainImage = () => {
        if (!obj) return <></>;
        if (!obj.hasOwnProperty("gallery")) return <></>;


        const _gallery = obj.gallery ? obj.gallery : undefined;
        return <Box className='image'><MainObjectImage gallery={_gallery} w={420} h={250} /></Box>;
    };

    const processHead = () => {
        if (!obj) return <></>;
        if (!obj.hasOwnProperty("name")) return <></>;
        if (!obj.name) return <></>;
        return <Typography variant="h5">{obj.name}</Typography>;
    };

    const processContent = () => {
        if (!obj) return <></>;
        if (!obj.hasOwnProperty("content")) return <></>;
        if (!obj.content) return <></>;

        return <HtmlTag content={obj.content} />;
    };

    const processPage = () => {
        if (!obj) return <></>;
        return (
            <Grid container spacing={2}  py={5}>
                <Grid item xs={12} lg={7}>
                {processHead()}
                {processContent()}
                </Grid >
                <Grid item xs={12} lg={5}>{processMainImage()}</Grid>

            </Grid>
        );
    }

    return isLoading ? (
        <Skeleton width={1000} height={500} />
    ) : (
        processPage()
    );
};

export { BecomeTeacherStepPage };
