import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useResource } from "hook/useResource";
import { logger } from "hoc/logger";
import TeacherService from "services/teacher/teacher.service";
import PageComponentProps from "interfaces/pagecomponentprops.interface";
import { TeacherDto } from "dto/teacher/teacher.dto";
import { RouteTools } from "tools/routetools";
import RequestListDTO from "dto/app/requestlist.dto";
import { Status } from "tools/status";
import RequestFilterDTO from "dto/app/requestfilter.dto";
import ResultListDTO from "dto/app/resultlist.dto";
import { Types } from "tools/types";
import { ComponentCommonTools } from 'tools/componentcommontools'
import { Loading } from "components/general/Loading";
import Skeleton from '@mui/material/Skeleton';
import { Box, Typography } from "@mui/material";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Grid from '@mui/material/Grid';
import { MyNavBt } from "components/general/MyNavBt";
import { TeacherCard } from "components/teacher/TeacherCard";
import ArrowRightAltSharpIcon from '@mui/icons-material/ArrowRightAltSharp';
const teacherService = new TeacherService();
const HomeTopTeacherBlock: React.FC<PageComponentProps> = ({ currentRoute, ...props }) => {
  const { saveCache, getCache } = useResource();
  const { LL ,CC} = useResource();
  const [isLoading, setIsLoading] = useState(true);
  const [objects, setObjects] = useState<Array<TeacherDto>>([]);


  const getList = async () => {
    const reqList = new RequestListDTO();
    reqList.onpage = parseInt(CC("Top_Teacher_Block_On_Page","12"));
    reqList.page = 1;


    const cd = getCache(reqList);

    if (cd !== false) {
      setObjects(cd);
      setIsLoading(false);
      return;
    }

    setIsLoading(true);

    const data = await teacherService.getListByRating(undefined, {}, reqList);

    const rez = !data || !data.objects ? [] : data.objects;

    setObjects(rez);
    setIsLoading(false);


    saveCache(rez, reqList);
  };

  useEffect(() => {
    getList();
  }, []);


  const processTeacherItem = (obj: TeacherDto, index: number) => {
    if (!obj) return <></>;
    return  <Grid item sm={6} lg={3} md={4} key={index}><TeacherCard obj={obj} /> </Grid>
  };


  const processTeacherList = () => {
    if (!objects.length) return <></>;

    return (
      <>
        <Box className="container topTeacherHome" py={5}>
          <Typography variant="h2" my={4}>
            {LL("Top_Teachers")}
          </Typography>
          <Box >
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
              {objects.map((item: TeacherDto, i: number) => {
                return processTeacherItem(item, i);
              })}
            </Grid>
          </Box>
          <Box>
            <MyNavBt 
            className="btn" 
            sx={{my:4}} 
            href="/teacher"
            >
              {LL("BT_Show_All_Teachers")} <ArrowRightAltSharpIcon sx={{ml:1}} />
            </MyNavBt>
          </Box>
        </Box>
      </>
    );
  };

  return isLoading ? <Skeleton /> : processTeacherList();
};

export { HomeTopTeacherBlock };




