import React, { useCallback, useEffect, useRef, useState } from "react";
import { useResource } from "hook/useResource";
import PageComponentProps from "interfaces/pagecomponentprops.interface";
import CourseService from "services/course/course.service";
import { CourseDto } from "dto/course/course.dto";
import { RouteTools } from "tools/routetools";
import RequestListDTO from "dto/app/requestlist.dto";
import { Status } from "tools/status";
import RequestFilterDTO from "dto/app/requestfilter.dto";
import ResultListDTO from "dto/app/resultlist.dto";
import { Types } from "tools/types";
import { Loading } from "components/general/Loading";
import Skeleton from '@mui/material/Skeleton';
import { Box, Typography, Grid } from "@mui/material";
import ResultObjectDTO from "dto/app/resultobject.dto";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { ComponentCommonTools } from "tools/componentcommontools";
import Stack from '@mui/material/Stack';
import AppBar from '@mui/material/AppBar';
import { MyNavBt } from "components/general/MyNavBt";
import { CourseCard } from "components/course/course/CourseCard";
import { Carousel } from "components/elements/Carousel/Carousel";
import RequestSortCriteriaDTO from "dto/app/requestsortcriteria.dto";
import { MyPagination } from "components/elements/MyPagination/MyPagination";
import { useCookies } from 'react-cookie';
import { CommonTools } from "tools/commontools";
import RequestCriteriaDTO from "dto/app/requestcriteria.dto";
import { MySortSelect } from "components/elements/Select/MySortSelect";
import SelectOptionSort from "dto/app/selectoptionsort.dto";

import { useAuth } from "hook/useAuth";
import { logger } from "hoc/logger";


import MyTextField from "components/form/mytextfield";
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import { StudentNoCoursePage } from "./StudentNoCoursePage";
import { StudentCourseCard } from "./StudentCourseCard";

import { NoResult } from "components/general/NoResult";

const courseService = new CourseService();

const StudentCoursePage: React.FC<PageComponentProps> = ({ currentRoute, ...props }) => {
  const { LL, CC } = useResource();
  const { user, student } = useAuth();
  const mainUrl = currentRoute && currentRoute.url ? currentRoute.url.split("?") : "";
  const [isLoading, setIsLoading] = useState(true);
  const [objects, setObjects] = useState<Array<CourseDto>>([]);
  const [cookies, setCookies] = useCookies();
  const [page, setPage] = useState<number>(1);
  const [total, setTotal] = useState<number>(-1);
  const [totalPage, setTotalPage] = useState<number>(-1);
  const [responseParams, setResponseParams] = useState<any>({});
  const [onPage, setOnPage] = useState<number>(parseInt(CC('CourseListOnPage', '10')));
  const [reqList, setReqList] = useState<RequestListDTO>(new RequestListDTO());
  const [sortCriteria, setSortCriteria] = useState<Array<RequestSortCriteriaDTO>>(new Array<RequestSortCriteriaDTO>());
  const [search, setSearch] = useState("");
  const [searchReq, setSearchReq] = useState("");


  const getList = () => {

    setIsLoading(true);

    reqList.onpage = onPage;
    reqList.page = page;
    reqList.filters = [];
    reqList.sortcriteria = sortCriteria;

    let f: RequestFilterDTO;

    if (student) {
      f = new RequestFilterDTO();
      f.field = "idstudent";
      f.values = [student.id];
      reqList.filters.push(f);


      f = new RequestFilterDTO();
      f.field = "search";
      f.values = [searchReq];
      reqList.filters.push(f);


      const urlPart = RouteTools.prepareListLocation(reqList);

      RouteTools.setHistory(mainUrl[0] + urlPart, {});
      courseService.getListForStudent(loadObjects, {}, reqList);
    }

  };


  const defaultSortCriteria = () => {
    if (!sortCriteria || sortCriteria.length === 0) {
      const sortCriteria = new RequestSortCriteriaDTO();
      sortCriteria.field = "courseenrollments.data";
      sortCriteria.asc = false;
      setSortCriteria([sortCriteria]);
    }
  }

  useEffect(() => {
    const reqListRoute = RouteTools.prepareListRequest(currentRoute, cookies);
    if (reqListRoute.page) setPage(reqListRoute.page);

    if (reqListRoute.sortcriteria && reqListRoute.sortcriteria.length > 0) {
      setSortCriteria(reqListRoute.sortcriteria);
    } else {
      defaultSortCriteria();
    }

  }, []);

  const loadObjects = (result: ResultListDTO) => {
    if (!result) return;
    if (!result.err) {
      const objects = result.objects ? result.objects : [];
      const total = result.total !== undefined ? result.total : -1;
      const totalPage = result.totalpages !== undefined ? result.totalpages : -1;
      const reqInfo = result.requestinfo ? result.requestinfo : {};
      setObjects(objects);
      setTotal(total);
      setTotalPage(totalPage);
      setResponseParams(reqInfo);
    }
  }

  const checkLoading = () => {
    let load = false;

    if (total === -1) return true;
    if (totalPage === -1) return true;
    if (!objects) load = true;
    // if (objects.length === 0) load = true;
    if (Object.keys(responseParams).length === 0) return true;

    setIsLoading(load);
  }
  useEffect(() => {
    if (sortCriteria && sortCriteria.length > 0) getList();
  }, [page, sortCriteria, searchReq]);

  useEffect(() => {
    checkLoading();
  }, [objects]);

  const processPagination = () => {
    if (totalPage === -1) return <></>;
    if (total === -1) return <></>;
    if (objects.length === 0) return <></>;

    return (
      <Box className="container" py={4} display='flex' sx={{ justifyContent: 'center' }}>
        <MyPagination
          page={page}

          total={total}
          totalPage={totalPage}
          setPage={setPage} />
      </Box>);
  }

  const processSortSelect = () => {
    if (isLoading) return <></>;

    const objects = [
      'popularidentifier_asc',
      'popularidentifier_desc',
      'name_asc',
      'name_desc',
      'courseenrollments.data_asc',
      'courseenrollments.data_desc'
    ];
    return (
      <Box sx={{
        "& label": {
          display: 'none',
        },
      }}>
        <MySortSelect
          objects={objects}
          setSortCriteria={setSortCriteria}
          sortCriteria={sortCriteria}
          variant="standard"
          className="form-select"


        />
      </Box>
    )
  }

  const processCourseItem = (obj: CourseDto, index: number) => {

    if (!obj) return <></>;
    return (<Box key={index}>
      <StudentCourseCard obj={obj} loading={isLoading} loadAction={getList} />
    </Box>);

  };

  const processCourseList = () => {
    if (!objects.length) return <NoResult identifier="course" />;

    return (
      <>
        {objects.map((item: CourseDto, i: number) => {
          return processCourseItem(item, i);
        })}
      </>
    );
  };


  const handleSubmit = (event: any) => {
    event.preventDefault();

    setSearchReq(search);
  };

  const processSearch = () => {
    return (

      <form onSubmit={handleSubmit}>
        <Box className="form-input-box" display='flex' alignItems='center'>
          <SearchIcon />
          <MyTextField
            fullWidth
            id="search"
            name="search"
            _label={LL("Search course by name")}
            value={search}
            setObj={(field: any, value: any) => { setSearch(value) }}
            _vresults={
              undefined
            }
          /></Box>
      </form>
    )
  }

  const processPageWithCourse = () => {

    return (
      <Box className="container">
        <Typography variant='h2' textAlign='center' my={4}>
          {LL("Title_page_student_my_course")}
        </Typography>
        <Stack direction={'row'} spacing={2} mb={4} useFlexGap flexWrap="wrap">
          {processSearch()}
          {processSortSelect()}

        </Stack>
        {isLoading ? <Skeleton /> : processCourseList()}
        {isLoading ? <Skeleton /> : processPagination()}
      </Box>
    )
  }
  const processPageWithoutCourse = () => {
    return <StudentNoCoursePage currentRoute={currentRoute} />
  }

  const processPage = () => {
    if (isLoading) return <Skeleton />;
    if(!student) return <></>;
    if (!student.hasOwnProperty('numberofcourses') || !student.numberofcourses) {
      return processPageWithoutCourse();
    }
    return processPageWithCourse();
  }

  return processPage()

};


export { StudentCoursePage };
