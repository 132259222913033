import React, { useEffect, useState } from "react";
import PageComponentProps from "interfaces/pagecomponentprops.interface";
import { useResource } from "hook/useResource";
import { logger } from "hoc/logger";
import MenuService from "services/menu/menu.service";
import { MyNavBt } from "components/general/MyNavBt";
import { IdCardIcon } from "components/general/IdCardIcon";
import Box from '@mui/material/Box';
import { useAuth } from "hook/useAuth";

const SignUpTeacherBlock: React.FC<PageComponentProps> = ({
  currentRoute,
  ...props
}) => {
  const { LL } = useResource();
  const { user } = useAuth();
  return user == undefined ? (
    <></>
  ) : (
    <MyNavBt href={"/becometeacherpage"} className="btnTeachTop">
      <IdCardIcon />
      <Box sx={{ display: 'flex', flexDirection: 'column', ml: 1 }}>
        <div>{LL("bt_top_teach")}</div>
        <div>{LL("bt_top_liveclass")}</div>
      </Box>
    </MyNavBt>
  );
};

export { SignUpTeacherBlock };
