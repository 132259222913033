
import Idto from 'interfaces/idto.interface';

export class PostAddressDto implements Idto {

    status?: number;
    street?: string;
    zipcode?: string;
    streetnumber?: number;
    idlocality?: string;
    iduser?: string;
    locality?: any;
}

export class AddressDto implements Idto {

    id?: string;
    status?: number;
    street?: string;
    zipcode?: string;
    streetnumber?: number;
    idlocality?: string;
    iduser?: string;
    locality?: any;
}