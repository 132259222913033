import React, { useState, useEffect, Dispatch, SetStateAction } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import CircularProgress from "@mui/material/CircularProgress";
import { logger } from "hoc/logger";
import { useResource } from "hook/useResource";
import RequestFilterDTO from "dto/app/requestfilter.dto";

import CourseCategoryService from "services/course/coursecategory.service";
import { Status } from "tools/status";
import { set } from "date-fns";
const service = new CourseCategoryService();

interface CourseCategoryAutocompleteProps {
  [x: string]: any;
  obj?: any;
  setObj: any;
  _defaultValue?: any;
}

const CourseCategoryAutocomplete: React.FC<CourseCategoryAutocompleteProps> = ({
  obj,
  setObj,
  _defaultValue,
  ...props
}) => {
  const { LL } = useResource();

  const idField = "idcoursecategory";
  const objField = "coursecategory";
  const searchValueField = "searchvalue";
  const labelShowField = "fullname";
  const labelText = LL("Autocomplete_CourseCategory");

  const [objects, setObjects] = useState<any>();
  const [value, setValue] = useState<any>(null);
  const [search, setSearch] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);

  const [open, setOpen] = useState<boolean>(false);
  const [options, setOptions] = useState<any>([{}]);

  const [label] = useState<string>(labelText);

  const onPage = 20;

  const [reqList] = useState<any>(service.getDefaultRequestListSelect(onPage));

  useEffect(() => {
    if (!options) return;
    if (_defaultValue && !options.length) {
      setOptions([_defaultValue]);
     return;
    }
    
    
  }, [_defaultValue, options, options.length]);

  const loadData = () => {
    if (search === undefined) {
      setOptions([]);
      setLoading(false);
      return;
    }

    const tmp: RequestFilterDTO = new RequestFilterDTO();
    tmp.field = searchValueField;
    tmp.values = [];
    tmp.values.push(search);
    const tmp2: RequestFilterDTO = new RequestFilterDTO();
    tmp2.field = "status";
    tmp2.values = [Status.ACTIVE.toString()];
    reqList.filters = [];
    reqList.filters.push(tmp, tmp2);

    getList();
  };

  const getList = () => {
    setLoading(true);
    service.getList(loadObjects, {}, reqList);
  };
  const loadObjects = (data: any) => {
    if (!data) setOptions([]);

    setOptions(data.objects);
    setLoading(false);
  };

  useEffect(() => {
    loadData();
  }, [search]);

  useEffect(() => {
    if (objects !== null && objects !== undefined) {
      setValue(objects);
    } else {
      setValue(null);
    }
  }, [objects]);

  useEffect(() => {
    if(_defaultValue && options.length) {
      setValue(_defaultValue);
    }
    if (isLoaded) return;
    setIsLoaded(true);
  }, [options,_defaultValue]);

  useEffect(() => {
    if (!isLoaded) return;
    if (!obj) return;
    if (!setObj) return;
    if (setObj == undefined) return;

    var _obj = obj;

    if (objects == null || objects == undefined) return;
    _obj[idField] = objects.id;
    _obj[objField] = objects;

    setObj(_obj);
  }, [value]);

  const handleChange = (value: any) => {
    setObjects(value);
  };

  useEffect(() => {
    if (!open) {
      setSearch("");
    }
  }, [open]);

  return (
    <Autocomplete
      id="asynchronous-demo"
      fullWidth
      size="small"
      className="form-autocomplete"
      sx={{ height: 40 }}
      open={open}
      value={value}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      onChange={(event, value) => {
        handleChange(value);
      }}
      isOptionEqualToValue={(option: any, value: any) => option.id === value.id}
      getOptionLabel={(option: any) =>
        option[labelShowField] ? option[labelShowField] : ""
      }
      options={options}
      loading={loading}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          onChange={(event) => {
            setSearch(event.target.value);
          }}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
};

export { CourseCategoryAutocomplete };
