import React, { useEffect, useState } from "react";
import { useResource } from "hook/useResource";
import { logger } from "hoc/logger";
import CourseCategoryService from "services/course/coursecategory.service";
import MenuLevelProps from "interfaces/menulevelprops.interface";
import { CourseCategoryDto } from "dto/course/coursecategory.dto";
import { RouteTools } from "tools/routetools";
import RequestListDTO from "dto/app/requestlist.dto";
import { Status } from "tools/status";
import RequestFilterDTO from "dto/app/requestfilter.dto";
import ResultListDTO from "dto/app/resultlist.dto";
import { Types } from "tools/types";
import MenuItemProps from "interfaces/menuitemprops.interface";
import { MyNavBt } from "components/general/MyNavBt";
import { InfoCourseCategoryLevel } from "./InfoCourseCategoryLevel";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { MyButton } from "components/general/MyButton";
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import { ComponentCommonTools } from 'tools/componentcommontools'
import Typography from "@mui/material/Typography";
import { CommonTools } from "tools/commontools";
import CardMedia from '@mui/material/CardMedia';

type InfoCourseCategoryCardProps = {
    obj: CourseCategoryDto;
    withImage?: boolean;
}

const InfoCourseCategoryCard: React.FC<InfoCourseCategoryCardProps> = ({
    obj,
    withImage = true,
    ...props
}) => {

    const [imgUrl, setImgUrl] = useState<string>("");
    const [fullUrl, setFullUrl] = useState<string>("");

    const prepareGallery = () => {
        if (!obj) return;
        
        if (!obj.hasOwnProperty('gallery')) return;
        if (!obj.gallery) return;
        if (obj.gallery.length === 0) return;
        const galleryObj = CommonTools.getGalleryObjectLogo(obj.gallery);
        if (!galleryObj) return;
        CommonTools.setFileUrlsGallery(galleryObj, setImgUrl, setFullUrl, 200, 150);
    }
    
    useEffect(() => {
        
        prepareGallery();
    }, [obj])

    const processImage = () => {
        if (!obj) return <></>;
        if (!withImage) return <></>;
        return (
            <>
                {ComponentCommonTools.getFileBlock(imgUrl, 310, 390)}
            </>
        )
    }

    const processDetail = () => {
        if (!obj) return <></>;


        return (
            
                <Card className="card">
                    <CardMedia>
                        {processImage()}
                    </CardMedia>
                    <CardContent>
                        <Typography component="div" className="name" variant="h5">
                            {ComponentCommonTools.processObjectField(obj, 'name')}
                        </Typography>
                    </CardContent>
                </Card>
            
        )
    };

    const processItem = () => {
        if (!obj) return <></>;
        if(!obj.hasOwnProperty('fullurl')) return processDetail();
        
        return obj.fullurl ? (
            <MyNavBt href={obj.fullurl}>{processDetail()}</MyNavBt>
        ) : (processDetail())

    };

    return processItem();


};

export { InfoCourseCategoryCard };
