import React, { useState, useContext, useEffect } from "react";
import { LessonDto } from "dto/course/lesson.dto";
import {
  Accordion,
  Typography,
  Stack,
  AccordionSummary,
  Box,
  AccordionDetails,
  Collapse,
  Grid,
  Divider
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { ComponentCommonTools } from "tools/componentcommontools";
import { MyButton } from "components/general/MyButton";
import { useResource } from "hook/useResource";
import { CommonTools } from "tools/commontools";
import { RouteTools } from "tools/routetools";

import { AttachmentListLessonStatic } from "components/attachment/AttachementListLessonStatic";
import { logger } from "hoc/logger";
import { GalleryListLessonStatic } from "components/gallery/GalleryListLessonStatic";
import { StudentLessonDialogDescription } from "components/student/StudentLessonDialogDescription";
import { StudentCourseContext } from "context/studentcourse/studentcourse.context";
import { StudentButtonFinishLesson } from "./StudentButtonFinishLesson";
import { VerifyLesson } from "./VerifyLesson";
import { VideoListLessonStatic } from "components/video/VideoListLessonStatic";
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PlayCircleOutlineOutlinedIcon from '@mui/icons-material/PlayCircleOutlineOutlined';

type ToolBoxLessonItemProps = {
  obj: LessonDto;
  currentRoute: any;
};

export const ToolBoxLessonItem: React.FC<ToolBoxLessonItemProps> = ({
  obj,
  currentRoute,
}) => {
  const {
    state: { courseEnrollmentObject, idCurrentLesson, lessonEnrollmentObject,selectedLessonObject },
    actions: { processDisabledInteraction, selectLesson },
  } = useContext(StudentCourseContext);

  const { LL } = useResource();
  const processDefaultExpanded = () => {
    if (!idCurrentLesson) return false;
    if (!obj) return false;
    if (!obj.hasOwnProperty("id")) return false;
    if (!obj.id) return false;
    if (idCurrentLesson !== obj.id) return false;
    return true;
  };

  const [expanded, setExpanded] = useState<boolean>(processDefaultExpanded());
  const [openDescription, setOpenDescription] = useState<boolean>(false);
  const [openResource, setOpenResource] = useState<boolean>(false);


  const processSelectedLesson = () => {
    if(!selectedLessonObject) return <></>;
    if(!selectedLessonObject.hasOwnProperty("id")) return <></>;
    if(!selectedLessonObject.id) return <></>;
    if(!obj) return <></>;
    if(!obj.hasOwnProperty("id")) return <></>;
    if(!obj.id) return <></>;
    if(selectedLessonObject.id !== obj.id) return <></>;
    return <Box><PlayArrowIcon color="primary"/></Box>
  }
  //Test
  useEffect(() => {
    if (!lessonEnrollmentObject) return;
    setExpanded(processDefaultExpanded());
  }, [lessonEnrollmentObject]);

  const handleOnClick = () => {
    setExpanded(!expanded);
  };

  const handleOnClickLesson = () => {
    if (!courseEnrollmentObject) return;
    if (!obj) return;
    if (!obj.hasOwnProperty("id")) return;
    if (!obj.id) return;

    selectLesson(obj.id);
  };

  const processButtonPlay = () => {
    return (
      <>
        <MyButton className="playButton" fullWidth cb={handleOnClickLesson}><PlayCircleOutlineOutlinedIcon/>{LL("Play this lesson")}</MyButton>
      </>
    );
  };

  const processButtonFinish = () => {
    logger("processButtonFinish", lessonEnrollmentObject, obj);
    if (!lessonEnrollmentObject) return <></>;
    if (!lessonEnrollmentObject.hasOwnProperty("idlesson")) return <></>;
    if (!lessonEnrollmentObject.idlesson) return <></>;
    if (!obj) return <></>;
    if (!obj.hasOwnProperty("id")) return <></>;
    if (!obj.id) return <></>;
    if (lessonEnrollmentObject.idlesson !== obj.id) return <></>;

    return (
      <Box>
        <StudentButtonFinishLesson  />
      </Box>
    );
  };

  const handleOpenDescription = () => {
    setOpenDescription(true);
  };
  const processDescriptionButton = () => {
    return (
      <Box>
        <MyButton cb={handleOpenDescription} fullWidth sx={{display:"flex", flexDirection:'column',}}>
          <i className="description-icon"></i>{LL("Description")}
          </MyButton>
      </Box>
    );
  };

  const handleOpenResource = () => {
    setOpenResource(!openResource);
  };

  const processResourceButton = () => {
    return (
      <Box>
        <MyButton cb={handleOpenResource} fullWidth sx={{display:"flex", flexDirection:'column',}}><i className="resource-icon"></i>{LL("Resource")}</MyButton>
      </Box>
    );
  };
  const processAttachmentList = () => {
    if (!obj) return <></>;
    if (!obj.hasOwnProperty("id")) return <></>;
    if (!obj.id) return <></>;
    if (!obj.hasOwnProperty("attachment")) return <></>;
    if (!obj.attachment) return <></>;

    return (
      <AttachmentListLessonStatic 
        objects={obj.attachment}
        disabled={processDisabledInteraction()}
      />
    );
  };

  const processVideoSelectionEnabled = () =>{
    if(processDisabledInteraction()) return true
    if(!selectedLessonObject) return true;
    if(!selectedLessonObject.hasOwnProperty("id")) return true;  
    if(!selectedLessonObject.id) return true;
    if(!obj) return true;
    if(!obj.hasOwnProperty("id")) return true;
    if(!obj.id) return true;
    if(selectedLessonObject.id !== obj.id) return true;
    return false;
  }
  const processVideoList = () => {
    console.log("processVideoList", currentRoute);
    if (!obj) return <></>;
    if (!obj.hasOwnProperty("id")) return <></>;
    if (!obj.id) return <></>;
    if (!obj.hasOwnProperty("video")) return <></>;
    if (!obj.video) return <></>;

    return (
      <VideoListLessonStatic
        objects={obj.video}
        disabled={processVideoSelectionEnabled()}
        currentRoute={currentRoute}
      />
    );
  };

  

  const processGalleryList = () => {
    if (!obj) return <></>;
    if (!obj.hasOwnProperty("id")) return <></>;
    if (!obj.id) return <></>;
    if (!obj.hasOwnProperty("gallery")) return <></>;
    if (!obj.gallery) return <></>;

    return (
      <GalleryListLessonStatic
        objects={obj.gallery}
        disabled={processDisabledInteraction()}
      />
    );
  };

  const processResourceMenu = () => {
    if (!openResource) return <></>;
    return (
      <Box>
        {processVideoList()}
        {processAttachmentList()}
        {processGalleryList()}
      </Box>
    );
  };

  const processComponent = () => {
    if (!obj) return <></>;
    if (!obj.hasOwnProperty("id")) return <></>;
    if (!obj.id) return <></>;

    return (
      <>
        <Accordion
          disabled={processDisabledInteraction()}
          expanded={expanded}
          onChange={handleOnClick}
          className='accordionLessons'
          square
          elevation={0}
          disableGutters
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panelLessonContent"
            id={"panelLessonHeader" + obj.id}
          >
            <Stack direction="row" spacing={1} alignItems={"center"}>
              {processSelectedLesson()}
              <Box>
                <VerifyLesson idLesson={obj.id} />
              </Box>
              <Box>
                <Typography>
                  {ComponentCommonTools.processObjectField(obj, "name")}
                </Typography>
              </Box>
            </Stack>
          </AccordionSummary>
          <AccordionDetails className="subAccordion">

              {processButtonPlay()}
              {processButtonFinish()}

            <Grid container className="zoneButtons">
              <Grid item xs={6} className="rightBorder">{processDescriptionButton()}</Grid>
              <Grid item xs={6}>{processResourceButton()}</Grid>
            </Grid>
            <Collapse in={openResource}>{processResourceMenu()}</Collapse>
          </AccordionDetails>
        </Accordion>
        <StudentLessonDialogDescription
          obj={obj}
          open={openDescription}
          setOpen={setOpenDescription}
        />
      </>
    );
  };
  return processComponent();
};
