import React, { useEffect, useState } from "react";
import PageComponentProps from "interfaces/pagecomponentprops.interface";
import { useResource } from "hook/useResource";
import { logger } from "hoc/logger";
import Box from '@mui/material/Box'
import { MyNavBt } from "components/general/MyNavBt";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import MyTextField from 'components/form/mytextfield';
import { IconButton } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';

type SearchDialogProps = {
    open: boolean;
    setOpen: any;
}
const SearchDialog: React.FC<SearchDialogProps> = ({
    open,
    setOpen,
    ...props
}) => {
    const { LL } = useResource();

    const [search, setSearch] = useState("");


    const handleSubmit = (event: any) => {
        event.preventDefault();

        logger("SearchDialogSearchDialog", search);
    };




    const handleClose = () => {
        setOpen(false);
    };

    return (
        
            <Dialog
                open={open}
                onClose={handleClose}
                fullWidth
                sx={{
                    '& .MuiDialog-paper': {
                        position: 'absolute',
                        top: '3%',
                    }
                }}

            >
                    <DialogTitle>{LL("search in site")}</DialogTitle>
                    <IconButton sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,}} onClick={handleClose}>
                        <CloseIcon />
                    </IconButton>
                    <DialogContent dividers>
                        <form onSubmit={handleSubmit}>
                            <MyTextField
                                fullWidth
                                id="search"
                                name="search"
                                size="small"
                                variant="outlined"
                                _label={LL("Type something for search")}
                                value={search}
                                setObj={(field: any, value: any) => { setSearch(value) }}
                                _vresults={
                                    undefined
                                }
                            />
                        </form>
                        
                    </DialogContent>
                
            </Dialog>
        
    );
};

export { SearchDialog };
