import React, { useEffect, useState } from "react";
import PageComponentProps from "interfaces/pagecomponentprops.interface";
import { useResource } from "hook/useResource";
import { logger } from "hoc/logger";

import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'


import Grid from '@mui/material/Grid';
import { useAuth } from "hook/useAuth";
import { Divider, IconButton } from "@mui/material";

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { TabPanel } from 'components/elements/Tabs/TabPanel';

import { CircularLoading } from 'components/general/CircularLoading';
import { useCookies } from 'react-cookie';
import { MyButton } from "components/general/MyButton";
import { RouteTools } from "tools/routetools";
import UserDto from "dto/user/user.dto";
import ValidateTools from 'tools/validate.tools';
import RequiredValidator from 'validators/required.validator';
import { LabelTypes } from 'tools/labeltypes';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import MyTextField from 'components/form/mytextfield';
import { CommonTools } from "tools/commontools";
import { CourseDto } from "dto/course/course.dto";
import CourseService from "services/course/course.service";
import ResultObjectDTO from "dto/app/resultobject.dto";
import { ComponentCommonTools } from "tools/componentcommontools";
import { MyNavBt } from "components/general/MyNavBt";
import { EditGeneralInfo } from "components/course/courseteacherinterface/EditGeneralInfo";
import EditCourseSettingsInfo from "components/course/courseteacherinterface/EditCourseSettings";
import EditCoursePrice from "components/course/courseteacherinterface/EditCoursePrice";
import EditLinkCourseCategory from "components/course/courseteacherinterface/EditLinkCourseCategory";
import EditCourseFilter from "components/course/courseteacherinterface/EditCourseFilter";
import { EditCourseMedia } from "components/course/courseteacherinterface/EditCourseMedia";
import EditCourseLesson from "../course/courseteacherinterface/EditCourseLesson";
import EditCourseTest from "components/course/courseteacherinterface/EditCourseTest";
import CourseActions from "components/course/courseteacherinterface/CourseActions";
import { SelectCoursePrice } from "components/course/courseteacherinterface/SelectCoursePrice";
import EditOnlyOneCourseCategory from "components/course/courseteacherinterface/EditOnlyOneCourseCategory";

const courseService = new CourseService();

const TeacherEditCourse: React.FC<PageComponentProps> = ({
    currentRoute,
    ...props
}) => {

    const { LL } = useResource();
    const { user, teacher } = useAuth();

    const [loading, setLoading] = useState<boolean>(true);
    const [obj, setObj] = useState<CourseDto | undefined>();
    const [objectHasChanged, setObjectHasChanged] = useState<string>('');

    const id = currentRoute._paths !== undefined ? currentRoute._paths[2] : '';



    const checkLoading = () => {
        if (!loading) return;
        if (obj === undefined) return;

        setLoading(false);
    };

    const loadObject = (obj: ResultObjectDTO) => {
        if (!obj) return;
        if (!obj.err) {
            const object = obj.obj ? obj.obj : {};
            setObj(object);
        }
    };

    const getObject = () => {
        setLoading(true);
        if (id !== undefined) {

            courseService.get(id, loadObject, {});
        }
    };

    useEffect(() => {
        getObject();
    }, [currentRoute, objectHasChanged]);

    useEffect(() => {
        checkLoading();
    }, [obj]);


    const [value, setValue] = React.useState(CommonTools.getAnchor(currentRoute, 'courseActions'));

    const handleChange = (event: React.SyntheticEvent, newValue: any) => {
        setValue(newValue);
        RouteTools.setAnchor(newValue)
    };


 
    return user !== undefined && teacher && !loading && obj ? (
        <>

            <Box className='container' display='flex' flexDirection="row" my={4}>
                <MyNavBt
                    href={"/teacherinterface/course"}
                    size="large"
                >
                    <IconButton color="primary" ><ArrowBackIcon /></IconButton>
                </MyNavBt>
                <Typography variant='h2' textAlign="center" mx='auto'>{ComponentCommonTools.processObjectField(obj, 'name')}</Typography>
            </Box>

            <Box sx={{ flexGrow: 1 }} className='container' mt={2}>
                <Box my={4} sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Tabs
                        variant="scrollable"
                        value={value}
                        onChange={handleChange}
                        orientation="horizontal"
                        scrollButtons
                        allowScrollButtonsMobile
                        className="tabVertical profileTeacher"
                    >
                        <Tab label={LL("Course_Actions")} value={'courseActions'} />
                        <Tab label={LL("General_Info")} value={'generalInfo'} />
                        <Tab label={LL("Course_Settings")} value={'courseSettings'} />
                        <Tab label={LL("Course_Price")} value={'coursePrice'} />
                        <Tab label={LL("Course_Category")} value={'courseCategory'} />
                        <Tab label={LL("Course_Filter")} value={'courseFilter'} />
                        <Tab label={LL("Course_Media")} value={'courseMedia'} />
                        <Tab label={LL("Course_Lesson")} value={'courseLesson'} />
                        <Tab label={LL("Course_Test")} value={'courseTest'} />
                    </Tabs>
                </Box>
                <Box maxWidth="md" mx='auto'>
                    <TabPanel value={value} index={'courseActions'}>
                        <CourseActions
                            currentRoute={currentRoute}
                            setCourse={setObj}
                            course={obj}
                        />
                    </TabPanel>

                    <TabPanel value={value} index={'generalInfo'}>
                        <EditGeneralInfo
                            currentRoute={currentRoute}
                            defaultObj={obj}
                            setObjectWasChanged={setObjectHasChanged}
                            objectWasChanged={objectHasChanged}
                        />

                    </TabPanel>
                    <TabPanel value={value} index={'courseSettings'}>
                        <EditCourseSettingsInfo currentRoute={currentRoute} setObjectCourseHasChanged={setObjectHasChanged} />
                    </TabPanel>
                    <TabPanel value={value} index={'coursePrice'}>
                        <SelectCoursePrice
                            currentRoute={currentRoute}
                            course={obj}
                        />
                    </TabPanel>
                    <TabPanel value={value} index={'courseCategory'}>
                        <EditOnlyOneCourseCategory currentRoute={currentRoute} />
                    </TabPanel>
                    <TabPanel value={value} index={'courseFilter'}>
                        <EditCourseFilter currentRoute={currentRoute} />
                    </TabPanel>
                    <TabPanel value={value} index={'courseMedia'}>
                        <EditCourseMedia currentRoute={currentRoute} />
                    </TabPanel>
                    <TabPanel value={value} index={'courseLesson'}>
                        <EditCourseLesson currentRoute={currentRoute} />
                    </TabPanel>
                    <TabPanel value={value} index={'courseTest'}>
                        <EditCourseTest currentRoute={currentRoute} />
                    </TabPanel>
                </Box>


            </Box>

        </>
    ) : (
        <CircularLoading />
    );
};

export { TeacherEditCourse };
