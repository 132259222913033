import React, { useEffect, useState } from "react";
import PageComponentProps from "interfaces/pagecomponentprops.interface";
import { TicketDto } from "dto/support/ticket.dto";
import { Box, Grid } from "@mui/material";
import { TicketListMenu } from "./TicketListMenu";
import { useAuth } from "hook/useAuth";
import { Types } from "tools/types";
import { SupportSelectedTicket } from "./SupportSelectedTicket";
import { NoResult } from "components/general/NoResult";
import { TeacherResponseForm } from "./TeacherResponseForm";

const SupportBlockTeacher: React.FC<PageComponentProps> = ({ currentRoute, ...props }) => {

    const [selectedTicket, setSelectedTicket] = useState<TicketDto | undefined>(undefined);
    const [total, setTotal] = useState<number>(-1);
    const [objWasChanged, setObjWasChanged] = useState<string>("");
    const [ticketObjects, setTicketObjects] = useState<Array<TicketDto>>([]);
    const [ticketIndex, setTicketIndex] = useState<number>(-1);
    const { teacher } = useAuth();

    const processForm = () => {
        if (!selectedTicket) return <></>;
        return (
            <TeacherResponseForm
                ticket={selectedTicket}
                setObjWasChanged={setObjWasChanged}
                objWasChanged={objWasChanged}
                setTicketObjects={setTicketObjects}
                ticketObjects={ticketObjects}
                index={ticketIndex}
            />
        )
    }
    const processComponent = () => {
        if (!teacher) return <></>;
        if (total === 0) return <NoResult identifier="ticket" />;

        return (
            <Box>
                <Grid container spacing={2}>
                    <Grid item xs={10}>
                        <SupportSelectedTicket
                            obj={selectedTicket}
                            objWasChanged={objWasChanged}
                            setObjWasChanged={setObjWasChanged}
                        />
                        {processForm()}
                    </Grid>
                    <Grid item xs={2}>
                        <TicketListMenu
                            selectedTicket={selectedTicket}
                            setSelectedTicket={setSelectedTicket}
                            userObject={teacher}
                            userType={Types.TYPE_TICKET_TEACHER}
                            userObjectField="idteacher"
                            total={total}
                            setTotal={setTotal}
                            setObjects={setTicketObjects}
                            objects={ticketObjects}
                            setIndex={setTicketIndex}
                        />
                    </Grid>
                </Grid>
            </Box>);
    }
    return processComponent();
};

export { SupportBlockTeacher };
