import React, { useEffect, useState } from "react";
import PageComponentProps from "interfaces/pagecomponentprops.interface";
import { TicketDto } from "dto/support/ticket.dto";
import { Box, Grid } from "@mui/material";
import { TicketListMenu } from "./TicketListMenu";
import { useAuth } from "hook/useAuth";
import { Types } from "tools/types";
import { SupportSelectedTicket } from "./SupportSelectedTicket";
import { NoResult } from "components/general/NoResult";
import { StudentResponseForm } from "./StudentResponseForm";

const SupportBlockStudent: React.FC<PageComponentProps> = ({ currentRoute, ...props }) => {

    const [selectedTicket, setSelectedTicket] = useState<TicketDto | undefined>(undefined);
    const [total, setTotal] = useState<number>(-1);
    const [objWasChanged, setObjWasChanged] = useState<string>("");
    const [ticketObjects, setTicketObjects] = useState<Array<TicketDto>>([]);
    const [ticketIndex, setTicketIndex] = useState<number>(-1);
    const { student } = useAuth();

    const processForm = () => {
        if (!selectedTicket) return <></>;
        return (
            <StudentResponseForm
                ticket={selectedTicket}
                setObjWasChanged={setObjWasChanged}
                objWasChanged={objWasChanged}
                setTicketObjects={setTicketObjects}
                ticketObjects={ticketObjects}
                index={ticketIndex}
            />
        )
    }
    const processComponent = () => {
        if (!student) return <></>;
        if (total === 0) return <NoResult identifier="ticket" />;

        return (
            <Box>
                <Grid container spacing={2}>
                    <Grid item xs={10}>
                        <SupportSelectedTicket
                            obj={selectedTicket}
                            objWasChanged={objWasChanged}
                            setObjWasChanged={setObjWasChanged}
                        />
                        {processForm()}
                    </Grid>
                    <Grid item xs={2}>
                        <TicketListMenu
                            selectedTicket={selectedTicket}
                            setSelectedTicket={setSelectedTicket}
                            userObject={student}
                            userType={Types.TYPE_TICKET_STUDENT}
                            userObjectField="idstudent"
                            total={total}
                            setTotal={setTotal}
                            setObjects={setTicketObjects}
                            objects={ticketObjects}
                            setIndex={setTicketIndex}
                        />
                    </Grid>
                </Grid>
            </Box>);
    }
    return processComponent();
};

export { SupportBlockStudent };
