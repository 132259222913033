import React, { useState, useEffect } from 'react';


import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import RequestListDTO from "dto/app/requestlist.dto";
import { TestimonialDto } from "dto/testimonial/testimonial.dto";
import TestimonialService from "services/testimonial/testimonial.service";
import { Status } from "tools/status";
import RequestFilterDTO from "dto/app/requestfilter.dto";
import { useResource } from "hook/useResource";
import ResultListDTO from "dto/app/resultlist.dto";
import Skeleton from '@mui/material/Skeleton';
import { TestimonialCard } from "components/testimonial/TestimonialCard";
import { CourseDetailTestimonialCard } from 'components/testimonial/CourseDetailTestimonialCard';

const testimonialService = new TestimonialService();

interface CourseTestimonialsProps {
    idCourse: string
}

const CourseTestimonials: React.FC<CourseTestimonialsProps> = ({
    idCourse,
    ...props
}) => {


    const { LL, CC } = useResource();
    const [isLoading, setIsLoading] = useState(true);
    const [objects, setObjects] = useState<Array<TestimonialDto>>([]);

    const getList = () => {
        const reqList = new RequestListDTO();
        reqList.onpage = parseInt(CC("CourseTestimonial_OnPage_Show", "10"));
        reqList.page = 1;
        reqList.filters = [];

        let f: RequestFilterDTO;

        f = new RequestFilterDTO();
        f.field = "status";
        f.values = [];
        f.values.push(Status.ACTIVE.toString());
        reqList.filters.push(f);

        f = new RequestFilterDTO();
        f.field = "idcourse";
        f.values = [idCourse];
        reqList.filters.push(f);

        setIsLoading(true);

        testimonialService.getList(loadObjects, {}, reqList);

    };
    const loadObjects = (result: ResultListDTO) => {
        if (!result) return;
        if (!result.err) {
            const objects = result.objects ? result.objects : [];
            // const total = result.total !== undefined ? result.total : -1;
            // const totalPage = result.totalpages !== undefined ? result.totalpages : -1;
            // const reqInfo = result.requestinfo ? result.requestinfo : {};
            setObjects(objects);
            // setTotal(total);
            // setTotalPage(totalPage);
            // setResponseParams(reqInfo);
        }
    }

    const checkLoading = () => {
        let load = false;

        // if (total === -1) return true;
        // if (totalPage === -1) return true;
        if (!objects) load = true;
        // if (objects.length === 0) load = true;
        // if (Object.keys(responseParams).length === 0) return true;

        setIsLoading(load);
    }

    useEffect(() => {
        if (!idCourse) return;
        getList();
    }, [idCourse]);

    useEffect(() => {
        checkLoading();
    }, [objects]);


    const processTestimonialItem = (obj: TestimonialDto, index: number) => {
        if (!obj) return <></>;

        return (<Box
            key={index}
        >
            <CourseDetailTestimonialCard obj={obj} />
        </Box>)
    };


    const processTestimonialList = () => {
        if (!objects.length) return <></>;

        return (

            <Box className="courseReview" >

                {objects.map((item: TestimonialDto, i: number) => {
                    return processTestimonialItem(item, i)
                })}

            </Box>

        );
    };

    return isLoading ? <Skeleton /> : processTestimonialList();

};

export { CourseTestimonials };
