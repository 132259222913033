import Idto from 'interfaces/idto.interface';

export class TicketMessageDto implements Idto {

  id?: string;
  status?: number;
  idoperator?: string;
  idstudent?: string;
  idteacher?: string;
  typesender?: number;
  idticket?: string;
  message?: string;

}

export class PostTicketMessageDto implements Idto {

  typesender?: number;
  status?: number;
  idoperator?: string;
  idstudent?: string;
  idteacher?: string;
  idticket?: string;
  message?: string;
}


export class ResponseTicketStudentDto implements Idto {
  
  idticket?: string;
  message?: string;
  idstudent?: string;
  typesender?: number;
  status?: number;

}

export class ResponseTicketTeacherDto implements Idto {
  
  idticket?: string;
  message?: string;
  idteacher?: string;
  typesender?: number;
  status?: number;

}