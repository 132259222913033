import React, { useEffect, useState } from "react";

import { useResource } from "hook/useResource";
import { logger } from "hoc/logger";

import { Chip } from '@mui/material';


import { CourseDto } from "dto/course/course.dto";
import { Status } from "tools/status";

type TypeCourseCardChipProps = {
    [x: string]: any;
    obj: CourseDto
}


const StatusChipCourse: React.FC<TypeCourseCardChipProps> = ({
    obj,
    ...props
}) => {

    const processColor = (value: number) => {
        switch (value) {
            case Status.ACTIVE:
                return "success";
            case Status.INACTIVE:
                return "error";
            case Status.COURSE_DRAFT:
                return "warning";
            case Status.COURSE_UNVERIFIED:
                return "error";
            case Status.COURSE_VERIFIED:
                return "info";
            case Status.COURSE_ARCHIVED:
                return "info";
            case Status.COURSE_DELETED:
                return "error";
            default:
                return "primary";
        }

    }
    const processChip = () => {
        if (!obj) return <></>;
        if (!obj.hasOwnProperty('status')) return <></>;
        if (!obj.status) return <></>;
        if (!obj.status_name) return <></>;
        const color = processColor(obj.status);


        return (
            <Chip label={obj.status_name} {...props} color={color} />
        );
    }


    return (<>{processChip()}</>);
};

export { StatusChipCourse };
