import React, { useState, useEffect } from "react";
import { useResource } from "hook/useResource";
import { AnswerDto } from "dto/course/answer.dto";
import RequestFilterDTO from "dto/app/requestfilter.dto";
import RequestListDTO from "dto/app/requestlist.dto";
import { Status } from "tools/status";
import AnswerService from "services/course/answer.service";
import ResultListDTO from "dto/app/resultlist.dto";
import { Paper, Box, IconButton, Skeleton, Typography, Stack, Card, CardContent } from "@mui/material";

import RequestSortCriteriaDTO from "dto/app/requestsortcriteria.dto";
import { MySortSelect } from "components/elements/Select/MySortSelect";
import { MyButton } from "components/general/MyButton";
import { LoadMoreButton } from "components/elements/MyButton/LoadMoreButton";
import AccessTimeIcon from '@mui/icons-material/AccessTime';

import { AttachmentDialog } from "components/attachment/AttachmentDialog";
import { GalleryDialog } from "components/gallery/GalleryDialog";
import { VideoDialog } from "components/video/VideoDialog";
import VideocamIcon from '@mui/icons-material/Videocam';
import CollectionsIcon from '@mui/icons-material/Collections';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import DeleteIcon from '@mui/icons-material/Delete';
import { logger } from "hoc/logger";

import { useMessage } from "hook/useMessage";
import { CommonTools } from "tools/commontools";
import { ComponentCommonTools } from "tools/componentcommontools";


type AnswerListItemProps = {
    obj: AnswerDto
    setObjectWasChanged: any;
}

const answerService = new AnswerService();

const AnswerListItem: React.FC<AnswerListItemProps> = ({

    obj,
    setObjectWasChanged,
    ...props }) => {

    const { LL } = useResource();
    const { registerDialog } = useMessage();
    const [openDialogAttachment, setOpenDialogAttachment] = useState(false);
    const handleOpenDialogAttachment = () => {
        setOpenDialogAttachment(true);

    };

    const [openDialogGallery, setOpenDialogGallery] = useState(false);
    const handleOpenDialogGallery = () => {
        setOpenDialogGallery(true);
    };

    const [openDialogVideo, setOpenDialogVideo] = useState(false);

    const handleOpenDialogVideo = () => {

        setOpenDialogVideo(true);
    };

    const handleActionDelete = () => {
        registerDialog(LL('Confirm_delete_answer'),
            LL('Are_you_sure_you_want_to_delete_this_answer'),
            { cb: handleDelete }, null);
    }

    const handleDelete = () => {
        if (!obj) return;
        if (!obj.id) return;
        answerService.delete(obj.id,CommonTools.handleSuccessAddAndEditStayOnPage, {setObjectWasChanged});
    }

    const processActions = () => {
        if (!obj) return (<></>)
        if (!obj.id) return (<></>)
        return (
            <>
                <AttachmentDialog
                    open={openDialogAttachment}
                    setOpen={setOpenDialogAttachment}
                    idparent={obj.id}
                    parent="answer"
                />
                <GalleryDialog
                    open={openDialogGallery}
                    setOpen={setOpenDialogGallery}
                    idparent={obj.id}
                    parent="answer"
                />
                <VideoDialog
                    open={openDialogVideo}
                    setOpen={setOpenDialogVideo}
                    idparent={obj.id}
                    parent="answer"
                />
            </>
        )
    }

    return (
        <>
            {processActions()}
            <Card>
                <CardContent>
                    <Stack
                        direction={'row'}
                        spacing={3}
                    >
                        <Typography>
                            {ComponentCommonTools.processObjectField(obj, 'name')}
                        </Typography>
                        <Typography>
                            {ComponentCommonTools.processObjectField(obj, 'score')}
                        </Typography>
                        <Typography>
                            {ComponentCommonTools.processObjectField(obj, 'type_name')}
                        </Typography>
                        <Box >
                            <IconButton
                                onClick={handleOpenDialogAttachment}
                            >
                                <AttachFileIcon />
                            </IconButton>
                            <IconButton

                                onClick={handleOpenDialogGallery}
                            >
                                <CollectionsIcon />
                            </IconButton>
                            <IconButton
                                onClick={handleOpenDialogVideo}
                            >
                                <VideocamIcon />
                            </IconButton>
                            <IconButton
                                onClick={handleActionDelete}
                            >
                                <DeleteIcon />
                            </IconButton>
                        </Box>
                    </Stack>
                </CardContent>
            </Card>

        </>
    );
};

export { AnswerListItem };
