import Idto from 'interfaces/idto.interface';

export default class UserSettingsDto implements Idto {
  id?: string;
  iduser?: string;
  name?: string;
  surname?: string;
  birthday?: Date ;
  idtypegender?: string;
  idsphone?: string[];
  idlanguage?: string;
  files?: any;
  deleteavatar?: boolean;
  idavatar?: string | null;
  idphysicaladdress?: string;
  avatar?: any;
}
