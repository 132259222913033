
import Idto from 'interfaces/idto.interface';

export class WishlistDto implements Idto {
    id?: string;
    idcourse?: string;
    iduser?: string;
    date?: number;
    course?: any;
}

export class PostWishlistDto implements Idto {

    idcourse?: string;
    iduser?: string;
    date?: number;
    course?: any;
}


export class CheckCourseInWishlist implements Idto {

    isinwishlist?: boolean;
    id?: string;
}