import React, { useEffect, useState, useRef } from "react";
import PageComponentProps from "interfaces/pagecomponentprops.interface";
import { useResource } from "hook/useResource";
import { logger } from "hoc/logger";
import { Box, Typography } from '@mui/material';
import { MyNavBt } from "components/general/MyNavBt";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import MyTextField from 'components/form/mytextfield';
import { IconButton, Skeleton, Stack } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import Snackbar from '@mui/material/Snackbar';
import { useMessage } from "hook/useMessage";
import { CommonTools } from "tools/commontools";
import { MessageTypes } from "tools/messagetypes";
import { ShareElements } from "components/elements/Share/ShareElements";

import RequiredValidator from "validators/required.validator";
import ValidateTools from "tools/validate.tools";
import { LocalityAutocompleteV2 } from "components/elements/Autocomplete/LocalityAutocompleteV2";
import MyDatePicker from "components/elements/MyDatePicker/MyDatePicker";

import { useAuth } from "hook/useAuth";
import ResultObjectDTO from "dto/app/resultobject.dto";
import { MyButton } from "components/general/MyButton";
import { Types } from "tools/types";

import { CircularLoading } from "components/general/CircularLoading";
import MyCKEditor from "components/elements/ckeditor/MyCKEditor";
import { TestDto, PostTestDto } from "dto/course/test.dto";
import TestService from "services/course/test.service";
import MyCheckboxField from "components/form/mycheckboxfield";
import { TestTypeSelect } from "components/elements/Select/TestTypeSelect";
import MyDateTimePicker from "components/elements/MyDatePicker/MyDateTimePicker";
import DateTools from "tools/date.tools";
import { LessonSelect } from "components/elements/Select/LessonSelect";

const testService = new TestService();

type AddTestDialogProps = {
    open: boolean;
    setOpen: any;
    id: string;
    setObjectWasChanged: any;
    idLesson?: string;
}
const AddTestDialog: React.FC<AddTestDialogProps> = ({
    open,
    setOpen,
    id,
    setObjectWasChanged,
    idLesson,
    ...props
}) => {
    const { LL } = useResource();

    const [loading, setLoading] = useState<boolean>(true);
    const [obj, setObj] = useState<PostTestDto>(new PostTestDto());
    const [isFormDisabled, setIsFormDisabled] = useState(true);


    const [validators, setValidators] = useState(false);
    const [vresults, setVResults] = useState<any>(false);
    const validateAll = () => {
        setLoading(true);
        if (!obj) return;
        if (!validators) return;
        // if (vresults) return;
        
        obj.name = obj.name !== undefined ? obj.name : '';
        obj.mintestscore = obj.mintestscore !== undefined ? obj.mintestscore : 0;
        obj.idlesson = obj.idlesson ? obj.idlesson : null;
        processIdLesson();
        setObj(obj);

        let t = validateField("name", obj.name);
        
    };

    const processIdLesson = () => {
        if (!idLesson) return;
        obj.idlesson = idLesson;
        obj.type = Types.TYPE_TEST_LESSON;
        
    }

    const validateField = (field: any, value: any, v?: any) => {
        v = (v) ? v : JSON.parse(JSON.stringify(vresults));
        if (v === false) v = {};
        v[field] = ValidateTools.validate(field, value, validators, vresults);
        setVResults(v);
        return v;
    };

    const processValidators = () => {
        let v: any = {

            name: [],
            mintestscore: [],
        };

        v.name.push(new RequiredValidator('', ''));
        v.mintestscore.push(new RequiredValidator('', ''));

        setValidators(v);
    };

    const checkLoading = () => {
        if (!obj) return;
        if (!validators) return;
        
        setLoading(false);
    };

    useEffect(() => {
        processValidators();
        validateAll();
    }, [open]);

    useEffect(() => {
        checkLoading();
    }, [obj, validators]);

    useEffect(() => {
        checkIsFormDisabled();
    }, [vresults, obj.type]);



    const setObjField = (field: any, value: any) => {
        let t = JSON.parse(JSON.stringify(obj));
        t[field] = value;
        setObj(t);

        validateField(field, value);
    };

    const checkIsFormDisabled = () => {
        let rez = ValidateTools.vForm(vresults, 'name');
        

        if (!obj.type) rez = true;
        setIsFormDisabled(rez);
    };

    const handleSubmit = (event: any) => {
        event.preventDefault();
        if (isFormDisabled) return false;

        if (!obj) return;

        if (!id) return;
        obj.idcourse = id
        setLoading(true);
        testService.add(handleResult, {}, obj);
    };

    const handleResult = (result: ResultObjectDTO, cbparams: any) => {
        if (!result) return;
        if (!result.err) {
            setObjectWasChanged(CommonTools.generateRandomString(5));
            setOpen(false);
            setLoading(false);
            setObj(new PostTestDto());
        }
    }

    const handleClose = () => {
        setOpen(false);
    };

    const processLessonSelect = () => {
        if (!obj) return <></>;
        if (!obj.type) return <></>;
        if (obj.type !== Types.TYPE_TEST_LESSON) return <></>;
        const defaultValue = idLesson ? idLesson : '';
        return (
            <Box>
                <Typography variant="h6" mb={1}>{LL('Lesson')}</Typography>
                <LessonSelect
                    id="idlesson"
                    name="idlesson"
                    variant="standard"
                    className="form-select"
                    color="primary"
                    setObj={setObjField}
                    field="idlesson"
                    obj={obj}
                    idCourse={id}
                    _defaultValue={defaultValue}
                />
            </Box>
        )
    }

    const processForm = () => {
        if (!obj) return <></>;
        if (loading) return <CircularLoading />;

        return (
            <form onSubmit={handleSubmit}>
                <Box>
                    <Typography variant="h6" mb={1}> {LL('Numele')}</Typography>
                    <MyTextField
                        tabIndex={0}
                        fullWidth
                        id="name"
                        name="name"
                        variant="standard"
                        color="primary"
                        className="form-input"
                        _label={LL("Name")}
                        size="small"
                        value={obj.name}
                        setObj={setObjField}
                        _vresults={vresults && vresults.name ? vresults.name : false}
                    />
                </Box>
                <Box>
                    <Typography variant="h6" mb={1}>{LL('Tip test')}</Typography>
                    <TestTypeSelect
                        id="type"
                        name="type"
                        variant="standard"
                        className="form-select"
                        color="primary"
                        setObj={setObjField}
                        field="type"
                        value={obj.type}
                    />
                </Box>
                <Box>
                    <Typography variant="h6" mb={1}> {LL('Min test score')}</Typography>
                    <MyTextField
                        tabIndex={0}
                        fullWidth
                        id="mintestscore"
                        name="mintestscore"
                        variant="standard"
                        color="primary"
                        className="form-input"
                        _label={LL("mintestscore")}
                        size="small"
                        value={obj.mintestscore}
                        setObj={setObjField}
                        _vresults={undefined}
                    />
                </Box>
                {processLessonSelect()}
                <Box textAlign='center' my={4}>
                    <MyButton
                        tabIndex={1}
                        disabled={isFormDisabled}
                        color="primary"
                        variant="contained"
                        size="large"
                        type="submit"
                        className="btn"
                        children={LL("Bt_Submit")}
                    />
                </Box>
            </form>
        )
    }
    return (<>

        <Dialog
            open={open}
            onClose={handleClose}
            fullWidth
            className="dialogAdd"

        >
            <DialogTitle>{LL("Add_test")}</DialogTitle>
            <IconButton sx={{
                position: 'absolute',
                right: 8,
                top: 8,
            }} onClick={handleClose}>
                <CloseIcon />
            </IconButton>
            <DialogContent dividers>

                {processForm()}
            </DialogContent>

        </Dialog>
    </>
    )
};

export { AddTestDialog };