import React, { useContext } from "react";

import { StudentCourseContext } from "context/studentcourse/studentcourse.context";
import { Box, Grid, Typography, Divider } from "@mui/material";

import { MyNavBt } from "components/general/MyNavBt";
import { ComponentCommonTools } from "tools/componentcommontools";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { useResource } from "hook/useResource";
import { CourseProgress } from "components/studentcourse/CourseProgress";
import { PlaceTestimonial } from "./PlaceTestimonial";
import { PreviewCertificate } from "./PreviewCertificate";

export const StudentCoursePageTopBar: React.FC = () => {
  const {
    state: { course },
  } = useContext(StudentCourseContext);
  const { LL } = useResource();

  const processComponent = () => {
    if (!course) return <></>;
    return (
      <Box className="first-block" py={1}>
        <Grid
          container
          className="container"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box display="flex" alignItems="center" flexWrap="wrap">
            <MyNavBt href={"/profile/course"} className="back-btn">
              <KeyboardArrowLeftIcon />
              {LL("Revenire la cursuri_btn")}
            </MyNavBt>
            <Divider
              orientation="vertical"
              sx={{ mx: 2, opacity: "0.5" }}
              flexItem
            />
            <Typography variant="h5">
              {ComponentCommonTools.processObjectField(course, "name")}
            </Typography>
          </Box>
          <PreviewCertificate />
          <PlaceTestimonial />
          <CourseProgress />
        </Grid>
      </Box>
    );
  };
  return processComponent();
};
