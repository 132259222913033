import React, { useEffect, useState } from "react";
import PageComponentProps from "interfaces/pagecomponentprops.interface";
import { useResource } from "hook/useResource";
import { logger } from "hoc/logger";
import MenuService from "services/menu/menu.service";
import { MyNavBt } from "components/general/MyNavBt";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { IconButton } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import { SearchDialog } from 'components/general/SearchDialog';

const SearchBlock: React.FC<PageComponentProps> = ({
  currentRoute,
  ...props
}) => {
  const { LL } = useResource();

  const [search, setSearch] = useState("");

  const handleChange = (event: any) => {
    setSearch(event.target.value);
  };
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();

    logger("SearchBlockSearchBlock", search);
  };
  return (
    // <form onSubmit={handleSubmit}>
    //   <TextField
    //     label={LL("search")}
    //     onFocus={handleChange}
    //     onChange={handleChange}
    //     value={search}
    //     {...props}
    //   />
    //   <Button type="submit">XX</Button>
    // </form>

    <>
      {!open ? (<IconButton sx={{mx:1}} onClick={handleClickOpen}><SearchIcon /></IconButton>) : (<></>)}
      <SearchDialog open={open} setOpen={setOpen} />
    </>
  );
};

export { SearchBlock };
